<template>
  <div class="form-tracking-events-view-cpt mt-4">
    <table style="border-collapse: collapse;">
      <thead>
        <th>Name</th>
        <th>Status</th>
        <th>Last Edited</th>
        <th>Action</th>
      </thead>
      <tbody>
        <tr v-for="ev in events" :key="ev.id">
          <td>{{ ev.title }}</td>
          <td><span :class="{ [getStatusClass(ev)]: true }">{{ getStatus(ev) }}</span></td>
          <td>{{ localeDateTime(ev.updated_at) }}</td>
          <td>
            <v-icon size="large" @click="() => editEvent(ev)" color="primary-red" class="cursor-pointer"
              icon="mdi-square-edit-outline"></v-icon>
          </td>
        </tr>
      </tbody>
    </table>
    <v-dialog persistent v-model="editTrackingEventModal" max-width="600">
      <v-card prepend-icon="mdi-language-javascript">
        <template v-slot:title>
          <strong>Edit Tracking Event</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="() => false" ref="trackingEventForm">
            <v-textarea variant="outlined" rows="10" name="script" label="Enter Script" placeholder=""
              v-model="trackingEventForm.script" multiLine="true" rounded="md"></v-textarea>
            <div class="rounded-pill w-33 bg-grey-lighten-2 pl-3">
              <v-switch hide-details label="Activate" inset color="primary-red"
                v-model="trackingEventForm.active"></v-switch>
            </div>
            <v-alert class="mt-3" density="compact" type="error" closable v-if="save.errorMsg.length > 0">
              {{ save.errorMsg }}
            </v-alert>
          </v-form>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="editTrackingEventModal = false">
          </v-btn>
          <v-btn rounded="md" variant="elevated" class="font-weight-bold text-none" color="primary-red"
            :loading="save.saving" @click="saveEvent">
            <span>Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import helperMixin from '@/mixins/helper'
import { featureSlugs } from '@/shared/constants/packagebuilder/features'

export default {
  data: function () {
    return {
      editTrackingEventModal: false,
      trackingEventForm: {
        id: null,
        script: '',
        active: false
      },
      save: {
        errorMsg: '',
        saving: false
      }
    }
  },
  mixins: [helperMixin],
  mounted() {
    this.fetchTrackingEvents()
  },
  methods: {
    getStatus(ev) {
      return ev.active ? (ev.configured ? 'Configured' : 'Not Configured') : 'Disabled'
    },
    getStatusClass(ev) {
      return `status-${this.getStatus(ev).toLowerCase().replace(' ', '-')}`
    },
    fetchTrackingEvents() {
      this.$store.dispatch('form/getFormTrackingEvents', {
        formId: this.formId
      }).then((response) => {
        this.$store.dispatch('form/setFormTrackingEvents', {
          formId: this.formId,
          events: response.data.data || []
        })
      })
    },
    openModal: function (ref) {
      this.$refs[ref].open()
    },
    closeModal: function (ref) {
      this.$refs[ref].close()
    },
    editEvent: function (trackingEvent) {
      this.selectedTrackingEvent = trackingEvent

      this.trackingEventForm.id = trackingEvent.id
      this.trackingEventForm.script = trackingEvent.script
      this.trackingEventForm.active = trackingEvent.active === 1 ? true : false

      this.editTrackingEventModal = true
    },
    saveEvent: async function () {
      this.save.saving = true
      this.save.errorMsg = ''
      const { valid } = await this.$refs.trackingEventForm.validate()
      if (!valid) {
        this.save.saving = false
        return
      }

      if (this.trackingEventForm.script.search(`script>`) >= 0 || this.trackingEventForm.script.search(`<script`) >= 0) {
        this.save.errorMsg = 'Scripts tags not allowed, enter javascript code without tags'
        this.save.saving = false

        return
      } else if (this.trackingEventForm.script.search('api.trustedform.com') !== -1) {
        this.save.errorMsg = 'TrustedForm Script not allowed.'
        this.save.saving = false
        return
      }

      this.$store.dispatch('formTrackingEvent/saveFormTrackingEvent', this.trackingEventForm)
        .then(() => {
          this.save.saving = false
          this.fetchTrackingEvents()
          this.editTrackingEventModal = false
        }, () => {
          this.save.saving = true
          this.save.errorMsg = 'Something went wrong! please try again'
        })
    }
  },
  computed: {
    featureSlugs: function () {
      return featureSlugs
    },
    formId() {
      return this.$route.params.id
    },
    trackingEvents() {
      return _.find(this.$store.getters['form/trackingEvents'] || [], { formId: this.formId }) || []
    },
    events() {
      return _.filter(this.trackingEvents.events, (obj) => obj.title !== this.featureSlugs.TRUSTEDFORM.toUpperCase())
    }
  }
}
</script>
