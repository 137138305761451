<template>
  <v-row align="center" justify="center" class="my-16">
    <v-col cols="12" sm="10" md="8" lg="6" xl="4">
      <v-card class="text-center pa-6 my-2" flat>
        <v-card-title class="text-center d-flex flex-column align-center">
          <v-img width="80" aspect-ratio="1" cover :src="require('@/assets/images/leadgen-leaf-logo.png')"></v-img>
        </v-card-title>
        <v-card-text>
          <h4 class="text-h4 font-weight-bold mb-4">LeadGen</h4>

          <v-row justify="center" class="my-1">
            <v-col cols="12">
              <!-- Error / Warning alert -->
              <v-alert v-if="errorMessage" border type="error" variant="tonal" rounded="md" density="compact"
                class="my-4 text-left" icon="mdi-alert-circle">
                {{ errorMessage }}
              </v-alert>

              <!-- Loader -->
              <v-progress-circular v-else-if="verifying" model-value="50" :size="50" :width="4" indeterminate
                color="secondary-red"></v-progress-circular>
              <div v-else class="text-h6 font-weight-regular">
                Congrats! Your LeadGen account is verified. You may login now.
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-2">
            <v-col cols="12">
              <v-btn size="large" color="primary-red" append-icon="mdi-arrow-right-thin" rounded="md"
                class="text-none font-weight-bold" @click="navigateUser">
                {{ buttonText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import errorTypes from '@/store/error-types'
import userMixin from '@/mixins/user'

export default {
  mixins: [userMixin],
  data() {
    return {
      verifying: false,
      errorMessage: '',
      hasTokenLogin: false,
      tokenLogin: null
    }
  },
  computed: {
    buttonText() {
      if (this.hasTokenLogin) return 'Dashboard'
      if (!this.user) return 'Login'
      return 'Dashboard'
    }
  },
  mounted() {
    this.verify()
  },
  methods: {
    navigateUser() {
      if (this.hasTokenLogin) {
        this.$router.push({ name: 'tokenLogin', query: { token: this.tokenLogin } })
      } else if (this.user) {
        this.$router.push({ name: 'dashboard' })
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    verify: function () {
      const { token, userId } = this.$route.params
      if (!token || !userId) {
        this.errorMessage = 'It seems your link is broken'
        return
      }

      this.verifying = true
      this.$store.dispatch(actionTypes.VERIFY_USER_ACCOUNT, {
        userId: userId,
        token: token
      }).then((response) => {
        this.verifying = false
        if (response?.data?.data.token) {
          this.hasTokenLogin = true
          this.tokenLogin = response?.data?.data.token
        } else {
          this.hasTokenLogin = false
          this.tokenLogin = null
        }
      }, (err) => {
        this.verifying = false

        if (errorTypes.ACCOUNT_ALREADY_VERIFIED === err.response?.data?.meta?.error_type) {
          this.errorMessage = 'Your Email Address is already verified.'
        } else if (errorTypes.INVALID_VERIFICATION_TOKEN === err.response?.data?.meta?.error_type) {
          this.errorMessage = 'Invalid verification token, please try again later.'
        } else {
          this.errorMessage = 'Unable to verify Email Address, something went wrong.'
        }
      })
    }
  },
  watch: {
    '$route': 'verify'
  }
}
</script>
