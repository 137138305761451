import * as mutationTypes from '@/store/mutation-types'
import * as IconApi from '@/api/icon'
import _ from 'lodash'

const state = {
  visible: false,
  fetching: false,
  search: '',
  library: 'fa5_far',
  selected: [],
  categories: [
    {
      id: 'fa5',
      title: 'Basic',
      collapsed: false
    },
    {
      id: 'mdi',
      title: 'Advanced',
      collapsed: true
    }
  ],
  libraries: [
    {
      id: 'fa5_far',
      title: 'Regular',
      prefix: 'far fa-',
      icons: {},
      categoryId: 'fa5',
      icon: `<svg id="cloud-computing" xmlns="http://www.w3.org/2000/svg" width="19.35" height="13.303" viewBox="0 0 19.35 13.303">
      <g id="Group_715" data-name="Group 715">
        <path id="Path_238" data-name="Path 238" d="M15.163,84.246A5.671,5.671,0,0,0,4.621,83.1a2.838,2.838,0,0,0-2.806,2.834q0,.034,0,.067a3.969,3.969,0,0,0,2.153,7.3H14.815a4.535,4.535,0,0,0,.348-9.057Zm-.348,7.923H3.968a2.835,2.835,0,0,1-1.3-5.356l.368-.189-.068-.408a1.72,1.72,0,0,1-.024-.283,1.7,1.7,0,0,1,1.7-1.7,1.729,1.729,0,0,1,.243.018l.433.062.17-.4a4.536,4.536,0,0,1,8.651.988l.083.485.492-.014h.1a3.4,3.4,0,1,1,0,6.8Z" transform="translate(0 -80)"/>
      </g>
    </svg>`
    },
    {
      id: 'fa5_fas',
      title: 'Solid',
      prefix: 'fas fa-',
      icons: {},
      categoryId: 'fa5',
      icon: `<svg id="cloud-computing_1_" data-name="cloud-computing (1)" xmlns="http://www.w3.org/2000/svg" width="19.35" height="13.303" viewBox="0 0 19.35 13.303">
      <g id="Group_717" data-name="Group 717">
        <path id="Path_239" data-name="Path 239" d="M15.163,84.246A5.671,5.671,0,0,0,4.621,83.1a2.838,2.838,0,0,0-2.806,2.834q0,.034,0,.067a3.969,3.969,0,0,0,2.153,7.3H14.815a4.535,4.535,0,0,0,.348-9.057Z" transform="translate(0 -80)"/>
      </g>
    </svg>`
    },
    {
      id: 'fa5_fab',
      title: 'Brands',
      prefix: 'fab fa-',
      icons: {},
      categoryId: 'fa5',
      icon: `<svg id="adobe" xmlns="http://www.w3.org/2000/svg" width="19.47" height="14.603" viewBox="0 0 19.47 14.603">
      <g id="Group_719" data-name="Group 719">
        <path id="Path_240" data-name="Path 240" d="M12.169,64a7.275,7.275,0,0,0-5.486,2.495,5.729,5.729,0,0,0-.6-.061,6.084,6.084,0,1,0,0,12.169h6.084a7.3,7.3,0,0,0,0-14.6ZM6.084,77.386a4.868,4.868,0,1,1,0-9.735,4.659,4.659,0,0,1,1.594.28,3.764,3.764,0,0,1,.954.441l.032.018,3.828,2.4a.609.609,0,0,1-.647,1.032l-3.8-2.379a.336.336,0,0,1-.035-.021,2.675,2.675,0,0,0-.7-.329,3.616,3.616,0,0,0-4.875,3.429,3.655,3.655,0,0,0,3.651,3.651,3.718,3.718,0,0,0,.607-.055,6.85,6.85,0,0,0,1.045.977A4.866,4.866,0,0,1,6.084,77.386Zm6.084,0a6.017,6.017,0,0,1-3.625-1.2,5.636,5.636,0,0,1-1.05-.989l-.032-.035L5.613,72.9a.609.609,0,0,1,.942-.772l1.813,2.21c.015.016.029.032.043.049a4.431,4.431,0,0,0,.857.814,4.817,4.817,0,0,0,2.9.964,4.868,4.868,0,0,0,0-9.735,4.819,4.819,0,0,0-2.856.927l0,0-.058-.035A5.108,5.108,0,0,0,8.1,66.79a6,6,0,0,1,4.07-1.573,6.084,6.084,0,0,1,0,12.169Z" transform="translate(0 -64)"/>
      </g>
    </svg>`
    },

    {
      id: 'mdi_baseline',
      title: 'Baseline',
      prefix: 'material-icons ',
      icons: {},
      categoryId: 'mdi',
      icon: `<svg id="ear" xmlns="http://www.w3.org/2000/svg" width="12.763" height="18.533" viewBox="0 0 12.763 18.533">
      <g id="Group_726" data-name="Group 726">
        <g id="Group_725" data-name="Group 725">
          <path id="Path_241" data-name="Path 241" d="M86.083,0A6.389,6.389,0,0,0,79.7,6.382a.618.618,0,1,0,1.236,0,5.146,5.146,0,0,1,10.292,0A4.246,4.246,0,0,1,89.5,9.922a6.609,6.609,0,0,0-2.451,5.257,2.119,2.119,0,0,1-4.239,0,.618.618,0,1,0-1.236,0,3.355,3.355,0,0,0,6.71,0A5.429,5.429,0,0,1,90.37,10.8a5.468,5.468,0,0,0,2.094-4.42A6.389,6.389,0,0,0,86.083,0Z" transform="translate(-79.701)"/>
        </g>
      </g>
      <g id="Group_728" data-name="Group 728" transform="translate(2.273 7.317)">
        <g id="Group_727" data-name="Group 727">
          <path id="Path_242" data-name="Path 242" d="M146.9,204.219a2.777,2.777,0,0,0-2.977-2.073.618.618,0,1,0,.129,1.229,1.54,1.54,0,1,1-.626,2.855.618.618,0,0,0-.63,1.063,2.776,2.776,0,0,0,4.1-3.075Z" transform="translate(-142.494 -202.131)"/>
        </g>
      </g>
      <g id="Group_730" data-name="Group 730" transform="translate(3.101 2.881)">
        <g id="Group_729" data-name="Group 729">
          <path id="Path_243" data-name="Path 243" d="M168.963,79.6a3.609,3.609,0,0,0-3.6,3.6.618.618,0,1,0,1.236,0,2.368,2.368,0,1,1,4.737,0,.618.618,0,1,0,1.236,0A3.609,3.609,0,0,0,168.963,79.6Z" transform="translate(-165.359 -79.599)"/>
        </g>
      </g>
      <g id="Group_732" data-name="Group 732" transform="translate(3.09 5.642)">
        <g id="Group_731" data-name="Group 731">
          <path id="Path_244" data-name="Path 244" d="M166.294,156.459a.618.618,0,1,0-1.235.041l.055,1.643a.618.618,0,0,0,.617.6h.021a.618.618,0,0,0,.6-.638Z" transform="translate(-165.059 -155.862)"/>
        </g>
      </g>
    </svg>`
    },
    {
      id: 'mdi_outlined',
      title: 'Outline',
      prefix: 'material-icons-outlined ',
      icons: {},
      categoryId: 'mdi',
      icon: `<svg id="cloud-computing" xmlns="http://www.w3.org/2000/svg" width="19.35" height="13.303" viewBox="0 0 19.35 13.303">
      <g id="Group_715" data-name="Group 715">
        <path id="Path_238" data-name="Path 238" d="M15.163,84.246A5.671,5.671,0,0,0,4.621,83.1a2.838,2.838,0,0,0-2.806,2.834q0,.034,0,.067a3.969,3.969,0,0,0,2.153,7.3H14.815a4.535,4.535,0,0,0,.348-9.057Zm-.348,7.923H3.968a2.835,2.835,0,0,1-1.3-5.356l.368-.189-.068-.408a1.72,1.72,0,0,1-.024-.283,1.7,1.7,0,0,1,1.7-1.7,1.729,1.729,0,0,1,.243.018l.433.062.17-.4a4.536,4.536,0,0,1,8.651.988l.083.485.492-.014h.1a3.4,3.4,0,1,1,0,6.8Z" transform="translate(0 -80)"/>
      </g>
    </svg>`
    },
    {
      id: 'mdi_sharp',
      title: 'Sharp',
      prefix: 'material-icons-sharp ',
      icons: {},
      categoryId: 'mdi',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15.243" height="16.91" viewBox="0 0 15.243 16.91">
      <path id="XMLID_811_" d="M37.115,10.625l-3.018.528V4.31l3.168-.554A.385.385,0,0,0,37.132,3L34.1,3.528V.385a.385.385,0,1,0-.77,0V3.663L26.6,4.84V1.85a.385.385,0,0,0-.77,0V4.974l-3.15.551a.385.385,0,1,0,.133.759l3.018-.528V12.6l-3.168.554a.385.385,0,1,0,.133.759l3.035-.531v3.143a.385.385,0,1,0,.77,0V13.247l6.729-1.177v2.99a.385.385,0,0,0,.77,0V11.935l3.15-.551a.385.385,0,0,0-.133-.759ZM26.6,12.465V5.622l6.729-1.177v6.843Z" transform="translate(-22.341)"/>
    </svg>`
    },
    {
      id: 'mdi_round',
      title: 'Round',
      prefix: 'material-icons-round ',
      icons: {},
      categoryId: 'mdi',
      icon: `<svg id="information" xmlns="http://www.w3.org/2000/svg" width="17.2" height="17.2" viewBox="0 0 17.2 17.2">
      <g id="Group_734" data-name="Group 734">
        <g id="Group_733" data-name="Group 733">
          <path id="Path_245" data-name="Path 245" d="M8.6,0a8.6,8.6,0,1,0,8.6,8.6A8.6,8.6,0,0,0,8.6,0Zm0,15.856A7.256,7.256,0,1,1,15.856,8.6,7.252,7.252,0,0,1,8.6,15.856Z"/>
        </g>
      </g>
      <g id="Group_736" data-name="Group 736" transform="translate(7.928 7.2)">
        <g id="Group_735" data-name="Group 735">
          <path id="Path_246" data-name="Path 246" d="M236.672,214.33A.672.672,0,0,0,236,215v4.327a.672.672,0,1,0,1.344,0V215A.672.672,0,0,0,236.672,214.33Z" transform="translate(-236 -214.33)"/>
        </g>
      </g>
      <g id="Group_738" data-name="Group 738" transform="translate(7.693 4.563)">
        <g id="Group_737" data-name="Group 737">
          <circle id="Ellipse_32" data-name="Ellipse 32" cx="0.907" cy="0.907" r="0.907"/>
        </g>
      </g>
    </svg>`
    },
    {
      id: 'mdi_two-tone',
      title: 'Twotone',
      prefix: 'material-icons-two-tone ',
      icons: {},
      categoryId: 'mdi',
      icon: `<svg id="insurance" xmlns="http://www.w3.org/2000/svg" width="19.206" height="24.012" viewBox="0 0 19.206 24.012">
      <path id="Path_247" data-name="Path 247" d="M17.206,26.232A12,12,0,0,1,12.6,16.781v-2.4a10.591,10.591,0,0,1-3.6.648v4.954a12,12,0,0,0,4.6,9.45l4.6,3.6,4.6-3.6a12,12,0,0,0,2.309-2.4A7.458,7.458,0,0,1,17.206,26.232Z" transform="translate(-8.6 -9.428)" fill="#bcbcbc"/>
      <g id="Group_739" data-name="Group 739" transform="translate(5.602 6.401)">
        <path id="Path_248" data-name="Path 248" d="M30.8,35.2H26l.4-3.2h4Z" transform="translate(-24.4 -26.398)" fill="#bdbcbc"/>
        <path id="Path_249" data-name="Path 249" d="M22.8,44h6.4a.8.8,0,0,1,.8.8v.8H22v-.8A.8.8,0,0,1,22.8,44Z" transform="translate(-22 -33.597)" fill="#bdbcbc"/>
        <path id="Path_250" data-name="Path 250" d="M28.4,18v1.2H26.8V18H25.2v1.2H23.6V18H22v2l2,2h4l2-2V18Z" transform="translate(-22 -18)" fill="#bdbcbc"/>
        <rect id="Rectangle_339" data-name="Rectangle 339" width="7" height="2" rx="1" transform="translate(0.398 3.599)" fill="#bdbcbc"/>
        <rect id="Rectangle_340" data-name="Rectangle 340" width="7" height="2" rx="1" transform="translate(0.398 8.599)" fill="#bdbcbc"/>
      </g>
      <path id="Path_251" data-name="Path 251" d="M26.806,7.2a10.231,10.231,0,0,1-8.054-3.965l-.832-1.08a.4.4,0,0,0-.632,0l-.832,1.08A10.231,10.231,0,0,1,8.4,7.2a.4.4,0,0,0-.4.4v4.954a12.332,12.332,0,0,0,4.757,9.771l4.6,3.6a.4.4,0,0,0,.5,0l4.6-3.6a12.332,12.332,0,0,0,4.757-9.771V7.6a.4.4,0,0,0-.4-.4Zm-.4,5.354A11.536,11.536,0,0,1,21.952,21.7L17.6,25.1,13.254,21.7A11.536,11.536,0,0,1,8.8,12.557V8a11.035,11.035,0,0,0,8.291-4.277l.512-.668.512.668A11.035,11.035,0,0,0,26.406,8Z" transform="translate(-8 -2.003)"/>
      <path id="Path_252" data-name="Path 252" d="M26.6,17.4a.4.4,0,0,0-.4-.4H24.6a.4.4,0,0,0-.4.4v.8h-.8v-.8A.4.4,0,0,0,23,17H21.4a.4.4,0,0,0-.4.4v2a.4.4,0,0,0,.116.284L22.532,21.1a1.2,1.2,0,0,0,.4,2.3l-.308,2.465a1.2,1.2,0,0,0-.752,1.6A1.2,1.2,0,0,0,21,28.6v.8a.4.4,0,0,0,.4.4h8a.4.4,0,0,0,.4-.4v-.8a1.2,1.2,0,0,0-.888-1.152,1.2,1.2,0,0,0-.752-1.6L27.854,23.4a1.2,1.2,0,0,0,.4-2.3l1.416-1.412A.4.4,0,0,0,29.8,19.4v-2a.4.4,0,0,0-.4-.4H27.8a.4.4,0,0,0-.4.4v.8h-.8Zm.748,8.4h-3.9l.3-2.4h3.3ZM29,29H21.8v-.4a.4.4,0,0,1,.4-.4h6.4a.4.4,0,0,1,.4.4Zm-1.2-1.6H23a.4.4,0,1,1,0-.8h4.8a.4.4,0,1,1,0,.8Zm0-4.8H23a.4.4,0,1,1,0-.8h4.8a.4.4,0,1,1,0,.8Zm0-3.6a.4.4,0,0,0,.4-.4v-.8H29v1.436L27.238,21H23.565L21.8,19.237V17.8h.8v.8a.4.4,0,0,0,.4.4h1.6a.4.4,0,0,0,.4-.4v-.8h.8v.8a.4.4,0,0,0,.4.4Z" transform="translate(-15.798 -10.999)"/>
      <path id="Path_253" data-name="Path 253" d="M16.281,27.113a.4.4,0,0,0-.568,0l-.516.52-.516-.52a.4.4,0,1,0-.568.568l.52.516-.52.516a.4.4,0,1,0,.568.568l.516-.52.516.52a.4.4,0,1,0,.568-.568l-.52-.516.52-.516a.4.4,0,0,0,0-.568Z" transform="translate(-11.596 -16.995)"/>
      <path id="Path_254" data-name="Path 254" d="M45.2,29.4A1.2,1.2,0,1,0,44,28.2,1.2,1.2,0,0,0,45.2,29.4Zm0-1.6a.4.4,0,1,1-.4.4A.4.4,0,0,1,45.2,27.8Z" transform="translate(-29.596 -16.998)"/>
    </svg>`
    }
  ]
}

const getters = {
  fetching: function (state) {
    return state.fetching
  },
  selected: function (state) {
    return state.selected
  },
  library: function (state) {
    return state.library
  },
  visible: function (state) {
    return state.visible
  },
  libraries: function (state) {
    return state.libraries
  },
  libraryObj: function (state, getters) {
    if (!state.library) {
      return null
    }

    return getters.libraries.filter((l) => l.id === state.library).pop()
  },
  libraryIcons: function (state, getters) {
    if (!getters.libraryObj) {
      return {}
    }

    return getters.libraryObj.icons
  },
  search: function (state) {
    return state.search
  },
  hasIcons: function (state, getters) {
    if (_.isObjectLike(getters.libraryIcons) && Object.keys(getters.libraryIcons).length > 0) {
      return true
    }

    return getters.hasCachedIcons
  },
  hasCachedIcons: function (state) {
    return localStorage.getItem(`iconlibrary_${state.library}`)
  },
  cachedIcons: function (state) {
    return JSON.parse(localStorage.getItem(`iconlibrary_${state.library}`)) || {}
  },
  searchedIcons: function (state, getters) {
    if (!state.search) {
      return getters.libraryIcons
    }

    return Object.keys(getters.libraryIcons).reduce((value, icon) => {
      if (icon.toLowerCase().includes(state.search.toLowerCase())) {
        value[icon] = getters.libraryIcons[icon]
      }

      return value
    }, {})
  },
  categories(state) {
    return state.categories
  },
  categoryLibraries: (state) => (id) => {
    return state.libraries
      .filter((l) => l.categoryId === id)
  }
}

const actions = {
  toggleFetching({ commit }, data) {
    commit(mutationTypes.ILM_TOGGLE_FETCHING, data)
  },

  fetchIcons({ commit }, data) {
    return IconApi.getIcons(data.library, data.variation)
  },

  resetIcons({ commit }, data) {
    commit(mutationTypes.ILM_RESET_ICONS, data)
  },

  fetchIconsSuccess({ commit }, data) {
    localStorage.setItem(`iconlibrary_${data.library}`, JSON.stringify(data.icons), 60 * 1000)

    commit(mutationTypes.ILM_FETCH_ICONS_SUCCESS, data)
  },

  setVisibility({ commit }, data) {
    commit(mutationTypes.ILM_SET_VISIBILITY, data)
  },

  selectLibrary({ commit }, data) {
    commit(mutationTypes.ILM_SELECT_LIBRARY, data)
  },

  selectIcon({ commit }, data) {
    commit(mutationTypes.ILM_SELECT_ICON, data)
  },

  searchLibrary({ commit }, data) {
    commit(mutationTypes.ILM_SEARCH_LIBRARY, data)
  },

  toggleCategory({ commit }, data) {
    commit(mutationTypes.ILM_TOGGLE_CATEGORY, data)
  }
}

const mutations = {
  [mutationTypes.ILM_TOGGLE_FETCHING](state, data) {
    state.fetching = data.fetching
  },

  [mutationTypes.ILM_RESET_ICONS](state, data) {
    for (let library of state.libraries) {
      library.icons = {}
    }
  },

  [mutationTypes.ILM_FETCH_ICONS_SUCCESS](state, data) {
    state.library = data.library

    for (let library of state.libraries) {
      if (library.id !== data.library) {
        continue
      }
      library.icons = data.icons
    }
  },

  [mutationTypes.ILM_SET_VISIBILITY](state, data) {
    state.visible = data.visible
  },

  [mutationTypes.ILM_SELECT_LIBRARY](state, data) {
    state.library = data.library
  },

  [mutationTypes.ILM_SELECT_ICON](state, data) {
    state.selected = data.selected
  },

  [mutationTypes.ILM_SEARCH_LIBRARY](state, data) {
    state.search = data.search
  },

  [mutationTypes.ILM_TOGGLE_CATEGORY](state, data) {
    const category = _.find(state.categories, { id: data.id })
    category.collapsed = data.collapsed

    state.categories
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
