import { deleteData, getData, putData } from '../plugins/ApiCalls'

export function getFormEndingLists(data) {
  return getData('form-endings/list?listParams=' + encodeURIComponent(JSON.stringify(data)))
}
export function updateDefaultThankyouPage(data) {
  return putData(`form-endings/${data.data.id}`, data)
}
export function getFormQuestions(data) {
  return getData(`form-endings/getFormQuestions?formId=${data.formId}&variantId=${data.variantId}`)
}
export function createFormEnding(data, userId) {
  return putData(`form-endings/createFormEnding/${userId}`, data)
}
export function getFormEndingData(data) {
  return getData(`form-endings/getFormEnding?id=${data.id}&type=${data.type}&userId=${data.userId}`)
}
export function updateFormEnding(data) {
  return putData(`form-endings/updateFormEnding/${data.id}`, data)
}
export function deleteFormEnding(data) {
  return deleteData(`form-endings/deleteAnswerBasedEnding?id=${data.id}&userId=${data.userId}`)
}
export function duplicateFormEnding(data) {
  return getData(`form-endings/duplicateAnswerBasedEnding?id=${data.id}&userId=${data.userId}`)
}
export function bulkDeleteFormEndings(data) {
  return deleteData(`form-endings/bulkDeleteAnswerBasedEnding?ids=${data.checked}&userId=${data.userId}`)
}
export function bulkDuplicateFormEndings(data) {
  return getData(`form-endings/bulkDuplicateAnswerBasedEnding?ids=${data.checked}&userId=${data.userId}`)
}
export function enableFormEnding(data) {
  return putData(`form-endings/enableFormEnding/${data.id}`, data)
}
export function saveOrder(data) {
  return putData(`form-endings/saveVisitorEndingOrder/${data.formId}`, data)
}
