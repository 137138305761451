import ApiConfig from '../api/config'
import axios from 'axios'
import { store } from '../store/store'
import router from '../router'
import * as actionTypes from '@/store/action-types'
import qs from 'qs'

const api = axios.create({
  baseURL: ApiConfig.basePath
})

// Request interceptor
api.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('token'))
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

// Response interceptor
api.interceptors.response.use(
  (response) => {
    if (
      response.data.meta &&
      response.data.meta.error_type &&
      (response.data.meta.error_type === 'token_expired' ||
        response.data.meta.error_type === 'token_invalid' ||
        response.data.meta.error_type === 'suspended_account')
    ) {
      store.dispatch(actionTypes.LOGOUT_USER).then(() => {
        router.push({ name: 'login' })
      })
    }
    return response
  },
  (error) => {
    if (error.response?.data?.meta?.code == 401) {
      store.dispatch(actionTypes.LOGOUT_USER).then(() => {
        router.push({ name: 'login' })
      })
    }
    return Promise.reject(error)
  }
)

// GET request function
export function getData(url, config = {}) {
  return api.get(url, config)
}

// POST request function
export function postData(url, data, config = {}) {
  return config?.emulateJSON
    ? encodeAndSendData('POST', url, data)
    : api.post(url, data, config)
}

// PUT request function
export function putData(url, data, config = {}) {
  return config?.emulateJSON
    ? encodeAndSendData('PUT', url, data)
    : api.put(url, data, config)
}

// DELETE request function
export function deleteData(url, config = {}) {
  return api.delete(url, config)
}

// Helper function for encoding data as application/x-www-form-urlencoded
function encodeAndSendData(method, url, data) {
  return api({
    method,
    url,
    data: qs.stringify(data),
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
    }
  })
}
