export default {
  EQ: '=',
  NEQ: '!=',
  LT: '<',
  LTE: '<=',
  GT: '>',
  GTE: '>=',
  AND: 'AND',
  OR: 'OR'
}
