<template>
  <v-container class="w-75">
    <v-row class="settings-view">
      <v-col cols="12" v-if="settingsLoaded">

        <v-snackbar rounded="md" height="50" v-model="snackbar.show" :color="snackbar.color" :timeout="5000">
          <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
        </v-snackbar>

        <v-form @submit.prevent="updateSettings">
          <v-row align="center" justify="space-between" class="mb-4 text-none">
            <v-col>
              <h4 class="page-title">Settings</h4>
            </v-col>
            <v-col class="text-right ">
              <v-btn :loading="saving" class="text-none font-weight-bold" prepend-icon="mdi-content-save-cog"
                type="submit" color="primary-red" rounded="md">
                Save Settings
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card rounded="md" elevation="0" class="border-thin border-t-0 rounded-t-sm">
            <v-card-title class="bg-blue-lighten-5 text-primary-blue">
              <h6 class="d-flex justify-left mb-0">
                <p class="font-weight-bold text-primary-blue mb-0">White Label Settings
                </p>
                <span class="lg-badge pt-2 ml-4"
                  :class="whiteLabelSetting.enabled ? 'bg-primary-green' : 'bg-secondary-red'">
                  {{ whiteLabelSetting.enabled ? 'Enabled' : 'Disabled' }}
                </span>
              </h6>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-switch v-model="whiteLabelSetting.enabled" color="primary-red" inset density="compact"
                label="White Labeling" hide-details>
              </v-switch>
            </v-card-text>
          </v-card>

          <v-spacer class="my-10"></v-spacer>

          <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
            <v-card-title class="bg-blue-lighten-5 text-primary-blue">
              <h6 class="d-flex justify-left mb-0">
                <p class="font-weight-bold text-primary-blue mb-0">Global API Keys&nbsp;&nbsp;
                </p>
              </h6>
            </v-card-title>
            <v-divider class="mb-4"></v-divider>
            <v-card-text>
              <v-text-field v-for="(credential, index) in credentials" :key="index" :label="credential.title"
                v-model="credential.config.apikey" variant="outlined" density="default" rounded="md" hide-details
                class="mb-2">
              </v-text-field>
              <a href="https://leadgenapp.io/help/how-can-i-use-google-powered-address-autocomplete-in-leadgen-forms/"
                class="text-none pl-0 link text-primary-red" target="_blank">
                Guide for Generating API Keys for Google Address Autocomplete
                <v-icon small>mdi-open-in-new</v-icon>
              </a>
            </v-card-text>
          </v-card>

          <v-spacer class="my-10"></v-spacer>

          <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
            <v-card-title class="bg-blue-lighten-5 text-primary-blue">
              <h6 class="d-flex justify-left mb-0">
                <p class="font-weight-bold text-primary-blue mb-0">Lead Notifications
                </p>
                <span class="lg-badge pt-2 ml-4"
                  :class="leadNotificationSetting.enabled ? 'bg-primary-green' : 'bg-secondary-red'">
                  {{ leadNotificationSetting.enabled ? 'Enabled' : 'Disabled' }}
                </span>
              </h6>
            </v-card-title>
            <v-divider class="mb-2"></v-divider>
            <v-card-text>
              <v-switch v-model="leadNotificationSetting.enabled" color="primary-red" inset density="compact"
                label="Show Lead Notification Badges In Forms List">
              </v-switch>
              <v-select v-if="leadNotificationSetting.enabled" class="mb-1" label="Notification Refresh"
                :items="frequencyOptions" v-model="leadNotificationSetting.notification_frequency" item-title="label"
                item-value="value" variant="outlined" density="default" rounded="md">
              </v-select>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" v-else>
        <div class="settings-view__loading">
          <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
            color="secondary-red"></v-progress-circular>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()

    const settingsLoaded = ref(false)
    const saving = ref(false)
    const snackbar = reactive({ show: false, text: '', color: 'success' })

    const whiteLabelSetting = reactive({
      id: null,
      enabled: false
    })

    const credentials = ref([])

    const leadNotificationSetting = reactive({
      id: null,
      enabled: false,
      notification_frequency: null
    })

    const frequencyOptions = [
      { label: 'For every new lead', value: 'for_every_new_lead' }
    ]

    const loadSettings = async () => {
      try {
        const [whiteLabelResponse, credentialsResponse, leadNotificationResponse] = await Promise.all([
          store.dispatch('setting/fetchWhiteLabelSetting'),
          store.dispatch('setting/fetchCredentials'),
          store.dispatch('setting/fetchLeadNotificationSetting')
        ])

        if (whiteLabelResponse.data && whiteLabelResponse.data.data) {
          whiteLabelSetting.id = whiteLabelResponse.data.data.id
          whiteLabelSetting.enabled = Boolean(whiteLabelResponse.data.data.enabled)
        }

        if (credentialsResponse.data && credentialsResponse.data.data) {
          credentials.value = credentialsResponse.data.data
        }

        if (leadNotificationResponse.data && leadNotificationResponse.data.data) {
          leadNotificationSetting.id = leadNotificationResponse.data.data.id
          leadNotificationSetting.enabled = Boolean(leadNotificationResponse.data.data.enabled)
          leadNotificationSetting.notification_frequency = leadNotificationResponse.data.data.notification_frequency
        }

        settingsLoaded.value = true
      } catch (error) {
        snackbar.text = 'Failed to load settings. Please try again.'
        snackbar.color = 'error'
        snackbar.show = true
      }
    }

    const updateSettings = async () => {
      saving.value = true
      snackbar.show = false

      try {
        await Promise.all([
          store.dispatch('setting/updateWhiteLabelSetting', {
            id: whiteLabelSetting.id,
            enabled: whiteLabelSetting.enabled
          }),
          store.dispatch('setting/updateCredentialsSetting', { credentials: credentials.value }),
          store.dispatch('setting/updateLeadNotificationSetting', {
            id: leadNotificationSetting.id,
            enabled: leadNotificationSetting.enabled,
            notification_frequency: leadNotificationSetting.notification_frequency
          })
        ])
        snackbar.text = 'Settings Updated Successfully.'
        snackbar.color = 'success'

        await loadSettings()
      } catch (error) {
        snackbar.text = 'Unable to save settings. Please try again.'
        snackbar.color = 'error'
      } finally {
        saving.value = false
        snackbar.show = true
      }
    }

    watch(() => store.state.setting.whiteLabelSetting, (newValue) => {
      if (newValue) {
        whiteLabelSetting.id = newValue.id
        whiteLabelSetting.enabled = Boolean(newValue.enabled)
      }
    }, { deep: true })

    watch(() => store.state.setting.leadNotificationSetting, (newValue) => {
      if (newValue) {
        leadNotificationSetting.id = newValue.id
        leadNotificationSetting.enabled = Boolean(newValue.enabled)
        leadNotificationSetting.notification_frequency = newValue.notification_frequency
      }
    }, { deep: true })

    onMounted(loadSettings)

    return {
      settingsLoaded,
      saving,
      snackbar,
      whiteLabelSetting,
      credentials,
      leadNotificationSetting,
      frequencyOptions,
      updateSettings
    }
  }
}
</script>
