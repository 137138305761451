<template>
  <div id="form-experiment-overview">
    <div>
      <router-link :to="{ name: 'forms.overview' }"> <v-icon icon="mdi-arrow-left"></v-icon>Form Overview
      </router-link>
    </div>
    <v-row class="mt-4">
      <v-col cols="8">
        <h3>Experiments</h3>
      </v-col>
      <v-col cols="4">
        <v-select :disabled="variants.length === 1" variant="outlined" placeholder="All Experiments"
          :items="experimentsFilter" density="comfortable" v-model="experimentFilter"></v-select>
      </v-col>
      <!-- <v-btn type="primary" color="primary-red">Create Second Variant</v-btn> -->
    </v-row>
    <v-divider></v-divider>
    <div class="text-end mt-4">
      <v-btn v-if="experiments.length > 0" @click="toggleDialog()" color="primary-red"
        class="font-weight-bold text-none">Create Experiment</v-btn>
    </div>
    <!-- create your first variant  -->
    <div class="mt-3" v-if="variants.length === 1 && !loading">
      <v-empty-state
        :image="require('@/assets/images/no-integration.svg')">
        <template v-slot:title>
          <h3 class="mb-0">No Form Experiments Created Yet</h3>
        </template>
        <template v-slot:text>
          <span>Before you get started with the form experiments feature you must have at least two variants created.</span>
        </template>
      </v-empty-state>
      <div class="text-center">
        <router-link :to="{ name: 'forms.variants.create', params: { id: $route.params.id || 0 } }">
          <v-btn type="primary" color="primary-red text-none font-weight-bold">Create Second Variant</v-btn>
        </router-link>
      </div>
    </div>
    <!-- Experiments list -->
    <div class="experiments-list" v-if="experiments.length > 0 && !loading">

      <!-- Running Experiments -->
      <div class="mt-6"  v-if="experimentFilter === 'Running'">
        <template v-if="runningExperiments.length > 0">
          <div class="d-flex justify-space-between">
            <div>
              <h3>{{ runningExperiment.title }}</h3>
            </div>
            <v-btn class="font-weight-bold text-none" color="primary-red" :loading="endingExperiment"
              @click="endExperiment(runningExperiment.id)">Stop</v-btn>
          </div>
          <table class="highlight">
            <thead>
              <tr>
                <th>Variant</th>
                <th>Views</th>
                <th>Visitors</th>
                <th>Partial Entries</th>
                <th>Submissions</th>
                <th>Conversions</th>
                <th>Conversion Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="competitor in runningExperimentResult.competitors" :key="competitor.id">
                <td>{{ getVariantName(competitor.form_variant_id) }}</td>
                <td>{{ competitor.visit_count }}</td>
                <td>{{ competitor.visitor_count }}</td>
                <td>{{ competitor.partial_count }}</td>
                <td>{{ competitor.lead_count }}</td>
                <td>{{ competitor.conversion_count }}</td>
                <td>{{ parseInt(competitor.conversion_rate * 100) }}%</td>
              </tr>
            </tbody>
          </table>
        </template>
        <v-empty-state v-else :image="require('@/assets/images/no-integration.svg')">
          <template v-slot:title>
            <h3 class="mt-2">No Running Experiments</h3>
          </template>
        </v-empty-state>
      </div>

      <!-- Draft Experiments -->
      <div class="mt-6"  v-if="experimentFilter === 'Draft'">
        <template v-if="draftExperiments.length > 0">
          <table class="highlight">
            <thead>
              <tr>
                <th>Experiment Name</th>
                <th>Note</th>
                <th>Created At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in draftExperiments" :key="e.id">
                <td>{{ e.title }}</td>
                <td>{{ e.note }}</td>
                <td>{{ localeDateTime(e.created_at) }}</td>
                <td><router-link
                    :to="{ name: 'forms.experiments.detail', params: { id: e.form_id, experimentId: e.id } }">View
                    Details</router-link></td>
              </tr>
            </tbody>
          </table>
        </template>
        <v-empty-state v-else :image="require('@/assets/images/no-integration.svg')">
          <template v-slot:title>
            <h3 class="mt-2">No Draft Experiments</h3>
          </template>
        </v-empty-state>
      </div>

      <!-- Ended Experiments -->
      <div class="mt-6" v-if="experimentFilter === 'Ended'">
        <template v-if="endedExperiments.length > 0">
          <table class="highlight">
            <thead>
              <tr>
                <th>Experiment Name</th>
                <th>Note</th>
                <th>Started At</th>
                <th>Ended At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in endedExperiments" :key="e.id">
                <td>{{ e.title }}</td>
                <td>{{ e.note }}</td>
                <td>{{ localeDateTime(e.started_at) }}</td>
                <td>{{ localeDateTime(e.ended_at) }}</td>
                <td><router-link
                    :to="{ name: 'forms.experiments.detail', params: { id: e.form_id, experimentId: e.id } }">View
                    Details</router-link></td>
              </tr>
            </tbody>
          </table>
        </template>
        <v-empty-state class="mt-5" v-else :image="require('@/assets/images/no-integration.svg')">
          <template v-slot:title>
            <h3 class="mt-2">No Ended Experiments</h3>
          </template>
        </v-empty-state>
      </div>

      <!-- All Experiments -->
      <div v-if="experimentFilter === 'All experiments'">
        <template v-if="runningExperiments.length > 0">
          <div class="my-8">
            <h5><b>Running Experiments</b></h5>
            <!-- <v-divider></v-divider> -->
          </div>
          <div class="d-flex justify-space-between">
            <div>
              <h3>{{ runningExperiment.title }}<v-chip density="comfortable" variant="flat" class="ml-2 font-weight-bold" color="primary-green">Running</v-chip></h3>
            </div>
            <v-btn class="font-weight-bold text-none" color="primary-red" :loading="endingExperiment"
              @click="endExperiment(runningExperiment.id)">Stop</v-btn>
          </div>
          <!-- <div class="text-end mb-2">
            <v-btn class="font-weight-bold text-none text-end" color="primary-red" :loading="endingExperiment"
            @click="endExperiment(runningExperiment.id)">Stop</v-btn>
          </div> -->
          <!-- Running Experiments Table -->
          <table class="highlight">
            <thead>
              <tr>
                <th>Variant</th>
                <th>Views</th>
                <th>Visitors</th>
                <th>Partial Entries</th>
                <th>Submissions</th>
                <th>Conversions</th>
                <th>Conversion Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="competitor in runningExperimentResult.competitors" :key="competitor.id">
                <td>{{ getVariantName(competitor.form_variant_id) }}</td>
                <td>{{ competitor.visit_count }}</td>
                <td>{{ competitor.visitor_count }}</td>
                <td>{{ competitor.partial_count }}</td>
                <td>{{ competitor.lead_count }}</td>
                <td>{{ competitor.conversion_count }}</td>
                <td>{{ parseInt(competitor.conversion_rate * 100) }}%</td>
              </tr>
            </tbody>
          </table>
        </template>

        <template v-if="draftExperiments.length > 0">
          <div class="my-8">
            <h5><b>Draft Experiments</b></h5>
            <!-- <v-divider></v-divider> -->
          </div>
          <!-- Draft Experiments Table -->
          <table class="highlight">
            <thead>
              <tr>
                <th>Experiment Name</th>
                <th>Note</th>
                <th>Created At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in draftExperiments" :key="e.id">
                <td>{{ e.title }}</td>
                <td>{{ e.note }}</td>
                <td>{{ localeDateTime(e.created_at) }}</td>
                <td><router-link
                    :to="{ name: 'forms.experiments.detail', params: { id: e.form_id, experimentId: e.id } }">View
                    Details</router-link></td>
              </tr>
            </tbody>
          </table>
        </template>

        <template v-if="endedExperiments.length > 0">
          <div class="my-8">
            <h5><b>Ended Experiments</b></h5>
            <!-- <v-divider></v-divider> -->
          </div>
          <!-- Ended Experiments Table -->
          <table class="highlight">
            <thead>
              <tr>
                <th>Experiment Name</th>
                <th>Note</th>
                <th>Started At</th>
                <th>Ended At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in endedExperiments" :key="e.id">
                <td>{{ e.title }}</td>
                <td>{{ e.note }}</td>
                <td>{{ localeDateTime(e.started_at) }}</td>
                <td>{{ localeDateTime(e.ended_at) }}</td>
                <td><router-link
                    :to="{ name: 'forms.experiments.detail', params: { id: e.form_id, experimentId: e.id } }">View
                    Details</router-link></td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>

    <!-- create first experiments -->
    <div class="text-center mt-3" v-else-if="variants.length > 1 && !loading">
      <v-empty-state
        :image="require('@/assets/images/create-experiment.svg')">
        <template v-slot:title>
          <h3 class="mb-0 pt-1">Create Your First Experiment</h3>
        </template>
        <template v-slot:text>
          <span>You are now ready to create your experiment. Click the button below to add your experiment.</span>
        </template>
      </v-empty-state>
      <!-- <h5>Create your first experiment</h5>
      <br> -->
      <v-btn class="font-weight-bold text-none" @click="toggleDialog()" color="primary-red">Create
        Experiment</v-btn>
    </div>

    <!-- Modal -->
    <div class="experiment-create-modal-wrap">
      <v-dialog v-model="createExperimentModal" size="normal" width="600px">
        <v-card>
          <v-toolbar color="white">
            <v-toolbar-title class="font-weight-bold"><v-icon class="mr-3" :size="25"
                icon="mdi-ab-testing"></v-icon>Create
              New Experiment</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon="mdi-close" @click="createExperimentModal = false"></v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="createExperiment" ref="createExperimentForm">
              <div class="formfield">
                <v-text-field :autofocus="true" name="title" label="Title" placeholder="Enter experiment title"
                  prepend-inner-icon="mdi-text-short" v-model="experimentForm.title"
                  :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '3', max: '100' }])" variant="outlined"
                  density="comfortable"></v-text-field>
              </div>
              <div class="formfield">
                <v-select label="Select your challenger variant" placeholder="Select Variant"
                  prepend-inner-icon="mdi-form-dropdown" :items="challengerVariants" item-title="label" return-object
                  v-model="challengerVariant" name="challengerVariant" :rules="$valid([{ rule: 'required' }])"
                  v-validate="'required'" variant="outlined" density="comfortable">
                </v-select>
              </div>
              <!-- hide for now until muti-variant feature -->
              <div v-if="false">
                <div class="formfield weight" v-for="variant in variants" :key="variant.id">
                  <div>
                    <p>{{ variant.title }} {{ champion.id === variant.id ? '(champion)' : '(variant)' }}</p>
                  </div>
                  <div>
                    <v-text-field floating-label :name="'variant[' + variant.id + ']'" label="Weight (0-100)%"
                      v-model="experimentForm.variants[variant.id]"
                      :rules="$valid([{ rule: 'required' }, { rule: 'range', min: 0, max: 100 }])"></v-text-field>
                  </div>
                  <div>
                  </div>
                </div>
                <div class="sum-error" v-if="!validWeights">
                  <span class="error-text">The sum of all the weights must be between 99-100%.</span>
                </div>
              </div>
              <div class="formfield">
                <v-textarea label="Note" :multiLine="true" v-model="experimentForm.note" variant="outlined"
                  density="comfortable" placeholder="Keep note of your experiment details" rows="2"
                  prepend-inner-icon="mdi-text-long"
                  :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '3', max: '300' }])"></v-textarea>
              </div>
              <div class="formfield center-align">
                <v-btn type="submit" color="primary-red font-weight-bold text-none" :loading="create.creating">
                  Create Experiment
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
        <br />
        <v-alert @dismiss="create.createdError = false" type="error" v-show="create.createdError">
          Unable to create experiment, please try again.
        </v-alert>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import variantTypes from '@/store/variant-types'
import helperMixin from '@/mixins/helper'
import ErrorTypes from '@/store/error-types'

export default {
  mixins: [helperMixin],

  data: function () {
    return {
      experimentsFilter: [
        'All experiments',
        'Draft',
        'Running',
        'Ended'
      ],
      experimentFilter: 'All experiments',
      challengerVariant: '',
      experimentForm: {
        title: '',
        note: '',
        variants: {}
      },
      loading: {
        form: true,
        variants: true,
        experiments: true
      },
      initWeights: false,
      create: {
        creating: false,
        createdError: false
      },
      stopErrorMessage: '',
      endingExperiment: false,
      createExperimentModal: false
    }
  },

  mounted: function () {
    this.fetchForm()
    this.fetchFormVariants()
    this.fetchFormExperiments()
  },

  methods: {
    fetchForm: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM, {
        id: this.$route.params.id
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_SUCCESS, response.data.data)
        this.loading.form = false
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_ERROR, {
          formId: this.$route.params.id
        })
        this.loading.form = false
      })
    },

    fetchFormVariants: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS, {
        formId: this.$route.params.id
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response.data.data)
        this.loadingFormVariant = false
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, {
          formId: this.$route.params.id
        })
        this.loading.variants = false
      })
    },

    fetchFormExperiments: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENTS, {
        formId: this.$route.params.id
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENTS_SUCCESS, response.data.data)
        this.loadingFormExperiment = false
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENTS_ERROR, {
          formId: this.$route.params.id
        })
        this.loading.experiments = false
      })
    },

    toggleDialog() {
      this.createExperimentModal = !this.createExperimentModal
    },

    createExperiment: async function () {
      /* this.validateForm(this.experimentFormScope).then((result) => {

      }) */
      let result = await this.$refs.createExperimentForm.validate()
      this.setWeights()
      if (result.valid && this.validWeights) {
        this.experimentForm.formId = this.formId
        this.create.creating = true
        this.createdSuccess = false
        this.create.createdError = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_CREATE, {
          formId: this.formId,
          data: this.experimentForm
        }).then((response) => {
          this.$store.dispatch(actionTypes.FORM_EXPERIMENT_CREATE_SUCCESS)
          this.fetchFormExperiments()
          this.create.creating = false
          this.$router.push({
            name: 'forms.experiments.detail',
            params: {
              id: this.formId,
              experimentId: parseInt(response.data.data.id)
            }
          })
        }, (response) => {
          this.$store.dispatch(actionTypes.FORM_EXPERIMENT_CREATE_ERROR)
          this.create.creating = false
          this.create.createdError = true
        })
      }
    },

    validateForm: function (scope) {
      return this.$validator.validateAll(scope)
    },

    setWeights: function () {
      for (let variantId in this.experimentForm.variants) {
        variantId = parseInt(variantId)
        if (
          variantId === this.challengerVariant.value ||
          variantId === this.champion.id
        ) {
          this.experimentForm.variants[parseInt(this.challengerVariant.value)] = 50
        } else {
          this.experimentForm.variants[variantId] = 0
        }
      }
    },

    fetchFormExperimentVariants: function (experimentId) {
      this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT_VARIANTS, {
        formId: this.formId,
        experimentId: experimentId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS, response.data.data)

        for (let v of response.data.data) {
          this.experimentForm.variants[v.form_variant_id] = v.weight
        }
        this.fetchFormVariants()
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENT_VARIANTS_ERROR)
      })
    },

    fetchFormExperimentResult: function (experimentId) {
      this.$store.dispatch(actionTypes.FORM_EXPERIMENT_RESULT, {
        formId: this.formId,
        experimentId: experimentId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_RESULT_SUCCESS, response.data.data)
      }, (response) => {
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_RESULT_ERROR, [])
      })
    },

    getVariant: function (id) {
      let variants = this.variants.filter(v => v.id === id)
      if (variants.length > 0) {
        return variants[0]
      }
      return null
    },

    getVariantName: function (id) {
      let variant = this.getVariant(id)
      return variant ? variant.title : ''
    },

    endExperiment: function (experimentId) {
      this.endingExperiment = true
      this.stopErrorMessage = ''
      this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END, {
        formId: this.formId,
        experimentId: experimentId
      }).then((response) => {
        this.endingExperiment = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END_SUCCESS, response.data.data)
          .then(() => {
            this.$router.push({ name: 'forms.experiments.detail', params: { id: this.formId, experimentId: experimentId } })
          })
      }, (response) => {
        this.endingExperiment = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END_ERROR)

        if (response.data.meta.error_type === ErrorTypes.EXPERIMENT_NOT_STARTED) {
          this.stopErrorMessage = 'Experiment is not started yet.'
        } else if (response.data.meta.error_type === ErrorTypes.EXPERIMENT_ALREADY_ENDED) {
          this.stopErrorMessage = 'Experiment already ended'
        } else {
          this.stopErrorMessage = 'Unable to end experiment, please try again.'
        }
      })
    }
  },

  computed: {
    loading: function () {
      return this.loading.form && this.loading.variants && this.loading.experiments
    },

    formId: function () {
      return parseInt(this.$route.params.id)
    },

    form: function () {
      return this.$store.getters.getForm(this.formId)
    },

    experiments: function () {
      return this.$store.getters.formExperiments(this.formId)
    },

    variants: function () {
      let variants = this.$store.getters.getFormVariants(this.formId) || []
      if (!this.initWeights && variants.length > 0) {
        for (let v of variants) {
          if (v.form_variant_type.type === variantTypes.CHAMPION) {
            this.experimentForm.variants[v.id] = 50
          } else {
            this.experimentForm.variants[v.id] = 0
          }
        }
        this.initWeights = true
      }
      return variants
    },

    champion: function () {
      for (let v of this.variants) {
        if (v.form_variant_type.type === variantTypes.CHAMPION) {
          return v
        }
      }
      return {}
    },

    challengers: function () {
      let challengers = []
      for (let v of this.variants) {
        if (v.form_variant_type.type === variantTypes.CHAMPION) {
          continue
        }
        challengers.push(v)
      }
      return challengers
    },

    challengerVariants: function () {
      let options = []
      for (let challenger of this.challengers) {
        options.push({ label: challenger.title, value: challenger.id })
      }
      return options
    },

    validWeights: function () {
      let sum = 0
      for (let index in this.experimentForm.variants) {
        sum += parseFloat(this.experimentForm.variants[index])
      }
      return sum >= 99 && sum <= 100
    },

    runningExperiments: function () {
      return this.$store.getters.runningExperiments(this.formId) || []
    },

    runningExperiment: function () {
      if (this.runningExperiments.length > 0) {
        return this.runningExperiments[0]
      }
      return null
    },

    draftExperiments: function () {
      return this.$store.getters.draftExperiments(this.formId)
    },

    endedExperiments: function () {
      return this.$store.getters.endedExperiments(this.formId)
    },

    runningExperimentResult: function () {
      if (!this.runningExperiment) {
        return null
      }
      return this.$store.getters.getFormExperimentResult(this.runningExperiment.id)
    }
  },

  watch: {
    runningExperiment: function (e) {
      if (e) {
        this.fetchFormExperimentResult(e.id)
      }
    }
  }
}
</script>
