import * as FormApi from '@/api/form'

export default {
  namespaced: true,
  state: {
    trackingEvents: [] // {formId: 123, data: [] }
  },
  getters: {
    trackingEvents (state) {
      return state.trackingEvents
    }
  },
  actions: {
    getFormTrackingEvents (context, data) {
      return FormApi.getFormTrackingEvents(data.formId)
    },
    setFormTrackingEvents (context, data) {
      context.commit('setFormTrackingEvents', data)
    }
  },
  mutations: {
    setFormTrackingEvents (state, data) {
      const teIndex = state.trackingEvents.findIndex(te => te.formId === data.formId)
      if (teIndex >= 0) {
        state.trackingEvents[teIndex] = data
      } else {
        state.trackingEvents.push(data)
      }
    }
  }
}
