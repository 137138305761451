import { formEndingTypes } from '@/shared/constants/formEndingTypes'
import formSubmitActions from '@/store/form-submit-actions'
import _ from 'lodash'
import { endingConditions } from '@/shared/constants/form/formEndingConditions'
import countries from '@/store/countries'
import questionTypes from '@/store/question-types'
import questionTypesMap from '@/store/question-types-map'

let toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'size': ['small', 'large', 'huge'] }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'align': [] }],
  ['link']
]

export const editorMixin = {
  data() {
    return {
      formQuestions: [],
      deviceTypes: ['DESKTOP', 'MOBILE', 'TABLET'],
      visitorFields: [
        {
          title: 'Country',
          type: 'COUNTRY',
          state: ['Country Equals', 'Country Doesnt Equals']
        },
        {
          title: 'Hidden Field',
          type: 'HIDDENFIELD',
          state: ['Is Equal To', 'Is Not Equal To', 'Contains', 'Does Not Contain', 'Starts With', 'Doesn’t Start With', 'Ends With', 'Doesn’t End With', 'Is Empty', 'Is Filled']
        },
        {
          title: 'Device Type',
          type: 'DEVICETYPE',
          state: ['Is Equal To', 'Is Not Equal To']
        }
      ],
      editorOption: {
        modules: {
          toolbar: toolbarOptions
        }
      },
      questionTypes: questionTypes,
      questionTypesMap: questionTypesMap
    }
  },
  methods: {
    endingsMixin_getFormQuestions: function () {
      this.$store.dispatch('formendings/getFormQuestions', { formId: this.$route.params.id, variantId: this.$route.params.variantId })
        .then((response) => {
          this.$store.dispatch('formendings/getFormQuestionsSuccess', response.data.data)
          this.formQuestions = response.data.data
          this.flags.showLoader = false
        }, (response) => {
          this.flags.errorOccured = true
        }).catch(err => { console.error('ERROR:', err) })
    }
  },
  computed: {
    formEndings: function () {
      return formEndingTypes
    },
    endingConditions: function () {
      return endingConditions
    },
    formSubmitActionArr: function () {
      return _.map(formSubmitActions, (action) => action) || []
    },
    getCountries: function () {
      return countries.map(country => country.name)
    }
  }
}
