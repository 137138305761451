import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function getFormVariants(formId, params = {}) {
  const baseUrl = `forms/${formId}/variants`
  const queryParams = new URLSearchParams(params).toString()
  const url = queryParams ? `${baseUrl}?${queryParams}` : baseUrl

  return getData(url)
}

export function createVariant(formId, data) {
  return postData('forms/' + formId + '/variants', data, { emulateJSON: true })
}

export function getVariantState(formId, variantId) {
  return getData('forms/' + formId + '/variants/' + variantId)
}

export function updateVariantState(formId, varaintId, data) {
  return putData('forms/' + formId + '/variants/' + varaintId, data, { emulateJSON: true })
}

export function duplicateFormVariant(formId, variantId) {
  return getData('forms/' + formId + '/variants/' + variantId + '/duplicate')
}

export function deleteFormVariant(formId, variantId) {
  return deleteData('forms/' + formId + '/variants/' + variantId + '/delete')
}

export function promoteFormVariant(formId, variantId) {
  return getData('forms/' + formId + '/variants/' + variantId + '/promote')
}
export function saveThemeSetting(formId, variantId, data) {
  return postData('forms/' + formId + '/variants/' + variantId + '/theme', data, { emulateJSON: true })
}

export function getThemeSetting(formId, variantId) {
  return getData('forms/' + formId + '/variants/' + variantId + '/theme')
}

export function updateThemeSetting(formId, variantId, data) {
  return putData('forms/' + formId + '/variants/' + variantId + '/theme', data, { emulateJSON: true })
}

export function updateVariantSetting(formId, variantId, data) {
  return putData('forms/' + formId + '/variants/' + variantId + '/setting', data)
}
