// NOTE: Please add the new submit actions after the last item in the order. Do not add them between or at the beginning.
export default {
  URL: {
    label: 'Go To URL after Form submission',
    value: 'URL'
  },
  MESSAGE: {
    label: 'Show Message on Form Submission',
    value: 'MESSAGE'
  }
}
