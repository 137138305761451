<template>
  <div class="form-connection-list-view">
    <form-webhook v-if="webhookLoaded && $route.params.connectionType === 'webhook'" :webhook="webhook"
      :updateMode="true" :formId="parseInt($route.params.id)" v-on:webhookSaved="webhookSaved"></form-webhook>
    <div>
      <div class="form-connection-list-view__body">
        <div class="row">
          <form-create-contact-state-connection
            v-if="contactStateLoaded && $route.params.connectionType === 'contactstate'"
            :contactStateData="contactStateData" :updateMode="true" :formId="parseInt($route.params.id)"
            :id="$route.params.connectionId"></form-create-contact-state-connection>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormWebhook from '@/components/form/integrations/FormWebhook'
import FormCreateContactStateConnection from '@/components/form/integrations/FormCreateContactStateConnection'
export default {
  data: function () {
    return {
      webhookLoaded: false,
      webhook: {},
      contactStateData: {},
      contactStateLoaded: false
    }
  },
  components: {
    'form-webhook': FormWebhook,
    'form-create-contact-state-connection': FormCreateContactStateConnection
  },
  mounted: function () {
    if (this.$route.params.connectionType === 'webhook') {
      this.fetchWebhook()
    } else if (this.$route.params.connectionType === 'contactstate') {
      this.fetchContactState()
    }
  },
  methods: {
    fetchWebhook: function () {
      this.$store.dispatch('formwebhook/fetchFormWebhook', {
        formId: parseInt(this.$route.params.id),
        webhookId: parseInt(this.$route.params.connectionId)
      }).then((response) => {
        this.webhook = response.data.data
        this.webhookLoaded = true
      })
    },
    fetchContactState: function () {
      this.$store.dispatch('formcontactstate/fetchContactState', {
        formId: parseInt(this.$route.params.id),
        connectionId: parseInt(this.$route.params.connectionId)
      })
        .then((response) => {
          this.contactStateData = response.data.data
          this.contactStateLoaded = true
        })
    },
    webhookSaved: function () {
    }
  }
}
</script>
<style lang="scss">
.form-connection-list-view {
  &__notification {
    padding: 15px 0;
  }

  &__header {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
  }

  &__body {
    table {
      padding: 15px 0;

      tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      td {
        padding: 15px 15px;
        min-width: 100px;
      }
    }

    .column {
      float: left;
      width: 25%;
      padding: 10px;
    }

    .row:after {
      display: table;
      clear: both;
    }
  }
}
</style>
