import * as mutationTypes from '@/store/mutation-types'
import * as FormContactStateApi from '@/api/form-contactstate'

const state = {
  contactstates: []
}

const getters = {
  contactstates: function (state) {
    return state.contactstates || []
  }
}

const actions = {
  fetchContactState ({state, commit}, data) {
    commit(mutationTypes.FETCH_CONTACT_STATE)
    return FormContactStateApi.fetchContactState(data.formId, data.connectionId)
  },
  fetchcontactStateSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_CONTACT_STATES_SUCCESS, data)
  },
  createContactState ({state, commit}, data) {
    commit(mutationTypes.CREATE_CONTACT_STATE)
    return FormContactStateApi.createContactState(data)
  },
  createContactStateSuccess ({state, commit}, data) {
    commit(mutationTypes.CREATE_CONTACT_STATE_SUCCESS, data)
  },
  createContactStateFailed ({state, commit}, data) {
    commit(mutationTypes.CREATE_CONTACT_STATE_SUCCESS, data)
  },
  updateContactState ({state, commit}, data) {
    commit(mutationTypes.UPDATE_CONTACT_STATE)
    return FormContactStateApi.updateContactState(data)
  },
  updateContactStateSuccess ({state, commit}, data) {
    commit(mutationTypes.UPDATE_CONTACT_STATE_SUCCESS, data)
  },
  updateContactStateFailed ({state, commit}, data) {
    commit(mutationTypes.UPDATE_CONTACT_STATE_SUCCESS, data)
  },
  deleteContactState ({state, commit}, data) {
    commit(mutationTypes.DELETE_CONTACT_STATE)
    return FormContactStateApi.deleteContactState(data.id)
  },
  deleteContactStateSuccess ({state, commit}, data) {
    commit(mutationTypes.DELETE_CONTACT_STATE_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_CONTACT_STATE] (state, data) {},
  [mutationTypes.FETCH_CONTACT_STATES_SUCCESS] (state, data) {
    state.contactstates = data || []
  },
  [mutationTypes.CREATE_CONTACT_STATE] (state, data) {},
  [mutationTypes.CREATE_CONTACT_STATE_SUCCESS] (state, data) {
    state.contactstates.push(data)
  },
  [mutationTypes.CREATE_CONTACT_STATE_FAILED] (state, data) {},
  [mutationTypes.UPDATE_CONTACT_STATE] (state, data) {},
  [mutationTypes.UPDATE_CONTACT_STATE_SUCCESS] (state, data) {},
  [mutationTypes.UPDATE_CONTACT_STATE_FAILED] (state, data) {},
  [mutationTypes.DELETE_CONTACT_STATE] (state, data) {},
  [mutationTypes.DELETE_CONTACT_STATE_SUCCESS](state, data) {
    const index = state.contactstates.findIndex(gkey => gkey.id === data.id)
    if (index !== -1) {
      state.contactstates.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
