<template>
  <v-container class="form-integration-type-view pt-1">
    <v-row class="align-center justify-space-between mt-n6">
      <v-col cols="12" md="6" class="text-left my-6">
        <router-link :to="{ name: 'forms.detail.connectionstab.list', params: { id: $route.params.id } }"
          class="text-primary-red mb-5 link">
          <v-icon left>mdi-arrow-left</v-icon>
          Back to Connections
        </router-link>
      </v-col>
    </v-row>

    <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
      <v-card-title class="bg-blue-lighten-5 text-primary-blue">
        <h6 class="d-flex justify-center mb-0">
          <v-icon icon="mdi-alpha-c-circle-outline" class="mt-1"></v-icon>
          <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
            Configure Contact State
          </p>
        </h6>
      </v-card-title>
      <v-card-text class="px-10">
        <v-form @submit.prevent="submitContactState" ref="contactStateForm">
          <v-row class="px-2 pt-7">
            <v-col cols="12" md="12" class="pb-0 pt-2">
              <v-text-field :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '3', max: '30' }])"
                v-model="contactState.title" density="comfortable" prepend-inner-icon="mdi-text-short"
                label="Contact State title" variant="outlined"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field :rules="$valid([{ rule: 'required' }])" v-model="contactState.landingpage_id"
                density="comfortable" prepend-inner-icon="mdi-script-text-key" label="Landing page ID"
                variant="outlined"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field :rules="$valid([{ rule: 'required' }])" v-model="contactState.secret_key"
                density="comfortable" prepend-inner-icon="mdi-key" label="Secret Key" variant="outlined"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-2 py-2">
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-switch label="Enable Contact State" inset hide-details color="primary-red"
                v-model="contactState.enable"></v-switch>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-switch hide-details inset color="primary-red" label="Contact State for specific variant"
                v-model="enableForSpecificVariant"></v-switch>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-select :rules="$valid([{ rule: 'required' }])" density="comfortable" variant="outlined"
                v-model="selectedVariant" v-if="enableForSpecificVariant" label="Select Form Variant"
                :items="formVariants" item-text="title" return-object @change="changeFormVariant" chips>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="px-2 py-4">
            <v-col cols="12" md="12">
              <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mt-3">
                <p class="text-black mb-0 border-0">
                  Checkout our <a href="https://leadgenapp.io/help/contact-state-integration-guide/" target="_blank"
                    class="text-info font-weight-bold">Contact State Integration guide <v-icon :size="14"
                      class="font-weight-bold">mdi-open-in-new</v-icon></a> for
                  guidance about this setup.
                </p>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12" class="pb-0 pt-2 text-right">
              <v-btn class="text-none font-weight-bold" type="submit" color="primary-red" prepend-icon="mdi-floppy"
                :loading="submit.submitting">
                Save Contact State
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>

  <v-snackbar rounded="md" height="50" :color="snackbar.error ? 'secondary-red' : 'primary-green'" :timeout="3000"
    v-model="snackbar.show">
    <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
  </v-snackbar>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import { mapGetters } from 'vuex'
import errorTypes from '@/store/error-types'
export default {
  props: {
    updateMode: {
      required: true,
      type: Boolean
    },
    contactStateData: {
      required: false,
      type: Object
    },
    formId: {
      required: false,
      type: Number
    }
  },
  data: function () {
    return {
      snackbar: {
        show: false,
        text: null,
        error: false
      },
      selectedVariant: null,
      submit: {
        submitting: false,
      },
      showHiddenFields: true,
      contactState: {
        title: '',
        enable: true,
        landingpage_id: '',
        secret_key: '',
        form_id: '',
        form_variant_id: ''
      },
      variantStates: [],
      formIdVariants: [],
      loadingVariantState: false,
      enableForSpecificVariant: false,
      checkedNames: []
    }
  },
  mounted: function () {
    if (!this.updateMode) {
      this.contactState = {
        title: '',
        enable: true,
        landingpage_id: '',
        secret_key: '',
        form_id: this.formId,
        form_variant_id: ''
      }
    } else {
      this.contactState = {
        title: this.contactStateData.title,
        enable: Boolean(this.contactStateData.enable),
        form_id: this.contactStateData.form_id,
        landingpage_id: this.contactStateData.landingpage_id,
        form_variant_id: this.contactStateData.form_variant_id,
        secret_key: this.contactStateData.secret_key
      }
      this.selectedVariant = this.contactStateVariant
      if (this.contactStateData.form_variant_id) {
        this.enableForSpecificVariant = true
      }
      this.fetchFormVariantState(this.contactStateData.form_variant_id)
    }
    this.fetchFormVariants()
  },
  methods: {
    submitContactState: async function () {
      this.submit.submitting = true
      const { valid } = await this.$refs.contactStateForm.validate()
      if (!valid) {
        this.showSnackbar(true, 'Please fill all the required fields.')
        this.submit.submitting = false
        return
      }
      let contactState = { ...this.contactState }
      contactState.enable = contactState.enable
      if (!this.enableForSpecificVariant) {
        contactState.form_variant_id = null
      }
      if (this.updateMode) {
        this.updateContactState(contactState)
      } else {
        this.createContactState(contactState)
      }
    },
    createContactState: function (contactState) {
      this.$store.dispatch('formcontactstate/createContactState', contactState)
        .then((response) => {
          this.$store.dispatch('formcontactstate/createContactStateSuccess', response.data.data)
          this.submit.submitting = false
          this.showSnackbar(false, 'Connection created successfully')
          // this.$emit('contactStateSaved')
        }, (response) => {
          this.submit.submitting = false
          this.$store.dispatch('formcontactstate/createContactStateFailed')
          if (response.response.data.meta.error_type === errorTypes.CONTACT_STATE_EXISTS) {
            this.showSnackbar(true, 'There is already an enabled contact state for this form.')
          } else {
            this.showSnackbar(true, 'Unable to update form contactState, please try again')
          }
        })
    },
    updateContactState: function (contactState) {
      this.$store.dispatch('formcontactstate/updateContactState', {
        connectionId: parseInt(this.contactStateData.id),
        contactstate: contactState
      })
        .then((response) => {
          this.$store.dispatch('formcontactstate/updateContactStateSuccess', response.data.data)
          this.submit.submitting = false
          this.showSnackbar(false, 'Connection updated successfully')
          // this.$emit('contactStateSaved')
        }, (response) => {
          this.submit.submitting = false
          if (response.response.data.meta.error_type === errorTypes.CONTACT_STATE_EXISTS) {
            this.showSnackbar(true, 'There is already an enabled contact state for this form.')
          } else {
            this.showSnackbar(true, 'Unable to update form contactState, please try again')
          }
        })
    },
    fetchFormVariants: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS, {
        formId: this.$route.params.id
      }).then((response) => {
        this.formIdVariants = response.data.data
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, {
          formId: this.formId,
          formIdVariants: this.formIdVariants
        })
      })
    },
    fetchFormVariantState: function (variantId) {
      if (!variantId) {
        return
      }
      for (let vs of this.variantStates) {
        if (vs.id === variantId) {
          return
        }
      }
      this.loadingVariantState = true
      this.$store.dispatch(actionTypes.FORM_FETCH_VARIANT_STATE, {
        formId: this.formId,
        variantId: variantId
      }).then((response) => {
        this.variantStates.push(response.data.data)
        this.fetchVariantHiddenFields(variantId)
      })
    },
    fetchVariantHiddenFields: function (variantId) {
      this.$store.dispatch(actionTypes.FETCH_FORM_HIDDEN_FIELDS, {
        formId: this.formId,
        variantId: variantId
      })
        .then((response) => {
          this.$store.dispatch(actionTypes.FETCH_FORM_HIDDEN_FIELDS_SUCCESS, response.data.data)
          this.loadingVariantState = false
        })
    },
    changeFormVariant: function (value) {
      if (!value) {
        return
      }
      this.contactState.form_variant_id = value.id
      this.fetchFormVariantState(value.id)
    },
    variantHiddenFields: function (formId, variantId) {
      return this.$store.getters.formHiddenFields(formId, variantId) || []
    },
    showSnackbar: function (flag, message) {
      this.snackbar.show = true
      this.snackbar.error = flag
      this.snackbar.text = message
    }
  },
  computed: {
    ...mapGetters(['questionTypesMap']),
    contactStates: function () {
      return this.$store.getters['formcontactState/contactStates'] || []
    },
    formVariants: function () {
      return this.$store.getters.getFormVariants(parseInt(this.formId))
    },
    contactStateVariant: function () {
      for (let v of this.formVariants) {
        if (this.contactState.form_variant_id === v.id) {
          return v
        }
      }
      return ''
    },
    contactStateVariantState: function () {
      for (let vs of this.variantStates) {
        if (vs.id === this.contactState.form_variant_id) {
          return vs
        }
      }
      return null
    }
  },
  watch: {
    selectedVariant(newValue) {
      this.changeFormVariant(newValue)
    },
  }
}
</script>
