<template>
  <div class="media-manager">
    <slot :openModal="openModal">
      <v-btn variant="flat" class="mt-2 font-weight-bold text-none text-black" color="grey-lighten-3"
        @click="openModal()"> <v-icon class="pr-1">mdi-image-plus</v-icon> Add Image</v-btn>
    </slot>
    <v-dialog rounded="sm" :max-width="activeTab !== 'uploader' ? 1500 : 1000" v-model="showDialog" @close="close">
      <v-card prepend-icon="mdi-folder-multiple-image">
        <template v-slot:title>
          <strong>Media Library</strong>
        </template>
        <v-divider></v-divider>
        <!-- Use v-sheet to create a scrollable area -->
        <v-sheet max-height="600" class="overflow-y-auto mb-0">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-tabs align-tabs="left" color="primary-red" height="30" slider-color="primary-red"
                  v-model="activeTab">
                  <!-- Tab Headers -->
                  <v-tab color="primary-red" class="text-uppercase text-left font-weight-bold"
                    prepend-icon="mdi-cloud-upload" value="uploader">Upload</v-tab>
                  <v-tab color="primary-red" class="text-uppercase text-left font-weight-bold"
                    prepend-icon="mdi-image-album" value="images">Images</v-tab>
                </v-tabs>
                <!-- Tab Data -->
                <v-tabs-window v-model="activeTab">
                  <v-tabs-window-item value="uploader" class="pt-3">
                    <div class="media-manager__uploader">
                      <media-uploader @uploaded="uploaded"></media-uploader>
                    </div>
                  </v-tabs-window-item>
                  <v-tabs-window-item value="images" class="pt-3">
                    <media-browser :type="mediaTypes.IMAGE" :key="timestamp" @delete="refreshBrowser"></media-browser>
                  </v-tabs-window-item>
                </v-tabs-window>
              </v-col>
            </v-row>
          </v-card-text>
        </v-sheet>
        <!-- Keep v-card-actions outside of scrollable area -->
        <v-divider></v-divider>
        <v-card-actions class="mb-2 text-right">
          <v-row class="mr-5">
            <v-col cols="12">
              <v-btn class="text-none font-weight-bold" rounded="md" variant="flat" color="primary-red"
                v-if="activeTab !== 'uploader'" :disabled="selected.length === 0" @click="choose">Choose</v-btn>
              <v-btn class="text-none font-weight-bold" rounded="md" variant="flat" color="grey-lighten-3"
                @click="cancel">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MediaUploader from '@/components/media/MediaUploader'
import MediaBrowser from '@/components/media/MediaBrowser'
import EventBus from '@/library/event-bus'

export default {
  props: {
    selectedTab: {
      type: String,
      default: 'images'
    }
  },
  components: {
    MediaUploader,
    MediaBrowser
  },
  data: function () {
    return {
      timestamp: null,
      ref: 'mediaManagerModal',
      showDialog: false,
      activeTab: this.selectedTab
    }
  },
  mounted: function () {
    this.updateTimestamp()

    this.$store.dispatch('media/changeTab', {
      tab: this.selectedTab
    }).then(() => {
      //this.$refs.uiTabs.setActiveTab(this.selectedTab + '-tab')
      this.activeTab = this.selectedTab
    })

    if (this.visible) {
      this.openModal()
    }
  },
  methods: {
    openModal: function () {
      //this.$refs[this.ref].open()
      this.showDialog = true
    },
    closeModal: function () {
      //this.$refs[this.ref].close()
      this.showDialog = false
    },
    close: function () {
      EventBus.$off('mm:selected')
      this.$emit('close')
      this.$store.dispatch('media/setVisibility', {
        visible: false
      })
      this.$store.dispatch('media/selectMedia', {
        selected: []
      })
      this.closeModal()
    },
    cancel: function () {
      this.$store.dispatch('media/setVisibility', {
        visible: false
      })
      this.$store.dispatch('media/selectMedia', {
        selected: []
      })
      this.closeModal()
    },
    onTabChange: function (tab) {
      this.$store.dispatch('media/changeTab', {
        tab: tab.id.replace('-tab', '')
      })
    },
    choose: function () {
      EventBus.$emit('mm:selected', this.$store.getters['media/selected'])
      EventBus.$off('mm:selected')
      this.$emit('mm:selected', this.$store.getters['media/selected'])
      this.$store.dispatch('media/setVisibility', {
        visible: false
      }).then(() => {
        this.$store.dispatch('media/selectMedia', {
          selected: []
        })
      })
      this.close()
    },
    uploaded: function (media) {
      if (media.type === this.mediaTypes.IMAGE) {
        this.$store.dispatch('media/resetMedia', {
          mediaType: this.mediaTypes.IMAGE
        }).then(() => {
          this.$store.dispatch('media/selectMedia', {
            selected: [media] || []
          })

          //this.$refs.uiTabs.setActiveTab('images-tab')
          this.activeTab = 'images'
          this.updateTimestamp()
        })
      }
    },
    updateTimestamp: function () {
      this.timestamp = (new Date()).getTime()
    },
    refreshBrowser: function (type) {
      this.$store.dispatch('media/selectMedia', {
        selected: []
      })

      if (type === this.mediaTypes.IMAGE) {
        this.$store.dispatch('media/resetMedia', {
          mediaType: type
        }).then(() => {
          this.updateTimestamp()
        })
      }
    }
  },
  computed: {
    mediaTypes: function () {
      return this.$store.getters['media/types']
    },
    selected: function () {
      return this.$store.getters['media/selected']
    },
    tab: function () {
      return this.$store.getters['media/tab']
    },
    visible: function () {
      return this.$store.getters['media/visible']
    }
  },
  watch: {
    visible: function (to, from) {
      if (to) {
        this.openModal()
      } else {
        this.closeModal()
      }
    }
  }
}
</script>
