export default [
  'password',
  'passw0rd',
  'login',
  'l0gin',
  'log1n',
  'l0g1n',
  'credential',
  'otp',
  '0tp'
]
