<template>
  <v-autocomplete variant="outlined" v-model="selectedIcon" v-bind="$attrs">
    <template v-slot:item="{ props }">
      <v-list-item v-bind="props">
        <template v-slot:prepend v-if="props.value">
          <i class="material-icons mr-4">{{ props.value }} </i>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    modelValue: {
      modelValue: String,
      default: ''
    },
  },
  computed: {
    selectedIcon: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
}
</script>

<style lang="scss" scoped></style>
