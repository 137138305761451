<template>
  <div id="users-list" class="user-list-view container">
    <h4 class="page-title">Users</h4>
    <general-list :fields="fields" :loadedError="loadedError" :loaded="loaded" :listData="listData"
      :listPagination="listPagination" @onListParamsChange="loadUsers($event)">
      <template v-slot:Name="props">
        <router-link class="text-secondary-red" :to="{ name: 'users.edit', params: { id: props.rowData.id } }">{{
          props.rowData.name
        }}</router-link>
      </template>
      <template v-slot:Email="props">
        <div class="email-text mb-1" :class="{ 'status': true, unverified: !props.rowData.verified }">
          {{ props.rowData.email }}
        </div>
        <span style="max-width: 100px;"
          class="status-badge pa-1 font-weight-black text-white bg-orange d-block text-center"
          v-if="!props.rowData.verified">Unverified</span>
      </template>
      <template v-slot:Account="props">
        <div class="pa-1 text-center"
          :class="{ 'active': userStatus(props.rowData) === 'active', 'suspended': userStatus(props.rowData) === 'suspended' }">
          <span class="status-badge font-weight-black text-white">{{
            userStatus(props.rowData) }}</span>
        </div>
      </template>
      <template v-slot:Subscription="props">
        <span class="subscription">
          {{ getTitle(props.rowData) }} <br>
          {{ getStatus(props.rowData) }}
        </span>
      </template>
      <template v-slot:Role="props">
        <span v-html="userRoles(props.rowData.roles)"></span>
      </template>
      <template v-slot:Forms="props">
        {{ props.rowData.forms_count }}
      </template>
      <template v-slot:Leads="props">
        {{ props.rowData.user_leads_count.toLocaleString('en-IN') }}
      </template>
      <template v-slot:[`CreatedAt`]="props">
        {{ localeDate(props.rowData.created_at) }}
      </template>
    </general-list>
  </div>
</template>

<script>
import GeneralList from '@/components/lists/GeneralList'
import { mapGetters } from 'vuex'
import helperMixin from '@/mixins/helper'
import userMixin from '@/mixins/user'

export default {
  mixins: [helperMixin, userMixin],
  components: {
    'general-list': GeneralList
  },
  data: function () {
    return {
      loaded: false,
      loadedError: false,
      listData: [],
      listPagination: {},
      fields: [
        {
          name: 'Name',
          sortField: 'name',
          searchField: 'name'
        },
        {
          name: 'Email',
          sortField: 'email',
          searchField: 'email'
        },
        {
          name: 'Account',
          sortField: 'active'
        },
        {
          name: 'Subscription'
        },
        {
          name: 'Role'
        },
        {
          name: 'Forms',
          sortField: 'forms_count'
        },
        {
          name: 'Leads',
          sortField: 'user_leads_count'
        },
        {
          name: 'CreatedAt',
          sortField: 'created_at'
        }
      ]
    }
  },
  mounted: function () {
    this.loadUsers()
  },
  methods: {
    loadUsers: function (params) {
      if (this.isAdmin) {
        if (!params) {
          return
        }
        params.resource = 'user'
        this.loaded = false
        this.loadedError = false
        this.$store.dispatch('userlists/fetchUserLists', params)
          .then((response) => {
            this.listData = response.data.data
            this.listPagination = response.data.pagination
            this.$store.dispatch('userlists/fetchUserListsSuccess', response.data.data)
            this.loaded = true
          }, () => {
            this.$store.dispatch('userlists/fetchUserListsSuccess', [])
            this.loaded = true
            this.loadedError = true
          })
      }
    },
    userStatus: function (user) {
      return user.active ? 'active' : 'suspended'
    },
    userRoles: function (roles) {
      let rolesArray = []
      for (let role of roles) {
        rolesArray.push(role.name)
      }
      return rolesArray.join('<br>')
    },
    isOnetoolTrial: function (user) {
      if (!user.one_tool_user) {
        return false
      }

      return user.one_tool_user.in_trial
    },
    getTitle: function (user) {
      let data = this.getUserSubscriptionStatus(user)
      if (data && data.includes('(')) {
        return data.split('(')[0]
      }
      return data
    },
    getStatus: function (user) {
      let data = this.getUserSubscriptionStatus(user)
      let newData = ''
      if (data && data.includes('(')) {
        newData = '( ' + data.split('(')[1]
      }
      return newData
    },
    getUserSubscriptionStatus: function (user) {
      if (user.one_tool_user) {
        return `${user.plan.title} ${(user.one_tool_user.in_trial ? '( Trial )' : '')}`
      }

      if (this.hasActiveSubscriptionByUser(user)) {
        let subscriptionStatus = ''

        if (user.plan.subscription.stripe) {
          subscriptionStatus = `( ${user.plan.subscription.stripe_status} )`.toUpperCase()
        } else {
          subscriptionStatus = `( ${user.plan.subscription.status} )`.toUpperCase()
        }
        return `${user.plan.title} ${subscriptionStatus}`
      }

      return 'Free'
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    userLists: function () {
      return this.$store.getters['userLists/getUserLists']
    }
  }
}
</script>
