import * as mutationTypes from '@/store/mutation-types'

const state = {
  verifyEmail: false
}

const getters = {
  showVerifyEmail: (state) => {
    return state.verifyEmail
  }
}

const actions = {
  toggleVisibility({ commit }, data) {
    commit(mutationTypes.MN_TOGGLE_VISIBILITY, data)
  }
}

const mutations = {
  [mutationTypes.MN_TOGGLE_VISIBILITY](state, data) {
    //Vue.set(state, data.key, data.value)
    state[data.key] = data.value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
