<template>
  <div id="form-detail-view">
    <div class="detail-header">
      <div class="container">
        <div class="d-flex justify-space-between align-baseline" v-if="showBreadCrumb">
          <div>
            <router-link class="text-primary-red" :to="{ name: 'forms.index' }"> <v-icon
                icon="mdi-arrow-left"></v-icon>Forms
            </router-link>
          </div>
          <div class="form-title">
            <h4 @click="editForm = true" v-if="!editForm">{{ formTitle }} <v-icon color="primary-red" :size="20"
                icon="mdi-square-edit-outline" class="cursor-pointer"></v-icon> </h4>
            <v-text-field v-else prepend-inner-icon="mdi-text-short" density="comfortable" variant="outlined"
              v-model="formTitle">
              <template v-slot:append>
                <v-icon @click="editForm = false" class="font-weight-bold" color="primary-red"
                  icon="mdi-close-circle"></v-icon>
              </template>
              <template v-slot:append-inner>
                <v-btn @click="updateFormTitle()" class="text-none font-weight-bold" density="comfortable" type="submit"
                  color="primary-red">
                  Update
                </v-btn>
              </template>
            </v-text-field>
            <!-- <h3 @click="editForm = true" v-if="!editForm">{{ form.title }} <i class="material-icons">edit</i></h3>
            <div v-else>
              <v-text-field v-model="formTitle" variant="underlined" placeholder="Title" style="width: 250px">
                <template v-slot:append>
                  <v-progress-circular color="primary-red" v-show="updatingForm"></v-progress-circular>
                  <div v-if="!updatingForm">
                    <v-icon title="Save Title" class="material-icons"
                      @click="updateFormTitle()">mdi-content-save</v-icon>&nbsp;
                    <v-icon title="Cancel" class="material-icons" @click="editForm = false">mdi-close-circle</v-icon>
                  </div>
                </template>
              </v-text-field>
            </div> -->
          </div>
          <div class="col s4 delete-form">
            <v-icon color="primary-red" @click="toggleDeleteDialog()">mdi-delete</v-icon>
            <v-dialog v-model="deleteConfirm" max-width="600px">
              <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
                <template v-slot:title>
                  <strong>Are you sure?</strong>
                </template>
                <v-card-text>
                  <v-alert v-if="deleteError" border type="warning" variant="tonal" density="compact">
                    <p class="text-black ma-0">Unable to delete form Connection.</p>
                  </v-alert>
                  <v-spacer class="my-4"></v-spacer>
                  <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
                    <p class="text-black ma-0">Proceed with caution. It will delete all the related resources that
                      belongs to this
                      form.</p>
                  </v-alert>
                </v-card-text>
                <v-card-actions class="mb-3 mr-4">
                  <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3"
                    text="Cancel" @click="toggleDeleteDialog()">
                  </v-btn>
                  <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
                    :loading="deleting" @click="onConfirmDelete()">
                    <span>Delete</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <!-- experiments breadcrumb -->
        <div v-else-if="$route.name === 'forms.experiments.detail'">
          <div>
            <router-link :to="{ name: 'forms.experiments.overview', params: { id: $route.params.id || 0 } }">
              <v-icon>mdi-arrow-left</v-icon> Form Experiments</router-link>
          </div>
        </div>
        <!-- webhooks breadcrumb -->
        <div v-else-if="$route.name === 'forms.webhooks.edit'">
          <div>
            <router-link :to="{ name: 'forms.webhooks.index', params: { id: $route.params.id || 0 } }">
              <v-icon>mdi-arrow-left</v-icon> Webhooks</router-link>
          </div>
        </div>
        <div v-else-if="$route.name === 'forms.webhooks.create'">
          <div>
            <router-link :to="{ name: 'forms.webhooks.index', params: { id: $route.params.id || 0 } }">
              <v-icon>mdi-arrow-left</v-icon> Webhooks</router-link>
          </div>
        </div>
        <form-connection-tab>
          <router-view></router-view>
        </form-connection-tab>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'
import { mapGetters } from 'vuex'
import * as actionTypes from '@/store/action-types'
import FormConnectionTab from '@/components/FormConnectionTab'
import AppDialog from '../components/AppDialog.vue'
import { size } from 'lodash';
export default {
  data: function () {
    return {
      loadingBuilder: true,
      routeName: 'forms.edit',
      editForm: false,
      formTitle: '',
      updatingForm: false,
      deleteConfirm: false,
      deleteError: false,
    }
  },
  mounted: function () {
    this.routeName = this.$route.name
    this.formTitle = this.form.title; // Initialize formTitle on mount
    // setTimeout(function () {
    //   $('.tabs').tabs()
    // }, 100)
  },
  components: {
    'form-connection-tab': FormConnectionTab,
    AppDialog
  },
  methods: {
    toggleDeleteDialog() {
      this.deleteConfirm = !this.deleteConfirm
    },
    onConfirmDelete: function () {
      this.$store.dispatch(actionTypes.FORM_ARCHIVE, {
        id: this.formId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FORM_ARCHIVE_SUCCESS)
        this.$router.push({ name: 'forms.index' })
      }, (err) => {
        this.$store.dispatch(actionTypes.FORM_ARCHIVE_ERROR)
        this.deleteError = true
      })
    },
    nl2Br: function (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    updateFormTitle: function () {
      this.updatingForm = true
      this.$store.dispatch(actionTypes.UPDATE_FORM, {
        id: this.form.id,
        title: this.formTitle
      }).then(() => {
        this.updatingForm = false
        this.editForm = false
        // this.form.title = this.formTitle
      }, () => {
        this.updatingForm = false
      })
    }
  },
  computed: {
    ...mapGetters([
      'currentFormId',
      'currentFormKey'
    ]),
    formId: function () {
      return parseInt(this.$route.params.id)
    },
    form: function () {
      return this.$store.getters.getForm(this.formId)
      // let form = this.$store.getters.getForm(this.formId)
      // this.formTitle = form.title
      // return form
    },
    formKey: function () {
      return this.form.key
    },
    showBreadCrumb() {
      return [
        'forms.overview',
        'forms.detail.connectionstab.list',
        'forms.detail.connectionstab.create',
        'forms.detail.connectionstab.edit',
        'forms.settings',
        'forms.leads',
        'forms.trackingEvents'
      ].indexOf(this.$route.name) >= 0
    }
  },
  watch: {
    '$route'(to, from) {
      this.routeName = to.name
      // setTimeout(function () {
      //   $('.tabs').tabs()
      // }, 100)
    },
    form(newForm) {
      this.formTitle = newForm.title // Sync formTitle with form.title
    },
  }
}
</script>
