import * as mutationTypes from '@/store/mutation-types'
import * as UserApi from '@/api/user'

const state = {
  userLists: []
}

const getters = {
  getUserLists: function (state) {
    return state.userLists
  }
}

const actions = {
  fetchUserLists ({state, commit}, data) {
    commit(mutationTypes.FETCH_USER_LISTS)
    return UserApi.fetchUserList(data)
  },
  fetchUserListsSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_USER_LISTS_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_USER_LISTS] (state, data) {},
  [mutationTypes.FETCH_USER_LISTS_SUCCESS] (state, data) {
    state.userLists = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
