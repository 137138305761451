<template>
  <v-container class="dashboard-graph-view">
    <!-- Filters Section -->
    <v-row class="flex-column flex-md-row filter-view">
      <!-- Form Select -->
      <v-col cols="12" md="3">
        <v-autocomplete variant="outlined" density="compact" label="Form" placeholder="Select Form" :items="forms"
          v-model="filterParams.forms" multiple @update:modelValue="onFormFilterChange" item-value="key" return-object>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2" size="small" variant="tonal" class="text-caption">
              <span>{{ item.title }}</span>
            </v-chip>
            <span v-if="index === 2" class="text-grey-darken-3 text-caption align-self-center">
              (+{{ filterParams.forms.length - 2 }} others)
            </span>
          </template>
        </v-autocomplete>
      </v-col>

      <!-- Period Select -->
      <v-col cols="12" md="4">
        <v-select variant="outlined" density="compact" chips label="Period" item-title="label" return-object
          :items="periods" v-model="filterParams.period.value"
          @update:modelValue="(value) => onPeriodFilterChange(value)"></v-select>

        <!-- Custom Period Dates -->
        <v-row v-if="filterParams.period.value.value === 'custom'" class="mt-n5">
          <v-col cols="12" class="mt-0 ml-1">
            <v-date-input label="Start Date" placeholder="Select a date" density="compact" variant="outlined"
              prepend-icon="" prepend-inner-icon="$calendar" cancel-text="" max-width="250" hide-details
              v-model="filterParams.period.start_date"
              @update:modelValue="(value) => onPeriodFilterChange(value, true)"></v-date-input>
          </v-col>
          <v-col cols="12" class="mt-0 ml-1">
            <v-date-input label="End Date" placeholder="Select a date" density="compact" variant="outlined"
              prepend-icon="" prepend-inner-icon="$calendar" cancel-text="" max-width="250" hide-details
              :min="filterParams.period.start_date" v-model="filterParams.period.end_date"
              @update:modelValue="(value) => onPeriodFilterChange(value, false)"></v-date-input>
          </v-col>
        </v-row>
        <br>
      </v-col>

      <!-- Compare Period Select -->
      <v-col cols="12" md="4">
        <v-select variant="outlined" density="compact" chips label="Compare With" :items="comparePeriods"
          item-title="label" return-object v-model="filterParams.compare_period.value"
          :disabled="comparePeriods.length <= 1"
          @update:modelValue="(value) => onComparePeriodFilterChange(value)"></v-select>

        <!-- Custom Compare Period Dates -->
        <v-row v-if="filterParams.compare_period.value.value === 'custom'" class="mt-n5">
          <v-col cols="12" class="mt-0 ml-1">
            <v-date-input label="Start Date" placeholder="Select a date" density="compact" variant="outlined"
              prepend-icon="" prepend-inner-icon="$calendar" cancel-text="" max-width="250" hide-details
              v-model="filterParams.compare_period.start_date"
              @update:modelValue="(value) => onComparePeriodFilterChange(value, true)"></v-date-input>
          </v-col>
          <v-col cols="12" class="mt-0 ml-1">
            <v-date-input label="Start Date" placeholder="Select a date" density="compact" variant="outlined"
              prepend-icon="" prepend-inner-icon="$calendar" cancel-text="" max-width="250" hide-details
              :min="filterParams.compare_period.start_date" v-model="filterParams.compare_period.end_date"
              @update:modelValue="(value) => onComparePeriodFilterChange(value, false)"></v-date-input>
          </v-col>
        </v-row>
        <br>
      </v-col>

      <!-- Action Buttons -->
      <v-col cols="12" md="1" class="d-flex justify-center justify-md-start gc-1 mt-4 mt-md-0">
        <v-btn v-if="filterParamsChanged" icon="mdi-filter-variant" color="secondary-red" size="small"
          title="Apply Filter" @click="applyFilter" />
        <v-btn v-else icon="mdi-refresh" color="secondary-red" size="small" variant="flat" title="Reset Filter"
          @click="applyFilter" />
      </v-col>
    </v-row>

    <!-- Indicators Section -->
    <v-row class="flex-column flex-md-row justify-center gc-2 indicator-view">

      <!-- Views Card -->
      <v-card rounded="md" min-height="160" width="160">
        <v-card-title class="text-body-2 text-grey-darken-2 text-center">
          Views
        </v-card-title>
        <v-card-text class="text-center" v-if="!initialized">
          <v-progress-circular indeterminate color="error" :size="30" :width="3">
          </v-progress-circular>
        </v-card-text>
        <v-card-text class="text-body-2 text-grey-darken-3 text-center" v-else>
          <div class="graph-value">
            <span class="d-block font-weight-bold text-grey-darken-4 text-h5">{{ viewsResponse.total }}</span>
            <span class="d-flex justify-center" v-if="viewsResponse.total_percentage_change">
              <v-chip :color="viewsResponse.total_percentage_change >= 0 ? 'success' : 'error'" size="small"
                class="ml-2">
                <v-icon size="small">
                  {{ viewsResponse.total_percentage_change >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                </v-icon>
                {{ Math.abs(viewsResponse.total_percentage_change) }}%
              </v-chip>
            </span>
          </div>
          <div class="graph-indicator">
            <canvas id="views-indicator-chart"></canvas>
          </div>
        </v-card-text>
      </v-card>

      <!-- Leads Card -->
      <v-card rounded="md" min-height="160" width="160">
        <v-card-title class="text-body-2 text-grey-darken-2 text-center">
          Leads
        </v-card-title>
        <v-card-text class="text-center" v-if="!initialized">
          <v-progress-circular indeterminate color="error" :size="30" :width="3">
          </v-progress-circular>
        </v-card-text>
        <v-card-text class="text-body-2 text-grey-darken-3 text-center" v-else>
          <div class="graph-value">
            <span class="d-block font-weight-bold text-grey-darken-4 text-h5">{{ leadsResponse.total }}</span>
            <span class="d-flex justify-center" v-if="leadsResponse.total_percentage_change">
              <v-chip :color="leadsResponse.total_percentage_change >= 0 ? 'success' : 'error'" size="small"
                class="ml-2">
                <v-icon size="small">
                  {{ leadsResponse.total_percentage_change >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                </v-icon>
                {{ Math.abs(leadsResponse.total_percentage_change) }}%
              </v-chip>
            </span>
          </div>
          <div class="graph-indicator">
            <canvas id="leads-indicator-chart"></canvas>
          </div>
        </v-card-text>
      </v-card>

      <!-- Partial Leads Card -->
      <v-card rounded="md" min-height="160" width="160">
        <v-card-title class="text-body-2 text-grey-darken-2 text-center">
          Partial Leads
        </v-card-title>
        <v-card-text class="text-center" v-if="!initialized">
          <v-progress-circular indeterminate color="error" :size="30" :width="3">
          </v-progress-circular>
        </v-card-text>
        <v-card-text class="text-center" v-if="showPartialActivation">
          <p class="text-body-2 text-grey-darken-1 mb-2">Collect responses before form submission</p>
          <v-btn color="secondary-red" variant="elevated" size="small" rounded="md" class="text-none font-weight-bold"
            :to="{ name: 'partialleads' }">
            Activate
          </v-btn>
        </v-card-text>
        <v-card-text class="text-body-2 text-grey-darken-3 text-center" v-else>
          <div class="graph-value">
            <span class="d-block font-weight-bold text-grey-darken-4 text-h5">{{ partialLeadsResponse.total }}</span>
            <span class="d-flex justify-center" v-if="partialLeadsResponse.total_percentage_change">
              <v-chip :color="partialLeadsResponse.total_percentage_change >= 0 ? 'success' : 'error'" size="small"
                class="ml-2">
                <v-icon size="small">
                  {{ partialLeadsResponse.total_percentage_change >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                </v-icon>
                {{ Math.abs(partialLeadsResponse.total_percentage_change) }}%
              </v-chip>
            </span>
          </div>
          <div class="graph-indicator">
            <canvas id="partial-leads-indicator-chart"></canvas>
          </div>
        </v-card-text>
      </v-card>

      <!-- Conversion Rate Card -->
      <v-card rounded="md" min-height="160" width="160">
        <v-card-title class="text-body-2 text-grey-darken-2 text-center">
          Conversion Rate
        </v-card-title>
        <v-card-text class="text-center" v-if="!initialized">
          <v-progress-circular indeterminate color="error" :size="30" :width="3">
          </v-progress-circular>
        </v-card-text>
        <v-card-text class="text-body-2 text-grey-darken-3 text-center" v-else>
          <div class="graph-value">
            <span class="d-block font-weight-bold text-grey-darken-4 text-h5">{{ crResponse.total }}</span>
            <span class="d-flex justify-center" v-if="crResponse.total_percentage_change">
              <v-chip :color="crResponse.total_percentage_change >= 0 ? 'success' : 'error'" size="small" class="ml-2">
                <v-icon size="small">
                  {{ crResponse.total_percentage_change >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                </v-icon>
                {{ Math.abs(crResponse.total_percentage_change) }}%
              </v-chip>
            </span>
          </div>
          <div class="graph-indicator">
            <canvas id="cr-indicator-chart"></canvas>
          </div>
        </v-card-text>
      </v-card>

      <!-- Completion Time Card -->
      <v-card rounded="md" min-height="160" width="160">
        <v-card-title class="text-body-2 text-grey-darken-2 text-center">
          Completion Time
        </v-card-title>
        <v-card-text class="text-center" v-if="!initialized">
          <v-progress-circular indeterminate color="error" :size="30" :width="3">
          </v-progress-circular>
        </v-card-text>
        <v-card-text class="text-body-2 text-grey-darken-3 text-center" v-else>
          <div class="graph-value">
            <span class="d-block font-weight-bold text-grey-darken-4 text-h5">{{ ctResponse.total }}</span>
            <span class="d-flex justify-center" v-if="ctResponse.total_percentage_change">
              <v-chip :color="ctResponse.total_percentage_change >= 0 ? 'success' : 'error'" size="small" class="ml-2">
                <v-icon size="small">
                  {{ ctResponse.total_percentage_change >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                </v-icon>
                {{ Math.abs(ctResponse.total_percentage_change) }}%
              </v-chip>
            </span>
          </div>
          <div class="graph-indicator">
            <canvas id="ctr-indicator-chart"></canvas>
          </div>
        </v-card-text>
      </v-card>
    </v-row>

    <!-- Graph Body Section -->
    <v-row class="flex-column flex-md-row justify-center gc-5 graph-body-view">
      <general-chart :initialized="initialized" :generalWidget="generalWidget"></general-chart>
    </v-row>
  </v-container>
</template>

<script>
import widgetTypes from '@/store/dashboard-widget-types'
import _ from 'lodash'
import moment from 'moment'
import Chart from 'chart.js'
import GeneralChart from '@/components/dashboard/widgets/general/Chart'
import { VDateInput } from 'vuetify/labs/VDateInput'

export default {
  data: function () {
    return {
      loading: true,
      // showFilters: true,
      cacheMinutes: 15 * 60 * 1000,
      filterParamsChanged: false,
      filtersSet: false,
      filterParams: {
        forms: null,
        period: {
          value: { label: 'Today', value: 'today' },
          start_date: new Date(),
          end_date: new Date()
        },
        compare_period: {
          value: { label: '- None -', value: '' },
          start_date: new Date(),
          end_date: new Date()
        }
      }
    }
  },
  components: {
    'general-chart': GeneralChart,
    VDateInput
  },
  mounted: function () {
    let generalWidgetCached = localStorage.getItem('general_widget')

    if (!generalWidgetCached || typeof generalWidgetCached !== 'object') {
      this.fetchWidgetState()
    } else {
      this.$store.dispatch('dashboard/fetchDashboardWidgetSuccess', generalWidgetCached)
        .then(() => {
          this.loading = false
          this.setFilters()
          setTimeout(() => this.initializeIndicatorCharts(), 100)
        })
    }
  },
  methods: {
    onFormFilterChange: function () {
      if (this.filtersSet) {
        this.filterParamsChanged = true
      }
    },
    onPeriodFilterChange: function (value, periodStart) {
      if (!value) return

      if (periodStart && value > this.filterParams.period.end_date) {
        this.filterParams.period.end_date = value
      }

      if (this.filtersSet) {
        this.filterParamsChanged = true
      }

      this.filterParams.compare_period.value = { label: '- None -', value: '' }
    },
    onComparePeriodFilterChange: function (value, periodStart) {
      if (!value) return

      if (periodStart && value > this.filterParams.compare_period.end_date) {
        this.filterParams.compare_period.end_date = value
      }

      if (this.filtersSet) {
        this.filterParamsChanged = true
      }
    },
    applyFilter: function () {
      if (!this.filterParams) return

      let filterParams = {
        forms: { ids: [] },
        period: {}
      }

      // Set forms
      if (this.filterParams.forms && this.filterParams.forms.length > 0) {
        filterParams.forms.ids = this.filterParams.forms.map(f => f.key || '')
      }

      // Set period
      if (this.filterParams.period) {
        filterParams.period = {
          value: this.filterParams.period.value.value || '',
          start_date: '',
          end_date: ''
        }

        if (filterParams.period.value === 'custom') {
          filterParams.period.start_date = moment(this.filterParams.period.start_date).format('YYYY-MM-DD') || ''
          filterParams.period.end_date = moment(this.filterParams.period.end_date).format('YYYY-MM-DD') || ''
        }
      }

      // Set compare period
      if (this.filterParams.compare_period.value?.value) {
        filterParams.compare_period = {
          value: this.filterParams.compare_period.value.value || '',
          start_date: '',
          end_date: ''
        }

        if (filterParams.compare_period.value === 'custom') {
          filterParams.compare_period.start_date = moment(this.filterParams.compare_period.start_date).format('YYYY-MM-DD') || ''
          filterParams.compare_period.end_date = moment(this.filterParams.compare_period.end_date).format('YYYY-MM-DD') || ''
        }
      }

      this.fetchWidgetState(filterParams)
      this.filterParamsChanged = false
    },
    fetchWidgetState: function (filterParams) {
      let data = {
        widget: {
          type: 'general',
          params: {}
        }
      }

      if (filterParams) {
        data.widget.params.filter_params = filterParams
      }

      this.loading = true
      this.$store.dispatch('dashboard/fetchDashboardWidget', data).then((response) => {
        localStorage.setItem('general_widget', JSON.stringify(response.data.data), this.cacheMinutes)
        this.$store.dispatch('dashboard/fetchDashboardWidgetSuccess', response.data.data || {})
          .then(() => {
            this.loading = false
            this.setFilters()
            setTimeout(() => this.initializeIndicatorCharts(), 100)
          })
      })
    },
    setFilters: function () {
      // set forms filter
      const formIds = this.generalWidget.filter_params.forms.ids
      this.filterParams.forms = this.forms.reduce((carry, form) => {
        if (formIds.indexOf(form.key) >= 0) {
          carry.push({ title: form.title, key: form.key })
        }
        return carry
      }, [])

      // set period filter
      const period = _.find(this.periods, { value: this.generalWidget.filter_params.period.value })
      this.filterParams.period.value = { label: period.label, value: period.value }

      const periodStart = this.generalWidget.filter_params.period.start_date
      if (periodStart) {
        this.filterParams.period.start_date = moment(periodStart).toDate()
      }

      const periodEnd = this.generalWidget.filter_params.period.end_date
      if (periodEnd) {
        this.filterParams.period.end_date = moment(periodEnd).toDate()
      }

      if (!this.generalWidget.filter_params.compare_period) {
        this.filtersSet = true
        return
      }

      // set compare period filter
      const comparePeriod = _.find(this.comparePeriods, {
        value: this.generalWidget.filter_params.compare_period.value
      })
      this.filterParams.compare_period.value = { label: comparePeriod.label, value: comparePeriod.value }

      const comparePeriodStart = this.generalWidget.filter_params.compare_period.start_date
      if (comparePeriodStart) {
        this.filterParams.compare_period.start_date = moment(comparePeriodStart).toDate()
      }

      const comparePeriodEnd = this.generalWidget.filter_params.compare_period.end_date
      if (comparePeriodEnd) {
        this.filterParams.compare_period.end_date = moment(comparePeriodEnd).toDate()
      }

      this.filtersSet = true
    },
    abs: function (value) {
      return Math.abs(value)
    },
    toMinutesAndSeconds: function (value) {
      let valueParts = value.toString().split('.')
      valueParts[0] = parseInt(valueParts[0]) + 'm'
      valueParts[1] = parseFloat('0.' + valueParts[1])
      valueParts[1] = parseFloat(valueParts[1] * 60).toFixed(0) + 's'
      return valueParts[0] + ' ' + valueParts[1]
    },
    initializeIndicatorCharts: function () {
      this.initializeViewsIndicatorChart()
      this.initializeLeadsIndicatorChart()
      this.initializeCRIndicatorChart()
      this.initializePartialLeadsIndicatorChart()
    },
    initializeViewsIndicatorChart: function () {
      let data = this.viewsResponse.value?.map(v => v.visits) || []
      let labels = this.viewsResponse.value?.map(v => v.visits_created_at) || []
      const ctx = document.getElementById('views-indicator-chart')
      this.createIndicatorChart(ctx, data, labels)
    },
    initializeLeadsIndicatorChart: function () {
      let data = this.leadsResponse.value?.map(v => v.leads) || []
      let labels = this.leadsResponse.value?.map(v => v.leads_created_at) || []
      const ctx = document.getElementById('leads-indicator-chart')
      this.createIndicatorChart(ctx, data, labels)
    },
    initializePartialLeadsIndicatorChart: function () {
      let data = this.partialLeadsResponse.value?.map(v => v.leads) || []
      let labels = this.partialLeadsResponse.value?.map(v => v.leads_created_at) || []
      const ctx = document.getElementById('partial-leads-indicator-chart')
      this.createIndicatorChart(ctx, data, labels)
    },
    initializeCRIndicatorChart: function () {
      let data = this.crResponse.value?.map(v => v.conversion_rate) || []
      let labels = this.crResponse.value?.map(v => v.conversions_rate_created_at) || []
      const ctx = document.getElementById('cr-indicator-chart')
      this.createIndicatorChart(ctx, data, labels)
    },
    createIndicatorChart: function (ctx, data, labels) {
      if (ctx) {
        return (new Chart(ctx, {
          type: 'line',
          data: {
            datasets: [
              {
                backgroundColor: 'rgba(238, 110, 115, 0.1)',
                strokeColor: 'rgba(238, 110, 115, 1)',
                data: data
              }
            ],
            labels: labels
          },
          options: {
            responsive: false,
            legend: {
              display: false
            },
            elements: {
              line: {
                borderColor: 'rgb(238, 110, 115)',
                borderWidth: 1
              },
              point: {
                radius: 0
              }
            },
            tooltips: {
              enabled: false
            },
            scales: {
              yAxes: [
                {
                  display: false,
                  ticks: {
                    beginAtZero: true
                  }
                }
              ],
              xAxes: [
                {
                  display: false
                }
              ]
            }
          }
        }))
      }
    }
  },
  computed: {
    showPartialActivation: function () {
      return !localStorage.getItem('activated')
    },
    initialized: function () {
      return this.generalWidget && !this.loading
    },
    generalWidget: function () {
      return this.$store.getters['dashboard/getWidget'](widgetTypes.GENERAL) || {}
    },
    forms: function () {
      if (!this.generalWidget || !this.generalWidget.filters) {
        return []
      }

      return this.generalWidget.filters.forms.map(form => ({
        title: form.title || '',
        key: form.id || ''
      })).sort((a, b) => a.key - b.key)
    },
    periods: function () {
      if (!this.generalWidget || !this.generalWidget.filters) {
        return []
      }

      return this.generalWidget.filters.periods || []
    },
    comparePeriods: function () {
      let period = _.find(this.periods, { value: this.filterParams.period.value.value })

      if (period && !_.find(period.compare, { value: '' })) {
        period.compare.unshift({ label: '- None -', value: '' })
      }

      return period ? period.compare : []
    },
    viewsResponse: function () {
      return this.generalWidget?.response?.views || { total: 0, total_percentage_change: 0, value: [] }
    },
    leadsResponse: function () {
      return this.generalWidget?.response?.leads || { total: 0, total_percentage_change: 0, value: [] }
    },
    partialLeadsResponse: function () {
      return this.generalWidget?.response?.partial_leads || { total: 0, total_percentage_change: 0, value: [] }
    },
    crResponse: function () {
      return this.generalWidget?.response?.conversion_rate || { total: 0, total_percentage_change: 0, value: [] }
    },
    ctResponse: function () {
      return this.generalWidget?.response?.completion_time || { total: 0, total_percentage_change: 0, value: [] }
    }
  }

}
</script>
