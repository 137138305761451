<template>
  <v-container class="form-connection-list-view pt-1" v-if="!$route.params.connectionType">
    <v-row class="align-center justify-space-between mt-n6">
      <v-col cols="12" md="6" class="text-left my-6">
        <router-link :to="{ name: 'forms.detail.connectionstab.list', params: { id: $route.params.id } }"
          class="text-primary-red mb-5 link">
          <v-icon left>mdi-arrow-left</v-icon>
          Back to Connections
        </router-link>
      </v-col>
    </v-row>
    <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
      <v-card-title class="bg-blue-lighten-5 text-primary-blue">
        <h6 class="d-flex justify-center mb-0">
          <v-icon icon="mdi-connection" class="mt-1"></v-icon>
          <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
            Add New Integration
          </p>
        </h6>
      </v-card-title>
      <v-card-text class="px-10">
        <v-row class="py-10">
          <v-col v-for="connection in connections" :key="connection.type" cols="12" md="3">
            <!-- Webhooks and Contact State Tooltip -->
            <v-tooltip v-if="userMixin_hasFeatureAccess(featureSlugs.TRUSTEDFORM) || connection.type !== 'trustedform'"
              :text="connection.tooltip" location="bottom">
              <template v-slot:activator="{ props }">
                <v-img v-bind="props" :src="connection.image" width="100%" height="100"
                  class="cursor-pointer border-thin" cover rounded="lg" @click="navigateToConnection(connection)" />
              </template>
            </v-tooltip>

            <!-- Locked TrustedForm with Tooltip and Overlay -->
            <v-tooltip v-if="!userMixin_hasFeatureAccess(featureSlugs.TRUSTEDFORM) && connection.type === 'trustedform'"
              :text="connection.lockMessage" location="bottom">
              <template v-slot:activator="{ props }">
                <v-img v-bind="props" :src="connection.lockImage" width="100%" height="100"
                  class="cursor-not-allowed border-thin" cover rounded="lg" />
                <!-- Overlay for Locked TrustedForm -->
                <v-overlay absolute :value="true" opacity="0.7">
                  <v-row align="center" justify="center">
                    <v-col class="text-center white--text">
                      <v-icon small>mdi-lock</v-icon>
                    </v-col>
                  </v-row>
                </v-overlay>
              </template>
            </v-tooltip>

            <p class="text-center font-weight-bold mt-2">
              {{ connection.name }}
              <v-icon v-if="!userMixin_hasFeatureAccess(featureSlugs.TRUSTEDFORM) && connection.type === 'trustedform'"
                small>mdi-lock</v-icon>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>

  <v-container>
    <form-webhook :updateMode="false" :formId="parseInt($route.params.id)" v-on:webhookSaved="webhookSaved"
      v-if="$route.params.connectionType === 'webhook'"></form-webhook>

    <form-create-contact-state-connection :updateMode="false" :formId="parseInt($route.params.id)"
      v-if="$route.params.connectionType === 'contact-state'"></form-create-contact-state-connection>

    <trusted-form v-if="$route.params.connectionType === this.featureSlugs.TRUSTEDFORM"></trusted-form>
  </v-container>
</template>

<script>
import FormWebhook from '@/components/form/integrations/FormWebhook'
import FormCreateContactStateConnection from '@/components/form/integrations/FormCreateContactStateConnection'
import TrustedForm from '../components/form/integrations/TrustedForm.vue'
import userMixin from '@/mixins/user'
import { featureSlugs } from '@/shared/constants/packagebuilder/features'

export default {
  props: {
    formId: {
      type: Number,
      required: true
    }
  },
  mixins: [userMixin],
  components: {
    'form-webhook': FormWebhook,
    'form-create-contact-state-connection': FormCreateContactStateConnection,
    TrustedForm
  },
  data() {
    return {
      connections: [
        {
          name: 'Webhooks',
          type: 'webhook',
          image: require('@/assets/images/icons/webhooks-new.png'),
          path: 'forms.detail.connectionstab.create',
          tooltip: 'Create a webhook Integration'
        },
        {
          name: 'Contact State',
          type: 'contact-state',
          image: require('@/assets/images/icons/contact-state.jpg'),
          path: 'forms.detail.connectionstab.create',
          tooltip: 'Create a Contact State Integration'
        },
        {
          name: 'Trustedform',
          type: 'trustedform',
          image: require('@/assets/images/icons/TrustedFormNew.jpg'),
          lockImage: require('@/assets/images/icons/Trustedformlock.png'),
          lockMessage: 'Please upgrade to Enterprise in order to use this integration.',
          path: 'forms.detail.connectionstab.create',
          tooltip: 'Create a Trustedform Integration'
        },
      ],
    }
  },
  methods: {
    webhookSaved: function () {
      this.$router.push({
        name: 'forms.webhooks.index',
        params: { id: this.$route.params.id, created: 'success' }
      })
    },
    navigateToConnection(connectionData) {
      if (!this.userMixin_hasFeatureAccess(this.featureSlugs.TRUSTEDFORM) && connectionData.type === 'trustedform') {
        return
      }
      const path = {
        name: 'forms.detail.connectionstab.create',
        params: { id: this.$route.params.id, connectionType: connectionData.type },
      }
      this.$router.push(path)
    }
  },
  computed: {
    featureSlugs: function () {
      return featureSlugs
    }
  }
}
</script>
