<template>
  <v-container class="form-webhook-component pt-1">
    <v-row class="align-center justify-space-between mt-n6">
      <v-col cols="12" md="6" class="text-left my-6">
        <router-link :to="{ name: 'forms.detail.connectionstab.list', params: { id: $route.params.id } }"
          class="text-primary-red mb-5 link">
          <v-icon left>mdi-arrow-left</v-icon>
          Back to Connections
        </router-link>
      </v-col>
    </v-row>

    <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
      <v-card-title class="bg-blue-lighten-5 text-primary-blue">
        <h6 class="d-flex justify-center mb-0">
          <v-icon icon="mdi-webhook" class="mt-1"></v-icon>
          <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
            {{ updateMode ? 'Update' : 'Create' }} Webhook
          </p>
        </h6>
      </v-card-title>
      <v-card-text class="px-10">
        <v-form @submit.prevent="submitWebhook" ref="webhookForm">
          <v-row class="px-2 pt-7">
            <v-col cols="12" md="6" class="pb-0 pt-2">
              <v-text-field v-model="webhookForm.title"
                :rules="$valid([{ rule: 'required', fieldName: 'Webhook title' }, { rule: 'between', min: '3', max: '30' }])"
                variant="outlined" prepend-inner-icon="mdi-text-short" density="comfortable"
                label="Webhook Title"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pb-0 pt-2">
              <v-text-field
                :rules="$valid([{ rule: 'required', fieldName: 'Webhook Url' }, { rule: 'url', fieldName: 'Webhook URl' }])"
                v-model="webhookForm.url" variant="outlined" prepend-inner-icon="mdi-link" density="comfortable"
                label="Webhook Url"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select v-model="webhookForm.method" variant="outlined" hide-details :items="webhookMethods"
                prepend-inner-icon="mdi-code-array" density="comfortable" label="Select Webhook Method"
                chips></v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select v-model="webhookForm.format" variant="outlined" hide-details :items="webhookFormats"
                prepend-inner-icon="mdi-code-json" density="comfortable" label="Select Webhook Payload Format"
                chips></v-select>
            </v-col>
            <v-col cols="12" md="6" class="pt-7">
              <v-text-field v-model="secret.key" variant="outlined" hide-details prepend-inner-icon="mdi-key"
                density="comfortable" label="Webhook Secret Key"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-7">
              <v-text-field v-model="secret.value" hide-details variant="outlined"
                prepend-inner-icon="mdi-script-text-key" density="comfortable"
                label="Webhook Secret Value"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-2 py-2" v-for="(header, counter) in headers" v-bind:key="counter">
            <v-col cols="12" md="5" class="pb-0 pt-2">
              <v-text-field variant="outlined" v-model="header.key" hide-details prepend-inner-icon="mdi-key"
                density="comfortable" label="Webhook Header Key"></v-text-field>
            </v-col>
            <v-col cols="12" md="5" class="pb-0 pt-2">
              <v-text-field variant="outlined" v-model="header.value" hide-details
                prepend-inner-icon="mdi-script-text-key" density="comfortable"
                label="Webhook Header value"></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="pb-0 pt-2">
              <v-btn color="primary-red" icon="mdi-delete" @click="deleteHeader(counter)" variant="text"></v-btn>
            </v-col>
          </v-row>
          <v-row class="px-2 py-2">
            <v-col cols="12" md="5" class="pb-0 pt-2">
              <v-btn class="text-none font-weight-bold" color="primary-green" prepend-icon="mdi-plus"
                @click="addHeader">Header</v-btn>
            </v-col>
          </v-row>
          <v-row class="px-2 py-2">
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-switch label="Enable Webhook" inset hide-details color="primary-red"
                v-model="webhookForm.enable"></v-switch>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-switch hide-details inset color="primary-red" label="Webhook For Specific Variant"
                v-model="enableForSpecificVariant"></v-switch>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <v-select density="comfortable" variant="outlined" v-model="selectedVariant"
                v-if="enableForSpecificVariant" label="Select Form Variant" :items="formVariants" item-text="title"
                return-object @change="changeFormVariant" :rules="$valid([{ rule: 'required' }])" chips>
              </v-select>
            </v-col>
          </v-row>

          <!-- FIELDS MAP -->
          <v-row class="px-2 py-2" v-if="enableForSpecificVariant">
            <v-col cols="12" md="4" class="pb-0 pt-2">
              <h5 class="text-primary-blue font-weight-bold">Fields Map</h5>
            </v-col>
            <v-col cols="12" md="12" class="pb-0 pt-2 text-center" v-if="loadingVariantState">
              <v-progress-circular model-value="40" :size="60" :width="3" indeterminate
                color="secondary-red"></v-progress-circular>
            </v-col>
          </v-row>
          <v-row class="px-2 py-2" v-if="!loadingVariantState">
            <v-col cols="12" md="4" class="py-0" v-for="(fp, index) in webhookForm.fields_map" :key="index">
              {{ fp.questionId ? `#${index + 1} ${fp.title} (${fp.from})` : `#${index + 1} ${fp.title} (Hidden Field)`
              }}
              <v-text-field density="comfortable" variant="outlined" color="black"
                :rules="$valid([{ rule: 'required' }, { rule: 'alphaNumDashSpace' }])" :name="fp.from" v-model="fp.to"
                :hint="hasDuplicatedFieldName(fp) ? 'Existing field has the same name' : ''">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-2 py-4">
            <v-col cols="12" md="12">
              <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mt-3">
                <p class="text-black mb-0 border-0">
                  Checkout our <a
                    href="https://leadgenapp.io/help/integrations-guide-how-to-integrate-leadgen-via-webhooks/"
                    target="_blank" class="text-info font-weight-bold">Webhook Integration guide <v-icon :size="14"
                      class="font-weight-bold">mdi-open-in-new</v-icon></a> for
                  guidance about this setup.
                </p>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12" class="pb-0 pt-2 text-right">
              <v-btn class="text-none font-weight-bold mt-4" type="submit" color="primary-red" prepend-icon="mdi-floppy"
                :loading="submit.submitting">
                Save Webhook
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>

  <v-snackbar rounded="md" height="50" :color="snackbar.error ? 'secondary-red' : 'primary-green'" :timeout="3000"
    v-model="snackbar.show">
    <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
  </v-snackbar>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import { mapGetters } from 'vuex'
import WebhookFormats from '@/store/modules/formwebhook/webhook-formats'
import WebhookMethods from '@/store/modules/formwebhook/webhook-methods'
export default {
  emits: ['webhookSaved'],  // Declare the emitted event
  props: {
    updateMode: {
      required: true,
      type: Boolean
    },
    webhook: {
      required: false,
      type: Object
    },
    formId: {
      required: false,
      type: Number
    }
  },
  data: function () {
    return {
      snackbar: {
        show: false,
        text: null,
        error: false
      },
      selectedVariant: null,
      submit: {
        submitting: false,
      },
      showHiddenFields: true,
      webhookForm: {
        title: '',
        enable: true,
        method: WebhookMethods.POST,
        format: WebhookFormats.FORM_URLENCODED,
        url: '',
        form_variant_id: '',
        fields_map: []
      },
      headers: [{
        key: '',
        value: ''
      }],
      secret: {
        key: '',
        value: ''
      },
      variantStates: [],
      loadingVariantState: false,
      enableForSpecificVariant: false
    }
  },
  mounted: function () {
    if (!this.updateMode) {
      this.webhookForm = {
        title: '',
        enable: true,
        method: WebhookMethods.POST,
        format: WebhookFormats.FORM_URLENCODED,
        url: '',
        form_variant_id: '',
        fields_map: null
      }
    } else {
      this.webhookForm = {
        title: this.webhook.title,
        enable: this.webhook.enable === 1 ? true : false,
        method: this.webhook.method,
        format: this.webhook.format,
        url: this.webhook.url,
        fields_map: [],
        form_variant_id: this.webhook.form_variant_id
      }
      this.selectedVariant = this.webhookFormVariant
      if (this.webhook.form_variant_id) {
        this.enableForSpecificVariant = true
      }
      if (this.webhook.secret) {
        var secret = this.webhook.secret
        var obj = JSON.parse(secret)
        this.secret.key = obj.key
        this.secret.value = obj.value
      }
      if (this.webhook.headers) {
        var headers = this.webhook.headers
        this.headers = JSON.parse(headers)
      }
      this.fetchFormVariantState(this.webhook.form_variant_id)
    }
    this.fetchFormVariants()
  },
  methods: {
    submitWebhook: async function () {
      if (this.submit.submitting) {
        return
      }
      this.submit.submitting = true
      const { valid } = await this.$refs.webhookForm.validate()
      if (!valid) {
        if (this.hasInvalidFieldName()) {
          this.showSnackbar(true, 'Invalid field names found.')
          this.submit.submitting = false
          return
        }
        if (this.hasDuplicateCalculatorFieldName) {
          let fieldName = this.webhookFormVariantState.calculator.fieldName
          this.showSnackbar(true, 'Field name "' + fieldName + '" already used for calculator.')
          this.submit.submitting = false
          return
        }
        this.showSnackbar(true, 'Please fill all the required fields.')
        this.submit.submitting = false
        return
      }

      if (this.hasDuplicatedFields()) {
        this.showSnackbar(true, 'Duplicate field names found.')
        this.submit.submitting = false
        return
      }
      let webhook = { ...this.webhookForm }
      // Handle empty fields_map
      if (!webhook.fields_map) {
        webhook.fields_map = null
      } else {
        webhook.fields_map = webhook.fields_map.map((fp) => {
          if (fp.questionId) {
            return { from: fp.from, to: fp.to, questionId: fp.questionId }
          } else if (fp.hiddenFieldId) {
            return { from: fp.from, to: fp.to, hiddenFieldId: fp.hiddenFieldId }
          }
        })
      }
      webhook.headers = this.headers
      webhook.secret = this.secret
      webhook.enable = webhook.enable
      if (!this.enableForSpecificVariant) {
        webhook.form_variant_id = null
      }
      if (this.updateMode) {
        this.updateWebhook(webhook)
      } else {
        this.createWebhook(webhook)
      }
    },
    createWebhook: function (webhook) {
      this.$store.dispatch('formwebhook/createFormWebhook', {
        formId: parseInt(this.formId),
        webhook: webhook
      })
        .then((response) => {
          this.$store.dispatch('formwebhook/createFormWebhookSuccess', response.data.data)
          this.submit.submitting = false
          this.showSnackbar(false, 'Webhook Created Successfully')
        }, () => {
          this.showSnackbar(true, 'Unable to create Form Webhook, please try again')
          this.submit.submitting = false

        })
    },
    updateWebhook: function (webhook) {
      this.$store.dispatch('formwebhook/updateFormWebhook', {
        formId: parseInt(this.formId),
        webhookId: parseInt(this.webhook.id),
        webhook: webhook
      })
        .then((response) => {
          this.submit.submitting = false
          this.showSnackbar(false, 'Webhook Updated Successfully')
        }, () => {
          this.showSnackbar(true, 'Unable to update Form Webhook, please try again')
          this.submit.submitting = false

        })
    },
    fetchFormVariants: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS, {
        formId: this.formId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response.data.data)
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, {
          formId: this.formId
        })
      })
    },
    fetchFormVariantState: function (variantId) {
      if (!variantId) {
        return
      }
      for (let vs of this.variantStates) {
        if (vs.id === variantId) {
          this.setFieldsMap()
          return
        }
      }
      this.loadingVariantState = true
      this.$store.dispatch(actionTypes.FORM_FETCH_VARIANT_STATE, {
        formId: this.formId,
        variantId: variantId
      }).then((response) => {
        this.variantStates.push(response.data.data)
        this.fetchVariantHiddenFields(variantId)
      })
    },
    fetchVariantHiddenFields: function (variantId) {
      this.$store.dispatch(actionTypes.FETCH_FORM_HIDDEN_FIELDS, {
        formId: this.formId,
        variantId: variantId
      })
        .then((response) => {
          this.$store.dispatch(actionTypes.FETCH_FORM_HIDDEN_FIELDS_SUCCESS, response.data.data)
          this.setFieldsMap()
          this.loadingVariantState = false
        })
    },
    changeFormVariant: function (value) {
      if (!value) {
        return
      }
      this.webhookForm.form_variant_id = value.id
      this.fetchFormVariantState(value.id)
    },
    setFieldsMap: function () {
      let variantState = null
      for (let vs of this.variantStates) {
        if (vs.id === this.webhookForm.form_variant_id) {
          variantState = vs
          break
        }
      }
      if (!variantState) {
        return
      }
      this.webhookForm.fields_map = []
      let sIndex = 1
      for (let step of variantState.steps) {
        let qIndex = 1
        for (let question of (step.questions || [])) {
          if (
            question.type !== this.questionTypesMap.GDPR ||
            (question.type === this.questionTypesMap.GDPR && question.enabled)
          ) {
            this.webhookForm.fields_map.push({
              title: question.title,
              from: 'S' + sIndex + '_' + 'Q' + qIndex,
              to: this.toFieldName(sIndex, qIndex, variantState, question),
              questionId: question.dbId
            })
          }
          qIndex++
        }
        sIndex++
      }
      for (let hiddenField of this.variantHiddenFields(this.formId, this.webhookForm.form_variant_id)) {
        this.webhookForm.fields_map.push({
          title: hiddenField.name,
          from: hiddenField.name,
          to: this.toHiddenFieldName(hiddenField),
          hiddenFieldId: hiddenField.id
        })
      }
    },
    hasDuplicatedFieldName: function (fieldMap) {
      let count = 0
      for (let fp of this.webhookForm.fields_map) {
        if (fieldMap.to === fp.to) {
          count++
        }
        if (count >= 2) {
          return true
        }
      }
      return false
    },
    hasDuplicatedFields: function () {
      if (!this.webhookForm.fields_map) {
        return
      }
      let toArray = this.webhookForm.fields_map.map((m) => m.to)
      return toArray.some((to, i) => {
        return toArray.indexOf(to) !== i
      })
    },
    hasInvalidFieldName: function () {
      if (!this.webhookForm.fields_map) {
        return
      }
      for (let field of this.webhookForm.fields_map) {
        if (!/^[ 0-9A-Z_-]*$/i.test(field.to)) {
          return true
        }
      }
      return false
    },
    toFieldName: function (sIndex, qIndex, variantState, question) {
      if (this.updateMode && variantState.id === this.webhook.form_variant_id) {
        for (let fieldMap of this.webhook.fields_map) {
          if (fieldMap.from === 'S' + sIndex + '_Q' + qIndex) {
            return fieldMap.to
          }
        }
      } else {
        return question.field_name ? question.field_name : 'S' + sIndex + '_' + 'Q' + qIndex
      }
    },
    toHiddenFieldName: function (hiddenField) {
      if (this.updateMode) {
        for (let fieldMap of (this.webhook.fields_map || [])) {
          if (fieldMap.hiddenFieldId === '' + hiddenField.id) {
            return fieldMap.to
          }
        }
      }
      return hiddenField.name
    },
    variantHiddenFields: function (formId, variantId) {
      return this.$store.getters.formHiddenFields(formId, variantId) || []
    },
    addHeader: function () {
      this.headers.push({
        key: '',
        value: ''
      })
    },
    deleteHeader: function (counter) {
      this.headers.splice(counter, 1)
    },
    showSnackbar: function (flag, message) {
      this.snackbar.show = true
      this.snackbar.error = flag
      this.snackbar.text = message
    }
  },
  computed: {
    ...mapGetters(['questionTypesMap']),
    webhooks: function () {
      return this.$store.getters['formwebhook/webhooks'] || []
    },
    webhookMethods: function () {
      let methods = []
      let storeMethods = this.$store.getters['formwebhook/webhookMethods']
      for (let i in storeMethods) {
        methods.push(storeMethods[i])
      }
      return methods || []
    },
    webhookFormats: function () {
      let formats = []
      let storeFormats = this.$store.getters['formwebhook/webhookFormats']
      for (let i in storeFormats) {
        formats.push(storeFormats[i])
      }
      return formats || []
    },
    formVariants: function () {
      return this.$store.getters.getFormVariants(parseInt(this.formId))
    },
    webhookFormVariant: function () {
      for (let v of this.formVariants) {
        if (this.webhookForm.form_variant_id === v.id) {
          return v
        }
      }
      return ''
    },
    webhookFormVariantState: function () {
      for (let vs of this.variantStates) {
        if (vs.id === this.webhookForm.form_variant_id) {
          return vs
        }
      }
      return null
    },
    hasDuplicateCalculatorFieldName: function () {
      if (!this.webhookFormVariantState) {
        return
      }
      let calculator = this.webhookFormVariantState.calculator
      let toArray = this.webhookForm.fields_map.map((m) => m.to)
      if (!calculator.fieldName) {
        return false
      }
      if (toArray.indexOf(calculator.fieldName) >= 0) {
        return true
      }
      return false
    }
  },
  watch: {
    selectedVariant(newValue) {
      this.changeFormVariant(newValue)
    },
  },
}
</script>
