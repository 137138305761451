<template>
  <v-container style="width:90%">
    <h4 class="page-title">Packages</h4>
    <v-row>
      <v-col class="text-end mb-n10">
        <v-btn rounded="md" color="primary-red" class="text-none font-weight-bold" prepend-icon="mdi-plus"
          @click="$router.push({ name: 'package-builder.create' })">
          Create Package</v-btn>
      </v-col>
    </v-row>
    <div>
      <general-list :fields="fields" :loadedError="list.loadError" :loaded="list.loaded" :listData="listData"
        :listPagination="listPagination" @onListParamsChange="loadTemplates($event)">
        <template v-slot:Title="props">
          <router-link class="text-secondary-red"
            :to="{ name: 'package-builder.edit', params: { id: props.rowData.id } }">
            {{ props.rowData.title }}
          </router-link>
        </template>
        <template v-slot:Description="props">
          {{ props.rowData.description }}
        </template>
        <template v-slot:Plan="props">
          {{ props.rowData.plan.title }}
        </template>
      </general-list>
    </div>
  </v-container>
</template>

<script>
import GeneralList from '@/components/lists/GeneralList'
import { resourceTypes } from '@/shared/constants/generalList/list'
import { mapGetters } from 'vuex'

export default {
  components: {
    'general-list': GeneralList
  },
  data: function () {
    return {
      list: {
        loaded: false,
        loadError: false
      },
      listData: [],
      listPagination: {},
      fields: [
        {
          name: 'Title',
          sortField: 'title',
          searchField: 'title'
        },
        {
          name: 'Description'
        },
        {
          name: 'Plan'
        }
      ]
    }
  },
  mounted: function () {
    this.loadTemplates()
  },
  methods: {
    loadTemplates: function (params) {
      if (this.isAdmin) {
        if (!params) {
          return
        }
        params.resource = resourceTypes.PACKAGE_BUILDER
        this.list.loaded = false
        this.list.loadError = false
        this.$store.dispatch('packagebuilder/getPackageLists', params)
          .then((response) => {
            this.$store.dispatch('packagebuilder/getPackageListsSuccess', response.data.data)
            this.listData = response.data.data
            this.listPagination = response.data.pagination
            this.list.loadError = false
          }, (response) => {
            this.list.loadError = true
          })
        this.list.loaded = true
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  }
}
</script>
