import { deleteData, getData, postData } from '../plugins/ApiCalls'

export function fetchMedia(mediaType, data) {
  return getData('media?page=' + data.page, {
    params: {
      'mediaType': mediaType
    }
  })
}

export function uploadMedia(data, progress) {
  return postData('media', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    uploadProgress: progress
  })
}

export function deleteMedia(id) {
  return deleteData('media/' + id)
}
