import * as mutationTypes from '@/store/mutation-types'
import * as FormTemplateBuilderApi from '@/api/form-templates'

const state = {
  formTemplateList: []
}

const getters = {
  getFormTemplates: function (state) {
    return state.formTemplatesList
  }
}

const actions = {
  getFormTemplateList ({ state, commit }, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_LISTS)
    return FormTemplateBuilderApi.getFormTemplateList(data)
  },
  getFormTemplateListSuccess ({ state, commit }, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_LISTS_SUCCESS, data)
  },
  deleteFormTemplate ({ state, commit }, data) {
    commit(mutationTypes.DELETE_FORM_TEMPLATE)
    return FormTemplateBuilderApi.deleteFormTemplate(data.id)
  },
  deleteFormTemplateSuccess ({ state, commit }, data) {
    commit(mutationTypes.DELETE_FORM_TEMPLATE_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_FORM_TEMPLATE_LISTS] (state) {

  },
  [mutationTypes.FETCH_FORM_TEMPLATE_LISTS_SUCCESS] (state, data) {
    state.formTemplatesList = data || []
  },
  [mutationTypes.DELETE_FORM_TEMPLATE_SUCCESS] (state, data) {
    const index = state.formTemplatesList.findIndex(item => item.id === data.id)
    if (index > -1) {
      state.formTemplatesList.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
