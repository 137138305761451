export default {
  'environment': 'dev',
  'basePath': window.location.protocol + '//api.dev-leadgen.apexure.org/api',
  'recaptchaSiteKey': '6Le6gTIqAAAAADy40OUCf0PH2x-TmoCiGsKKPsDS',
  'pagesUrl': window.location.protocol + '//pages.dev-leadgen.apexure.org',
  'formsUrl': window.location.protocol + '//forms.dev-leadgen.apexure.org',
  'proofsUrl': window.location.protocol + '//proofs.dev-leadgen.apexure.org',
  'scriptsUrl': window.location.protocol + '//scripts.dev-leadgen.apexure.org',
  'stripePublicKey': 'pk_test_YFqP01U3tLiimhs24cmtaDT8',
  'sampleCsvUrl': window.location.protocol + '//' + window.location.host + '/static/sample/SampleTemplate.csv',
  'utmUrl': window.location.protocol + '//scripts.dev-leadgen.apexure.org',
  'paddle': {
    'vendorId': 1189,
    'trialPlanId': 23499,
    'trialScaleId': 32674,
    'trialEnterpriseId': 32676,
    'trialAnnualProId': 32672,
    'trialAnnualScaleId': 32675,
    'trialAnnualEnterpriseId': 32677,
    'planId': 8687,
    'scaleId': 8694,
    'enterpriseId': 32678,
    'annualProId': 15628,
    'annualScaleId': 15629,
    'annualEnterpriseId': 32679,
    'singlePlanId': 47202
  },
  'registration': {
    'recaptchaEnabled': true
  }
}
