import * as mutationTypes from '@/store/mutation-types'
import * as FormApi from '@/api/form'

const state = {
  resetFormStatus: false
}

const getters = {
  //
}

const actions = {
  resetFormStatus ({state, commit}, data) {
    commit(mutationTypes.FM_RESET_FORM_STATUS)
    return FormApi.resetFormStatus(data.id)
  },
  resetFormStatusSuccess: (context, data) => {
    context.commit(mutationTypes.FM_RESET_FORM_STATUS_SUCCESS, data)
  },
  resetFormStatusError: (context, data) => {
    context.commit(mutationTypes.FM_RESET_FORM_STATUS_ERROR, data)
  }
}

const mutations = {
  [mutationTypes.FM_RESET_FORM_STATUS_SUCCESS]  (state, data) {
    state.resetFormStatus = true
  },
  [mutationTypes.FM_RESET_FORM_STATUS_ERROR] (state, data) {
    state.resetFormStatus = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
