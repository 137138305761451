<template>
  <v-container class="form-builder-share fill-height">
    <v-row class="align-center justify-center mt-10">
      <v-col cols="12" md="10">
        <v-card rounded="md">
          <v-card-text>
            <v-tabs v-model="tab" color="primary-red" height="60" slider-color="primary-red" class="px-10">
              <v-tab v-for="item in tabs" :key="item.value" :value="item.value" color="primary-red"
                class="text-none text-left font-weight-bold">
                <v-icon start>{{ item.icon }}</v-icon>
                {{ item.text }}
              </v-tab>
            </v-tabs>
            <v-window v-model="tab">
              <!-- direct embed code -->
              <v-window-item value="direct-embed">
                <v-row class="mt-5 px-10">
                  <v-col>
                    <v-alert border type="info" variant="tonal" rounded="md" density="compact" class="mb-6"
                      icon="mdi-information">
                      <p class="text-black mb-0">Embed below code in your website.</p>
                    </v-alert>
                    <v-card color="grey-darken-4" class="overflow-hidden" rounded="md">
                      <v-card-text class="position-relative">
                        <v-btn icon="mdi-content-copy" title="Copy Direct Embed Code" size="small" color="grey"
                          class="position-absolute top-0 right-0 my-3 mx-2" @click="copyCode()"></v-btn>
                        <v-sheet color="transparent" class="overflow-x-auto pl-2 pr-6">
                          <pre><code v-html="highlightedCode" class="text-wrap text-pre-wrap"></code></pre>
                        </v-sheet>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="px-10">
                  <v-col></v-col>
                  <v-col cols="6" class="d-flex gc-5 justify-end">
                    <!-- set form width when full width toggle is off -->
                    <v-text-field variant="outlined" density="compact" type="number" label="Width (PX)" active
                      :model-value="formPreview.width" @update:model-value="setIframeWidth"
                      v-if="!formPreview.fullWidthForm"
                      :rules="$valid([{ rule: 'number' }, { rule: 'required' }])"></v-text-field>
                    <!-- full width toggle -->
                    <v-switch color="secondary-red" title="Enable/Disable full width form" label="Full width form"
                      v-model="formPreview.fullWidthForm"
                      @update:model-value="setFullWidthToggle(formPreview.fullWidthForm)"></v-switch>
                  </v-col>
                </v-row>
              </v-window-item>
              <!-- iframe embed code -->
              <v-window-item value="iframe">
                <v-row class="mt-5 px-10">
                  <v-col>
                    <v-alert border type="info" variant="tonal" rounded="md" density="compact" class="mb-6"
                      icon="mdi-information">
                      <p class="text-black mb-0">Embed below Iframe code in your website.</p>
                    </v-alert>
                    <v-card color="grey-darken-4" class="overflow-hidden" rounded="md">
                      <v-card-text class="position-relative">
                        <v-btn icon="mdi-content-copy" title="Copy Iframe Code" size="small" color="grey"
                          class="position-absolute top-0 right-0 my-3 mx-2" @click="copyCode()"></v-btn>
                        <v-sheet color="transparent" class="overflow-x-auto pl-2 pr-6">
                          <pre><code v-html="highlightedCode" class="text-wrap text-pre-wrap"></code></pre>
                        </v-sheet>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="mt-5 px-10">
                  <v-col class="d-flex gc-2 justify-end">
                    <!-- set form height -->
                    <v-text-field variant="outlined" density="comfortable" type="number" label="Height (PX)" active
                      v-model="formPreview.height" @update:model-value="setIframeHeight"
                      :rules="$valid([{ rule: 'number' }, { rule: 'required' }])"></v-text-field>
                    <!-- set form width -->
                    <v-text-field variant="outlined" density="comfortable" type="number" label="Width (PX)" active
                      v-model="formPreview.width" @update:model-value="setIframeWidth"
                      :rules="$valid([{ rule: 'number' }, { rule: 'required' }])"></v-text-field>
                    <!-- form shadow toggle -->
                    <v-switch color="secondary-red" title="Enable/Disable shadow to a form"
                      label="Enable/Disable shadow to form" v-model="formPreview.formShadow"
                      @update:model-value="setFormShadow(formPreview.formShadow)"></v-switch>
                  </v-col>
                </v-row>
              </v-window-item>
              <!-- share code -->
              <v-window-item value="share">
                <v-row class="mt-5 px-10">
                  <v-col>
                    <v-text-field label="Share link" variant="outlined" density="comfortable" hide-details
                      prepend-inner-icon="mdi-share" v-model="sharedLink" color="black">
                      <template v-slot:append-inner>
                        <v-icon color="black" @click="copyCode()" title="Copy Share Link">
                          mdi-content-copy
                        </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="px-10 mb-3">
                  <v-col class="d-flex justify-end gc-1">
                    <!-- Twitter -->
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <a v-bind="props"
                          :href="'https://twitter.com/intent/tweet?text=' + encodeURIComponent(sharedLink)"
                          target="_blank" class="mr-2">
                          <v-icon size="x-large" color="primary-red" class="cursor-pointer">mdi-twitter</v-icon>
                        </a>
                      </template>
                      <span>Share on Twitter</span>
                    </v-tooltip>

                    <!-- Facebook -->
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <a v-bind="props"
                          :href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(sharedLink)"
                          target="_blank" class="mr-2">
                          <v-icon size="x-large" color="primary-red" class="cursor-pointer">mdi-facebook</v-icon>
                        </a>
                      </template>
                      <span>Share on Facebook</span>
                    </v-tooltip>

                    <!-- LinkedIn -->
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props }">
                        <a v-bind="props"
                          :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(sharedLink)"
                          target="_blank">
                          <v-icon size="x-large" color="primary-red" class="cursor-pointer">mdi-linkedin</v-icon>
                        </a>
                      </template>
                      <span>Share on LinkedIn</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar rounded="md" height="50" :color="snackbar.color" :timeout="5000" v-model="snackbar.show">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import * as actionTypes from '@/store/action-types'
import config from '@/api/config'
import Prism from 'prismjs'

export default {
  data: function () {
    return {
      tab: 'direct-embed', // Set initial tab
      tabs: [
        {
          icon: 'mdi-code-block-braces',
          text: 'Direct Embed',
          value: 'direct-embed',
        },
        {
          icon: 'mdi-application-braces-outline',
          text: 'Iframe',
          value: 'iframe',
        },
        {
          icon: 'mdi-share-variant',
          text: 'Share',
          value: 'share',
        },
      ],
      recaptcha: {
        selectedKey: {},
        enable: false
      },
      snackbar: {
        show: false,
        text: null,
        color: null
      },
      formSetting: null
    }
  },
  mounted: function () {
    this.fetchForm()
    this.getIframeHeight()
    this.getIframeWidth()
    this.getFormShadow()
    this.getFullWidthToggle()
  },
  methods: {
    copyCode: function () {
      let text
      switch (this.tab) {
        case 'direct-embed':
          text = this.directEmbedCode
          break
        case 'iframe':
          text = this.iframeCode
          break
        case 'share':
          text = this.sharedLink
          break
        default:
          text = ''
      }

      navigator.clipboard.writeText(text).then(() => {
        let text = this.tab === 'share' ? 'Link' : 'Code'
        let message = text + ' copied to clipboard.'
        this.showSnackbar(message, false)
      }).catch(err => {
        this.showSnackbar('Failed to copy.', true)
      })
    },
    showSnackbar(text, isError) {
      this.snackbar = {
        show: true,
        text: text,
        color: isError ? 'secondary-red' : 'primary-green'
      }
    },
    fetchForm: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM, {
        id: this.$route.params.id
      }).then((response) => {
        this.fetchFormSetting(response?.data?.data?.id)
        this.$store.dispatch(actionTypes.FETCH_FORM_SUCCESS, response?.data?.data)
      }, (err) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_ERROR, {
          formId: this.$route.params.id
        })
      })
    },
    fetchFormSetting: function (formId) {
      this.$store.dispatch(actionTypes.FORM_FETCH_SETTING, {
        formId: formId
      }).then((response) => {
        this.formSetting = response?.data?.data
        this.recaptcha.enable = this.formSetting.enable_google_recaptcha
      })
    },
    getIframeHeight: function () {
      const { id, variantId } = this.$route.params
      const compositeKey = `form_${id}_variant_${variantId}_dimensions`

      const storedDimensions = localStorage.getItem(compositeKey)

      if (storedDimensions) {
        const dimensions = JSON.parse(storedDimensions)
        this.formPreview.height = dimensions.height
        return dimensions.height || 400
      } else {
        return this.formPreview.height || 400
      }
    },
    getIframeWidth: function () {
      const { id, variantId } = this.$route.params
      const compositeKey = `form_${id}_variant_${variantId}_dimensions`
      const storedDimensions = localStorage.getItem(compositeKey)
      if (storedDimensions) {
        const dimensions = JSON.parse(storedDimensions)
        this.formPreview.width = dimensions.width
        return dimensions.width || 500
      } else {
        return this.formPreview.width || 500
      }
    },
    setIframeHeight: function (value) {
      this.$store.dispatch('FORM_UPDATE_IFRAME_HEIGHT', {
        height: value
      })
    },
    setIframeWidth: function (value) {
      this.$store.dispatch('FORM_UPDATE_IFRAME_WIDTH', {
        width: value
      })
    },
    getFormShadow: function () {
      const { id, variantId } = this.$route.params
      const compositeKey = `form_${id}_variant_${variantId}_dimensions`
      const storedDimensions = localStorage.getItem(compositeKey)
      if (storedDimensions) {
        const dimensions = JSON.parse(storedDimensions)
        this.formPreview.formShadow = dimensions.formShadow
        return dimensions.formShadow || false
      } else {
        return this.formPreview.formShadow || false
      }
    },
    setFormShadow: function (value) {
      this.$store.dispatch('FORM_UPDATE_IFRAME_FORM_SHADOW', {
        formShadow: value
      })
    },
    getFullWidthToggle: function () {
      const { id, variantId } = this.$route.params
      const compositeKey = `form_${id}_variant_${variantId}_dimensions`
      const storedDimensions = localStorage.getItem(compositeKey)
      if (storedDimensions) {
        const dimensions = JSON.parse(storedDimensions)
        this.formPreview.fullWidthForm = dimensions.fullWidthForm
        return dimensions.fullWidthForm || false
      } else {
        return this.formPreview.fullWidthForm || false
      }
    },
    setFullWidthToggle: function (value) {
      this.$store.dispatch('FORM_FULL_WIDTH_FORM', {
        fullWidthForm: value
      })
    }
  },

  computed: {
    ...mapGetters([
      'formPreview',
      'currentFormKey'
    ]),
    sharedLink: function () {
      return config.formsUrl + '/' + this.currentFormKey
    },
    iframeSource: function () {
      return config.formsUrl + '/' + this.currentFormKey + '/iframe'
    },
    iframeCode: function () {
      let boxShadow = 'none'
      if (this.formPreview.formShadow) {
        boxShadow = '11px 17px 50px 0px #727eb721'
      }
      return `<script src="` + config.formsUrl + `/js/handler.js/` + this.currentFormKey + `"></scri` + `pt>
      <iframe data-leadgen-form-key="` + this.currentFormKey + `" onload="onLeadgenFormIframeLoad(this)" style="display:block;border:0px;width:100%;max-width:` + this.formPreview.width + `px;margin:auto;height:` + this.formPreview.height + `px;box-shadow:` + boxShadow + `;" frameborder="0" src="` + this.iframeSource + `" scrolling="auto"></iframe>`
    },
    directEmbedCode: function () {
      let str = '<script type="text/javascript" src="' + config.formsUrl + '/js/lf.min.js/' + this.currentFormKey + '" async></scri' + 'pt>'
      str += '<div id="leadgen-form-wrap-' + this.currentFormKey + '" style="' + this.codeStyle + '"><leadgen-form-' + this.currentFormKey + '></leadgen-form-' + this.currentFormKey + '></div>'
      return str
    },
    codeStyle: function () {
      let width = this.formPreview.width + 'px'
      if (this.formPreview.fullWidthForm) {
        width = '100%'
      }
      return 'display:block;border:0;width:100%;max-width:' + width + ';margin:0 auto;'
    },
    highlightedCode() {
      let text = this.tab === 'direct-embed' ? this.directEmbedCode : this.iframeCode
      return Prism.highlight(text, Prism.languages.html, 'html')
    },
    formId: function () {
      return parseInt(this.$route.params.id)
    },
    form: function () {
      return this.$store.getters.getForm(this.formId) || {}
    },
    currentFormKey: function () {
      return this.form.key || 0
    },
    apiOrigin: function () {
      return (new URL(config.basePath)).origin
    },
    fullWidthForm: {
      get() {
        return this.$store.state.fullWidthForm
      },
      set(value) {
        this.$store.dispatch('FORM_FULL_WIDTH_FORM', value)
      }
    }
  },
  watch: {
    fullWidthForm(newValue) {
      localStorage.setItem('FORM_FULL_WIDTH_FORM', JSON.stringify(newValue))
    }
  }
}
</script>
