<template>
  <div
    :class="{'form-theme-store-cpt__themes__item': true}" class="theme-item">
    <div :class="{'form-theme-store-cpt__themes__item__design': true, 'form-theme-store-cpt__themes__item__design--active': selectedTheme ? theme.id === selectedTheme.id : false}" v-if="theme.type === 'DEFAULT'" @click="() => selectTheme(theme)" class="theme-preview">
      <img class="theme-img" :src="!this.themeImage ? themeImg : this.themeImage" alt="Theme image">
      <div class="theme-preview-footer">
        <p>{{theme.title}}</p>
      </div>
    </div>
      <div v-if="theme.type === 'CUSTOM'" :class="{'form-theme-store-cpt__themes__item__design': true, 'form-theme-store-cpt__themes__item__design--active': selectedTheme ? theme.id === selectedTheme.id : false}" :style="`background-color:${theme.config.ui_elements.background.formColor}`" @click="() => selectTheme(theme)">
        <div :class="{'form-theme-store-cpt__themes__item__design__progress': true, hide: !showProgressBar}" :style="progressStyle"></div>
        <div>
          <div class="form-theme-store-cpt__themes__item__design__name" :style="`color:${theme.config.general.colors.text_color};font-family:${theme.config.general.font.family}`">{{theme.title}}</div>
        </div>
        <div>
          <span class="form-theme-store-cpt__themes__item__design__prev" :style="backButtonStyle">Back</span>
          <span class="form-theme-store-cpt__themes__item__design__next" :style="nextButtonStyle">Next</span>
        </div>
      </div>
  </div>
</template>

<script>
import config from '@/api/config'
import themeImg from '@/assets/images/theme-image.jpg'
export default {
  props: {
    theme: {
      type: Object,
      required: true
    },
    selectedTheme: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      themeImg,
      image: ''
    }
  },
  methods: {
    selectTheme (theme) {
      this.$emit('selectTheme', theme)
    }
  },
  computed: {
    uiElements () {
      return this.theme.config.ui_elements
    },
    themeImage: function () {
      if (!this.theme.theme_image) {
        return
      }
      return config.basePath + '/media/' + this.theme.theme_image.ref_id + '/' + this.theme.theme_image.name
    },
    backButtonStyle () {
      return {
        'background-color': this.uiElements.step_navigation.back_button.backgroundColor,
        'color': this.uiElements.step_navigation.back_button.font.color,
        'border-color': this.uiElements.step_navigation.back_button.backgroundColor,
        'border-radius': `${this.uiElements.step_navigation.back_button.borderRadius}px`,
        'font-family': this.uiElements.step_navigation.back_button.font.family
      }
    },
    nextButtonStyle () {
      return {
        'background-color': this.uiElements.step_navigation.next_button.backgroundColor,
        'color': this.uiElements.step_navigation.next_button.font.color,
        'border-color': this.uiElements.step_navigation.next_button.backgroundColor,
        'border-radius': `${this.uiElements.step_navigation.next_button.borderRadius}px`,
        'font-family': this.uiElements.step_navigation.next_button.font.family
      }
    },
    progressStyle () {
      return {
        'background-image': `
        linear-gradient(to right, ${this.uiElements.step_progress.config.fill_color} 50%, ${this.uiElements.step_progress.config.stroke_color} 50%)
        `
      }
    },
    showProgressBar () {
      return this.uiElements.step_progress.showProgress
    }
  }
}
</script>
<style lang="scss">
.theme-preview {
    border-radius: 5px;
    // max-width: 307px;
    width: fit-content;
    background: #EDF4F9;
    transition: all .3s,opacity .45s;
    &:hover {
      box-shadow: 11px 1px 50px 0px #727eb76e;
    }
    .theme-img {
      width: 300px;
      height: 168px;
      border-radius: 5px 5px 0 0;
    }
    @media only screen and (min-width: 1100px) {
    .theme-img {
      width: 19vw; 
      // min-width: 289px;
      // max-width: 700px;
      object-fit: cover;
    }
  }
  .theme-preview-footer {
    padding-left: 14px;
    height: 31px;
    > p {
      font-weight: bold;
    }
  }
}
@media only screen and (min-width: 1400px) {
  .theme-item {
    margin-right: 2%;
  }
}
</style>
