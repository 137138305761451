<template>
  <v-container style="width: 85%;" class="subscription-view">
    <v-row align="center" justify="space-between">
      <v-col cols="12" sm="4">
        <h2 class="text-h5">Plans</h2>
      </v-col>
      <v-col cols="12" sm="8" class="d-flex justify-end align-center">
        <span class="mr-2">Monthly</span>
        <v-switch v-model="yearlyToggle" color="secondary-blue" base-color="secondary-blue" hide-details
          dense></v-switch>
        <span class="ml-2">Yearly (Save 20%)</span>
      </v-col>
    </v-row>
    <!-- alerts -->
    <v-row>
      <!-- info alerts -->
      <!-- cancelled alert -->
      <v-col class="mb-10" cols="12" v-if="hasCancelledSubscription || userMixin_hasPausedSubscriptionInGrace">
        <v-alert type="info" variant="tonal" rounded="md" density="compact" icon="mdi-alert-decagram"
          class="mb-1 mt-5 text-black">
          <span class="text-black mb-0">
            Your subscription is currently in grace period. Your grace period will expire on
            <strong>{{ hasCancelledSubscription ? localeDate(subscription.ends_at) :
              localeDate(subscription.paused_from) }}</strong>. After the grace period all your forms will be disabled
            automatically.
          </span>
        </v-alert>
      </v-col>
      <!-- trial alert -->
      <v-col class="mb-10" cols="12" v-if="userMixin_hasActiveFreeTrialPlan && !userMixin_planExpired">
        <v-alert type="info" variant="tonal" rounded="md" density="compact" icon="mdi-alert-decagram"
          class="mb-1 mt-5 text-black">
          <span class="text-black mb-0">
            Your have
            {{ (userMixin_trialDaysLeft === 1) ? userMixin_trialDaysLeft + ' Day' : userMixin_trialDaysLeft + ' Days' }}
            left on trial. To keep access to your account and grow
            your business with new lead generation campaigns, subscribe to any
            LeadGen plan and you're ready to go.
          </span>
        </v-alert>
      </v-col>
      <!-- account expired alert -->
      <v-col class="mb-10" cols="12" v-else-if="userMixin_hasActiveFreeTrialPlan && userMixin_planExpired">
        <v-alert type="error" variant="tonal" rounded="md" density="compact" icon="mdi-alert-decagram"
          class="mb-1 mt-5 text-black">
          <span class="text-black mb-0"> Your account is currently inactive. To access your forms and grow
            your business with new lead generation campaigns, subscribe to any
            LeadGen plan and you're ready to go.
          </span>
        </v-alert>
      </v-col>
    </v-row>

    <!-- monthly & yearly plans -->
    <v-row align="center" justify="center" no-gutters dense>
      <v-col cols align-self="center" class="mb-4 px-1" v-for="(fp, index) in filteredPlans" :key="index">
        <v-card class="text-center rounded-t-lg rounded-b-md" :min-height="fp.isPopular ? 630 : 570" hover
          max-width="335">
          <div v-if="fp.isPopular" class="bg-purple-accent-2 rounded-t-lg font-weight-bold text-capitalize">
            Most Popular
          </div>
          <!-- plan title -->
          <v-card-title class="font-weight-bold text-center text-white"
            :class="[fp.titleClass, fp.isPopular ? 'rounded-0' : 'rounded-t-lg']">
            {{ fp.title }}
          </v-card-title>
          <!-- plan price monthly-->
          <v-card-subtitle class="text-left mt-5 mb-1 pb-0">
            <div class="ml-2">
              <span v-if="yearlyToggle"
                class="d-block text-grey-darken-4 font-weight-normal text-decoration-line-through text-body-2">
                ${{ fp.originalPrice }}/month
              </span>
              <span class="text-grey-darken-3 font-weight-normal text-h6">$</span>
              <span class="text-grey-darken-4 font-weight-bold text-h5">{{ fp.price }}</span>
              <span class="text-grey-darken-3 font-weight-normal text-h6">/month</span>
              <span v-if="yearlyToggle" class="text-primary-green font-weight-bold text-body-1 float-right mt-1 mr-3">
                Save ${{ calculateSavings(fp.price, fp.originalPrice) }}
              </span>
            </div>
            <small class="text-grey-darken-1 billed ml-2">Billed {{ yearlyToggle ? 'Yearly' : 'Monthly' }}</small>
          </v-card-subtitle>

          <!--  -->
          <!-- YEARLY PLAN BUTTONS STARTS-->
          <!--  -->
          <v-card-text class="font-weight-normal text-left ml-2 py-2" v-if="yearlyToggle">
            <!--  -->
            <!-- SINGLE YEARLY PLAN BUTTONS START -->
            <!--  -->
            <div v-if="fp.publicId === publicIds.singleYearly">
              <!-- CURRENT PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="light-blue">Current
                  Plan</v-btn>
                <br>
                <v-spacer class="mt-2"></v-spacer>
                <a class="text-grey-darken-3 cancel-link"
                  @click="openSubscriptionDialog(fp, paddleIds.singleYearly, opType.pause)">Cancel
                  Subscription</a>
              </div>
              <!-- CURRENT PLAN END -->

              <!-- REACTIVATE PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAUSED">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.singleYearly, opType.resume)">
                  Reactivate Plan
                </v-btn>
              </div>
              <div v-else-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAST_DUE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.singleYearly, opType.billing)">
                  Reactivate Account
                </v-btn>
              </div>
              <!-- REACTIVATE PLAN END -->

              <!-- SUBSCRIBE ON TRIAL CASES START -->
              <!-- no trial for single -->
              <!-- SUBSCRIBE ON TRIAL CASES END -->

              <!-- UPGRADE CASES START -->
              <div v-if="!hasSubscription">
                <!-- Subscribe to Single Plan when on no plan -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.singleYearly, opType.subscribe)">Subscribe</v-btn>
              </div>
              <!-- UPGRADE CASES END -->

              <!-- DOWNGRADE CASES START -->
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- No downgrade on yearly yet -->
              </div>
              <!-- DOWNGRADE CASES END -->
            </div>
            <!--  -->
            <!-- STARTER YEARLY PLAN BUTTONS ENDS -->
            <!--  -->


            <!--  -->
            <!-- PRO YEARLY PLAN BUTTONS START -->
            <!--  -->
            <div v-if="fp.publicId === publicIds.proYearly">
              <!-- CURRENT PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="light-blue">Current
                  Plan</v-btn>
                <br>
                <v-spacer class="mt-2"></v-spacer>
                <a class="text-grey-darken-3 cancel-link"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearly, opType.pause)">Cancel
                  Subscription</a>
              </div>
              <!-- CURRENT PLAN END -->

              <!-- REACTIVATE PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAUSED">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearly, opType.resume)">
                  Reactivate Plan
                </v-btn>
              </div>
              <div v-else-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAST_DUE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearly, opType.billing)">
                  Reactivate Account
                </v-btn>
              </div>
              <!-- REACTIVATE PLAN END -->

              <!-- SUBSCRIBE ON TRIAL CASES START -->
              <div
                v-if="hasSubscription && plan.public_id === publicIds.proYearlyTrial && subscription?.status === paddleSubscriptionStatusTypes.TRIALING">
                <!-- Subscribe to Pro Yearly Plan when on Pro Yearly Trial-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="tonal" color="primary-red"
                  v-if="plan.public_id === publicIds.proYearlyTrial"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearlyTrial, opType.subscribeWithinTrial)">Subscribe
                  Now</v-btn>
                <v-spacer class="mt-2"></v-spacer>
                <p class="grey-lighten-2 font-italic text-body-2 pt-1 ml-1 pb-0 mb-0">You are currently in trial period.
                  <a class="grey-lighten-2 font-italic text-body-2 cancel-link"
                    @click="openSubscriptionDialog(fp, paddleIds.proYearlyTrial, opType.cancel)">Cancel Trial?</a>
                </p>
              </div>
              <!-- SUBSCRIBE ON TRIAL CASES END -->

              <!-- UPGRADE CASES START -->
              <div v-if="!hasSubscription">
                <!-- Subscribe to Pro Yearly Plan when on no plan -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearly, opType.subscribe)">Subscribe</v-btn>
              </div>
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- upgrade from monthly -->
                <!-- Upgrade to Pro Yearly Plan when on Starter Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.starter"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Pro Yearly Plan when on Pro Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.proMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Pro Yearly Plan when on Scale Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.scaleMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Pro Yearly Plan when on Enterprise Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.enterpriseMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.proYearly, opType.upgrade)">Upgrade</v-btn>
              </div>
              <!-- UPGRADE CASES END -->

              <!-- DOWNGRADE CASES START -->
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- No downgrade on yearly yet -->
              </div>
              <!-- DOWNGRADE CASES END -->
            </div>
            <!--  -->
            <!-- PRO YEARLY PLAN BUTTONS ENDS -->
            <!--  -->


            <!--  -->
            <!-- SCALE YEARLY PLAN BUTTONS START -->
            <!--  -->
            <div v-if="fp.publicId === publicIds.scaleYearly">
              <!-- CURRENT PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="light-blue">Current
                  Plan</v-btn>
                <br>
                <v-spacer class="mt-2"></v-spacer>
                <a class="text-grey-darken-3 cancel-link"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearly, opType.pause)">Cancel
                  Subscription</a>
              </div>
              <!-- CURRENT PLAN END -->

              <!-- REACTIVATE PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAUSED">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearly, opType.resume)">
                  Reactivate Plan
                </v-btn>
              </div>
              <div v-else-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAST_DUE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearly, opType.billing)">
                  Reactivate Account
                </v-btn>
              </div>
              <!-- REACTIVATE PLAN END -->

              <!-- SUBSCRIBE ON TRIAL CASES START -->
              <div
                v-if="hasSubscription && plan.public_id === publicIds.scaleYearlyTrial && subscription?.status === paddleSubscriptionStatusTypes.TRIALING">
                <!-- Subscribe to Scale Yearly Plan when on Scale Yearly Trial-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="tonal" color="primary-red"
                  v-if="plan.public_id === publicIds.scaleYearlyTrial"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearlyTrial, opType.subscribeWithinTrial)">Subscribe
                  Now</v-btn>
                <v-spacer class="mt-2"></v-spacer>
                <p class="grey-lighten-2 font-italic text-body-2 pt-1 ml-1 pb-0 mb-0">You are currently in trial period.
                  <a class="grey-lighten-2 font-italic text-body-2 cancel-link"
                    @click="openSubscriptionDialog(fp, paddleIds.scaleYearlyTrial, opType.cancel)">Cancel Trial?</a>
                </p>
              </div>
              <!-- SUBSCRIBE ON TRIAL CASES END -->

              <!-- UPGRADE CASES START -->
              <div v-if="!hasSubscription">
                <!-- Subscribe to Scale Yearly Plan when on no plan -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearly, opType.subscribe)">Subscribe</v-btn>
              </div>
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- upgrade from monthly -->
                <!-- Upgrade to Scale Yearly Plan when on Starter Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.starter"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Scale Yearly Plan when on Pro Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.proMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Scale Yearly Plan when on Scale Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.scaleMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Scale Yearly Plan when on Enterprise  Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.enterpriseMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleYearly, opType.upgrade)">Upgrade</v-btn>
              </div>
              <!-- UPGRADE CASES END -->

              <!-- DOWNGRADE CASES START -->
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- No downgrade on yearly yet -->
              </div>
              <!-- DOWNGRADE CASES END -->
            </div>
            <!--  -->
            <!-- SCALE YEARLY PLAN BUTTONS ENDS -->
            <!--  -->


            <!--  -->
            <!-- ENTERPRISE YEARLY PLAN BUTTONS START -->
            <!--  -->
            <div v-if="fp.publicId === publicIds.enterpriseYearly">
              <!-- CURRENT PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="light-blue">Current
                  Plan</v-btn>
                <br>
                <v-spacer class="mt-2"></v-spacer>
                <a class="text-grey-darken-3 cancel-link"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearly, opType.pause)">Cancel
                  Subscription</a>
              </div>
              <!-- CURRENT PLAN END -->

              <!-- REACTIVATE PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAUSED">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearly, opType.resume)">
                  Reactivate Plan
                </v-btn>
              </div>
              <div v-else-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAST_DUE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearly, opType.billing)">
                  Reactivate Account
                </v-btn>
              </div>
              <!-- REACTIVATE PLAN END -->

              <!-- SUBSCRIBE ON TRIAL CASES START -->
              <div
                v-if="hasSubscription && plan.public_id === publicIds.enterpriseYearlyTrial && subscription?.status === paddleSubscriptionStatusTypes.TRIALING">
                <!-- Subscribe to Enterprise Yearly Plan when on Enterprise Yearly Trial-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="tonal" color="primary-red"
                  v-if="plan.public_id === publicIds.enterpriseYearlyTrial"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearlyTrial, opType.subscribeWithinTrial)">Subscribe
                  Now</v-btn>
                <v-spacer class="mt-2"></v-spacer>
                <p class="grey-lighten-2 font-italic text-body-2 pt-1 ml-1 pb-0 mb-0">You are currently in trial period.
                  <a class="grey-lighten-2 font-italic text-body-2 cancel-link"
                    @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearlyTrial, opType.cancel)">Cancel
                    Trial?</a>
                </p>
              </div>
              <!-- SUBSCRIBE ON TRIAL CASES END -->

              <!-- UPGRADE CASES START -->
              <div v-if="!hasSubscription">
                <!-- Subscribe to Starter Plan when on no plan -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearly, opType.subscribe)">Subscribe</v-btn>
              </div>
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- upgrade from monthly -->
                <!-- Upgrade to Enterprise Yearly Plan when on Starter Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.starter"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Enterprise Yearly Plan when on Pro Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.proMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Enterprise Yearly Plan when on Scale Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.scaleMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Enterprise Yearly Plan when on Enterprise  Monthly-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.enterpriseMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseYearly, opType.upgrade)">Upgrade</v-btn>
              </div>
              <!-- UPGRADE CASES END -->
            </div>
            <!--  -->
            <!-- ENTERPRISE YEARLY PLAN BUTTONS ENDS -->
            <!--  -->
          </v-card-text>
          <!--  -->
          <!-- YEARLY PLAN BUTTONS ENDS-->
          <!--  -->



          <!--  -->
          <!-- MONTHLY PLAN BUTTONS STARTS-->
          <!--  -->
          <v-card-text class="font-weight-normal text-left ml-1 py-2" v-else>

            <!--  -->
            <!-- STARTER MONTHLY PLAN BUTTONS START -->
            <!--  -->
            <div v-if="fp.publicId === publicIds.starter">
              <!-- CURRENT PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="light-blue">Current
                  Plan</v-btn>
                <br>
                <v-spacer class="mt-2"></v-spacer>
                <a class="text-grey-darken-3 cancel-link"
                  @click="openSubscriptionDialog(fp, paddleIds.starter, opType.pause)">Cancel
                  Subscription</a>
              </div>
              <!-- CURRENT PLAN END -->

              <!-- REACTIVATE PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAUSED">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.starter, opType.resume)">
                  Reactivate Plan
                </v-btn>
              </div>
              <div v-else-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAST_DUE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.starter, opType.billing)">
                  Reactivate Account
                </v-btn>
              </div>
              <!-- REACTIVATE PLAN END -->

              <!-- SUBSCRIBE ON TRIAL CASES START -->
              <!-- no trial for starter -->
              <!-- SUBSCRIBE ON TRIAL CASES END -->

              <!-- UPGRADE CASES START -->
              <div v-if="!hasSubscription">
                <!-- Subscribe to Starter Plan when on no plan -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.starter, opType.subscribe)">Subscribe</v-btn>
              </div>
              <!-- UPGRADE CASES END -->

              <!-- DOWNGRADE CASES START -->
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- Downgrade to Starter Plan when on Enterprise Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="grey-lighten-2"
                  v-if="plan.public_id === publicIds.enterpriseMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.starter, opType.downgrade)">Downgrade</v-btn>
                <!-- Downgrade to Starter Plan when on Scale Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="grey-lighten-2"
                  v-if="plan.public_id === publicIds.scaleMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.starter, opType.downgrade)">Downgrade</v-btn>
                <!-- Downgrade to Starter Plan when on Pro Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="grey-lighten-2"
                  v-if="plan.public_id === publicIds.proMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.starter, opType.downgrade)">Downgrade</v-btn>
              </div>
              <!-- DOWNGRADE CASES END -->
            </div>
            <!--  -->
            <!-- STARTER MONTHLY PLAN BUTTONS ENDS -->
            <!--  -->


            <!--  -->
            <!-- PRO MONTHLY PLAN BUTTONS START -->
            <!--  -->
            <div v-if="fp.publicId === publicIds.proMonthly">
              <!-- CURRENT PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="light-blue">Current
                  Plan</v-btn>
                <br>
                <v-spacer class="mt-2"></v-spacer>
                <a class="text-grey-darken-3 cancel-link"
                  @click="openSubscriptionDialog(fp, paddleIds.proMonthly, opType.pause)">Cancel
                  Subscription</a>
              </div>
              <!-- CURRENT PLAN END -->

              <!-- REACTIVATE PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAUSED">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.proMonthly, opType.resume)">
                  Reactivate Plan
                </v-btn>
              </div>
              <div v-else-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAST_DUE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.proMonthly, opType.billing)">
                  Reactivate Account
                </v-btn>
              </div>
              <!-- REACTIVATE PLAN END -->

              <!-- SUBSCRIBE ON TRIAL CASES START -->
              <div
                v-if="hasSubscription && plan.public_id === publicIds.proMonthlyTrial && subscription?.status === paddleSubscriptionStatusTypes.TRIALING">
                <!-- Subscribe to Pro Monthly Plan when on Pro Monthly Trial-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="tonal" color="primary-red"
                  v-if="plan.public_id === publicIds.proMonthlyTrial"
                  @click="openSubscriptionDialog(fp, paddleIds.proMonthlyTrial, opType.subscribeWithinTrial)">Subscribe
                  Now</v-btn>
                <v-spacer class="mt-2"></v-spacer>
                <p class="grey-lighten-2 font-italic text-body-2 pt-1 ml-1 pb-0 mb-0">You are currently in trial period.
                  <a class="grey-lighten-2 font-italic text-body-2 cancel-link"
                    @click="openSubscriptionDialog(fp, paddleIds.proMonthlyTrial, opType.cancel)">Cancel Trial?</a>
                </p>
              </div>
              <!-- SUBSCRIBE ON TRIAL CASES END -->


              <!-- UPGRADE CASES START -->
              <div v-if="!hasSubscription">
                <!-- Subscribe to Pro Plan when on no plan -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.proMonthly, opType.subscribe)">Subscribe</v-btn>
              </div>
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- Upgrade to Pro Plan when on Starter Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.starter"
                  @click="openSubscriptionDialog(fp, paddleIds.proMonthly, opType.upgrade)">Upgrade</v-btn>
              </div>
              <!-- UPGRADE CASES END -->

              <!-- DOWNGRADE CASES START -->
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- Downgrade to Pro Plan when on Enterprise Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="grey-lighten-2"
                  v-if="plan.public_id === publicIds.enterpriseMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.proMonthly, opType.downgrade)">Downgrade</v-btn>
                <!-- Downgrade to Pro Plan when on Scale Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="grey-lighten-2"
                  v-if="plan.public_id === publicIds.scaleMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.proMonthly, opType.downgrade)">Downgrade</v-btn>
              </div>
              <!-- DOWNGRADE CASES END -->
            </div>
            <!--  -->
            <!-- PRO MONTHLY PLAN BUTTONS ENDS -->
            <!--  -->


            <!--  -->
            <!-- SCALE MONTHLY PLAN BUTTONS START -->
            <!--  -->
            <div v-if="fp.publicId === publicIds.scaleMonthly">
              <!-- CURRENT PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="light-blue">Current
                  Plan</v-btn>
                <br>
                <v-spacer class="mt-2"></v-spacer>
                <a class="text-grey-darken-3 cancel-link"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleMonthly, opType.pause)">Cancel
                  Subscription</a>
              </div>
              <!-- CURRENT PLAN END -->

              <!-- REACTIVATE PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAUSED">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleMonthly, opType.resume)">
                  Reactivate Plan
                </v-btn>
              </div>
              <div v-else-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAST_DUE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleMonthly, opType.billing)">
                  Reactivate Account
                </v-btn>
              </div>
              <!-- REACTIVATE PLAN END -->

              <!-- SUBSCRIBE ON TRIAL CASES START -->
              <div
                v-if="hasSubscription && plan.public_id === publicIds.scaleMonthlyTrial && subscription?.status === paddleSubscriptionStatusTypes.TRIALING">
                <!-- Subscribe to Scale Monthly Plan when on Scale Monthly Trial-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="tonal" color="primary-red"
                  v-if="plan.public_id === publicIds.scaleMonthlyTrial"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleMonthlyTrial, opType.subscribeWithinTrial)">Subscribe
                  Now</v-btn>
                <v-spacer class="mt-2"></v-spacer>
                <p class="grey-lighten-2 font-italic text-body-2 pt-1 ml-1 pb-0 mb-0">You are currently in trial period.
                  <a class="grey-lighten-2 font-italic text-body-2 cancel-link"
                    @click="openSubscriptionDialog(fp, paddleIds.scaleMonthlyTrial, opType.cancel)">Cancel Trial?</a>
                </p>
              </div>
              <!-- SUBSCRIBE ON TRIAL CASES END -->

              <!-- UPGRADE CASES START -->
              <div v-if="!hasSubscription">
                <!-- Subscribe to Scale Plan when on no plan -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleMonthly, opType.subscribe)">Subscribe</v-btn>
              </div>
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- Upgrade to Scale Plan when on Starter Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.starter"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleMonthly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Scale Plan when on Pro Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.proMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleMonthly, opType.upgrade)">Upgrade</v-btn>
              </div>
              <!-- UPGRADE CASES END -->

              <!-- DOWNGRADE CASES START -->
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- Downgrade to Scale Plan when on Enterprise Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="grey-lighten-2"
                  v-if="plan.public_id === publicIds.enterpriseMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.scaleMonthly, opType.downgrade)">Downgrade</v-btn>
              </div>
              <!-- DOWNGRADE CASES END -->
            </div>
            <!--  -->
            <!-- SCALE MONTHLY PLAN BUTTONS ENDS -->
            <!--  -->


            <!--  -->
            <!-- ENTERPRISE MONTHLY PLAN BUTTONS START -->
            <!--  -->
            <div v-if="fp.publicId === publicIds.enterpriseMonthly">
              <!-- CURRENT PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="light-blue">Current
                  Plan</v-btn>
                <br>
                <v-spacer class="mt-2"></v-spacer>
                <a class="text-grey-darken-3 cancel-link"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthly, opType.pause)">Cancel
                  Subscription</a>
              </div>
              <!-- CURRENT PLAN END -->

              <!-- REACTIVATE PLAN START -->
              <div v-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAUSED">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthly, opType.resume)">
                  Reactivate Plan
                </v-btn>
              </div>
              <div v-else-if="isCurrentPlan(fp) && subscription?.status === paddleSubscriptionStatusTypes.PAST_DUE">
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthly, opType.billing)">
                  Reactivate Account
                </v-btn>
              </div>
              <!-- REACTIVATE PLAN END -->

              <!-- SUBSCRIBE ON TRIAL CASES START -->
              <div
                v-if="hasSubscription && plan.public_id === publicIds.enterpriseMonthlyTrial && subscription?.status === paddleSubscriptionStatusTypes.TRIALING">
                <!-- Subscribe to Enterprise Monthly Plan when on Enterprise Monthly Trial-->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="tonal" color="primary-red"
                  v-if="plan.public_id === publicIds.enterpriseMonthlyTrial"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthlyTrial, opType.subscribeWithinTrial)">Subscribe
                  Now</v-btn>
                <v-spacer class="mt-2"></v-spacer>
                <p class="grey-lighten-2 font-italic text-body-2 pt-1 ml-1 pb-0 mb-0">You are currently in trial period.
                  <a class="grey-lighten-2 font-italic text-body-2 cancel-link"
                    @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthlyTrial, opType.cancel)">Cancel
                    Trial?</a>
                </p>
              </div>
              <!-- SUBSCRIBE ON TRIAL CASES END -->

              <!-- UPGRADE CASES START -->
              <div v-if="!hasSubscription">
                <!-- Subscribe to Enterprise Plan when on no plan -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="elevated" color="primary-red"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthly, opType.subscribe)">Subscribe</v-btn>
              </div>
              <div v-if="subscription?.status === paddleSubscriptionStatusTypes.ACTIVE">
                <!-- Upgrade to Enterprise Plan when on Starter Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.starter"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Enterprise Plan when on Pro Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.proMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthly, opType.upgrade)">Upgrade</v-btn>
                <!-- Upgrade to Enterprise Plan when on Scale Monthly -->
                <v-btn class="text-none font-weight-bold" rounded="pill" variant="flat" color="primary-red"
                  v-if="plan.public_id === publicIds.scaleMonthly"
                  @click="openSubscriptionDialog(fp, paddleIds.enterpriseMonthly, opType.upgrade)">Upgrade</v-btn>
              </div>
              <!-- UPGRADE CASES END -->
            </div>
            <!--  -->
            <!-- ENTERPRISE MONTHLY PLAN BUTTONS ENDS -->
            <!--  -->
          </v-card-text>
          <!--  -->
          <!-- MONTHLY PLAN BUTTONS  ENDS-->
          <!--  -->

          <!-- plan features -->
          <v-card-text class="pt-0 mt-0 text-left">
            <v-list :lines="false" density="compact" class="plan-features">
              <v-list-item class="text-black">
                ✔ {{ formatNumber(fp.forms) }} Forms
              </v-list-item>
              <v-list-item class="text-black">
                ✔ {{ formatNumber(fp.leads) }} Leads/mo
              </v-list-item>
              <v-list-item class="text-black" v-if="fp.partial_leads > 0">
                ✔ {{ formatNumber(fp.partial_leads) }} Partial Leads/mo
              </v-list-item>
              <v-list-item class="text-black">
                ✔ {{ formatNumber(fp.views) }} Views/mo
              </v-list-item>
              <v-list-item class="text-grey" v-if="fp.partial_leads === 0">
                ✗ Partial Leads
              </v-list-item>
              <v-list-item v-for="(feature, index) in requiredFeatures" :key="index">
                <span :class="isPlanFeature(fp, feature) ? 'text-black' : 'text-grey'">
                  {{ isPlanFeature(fp, feature) ? '✔' : '✗' }}
                  {{ feature }}
                </span>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- plan table data -->
    <v-row>
      <subscription-table></subscription-table>
    </v-row>
    <!-- other info -->
    <v-row>
      <subscription-enterprise></subscription-enterprise>
    </v-row>
    <!-- dialogs -->
    <subscription-dialog v-model="dialog.subscription" :dialog-data="dialog.data"></subscription-dialog>
  </v-container>
</template>

<script>
import SubscriptionTable from '@/components/plan/SubscriptionTable.vue'
import SubscriptionEnterprise from '@/components/plan/SubscriptionEnterprise.vue'
import SubscriptionDialog from '@/components/plan/SubscriptionDialog.vue'
import {
  paddleMonthlyPlans, paddleYearlyPlans,
  paddleMonthlyTrialPlans, paddleYearlyTrialPlans,
  freeTrialPlans
} from '@/shared/constants/plan/paddle/plans'
import opType from '@/shared/constants/plan/paddle/action'
import userMixin from '@/mixins/user'
import helperMixin from '@/mixins/helper'
import pastDueMixin from '@/mixins/plan'
import config from '@/api/config'

export default {
  mixins: [userMixin, helperMixin, pastDueMixin],
  components: {
    SubscriptionTable,
    SubscriptionEnterprise,
    SubscriptionDialog
  },
  data() {
    return {
      yearlyToggle: false,
      paddleMonthlyPlans,
      paddleYearlyPlans,
      paddleMonthlyTrialPlans,
      paddleYearlyTrialPlans,
      freeTrialPlans,
      opType,
      publicIds: {},
      paddleIds: {},
      dialog: {
        subscription: false,
        data: {}
      },
      requiredFeatures: [
        "Contact State Integration",
        "UTM Parameters",
        "Trustedform Integration",
        "Multiple Form Endings",
        "Account Manager",
      ],
    }
  },
  computed: {
    filteredPlans() {
      // Filter the yearly or monthly plans based on the toggle
      let plans = this.yearlyToggle ? this.paddleYearlyPlans : this.paddleMonthlyPlans

      // Check if the user is on the Single plan
      const isOnSinglePlan = this.plan?.public_id === this.publicIds.singleYearly

      // Filter out the Single plan if the user is not on it
      if (!isOnSinglePlan) {
        plans = plans.filter(plan => plan.publicId !== this.publicIds.singleYearly)
      }

      return plans
    }
  },
  mounted() {
    this.initializePublicIds()
    this.initializeConfigPaddleIds()
  },
  methods: {
    calculateSavings: function (a, b) {
      return (b - a) * 12
    },
    // Check if the feature exists in the plan's features
    isPlanFeature(plan, feature) {
      const featureMap = {
        "Contact State Integration": ["Starter", "Pro", "Scale", "Enterprise"],
        "UTM Parameters": ["Starter", "Pro", "Scale", "Enterprise"],
        "Trustedform Integration": ["Pro", "Scale", "Enterprise"],
        "Multiple Form Endings": ["Scale", "Enterprise"],
        "Account Manager": ["Enterprise"]
      }
      return featureMap[feature]?.includes(plan.title) || false
    },
    initializePublicIds() {
      this.publicIds = {
        starter: this.getPlanPublicId(paddleMonthlyPlans, "Starter"),
        proMonthly: this.getPlanPublicId(paddleMonthlyPlans, "Pro"),
        scaleMonthly: this.getPlanPublicId(paddleMonthlyPlans, "Scale"),
        enterpriseMonthly: this.getPlanPublicId(paddleMonthlyPlans, "Enterprise"),
        singleYearly: this.getPlanPublicId(paddleYearlyPlans, "Single"),
        proYearly: this.getPlanPublicId(paddleYearlyPlans, "Pro"),
        scaleYearly: this.getPlanPublicId(paddleYearlyPlans, "Scale"),
        enterpriseYearly: this.getPlanPublicId(paddleYearlyPlans, "Enterprise"),
        proMonthlyTrial: this.getPlanPublicId(paddleMonthlyTrialPlans, "Pro Trial"),
        scaleMonthlyTrial: this.getPlanPublicId(paddleMonthlyTrialPlans, "Scale Trial"),
        enterpriseMonthlyTrial: this.getPlanPublicId(paddleMonthlyTrialPlans, "Enterprise Trial"),
        proYearlyTrial: this.getPlanPublicId(paddleYearlyTrialPlans, "Pro Trial"),
        scaleYearlyTrial: this.getPlanPublicId(paddleYearlyTrialPlans, "Scale Trial"),
        enterpriseYearlyTrial: this.getPlanPublicId(paddleYearlyTrialPlans, "Enterprise Trial"),
        freeTrial: freeTrialPlans[0].publicId
      }
    },
    initializeConfigPaddleIds() {
      this.paddleIds = {
        starter: config.paddle.starterPlanId,
        proMonthly: config.paddle.planId,
        scaleMonthly: config.paddle.scaleId,
        enterpriseMonthly: config.paddle.enterpriseId,
        singleYearly: config.paddle.singlePlanId,
        proYearly: config.paddle.annualProId,
        scaleYearly: config.paddle.annualScaleId,
        enterpriseYearly: config.paddle.annualEnterpriseId,
        proMonthlyTrial: config.paddle.trialPlanId,
        scaleMonthlyTrial: config.paddle.trialScaleId,
        enterpriseMonthlyTrial: config.paddle.trialEnterpriseId,
        proYearlyTrial: config.paddle.trialAnnualProId,
        scaleYearlyTrial: config.paddle.trialAnnualScaleId,
        enterpriseYearlyTrial: config.paddle.trialAnnualEnterpriseId,
      }
    },
    getPlanByPublicId(publicId) {
      return [...this.paddleMonthlyPlans, ...this.paddleMonthlyTrialPlans, ...this.paddleYearlyPlans, ...this.paddleYearlyTrialPlans].find(plan => plan.publicId === publicId)
    },
    getPlanPublicId(planArray, title) {
      const plan = planArray.find(p => p.title === title)
      return plan ? plan.publicId : null
    },
    isCurrentPlan(plan) {
      return plan.publicId === this.plan?.public_id
    },
    openSubscriptionDialog(newPlan, newPlanId, action) {
      let dialogData = {
        action: action,
        newPlanId: newPlanId
      }

      if (action === this.opType.upgrade || action === this.opType.subscribe) {
        // For upgrade, we use the new plan data (fp)
        dialogData.planData = newPlan
      } else {
        dialogData.planData = this.getPlanByPublicId(this.plan?.public_id)
      }

      // Set the dialog data
      this.dialog.data = dialogData
      // Open the dialog
      this.dialog.subscription = true
    }
  }
}
</script>
