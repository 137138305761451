<template>
  <div class="dashboard-notification__item" v-if="user">
    👋 Hey <strong>{{ user.name }}</strong>! We need you to confirm your email address.
    <span class="dashboard-notification__item__action" @click="sendConfirmation">Confirm Email</span>
    <span class="dashboard-notification__item__action" @click="remindLater">Remind Me Later</span>
  </div>
</template>

<script type="text/javascript">
import userMixin from '@/mixins/user'
import dashboardMixin from '@/mixins/dashboard'
import * as actionTypes from '@/store/action-types'

export default {
  mixins: [userMixin, dashboardMixin],

  methods: {
    sendConfirmation: function () {
      this.$store.dispatch(actionTypes.SEND_ACCOUNT_VERIFICATION, {
        email: this.user.email
      }).then((response) => {
        this.remindLater()
      })
    },

    remindLater: function () {
      this.$store.dispatch('notification/toggleVisibility', {
        key: 'verifyEmail',
        value: false
      }).then(() => {
        localStorage.setItem('notification.dashboard.verifyEmailHide', 1, 3 * 60 * 60 * 1000)
        this.adjustDashboardLayout()
      })
    }
  }
}
</script>
