<template>
  <div class="form-share-view">
    <v-container class="w-75">
      <v-row class="align-center justify-space-between my-1">
        <v-col cols="12" md="6" class="text-left">
          <router-link :to="{ name: 'forms.index' }" class="text-primary-red mb-5 link">
            <v-icon left>mdi-arrow-left</v-icon>
            Forms
          </router-link>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn rounded="md" color="primary-red" class="text-none font-weight-bold" prepend-icon="mdi-share-all"
            :loading="share.loading" @click="shareForm">
            Share
          </v-btn>
        </v-col>
      </v-row>
      <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
        <v-card-title class="bg-blue-lighten-5 text-primary-blue">
          <h6 class="d-flex justify-left mb-0">
            <v-icon icon="mdi-share-all" class="pt-1"></v-icon>
            <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Share form between user accounts
            </p>
          </h6>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0 my-5">
          <v-form ref="shareForm">
            <v-row class="px-10 py-5 align-baseline">
              <v-col cols="12" v-if="share.error || share.success" class="py-3">
                <v-alert variant="tonal" rounded="md" density="compact" class="text-left mb-8" icon="mdi-alert-circle"
                  :type="share.error ? 'error' : 'success'">
                  <span class="text-black mb-0">{{ share.message }}</span>
                </v-alert>
              </v-col>
              <v-col cols="12" md="6" class="py-0 px-3">
                <v-autocomplete variant="outlined" prepend-inner-icon="mdi-account-arrow-left" label="From Account"
                  placeholder="Search from account by email" density="default" clearable
                 :items="from.users" item-title="email" return-object
                  v-model="from.user" :loading="from.loadingUsers" @update:search="onFromUsersSearch"
                   :hide-no-data="true" :no-filter="true" :rules="$valid([
                    { rule: 'required' }
                  ])">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="py-1 px-3">
                <v-autocomplete variant="outlined" prepend-inner-icon="mdi-form-select" label="Select form to share"
                  placeholder="Select form to share" return-object :items="from.forms" density="default" clearable
                  item-title="title" v-model="from.form" :disabled="from.forms.length === 0" :loading="from.loadingUsers"
                  :hide-no-data="true" :no-filter="true" :rules="$valid([
                    { rule: 'required' }
                  ])">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="py-0 px-3">
                <v-autocomplete variant="outlined" prepend-inner-icon="mdi-account-arrow-right" :disabled="Array.isArray(from.form) || !from.form" label="To Account"
                  placeholder="Search user by email" density="default" clearable
                  :items="to.users" item-title="email" return-object
                  v-model="to.user" :loading="to.loadingUsers" @update:search="onToUsersSearch" :hide-no-data="true"
                  :no-filter="true" :rules="$valid([
                    { rule: 'required' }
                  ])">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="py-1 px-3">
                <v-text-field variant="outlined" prepend-inner-icon="mdi-text-short" :disabled="Array.isArray(from.form) || !from.form" label="New Form Title"
                  placeholder="Set new form title" density="default" clearable v-model="to.formTitle" :rules="$valid([
                    { rule: 'required' },
                    { rule: 'between', min: 5, max: 150 },
                    { rule: 'alphaSpaces' }
                  ])">
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="to?.user?.id && from?.form?.id" class="px-3 py-2">
                <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mb-1 mt-5 text-black">
                  <p class="text-black mb-0"> <strong>Note:</strong> <strong class="text-grey-darken-3">{{
                    from.form.title }}</strong> will be shared to
                    <strong class="text-grey-darken-3">{{ to.user.email }}</strong>
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash'
import * as actionTypes from '@/store/action-types'

export default {
  data() {
    return {
      share: {
        loading: false,
        error: false,
        success: false,
        message: null
      },
      from: {
        user: [],
        form: [],
        users: [],
        forms: [],
        loadingUsers: false
      },
      to: {
        user: [],
        formTitle: null,
        users: [],
        loadingUsers: false
      }
    }
  },
  methods: {
    fetchForms(search, callback) {
      if (!this.from.user) return
      this.$store.dispatch(actionTypes.FETCH_FORMS, {
        search
      }).then(
        (response) => {
          callback(null, response)
        },
        (response) => {
          callback(response)
        }
      )
    },
    fetchUsers(search, callback) {
      this.$store.dispatch(actionTypes.FETCH_USERS, {
        page: 1,
        search
      }).then(
        (response) => {
          callback(null, response)
        },
        (response) => {
          callback(response)
        }
      )
    },

    onFromUsersSearch: _.debounce(function (query) {
      let searchQuery = query ? query.trim() : ''; // Fallback to avoid null/undefined error
      let search = ['email', searchQuery]
      this.from.users = []
      this.from.forms = []
      this.from.form = []
      this.share.error = false
      this.share.success = false
      this.share.message = null
      this.from.loadingUsers = true

      this.fetchUsers(search, (error, response) => {
        this.from.loadingUsers = false

        if (error) {
          return
        }

        let excludes = []

        if (this.from.user && this.from.user.id) {
          excludes.push(this.from.user.id)
        }

        this.from.users = this.filterUsers(response.data.data || [], excludes, searchQuery)
      })
      this.onFromUserChange()
    }, 300),

    onToUsersSearch: _.debounce(function (query) {
      let searchQuery = query ? query.trim() : ''; // Fallback to avoid null/undefined error
      let search = ['email', searchQuery]
      this.to.users = []
      this.to.loadingUsers = true

      this.fetchUsers(search, (error, response) => {
        this.to.loadingUsers = false
        if (error) {
          return
        }

        let excludes = [this.from.user?.id]

        if (this.to.user?.id) {
          excludes.push(this.to.user.id)
        }

        this.to.users = this.filterUsers(response.data.data || [], excludes, searchQuery)
      })
    }, 300),

    onFromUserChange() {
      this.share.error = false
      this.share.message = null

      if (this.from.user?.id === this.to.user?.id) {
        this.to.user = null
        return
      }

      this.fetchForms(['created_by', this.from.user.id], (error, response) => {
        if (error) {
          return
        }
        if (!response.data.data) {
          this.share.error = true
          this.share.message = "The selected from account has not any forms yet."
          return
        }
        this.from.forms = response.data.data || []
      })
    },

    filterUsers(users, excludes, searchQuery) {
      if (!users || !excludes) {
        return users
      }

      let searchRegex = new RegExp(searchQuery, 'i')

      return users.filter((user) => {
        if (excludes.includes(user.id)) {
          return false
        }

        return searchRegex.test(user.email)
      })
    },
    async shareForm() {
      const { valid } = await this.$refs.shareForm.validate()
      if (!valid) {
        return // Prevent submission if the form is not valid
      }

      this.share.error = false
      this.share.success = false
      this.share.message = null
      this.share.loading = true

      this.$store.dispatch(actionTypes.FORM_SHARE, {
        title: this.to.formTitle,
        form_id: this.from.form.id,
        to_user_id: this.to.user.id,
        from_user_id: this.from.user.id
      }).then(
        (response) => {
          this.share.loading = false
          this.share.success = true
          this.share.message = 'The form has been successfully shared!'
          this.resetForm()
        },
        (err) => {
          this.share.loading = false
          this.share.error = true
          this.share.message = 'Something went wrong, please try again.'
        }
      )
    },
    resetForm() {
      this.from.user = []
      this.from.form = []
      this.to.user = []
      this.to.formTitle = null
      this.$nextTick(() => {
        this.$refs.shareForm.resetValidation()
      })
    }
  }
}
</script>
