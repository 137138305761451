<template>
  <div class="visitor-based-view">
    <div class="container">
      <condition-builder-header headerMessage="visitor's interaction"></condition-builder-header>
      <condition-builder :endingTrigger="visitorFields" :endingType="visitorBased.type" :isEditView="isEditView"
        :placeholderTrigger="visitorBased.placeholderTrigger" :placeholderState="visitorBased.placeholderState"
        :hiddenFields="hiddenFields">
      </condition-builder>
    </div>
  </div>
</template>

<script>
import ConditionBuilder from './ConditionBuilder.vue'
import { formEndingTypes } from '../../../shared/constants/formEndingTypes'
import * as actionTypes from '../../../store/action-types'
import { editorMixin } from '../../../mixins/form-endings'
import ConditionBuilderHeader from './ConditionBuilderHeader.vue'

export default {
  mixins: [editorMixin],
  components: { ConditionBuilder, ConditionBuilderHeader },
  data: function () {
    return {
      visitorBased: {
        type: formEndingTypes.VISITORBASED,
        placeholderTrigger: 'Select A Field to apply a condition',
        placeholderState: 'Choose state of the selected Field'
      }
    }
  },
  mounted: function () {
    this.fetchHiddenFields()
  },
  methods: {
    fetchHiddenFields: function () {
      this.$store.dispatch(actionTypes.RESET_FORM_HIDDEN_FIELDS, []).then(() => {
        this.$store.dispatch(actionTypes.FETCH_FORM_HIDDEN_FIELDS, {
          formId: this.formID,
          variantId: this.variantId
        })
          .then((response) => {
            this.$store.dispatch(actionTypes.FETCH_FORM_HIDDEN_FIELDS_SUCCESS, response.data.data)
              .then(() => {
                this.fetchHiddenFieldsComplete = true
              })
          })
      })
    }
  },
  computed: {
    formID: function () {
      return parseInt(this.$route.params.id) || null
    },
    variantId: function () {
      return parseInt(this.$route.params.variantId) || null
    },
    hiddenFields: function () {
      return this.$store.getters.formHiddenFields(this.formID, this.variantId) || []
    },
    isEditView: function () {
      return this.$route.name === 'forms.variants.visitorbased.edit'
    }
  }
}
</script>

<style></style>
