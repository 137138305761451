<template>
  <div>
    <slot :openModal="openModal">
      <v-btn variant="flat" class="mt-2 font-weight-bold text-none text-black" color="grey-lighten-3"
        @click="openModal()"> Select Icon</v-btn>
    </slot>

    <v-dialog v-model="showIconManager" max-width="1100" rounded="sm" class="icon-library-manager">
      <v-card prepend-icon="mdi-shape-plus">
        <template v-slot:title>
          <strong>Icon Library</strong>
        </template>
        <v-divider></v-divider>
        <!-- Use v-sheet to create a scrollable area -->
        <v-sheet max-height="600" class="overflow-y-auto mb-0">
          <v-card-text>
            <v-row>
              <!-- Left Sidebar -->
              <v-col cols="3" class="icon-nav">
                <v-expansion-panels v-model="expandedPanel" flat>
                  <v-expansion-panel v-for="category in categories" :key="category.id">
                    <v-expansion-panel-title color="white">
                      <span class="font-weight-bold text-uppercase text-primary-blue">{{ category.title }}</span>
                      <template v-slot:actions>
                        <v-icon icon="mdi-chevron-down"></v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-list density="compact">
                        <v-hover v-for="l in categoryLibraries(category.id)" :key="l.id" v-slot="{ isHovering, props }">
                          <v-list-item v-bind="props" :value="l.id" :active="l.id === library"
                            @click="selectLibrary(l.id)" variant="flat" rounded="rounded" :class="{
                              'text-primary-red': l.id === library,
                              'text-secondary-red': isHovering && l.id !== library
                            }">
                            <template v-slot:prepend>
                              <span class="pt-1" v-html="l.icon"></span>
                            </template>
                            <v-list-item-title class="ml-1">{{ l.title }}</v-list-item-title>
                          </v-list-item>
                        </v-hover>
                      </v-list>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-spacer class="my-2"></v-spacer>
                </v-expansion-panels>
              </v-col>

              <!-- Right Content Area -->
              <v-col cols="9" class="pa-4">
                <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Search Icon" variant="outlined"
                  density="comfortable" hide-details class="mb-4"></v-text-field>

                <v-row v-if="fetching" justify="center" align="center" class="py-5 mt-5">
                  <v-progress-circular indeterminate color="primary-red" :size="30" :width="3"></v-progress-circular>
                </v-row>

                <v-row v-else-if="search && Object.keys(libraryIcons).length === 0" justify="center" align="center">
                  <v-col cols="12" class="text-center">
                    <span class="text-h6">No icons found for "{{ search }}"</span>
                  </v-col>
                </v-row>

                <v-row v-else>
                  <v-col v-for="(libraryIcon, key, index) in libraryIcons" :key="index" cols="12" sm="4" md="2" lg="2">
                    <v-card class="mx-0 px-0 icon-card" rounded="sm" height="85" hover @click="selectIcon(key)"
                      :class="isSelectedIcon(key) ? 'border-md border-black-border' : 'border-sm border-grey-border'"
                      variant="outlined">
                      <v-card-text class=" d-flex flex-column align-center justify-center text-center">
                        <v-icon size="28" class="mb-2" v-html="libraryIcon['svg']">
                        </v-icon>
                        <!-- <div v-html="libraryIcon['svg']"></div> -->
                        <span class="text-icon d-block mt-1">
                          {{ key }}
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-sheet>
        <v-divider></v-divider>
        <!-- Footer Actions -->
        <v-card-actions class="mb-2 text-right">
          <v-row class="mr-3">
            <v-col cols="12">
              <v-btn class="text-none font-weight-bold" rounded="md" variant="flat" color="primary-red"
                :disabled="selected.length === 0" @click="choose">Choose</v-btn>
              <v-btn class="text-none font-weight-bold" rounded="md" variant="flat" color="grey-lighten-3"
                @click="cancel">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventBus from '@/library/event-bus'

export default {
  name: 'IconLibraryManager',
  props: {
    selectedLibrary: {
      type: String,
      default: 'fa5_far'
    }
  },
  data() {
    return {
      showIconManager: false,
      expandedPanel: [0, 1],
      timestamp: null
    }
  },
  mounted() {
    this.updateTimestamp()
    if (this.visible) {
      this.openModal()
    }
  },
  methods: {
    fetchIcons() {
      this.$store.dispatch('iconlibrary/toggleFetching', { fetching: true })

      if (!this.library) {
        this.$store.dispatch('iconlibrary/toggleFetching', { fetching: false })
        return
      }

      if (this.hasCachedIcons) {
        this.$store.dispatch('iconlibrary/fetchIconsSuccess', {
          library: this.library,
          icons: this.$store.getters['iconlibrary/cachedIcons']
        })
        this.$store.dispatch('iconlibrary/toggleFetching', { fetching: false })
        return
      }

      this.$store.dispatch('iconlibrary/fetchIcons', {
        library: this.library.split('_')[0],
        variation: this.library.split('_')[1]
      })
        .then((response) => {
          this.$store.dispatch('iconlibrary/fetchIconsSuccess', {
            library: this.library,
            icons: response.data.data
          })
          this.$store.dispatch('iconlibrary/toggleFetching', { fetching: false })
        })
    },
    openModal() {
      this.showIconManager = true
      this.fetchIcons()
    },
    closeModal() {
      this.showIconManager = false
    },
    close() {
      EventBus.$off('ilm:selected')
      this.$emit('close')
      this.$store.dispatch('iconlibrary/setVisibility', {
        visible: false
      })
      this.$store.dispatch('iconlibrary/selectIcon', {
        selected: []
      })
      this.closeModal()
    },
    cancel() {
      this.$store.dispatch('iconlibrary/setVisibility', {
        visible: false
      })
      this.$store.dispatch('iconlibrary/selectIcon', {
        selected: []
      })
      this.closeModal()
    },
    choose() {
      EventBus.$emit('ilm:selected', this.$store.getters['iconlibrary/selected'])
      EventBus.$off('ilm:selected')
      this.$emit('ilm:selected', this.$store.getters['iconlibrary/selected'])
      this.$store.dispatch('iconlibrary/setVisibility', {
        visible: false
      }).then(() => {
        this.$store.dispatch('iconlibrary/selectIcon', {
          selected: []
        })
      })
      this.close()
    },
    updateTimestamp() {
      this.timestamp = Date.now()
    },
    selectLibrary(library) {
      this.$store.dispatch('iconlibrary/resetIcons').then(() => {
        this.$store.dispatch('iconlibrary/toggleFetching', { fetching: true })
        this.$store.dispatch('iconlibrary/selectLibrary', {
          library
        }).then(() => {
          const delay = this.hasCachedIcons ? 500 : 0
          setTimeout(() => {
            this.fetchIcons()
            this.$store.dispatch('iconlibrary/selectIcon', {
              selected: []
            })
          }, delay)
        })
      })
    },
    selectIcon(icon) {
      this.$store.dispatch('iconlibrary/selectIcon', {
        selected: [this.libraryObj.prefix + icon]
      })
    },
    searchIcons(icons) {
      if (!this.search) {
        return icons
      }

      return Object.keys(icons).reduce((value, icon) => {
        if (icon.toLowerCase().includes(this.search.toLowerCase())) {
          value[icon] = icons[icon]
        }

        return value
      }, {})
    },
    isSelectedIcon(icon) {
      if (this.selected.length === 0) {
        return false
      }
      return this.selected[0] === this.libraryObj.prefix + icon
    },
    categoryLibraries(id) {
      return this.$store.getters['iconlibrary/categoryLibraries'](id)
    }
  },
  computed: {
    fetching() {
      return this.$store.getters['iconlibrary/fetching']
    },
    hasCachedIcons() {
      return this.$store.getters['iconlibrary/hasCachedIcons']
    },
    svgIcons() {
      return []
    },
    selected() {
      return this.$store.getters['iconlibrary/selected']
    },
    libraryObj() {
      return this.$store.getters['iconlibrary/libraryObj']
    },
    library() {
      return this.$store.getters['iconlibrary/library']
    },
    visible() {
      return this.$store.getters['iconlibrary/visible']
    },
    categories() {
      return this.$store.getters['iconlibrary/categories']
    },
    libraries() {
      return this.$store.getters['iconlibrary/libraries']
    },
    libraryIcons() {
      if (this.search) {
        return this.$store.getters['iconlibrary/searchedIcons']
      }
      return this.$store.getters['iconlibrary/libraryIcons']
    },
    search: {
      get() {
        return this.$store.getters['iconlibrary/search']
      },
      set(search) {
        this.$store.dispatch('iconlibrary/searchLibrary', {
          search
        })
      }
    }
  },
  watch: {
    visible(to, from) {
      if (to) {
        this.fetchIcons()
        this.openModal()
      } else {
        this.closeModal()
      }
    }
  }
}
</script>
