<template>
  <div class="text-center pa-4 onboarding-container">
    <v-dialog v-model="screen.dialog" width="auto" class="onboarding" persistent rounded="md">
      <v-card width="720" class="onboarding-card">
        <v-row v-if="screen.step !== 1">
          <v-col cols="12">
            <v-progress-linear color="primary-red" height="12" :model-value="screen.progress"
              striped></v-progress-linear>
          </v-col>
        </v-row>
        <v-form ref="onboardingForm" @submit.prevent>
          <v-stepper :items="['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5']" v-model="screen.step"
            :class="{ 'gradient': screen.step === 1 }">
            <!-- Step 1 -->
            <template v-slot:item.1>
              <v-row class="mt-5 ml-3">
                <v-col cols="12">
                  <h5 class="mb-3 font-weight-bold">
                    Thank you for choosing LeadGen!
                  </h5>
                  <p>
                    We're excited to help you get started. It takes only a minute
                    to set up your account.
                  </p>
                </v-col>
              </v-row>
            </template>

            <!-- Step 2 -->
            <template v-slot:item.2>
              <v-row class="mt-5 mx-7">
                <v-col cols="12">
                  <!-- question 1 -->
                  <h5 class="mb-3 font-weight-bold">
                    Which industry do you primarily operate in?
                  </h5>
                  <v-autocomplete variant="outlined" clearable density="comfortable" color="secondary-red"
                    prepend-inner-icon="mdi-office-building"
                    placeholder="Select option closest to your field of activity" ref="onboard-industry"
                    :items="onboardingIndustries" item-title="label" item-value="value" v-model="onboard.industry"
                    :rules="$valid([{ rule: 'required', msg: 'Please select an option closest to your field of activity.' }])">
                  </v-autocomplete>
                  <!-- question 2 -->
                  <h5 class="mb-3 font-weight-bold">
                    Which of these best describes your role?
                  </h5>
                  <v-autocomplete variant="outlined" clearable density="comfortable" color="secondary-red"
                    prepend-inner-icon="mdi-account-tie-outline" placeholder="Select your Job Title" ref="onboard-role"
                    :items="onboardingRole" item-title="label" item-value="value" v-model="onboard.role"
                    :rules="$valid([{ rule: 'required', msg: 'Please select your job title from the options provided.' }])">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </template>

            <!-- Step 3 -->
            <template v-slot:item.3>
              <v-row class="mt-5 mx-7">
                <v-col cols="12">
                  <!-- question 1 radio images -->
                  <h5 class="mb-3 font-weight-bold">
                    What would you like to use LeadGen for?
                  </h5>
                  <v-radio-group ref="onboard-goal" v-model="onboard.goal"
                    :rules="$valid([{ rule: 'required', msg: 'Please select an option from the provided choices.' }])">
                    <v-row>
                      <v-col v-for="(goal, index) in onboardingGoal" :key="index" cols="12" xs="6" md="4">
                        <v-radio :value="goal.value" class="d-none"></v-radio>
                        <v-card hover rounded="md" class="goal-card px-2"
                          :class="{ 'goal-card--selected': onboard.goal === goal.value }"
                          @click="onboard.goal = goal.value">
                          <v-img :src="goal.image" width="50" aspect-ratio="1/1" class="mx-auto mt-3" contain></v-img>
                          <v-card-text class="text-center font-weight-bold">
                            {{ goal.label }}
                          </v-card-text>
                        </v-card>
                        <v-spacer></v-spacer>
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>
            </template>

            <!-- Step 4 -->
            <template v-slot:item.4>
              <v-row class="mt-5 mx-7">
                <v-col cols="12">
                  <!-- question 1 optional -->
                  <h5 class="mb-3 font-weight-bold">
                    How you came to know about LeadGen?
                  </h5>
                  <v-text-field prepend-inner-icon="mdi-chat-alert-outline" variant="outlined" density="comfortable"
                    clearable color="secondary-red" placeholder="Share how you came to know about LeadGen"
                    v-model="onboard.firstHeard" :rules="$valid([{ rule: 'between', min: '2', max: '20' }])">
                  </v-text-field>
                </v-col>
              </v-row>
            </template>

            <!-- Step 5 -->
            <template v-slot:item.5>
              <v-row class="mt-5 px-5">
                <v-col cols="12" class="text-center">
                  <h5 class="mb-3 font-weight-bold">Thank you!</h5>
                  <p class="mb-5">
                    We're in the process of loading the top-notch templates for
                    you to begin with.
                  </p>
                  <v-progress-circular color="secondary-red" indeterminate size="70"></v-progress-circular>
                </v-col>
              </v-row>
            </template>

            <!-- Stepper Actions -->
            <template v-slot:actions>
              <v-row v-if="screen.step === 1" class="px-4 mb-5 ml-3">
                <v-col cols="12">
                  <v-btn append-icon="mdi-arrow-right-thin" class="font-weight-bold text-none ml-2" color="primary-red"
                    rounded="sm" elevated @click="nextStep">
                    Let's Start
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mb-5 mr-13" v-else>
                <v-col class="d-flex flex-row-reverse" cols="12">
                  <v-btn class="font-weight-bold text-none ml-2" color="primary-red" rounded="md" @click="nextStep"
                    :variant="screen.variant" @mouseover="screen.variant = 'elevated'"
                    @mouseleave="screen.variant = 'flat'" v-if="screen.step !== 1 && screen.step !== 5">
                    {{ screen.step === 4 ? 'Get Started' : 'Next' }}
                  </v-btn>
                  <v-btn class="font-weight-bold text-none ml-2" color="grey-lighten-2" rounded="md" flat
                    @click="prevStep" v-if="screen.step !== 1 && screen.step !== 2 && screen.step !== 5">
                    Back
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-stepper>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Snackbar -->
    <v-snackbar rounded="md" height="50" v-model="snackbar.show" :color="snackbar.color" top :timeout="snackbar.duration">
      <p class=" text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.message }} </p>
    </v-snackbar>
  </div>
</template>

<script>
import { onboardingRole, onboardingIndustries, onboardingGoal } from '@/shared/constants/onboarding/index'

export default {
  data() {
    return {
      screen: {
        dialog: true,
        step: 1,
        progress: 25,
        variant: 'flat'
      },
      onboard: {
        industry: null,
        role: null,
        goal: null,
        firstHeard: ''
      },
      steps: [
        { id: 1, inputs: [] }, // Step 1 doesn't have inputs
        { id: 2, inputs: ['onboard-industry', 'onboard-role'] },
        { id: 3, inputs: ['onboard-goal'] },
        { id: 4, inputs: ['onboard-firstHeard'] },
        { id: 5, inputs: [] } // Step 5 is the thank you step
      ],
      snackbar: {
        show: false,
        message: '',
        color: 'secondary-red',
        duration: 2000
      }
    }
  },
  methods: {
    async validateCurrentStep() {
      let currentInputs = this.steps.find(step => step.id === this.screen.step)?.inputs
      if (!currentInputs || currentInputs.length === 0) {
        return true // No validation necessary if no inputs specified
      }
      try {
        const valid = await this.$refs.onboardingForm.validate(currentInputs)
        return valid.valid && valid.errors.length === 0 // Ensure both valid status and no errors
      } catch (error) {
        return false
      }
    },

    async nextStep() {
      const isValid = await this.validateCurrentStep()
      if (isValid) {
        this.screen.step++
        this.screen.progress = (this.screen.step - 1) * 25
        this.$nextTick(() => this.$refs.onboardingForm.resetValidation())
        if (this.screen.step === 5) {
          this.submitForm()
        }
      }
    },

    async prevStep() {
      const isValid = await this.validateCurrentStep()
      if (isValid) {
        this.screen.step--
        this.screen.progress = (this.screen.step - 1) * 25
        this.$nextTick(() => this.$refs.onboardingForm.resetValidation())
      }
    },

    showSnackbar: function (message) {
      this.snackbar.message = message
      this.snackbar.show = true
    },

    submitForm() {
      const formIsValid = this.$refs.onboardingForm.validate()
      if (formIsValid) {
        const formData = {
          industry: this.onboard.industry,
          role: this.onboard.role,
          firstHeard: this.onboard.firstHeard,
          goal: this.onboard.goal
        }

        this.$store.dispatch('onboarding/saveOnboardData', formData)
          .then(() => {
            this.$store.dispatch('onboarding/saveOnboardFlag', {
              page: 1,
              complete: true
            }).then(() => {
              setTimeout(() => {
                this.$router.push({ name: 'forms.choose.template', params: { industry: formData.industry } })
              }, 100)
            })
          }).catch(() => {
            this.$router.push({ name: 'forms.create.option' })
          })
      } else {
        this.$router.push({ name: 'forms.create.option' })
        this.showSnackbar('OOPS! Something went wrong.')
      }
    }
  },
  computed: {
    onboardingRole() {
      return onboardingRole
    },
    onboardingIndustries() {
      return onboardingIndustries
    },
    onboardingGoal() {
      return onboardingGoal
    }
  }
}
</script>
