import { postData } from '../plugins/ApiCalls'

export function getAuthToken() {
  return JSON.parse(localStorage.getItem('token'))
}

export async function register(data) {
  // TODO: Remove await and test again
  return await postData('/register', data)
}

export function login(data) {
  return postData('/authenticate', data)
}

export function logout() {
  localStorage.setItem('token', null)
  localStorage.setItem('decodedToken', null)
  localStorage.setItem('user', null)
  localStorage.removeItem('general_widget')
}
