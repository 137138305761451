import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function createExternalCheckout(data) {
  return postData('external-checkout', data)
}

export function updateExternalCheckout(id, data) {
  return putData('external-checkout/' + id, data)
}

export function getExternalCheckout(id) {
  return getData('external-checkout/' + id)
}

export function getExternalCheckouts() {
  return getData('external-checkout')
}

export function deleteExternalCheckout(id) {
  return deleteData('external-checkout/' + id)
}

export function getExternalCheckoutList(data) {
  return getData('external-checkout/list?listParams=' + encodeURIComponent(JSON.stringify(data)))
}
