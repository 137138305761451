import { getData, postData, putData } from '../plugins/ApiCalls'

export function createLandingPageTPL1(data) {
  return postData('landingpages/tpl1', data, { emulateJSON: true })
}

export function updateLandingPageTPL1(data) {
  return putData('landingpages/tpl1/' + data.pageId, data, { emulateJSON: true })
}

export function fetchPages(page) {
  return getData('landingpages?page=' + page)
}

export function fetchPage(id) {
  return getData('landingpages/' + id)
}

export function fetchPageView(id) {
  return getData('landingpages/' + id + '/public')
}

export function deletePages(data) {
  return postData('landingpages/all', data, { emulateJSON: true })
}

export function updateSlug(data) {
  return putData('landingpages/' + data.id + '/update-slug', { slug: data.slug }, { emulateJSON: true })
}
