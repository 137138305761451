<template>
  <div class="dashboard-layout">
    <!-- DASHBOARD NOTIFICATIONS -->
    <dashboard-notification-manager />
    <!-- DASHBOARD MAIN CONTENT -->
    <div class="dashboard-main">
      <!-- DASHBOARD NAVBAR -->
      <div :class="{ 'dashboard-navbar': true, 'dashboard-navbar__collapsed': navbarCollapsed }">
        <div class="dashboard-navbar__header">
          <img src="./../../assets/images/leadgen-logo-white-new.svg" alt="LeadGen">
          <img src="./../../assets/images/leadgen-leaf-logo.png" alt="LeadGen">
        </div>
        <div class="dashboard-navbar__body">
          <ul class="dashboard-navbar__list">
            <li>
              <router-link :to="{ name: 'dashboard' }" title="Dashboard" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/dashboard.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-view-dashboard</v-icon>
                <span>Dashboard</span>
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link :to="{ name: 'users.index' }" title="Users" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/users.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-account-group</v-icon>
                <span>Users</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'forms.index' }" title="Forms" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/forms.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-form-select</v-icon>
                <span>Forms</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'partialleads' }" title="Partial Leads" class="d-flex gc-2">
                <!-- <img style="margin-left: -3px;" src="./../../assets/images/icons/navbar/partial-leads.svg"
                  alt="LeadGen"> -->
                <v-icon icon="mdi-account-supervisor-circle-outline" size="small"></v-icon>
                <span>Partial Leads</span>
              </router-link>
            </li>
            <li v-if="canUseFeature(featureSlugs.LEAD_PROOFS)">
              <router-link :to="{ name: 'leadproofs.index.list' }" title="Lead Proofs" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/lead-proof.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-format-list-text</v-icon>
                <span>Lead Proofs</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'connections.list' }" title="Intergrations" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/integration.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-connection</v-icon>
                <span>Integrations</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'utmparams.index.list' }" title="UTM Parameters" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/utm-params.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-view-list</v-icon>
                <span>UTM Parameters</span>
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link :to="{ name: 'externalcheckout.index.list' }" title="External Checkout" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/external-checkout.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-open-in-app</v-icon>
                <span>External Checkout</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'googlerecaptchakeys' }" title="Google reCAPTCHA" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/recaptcha.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-sync</v-icon>
                <span class="text-none">reCAPTCHA</span>
              </router-link>
            </li>
            <li v-if="(isCustomer || hasSubscription) && !isOneToolUser">
              <router-link :to="{ name: 'plans.index' }" title="Plans" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/plan.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-currency-usd</v-icon>
                <span>Plans <span v-if="!hasSubscription"
                    class="lf-badge bg-primary-red font-weight-bold pt-2">UPGRADE</span></span>
                <!-- <span>Plans <span class="lf-badge" v-if="!hasSubscription">UPGRADE</span> </span> -->
              </router-link>
            </li>
            <li v-if="(isCustomer || hasSubscription) && !isOneToolUser">
              <router-link :to="{ name: 'billing.index' }" title="Billing" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/billing.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-invoice-text-outline</v-icon>
                <span>Billing</span>
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link :to="{ name: 'blacklist.ips' }" title="Blacklist IP" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/blacklist-ip.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-account-cancel</v-icon>
                <span>BlackList IP</span>
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link :to="{ name: 'template-builder.index.list' }" title="Template Builder" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/template-builder.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-layers-plus</v-icon>
                <span>Template Builder</span>
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link :to="{ name: 'package-builder.index.list' }" title="Package Builder" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/package-builder.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-briefcase-plus</v-icon>
                <span>Package Builder</span>
              </router-link>
            </li>
            <li v-if="isAdmin">
              <router-link :to="{ name: 'user-settings' }" title="User Settings" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/user-settings.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-account-cog</v-icon>
                <span>User Settings</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'settings' }" title="Settings" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/settings.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-cog</v-icon>
                <span>Settings</span>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'help.index' }" title="Help" class="d-flex gc-2">
                <!-- <img src="./../../assets/images/icons/navbar/help.svg" alt="LeadGen"> -->
                <v-icon size="small">mdi-help-circle</v-icon>
                <span>Help</span>
              </router-link>
            </li>
            <!-- <li v-if="isAdmin">
              <router-link :to="{ name: 'user-form.report' }" title="User Form Report"
                class="dashboard-navbar__list-item__userFormReport">
                <img src="./../../assets/images/icons/navbar/report.svg" alt="LeadGen">
                <span>Report</span>
              </router-link>
            </li> -->
          </ul>
        </div>
      </div>
      <!-- DASHBOARD CONTENT -->
      <div :class="{ 'dashboard-content': true, 'dashboard-content__expanded': navbarCollapsed }">
        <div class="dashboard-content__header">
          <div class="dashboard-toggle-navbar">
            <i class="material-icons" @click="toggleNavbar">menu</i>
          </div>
          <div class="dashboard_search"></div>
          <div class="dashboard-quick-actions">
            <UserAccountAction :userName="userName"></UserAccountAction>
          </div>
        </div>
        <div class="dashboard-content__body">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import userMixin from '@/mixins/user'
import dashboardMixin from '@/mixins/dashboard'
import DashboardNotificationManager from '@/components/notification/dashboard/manager'
import UserAccountAction from '@/components/UserAccountAction'
import { featureSlugs } from '@/shared/constants/packagebuilder/features'

export default {
  mixins: [userMixin, dashboardMixin],
  components: {
    DashboardNotificationManager,
    UserAccountAction
  },
  mounted() {
    this.adjustDashboardLayout()
    this.userName = this.user?.name
  },
  data: function () {
    return {
      navbarCollapsed: false,
      userName: null
    }
  },
  methods: {
    toggleNavbar: function () {
      this.navbarCollapsed = !this.navbarCollapsed
    },
    canUseFeature: function (featureSlug) {
      return this.userMixin_hasFeatureAccess(featureSlug)
    }
  },
  computed: {
    featureSlugs: function () {
      return featureSlugs
    }
  }
}
</script>
