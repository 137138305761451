<template>
  <v-container v-if="!flags.loadingContent" class="utm-form-view" style="width: 80%" :loading="flags.loadingContent"
    :disabled="flags.loadingContent">
    <v-row class="align-center justify-space-between mt-n6">
      <v-col cols="12" md="6" class="text-left">
        <router-link :to="{ name: 'utmparams.index.list' }" class="text-primary-red mb-5 link">
          <v-icon left>mdi-arrow-left</v-icon>
          UTM Parameters
        </router-link>
      </v-col>
    </v-row>

    <v-snackbar rounded="md" height="50" :color="snackbar.color" :timeout="3000"
      v-model="snackbar.show">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.message }} </p>
    </v-snackbar>

    <div class="mb-4 d-flex align-center justify-space-between">
      <div class="mb-n4" v-if="code.length > 0 && flags.showCode">
        Embed below script on your Website/Landing page to capture UTM parameters as cookies.
      </div>
      <div v-else class="flex-grow-1"></div>
      <v-btn variant="flat" type="submit" :loading="flags.submitting"
        :prepend-icon="isEditView ? 'mdi-update' : 'mdi-plus'" class="bg-primary-red font-weight-bold text-none px-4"
        @click="onFormSubmit">
        {{ isEditView ? 'Update' : 'Create' }} Parameters
      </v-btn>
    </div>

    <v-card v-if="code.length > 0 && showCode" color="black" class="my-3 rounded-thin overflow-hidden" elevation="0">
      <v-card-text class="pa-0 position-relative">
        <div class="d-flex align-center">
          <v-sheet class="overflow-x-auto bg-black flex-grow-1">
            <pre
              class="my-0 bg-black py-4"><code class="language-html pl-3 bg-black" v-html="highlightedCode"></code></pre>
          </v-sheet>
          <v-btn icon size="x-small" color="grey-lighten-2" class="mr-3" @click="copyCode" title="Copy">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
      <v-card-title class="bg-blue-lighten-5 text-primary-red">
        <h6 class="d-flex justify-center mb-0">
          <v-icon color="primary-blue" icon="mdi-view-list" class="pt-1"></v-icon>
          <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
            {{ isEditView ? 'Update' : 'Create' }} UTM Parameters
          </p>
        </h6>
      </v-card-title>
      <v-card-text>
        <v-form ref="formRef" @submit.prevent="onFormSubmit">
          <v-row class="px-10 py-5">
            <v-col class="pt-3 pb-0 px-3" cols="12" md="6">
              <v-text-field v-model="formData.title" label="Title" prepend-inner-icon="mdi-text-short"
                variant="outlined" density="default" rounded="md" placeholder="Enter Title"
                :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '5', max: '50' }])" required
               ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pt-3 pb-0 px-3">
              <v-text-field v-model="formData.description" label="Description" prepend-inner-icon="mdi-text-long"
                variant="outlined" density="default" placeholder="Enter Description" rounded="md"
                :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '3', max: '300' }])" rows="1" auto-grow
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 px-3">
              <v-autocomplete v-model="formData.form" :items="forms" item-value="id" item-title="title"
                label="Choose Form" placeholder="Choose Form" prepend-inner-icon="mdi-form-select" variant="outlined"
                density="default" rounded="md" :rules="$valid([{ rule: 'required' }])" required
                @update:model-value="handleFormChange" :loading="flags.loadingFormVariant"
                :disabled="flags.loadingFormVariant">
                <template v-slot:prepend-inner>
                  <v-progress-circular v-if="flags.loadingFormVariant" indeterminate
                    color="primary"></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0 px-3">
              <v-autocomplete v-model="formData.variant" :items="variantsOrFallback" item-value="id" item-title="title"
                label="Choose Form Variant" prepend-inner-icon="mdi-form-dropdown" variant="outlined" density="default"
                placeholder="Choose Form Variant" rounded="md" :rules="$valid([{ rule: 'required' }])" required
                @update:model-value="handleVariantChange" :loading="flags.fetchingVariantState"
                :disabled="!formData.form"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0 px-3">
              <v-autocomplete v-model="formData.selectedOption" :items="formData.choiceOptions"
                label="Attribution Choice" prepend-inner-icon="mdi-format-list-text" variant="outlined"
                density="default" placeholder="Choose Attribution Choice" rounded="md"
                :rules="$valid([{ rule: 'required' }])"></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div v-if="flags.loadingContent" class="utm-list-view__loading">
      <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
        color="secondary-red"></v-progress-circular>
    </div>

  </v-container>
  <div class="loader-container" v-else>
    <div class="d-flex justify-center align-center full-screen" style="height: 90vh">
      <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
        color="secondary-red"></v-progress-circular>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import * as actionTypes from '@/store/action-types'
import config from '@/api/config'
import Prism from 'prismjs'

const store = useStore()
const router = useRouter()
const route = useRoute()

const formRef = ref(null)
const forms = ref([])

const formData = ref({
  title: '',
  description: '',
  form: null,
  variant: null,
  attributionChoice: '',
  refId: '',
  selectedOption: null,
  choiceOptions: ['First Touch', 'Last Touch'],
  variantStates: []
})

const flags = ref({
  loadingFormVariant: false,
  fetchingVariantState: false,
  submitting: false,
  showCode: false,
  loadingContent: true
})


const isEditView = computed(() => route.name === 'utmparams.edit')

const variants = computed(() => {
  if (!formData.value.form) {
    return []
  }
  return store.getters.getFormVariants(formData.value.form) || []
})

const fetchForms = async () => {
  try {
    const response = await store.dispatch(actionTypes.FETCH_FORMS, {})
    if (response.data && response.data.data) {
      forms.value = response.data.data.map(form => ({
        id: form.id,
        title: form.title
      }))
    }
  } catch (error) {
    showSnackbar('Error message here', 'error')
  } finally {
    flags.value.loadingContent = false
  }
}

const handleFormChange = async (newFormId) => {
  formData.value.variant = null
  await fetchFormVariants()
}

const handleVariantChange = (value) => {
  if (variants.value.length > 0) {
    fetchFormVariantState()
  }
}

const fetchFormVariants = async (reset = true) => {
  if (reset) {
    formData.value.variant = null
  }
  if (!formData.value.form) return

  flags.value.loadingFormVariant = true

  try {
    const formId = formData.value.form

    const response = await store.dispatch(actionTypes.FETCH_FORM_VARIANTS, { formId: formId })
    await store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response.data.data)

    if (variants.value.length === 0) {
      formData.value.variant = formId
    }
  } catch (error) {
    await store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, { formId: formData.value.form })
    showSnackbar('Failed to fetch form variants. Please try again.', 'error')
  } finally {
    flags.value.loadingFormVariant = false
  }
}

const fetchFormVariantState = async () => {
  if (!formData.value.variant || formData.value.variantStates.some(v => v.id === formData.value.variant)) return

  flags.value.fetchingVariantState = true

  try {
    const response = await store.dispatch(actionTypes.FORM_FETCH_VARIANT_STATE, {
      formId: formData.value.form,
      variantId: formData.value.variant
    })
    formData.value.variantStates.push(response.data.data)
  } catch (error) {
    showSnackbar('Failed to fetch variant state. Please try again.', 'error')
  } finally {
    flags.value.fetchingVariantState = false
  }
}

const variantsOrFallback = computed(() => {
  if (variants.value.length > 0) {
    return variants.value
  } else if (formData.value.form) {
    const selectedForm = forms.value.find(f => f.id === formData.value.form)
    return [{ id: formData.value.form, title: selectedForm ? selectedForm.title : 'Selected Form' }]
  } else {
    return []
  }
})

const fetchUtmParams = async () => {
  try {
    const response = await store.dispatch('utmparamslists/getScript', { id: route.params.id })
    const utmParam = response.data

    formData.value.variantStates.push(utmParam.form_variant)

    formData.value.title = utmParam.title
    formData.value.description = utmParam.description
    formData.value.form = utmParam.form.id
    formData.value.variant = utmParam.form_variant ? utmParam.form_variant.id : utmParam.form.id
    formData.value.refId = utmParam.ref_id
    formData.value.attributionChoice = utmParam.selectedOption
    formData.value.selectedOption = utmParam.choice

    await fetchFormVariants(false)
  } catch (error) {
    showSnackbar('Failed to fetch UTM parameters. Please try again.', 'error')
  } finally {
    flags.value.loadingContent = false
  }
}

watch(() => route.name, async (newValue) => {
  if (isEditView.value) {
    await fetchUtmParams()
    setTimeout(() => {
      Prism.highlightAll()
      flags.value.showCode = true
    }, 1000)
  }
})

const code = computed(() => {
  if (!formData.value.refId) {
    return ''
  }
  return `<script src="${config.scriptsUrl}/utm-params/${formData.value.refId}"></scri` + `pt>`
})

const validateForm = async () => {
  if (formRef.value) {
    const { valid, errors } = await formRef.value.validate()
    return { valid, errors }
  }
  return { valid: false, errors: ['Form reference not found'] }
}

const onFormSubmit = async () => {
  const { valid, errors } = await validateForm()
  if (!valid) {
    return
  }

  flags.value.submitting = true
  try {
    const data = {
      title: formData.value.title,
      description: formData.value.description,
      form_variant_id: formData.value.variant,
      choice: formData.value.selectedOption
    }

    let response
    if (!isEditView.value) {
      response = await store.dispatch('utmparamslists/createScript', data)
      showSnackbar('UTM parameters created successfully')
      router.push({ name: 'utmparams.edit', params: { id: response.data.data.id } })
    } else {
      response = await store.dispatch('utmparamslists/updateScript', {
        id: parseInt(route.params.id),
        data: data
      })
      showSnackbar('UTM parameters updated successfully')
    }
  } catch (error) {
    if (error.response?.data?.meta?.error_type === 'INAPPROPRIATE_PLAN') {
      showSnackbar('Please upgrade your plan to create UTM parameters.', 'error')
    } else {
      showSnackbar('Failed to save UTM parameters. Please try again.', 'error')
    }
  } finally {
    flags.value.submitting = false
  }
}

const snackbar = ref({
  show: false,
  message: '',
  color: ''
})

const showSnackbar = (message, color = 'success') => {
  snackbar.value.show = true
  snackbar.value.message = message
  snackbar.value.color = color
}
const highlightedCode = computed(() => {
  return code.value ? Prism.highlight(code.value, Prism.languages.html, 'html') : ''
})

const copyCode = () => {
  navigator.clipboard.writeText(code.value)
    .then(() => showSnackbar('Code copied to clipboard!'))
}

watch(code, (newValue) => {
  if (newValue.length > 0) {
    setTimeout(() => {
      Prism.highlightAll()
      flags.value.showCode = true
    }, 100)
  }
})
const showCode = ref(false)


const highlightCode = async () => {
  await nextTick()
  Prism.highlightAll()
}

watch(code, highlightCode)
watch(flags.showCode, highlightCode)
watch(code, async (newCode) => {
  if (newCode.length === 0) return
  showCode.value = true
  await highlightCode()
})
onMounted(async () => {
  fetchForms()
  if (isEditView.value) {
    await highlightCode()
    fetchUtmParams()
  }
  await highlightCode()
})
</script>
