/* eslint-disable */

export default [
  {
      'id': 1,
      'name': 'Afghanistan',
      'iso3': 'AFG',
      'iso2': 'AF',
      'country_code': '4',
      'phone_code': '93',
      'capital': 'Kabul',
      'currency': 'AFN',
      'states': [
        'Badakhshan',
        'Badgis',
        'Baglan',
        'Balkh',
        'Bamiyan',
        'Farah',
        'Faryab',
        'Gawr',
        'Gazni',
        'Herat',
        'Hilmand',
        'Jawzjan',
        'Kabul',
        'Kapisa',
        'Khawst',
        'Kunar',
        'Lagman',
        'Lawghar',
        'Nangarhar',
        'Nimruz',
        'Nuristan',
        'Paktika',
        'Paktiya',
        'Parwan',
        'Qandahar',
        'Qunduz',
        'Samangan',
        'Sar-e Pul',
        'Takhar',
        'Uruzgan',
        'Wardag',
        'Zabul'
      ]
  },
  {
      'id': 3,
      'name': 'Albania',
      'iso3': 'ALB',
      'iso2': 'AL',
      'country_code': '8',
      'phone_code': '355',
      'capital': 'Tirana',
      'currency': 'ALL',
      'states': [
          'Berat',
          'Bulqize',
          'Delvine',
          'Devoll',
          'Dibre',
          'Durres',
          'Elbasan',
          'Fier',
          'Gjirokaster',
          'Gramsh',
          'Has',
          'Kavaje',
          'Kolonje',
          'Korce',
          'Kruje',
          'Kucove',
          'Kukes',
          'Kurbin',
          'Lezhe',
          'Librazhd',
          'Lushnje',
          'Mallakaster',
          'Malsi e Madhe',
          'Mat',
          'Mirdite',
          'Peqin',
          'Permet',
          'Pogradec',
          'Puke',
          'Sarande',
          'Shkoder',
          'Skrapar',
          'Tepelene',
          'Tirane',
          'Tropoje',
          'Vlore'
      ]
  },
  {
      'id': 4,
      'name': 'Algeria',
      'iso3': 'DZA',
      'iso2': 'DZ',
      'country_code': '12',
      'phone_code': '213',
      'capital': 'Algiers',
      'currency': 'DZD',
      'states': [
          '\'Ayn Daflah',
          '\'Ayn Tamushanat',
          'Adrar',
          'Algiers',
          'Annabah',
          'Bashshar',
          'Batnah',
          'Bijayah',
          'Biskrah',
          'Blidah',
          'Buirah',
          'Bumardas',
          'Burj Bu Arririj',
          'Ghalizan',
          'Ghardayah',
          'Ilizi',
          'Jijili',
          'Jilfah',
          'Khanshalah',
          'Masilah',
          'Midyah',
          'Milah',
          'Muaskar',
          'Mustaghanam',
          'Naama',
          'Oran',
          'Ouargla',
          'Qalmah',
          'Qustantinah',
          'Sakikdah',
          'Satif',
          'Sayda\'',
          'Sidi ban-al-\'Abbas',
          'Suq Ahras',
          'Tamanghasat',
          'Tibazah',
          'Tibissah',
          'Tilimsan',
          'Tinduf',
          'Tisamsilt',
          'Tiyarat',
          'Tizi Wazu',
          'Umm-al-Bawaghi',
          'Wahran',
          'Warqla',
          'Wilaya d Alger',
          'Wilaya de Bejaia',
          'Wilaya de Constantine',
          'al-Aghwat',
          'al-Bayadh',
          'al-Jaza\'ir',
          'al-Wad',
          'ash-Shalif',
          'at-Tarif'
      ]
  },
  {
      'id': 5,
      'name': 'American Samoa',
      'iso3': 'ASM',
      'iso2': 'AS',
      'country_code': '16',
      'phone_code': '+1-684',
      'capital': 'Pago Pago',
      'currency': 'USD',
      'states': [
          'Eastern',
          'Manu\'a',
          'Swains Island',
          'Western'
      ]
  },
  {
      'id': 6,
      'name': 'Andorra',
      'iso3': 'AND',
      'iso2': 'AD',
      'country_code': '20',
      'phone_code': '376',
      'capital': 'Andorra la Vella',
      'currency': 'EUR',
      'states': [
          'Andorra la Vella',
          'Canillo',
          'Encamp',
          'La Massana',
          'Les Escaldes',
          'Ordino',
          'Sant Julia de Loria'
      ]
  },
  {
      'id': 7,
      'name': 'Angola',
      'iso3': 'AGO',
      'iso2': 'AO',
      'country_code': '24',
      'phone_code': '244',
      'capital': 'Luanda',
      'currency': 'AOA',
      'states': [
          'Bengo',
          'Benguela',
          'Bie',
          'Cabinda',
          'Cunene',
          'Huambo',
          'Huila',
          'Kuando-Kubango',
          'Kwanza Norte',
          'Kwanza Sul',
          'Luanda',
          'Lunda Norte',
          'Lunda Sul',
          'Malanje',
          'Moxico',
          'Namibe',
          'Uige',
          'Zaire'
      ]
  },
  {
      'id': 8,
      'name': 'Anguilla',
      'iso3': 'AIA',
      'iso2': 'AI',
      'country_code': '660',
      'phone_code': '+1-264',
      'capital': 'The Valley',
      'currency': 'XCD',
      'states': [
          'Other Provinces'
      ]
  },
  {
      'id': 9,
      'name': 'Antarctica',
      'iso3': 'ATA',
      'iso2': 'AQ',
      'country_code': '10',
      'phone_code': '',
      'capital': '',
      'currency': '',
      'states': [
          'Sector claimed by Argentina\/Ch',
          'Sector claimed by Argentina\/UK',
          'Sector claimed by Australia',
          'Sector claimed by France',
          'Sector claimed by New Zealand',
          'Sector claimed by Norway',
          'Unclaimed Sector'
      ]
  },
  {
      'id': 10,
      'name': 'Antigua And Barbuda',
      'iso3': 'ATG',
      'iso2': 'AG',
      'country_code': '28',
      'phone_code': '+1-268',
      'capital': 'St. John\'s',
      'currency': 'XCD',
      'states': [
          'Barbuda',
          'Saint George',
          'Saint John',
          'Saint Mary',
          'Saint Paul',
          'Saint Peter',
          'Saint Philip'
      ]
  },
  {
      'id': 11,
      'name': 'Argentina',
      'iso3': 'ARG',
      'iso2': 'AR',
      'country_code': '32',
      'phone_code': '54',
      'capital': 'Buenos Aires',
      'currency': 'ARS',
      'states': [
          'Buenos Aires',
          'Catamarca',
          'Chaco',
          'Chubut',
          'Cordoba',
          'Corrientes',
          'Distrito Federal',
          'Entre Rios',
          'Formosa',
          'Jujuy',
          'La Pampa',
          'La Rioja',
          'Mendoza',
          'Misiones',
          'Neuquen',
          'Rio Negro',
          'Salta',
          'San Juan',
          'San Luis',
          'Santa Cruz',
          'Santa Fe',
          'Santiago del Estero',
          'Tierra del Fuego',
          'Tucuman'
      ]
  },
  {
      'id': 12,
      'name': 'Armenia',
      'iso3': 'ARM',
      'iso2': 'AM',
      'country_code': '51',
      'phone_code': '374',
      'capital': 'Yerevan',
      'currency': 'AMD',
      'states': [
          'Aragatsotn',
          'Ararat',
          'Armavir',
          'Gegharkunik',
          'Kotaik',
          'Lori',
          'Shirak',
          'Stepanakert',
          'Syunik',
          'Tavush',
          'Vayots Dzor',
          'Yerevan'
      ]
  },
  {
      'id': 13,
      'name': 'Aruba',
      'iso3': 'ABW',
      'iso2': 'AW',
      'country_code': '533',
      'phone_code': '297',
      'capital': 'Oranjestad',
      'currency': 'AWG',
      'states': [
          'Aruba'
      ]
  },
  {
      'id': 14,
      'name': 'Australia',
      'iso3': 'AUS',
      'iso2': 'AU',
      'country_code': '36',
      'phone_code': '61',
      'capital': 'Canberra',
      'currency': 'AUD',
      'states': [
          'Auckland',
          'Australian Capital Territory',
          'Balgowlah',
          'Balmain',
          'Bankstown',
          'Baulkham Hills',
          'Bonnet Bay',
          'Camberwell',
          'Carole Park',
          'Castle Hill',
          'Caulfield',
          'Chatswood',
          'Cheltenham',
          'Cherrybrook',
          'Clayton',
          'Collingwood',
          'Frenchs Forest',
          'Hawthorn',
          'Jannnali',
          'Knoxfield',
          'Melbourne',
          'New South Wales',
          'Northern Territory',
          'Perth',
          'Queensland',
          'South Australia',
          'Tasmania',
          'Templestowe',
          'Victoria',
          'Werribee south',
          'Western Australia',
          'Wheeler'
      ]
  },
  {
      'id': 15,
      'name': 'Austria',
      'iso3': 'AUT',
      'iso2': 'AT',
      'country_code': '40',
      'phone_code': '43',
      'capital': 'Vienna',
      'currency': 'EUR',
      'states': [
          'Bundesland Salzburg',
          'Bundesland Steiermark',
          'Bundesland Tirol',
          'Burgenland',
          'Carinthia',
          'Karnten',
          'Liezen',
          'Lower Austria',
          'Niederosterreich',
          'Oberosterreich',
          'Salzburg',
          'Schleswig-Holstein',
          'Steiermark',
          'Styria',
          'Tirol',
          'Upper Austria',
          'Vorarlberg',
          'Wien'
      ]
  },
  {
      'id': 16,
      'name': 'Azerbaijan',
      'iso3': 'AZE',
      'iso2': 'AZ',
      'country_code': '31',
      'phone_code': '994',
      'capital': 'Baku',
      'currency': 'AZN',
      'states': [
          'Abseron',
          'Baki Sahari',
          'Ganca',
          'Ganja',
          'Kalbacar',
          'Lankaran',
          'Mil-Qarabax',
          'Mugan-Salyan',
          'Nagorni-Qarabax',
          'Naxcivan',
          'Priaraks',
          'Qazax',
          'Saki',
          'Sirvan',
          'Xacmaz'
      ]
  },
  {
      'id': 18,
      'name': 'Bahrain',
      'iso3': 'BHR',
      'iso2': 'BH',
      'country_code': '48',
      'phone_code': '973',
      'capital': 'Manama',
      'currency': 'BHD',
      'states': [
          '\'Isa',
          'Badiyah',
          'Hidd',
          'Jidd Hafs',
          'Mahama',
          'Manama',
          'Sitrah',
          'al-Manamah',
          'al-Muharraq',
          'ar-Rifa\'a'
      ]
  },
  {
      'id': 19,
      'name': 'Bangladesh',
      'iso3': 'BGD',
      'iso2': 'BD',
      'country_code': '50',
      'phone_code': '880',
      'capital': 'Dhaka',
      'currency': 'BDT',
      'states': [
          'Bagar Hat',
          'Bandarban',
          'Barguna',
          'Barisal',
          'Bhola',
          'Bogora',
          'Brahman Bariya',
          'Chandpur',
          'Chattagam',
          'Chittagong Division',
          'Chuadanga',
          'Dhaka',
          'Dinajpur',
          'Faridpur',
          'Feni',
          'Gaybanda',
          'Gazipur',
          'Gopalganj',
          'Habiganj',
          'Jaipur Hat',
          'Jamalpur',
          'Jessor',
          'Jhalakati',
          'Jhanaydah',
          'Khagrachhari',
          'Khulna',
          'Kishorganj',
          'Koks Bazar',
          'Komilla',
          'Kurigram',
          'Kushtiya',
          'Lakshmipur',
          'Lalmanir Hat',
          'Madaripur',
          'Magura',
          'Maimansingh',
          'Manikganj',
          'Maulvi Bazar',
          'Meherpur',
          'Munshiganj',
          'Naral',
          'Narayanganj',
          'Narsingdi',
          'Nator',
          'Naugaon',
          'Nawabganj',
          'Netrakona',
          'Nilphamari',
          'Noakhali',
          'Pabna',
          'Panchagarh',
          'Patuakhali',
          'Pirojpur',
          'Rajbari',
          'Rajshahi',
          'Rangamati',
          'Rangpur',
          'Satkhira',
          'Shariatpur',
          'Sherpur',
          'Silhat',
          'Sirajganj',
          'Sunamganj',
          'Tangayal',
          'Thakurgaon'
      ]
  },
  {
      'id': 20,
      'name': 'Barbados',
      'iso3': 'BRB',
      'iso2': 'BB',
      'country_code': '52',
      'phone_code': '+1-246',
      'capital': 'Bridgetown',
      'currency': 'BBD',
      'states': [
          'Christ Church',
          'Saint Andrew',
          'Saint George',
          'Saint James',
          'Saint John',
          'Saint Joseph',
          'Saint Lucy',
          'Saint Michael',
          'Saint Peter',
          'Saint Philip',
          'Saint Thomas'
      ]
  },
  {
      'id': 21,
      'name': 'Belarus',
      'iso3': 'BLR',
      'iso2': 'BY',
      'country_code': '112',
      'phone_code': '375',
      'capital': 'Minsk',
      'currency': 'BYR',
      'states': [
          'Brest',
          'Homjel\'',
          'Hrodna',
          'Mahiljow',
          'Mahilyowskaya Voblasts',
          'Minsk',
          'Minskaja Voblasts\'',
          'Petrik',
          'Vicebsk'
      ]
  },
  {
      'id': 22,
      'name': 'Belgium',
      'iso3': 'BEL',
      'iso2': 'BE',
      'country_code': '56',
      'phone_code': '32',
      'capital': 'Brussels',
      'currency': 'EUR',
      'states': [
          'Antwerpen',
          'Berchem',
          'Brabant',
          'Brabant Wallon',
          'Brussel',
          'East Flanders',
          'Hainaut',
          'Liege',
          'Limburg',
          'Luxembourg',
          'Namur',
          'Ontario',
          'Oost-Vlaanderen',
          'Provincie Brabant',
          'Vlaams-Brabant',
          'Wallonne',
          'West-Vlaanderen'
      ]
  },
  {
      'id': 23,
      'name': 'Belize',
      'iso3': 'BLZ',
      'iso2': 'BZ',
      'country_code': '84',
      'phone_code': '501',
      'capital': 'Belmopan',
      'currency': 'BZD',
      'states': [
          'Belize',
          'Cayo',
          'Corozal',
          'Orange Walk',
          'Stann Creek',
          'Toledo'
      ]
  },
  {
      'id': 24,
      'name': 'Benin',
      'iso3': 'BEN',
      'iso2': 'BJ',
      'country_code': '204',
      'phone_code': '229',
      'capital': 'Porto-Novo',
      'currency': 'XOF',
      'states': [
          'Alibori',
          'Atacora',
          'Atlantique',
          'Borgou',
          'Collines',
          'Couffo',
          'Donga',
          'Littoral',
          'Mono',
          'Oueme',
          'Plateau',
          'Zou'
      ]
  },
  {
      'id': 25,
      'name': 'Bermuda',
      'iso3': 'BMU',
      'iso2': 'BM',
      'country_code': '60',
      'phone_code': '+1-441',
      'capital': 'Hamilton',
      'currency': 'BMD',
      'states': [
          'Hamilton',
          'Saint George'
      ]
  },
  {
      'id': 26,
      'name': 'Bhutan',
      'iso3': 'BTN',
      'iso2': 'BT',
      'country_code': '64',
      'phone_code': '975',
      'capital': 'Thimphu',
      'currency': 'BTN',
      'states': [
          'Bumthang',
          'Chhukha',
          'Chirang',
          'Daga',
          'Geylegphug',
          'Ha',
          'Lhuntshi',
          'Mongar',
          'Pemagatsel',
          'Punakha',
          'Rinpung',
          'Samchi',
          'Samdrup Jongkhar',
          'Shemgang',
          'Tashigang',
          'Timphu',
          'Tongsa',
          'Wangdiphodrang'
      ]
  },
  {
      'id': 27,
      'name': 'Bolivia',
      'iso3': 'BOL',
      'iso2': 'BO',
      'country_code': '68',
      'phone_code': '591',
      'capital': 'Sucre',
      'currency': 'BOB',
      'states': [
          'Beni',
          'Chuquisaca',
          'Cochabamba',
          'La Paz',
          'Oruro',
          'Pando',
          'Potosi',
          'Santa Cruz',
          'Tarija'
      ]
  },
  {
      'id': 28,
      'name': 'Bosnia and Herzegovina',
      'iso3': 'BIH',
      'iso2': 'BA',
      'country_code': '70',
      'phone_code': '387',
      'capital': 'Sarajevo',
      'currency': 'BAM',
      'states': [
          'Federacija Bosna i Hercegovina',
          'Republika Srpska'
      ]
  },
  {
      'id': 29,
      'name': 'Botswana',
      'iso3': 'BWA',
      'iso2': 'BW',
      'country_code': '72',
      'phone_code': '267',
      'capital': 'Gaborone',
      'currency': 'BWP',
      'states': [
          'Central Bobonong',
          'Central Boteti',
          'Central Mahalapye',
          'Central Serowe-Palapye',
          'Central Tutume',
          'Chobe',
          'Francistown',
          'Gaborone',
          'Ghanzi',
          'Jwaneng',
          'Kgalagadi North',
          'Kgalagadi South',
          'Kgatleng',
          'Kweneng',
          'Lobatse',
          'Ngamiland',
          'Ngwaketse',
          'North East',
          'Okavango',
          'Orapa',
          'Selibe Phikwe',
          'South East',
          'Sowa'
      ]
  },
  {
      'id': 30,
      'name': 'Bouvet Island',
      'iso3': 'BVT',
      'iso2': 'BV',
      'country_code': '74',
      'phone_code': '',
      'capital': '',
      'currency': 'NOK',
      'states': [
          'Bouvet Island'
      ]
  },
  {
      'id': 31,
      'name': 'Brazil',
      'iso3': 'BRA',
      'iso2': 'BR',
      'country_code': '76',
      'phone_code': '55',
      'capital': 'Brasilia',
      'currency': 'BRL',
      'states': [
          'Acre',
          'Alagoas',
          'Amapa',
          'Amazonas',
          'Bahia',
          'Ceara',
          'Distrito Federal',
          'Espirito Santo',
          'Estado de Sao Paulo',
          'Goias',
          'Maranhao',
          'Mato Grosso',
          'Mato Grosso do Sul',
          'Minas Gerais',
          'Para',
          'Paraiba',
          'Parana',
          'Pernambuco',
          'Piaui',
          'Rio Grande do Norte',
          'Rio Grande do Sul',
          'Rio de Janeiro',
          'Rondonia',
          'Roraima',
          'Santa Catarina',
          'Sao Paulo',
          'Sergipe',
          'Tocantins'
      ]
  },
  {
      'id': 32,
      'name': 'British Indian Ocean Territory',
      'iso3': 'IOT',
      'iso2': 'IO',
      'country_code': '86',
      'phone_code': '246',
      'capital': 'Diego Garcia',
      'currency': 'USD',
      'states': [
          'British Indian Ocean Territory'
      ]
  },
  {
      'id': 34,
      'name': 'Bulgaria',
      'iso3': 'BGR',
      'iso2': 'BG',
      'country_code': '100',
      'phone_code': '359',
      'capital': 'Sofia',
      'currency': 'BGN',
      'states': [
          'Blagoevgrad',
          'Burgas',
          'Dobrich',
          'Gabrovo',
          'Haskovo',
          'Jambol',
          'Kardzhali',
          'Kjustendil',
          'Lovech',
          'Montana',
          'Oblast Sofiya-Grad',
          'Pazardzhik',
          'Pernik',
          'Pleven',
          'Plovdiv',
          'Razgrad',
          'Ruse',
          'Shumen',
          'Silistra',
          'Sliven',
          'Smoljan',
          'Sofija grad',
          'Sofijska oblast',
          'Stara Zagora',
          'Targovishte',
          'Varna',
          'Veliko Tarnovo',
          'Vidin',
          'Vraca',
          'Yablaniza'
      ]
  },
  {
      'id': 35,
      'name': 'Burkina Faso',
      'iso3': 'BFA',
      'iso2': 'BF',
      'country_code': '854',
      'phone_code': '226',
      'capital': 'Ouagadougou',
      'currency': 'XOF',
      'states': [
          'Bale',
          'Bam',
          'Bazega',
          'Bougouriba',
          'Boulgou',
          'Boulkiemde',
          'Comoe',
          'Ganzourgou',
          'Gnagna',
          'Gourma',
          'Houet',
          'Ioba',
          'Kadiogo',
          'Kenedougou',
          'Komandjari',
          'Kompienga',
          'Kossi',
          'Kouritenga',
          'Kourweogo',
          'Leraba',
          'Mouhoun',
          'Nahouri',
          'Namentenga',
          'Noumbiel',
          'Oubritenga',
          'Oudalan',
          'Passore',
          'Poni',
          'Sanguie',
          'Sanmatenga',
          'Seno',
          'Sissili',
          'Soum',
          'Sourou',
          'Tapoa',
          'Tuy',
          'Yatenga',
          'Zondoma',
          'Zoundweogo'
      ]
  },
  {
      'id': 36,
      'name': 'Burundi',
      'iso3': 'BDI',
      'iso2': 'BI',
      'country_code': '108',
      'phone_code': '257',
      'capital': 'Bujumbura',
      'currency': 'BIF',
      'states': [
          'Bubanza',
          'Bujumbura',
          'Bururi',
          'Cankuzo',
          'Cibitoke',
          'Gitega',
          'Karuzi',
          'Kayanza',
          'Kirundo',
          'Makamba',
          'Muramvya',
          'Muyinga',
          'Ngozi',
          'Rutana',
          'Ruyigi'
      ]
  },
  {
      'id': 37,
      'name': 'Cambodia',
      'iso3': 'KHM',
      'iso2': 'KH',
      'country_code': '116',
      'phone_code': '855',
      'capital': 'Phnom Penh',
      'currency': 'KHR',
      'states': [
          'Banteay Mean Chey',
          'Bat Dambang',
          'Kampong Cham',
          'Kampong Chhnang',
          'Kampong Spoeu',
          'Kampong Thum',
          'Kampot',
          'Kandal',
          'Kaoh Kong',
          'Kracheh',
          'Krong Kaeb',
          'Krong Pailin',
          'Krong Preah Sihanouk',
          'Mondol Kiri',
          'Otdar Mean Chey',
          'Phnum Penh',
          'Pousat',
          'Preah Vihear',
          'Prey Veaeng',
          'Rotanak Kiri',
          'Siem Reab',
          'Stueng Traeng',
          'Svay Rieng',
          'Takaev'
      ]
  },
  {
      'id': 38,
      'name': 'Cameroon',
      'iso3': 'CMR',
      'iso2': 'CM',
      'country_code': '120',
      'phone_code': '237',
      'capital': 'Yaounde',
      'currency': 'XAF',
      'states': [
          'Adamaoua',
          'Centre',
          'Est',
          'Littoral',
          'Nord',
          'Nord Extreme',
          'Nordouest',
          'Ouest',
          'Sud',
          'Sudouest'
      ]
  },
  {
      'id': 39,
      'name': 'Canada',
      'iso3': 'CAN',
      'iso2': 'CA',
      'country_code': '124',
      'phone_code': '1',
      'capital': 'Ottawa',
      'currency': 'CAD',
      'states': [
          'Alberta',
          'British Columbia',
          'Manitoba',
          'New Brunswick',
          'Newfoundland and Labrador',
          'Northwest Territories',
          'Nova Scotia',
          'Nunavut',
          'Ontario',
          'Prince Edward Island',
          'Quebec',
          'Saskatchewan',
          'Yukon'
      ]
  },
  {
      'id': 40,
      'name': 'Cape Verde',
      'iso3': 'CPV',
      'iso2': 'CV',
      'country_code': '132',
      'phone_code': '238',
      'capital': 'Praia',
      'currency': 'CVE',
      'states': [
          'Boavista',
          'Brava',
          'Fogo',
          'Maio',
          'Sal',
          'Santo Antao',
          'Sao Nicolau',
          'Sao Tiago',
          'Sao Vicente'
      ]
  },
  {
      'id': 41,
      'name': 'Cayman Islands',
      'iso3': 'CYM',
      'iso2': 'KY',
      'country_code': '136',
      'phone_code': '+1-345',
      'capital': 'George Town',
      'currency': 'KYD',
      'states': [
          'Grand Cayman'
      ]
  },
  {
      'id': 42,
      'name': 'Central African Republic',
      'iso3': 'CAF',
      'iso2': 'CF',
      'country_code': '140',
      'phone_code': '236',
      'capital': 'Bangui',
      'currency': 'XAF',
      'states': [
          'Bamingui-Bangoran',
          'Bangui',
          'Basse-Kotto',
          'Haut-Mbomou',
          'Haute-Kotto',
          'Kemo',
          'Lobaye',
          'Mambere-Kadei',
          'Mbomou',
          'Nana-Gribizi',
          'Nana-Mambere',
          'Ombella Mpoko',
          'Ouaka',
          'Ouham',
          'Ouham-Pende',
          'Sangha-Mbaere',
          'Vakaga'
      ]
  },
  {
      'id': 43,
      'name': 'Chad',
      'iso3': 'TCD',
      'iso2': 'TD',
      'country_code': '148',
      'phone_code': '235',
      'capital': 'N\'Djamena',
      'currency': 'XAF',
      'states': [
          'Batha',
          'Biltine',
          'Bourkou-Ennedi-Tibesti',
          'Chari-Baguirmi',
          'Guera',
          'Kanem',
          'Lac',
          'Logone Occidental',
          'Logone Oriental',
          'Mayo-Kebbi',
          'Moyen-Chari',
          'Ouaddai',
          'Salamat',
          'Tandjile'
      ]
  },
  {
      'id': 44,
      'name': 'Chile',
      'iso3': 'CHL',
      'iso2': 'CL',
      'country_code': '152',
      'phone_code': '56',
      'capital': 'Santiago',
      'currency': 'CLP',
      'states': [
          'Aisen',
          'Antofagasta',
          'Araucania',
          'Atacama',
          'Bio Bio',
          'Coquimbo',
          'Libertador General Bernardo O\'',
          'Los Lagos',
          'Magellanes',
          'Maule',
          'Metropolitana',
          'Metropolitana de Santiago',
          'Tarapaca',
          'Valparaiso'
      ]
  },
  {
      'id': 45,
      'name': 'China',
      'iso3': 'CHN',
      'iso2': 'CN',
      'country_code': '156',
      'phone_code': '86',
      'capital': 'Beijing',
      'currency': 'CNY',
      'states': [
          'Anhui',
          'Anhui Province',
          'Anhui Sheng',
          'Aomen',
          'Beijing',
          'Beijing Shi',
          'Chongqing',
          'Fujian',
          'Fujian Sheng',
          'Gansu',
          'Guangdong',
          'Guangdong Sheng',
          'Guangxi',
          'Guizhou',
          'Hainan',
          'Hebei',
          'Heilongjiang',
          'Henan',
          'Hubei',
          'Hunan',
          'Jiangsu',
          'Jiangsu Sheng',
          'Jiangxi',
          'Jilin',
          'Liaoning',
          'Liaoning Sheng',
          'Nei Monggol',
          'Ningxia Hui',
          'Qinghai',
          'Shaanxi',
          'Shandong',
          'Shandong Sheng',
          'Shanghai',
          'Shanxi',
          'Sichuan',
          'Tianjin',
          'Xianggang',
          'Xinjiang',
          'Xizang',
          'Yunnan',
          'Zhejiang',
          'Zhejiang Sheng'
      ]
  },
  {
      'id': 46,
      'name': 'Christmas Island',
      'iso3': 'CXR',
      'iso2': 'CX',
      'country_code': '162',
      'phone_code': '61',
      'capital': 'Flying Fish Cove',
      'currency': 'AUD',
      'states': [
          'Christmas Island'
      ]
  },
  {
      'id': 47,
      'name': 'Cocos (Keeling) Islands',
      'iso3': 'CCK',
      'iso2': 'CC',
      'country_code': '166',
      'phone_code': '61',
      'capital': 'West Island',
      'currency': 'AUD',
      'states': [
          'Cocos (Keeling) Islands'
      ]
  },
  {
      'id': 48,
      'name': 'Colombia',
      'iso3': 'COL',
      'iso2': 'CO',
      'country_code': '170',
      'phone_code': '57',
      'capital': 'Bogota',
      'currency': 'COP',
      'states': [
          'Amazonas',
          'Antioquia',
          'Arauca',
          'Atlantico',
          'Bogota',
          'Bolivar',
          'Boyaca',
          'Caldas',
          'Caqueta',
          'Casanare',
          'Cauca',
          'Cesar',
          'Choco',
          'Cordoba',
          'Cundinamarca',
          'Guainia',
          'Guaviare',
          'Huila',
          'La Guajira',
          'Magdalena',
          'Meta',
          'Narino',
          'Norte de Santander',
          'Putumayo',
          'Quindio',
          'Risaralda',
          'San Andres y Providencia',
          'Santander',
          'Sucre',
          'Tolima',
          'Valle del Cauca',
          'Vaupes',
          'Vichada'
      ]
  },
  {
      'id': 49,
      'name': 'Comoros',
      'iso3': 'COM',
      'iso2': 'KM',
      'country_code': '174',
      'phone_code': '269',
      'capital': 'Moroni',
      'currency': 'KMF',
      'states': [
          'Mwali',
          'Njazidja',
          'Nzwani'
      ]
  },
  {
      'id': 50,
      'name': 'Congo',
      'iso3': 'COG',
      'iso2': 'CG',
      'country_code': '178',
      'phone_code': '242',
      'capital': 'Brazzaville',
      'currency': 'XAF',
      'states': [
          'Bouenza',
          'Brazzaville',
          'Cuvette',
          'Kouilou',
          'Lekoumou',
          'Likouala',
          'Niari',
          'Plateaux',
          'Pool',
          'Sangha'
      ]
  },
  {
      'id': 52,
      'name': 'Cook Islands',
      'iso3': 'COK',
      'iso2': 'CK',
      'country_code': '184',
      'phone_code': '682',
      'capital': 'Avarua',
      'currency': 'NZD',
      'states': [
          'Aitutaki',
          'Atiu',
          'Mangaia',
          'Manihiki',
          'Mauke',
          'Mitiaro',
          'Nassau',
          'Pukapuka',
          'Rakahanga',
          'Rarotonga',
          'Tongareva'
      ]
  },
  {
      'id': 53,
      'name': 'Costa Rica',
      'iso3': 'CRI',
      'iso2': 'CR',
      'country_code': '188',
      'phone_code': '506',
      'capital': 'San Jose',
      'currency': 'CRC',
      'states': [
          'Alajuela',
          'Cartago',
          'Guanacaste',
          'Heredia',
          'Limon',
          'Puntarenas',
          'San Jose'
      ]
  },
  {
      'id': 56,
      'name': 'Cuba',
      'iso3': 'CUB',
      'iso2': 'CU',
      'country_code': '192',
      'phone_code': '53',
      'capital': 'Havana',
      'currency': 'CUP',
      'states': [
          'Camaguey',
          'Ciego de Avila',
          'Cienfuegos',
          'Ciudad de la Habana',
          'Granma',
          'Guantanamo',
          'Habana',
          'Holguin',
          'Isla de la Juventud',
          'La Habana',
          'Las Tunas',
          'Matanzas',
          'Pinar del Rio',
          'Sancti Spiritus',
          'Santiago de Cuba',
          'Villa Clara'
      ]
  },
  {
      'id': 57,
      'name': 'Cyprus',
      'iso3': 'CYP',
      'iso2': 'CY',
      'country_code': '196',
      'phone_code': '357',
      'capital': 'Nicosia',
      'currency': 'EUR',
      'states': [
          'Government controlled area',
          'Limassol',
          'Nicosia District',
          'Paphos',
          'Turkish controlled area'
      ]
  },
  {
      'id': 58,
      'name': 'Czech Republic',
      'iso3': 'CZE',
      'iso2': 'CZ',
      'country_code': '203',
      'phone_code': '420',
      'capital': 'Prague',
      'currency': 'CZK',
      'states': [
          'Central Bohemian',
          'Frycovice',
          'Jihocesky Kraj',
          'Jihochesky',
          'Jihomoravsky',
          'Karlovarsky',
          'Klecany',
          'Kralovehradecky',
          'Liberecky',
          'Lipov',
          'Moravskoslezsky',
          'Olomoucky',
          'Olomoucky Kraj',
          'Pardubicky',
          'Plzensky',
          'Praha',
          'Rajhrad',
          'Smirice',
          'South Moravian',
          'Straz nad Nisou',
          'Stredochesky',
          'Unicov',
          'Ustecky',
          'Valletta',
          'Velesin',
          'Vysochina',
          'Zlinsky'
      ]
  },
  {
      'id': 59,
      'name': 'Denmark',
      'iso3': 'DNK',
      'iso2': 'DK',
      'country_code': '208',
      'phone_code': '45',
      'capital': 'Copenhagen',
      'currency': 'DKK',
      'states': [
          'Arhus',
          'Bornholm',
          'Frederiksborg',
          'Fyn',
          'Hovedstaden',
          'Kobenhavn',
          'Kobenhavns Amt',
          'Kobenhavns Kommune',
          'Nordjylland',
          'Ribe',
          'Ringkobing',
          'Roervig',
          'Roskilde',
          'Roslev',
          'Sjaelland',
          'Soeborg',
          'Sonderjylland',
          'Storstrom',
          'Syddanmark',
          'Toelloese',
          'Vejle',
          'Vestsjalland',
          'Viborg'
      ]
  },
  {
      'id': 60,
      'name': 'Djibouti',
      'iso3': 'DJI',
      'iso2': 'DJ',
      'country_code': '262',
      'phone_code': '253',
      'capital': 'Djibouti',
      'currency': 'DJF',
      'states': [
          '\'Ali Sabih',
          'Dikhil',
          'Jibuti',
          'Tajurah',
          'Ubuk'
      ]
  },
  {
      'id': 61,
      'name': 'Dominica',
      'iso3': 'DMA',
      'iso2': 'DM',
      'country_code': '212',
      'phone_code': '+1-767',
      'capital': 'Roseau',
      'currency': 'XCD',
      'states': [
          'Saint Andrew',
          'Saint David',
          'Saint George',
          'Saint John',
          'Saint Joseph',
          'Saint Luke',
          'Saint Mark',
          'Saint Patrick',
          'Saint Paul',
          'Saint Peter'
      ]
  },
  {
      'id': 62,
      'name': 'Dominican Republic',
      'iso3': 'DOM',
      'iso2': 'DO',
      'country_code': '214',
      'phone_code': '+1-809 and 1-829',
      'capital': 'Santo Domingo',
      'currency': 'DOP',
      'states': [
          'Azua',
          'Bahoruco',
          'Barahona',
          'Dajabon',
          'Distrito Nacional',
          'Duarte',
          'El Seybo',
          'Elias Pina',
          'Espaillat',
          'Hato Mayor',
          'Independencia',
          'La Altagracia',
          'La Romana',
          'La Vega',
          'Maria Trinidad Sanchez',
          'Monsenor Nouel',
          'Monte Cristi',
          'Monte Plata',
          'Pedernales',
          'Peravia',
          'Puerto Plata',
          'Salcedo',
          'Samana',
          'San Cristobal',
          'San Juan',
          'San Pedro de Macoris',
          'Sanchez Ramirez',
          'Santiago',
          'Santiago Rodriguez',
          'Valverde'
      ]
  },
  {
      'id': 63,
      'name': 'East Timor',
      'iso3': 'TLS',
      'iso2': 'TL',
      'country_code': '626',
      'phone_code': '670',
      'capital': 'Dili',
      'currency': 'USD',
      'states': [
          'Aileu',
          'Ainaro',
          'Ambeno',
          'Baucau',
          'Bobonaro',
          'Cova Lima',
          'Dili',
          'Ermera',
          'Lautem',
          'Liquica',
          'Manatuto',
          'Manufahi',
          'Viqueque'
      ]
  },
  {
      'id': 64,
      'name': 'Ecuador',
      'iso3': 'ECU',
      'iso2': 'EC',
      'country_code': '218',
      'phone_code': '593',
      'capital': 'Quito',
      'currency': 'USD',
      'states': [
          'Azuay',
          'Bolivar',
          'Canar',
          'Carchi',
          'Chimborazo',
          'Cotopaxi',
          'El Oro',
          'Esmeraldas',
          'Galapagos',
          'Guayas',
          'Imbabura',
          'Loja',
          'Los Rios',
          'Manabi',
          'Morona Santiago',
          'Napo',
          'Orellana',
          'Pastaza',
          'Pichincha',
          'Sucumbios',
          'Tungurahua',
          'Zamora Chinchipe'
      ]
  },
  {
      'id': 65,
      'name': 'Egypt',
      'iso3': 'EGY',
      'iso2': 'EG',
      'country_code': '818',
      'phone_code': '20',
      'capital': 'Cairo',
      'currency': 'EGP',
      'states': [
          'Aswan',
          'Asyut',
          'Bani Suwayf',
          'Bur Sa\'id',
          'Cairo',
          'Dumyat',
          'Kafr-ash-Shaykh',
          'Matruh',
          'Muhafazat ad Daqahliyah',
          'Muhafazat al Fayyum',
          'Muhafazat al Gharbiyah',
          'Muhafazat al Iskandariyah',
          'Muhafazat al Qahirah',
          'Qina',
          'Sawhaj',
          'Sina al-Janubiyah',
          'Sina ash-Shamaliyah',
          'ad-Daqahliyah',
          'al-Bahr-al-Ahmar',
          'al-Buhayrah',
          'al-Fayyum',
          'al-Gharbiyah',
          'al-Iskandariyah',
          'al-Ismailiyah',
          'al-Jizah',
          'al-Minufiyah',
          'al-Minya',
          'al-Qahira',
          'al-Qalyubiyah',
          'al-Uqsur',
          'al-Wadi al-Jadid',
          'as-Suways',
          'ash-Sharqiyah'
      ]
  },
  {
      'id': 66,
      'name': 'El Salvador',
      'iso3': 'SLV',
      'iso2': 'SV',
      'country_code': '222',
      'phone_code': '503',
      'capital': 'San Salvador',
      'currency': 'USD',
      'states': [
          'Ahuachapan',
          'Cabanas',
          'Chalatenango',
          'Cuscatlan',
          'La Libertad',
          'La Paz',
          'La Union',
          'Morazan',
          'San Miguel',
          'San Salvador',
          'San Vicente',
          'Santa Ana',
          'Sonsonate',
          'Usulutan'
      ]
  },
  {
      'id': 67,
      'name': 'Equatorial Guinea',
      'iso3': 'GNQ',
      'iso2': 'GQ',
      'country_code': '226',
      'phone_code': '240',
      'capital': 'Malabo',
      'currency': 'XAF',
      'states': [
          'Annobon',
          'Bioko Norte',
          'Bioko Sur',
          'Centro Sur',
          'Kie-Ntem',
          'Litoral',
          'Wele-Nzas'
      ]
  },
  {
      'id': 68,
      'name': 'Eritrea',
      'iso3': 'ERI',
      'iso2': 'ER',
      'country_code': '232',
      'phone_code': '291',
      'capital': 'Asmara',
      'currency': 'ERN',
      'states': [
          'Anseba',
          'Debub',
          'Debub-Keih-Bahri',
          'Gash-Barka',
          'Maekel',
          'Semien-Keih-Bahri'
      ]
  },
  {
      'id': 69,
      'name': 'Estonia',
      'iso3': 'EST',
      'iso2': 'EE',
      'country_code': '233',
      'phone_code': '372',
      'capital': 'Tallinn',
      'currency': 'EUR',
      'states': [
          'Harju',
          'Hiiu',
          'Ida-Viru',
          'Jarva',
          'Jogeva',
          'Laane',
          'Laane-Viru',
          'Parnu',
          'Polva',
          'Rapla',
          'Saare',
          'Tartu',
          'Valga',
          'Viljandi',
          'Voru'
      ]
  },
  {
      'id': 70,
      'name': 'Ethiopia',
      'iso3': 'ETH',
      'iso2': 'ET',
      'country_code': '231',
      'phone_code': '251',
      'capital': 'Addis Ababa',
      'currency': 'ETB',
      'states': [
          'Addis Abeba',
          'Afar',
          'Amhara',
          'Benishangul',
          'Diredawa',
          'Gambella',
          'Harar',
          'Jigjiga',
          'Mekele',
          'Oromia',
          'Somali',
          'Southern',
          'Tigray'
      ]
  },
  {
      'id': 71,
      'name': 'Falkland Islands',
      'iso3': 'FLK',
      'iso2': 'FK',
      'country_code': '238',
      'phone_code': '500',
      'capital': 'Stanley',
      'currency': 'FKP',
      'states': [
          'Falkland Islands',
          'South Georgia'
      ]
  },
  {
      'id': 72,
      'name': 'Faroe Islands',
      'iso3': 'FRO',
      'iso2': 'FO',
      'country_code': '234',
      'phone_code': '298',
      'capital': 'Torshavn',
      'currency': 'DKK',
      'states': [
          'Klaksvik',
          'Nor ara Eysturoy',
          'Nor oy',
          'Sandoy',
          'Streymoy',
          'Su uroy',
          'Sy ra Eysturoy',
          'Torshavn',
          'Vaga'
      ]
  },
  {
      'id': 73,
      'name': 'Fiji Islands',
      'iso3': 'FJI',
      'iso2': 'FJ',
      'country_code': '242',
      'phone_code': '679',
      'capital': 'Suva',
      'currency': 'FJD',
      'states': [
          'Central',
          'Eastern',
          'Northern',
          'South Pacific',
          'Western'
      ]
  },
  {
      'id': 74,
      'name': 'Finland',
      'iso3': 'FIN',
      'iso2': 'FI',
      'country_code': '246',
      'phone_code': '358',
      'capital': 'Helsinki',
      'currency': 'EUR',
      'states': [
          'Ahvenanmaa',
          'Etela-Karjala',
          'Etela-Pohjanmaa',
          'Etela-Savo',
          'Etela-Suomen Laani',
          'Ita-Suomen Laani',
          'Ita-Uusimaa',
          'Kainuu',
          'Kanta-Hame',
          'Keski-Pohjanmaa',
          'Keski-Suomi',
          'Kymenlaakso',
          'Lansi-Suomen Laani',
          'Lappi',
          'Northern Savonia',
          'Ostrobothnia',
          'Oulun Laani',
          'Paijat-Hame',
          'Pirkanmaa',
          'Pohjanmaa',
          'Pohjois-Karjala',
          'Pohjois-Pohjanmaa',
          'Pohjois-Savo',
          'Saarijarvi',
          'Satakunta',
          'Southern Savonia',
          'Tavastia Proper',
          'Uleaborgs Lan',
          'Uusimaa',
          'Varsinais-Suomi'
      ]
  },
  {
      'id': 75,
      'name': 'France',
      'iso3': 'FRA',
      'iso2': 'FR',
      'country_code': '250',
      'phone_code': '33',
      'capital': 'Paris',
      'currency': 'EUR',
      'states': [
          'Ain',
          'Aisne',
          'Albi Le Sequestre',
          'Allier',
          'Alpes-Cote dAzur',
          'Alpes-Maritimes',
          'Alpes-de-Haute-Provence',
          'Alsace',
          'Aquitaine',
          'Ardeche',
          'Ardennes',
          'Ariege',
          'Aube',
          'Aude',
          'Auvergne',
          'Aveyron',
          'Bas-Rhin',
          'Basse-Normandie',
          'Bouches-du-Rhone',
          'Bourgogne',
          'Bretagne',
          'Brittany',
          'Burgundy',
          'Calvados',
          'Cantal',
          'Cedex',
          'Centre',
          'Charente',
          'Charente-Maritime',
          'Cher',
          'Correze',
          'Corse-du-Sud',
          'Cote-d\'Or',
          'Cotes-d\'Armor',
          'Creuse',
          'Crolles',
          'Deux-Sevres',
          'Dordogne',
          'Doubs',
          'Drome',
          'Essonne',
          'Eure',
          'Eure-et-Loir',
          'Feucherolles',
          'Finistere',
          'Franche-Comte',
          'Gard',
          'Gers',
          'Gironde',
          'Haut-Rhin',
          'Haute-Corse',
          'Haute-Garonne',
          'Haute-Loire',
          'Haute-Marne',
          'Haute-Saone',
          'Haute-Savoie',
          'Haute-Vienne',
          'Hautes-Alpes',
          'Hautes-Pyrenees',
          'Hauts-de-Seine',
          'Herault',
          'Ile-de-France',
          'Ille-et-Vilaine',
          'Indre',
          'Indre-et-Loire',
          'Isere',
          'Jura',
          'Klagenfurt',
          'Landes',
          'Languedoc-Roussillon',
          'Larcay',
          'Le Castellet',
          'Le Creusot',
          'Limousin',
          'Loir-et-Cher',
          'Loire',
          'Loire-Atlantique',
          'Loiret',
          'Lorraine',
          'Lot',
          'Lot-et-Garonne',
          'Lower Normandy',
          'Lozere',
          'Maine-et-Loire',
          'Manche',
          'Marne',
          'Mayenne',
          'Meurthe-et-Moselle',
          'Meuse',
          'Midi-Pyrenees',
          'Morbihan',
          'Moselle',
          'Nievre',
          'Nord',
          'Nord-Pas-de-Calais',
          'Oise',
          'Orne',
          'Paris',
          'Pas-de-Calais',
          'Pays de la Loire',
          'Pays-de-la-Loire',
          'Picardy',
          'Puy-de-Dome',
          'Pyrenees-Atlantiques',
          'Pyrenees-Orientales',
          'Quelmes',
          'Rhone',
          'Rhone-Alpes',
          'Saint Ouen',
          'Saint Viatre',
          'Saone-et-Loire',
          'Sarthe',
          'Savoie',
          'Seine-Maritime',
          'Seine-Saint-Denis',
          'Seine-et-Marne',
          'Somme',
          'Sophia Antipolis',
          'Souvans',
          'Tarn',
          'Tarn-et-Garonne',
          'Territoire de Belfort',
          'Treignac',
          'Upper Normandy',
          'Val-d\'Oise',
          'Val-de-Marne',
          'Var',
          'Vaucluse',
          'Vellise',
          'Vendee',
          'Vienne',
          'Vosges',
          'Yonne',
          'Yvelines'
      ]
  },
  {
      'id': 76,
      'name': 'French Guiana',
      'iso3': 'GUF',
      'iso2': 'GF',
      'country_code': '254',
      'phone_code': '594',
      'capital': 'Cayenne',
      'currency': 'EUR',
      'states': [
          'Cayenne',
          'Saint-Laurent-du-Maroni'
      ]
  },
  {
      'id': 77,
      'name': 'French Polynesia',
      'iso3': 'PYF',
      'iso2': 'PF',
      'country_code': '258',
      'phone_code': '689',
      'capital': 'Papeete',
      'currency': 'XPF',
      'states': [
          'Iles du Vent',
          'Iles sous le Vent',
          'Marquesas',
          'Tuamotu',
          'Tubuai'
      ]
  },
  {
      'id': 78,
      'name': 'French Southern Territories',
      'iso3': 'ATF',
      'iso2': 'TF',
      'country_code': '260',
      'phone_code': '',
      'capital': 'Port-aux-Francais',
      'currency': 'EUR',
      'states': [
          'Amsterdam',
          'Crozet Islands',
          'Kerguelen'
      ]
  },
  {
      'id': 79,
      'name': 'Gabon',
      'iso3': 'GAB',
      'iso2': 'GA',
      'country_code': '266',
      'phone_code': '241',
      'capital': 'Libreville',
      'currency': 'XAF',
      'states': [
          'Estuaire',
          'Haut-Ogooue',
          'Moyen-Ogooue',
          'Ngounie',
          'Nyanga',
          'Ogooue-Ivindo',
          'Ogooue-Lolo',
          'Ogooue-Maritime',
          'Woleu-Ntem'
      ]
  },
  {
      'id': 80,
      'name': 'Gambia The',
      'iso3': 'GMB',
      'iso2': 'GM',
      'country_code': '270',
      'phone_code': '220',
      'capital': 'Banjul',
      'currency': 'GMD',
      'states': [
          'Banjul',
          'Basse',
          'Brikama',
          'Janjanbureh',
          'Kanifing',
          'Kerewan',
          'Kuntaur',
          'Mansakonko'
      ]
  },
  {
      'id': 81,
      'name': 'Georgia',
      'iso3': 'GEO',
      'iso2': 'GE',
      'country_code': '268',
      'phone_code': '995',
      'capital': 'Tbilisi',
      'currency': 'GEL',
      'states': [
          'Abhasia',
          'Ajaria',
          'Guria',
          'Imereti',
          'Kaheti',
          'Kvemo Kartli',
          'Mcheta-Mtianeti',
          'Racha',
          'Samagrelo-Zemo Svaneti',
          'Samche-Zhavaheti',
          'Shida Kartli',
          'Tbilisi'
      ]
  },
  {
      'id': 82,
      'name': 'Germany',
      'iso3': 'DEU',
      'iso2': 'DE',
      'country_code': '276',
      'phone_code': '49',
      'capital': 'Berlin',
      'currency': 'EUR',
      'states': [
          'Auvergne',
          'Baden-Wurttemberg',
          'Bavaria',
          'Bayern',
          'Beilstein Wurtt',
          'Berlin',
          'Brandenburg',
          'Bremen',
          'Dreisbach',
          'Freistaat Bayern',
          'Hamburg',
          'Hannover',
          'Heroldstatt',
          'Hessen',
          'Kortenberg',
          'Laasdorf',
          'Land Baden-Wurttemberg',
          'Land Bayern',
          'Land Brandenburg',
          'Land Hessen',
          'Land Mecklenburg-Vorpommern',
          'Land Nordrhein-Westfalen',
          'Land Rheinland-Pfalz',
          'Land Sachsen',
          'Land Sachsen-Anhalt',
          'Land Thuringen',
          'Lower Saxony',
          'Mecklenburg-Vorpommern',
          'Mulfingen',
          'Munich',
          'Neubeuern',
          'Niedersachsen',
          'Noord-Holland',
          'Nordrhein-Westfalen',
          'North Rhine-Westphalia',
          'Osterode',
          'Rheinland-Pfalz',
          'Rhineland-Palatinate',
          'Saarland',
          'Sachsen',
          'Sachsen-Anhalt',
          'Saxony',
          'Schleswig-Holstein',
          'Thuringia',
          'Webling',
          'Weinstrabe',
          'schlobborn'
      ]
  },
  {
      'id': 83,
      'name': 'Ghana',
      'iso3': 'GHA',
      'iso2': 'GH',
      'country_code': '288',
      'phone_code': '233',
      'capital': 'Accra',
      'currency': 'GHS',
      'states': [
          'Ashanti',
          'Brong-Ahafo',
          'Central',
          'Eastern',
          'Greater Accra',
          'Northern',
          'Upper East',
          'Upper West',
          'Volta',
          'Western'
      ]
  },
  {
      'id': 84,
      'name': 'Gibraltar',
      'iso3': 'GIB',
      'iso2': 'GI',
      'country_code': '292',
      'phone_code': '350',
      'capital': 'Gibraltar',
      'currency': 'GIP',
      'states': [
          'Gibraltar'
      ]
  },
  {
      'id': 85,
      'name': 'Greece',
      'iso3': 'GRC',
      'iso2': 'GR',
      'country_code': '300',
      'phone_code': '30',
      'capital': 'Athens',
      'currency': 'EUR',
      'states': [
          'Acharnes',
          'Ahaia',
          'Aitolia kai Akarnania',
          'Argolis',
          'Arkadia',
          'Arta',
          'Attica',
          'Attiki',
          'Ayion Oros',
          'Crete',
          'Dodekanisos',
          'Drama',
          'Evia',
          'Evritania',
          'Evros',
          'Evvoia',
          'Florina',
          'Fokis',
          'Fthiotis',
          'Grevena',
          'Halandri',
          'Halkidiki',
          'Hania',
          'Heraklion',
          'Hios',
          'Ilia',
          'Imathia',
          'Ioannina',
          'Iraklion',
          'Karditsa',
          'Kastoria',
          'Kavala',
          'Kefallinia',
          'Kerkira',
          'Kiklades',
          'Kilkis',
          'Korinthia',
          'Kozani',
          'Lakonia',
          'Larisa',
          'Lasithi',
          'Lesvos',
          'Levkas',
          'Magnisia',
          'Messinia',
          'Nomos Attikis',
          'Nomos Zakynthou',
          'Pella',
          'Pieria',
          'Piraios',
          'Preveza',
          'Rethimni',
          'Rodopi',
          'Samos',
          'Serrai',
          'Thesprotia',
          'Thessaloniki',
          'Trikala',
          'Voiotia',
          'West Greece',
          'Xanthi',
          'Zakinthos'
      ]
  },
  {
      'id': 86,
      'name': 'Greenland',
      'iso3': 'GRL',
      'iso2': 'GL',
      'country_code': '304',
      'phone_code': '299',
      'capital': 'Nuuk',
      'currency': 'DKK',
      'states': [
          'Aasiaat',
          'Ammassalik',
          'Illoqqortoormiut',
          'Ilulissat',
          'Ivittuut',
          'Kangaatsiaq',
          'Maniitsoq',
          'Nanortalik',
          'Narsaq',
          'Nuuk',
          'Paamiut',
          'Qaanaaq',
          'Qaqortoq',
          'Qasigiannguit',
          'Qeqertarsuaq',
          'Sisimiut',
          'Udenfor kommunal inddeling',
          'Upernavik',
          'Uummannaq'
      ]
  },
  {
      'id': 87,
      'name': 'Grenada',
      'iso3': 'GRD',
      'iso2': 'GD',
      'country_code': '308',
      'phone_code': '+1-473',
      'capital': 'St. George\'s',
      'currency': 'XCD',
      'states': [
          'Carriacou-Petite Martinique',
          'Saint Andrew',
          'Saint Davids',
          'Saint George\'s',
          'Saint John',
          'Saint Mark',
          'Saint Patrick'
      ]
  },
  {
      'id': 88,
      'name': 'Guadeloupe',
      'iso3': 'GLP',
      'iso2': 'GP',
      'country_code': '312',
      'phone_code': '590',
      'capital': 'Basse-Terre',
      'currency': 'EUR',
      'states': [
          'Basse-Terre',
          'Grande-Terre',
          'Iles des Saintes',
          'La Desirade',
          'Marie-Galante',
          'Saint Barthelemy',
          'Saint Martin'
      ]
  },
  {
      'id': 89,
      'name': 'Guam',
      'iso3': 'GUM',
      'iso2': 'GU',
      'country_code': '316',
      'phone_code': '+1-671',
      'capital': 'Hagatna',
      'currency': 'USD',
      'states': [
          'Agana Heights',
          'Agat',
          'Barrigada',
          'Chalan-Pago-Ordot',
          'Dededo',
          'Hagatna',
          'Inarajan',
          'Mangilao',
          'Merizo',
          'Mongmong-Toto-Maite',
          'Santa Rita',
          'Sinajana',
          'Talofofo',
          'Tamuning',
          'Yigo',
          'Yona'
      ]
  },
  {
      'id': 90,
      'name': 'Guatemala',
      'iso3': 'GTM',
      'iso2': 'GT',
      'country_code': '320',
      'phone_code': '502',
      'capital': 'Guatemala City',
      'currency': 'GTQ',
      'states': [
          'Alta Verapaz',
          'Baja Verapaz',
          'Chimaltenango',
          'Chiquimula',
          'El Progreso',
          'Escuintla',
          'Guatemala',
          'Huehuetenango',
          'Izabal',
          'Jalapa',
          'Jutiapa',
          'Peten',
          'Quezaltenango',
          'Quiche',
          'Retalhuleu',
          'Sacatepequez',
          'San Marcos',
          'Santa Rosa',
          'Solola',
          'Suchitepequez',
          'Totonicapan',
          'Zacapa'
      ]
  },
  {
      'id': 91,
      'name': 'Guernsey and Alderney',
      'iso3': 'GGY',
      'iso2': 'GG',
      'country_code': '831',
      'phone_code': '+44-1481',
      'capital': 'St Peter Port',
      'currency': 'GBP',
      'states': [
          'Alderney',
          'Castel',
          'Forest',
          'Saint Andrew',
          'Saint Martin',
          'Saint Peter Port',
          'Saint Pierre du Bois',
          'Saint Sampson',
          'Saint Saviour',
          'Sark',
          'Torteval',
          'Vale'
      ]
  },
  {
      'id': 92,
      'name': 'Guinea',
      'iso3': 'GIN',
      'iso2': 'GN',
      'country_code': '324',
      'phone_code': '224',
      'capital': 'Conakry',
      'currency': 'GNF',
      'states': [
          'Beyla',
          'Boffa',
          'Boke',
          'Conakry',
          'Coyah',
          'Dabola',
          'Dalaba',
          'Dinguiraye',
          'Faranah',
          'Forecariah',
          'Fria',
          'Gaoual',
          'Gueckedou',
          'Kankan',
          'Kerouane',
          'Kindia',
          'Kissidougou',
          'Koubia',
          'Koundara',
          'Kouroussa',
          'Labe',
          'Lola',
          'Macenta',
          'Mali',
          'Mamou',
          'Mandiana',
          'Nzerekore',
          'Pita',
          'Siguiri',
          'Telimele',
          'Tougue',
          'Yomou'
      ]
  },
  {
      'id': 93,
      'name': 'Guinea-Bissau',
      'iso3': 'GNB',
      'iso2': 'GW',
      'country_code': '624',
      'phone_code': '245',
      'capital': 'Bissau',
      'currency': 'XOF',
      'states': [
          'Bafata',
          'Bissau',
          'Bolama',
          'Cacheu',
          'Gabu',
          'Oio',
          'Quinara',
          'Tombali'
      ]
  },
  {
      'id': 94,
      'name': 'Guyana',
      'iso3': 'GUY',
      'iso2': 'GY',
      'country_code': '328',
      'phone_code': '592',
      'capital': 'Georgetown',
      'currency': 'GYD',
      'states': [
          'Barima-Waini',
          'Cuyuni-Mazaruni',
          'Demerara-Mahaica',
          'East Berbice-Corentyne',
          'Essequibo Islands-West Demerar',
          'Mahaica-Berbice',
          'Pomeroon-Supenaam',
          'Potaro-Siparuni',
          'Upper Demerara-Berbice',
          'Upper Takutu-Upper Essequibo'
      ]
  },
  {
      'id': 95,
      'name': 'Haiti',
      'iso3': 'HTI',
      'iso2': 'HT',
      'country_code': '332',
      'phone_code': '509',
      'capital': 'Port-au-Prince',
      'currency': 'HTG',
      'states': [
          'Artibonite',
          'Centre',
          'Grand\'Anse',
          'Nord',
          'Nord-Est',
          'Nord-Ouest',
          'Ouest',
          'Sud',
          'Sud-Est'
      ]
  },
  {
      'id': 96,
      'name': 'Heard and McDonald Islands',
      'iso3': 'HMD',
      'iso2': 'HM',
      'country_code': '334',
      'phone_code': ' ',
      'capital': '',
      'currency': 'AUD',
      'states': [
          'Heard and McDonald Islands'
      ]
  },
  {
      'id': 97,
      'name': 'Honduras',
      'iso3': 'HND',
      'iso2': 'HN',
      'country_code': '340',
      'phone_code': '504',
      'capital': 'Tegucigalpa',
      'currency': 'HNL',
      'states': [
          'Atlantida',
          'Choluteca',
          'Colon',
          'Comayagua',
          'Copan',
          'Cortes',
          'Distrito Central',
          'El Paraiso',
          'Francisco Morazan',
          'Gracias a Dios',
          'Intibuca',
          'Islas de la Bahia',
          'La Paz',
          'Lempira',
          'Ocotepeque',
          'Olancho',
          'Santa Barbara',
          'Valle',
          'Yoro'
      ]
  },
  {
      'id': 98,
      'name': 'Hong Kong S.A.R.',
      'iso3': 'HKG',
      'iso2': 'HK',
      'country_code': '344',
      'phone_code': '852',
      'capital': 'Hong Kong',
      'currency': 'HKD',
      'states': [
          'Hong Kong'
      ]
  },
  {
      'id': 99,
      'name': 'Hungary',
      'iso3': 'HUN',
      'iso2': 'HU',
      'country_code': '348',
      'phone_code': '36',
      'capital': 'Budapest',
      'currency': 'HUF',
      'states': [
          'Bacs-Kiskun',
          'Baranya',
          'Bekes',
          'Borsod-Abauj-Zemplen',
          'Budapest',
          'Csongrad',
          'Fejer',
          'Gyor-Moson-Sopron',
          'Hajdu-Bihar',
          'Heves',
          'Jasz-Nagykun-Szolnok',
          'Komarom-Esztergom',
          'Nograd',
          'Pest',
          'Somogy',
          'Szabolcs-Szatmar-Bereg',
          'Tolna',
          'Vas',
          'Veszprem',
          'Zala'
      ]
  },
  {
      'id': 100,
      'name': 'Iceland',
      'iso3': 'ISL',
      'iso2': 'IS',
      'country_code': '352',
      'phone_code': '354',
      'capital': 'Reykjavik',
      'currency': 'ISK',
      'states': [
          'Austurland',
          'Gullbringusysla',
          'Hofu borgarsva i',
          'Nor urland eystra',
          'Nor urland vestra',
          'Su urland',
          'Su urnes',
          'Vestfir ir',
          'Vesturland'
      ]
  },
  {
      'id': 101,
      'name': 'India',
      'iso3': 'IND',
      'iso2': 'IN',
      'country_code': '356',
      'phone_code': '91',
      'capital': 'New Delhi',
      'currency': 'INR',
      'states': [
          'Andaman and Nicobar Islands',
          'Andhra Pradesh',
          'Arunachal Pradesh',
          'Assam',
          'Bihar',
          'Chandigarh',
          'Chhattisgarh',
          'Dadra and Nagar Haveli',
          'Daman and Diu',
          'Delhi',
          'Goa',
          'Gujarat',
          'Haryana',
          'Himachal Pradesh',
          'Jammu and Kashmir',
          'Jharkhand',
          'Karnataka',
          'Kenmore',
          'Kerala',
          'Lakshadweep',
          'Madhya Pradesh',
          'Maharashtra',
          'Manipur',
          'Meghalaya',
          'Mizoram',
          'Nagaland',
          'Narora',
          'Natwar',
          'Odisha',
          'Paschim Medinipur',
          'Pondicherry',
          'Punjab',
          'Rajasthan',
          'Sikkim',
          'Tamil Nadu',
          'Telangana',
          'Tripura',
          'Uttar Pradesh',
          'Uttarakhand',
          'Vaishali',
          'West Bengal'
      ]
  },
  {
      'id': 102,
      'name': 'Indonesia',
      'iso3': 'IDN',
      'iso2': 'ID',
      'country_code': '360',
      'phone_code': '62',
      'capital': 'Jakarta',
      'currency': 'IDR',
      'states': [
          'Aceh',
          'Bali',
          'Bangka-Belitung',
          'Banten',
          'Bengkulu',
          'Gandaria',
          'Gorontalo',
          'Jakarta',
          'Jambi',
          'Jawa Barat',
          'Jawa Tengah',
          'Jawa Timur',
          'Kalimantan Barat',
          'Kalimantan Selatan',
          'Kalimantan Tengah',
          'Kalimantan Timur',
          'Kendal',
          'Lampung',
          'Maluku',
          'Maluku Utara',
          'Nusa Tenggara Barat',
          'Nusa Tenggara Timur',
          'Papua',
          'Riau',
          'Riau Kepulauan',
          'Solo',
          'Sulawesi Selatan',
          'Sulawesi Tengah',
          'Sulawesi Tenggara',
          'Sulawesi Utara',
          'Sumatera Barat',
          'Sumatera Selatan',
          'Sumatera Utara',
          'Yogyakarta'
      ]
  },
  {
      'id': 103,
      'name': 'Iran',
      'iso3': 'IRN',
      'iso2': 'IR',
      'country_code': '364',
      'phone_code': '98',
      'capital': 'Tehran',
      'currency': 'IRR',
      'states': [
          'Ardabil',
          'Azarbayjan-e Bakhtari',
          'Azarbayjan-e Khavari',
          'Bushehr',
          'Chahar Mahal-e Bakhtiari',
          'Esfahan',
          'Fars',
          'Gilan',
          'Golestan',
          'Hamadan',
          'Hormozgan',
          'Ilam',
          'Kerman',
          'Kermanshah',
          'Khorasan',
          'Khuzestan',
          'Kohgiluyeh-e Boyerahmad',
          'Kordestan',
          'Lorestan',
          'Markazi',
          'Mazandaran',
          'Ostan-e Esfahan',
          'Qazvin',
          'Qom',
          'Semnan',
          'Sistan-e Baluchestan',
          'Tehran',
          'Yazd',
          'Zanjan'
      ]
  },
  {
      'id': 104,
      'name': 'Iraq',
      'iso3': 'IRQ',
      'iso2': 'IQ',
      'country_code': '368',
      'phone_code': '964',
      'capital': 'Baghdad',
      'currency': 'IQD',
      'states': [
          'Babil',
          'Baghdad',
          'Dahuk',
          'Dhi Qar',
          'Diyala',
          'Erbil',
          'Irbil',
          'Karbala',
          'Kurdistan',
          'Maysan',
          'Ninawa',
          'Salah-ad-Din',
          'Wasit',
          'al-Anbar',
          'al-Basrah',
          'al-Muthanna',
          'al-Qadisiyah',
          'an-Najaf',
          'as-Sulaymaniyah',
          'at-Ta\'mim'
      ]
  },
  {
      'id': 105,
      'name': 'Ireland',
      'iso3': 'IRL',
      'iso2': 'IE',
      'country_code': '372',
      'phone_code': '353',
      'capital': 'Dublin',
      'currency': 'EUR',
      'states': [
          'Armagh',
          'Carlow',
          'Cavan',
          'Clare',
          'Cork',
          'Donegal',
          'Dublin',
          'Galway',
          'Kerry',
          'Kildare',
          'Kilkenny',
          'Laois',
          'Leinster',
          'Leitrim',
          'Limerick',
          'Loch Garman',
          'Longford',
          'Louth',
          'Mayo',
          'Meath',
          'Monaghan',
          'Offaly',
          'Roscommon',
          'Sligo',
          'Tipperary North Riding',
          'Tipperary South Riding',
          'Ulster',
          'Waterford',
          'Westmeath',
          'Wexford',
          'Wicklow'
      ]
  },
  {
      'id': 106,
      'name': 'Israel',
      'iso3': 'ISR',
      'iso2': 'IL',
      'country_code': '376',
      'phone_code': '972',
      'capital': 'Jerusalem',
      'currency': 'ILS',
      'states': [
          'Beit Hanania',
          'Ben Gurion Airport',
          'Bethlehem',
          'Caesarea',
          'Centre',
          'Gaza',
          'Hadaron',
          'Haifa District',
          'Hamerkaz',
          'Hazafon',
          'Hebron',
          'Jaffa',
          'Jerusalem',
          'Khefa',
          'Kiryat Yam',
          'Lower Galilee',
          'Qalqilya',
          'Talme Elazar',
          'Tel Aviv',
          'Tsafon',
          'Umm El Fahem',
          'Yerushalayim'
      ]
  },
  {
      'id': 107,
      'name': 'Italy',
      'iso3': 'ITA',
      'iso2': 'IT',
      'country_code': '380',
      'phone_code': '39',
      'capital': 'Rome',
      'currency': 'EUR',
      'states': [
          'Abruzzi',
          'Abruzzo',
          'Agrigento',
          'Alessandria',
          'Ancona',
          'Arezzo',
          'Ascoli Piceno',
          'Asti',
          'Avellino',
          'Bari',
          'Basilicata',
          'Belluno',
          'Benevento',
          'Bergamo',
          'Biella',
          'Bologna',
          'Bolzano',
          'Brescia',
          'Brindisi',
          'Calabria',
          'Campania',
          'Cartoceto',
          'Caserta',
          'Catania',
          'Chieti',
          'Como',
          'Cosenza',
          'Cremona',
          'Cuneo',
          'Emilia-Romagna',
          'Ferrara',
          'Firenze',
          'Florence',
          'Forli-Cesena ',
          'Friuli-Venezia Giulia',
          'Frosinone',
          'Genoa',
          'Gorizia',
          'L\'Aquila',
          'Lazio',
          'Lecce',
          'Lecco',
          'Lecco Province',
          'Liguria',
          'Lodi',
          'Lombardia',
          'Lombardy',
          'Macerata',
          'Mantova',
          'Marche',
          'Messina',
          'Milan',
          'Modena',
          'Molise',
          'Molteno',
          'Montenegro',
          'Monza and Brianza',
          'Naples',
          'Novara',
          'Padova',
          'Parma',
          'Pavia',
          'Perugia',
          'Pesaro-Urbino',
          'Piacenza',
          'Piedmont',
          'Piemonte',
          'Pisa',
          'Pordenone',
          'Potenza',
          'Puglia',
          'Reggio Emilia',
          'Rimini',
          'Roma',
          'Salerno',
          'Sardegna',
          'Sassari',
          'Savona',
          'Sicilia',
          'Siena',
          'Sondrio',
          'South Tyrol',
          'Taranto',
          'Teramo',
          'Torino',
          'Toscana',
          'Trapani',
          'Trentino-Alto Adige',
          'Trento',
          'Treviso',
          'Udine',
          'Umbria',
          'Valle d\'Aosta',
          'Varese',
          'Veneto',
          'Venezia',
          'Verbano-Cusio-Ossola',
          'Vercelli',
          'Verona',
          'Vicenza',
          'Viterbo'
      ]
  },
  {
      'id': 108,
      'name': 'Jamaica',
      'iso3': 'JAM',
      'iso2': 'JM',
      'country_code': '388',
      'phone_code': '+1-876',
      'capital': 'Kingston',
      'currency': 'JMD',
      'states': [
          'Buxoro Viloyati',
          'Clarendon',
          'Hanover',
          'Kingston',
          'Manchester',
          'Portland',
          'Saint Andrews',
          'Saint Ann',
          'Saint Catherine',
          'Saint Elizabeth',
          'Saint James',
          'Saint Mary',
          'Saint Thomas',
          'Trelawney',
          'Westmoreland'
      ]
  },
  {
      'id': 109,
      'name': 'Japan',
      'iso3': 'JPN',
      'iso2': 'JP',
      'country_code': '392',
      'phone_code': '81',
      'capital': 'Tokyo',
      'currency': 'JPY',
      'states': [
          'Aichi',
          'Akita',
          'Aomori',
          'Chiba',
          'Ehime',
          'Fukui',
          'Fukuoka',
          'Fukushima',
          'Gifu',
          'Gumma',
          'Hiroshima',
          'Hokkaido',
          'Hyogo',
          'Ibaraki',
          'Ishikawa',
          'Iwate',
          'Kagawa',
          'Kagoshima',
          'Kanagawa',
          'Kanto',
          'Kochi',
          'Kumamoto',
          'Kyoto',
          'Mie',
          'Miyagi',
          'Miyazaki',
          'Nagano',
          'Nagasaki',
          'Nara',
          'Niigata',
          'Oita',
          'Okayama',
          'Okinawa',
          'Osaka',
          'Saga',
          'Saitama',
          'Shiga',
          'Shimane',
          'Shizuoka',
          'Tochigi',
          'Tokushima',
          'Tokyo',
          'Tottori',
          'Toyama',
          'Wakayama',
          'Yamagata',
          'Yamaguchi',
          'Yamanashi'
      ]
  },
  {
      'id': 110,
      'name': 'Jersey',
      'iso3': 'JEY',
      'iso2': 'JE',
      'country_code': '832',
      'phone_code': '+44-1534',
      'capital': 'Saint Helier',
      'currency': 'GBP',
      'states': [
          'Grouville',
          'Saint Brelade',
          'Saint Clement',
          'Saint Helier',
          'Saint John',
          'Saint Lawrence',
          'Saint Martin',
          'Saint Mary',
          'Saint Peter',
          'Saint Saviour',
          'Trinity'
      ]
  },
  {
      'id': 111,
      'name': 'Jordan',
      'iso3': 'JOR',
      'iso2': 'JO',
      'country_code': '400',
      'phone_code': '962',
      'capital': 'Amman',
      'currency': 'JOD',
      'states': [
          '\'Ajlun',
          'Amman',
          'Irbid',
          'Jarash',
          'Ma\'an',
          'Madaba',
          'al-\'Aqabah',
          'al-Balqa\'',
          'al-Karak',
          'al-Mafraq',
          'at-Tafilah',
          'az-Zarqa\''
      ]
  },
  {
      'id': 112,
      'name': 'Kazakhstan',
      'iso3': 'KAZ',
      'iso2': 'KZ',
      'country_code': '398',
      'phone_code': '7',
      'capital': 'Astana',
      'currency': 'KZT',
      'states': [
          'Akmecet',
          'Akmola',
          'Aktobe',
          'Almati',
          'Atirau',
          'Batis Kazakstan',
          'Burlinsky Region',
          'Karagandi',
          'Kostanay',
          'Mankistau',
          'Ontustik Kazakstan',
          'Pavlodar',
          'Sigis Kazakstan',
          'Soltustik Kazakstan',
          'Taraz'
      ]
  },
  {
      'id': 113,
      'name': 'Kenya',
      'iso3': 'KEN',
      'iso2': 'KE',
      'country_code': '404',
      'phone_code': '254',
      'capital': 'Nairobi',
      'currency': 'KES',
      'states': [
          'Central',
          'Coast',
          'Eastern',
          'Nairobi',
          'North Eastern',
          'Nyanza',
          'Rift Valley',
          'Western'
      ]
  },
  {
      'id': 114,
      'name': 'Kiribati',
      'iso3': 'KIR',
      'iso2': 'KI',
      'country_code': '296',
      'phone_code': '686',
      'capital': 'Tarawa',
      'currency': 'AUD',
      'states': [
          'Abaiang',
          'Abemana',
          'Aranuka',
          'Arorae',
          'Banaba',
          'Beru',
          'Butaritari',
          'Kiritimati',
          'Kuria',
          'Maiana',
          'Makin',
          'Marakei',
          'Nikunau',
          'Nonouti',
          'Onotoa',
          'Phoenix Islands',
          'Tabiteuea North',
          'Tabiteuea South',
          'Tabuaeran',
          'Tamana',
          'Tarawa North',
          'Tarawa South',
          'Teraina'
      ]
  },
  {
      'id': 116,
      'name': 'Korea South',
      'iso3': 'KOR',
      'iso2': 'KR',
      'country_code': '410',
      'phone_code': '82',
      'capital': 'Seoul',
      'currency': 'KRW',
      'states': [
          'Busan',
          'Cheju',
          'Chollabuk',
          'Chollanam',
          'Chungbuk',
          'Chungcheongbuk',
          'Chungcheongnam',
          'Chungnam',
          'Daegu',
          'Gangwon-do',
          'Goyang-si',
          'Gyeonggi-do',
          'Gyeongsang ',
          'Gyeongsangnam-do',
          'Incheon',
          'Jeju-Si',
          'Jeonbuk',
          'Kangweon',
          'Kwangju',
          'Kyeonggi',
          'Kyeongsangbuk',
          'Kyeongsangnam',
          'Kyonggi-do',
          'Kyungbuk-Do',
          'Kyunggi-Do',
          'Kyunggi-do',
          'Pusan',
          'Seoul',
          'Sudogwon',
          'Taegu',
          'Taejeon',
          'Taejon-gwangyoksi',
          'Ulsan',
          'Wonju',
          'gwangyoksi'
      ]
  },
  {
      'id': 117,
      'name': 'Kuwait',
      'iso3': 'KWT',
      'iso2': 'KW',
      'country_code': '414',
      'phone_code': '965',
      'capital': 'Kuwait City',
      'currency': 'KWD',
      'states': [
          'Al Asimah',
          'Hawalli',
          'Mishref',
          'Qadesiya',
          'Safat',
          'Salmiya',
          'al-Ahmadi',
          'al-Farwaniyah',
          'al-Jahra',
          'al-Kuwayt'
      ]
  },
  {
      'id': 118,
      'name': 'Kyrgyzstan',
      'iso3': 'KGZ',
      'iso2': 'KG',
      'country_code': '417',
      'phone_code': '996',
      'capital': 'Bishkek',
      'currency': 'KGS',
      'states': [
          'Batken',
          'Bishkek',
          'Chui',
          'Issyk-Kul',
          'Jalal-Abad',
          'Naryn',
          'Osh',
          'Talas'
      ]
  },
  {
      'id': 119,
      'name': 'Laos',
      'iso3': 'LAO',
      'iso2': 'LA',
      'country_code': '418',
      'phone_code': '856',
      'capital': 'Vientiane',
      'currency': 'LAK',
      'states': [
          'Attopu',
          'Bokeo',
          'Bolikhamsay',
          'Champasak',
          'Houaphanh',
          'Khammouane',
          'Luang Nam Tha',
          'Luang Prabang',
          'Oudomxay',
          'Phongsaly',
          'Saravan',
          'Savannakhet',
          'Sekong',
          'Viangchan Prefecture',
          'Viangchan Province',
          'Xaignabury',
          'Xiang Khuang'
      ]
  },
  {
      'id': 120,
      'name': 'Latvia',
      'iso3': 'LVA',
      'iso2': 'LV',
      'country_code': '428',
      'phone_code': '371',
      'capital': 'Riga',
      'currency': 'EUR',
      'states': [
          'Aizkraukles',
          'Aluksnes',
          'Balvu',
          'Bauskas',
          'Cesu',
          'Daugavpils',
          'Daugavpils City',
          'Dobeles',
          'Gulbenes',
          'Jekabspils',
          'Jelgava',
          'Jelgavas',
          'Jurmala City',
          'Kraslavas',
          'Kuldigas',
          'Liepaja',
          'Liepajas',
          'Limbazhu',
          'Ludzas',
          'Madonas',
          'Ogres',
          'Preilu',
          'Rezekne',
          'Rezeknes',
          'Riga',
          'Rigas',
          'Saldus',
          'Talsu',
          'Tukuma',
          'Valkas',
          'Valmieras',
          'Ventspils',
          'Ventspils City'
      ]
  },
  {
      'id': 121,
      'name': 'Lebanon',
      'iso3': 'LBN',
      'iso2': 'LB',
      'country_code': '422',
      'phone_code': '961',
      'capital': 'Beirut',
      'currency': 'LBP',
      'states': [
          'Beirut',
          'Jabal Lubnan',
          'Mohafazat Liban-Nord',
          'Mohafazat Mont-Liban',
          'Sidon',
          'al-Biqa',
          'al-Janub',
          'an-Nabatiyah',
          'ash-Shamal'
      ]
  },
  {
      'id': 122,
      'name': 'Lesotho',
      'iso3': 'LSO',
      'iso2': 'LS',
      'country_code': '426',
      'phone_code': '266',
      'capital': 'Maseru',
      'currency': 'LSL',
      'states': [
          'Berea',
          'Butha-Buthe',
          'Leribe',
          'Mafeteng',
          'Maseru',
          'Mohale\'s Hoek',
          'Mokhotlong',
          'Qacha\'s Nek',
          'Quthing',
          'Thaba-Tseka'
      ]
  },
  {
      'id': 123,
      'name': 'Liberia',
      'iso3': 'LBR',
      'iso2': 'LR',
      'country_code': '430',
      'phone_code': '231',
      'capital': 'Monrovia',
      'currency': 'LRD',
      'states': [
          'Bomi',
          'Bong',
          'Grand Bassa',
          'Grand Cape Mount',
          'Grand Gedeh',
          'Loffa',
          'Margibi',
          'Maryland and Grand Kru',
          'Montserrado',
          'Nimba',
          'Rivercess',
          'Sinoe'
      ]
  },
  {
      'id': 124,
      'name': 'Libya',
      'iso3': 'LBY',
      'iso2': 'LY',
      'country_code': '434',
      'phone_code': '218',
      'capital': 'Tripolis',
      'currency': 'LYD',
      'states': [
          'Ajdabiya',
          'Banghazi',
          'Darnah',
          'Ghadamis',
          'Gharyan',
          'Misratah',
          'Murzuq',
          'Sabha',
          'Sawfajjin',
          'Surt',
          'Tarabulus',
          'Tarhunah',
          'Tripolitania',
          'Tubruq',
          'Yafran',
          'Zlitan',
          'al-\'Aziziyah',
          'al-Fatih',
          'al-Jabal al Akhdar',
          'al-Jufrah',
          'al-Khums',
          'al-Kufrah',
          'an-Nuqat al-Khams',
          'ash-Shati\'',
          'az-Zawiyah'
      ]
  },
  {
      'id': 125,
      'name': 'Liechtenstein',
      'iso3': 'LIE',
      'iso2': 'LI',
      'country_code': '438',
      'phone_code': '423',
      'capital': 'Vaduz',
      'currency': 'CHF',
      'states': [
          'Balzers',
          'Eschen',
          'Gamprin',
          'Mauren',
          'Planken',
          'Ruggell',
          'Schaan',
          'Schellenberg',
          'Triesen',
          'Triesenberg',
          'Vaduz'
      ]
  },
  {
      'id': 126,
      'name': 'Lithuania',
      'iso3': 'LTU',
      'iso2': 'LT',
      'country_code': '440',
      'phone_code': '370',
      'capital': 'Vilnius',
      'currency': 'LTL',
      'states': [
          'Alytaus',
          'Anyksciai',
          'Kauno',
          'Klaipedos',
          'Marijampoles',
          'Panevezhio',
          'Panevezys',
          'Shiauliu',
          'Taurages',
          'Telshiu',
          'Telsiai',
          'Utenos',
          'Vilniaus'
      ]
  },
  {
      'id': 127,
      'name': 'Luxembourg',
      'iso3': 'LUX',
      'iso2': 'LU',
      'country_code': '442',
      'phone_code': '352',
      'capital': 'Luxembourg',
      'currency': 'EUR',
      'states': [
          'Capellen',
          'Clervaux',
          'Diekirch',
          'Echternach',
          'Esch-sur-Alzette',
          'Grevenmacher',
          'Luxembourg',
          'Mersch',
          'Redange',
          'Remich',
          'Vianden',
          'Wiltz'
      ]
  },
  {
      'id': 128,
      'name': 'Macau S.A.R.',
      'iso3': 'MAC',
      'iso2': 'MO',
      'country_code': '446',
      'phone_code': '853',
      'capital': 'Macao',
      'currency': 'MOP',
      'states': [
          'Macau'
      ]
  },
  {
      'id': 129,
      'name': 'Macedonia',
      'iso3': 'MKD',
      'iso2': 'MK',
      'country_code': '807',
      'phone_code': '389',
      'capital': 'Skopje',
      'currency': 'MKD',
      'states': [
          'Berovo',
          'Bitola',
          'Brod',
          'Debar',
          'Delchevo',
          'Demir Hisar',
          'Gevgelija',
          'Gostivar',
          'Kavadarci',
          'Kichevo',
          'Kochani',
          'Kratovo',
          'Kriva Palanka',
          'Krushevo',
          'Kumanovo',
          'Negotino',
          'Ohrid',
          'Prilep',
          'Probishtip',
          'Radovish',
          'Resen',
          'Shtip',
          'Skopje',
          'Struga',
          'Strumica',
          'Sveti Nikole',
          'Tetovo',
          'Valandovo',
          'Veles',
          'Vinica'
      ]
  },
  {
      'id': 130,
      'name': 'Madagascar',
      'iso3': 'MDG',
      'iso2': 'MG',
      'country_code': '450',
      'phone_code': '261',
      'capital': 'Antananarivo',
      'currency': 'MGA',
      'states': [
          'Antananarivo',
          'Antsiranana',
          'Fianarantsoa',
          'Mahajanga',
          'Toamasina',
          'Toliary'
      ]
  },
  {
      'id': 131,
      'name': 'Malawi',
      'iso3': 'MWI',
      'iso2': 'MW',
      'country_code': '454',
      'phone_code': '265',
      'capital': 'Lilongwe',
      'currency': 'MWK',
      'states': [
          'Balaka',
          'Blantyre City',
          'Chikwawa',
          'Chiradzulu',
          'Chitipa',
          'Dedza',
          'Dowa',
          'Karonga',
          'Kasungu',
          'Lilongwe City',
          'Machinga',
          'Mangochi',
          'Mchinji',
          'Mulanje',
          'Mwanza',
          'Mzimba',
          'Mzuzu City',
          'Nkhata Bay',
          'Nkhotakota',
          'Nsanje',
          'Ntcheu',
          'Ntchisi',
          'Phalombe',
          'Rumphi',
          'Salima',
          'Thyolo',
          'Zomba Municipality'
      ]
  },
  {
      'id': 132,
      'name': 'Malaysia',
      'iso3': 'MYS',
      'iso2': 'MY',
      'country_code': '458',
      'phone_code': '60',
      'capital': 'Kuala Lumpur',
      'currency': 'MYR',
      'states': [
          'Johor',
          'Kedah',
          'Kelantan',
          'Kuala Lumpur',
          'Labuan',
          'Melaka',
          'Negeri Johor',
          'Negeri Sembilan',
          'Pahang',
          'Penang',
          'Perak',
          'Perlis',
          'Pulau Pinang',
          'Sabah',
          'Sarawak',
          'Selangor',
          'Sembilan',
          'Terengganu'
      ]
  },
  {
      'id': 133,
      'name': 'Maldives',
      'iso3': 'MDV',
      'iso2': 'MV',
      'country_code': '462',
      'phone_code': '960',
      'capital': 'Male',
      'currency': 'MVR',
      'states': [
          'Alif Alif',
          'Alif Dhaal',
          'Baa',
          'Dhaal',
          'Faaf',
          'Gaaf Alif',
          'Gaaf Dhaal',
          'Ghaviyani',
          'Haa Alif',
          'Haa Dhaal',
          'Kaaf',
          'Laam',
          'Lhaviyani',
          'Male',
          'Miim',
          'Nuun',
          'Raa',
          'Shaviyani',
          'Siin',
          'Thaa',
          'Vaav'
      ]
  },
  {
      'id': 134,
      'name': 'Mali',
      'iso3': 'MLI',
      'iso2': 'ML',
      'country_code': '466',
      'phone_code': '223',
      'capital': 'Bamako',
      'currency': 'XOF',
      'states': [
          'Bamako',
          'Gao',
          'Kayes',
          'Kidal',
          'Koulikoro',
          'Mopti',
          'Segou',
          'Sikasso',
          'Tombouctou'
      ]
  },
  {
      'id': 135,
      'name': 'Malta',
      'iso3': 'MLT',
      'iso2': 'MT',
      'country_code': '470',
      'phone_code': '356',
      'capital': 'Valletta',
      'currency': 'EUR',
      'states': [
          'Gozo and Comino',
          'Inner Harbour',
          'Northern',
          'Outer Harbour',
          'South Eastern',
          'Valletta',
          'Western'
      ]
  },
  {
      'id': 136,
      'name': 'Man (Isle of)',
      'iso3': 'IMN',
      'iso2': 'IM',
      'country_code': '833',
      'phone_code': '+44-1624',
      'capital': 'Douglas, Isle of Man',
      'currency': 'GBP',
      'states': [
          'Castletown',
          'Douglas',
          'Laxey',
          'Onchan',
          'Peel',
          'Port Erin',
          'Port Saint Mary',
          'Ramsey'
      ]
  },
  {
      'id': 137,
      'name': 'Marshall Islands',
      'iso3': 'MHL',
      'iso2': 'MH',
      'country_code': '584',
      'phone_code': '692',
      'capital': 'Majuro',
      'currency': 'USD',
      'states': [
          'Ailinlaplap',
          'Ailuk',
          'Arno',
          'Aur',
          'Bikini',
          'Ebon',
          'Enewetak',
          'Jabat',
          'Jaluit',
          'Kili',
          'Kwajalein',
          'Lae',
          'Lib',
          'Likiep',
          'Majuro',
          'Maloelap',
          'Mejit',
          'Mili',
          'Namorik',
          'Namu',
          'Rongelap',
          'Ujae',
          'Utrik',
          'Wotho',
          'Wotje'
      ]
  },
  {
      'id': 138,
      'name': 'Martinique',
      'iso3': 'MTQ',
      'iso2': 'MQ',
      'country_code': '474',
      'phone_code': '596',
      'capital': 'Fort-de-France',
      'currency': 'EUR',
      'states': [
          'Fort-de-France',
          'La Trinite',
          'Le Marin',
          'Saint-Pierre'
      ]
  },
  {
      'id': 139,
      'name': 'Mauritania',
      'iso3': 'MRT',
      'iso2': 'MR',
      'country_code': '478',
      'phone_code': '222',
      'capital': 'Nouakchott',
      'currency': 'MRO',
      'states': [
          'Adrar',
          'Assaba',
          'Brakna',
          'Dhakhlat Nawadibu',
          'Hudh-al-Gharbi',
          'Hudh-ash-Sharqi',
          'Inshiri',
          'Nawakshut',
          'Qidimagha',
          'Qurqul',
          'Taqant',
          'Tiris Zammur',
          'Trarza'
      ]
  },
  {
      'id': 140,
      'name': 'Mauritius',
      'iso3': 'MUS',
      'iso2': 'MU',
      'country_code': '480',
      'phone_code': '230',
      'capital': 'Port Louis',
      'currency': 'MUR',
      'states': [
          'Black River',
          'Eau Coulee',
          'Flacq',
          'Floreal',
          'Grand Port',
          'Moka',
          'Pamplempousses',
          'Plaines Wilhelm',
          'Port Louis',
          'Riviere du Rempart',
          'Rodrigues',
          'Rose Hill',
          'Savanne'
      ]
  },
  {
      'id': 141,
      'name': 'Mayotte',
      'iso3': 'MYT',
      'iso2': 'YT',
      'country_code': '175',
      'phone_code': '262',
      'capital': 'Mamoudzou',
      'currency': 'EUR',
      'states': [
          'Mayotte',
          'Pamanzi'
      ]
  },
  {
      'id': 142,
      'name': 'Mexico',
      'iso3': 'MEX',
      'iso2': 'MX',
      'country_code': '484',
      'phone_code': '52',
      'capital': 'Mexico City',
      'currency': 'MXN',
      'states': [
          'Aguascalientes',
          'Baja California',
          'Baja California Sur',
          'Campeche',
          'Chiapas',
          'Chihuahua',
          'Coahuila',
          'Colima',
          'Distrito Federal',
          'Durango',
          'Estado de Mexico',
          'Guanajuato',
          'Guerrero',
          'Hidalgo',
          'Jalisco',
          'Mexico',
          'Michoacan',
          'Morelos',
          'Nayarit',
          'Nuevo Leon',
          'Oaxaca',
          'Puebla',
          'Queretaro',
          'Quintana Roo',
          'San Luis Potosi',
          'Sinaloa',
          'Sonora',
          'Tabasco',
          'Tamaulipas',
          'Tlaxcala',
          'Veracruz',
          'Yucatan',
          'Zacatecas'
      ]
  },
  {
      'id': 143,
      'name': 'Micronesia',
      'iso3': 'FSM',
      'iso2': 'FM',
      'country_code': '583',
      'phone_code': '691',
      'capital': 'Palikir',
      'currency': 'USD',
      'states': [
          'Chuuk',
          'Kusaie',
          'Pohnpei',
          'Yap'
      ]
  },
  {
      'id': 144,
      'name': 'Moldova',
      'iso3': 'MDA',
      'iso2': 'MD',
      'country_code': '498',
      'phone_code': '373',
      'capital': 'Chisinau',
      'currency': 'MDL',
      'states': [
          'Balti',
          'Cahul',
          'Chisinau',
          'Chisinau Oras',
          'Edinet',
          'Gagauzia',
          'Lapusna',
          'Orhei',
          'Soroca',
          'Taraclia',
          'Tighina',
          'Transnistria',
          'Ungheni'
      ]
  },
  {
      'id': 145,
      'name': 'Monaco',
      'iso3': 'MCO',
      'iso2': 'MC',
      'country_code': '492',
      'phone_code': '377',
      'capital': 'Monaco',
      'currency': 'EUR',
      'states': [
          'Fontvieille',
          'La Condamine',
          'Monaco-Ville',
          'Monte Carlo'
      ]
  },
  {
      'id': 146,
      'name': 'Mongolia',
      'iso3': 'MNG',
      'iso2': 'MN',
      'country_code': '496',
      'phone_code': '976',
      'capital': 'Ulan Bator',
      'currency': 'MNT',
      'states': [
          'Arhangaj',
          'Bajan-Olgij',
          'Bajanhongor',
          'Bulgan',
          'Darhan-Uul',
          'Dornod',
          'Dornogovi',
          'Dundgovi',
          'Govi-Altaj',
          'Govisumber',
          'Hentij',
          'Hovd',
          'Hovsgol',
          'Omnogovi',
          'Orhon',
          'Ovorhangaj',
          'Selenge',
          'Suhbaatar',
          'Tov',
          'Ulaanbaatar',
          'Uvs',
          'Zavhan'
      ]
  },
  {
      'id': 148,
      'name': 'Montserrat',
      'iso3': 'MSR',
      'iso2': 'MS',
      'country_code': '500',
      'phone_code': '+1-664',
      'capital': 'Plymouth',
      'currency': 'XCD',
      'states': [
          'Montserrat'
      ]
  },
  {
      'id': 149,
      'name': 'Morocco',
      'iso3': 'MAR',
      'iso2': 'MA',
      'country_code': '504',
      'phone_code': '212',
      'capital': 'Rabat',
      'currency': 'MAD',
      'states': [
          'Agadir',
          'Casablanca',
          'Chaouia-Ouardigha',
          'Doukkala-Abda',
          'Fes-Boulemane',
          'Gharb-Chrarda-Beni Hssen',
          'Guelmim',
          'Kenitra',
          'Marrakech-Tensift-Al Haouz',
          'Meknes-Tafilalet',
          'Oriental',
          'Oujda',
          'Province de Tanger',
          'Rabat-Sale-Zammour-Zaer',
          'Sala Al Jadida',
          'Settat',
          'Souss Massa-Draa',
          'Tadla-Azilal',
          'Tangier-Tetouan',
          'Taza-Al Hoceima-Taounate',
          'Wilaya de Casablanca',
          'Wilaya de Rabat-Sale'
      ]
  },
  {
      'id': 150,
      'name': 'Mozambique',
      'iso3': 'MOZ',
      'iso2': 'MZ',
      'country_code': '508',
      'phone_code': '258',
      'capital': 'Maputo',
      'currency': 'MZN',
      'states': [
          'Cabo Delgado',
          'Gaza',
          'Inhambane',
          'Manica',
          'Maputo',
          'Maputo Provincia',
          'Nampula',
          'Niassa',
          'Sofala',
          'Tete',
          'Zambezia'
      ]
  },
  {
      'id': 151,
      'name': 'Myanmar',
      'iso3': 'MMR',
      'iso2': 'MM',
      'country_code': '104',
      'phone_code': '95',
      'capital': 'Nay Pyi Taw',
      'currency': 'MMK',
      'states': [
          'Ayeyarwady',
          'Bago',
          'Chin',
          'Kachin',
          'Kayah',
          'Kayin',
          'Magway',
          'Mandalay',
          'Mon',
          'Nay Pyi Taw',
          'Rakhine',
          'Sagaing',
          'Shan',
          'Tanintharyi',
          'Yangon'
      ]
  },
  {
      'id': 152,
      'name': 'Namibia',
      'iso3': 'NAM',
      'iso2': 'NA',
      'country_code': '516',
      'phone_code': '264',
      'capital': 'Windhoek',
      'currency': 'NAD',
      'states': [
          'Caprivi',
          'Erongo',
          'Hardap',
          'Karas',
          'Kavango',
          'Khomas',
          'Kunene',
          'Ohangwena',
          'Omaheke',
          'Omusati',
          'Oshana',
          'Oshikoto',
          'Otjozondjupa'
      ]
  },
  {
      'id': 153,
      'name': 'Nauru',
      'iso3': 'NRU',
      'iso2': 'NR',
      'country_code': '520',
      'phone_code': '674',
      'capital': 'Yaren',
      'currency': 'AUD',
      'states': [
          'Yaren'
      ]
  },
  {
      'id': 154,
      'name': 'Nepal',
      'iso3': 'NPL',
      'iso2': 'NP',
      'country_code': '524',
      'phone_code': '977',
      'capital': 'Kathmandu',
      'currency': 'NPR',
      'states': [
          'Bagmati',
          'Bheri',
          'Dhawalagiri',
          'Gandaki',
          'Janakpur',
          'Karnali',
          'Koshi',
          'Lumbini',
          'Mahakali',
          'Mechi',
          'Narayani',
          'Rapti',
          'Sagarmatha',
          'Seti'
      ]
  },
  {
      'id': 155,
      'name': 'Netherlands Antilles',
      'iso3': 'ANT',
      'iso2': 'AN',
      'country_code': '530',
      'phone_code': '',
      'capital': '',
      'currency': '',
      'states': [
          'Bonaire',
          'Curacao',
          'Saba',
          'Sint Eustatius',
          'Sint Maarten'
      ]
  },
  {
      'id': 156,
      'name': 'Netherlands The',
      'iso3': 'NLD',
      'iso2': 'NL',
      'country_code': '528',
      'phone_code': '31',
      'capital': 'Amsterdam',
      'currency': 'EUR',
      'states': [
          'Amsterdam',
          'Benelux',
          'Drenthe',
          'Flevoland',
          'Friesland',
          'Gelderland',
          'Groningen',
          'Limburg',
          'Noord-Brabant',
          'Noord-Holland',
          'Overijssel',
          'South Holland',
          'Utrecht',
          'Zeeland',
          'Zuid-Holland'
      ]
  },
  {
      'id': 157,
      'name': 'New Caledonia',
      'iso3': 'NCL',
      'iso2': 'NC',
      'country_code': '540',
      'phone_code': '687',
      'capital': 'Noumea',
      'currency': 'XPF',
      'states': [
          'Iles',
          'Nord',
          'Sud'
      ]
  },
  {
      'id': 158,
      'name': 'New Zealand',
      'iso3': 'NZL',
      'iso2': 'NZ',
      'country_code': '554',
      'phone_code': '64',
      'capital': 'Wellington',
      'currency': 'NZD',
      'states': [
          'Area Outside Region',
          'Auckland',
          'Bay of Plenty',
          'Canterbury',
          'Christchurch',
          'Gisborne',
          'Hawke\'s Bay',
          'Manawatu-Wanganui',
          'Marlborough',
          'Nelson',
          'Northland',
          'Otago',
          'Rodney',
          'Southland',
          'Taranaki',
          'Tasman',
          'Waikato',
          'Wellington',
          'West Coast'
      ]
  },
  {
      'id': 159,
      'name': 'Nicaragua',
      'iso3': 'NIC',
      'iso2': 'NI',
      'country_code': '558',
      'phone_code': '505',
      'capital': 'Managua',
      'currency': 'NIO',
      'states': [
          'Atlantico Norte',
          'Atlantico Sur',
          'Boaco',
          'Carazo',
          'Chinandega',
          'Chontales',
          'Esteli',
          'Granada',
          'Jinotega',
          'Leon',
          'Madriz',
          'Managua',
          'Masaya',
          'Matagalpa',
          'Nueva Segovia',
          'Rio San Juan',
          'Rivas'
      ]
  },
  {
      'id': 160,
      'name': 'Niger',
      'iso3': 'NER',
      'iso2': 'NE',
      'country_code': '562',
      'phone_code': '227',
      'capital': 'Niamey',
      'currency': 'XOF',
      'states': [
          'Agadez',
          'Diffa',
          'Dosso',
          'Maradi',
          'Niamey',
          'Tahoua',
          'Tillabery',
          'Zinder'
      ]
  },
  {
      'id': 161,
      'name': 'Nigeria',
      'iso3': 'NGA',
      'iso2': 'NG',
      'country_code': '566',
      'phone_code': '234',
      'capital': 'Abuja',
      'currency': 'NGN',
      'states': [
          'Abia',
          'Abuja Federal Capital Territor',
          'Adamawa',
          'Akwa Ibom',
          'Anambra',
          'Bauchi',
          'Bayelsa',
          'Benue',
          'Borno',
          'Cross River',
          'Delta',
          'Ebonyi',
          'Edo',
          'Ekiti',
          'Enugu',
          'Gombe',
          'Imo',
          'Jigawa',
          'Kaduna',
          'Kano',
          'Katsina',
          'Kebbi',
          'Kogi',
          'Kwara',
          'Lagos',
          'Nassarawa',
          'Niger',
          'Ogun',
          'Ondo',
          'Osun',
          'Oyo',
          'Plateau',
          'Rivers',
          'Sokoto',
          'Taraba',
          'Yobe',
          'Zamfara'
      ]
  },
  {
      'id': 162,
      'name': 'Niue',
      'iso3': 'NIU',
      'iso2': 'NU',
      'country_code': '570',
      'phone_code': '683',
      'capital': 'Alofi',
      'currency': 'NZD',
      'states': [
          'Niue'
      ]
  },
  {
      'id': 163,
      'name': 'Norfolk Island',
      'iso3': 'NFK',
      'iso2': 'NF',
      'country_code': '574',
      'phone_code': '672',
      'capital': 'Kingston',
      'currency': 'AUD',
      'states': [
          'Norfolk Island'
      ]
  },
  {
      'id': 164,
      'name': 'Northern Mariana Islands',
      'iso3': 'MNP',
      'iso2': 'MP',
      'country_code': '580',
      'phone_code': '+1-670',
      'capital': 'Saipan',
      'currency': 'USD',
      'states': [
          'Northern Islands',
          'Rota',
          'Saipan',
          'Tinian'
      ]
  },
  {
      'id': 165,
      'name': 'Norway',
      'iso3': 'NOR',
      'iso2': 'NO',
      'country_code': '578',
      'phone_code': '47',
      'capital': 'Oslo',
      'currency': 'NOK',
      'states': [
          'Akershus',
          'Aust Agder',
          'Bergen',
          'Buskerud',
          'Finnmark',
          'Hedmark',
          'Hordaland',
          'Moere og Romsdal',
          'Nord Trondelag',
          'Nordland',
          'Oestfold',
          'Oppland',
          'Oslo',
          'Rogaland',
          'Soer Troendelag',
          'Sogn og Fjordane',
          'Stavern',
          'Sykkylven',
          'Telemark',
          'Troms',
          'Vest Agder',
          'Vestfold',
          '\u00c3\u0083\u00c6\u0092\u00c3\u0082\u00cb\u009cstfold'
      ]
  },
  {
      'id': 166,
      'name': 'Oman',
      'iso3': 'OMN',
      'iso2': 'OM',
      'country_code': '512',
      'phone_code': '968',
      'capital': 'Muscat',
      'currency': 'OMR',
      'states': [
          'Al Buraimi',
          'Dhufar',
          'Masqat',
          'Musandam',
          'Rusayl',
          'Wadi Kabir',
          'ad-Dakhiliyah',
          'adh-Dhahirah',
          'al-Batinah',
          'ash-Sharqiyah'
      ]
  },
  {
      'id': 167,
      'name': 'Pakistan',
      'iso3': 'PAK',
      'iso2': 'PK',
      'country_code': '586',
      'phone_code': '92',
      'capital': 'Islamabad',
      'currency': 'PKR',
      'states': [
          'Baluchistan',
          'Federal Capital Area',
          'Federally administered Tribal ',
          'North-West Frontier',
          'Northern Areas',
          'Punjab',
          'Sind'
      ]
  },
  {
      'id': 168,
      'name': 'Palau',
      'iso3': 'PLW',
      'iso2': 'PW',
      'country_code': '585',
      'phone_code': '680',
      'capital': 'Melekeok',
      'currency': 'USD',
      'states': [
          'Aimeliik',
          'Airai',
          'Angaur',
          'Hatobohei',
          'Kayangel',
          'Koror',
          'Melekeok',
          'Ngaraard',
          'Ngardmau',
          'Ngaremlengui',
          'Ngatpang',
          'Ngchesar',
          'Ngerchelong',
          'Ngiwal',
          'Peleliu',
          'Sonsorol'
      ]
  },
  {
      'id': 169,
      'name': 'Palestinian Territory Occupied',
      'iso3': 'PSE',
      'iso2': 'PS',
      'country_code': '275',
      'phone_code': '970',
      'capital': 'East Jerusalem',
      'currency': 'ILS',
      'states': [
          'Ariha',
          'Bayt Lahm',
          'Bethlehem',
          'Dayr-al-Balah',
          'Ghazzah',
          'Ghazzah ash-Shamaliyah',
          'Janin',
          'Khan Yunis',
          'Nabulus',
          'Qalqilyah',
          'Rafah',
          'Ram Allah wal-Birah',
          'Salfit',
          'Tubas',
          'Tulkarm',
          'al-Khalil',
          'al-Quds'
      ]
  },
  {
      'id': 170,
      'name': 'Panama',
      'iso3': 'PAN',
      'iso2': 'PA',
      'country_code': '591',
      'phone_code': '507',
      'capital': 'Panama City',
      'currency': 'PAB',
      'states': [
          'Bocas del Toro',
          'Chiriqui',
          'Cocle',
          'Colon',
          'Darien',
          'Embera',
          'Herrera',
          'Kuna Yala',
          'Los Santos',
          'Ngobe Bugle',
          'Panama',
          'Veraguas'
      ]
  },
  {
      'id': 171,
      'name': 'Papua new Guinea',
      'iso3': 'PNG',
      'iso2': 'PG',
      'country_code': '598',
      'phone_code': '675',
      'capital': 'Port Moresby',
      'currency': 'PGK',
      'states': [
          'East New Britain',
          'East Sepik',
          'Eastern Highlands',
          'Enga',
          'Fly River',
          'Gulf',
          'Madang',
          'Manus',
          'Milne Bay',
          'Morobe',
          'National Capital District',
          'New Ireland',
          'North Solomons',
          'Oro',
          'Sandaun',
          'Simbu',
          'Southern Highlands',
          'West New Britain',
          'Western Highlands'
      ]
  },
  {
      'id': 172,
      'name': 'Paraguay',
      'iso3': 'PRY',
      'iso2': 'PY',
      'country_code': '600',
      'phone_code': '595',
      'capital': 'Asuncion',
      'currency': 'PYG',
      'states': [
          'Alto Paraguay',
          'Alto Parana',
          'Amambay',
          'Asuncion',
          'Boqueron',
          'Caaguazu',
          'Caazapa',
          'Canendiyu',
          'Central',
          'Concepcion',
          'Cordillera',
          'Guaira',
          'Itapua',
          'Misiones',
          'Neembucu',
          'Paraguari',
          'Presidente Hayes',
          'San Pedro'
      ]
  },
  {
      'id': 173,
      'name': 'Peru',
      'iso3': 'PER',
      'iso2': 'PE',
      'country_code': '604',
      'phone_code': '51',
      'capital': 'Lima',
      'currency': 'PEN',
      'states': [
          'Amazonas',
          'Ancash',
          'Apurimac',
          'Arequipa',
          'Ayacucho',
          'Cajamarca',
          'Cusco',
          'Huancavelica',
          'Huanuco',
          'Ica',
          'Junin',
          'La Libertad',
          'Lambayeque',
          'Lima y Callao',
          'Loreto',
          'Madre de Dios',
          'Moquegua',
          'Pasco',
          'Piura',
          'Puno',
          'San Martin',
          'Tacna',
          'Tumbes',
          'Ucayali'
      ]
  },
  {
      'id': 174,
      'name': 'Philippines',
      'iso3': 'PHL',
      'iso2': 'PH',
      'country_code': '608',
      'phone_code': '63',
      'capital': 'Manila',
      'currency': 'PHP',
      'states': [
          'Batangas',
          'Bicol',
          'Bulacan',
          'Cagayan',
          'Caraga',
          'Central Luzon',
          'Central Mindanao',
          'Central Visayas',
          'Cordillera',
          'Davao',
          'Eastern Visayas',
          'Greater Metropolitan Area',
          'Ilocos',
          'Laguna',
          'Luzon',
          'Mactan',
          'Metropolitan Manila Area',
          'Muslim Mindanao',
          'Northern Mindanao',
          'Southern Mindanao',
          'Southern Tagalog',
          'Western Mindanao',
          'Western Visayas'
      ]
  },
  {
      'id': 175,
      'name': 'Pitcairn Island',
      'iso3': 'PCN',
      'iso2': 'PN',
      'country_code': '612',
      'phone_code': '870',
      'capital': 'Adamstown',
      'currency': 'NZD',
      'states': [
          'Pitcairn Island'
      ]
  },
  {
      'id': 176,
      'name': 'Poland',
      'iso3': 'POL',
      'iso2': 'PL',
      'country_code': '616',
      'phone_code': '48',
      'capital': 'Warsaw',
      'currency': 'PLN',
      'states': [
          'Biale Blota',
          'Dobroszyce',
          'Dolnoslaskie',
          'Dziekanow Lesny',
          'Hopowo',
          'Kartuzy',
          'Koscian',
          'Krakow',
          'Kujawsko-Pomorskie',
          'Lodzkie',
          'Lubelskie',
          'Lubuskie',
          'Malomice',
          'Malopolskie',
          'Mazowieckie',
          'Mirkow',
          'Opolskie',
          'Ostrowiec',
          'Podkarpackie',
          'Podlaskie',
          'Polska',
          'Pomorskie',
          'Poznan',
          'Pruszkow',
          'Rymanowska',
          'Rzeszow',
          'Slaskie',
          'Stare Pole',
          'Swietokrzyskie',
          'Warminsko-Mazurskie',
          'Warsaw',
          'Wejherowo',
          'Wielkopolskie',
          'Wroclaw',
          'Zachodnio-Pomorskie',
          'Zukowo'
      ]
  },
  {
      'id': 177,
      'name': 'Portugal',
      'iso3': 'PRT',
      'iso2': 'PT',
      'country_code': '620',
      'phone_code': '351',
      'capital': 'Lisbon',
      'currency': 'EUR',
      'states': [
          'Abrantes',
          'Acores',
          'Alentejo',
          'Algarve',
          'Braga',
          'Centro',
          'Distrito de Leiria',
          'Distrito de Viana do Castelo',
          'Distrito de Vila Real',
          'Distrito do Porto',
          'Lisboa e Vale do Tejo',
          'Madeira',
          'Norte',
          'Paivas'
      ]
  },
  {
      'id': 178,
      'name': 'Puerto Rico',
      'iso3': 'PRI',
      'iso2': 'PR',
      'country_code': '630',
      'phone_code': '+1-787 and 1-939',
      'capital': 'San Juan',
      'currency': 'USD',
      'states': [
          'Arecibo',
          'Bayamon',
          'Carolina',
          'Florida',
          'Guayama',
          'Humacao',
          'Mayaguez-Aguadilla',
          'Ponce',
          'Salinas',
          'San Juan'
      ]
  },
  {
      'id': 179,
      'name': 'Qatar',
      'iso3': 'QAT',
      'iso2': 'QA',
      'country_code': '634',
      'phone_code': '974',
      'capital': 'Doha',
      'currency': 'QAR',
      'states': [
          'Doha',
          'Jarian-al-Batnah',
          'Umm Salal',
          'ad-Dawhah',
          'al-Ghuwayriyah',
          'al-Jumayliyah',
          'al-Khawr',
          'al-Wakrah',
          'ar-Rayyan',
          'ash-Shamal'
      ]
  },
  {
      'id': 180,
      'name': 'Reunion',
      'iso3': 'REU',
      'iso2': 'RE',
      'country_code': '638',
      'phone_code': '262',
      'capital': 'Saint-Denis',
      'currency': 'EUR',
      'states': [
          'Saint-Benoit',
          'Saint-Denis',
          'Saint-Paul',
          'Saint-Pierre'
      ]
  },
  {
      'id': 181,
      'name': 'Romania',
      'iso3': 'ROU',
      'iso2': 'RO',
      'country_code': '642',
      'phone_code': '40',
      'capital': 'Bucharest',
      'currency': 'RON',
      'states': [
          'Alba',
          'Arad',
          'Arges',
          'Bacau',
          'Bihor',
          'Bistrita-Nasaud',
          'Botosani',
          'Braila',
          'Brasov',
          'Bucuresti',
          'Buzau',
          'Calarasi',
          'Caras-Severin',
          'Cluj',
          'Constanta',
          'Covasna',
          'Dambovita',
          'Dolj',
          'Galati',
          'Giurgiu',
          'Gorj',
          'Harghita',
          'Hunedoara',
          'Ialomita',
          'Iasi',
          'Ilfov',
          'Maramures',
          'Mehedinti',
          'Mures',
          'Neamt',
          'Olt',
          'Prahova',
          'Salaj',
          'Satu Mare',
          'Sibiu',
          'Sondelor',
          'Suceava',
          'Teleorman',
          'Timis',
          'Tulcea',
          'Valcea',
          'Vaslui',
          'Vrancea'
      ]
  },
  {
      'id': 182,
      'name': 'Russia',
      'iso3': 'RUS',
      'iso2': 'RU',
      'country_code': '643',
      'phone_code': '7',
      'capital': 'Moscow',
      'currency': 'RUB',
      'states': [
          'Adygeja',
          'Aga',
          'Alanija',
          'Altaj',
          'Amur',
          'Arhangelsk',
          'Astrahan',
          'Bashkortostan',
          'Belgorod',
          'Brjansk',
          'Burjatija',
          'Chechenija',
          'Cheljabinsk',
          'Chita',
          'Chukotka',
          'Chuvashija',
          'Dagestan',
          'Evenkija',
          'Gorno-Altaj',
          'Habarovsk',
          'Hakasija',
          'Hanty-Mansija',
          'Ingusetija',
          'Irkutsk',
          'Ivanovo',
          'Jamalo-Nenets',
          'Jaroslavl',
          'Jevrej',
          'Kabardino-Balkarija',
          'Kaliningrad',
          'Kalmykija',
          'Kaluga',
          'Kamchatka',
          'Karachaj-Cherkessija',
          'Karelija',
          'Kemerovo',
          'Khabarovskiy Kray',
          'Kirov',
          'Komi',
          'Komi-Permjakija',
          'Korjakija',
          'Kostroma',
          'Krasnodar',
          'Krasnojarsk',
          'Krasnoyarskiy Kray',
          'Kurgan',
          'Kursk',
          'Leningrad',
          'Lipeck',
          'Magadan',
          'Marij El',
          'Mordovija',
          'Moscow',
          'Moskovskaja Oblast',
          'Moskovskaya Oblast',
          'Moskva',
          'Murmansk',
          'Nenets',
          'Nizhnij Novgorod',
          'Novgorod',
          'Novokusnezk',
          'Novosibirsk',
          'Omsk',
          'Orenburg',
          'Orjol',
          'Penza',
          'Perm',
          'Primorje',
          'Pskov',
          'Pskovskaya Oblast',
          'Rjazan',
          'Rostov',
          'Saha',
          'Sahalin',
          'Samara',
          'Samarskaya',
          'Sankt-Peterburg',
          'Saratov',
          'Smolensk',
          'Stavropol',
          'Sverdlovsk',
          'Tajmyrija',
          'Tambov',
          'Tatarstan',
          'Tjumen',
          'Tomsk',
          'Tula',
          'Tver',
          'Tyva',
          'Udmurtija',
          'Uljanovsk',
          'Ulyanovskaya Oblast',
          'Ust-Orda',
          'Vladimir',
          'Volgograd',
          'Vologda',
          'Voronezh'
      ]
  },
  {
      'id': 183,
      'name': 'Rwanda',
      'iso3': 'RWA',
      'iso2': 'RW',
      'country_code': '646',
      'phone_code': '250',
      'capital': 'Kigali',
      'currency': 'RWF',
      'states': [
          'Butare',
          'Byumba',
          'Cyangugu',
          'Gikongoro',
          'Gisenyi',
          'Gitarama',
          'Kibungo',
          'Kibuye',
          'Kigali-ngali',
          'Ruhengeri'
      ]
  },
  {
      'id': 184,
      'name': 'Saint Helena',
      'iso3': 'SHN',
      'iso2': 'SH',
      'country_code': '654',
      'phone_code': '290',
      'capital': 'Jamestown',
      'currency': 'SHP',
      'states': [
          'Ascension',
          'Gough Island',
          'Saint Helena',
          'Tristan da Cunha'
      ]
  },
  {
      'id': 185,
      'name': 'Saint Kitts And Nevis',
      'iso3': 'KNA',
      'iso2': 'KN',
      'country_code': '659',
      'phone_code': '+1-869',
      'capital': 'Basseterre',
      'currency': 'XCD',
      'states': [
          'Christ Church Nichola Town',
          'Saint Anne Sandy Point',
          'Saint George Basseterre',
          'Saint George Gingerland',
          'Saint James Windward',
          'Saint John Capesterre',
          'Saint John Figtree',
          'Saint Mary Cayon',
          'Saint Paul Capesterre',
          'Saint Paul Charlestown',
          'Saint Peter Basseterre',
          'Saint Thomas Lowland',
          'Saint Thomas Middle Island',
          'Trinity Palmetto Point'
      ]
  },
  {
      'id': 186,
      'name': 'Saint Lucia',
      'iso3': 'LCA',
      'iso2': 'LC',
      'country_code': '662',
      'phone_code': '+1-758',
      'capital': 'Castries',
      'currency': 'XCD',
      'states': [
          'Anse-la-Raye',
          'Canaries',
          'Castries',
          'Choiseul',
          'Dennery',
          'Gros Inlet',
          'Laborie',
          'Micoud',
          'Soufriere',
          'Vieux Fort'
      ]
  },
  {
      'id': 187,
      'name': 'Saint Pierre and Miquelon',
      'iso3': 'SPM',
      'iso2': 'PM',
      'country_code': '666',
      'phone_code': '508',
      'capital': 'Saint-Pierre',
      'currency': 'EUR',
      'states': [
          'Miquelon-Langlade',
          'Saint-Pierre'
      ]
  },
  {
      'id': 188,
      'name': 'Saint Vincent And The Grenadines',
      'iso3': 'VCT',
      'iso2': 'VC',
      'country_code': '670',
      'phone_code': '+1-784',
      'capital': 'Kingstown',
      'currency': 'XCD',
      'states': [
          'Charlotte',
          'Grenadines',
          'Saint Andrew',
          'Saint David',
          'Saint George',
          'Saint Patrick'
      ]
  },
  {
      'id': 191,
      'name': 'Samoa',
      'iso3': 'WSM',
      'iso2': 'WS',
      'country_code': '882',
      'phone_code': '685',
      'capital': 'Apia',
      'currency': 'WST',
      'states': [
          'A\'ana',
          'Aiga-i-le-Tai',
          'Atua',
          'Fa\'asaleleaga',
          'Gaga\'emauga',
          'Gagaifomauga',
          'Palauli',
          'Satupa\'itea',
          'Tuamasaga',
          'Va\'a-o-Fonoti',
          'Vaisigano'
      ]
  },
  {
      'id': 192,
      'name': 'San Marino',
      'iso3': 'SMR',
      'iso2': 'SM',
      'country_code': '674',
      'phone_code': '378',
      'capital': 'San Marino',
      'currency': 'EUR',
      'states': [
          'Acquaviva',
          'Borgo Maggiore',
          'Chiesanuova',
          'Domagnano',
          'Faetano',
          'Fiorentino',
          'Montegiardino',
          'San Marino',
          'Serravalle'
      ]
  },
  {
      'id': 193,
      'name': 'Sao Tome and Principe',
      'iso3': 'STP',
      'iso2': 'ST',
      'country_code': '678',
      'phone_code': '239',
      'capital': 'Sao Tome',
      'currency': 'STD',
      'states': [
          'Agua Grande',
          'Cantagalo',
          'Lemba',
          'Lobata',
          'Me-Zochi',
          'Pague'
      ]
  },
  {
      'id': 194,
      'name': 'Saudi Arabia',
      'iso3': 'SAU',
      'iso2': 'SA',
      'country_code': '682',
      'phone_code': '966',
      'capital': 'Riyadh',
      'currency': 'SAR',
      'states': [
          'Al Khobar',
          'Aseer',
          'Ash Sharqiyah',
          'Asir',
          'Central Province',
          'Eastern Province',
          'Ha\'il',
          'Jawf',
          'Jizan',
          'Makkah',
          'Najran',
          'Qasim',
          'Tabuk',
          'Western Province',
          'al-Bahah',
          'al-Hudud-ash-Shamaliyah',
          'al-Madinah',
          'ar-Riyad'
      ]
  },
  {
      'id': 195,
      'name': 'Senegal',
      'iso3': 'SEN',
      'iso2': 'SN',
      'country_code': '686',
      'phone_code': '221',
      'capital': 'Dakar',
      'currency': 'XOF',
      'states': [
          'Dakar',
          'Diourbel',
          'Fatick',
          'Kaolack',
          'Kolda',
          'Louga',
          'Saint-Louis',
          'Tambacounda',
          'Thies',
          'Ziguinchor'
      ]
  },
  {
      'id': 196,
      'name': 'Serbia',
      'iso3': 'SRB',
      'iso2': 'RS',
      'country_code': '688',
      'phone_code': '381',
      'capital': 'Belgrade',
      'currency': 'RSD',
      'states': [
          'Central Serbia',
          'Kosovo and Metohija',
          'Vojvodina'
      ]
  },
  {
      'id': 197,
      'name': 'Seychelles',
      'iso3': 'SYC',
      'iso2': 'SC',
      'country_code': '690',
      'phone_code': '248',
      'capital': 'Victoria',
      'currency': 'SCR',
      'states': [
          'Anse Boileau',
          'Anse Royale',
          'Cascade',
          'Takamaka',
          'Victoria'
      ]
  },
  {
      'id': 198,
      'name': 'Sierra Leone',
      'iso3': 'SLE',
      'iso2': 'SL',
      'country_code': '694',
      'phone_code': '232',
      'capital': 'Freetown',
      'currency': 'SLL',
      'states': [
          'Eastern',
          'Northern',
          'Southern',
          'Western'
      ]
  },
  {
      'id': 199,
      'name': 'Singapore',
      'iso3': 'SGP',
      'iso2': 'SG',
      'country_code': '702',
      'phone_code': '65',
      'capital': 'Singapur',
      'currency': 'SGD',
      'states': [
          'Singapore'
      ]
  },
  {
      'id': 200,
      'name': 'Slovakia',
      'iso3': 'SVK',
      'iso2': 'SK',
      'country_code': '703',
      'phone_code': '421',
      'capital': 'Bratislava',
      'currency': 'EUR',
      'states': [
          'Banskobystricky',
          'Bratislavsky',
          'Kosicky',
          'Nitriansky',
          'Presovsky',
          'Trenciansky',
          'Trnavsky',
          'Zilinsky'
      ]
  },
  {
      'id': 201,
      'name': 'Slovenia',
      'iso3': 'SVN',
      'iso2': 'SI',
      'country_code': '705',
      'phone_code': '386',
      'capital': 'Ljubljana',
      'currency': 'EUR',
      'states': [
          'Benedikt',
          'Gorenjska',
          'Gorishka',
          'Jugovzhodna Slovenija',
          'Koroshka',
          'Notranjsko-krashka',
          'Obalno-krashka',
          'Obcina Domzale',
          'Obcina Vitanje',
          'Osrednjeslovenska',
          'Podravska',
          'Pomurska',
          'Savinjska',
          'Slovenian Littoral',
          'Spodnjeposavska',
          'Zasavska'
      ]
  },
  {
      'id': 202,
      'name': 'Solomon Islands',
      'iso3': 'SLB',
      'iso2': 'SB',
      'country_code': '90',
      'phone_code': '677',
      'capital': 'Honiara',
      'currency': 'SBD',
      'states': [
          'Central',
          'Choiseul',
          'Guadalcanal',
          'Isabel',
          'Makira and Ulawa',
          'Malaita',
          'Rennell and Bellona',
          'Temotu',
          'Western'
      ]
  },
  {
      'id': 203,
      'name': 'Somalia',
      'iso3': 'SOM',
      'iso2': 'SO',
      'country_code': '706',
      'phone_code': '252',
      'capital': 'Mogadishu',
      'currency': 'SOS',
      'states': [
          'Awdal',
          'Bakol',
          'Banadir',
          'Bari',
          'Bay',
          'Galgudug',
          'Gedo',
          'Hiran',
          'Jubbada Hose',
          'Jubbadha Dexe',
          'Mudug',
          'Nugal',
          'Sanag',
          'Shabellaha Dhexe',
          'Shabellaha Hose',
          'Togdher',
          'Woqoyi Galbed'
      ]
  },
  {
      'id': 204,
      'name': 'South Africa',
      'iso3': 'ZAF',
      'iso2': 'ZA',
      'country_code': '710',
      'phone_code': '27',
      'capital': 'Pretoria',
      'currency': 'ZAR',
      'states': [
          'Eastern Cape',
          'Free State',
          'Gauteng',
          'Kempton Park',
          'Kramerville',
          'KwaZulu Natal',
          'Limpopo',
          'Mpumalanga',
          'North West',
          'Northern Cape',
          'Parow',
          'Table View',
          'Umtentweni',
          'Western Cape'
      ]
  },
  {
      'id': 205,
      'name': 'South Georgia',
      'iso3': 'SGS',
      'iso2': 'GS',
      'country_code': '239',
      'phone_code': '',
      'capital': 'Grytviken',
      'currency': 'GBP',
      'states': [
          'South Georgia'
      ]
  },
  {
      'id': 206,
      'name': 'South Sudan',
      'iso3': 'SSD',
      'iso2': 'SS',
      'country_code': '728',
      'phone_code': '211',
      'capital': 'Juba',
      'currency': 'SSP',
      'states': [
          'Central Equatoria'
      ]
  },
  {
      'id': 207,
      'name': 'Spain',
      'iso3': 'ESP',
      'iso2': 'ES',
      'country_code': '724',
      'phone_code': '34',
      'capital': 'Madrid',
      'currency': 'EUR',
      'states': [
          'A Coruna',
          'Alacant',
          'Alava',
          'Albacete',
          'Almeria',
          'Andalucia',
          'Asturias',
          'Avila',
          'Badajoz',
          'Balears',
          'Barcelona',
          'Bertamirans',
          'Biscay',
          'Burgos',
          'Caceres',
          'Cadiz',
          'Cantabria',
          'Castello',
          'Catalunya',
          'Ceuta',
          'Ciudad Real',
          'Comunidad Autonoma de Canarias',
          'Comunidad Autonoma de Cataluna',
          'Comunidad Autonoma de Galicia',
          'Comunidad Autonoma de las Isla',
          'Comunidad Autonoma del Princip',
          'Comunidad Valenciana',
          'Cordoba',
          'Cuenca',
          'Gipuzkoa',
          'Girona',
          'Granada',
          'Guadalajara',
          'Guipuzcoa',
          'Huelva',
          'Huesca',
          'Jaen',
          'La Rioja',
          'Las Palmas',
          'Leon',
          'Lerida',
          'Lleida',
          'Lugo',
          'Madrid',
          'Malaga',
          'Melilla',
          'Murcia',
          'Navarra',
          'Ourense',
          'Pais Vasco',
          'Palencia',
          'Pontevedra',
          'Salamanca',
          'Santa Cruz de Tenerife',
          'Segovia',
          'Sevilla',
          'Soria',
          'Tarragona',
          'Tenerife',
          'Teruel',
          'Toledo',
          'Valencia',
          'Valladolid',
          'Vizcaya',
          'Zamora',
          'Zaragoza'
      ]
  },
  {
      'id': 208,
      'name': 'Sri Lanka',
      'iso3': 'LKA',
      'iso2': 'LK',
      'country_code': '144',
      'phone_code': '94',
      'capital': 'Colombo',
      'currency': 'LKR',
      'states': [
          'Amparai',
          'Anuradhapuraya',
          'Badulla',
          'Boralesgamuwa',
          'Colombo',
          'Galla',
          'Gampaha',
          'Hambantota',
          'Kalatura',
          'Kegalla',
          'Kilinochchi',
          'Kurunegala',
          'Madakalpuwa',
          'Maha Nuwara',
          'Malwana',
          'Mannarama',
          'Matale',
          'Matara',
          'Monaragala',
          'Mullaitivu',
          'North Eastern Province',
          'North Western Province',
          'Nuwara Eliya',
          'Polonnaruwa',
          'Puttalama',
          'Ratnapuraya',
          'Southern Province',
          'Tirikunamalaya',
          'Tuscany',
          'Vavuniyawa',
          'Western Province',
          'Yapanaya',
          'kadawatha'
      ]
  },
  {
      'id': 209,
      'name': 'Sudan',
      'iso3': 'SDN',
      'iso2': 'SD',
      'country_code': '736',
      'phone_code': '249',
      'capital': 'Khartoum',
      'currency': 'SDG',
      'states': [
          'A\'ali-an-Nil',
          'Bahr-al-Jabal',
          'Central Equatoria',
          'Gharb Bahr-al-Ghazal',
          'Gharb Darfur',
          'Gharb Kurdufan',
          'Gharb-al-Istiwa\'iyah',
          'Janub Darfur',
          'Janub Kurdufan',
          'Junqali',
          'Kassala',
          'Nahr-an-Nil',
          'Shamal Bahr-al-Ghazal',
          'Shamal Darfur',
          'Shamal Kurdufan',
          'Sharq-al-Istiwa\'iyah',
          'Sinnar',
          'Warab',
          'Wilayat al Khartum',
          'al-Bahr-al-Ahmar',
          'al-Buhayrat',
          'al-Jazirah',
          'al-Khartum',
          'al-Qadarif',
          'al-Wahdah',
          'an-Nil-al-Abyad',
          'an-Nil-al-Azraq',
          'ash-Shamaliyah'
      ]
  },
  {
      'id': 210,
      'name': 'Suriname',
      'iso3': 'SUR',
      'iso2': 'SR',
      'country_code': '740',
      'phone_code': '597',
      'capital': 'Paramaribo',
      'currency': 'SRD',
      'states': [
          'Brokopondo',
          'Commewijne',
          'Coronie',
          'Marowijne',
          'Nickerie',
          'Para',
          'Paramaribo',
          'Saramacca',
          'Wanica'
      ]
  },
  {
      'id': 211,
      'name': 'Svalbard And Jan Mayen Islands',
      'iso3': 'SJM',
      'iso2': 'SJ',
      'country_code': '744',
      'phone_code': '47',
      'capital': 'Longyearbyen',
      'currency': 'NOK',
      'states': [
          'Svalbard'
      ]
  },
  {
      'id': 212,
      'name': 'Swaziland',
      'iso3': 'SWZ',
      'iso2': 'SZ',
      'country_code': '748',
      'phone_code': '268',
      'capital': 'Mbabane',
      'currency': 'SZL',
      'states': [
          'Hhohho',
          'Lubombo',
          'Manzini',
          'Shiselweni'
      ]
  },
  {
      'id': 213,
      'name': 'Sweden',
      'iso3': 'SWE',
      'iso2': 'SE',
      'country_code': '752',
      'phone_code': '46',
      'capital': 'Stockholm',
      'currency': 'SEK',
      'states': [
          'Alvsborgs Lan',
          'Angermanland',
          'Blekinge',
          'Bohuslan',
          'Dalarna',
          'Gavleborg',
          'Gaza',
          'Gotland',
          'Halland',
          'Jamtland',
          'Jonkoping',
          'Kalmar',
          'Kristianstads',
          'Kronoberg',
          'Norrbotten',
          'Orebro',
          'Ostergotland',
          'Saltsjo-Boo',
          'Skane',
          'Smaland',
          'Sodermanland',
          'Stockholm',
          'Uppsala',
          'Varmland',
          'Vasterbotten',
          'Vastergotland',
          'Vasternorrland',
          'Vastmanland',
          'Vastra Gotaland'
      ]
  },
  {
      'id': 214,
      'name': 'Switzerland',
      'iso3': 'CHE',
      'iso2': 'CH',
      'country_code': '756',
      'phone_code': '41',
      'capital': 'Berne',
      'currency': 'CHF',
      'states': [
          'Aargau',
          'Appenzell Inner-Rhoden',
          'Appenzell-Ausser Rhoden',
          'Basel-Landschaft',
          'Basel-Stadt',
          'Bern',
          'Canton Ticino',
          'Fribourg',
          'Geneve',
          'Glarus',
          'Graubunden',
          'Heerbrugg',
          'Jura',
          'Kanton Aargau',
          'Luzern',
          'Morbio Inferiore',
          'Muhen',
          'Neuchatel',
          'Nidwalden',
          'Obwalden',
          'Sankt Gallen',
          'Schaffhausen',
          'Schwyz',
          'Solothurn',
          'Thurgau',
          'Ticino',
          'Uri',
          'Valais',
          'Vaud',
          'Vauffelin',
          'Zug',
          'Zurich'
      ]
  },
  {
      'id': 215,
      'name': 'Syria',
      'iso3': 'SYR',
      'iso2': 'SY',
      'country_code': '760',
      'phone_code': '963',
      'capital': 'Damascus',
      'currency': 'SYP',
      'states': [
          'Aleppo',
          'Dar\'a',
          'Dayr-az-Zawr',
          'Dimashq',
          'Halab',
          'Hamah',
          'Hims',
          'Idlib',
          'Madinat Dimashq',
          'Tartus',
          'al-Hasakah',
          'al-Ladhiqiyah',
          'al-Qunaytirah',
          'ar-Raqqah',
          'as-Suwayda'
      ]
  },
  {
      'id': 216,
      'name': 'Taiwan',
      'iso3': 'TWN',
      'iso2': 'TW',
      'country_code': '158',
      'phone_code': '886',
      'capital': 'Taipei',
      'currency': 'TWD',
      'states': [
          'Changhwa',
          'Chiayi Hsien',
          'Chiayi Shih',
          'Eastern Taipei',
          'Hsinchu Hsien',
          'Hsinchu Shih',
          'Hualien',
          'Ilan',
          'Kaohsiung Hsien',
          'Kaohsiung Shih',
          'Keelung Shih',
          'Kinmen',
          'Miaoli',
          'Nantou',
          'Northern Taiwan',
          'Penghu',
          'Pingtung',
          'Taichung',
          'Taichung Hsien',
          'Taichung Shih',
          'Tainan Hsien',
          'Tainan Shih',
          'Taipei Hsien',
          'Taipei Shih \/ Taipei Hsien',
          'Taitung',
          'Taoyuan',
          'Yilan',
          'Yun-Lin Hsien',
          'Yunlin'
      ]
  },
  {
      'id': 217,
      'name': 'Tajikistan',
      'iso3': 'TJK',
      'iso2': 'TJ',
      'country_code': '762',
      'phone_code': '992',
      'capital': 'Dushanbe',
      'currency': 'TJS',
      'states': [
          'Dushanbe',
          'Gorno-Badakhshan',
          'Karotegin',
          'Khatlon',
          'Sughd'
      ]
  },
  {
      'id': 218,
      'name': 'Tanzania',
      'iso3': 'TZA',
      'iso2': 'TZ',
      'country_code': '834',
      'phone_code': '255',
      'capital': 'Dodoma',
      'currency': 'TZS',
      'states': [
          'Arusha',
          'Dar es Salaam',
          'Dodoma',
          'Iringa',
          'Kagera',
          'Kigoma',
          'Kilimanjaro',
          'Lindi',
          'Mara',
          'Mbeya',
          'Morogoro',
          'Mtwara',
          'Mwanza',
          'Pwani',
          'Rukwa',
          'Ruvuma',
          'Shinyanga',
          'Singida',
          'Tabora',
          'Tanga',
          'Zanzibar and Pemba'
      ]
  },
  {
      'id': 219,
      'name': 'Thailand',
      'iso3': 'THA',
      'iso2': 'TH',
      'country_code': '764',
      'phone_code': '66',
      'capital': 'Bangkok',
      'currency': 'THB',
      'states': [
          'Amnat Charoen',
          'Ang Thong',
          'Bangkok',
          'Buri Ram',
          'Chachoengsao',
          'Chai Nat',
          'Chaiyaphum',
          'Changwat Chaiyaphum',
          'Chanthaburi',
          'Chiang Mai',
          'Chiang Rai',
          'Chon Buri',
          'Chumphon',
          'Kalasin',
          'Kamphaeng Phet',
          'Kanchanaburi',
          'Khon Kaen',
          'Krabi',
          'Krung Thep',
          'Lampang',
          'Lamphun',
          'Loei',
          'Lop Buri',
          'Mae Hong Son',
          'Maha Sarakham',
          'Mukdahan',
          'Nakhon Nayok',
          'Nakhon Pathom',
          'Nakhon Phanom',
          'Nakhon Ratchasima',
          'Nakhon Sawan',
          'Nakhon Si Thammarat',
          'Nan',
          'Narathiwat',
          'Nong Bua Lam Phu',
          'Nong Khai',
          'Nonthaburi',
          'Pathum Thani',
          'Pattani',
          'Phangnga',
          'Phatthalung',
          'Phayao',
          'Phetchabun',
          'Phetchaburi',
          'Phichit',
          'Phitsanulok',
          'Phra Nakhon Si Ayutthaya',
          'Phrae',
          'Phuket',
          'Prachin Buri',
          'Prachuap Khiri Khan',
          'Ranong',
          'Ratchaburi',
          'Rayong',
          'Roi Et',
          'Sa Kaeo',
          'Sakon Nakhon',
          'Samut Prakan',
          'Samut Sakhon',
          'Samut Songkhran',
          'Saraburi',
          'Satun',
          'Si Sa Ket',
          'Sing Buri',
          'Songkhla',
          'Sukhothai',
          'Suphan Buri',
          'Surat Thani',
          'Surin',
          'Tak',
          'Trang',
          'Trat',
          'Ubon Ratchathani',
          'Udon Thani',
          'Uthai Thani',
          'Uttaradit',
          'Yala',
          'Yasothon'
      ]
  },
  {
      'id': 220,
      'name': 'Togo',
      'iso3': 'TGO',
      'iso2': 'TG',
      'country_code': '768',
      'phone_code': '228',
      'capital': 'Lome',
      'currency': 'XOF',
      'states': [
          'Centre',
          'Kara',
          'Maritime',
          'Plateaux',
          'Savanes'
      ]
  },
  {
      'id': 221,
      'name': 'Tokelau',
      'iso3': 'TKL',
      'iso2': 'TK',
      'country_code': '772',
      'phone_code': '690',
      'capital': '',
      'currency': 'NZD',
      'states': [
          'Atafu',
          'Fakaofo',
          'Nukunonu'
      ]
  },
  {
      'id': 222,
      'name': 'Tonga',
      'iso3': 'TON',
      'iso2': 'TO',
      'country_code': '776',
      'phone_code': '676',
      'capital': 'Nuku\'alofa',
      'currency': 'TOP',
      'states': [
          'Eua',
          'Ha\'apai',
          'Niuas',
          'Tongatapu',
          'Vava\'u'
      ]
  },
  {
      'id': 223,
      'name': 'Trinidad And Tobago',
      'iso3': 'TTO',
      'iso2': 'TT',
      'country_code': '780',
      'phone_code': '+1-868',
      'capital': 'Port of Spain',
      'currency': 'TTD',
      'states': [
          'Arima-Tunapuna-Piarco',
          'Caroni',
          'Chaguanas',
          'Couva-Tabaquite-Talparo',
          'Diego Martin',
          'Glencoe',
          'Penal Debe',
          'Point Fortin',
          'Port of Spain',
          'Princes Town',
          'Saint George',
          'San Fernando',
          'San Juan',
          'Sangre Grande',
          'Siparia',
          'Tobago'
      ]
  },
  {
      'id': 224,
      'name': 'Tunisia',
      'iso3': 'TUN',
      'iso2': 'TN',
      'country_code': '788',
      'phone_code': '216',
      'capital': 'Tunis',
      'currency': 'TND',
      'states': [
          'Aryanah',
          'Bajah',
          'Bin \'Arus',
          'Binzart',
          'Gouvernorat de Ariana',
          'Gouvernorat de Nabeul',
          'Gouvernorat de Sousse',
          'Hammamet Yasmine',
          'Jundubah',
          'Madaniyin',
          'Manubah',
          'Monastir',
          'Nabul',
          'Qabis',
          'Qafsah',
          'Qibili',
          'Safaqis',
          'Sfax',
          'Sidi Bu Zayd',
          'Silyanah',
          'Susah',
          'Tatawin',
          'Tawzar',
          'Tunis',
          'Zaghwan',
          'al-Kaf',
          'al-Mahdiyah',
          'al-Munastir',
          'al-Qasrayn',
          'al-Qayrawan'
      ]
  },
  {
      'id': 225,
      'name': 'Turkey',
      'iso3': 'TUR',
      'iso2': 'TR',
      'country_code': '792',
      'phone_code': '90',
      'capital': 'Ankara',
      'currency': 'TRY',
      'states': [
          'Adana',
          'Adiyaman',
          'Afyon',
          'Agri',
          'Aksaray',
          'Amasya',
          'Ankara',
          'Antalya',
          'Ardahan',
          'Artvin',
          'Aydin',
          'Balikesir',
          'Bartin',
          'Batman',
          'Bayburt',
          'Bilecik',
          'Bingol',
          'Bitlis',
          'Bolu',
          'Burdur',
          'Bursa',
          'Canakkale',
          'Cankiri',
          'Corum',
          'Denizli',
          'Diyarbakir',
          'Duzce',
          'Edirne',
          'Elazig',
          'Erzincan',
          'Erzurum',
          'Eskisehir',
          'Gaziantep',
          'Giresun',
          'Gumushane',
          'Hakkari',
          'Hatay',
          'Icel',
          'Igdir',
          'Isparta',
          'Istanbul',
          'Izmir',
          'Kahramanmaras',
          'Karabuk',
          'Karaman',
          'Kars',
          'Karsiyaka',
          'Kastamonu',
          'Kayseri',
          'Kilis',
          'Kirikkale',
          'Kirklareli',
          'Kirsehir',
          'Kocaeli',
          'Konya',
          'Kutahya',
          'Lefkosa',
          'Malatya',
          'Manisa',
          'Mardin',
          'Mugla',
          'Mus',
          'Nevsehir',
          'Nigde',
          'Ordu',
          'Osmaniye',
          'Rize',
          'Sakarya',
          'Samsun',
          'Sanliurfa',
          'Siirt',
          'Sinop',
          'Sirnak',
          'Sivas',
          'Tekirdag',
          'Tokat',
          'Trabzon',
          'Tunceli',
          'Usak',
          'Van',
          'Yalova',
          'Yozgat',
          'Zonguldak'
      ]
  },
  {
      'id': 226,
      'name': 'Turkmenistan',
      'iso3': 'TKM',
      'iso2': 'TM',
      'country_code': '795',
      'phone_code': '993',
      'capital': 'Ashgabat',
      'currency': 'TMT',
      'states': [
          'Ahal',
          'Asgabat',
          'Balkan',
          'Dasoguz',
          'Lebap',
          'Mari'
      ]
  },
  {
      'id': 227,
      'name': 'Turks And Caicos Islands',
      'iso3': 'TCA',
      'iso2': 'TC',
      'country_code': '796',
      'phone_code': '+1-649',
      'capital': 'Cockburn Town',
      'currency': 'USD',
      'states': [
          'Grand Turk',
          'South Caicos and East Caicos'
      ]
  },
  {
      'id': 228,
      'name': 'Tuvalu',
      'iso3': 'TUV',
      'iso2': 'TV',
      'country_code': '798',
      'phone_code': '688',
      'capital': 'Funafuti',
      'currency': 'AUD',
      'states': [
          'Funafuti',
          'Nanumanga',
          'Nanumea',
          'Niutao',
          'Nui',
          'Nukufetau',
          'Nukulaelae',
          'Vaitupu'
      ]
  },
  {
      'id': 229,
      'name': 'Uganda',
      'iso3': 'UGA',
      'iso2': 'UG',
      'country_code': '800',
      'phone_code': '256',
      'capital': 'Kampala',
      'currency': 'UGX',
      'states': [
          'Central',
          'Eastern',
          'Northern',
          'Western'
      ]
  },
  {
      'id': 230,
      'name': 'Ukraine',
      'iso3': 'UKR',
      'iso2': 'UA',
      'country_code': '804',
      'phone_code': '380',
      'capital': 'Kiev',
      'currency': 'UAH',
      'states': [
          'Cherkas\'ka',
          'Chernihivs\'ka',
          'Chernivets\'ka',
          'Crimea',
          'Dnipropetrovska',
          'Donets\'ka',
          'Ivano-Frankivs\'ka',
          'Kharkiv',
          'Kharkov',
          'Khersonska',
          'Khmel\'nyts\'ka',
          'Kirovohrad',
          'Krym',
          'Kyyiv',
          'Kyyivs\'ka',
          'L\'vivs\'ka',
          'Luhans\'ka',
          'Mykolayivs\'ka',
          'Odes\'ka',
          'Odessa',
          'Poltavs\'ka',
          'Rivnens\'ka',
          'Sevastopol\'',
          'Sums\'ka',
          'Ternopil\'s\'ka',
          'Volyns\'ka',
          'Vynnyts\'ka',
          'Zakarpats\'ka',
          'Zaporizhia',
          'Zhytomyrs\'ka'
      ]
  },
  {
      'id': 231,
      'name': 'United Arab Emirates',
      'iso3': 'ARE',
      'iso2': 'AE',
      'country_code': '784',
      'phone_code': '971',
      'capital': 'Abu Dhabi',
      'currency': 'AED',
      'states': [
          'Abu Zabi',
          'Ajman',
          'Dubai',
          'Ras al-Khaymah',
          'Sharjah',
          'Sharjha',
          'Umm al Qaywayn',
          'al-Fujayrah',
          'ash-Shariqah'
      ]
  },
  {
      'id': 232,
      'name': 'United Kingdom',
      'iso3': 'GBR',
      'iso2': 'GB',
      'country_code': '826',
      'phone_code': '44',
      'capital': 'London',
      'currency': 'GBP',
      'states': [
          'Aberdeen',
          'Aberdeenshire',
          'Argyll',
          'Armagh',
          'Bedfordshire',
          'Belfast',
          'Berkshire',
          'Birmingham',
          'Brechin',
          'Bridgnorth',
          'Bristol',
          'Buckinghamshire',
          'Cambridge',
          'Cambridgeshire',
          'Channel Islands',
          'Cheshire',
          'Cleveland',
          'Co Fermanagh',
          'Conwy',
          'Cornwall',
          'Coventry',
          'Craven Arms',
          'Cumbria',
          'Denbighshire',
          'Derby',
          'Derbyshire',
          'Devon',
          'Dial Code Dungannon',
          'Didcot',
          'Dorset',
          'Dunbartonshire',
          'Durham',
          'East Dunbartonshire',
          'East Lothian',
          'East Midlands',
          'East Sussex',
          'East Yorkshire',
          'England',
          'Essex',
          'Fermanagh',
          'Fife',
          'Flintshire',
          'Fulham',
          'Gainsborough',
          'Glocestershire',
          'Gwent',
          'Hampshire',
          'Hants',
          'Herefordshire',
          'Hertfordshire',
          'Ireland',
          'Isle Of Man',
          'Isle of Wight',
          'Kenford',
          'Kent',
          'Kilmarnock',
          'Lanarkshire',
          'Lancashire',
          'Leicestershire',
          'Lincolnshire',
          'Llanymynech',
          'London',
          'Ludlow',
          'Manchester',
          'Mayfair',
          'Merseyside',
          'Mid Glamorgan',
          'Middlesex',
          'Mildenhall',
          'Monmouthshire',
          'Newton Stewart',
          'Norfolk',
          'North Humberside',
          'North Yorkshire',
          'Northamptonshire',
          'Northants',
          'Northern Ireland',
          'Northumberland',
          'Nottinghamshire',
          'Oxford',
          'Powys',
          'Roos-shire',
          'SUSSEX',
          'Sark',
          'Scotland',
          'Scottish Borders',
          'Shropshire',
          'Somerset',
          'South Glamorgan',
          'South Wales',
          'South Yorkshire',
          'Southwell',
          'Staffordshire',
          'Strabane',
          'Suffolk',
          'Surrey',
          'Sussex',
          'Twickenham',
          'Tyne and Wear',
          'Tyrone',
          'Utah',
          'Wales',
          'Warwickshire',
          'West Lothian',
          'West Midlands',
          'West Sussex',
          'West Yorkshire',
          'Whissendine',
          'Wiltshire',
          'Wokingham',
          'Worcestershire',
          'Wrexham',
          'Wurttemberg',
          'Yorkshire'
      ]
  },
  {
      'id': 233,
      'name': 'United States',
      'iso3': 'USA',
      'iso2': 'US',
      'country_code': '840',
      'phone_code': '1',
      'capital': 'Washington',
      'currency': 'USD',
      'states': [
          'Alabama',
          'Alaska',
          'Arizona',
          'Arkansas',
          'Byram',
          'California',
          'Cokato',
          'Colorado',
          'Connecticut',
          'Delaware',
          'District of Columbia',
          'Florida',
          'Georgia',
          'Hawaii',
          'Idaho',
          'Illinois',
          'Indiana',
          'Iowa',
          'Kansas',
          'Kentucky',
          'Louisiana',
          'Lowa',
          'Maine',
          'Maryland',
          'Massachusetts',
          'Medfield',
          'Michigan',
          'Minnesota',
          'Mississippi',
          'Missouri',
          'Montana',
          'Nebraska',
          'Nevada',
          'New Hampshire',
          'New Jersey',
          'New Jersy',
          'New Mexico',
          'New York',
          'North Carolina',
          'North Dakota',
          'Ohio',
          'Oklahoma',
          'Ontario',
          'Oregon',
          'Pennsylvania',
          'Ramey',
          'Rhode Island',
          'South Carolina',
          'South Dakota',
          'Sublimity',
          'Tennessee',
          'Texas',
          'Trimble',
          'Utah',
          'Vermont',
          'Virginia',
          'Washington',
          'West Virginia',
          'Wisconsin',
          'Wyoming'
      ]
  },
  {
      'id': 234,
      'name': 'United States Minor Outlying Islands',
      'iso3': 'UMI',
      'iso2': 'UM',
      'country_code': '581',
      'phone_code': '1',
      'capital': '',
      'currency': 'USD',
      'states': [
          'United States Minor Outlying I'
      ]
  },
  {
      'id': 235,
      'name': 'Uruguay',
      'iso3': 'URY',
      'iso2': 'UY',
      'country_code': '858',
      'phone_code': '598',
      'capital': 'Montevideo',
      'currency': 'UYU',
      'states': [
          'Artigas',
          'Canelones',
          'Cerro Largo',
          'Colonia',
          'Durazno',
          'FLorida',
          'Flores',
          'Lavalleja',
          'Maldonado',
          'Montevideo',
          'Paysandu',
          'Rio Negro',
          'Rivera',
          'Rocha',
          'Salto',
          'San Jose',
          'Soriano',
          'Tacuarembo',
          'Treinta y Tres'
      ]
  },
  {
      'id': 236,
      'name': 'Uzbekistan',
      'iso3': 'UZB',
      'iso2': 'UZ',
      'country_code': '860',
      'phone_code': '998',
      'capital': 'Tashkent',
      'currency': 'UZS',
      'states': [
          'Andijon',
          'Buhoro',
          'Buxoro Viloyati',
          'Cizah',
          'Fargona',
          'Horazm',
          'Kaskadar',
          'Korakalpogiston',
          'Namangan',
          'Navoi',
          'Samarkand',
          'Sirdare',
          'Surhondar',
          'Toskent'
      ]
  },
  {
      'id': 237,
      'name': 'Vanuatu',
      'iso3': 'VUT',
      'iso2': 'VU',
      'country_code': '548',
      'phone_code': '678',
      'capital': 'Port Vila',
      'currency': 'VUV',
      'states': [
          'Malampa',
          'Penama',
          'Sanma',
          'Shefa',
          'Tafea',
          'Torba'
      ]
  },
  {
      'id': 238,
      'name': 'Vatican City State (Holy See)',
      'iso3': 'VAT',
      'iso2': 'VA',
      'country_code': '336',
      'phone_code': '379',
      'capital': 'Vatican City',
      'currency': 'EUR',
      'states': [
          'Vatican City State (Holy See)'
      ]
  },
  {
      'id': 239,
      'name': 'Venezuela',
      'iso3': 'VEN',
      'iso2': 'VE',
      'country_code': '862',
      'phone_code': '58',
      'capital': 'Caracas',
      'currency': 'VEF',
      'states': [
          'Amazonas',
          'Anzoategui',
          'Apure',
          'Aragua',
          'Barinas',
          'Bolivar',
          'Carabobo',
          'Cojedes',
          'Delta Amacuro',
          'Distrito Federal',
          'Falcon',
          'Guarico',
          'Lara',
          'Merida',
          'Miranda',
          'Monagas',
          'Nueva Esparta',
          'Portuguesa',
          'Sucre',
          'Tachira',
          'Trujillo',
          'Vargas',
          'Yaracuy',
          'Zulia'
      ]
  },
  {
      'id': 240,
      'name': 'Vietnam',
      'iso3': 'VNM',
      'iso2': 'VN',
      'country_code': '704',
      'phone_code': '84',
      'capital': 'Hanoi',
      'currency': 'VND',
      'states': [
          'Bac Giang',
          'Binh Dinh',
          'Binh Duong',
          'Da Nang',
          'Dong Bang Song Cuu Long',
          'Dong Bang Song Hong',
          'Dong Nai',
          'Dong Nam Bo',
          'Duyen Hai Mien Trung',
          'Hanoi',
          'Hung Yen',
          'Khu Bon Cu',
          'Long An',
          'Mien Nui Va Trung Du',
          'Thai Nguyen',
          'Thanh Pho Ho Chi Minh',
          'Thu Do Ha Noi',
          'Tinh Can Tho',
          'Tinh Da Nang',
          'Tinh Gia Lai'
      ]
  },
  {
      'id': 241,
      'name': 'Virgin Islands (British)',
      'iso3': 'VGB',
      'iso2': 'VG',
      'country_code': '92',
      'phone_code': '+1-284',
      'capital': 'Road Town',
      'currency': 'USD',
      'states': [
          'Anegada',
          'Jost van Dyke',
          'Tortola'
      ]
  },
  {
      'id': 242,
      'name': 'Virgin Islands (US)',
      'iso3': 'VIR',
      'iso2': 'VI',
      'country_code': '850',
      'phone_code': '+1-340',
      'capital': 'Charlotte Amalie',
      'currency': 'USD',
      'states': [
          'Saint Croix',
          'Saint John',
          'Saint Thomas'
      ]
  },
  {
      'id': 243,
      'name': 'Wallis And Futuna Islands',
      'iso3': 'WLF',
      'iso2': 'WF',
      'country_code': '876',
      'phone_code': '681',
      'capital': 'Mata Utu',
      'currency': 'XPF',
      'states': [
          'Alo',
          'Singave',
          'Wallis'
      ]
  },
  {
      'id': 244,
      'name': 'Western Sahara',
      'iso3': 'ESH',
      'iso2': 'EH',
      'country_code': '732',
      'phone_code': '212',
      'capital': 'El-Aaiun',
      'currency': 'MAD',
      'states': [
          'Bu Jaydur',
          'Wad-adh-Dhahab',
          'al-\'Ayun',
          'as-Samarah'
      ]
  },
  {
      'id': 245,
      'name': 'Yemen',
      'iso3': 'YEM',
      'iso2': 'YE',
      'country_code': '887',
      'phone_code': '967',
      'capital': 'Sanaa',
      'currency': 'YER',
      'states': [
          '\'Adan',
          'Abyan',
          'Dhamar',
          'Hadramaut',
          'Hajjah',
          'Hudaydah',
          'Ibb',
          'Lahij',
          'Ma\'rib',
          'Madinat San\'a',
          'Sa\'dah',
          'Sana',
          'Shabwah',
          'Ta\'izz',
          'al-Bayda',
          'al-Hudaydah',
          'al-Jawf',
          'al-Mahrah',
          'al-Mahwit'
      ]
  },
  {
      'id': 246,
      'name': 'Zambia',
      'iso3': 'ZMB',
      'iso2': 'ZM',
      'country_code': '894',
      'phone_code': '260',
      'capital': 'Lusaka',
      'currency': 'ZMK',
      'states': [
          'Central',
          'Copperbelt',
          'Eastern',
          'Luapala',
          'Lusaka',
          'North-Western',
          'Northern',
          'Southern',
          'Western'
      ]
  },
  {
      'id': 247,
      'name': 'Zimbabwe',
      'iso3': 'ZWE',
      'iso2': 'ZW',
      'country_code': '716',
      'phone_code': '263',
      'capital': 'Harare',
      'currency': 'ZWL',
      'states': [
          'Bulawayo',
          'Harare',
          'Manicaland',
          'Mashonaland Central',
          'Mashonaland East',
          'Mashonaland West',
          'Masvingo',
          'Matabeleland North',
          'Matabeleland South',
          'Midlands'
      ]
  }
]

