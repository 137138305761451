import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function fetchRecaptchaKeys() {
  return getData('googlerecaptchakeys')
}

export function createRecaptchaKey(data) {
  return postData('googlerecaptchakeys', data, { emulateJSON: true })
}

export function updateRecaptchaKey(data) {
  return putData('googlerecaptchakeys/' + data.id, data, { emulateJSON: true })
}

export function deleteRecaptchaKey(id) {
  return deleteData('googlerecaptchakeys/' + id)
}
