import { store } from '../store/store'
import * as actionTypes from '@/store/action-types'

import MainApp from '@/MainApp'

import Dash from '@/components/containers/Dash'
import Auth from '@/components/containers/Auth'

// login related
import Login from '@/views/Login'
import TokenLogin from '@/views/TokenLogin'
import VerifyEmail from '@/views/VerifyEmail'
import VerifyEmailForm from '@/components/authentication/VerifyEmailForm'
import ResetPasswordForm from '@/views/ResetPasswordForm'
import ForgotPassword from '@/components/authentication/ForgotPassword'

// register related
import Register from '@/views/Register'
import RegisterTranslate from '@/views/RegisterTranslate'

// dashboard & onboarding
import Home from '@/views/Home'
import Dashboard from '@/views/Dashboard'
import IndustryOnBoard from '@/components/authentication/IndustryOnboard'

// form related
import FormCreate from '@/views/FormCreate'
import FormVariantCreate from '@/views/FormVariantCreate'
import FormEmbed from '@/components/FormEmbed'
import FormSettings from '@/components/FormSettings'
import FormLeads from '@/components/FormLeads'
import AnswerBased from '@/components/form-builder/Elements/AnswerBased'
import VisitorBased from '@/components/form-builder/Elements/VisitorBased'

import FormsListView from '@/views/FormsListView'
import FormOverview from '@/views/FormOverview'
import FormDetail from '@/views/FormDetail'
import FormTrackingEvents from '@/views/FormDetail/FormTrackingEvents'
import FormVariantEdit from '@/views/FormVariantEdit'
import FormExperimentsOverview from '@/views/FormExperimentsOverview'
import FormExperimentDetail from '@/views/FormExperimentDetail'
import FormShare from '@/views/FormShare'

/** Form integrations */
import ConnectionsList from '@/views/ConnectionsList'
import FormConnectionsTab from '@/views/FormConnectionsTab'
// import FormConnections from '@/views/FormConnections'
import FormConnections from '../components/FormConnections'
import FormCreateConnection from '@/views/FormCreateConnection'
import FormEditConnection from '@/views/FormEditConnection'

/* User Related */
import UserList from '@/views/UserList'
import UserEdit from '@/views/UserEdit'
import UserProfile from '@/views/UserProfile'

// import LandingPageCreate from '@/views/landingpage/LandingPageCreate'
// import LandingPageEdit from '@/views/landingpage/LandingPageEdit'
// import LandingPageList from '@/views/landingpage/LandingPageList'
// import LandingPageView from '@/views/landingpage/LandingPageView'

/* Plans & billing */
import Subscription from '@/views/Subscription'
import Billing from '@/views/Billing'

import chatPage from '@/views/chatPage'
import PageNotFound from '@/views/PageNotFound'
import Help from '@/views/Help'

import Settings from '@/views/Settings'
import PartialLeads from '@/views/PartialLeads'

import BlacklistIps from '@/views/BlacklistIps'
import GoogleRecaptchaKeysList from '@/views/GoogleRecaptchaKeysList'

/* Lead Proof */
import LeadProofManage from '@/components/LeadProofManage'
import LeadProofList from '@/views/LeadProofList'

/* External Checkout */
import ExternalCheckoutManage from '@/components/ExternalCheckoutManage'
import ExternalCheckoutList from '@/views/ExternalCheckoutList'

/* Template */
import FormCreateOption from '@/views/FormCreateOption'
import FormBrowseTemplate from '@/views/FormBrowseTemplate'
import FormTemplateList from '@/views/FormTemplateList'
import FormTemplateBuilder from '@/components/form-template-build/FormTemplateBuilder'

/* User settings */
import UserSettings from '@/views/UserSettings'

/* Package Builder */
import PackageBuilderList from '@/views/PackageBuilderList'
import PackageBuilder from '@/components/PackageBuilder'

// import FormReport from '@/views/FormReport'

/* UTM Params */
import UtmParamsManager from '@/components/UtmParamsManager'
import UtmParams from '@/views/UtmParamsList'

import { createRouter, createWebHistory } from 'vue-router'

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: MainApp,
      children: [
        // frontend
        {
          path: "/",
          component: Auth,
          children: [
            {
              path: "",
              name: "home",
              component: Home,
              meta: { showWhenLoggedIn: true },
            },
          ],
        },
        // login related
        {
          path: "/",
          component: Auth,
          children: [
            {
              path: "login",
              name: "login",
              component: Login,
              meta: {
                showWhenLoggedIn: false,
                title: "Log In – LeadGen App",
                description:
                  "Click here to log in to your LeadGen App account and access your online forms and leads.",
              },
            },
            {
              path: "verify-email-form",
              name: "verifyEmailForm",
              component: VerifyEmailForm,
              meta: { showWhenLoggedIn: false },
            },
            {
              path: "verify-email/:userId/:token",
              name: "verifyEmail",
              component: VerifyEmail,
              meta: { showWhenLoggedIn: true },
            },
            {
              path: "forgot-password-form",
              name: "forgot-password-form",
              component: ForgotPassword,
              meta: {
                showWhenLoggedIn: false,
                title: "Forgot Password – LeadGen App",
              },
            },
            {
              path: "reset-password-form/:userId/:token",
              name: "resetPasswordForm",
              component: ResetPasswordForm,
              meta: { showWhenLoggedIn: true },
            },
            {
              path: "token/login",
              name: "tokenLogin",
              component: TokenLogin,
              meta: {
                showWhenLoggedIn: true,
                title: "Log In – LeadGen App",
                description:
                  "Log In Here To Your LeadGen App Account – The Fastest Way To Create Lead Capture And Multi-step Forms For Marketers.",
              },
            },
          ],
        },
        // register related
        {
          path: "",
          component: Register,
          meta: {
            requiresAuth: false,
            showWhenLoggedIn: false,
            title: "Create Your Free Account – LeadGen App",
            description:
              "Get Started With LeadGen App To Increase Your Lead Capture Conversions With Better Online Forms. Sign Up Here For A Free Account To Test It Out Yourself.",
          },
          children: [
            {
              path: "register",
              name: "register",
              meta: {
                title: "Create Free Trial Account – LeadGen App",
                description:
                  "Create and capture more leads effortlessly with our user-friendly online forms. Sign up for a free trial today and see the difference",
              },
            },
            {
              path: "register-free-trial",
              name: "registerFreeTrial",
              meta: {
                title: "Create Free Trial Account – LeadGen App",
                description:
                  "Create and capture more leads effortlessly with our user-friendly online forms. Sign up for a free trial today and see the difference",
              },
            },
            {
              path: "register-pro-trial",
              name: "registerProMonthlyTrial",
            },
            {
              path: "register-pro-trial-annual",
              name: "registerProAnnualTrial",
            },
            {
              path: "register-pro",
              name: "registerProMonthly",
            },
            {
              path: "register-pro-annual",
              name: "registerProAnnual",
              meta: {
                title: "Pro Annual Plan – LeadGen App",
                description:
                  "Unlock advanced features with LeadGen App Pro Annual. Boost lead generation and campaign performance with our plan.",
              },
            },
            {
              path: "register-scale-trial",
              name: "registerScaleMonthlyTrial",
            },
            {
              path: "register-scale-trial-annual",
              name: "registerScaleAnnualTrial",
            },
            {
              path: "register-scale",
              name: "registerScaleMonthly",
              meta: {
                title: "Scale Plan – LeadGen App",
                description:
                  "Scale your business with LeadGen App. Efficiently manage leads, optimize campaigns, and drive growth with advanced features",
              },
            },
            {
              path: "register-scale-annual",
              name: "registerScaleAnnual",
              meta: {
                title: "Scale Annual Plan – LeadGen App",
                description:
                  "Unlock advanced features with LeadGen App Scale Annual. Boost lead management and campaign performance.",
              },
            },
            {
              path: "register-enterprise-trial",
              name: "registerEnterpriseMonthlyTrial",
            },
            {
              path: "register-enterprise-trial-annual",
              name: "registerEnterpriseAnnualTrial",
              meta: {
                title: "Enterprise Trial Annual Plan – LeadGen App",
                description:
                  "Try LeadGen App Enterprise free for 14 days with no credit card required. Create up to 300 forms, capture unlimited leads, and upgrade anytime.",
              },
            },
            {
              path: "register-enterprise",
              name: "registerEnterpriseMonthly",
              meta: {
                title: "Enterprise Plan – LeadGen App",
                description:
                  "Boost your business with LeadGen App Enterprise’s powerful online forms. Capture more leads and drive growth efficiently.",
              },
            },
            {
              path: "register-enterprise-annual",
              name: "registerEnterpriseAnnual",
              meta: {
                title: "Enterprise Annual Plan – LeadGen App",
                description:
                  "Unlock the power of LeadGen App Enterprise Annual. Get advanced features and support to maximize your lead generation and business growth.",
              },
            },
            {
              path: "register-starter",
              name: "registerStarterMonthly",
            },
            {
              path: "register-single-annual",
              name: "registerSingleAnnual",
            },
          ],
        },
        {
          path: "",
          component: RegisterTranslate,
          children: [
            // Free Trial Routes
            {
              path: "enregistrer-essai-gratuit", // French
              name: "registerFreeTrialFr",
              meta: {
                title: "Créer un Compte d'Essai Gratuit – LeadGen App",
                description:
                  "Créez et capturez plus de leads facilement avec nos formulaires en ligne conviviaux. Inscrivez-vous à l'essai gratuit aujourd'hui et constatez la différence",
              },
            },
            {
              path: "registrar-prueba-gratuita", // Spanish
              name: "registerFreeTrialEs",
              meta: {
                title: "Crear Cuenta de Prueba Gratuita – LeadGen App",
                description:
                  "Crea y captura más leads sin esfuerzo con nuestros formularios en línea fáciles de usar. Regístrate para una prueba gratuita hoy y ve la diferencia",
              },
            },
            {
              path: "registrieren-kostenlose-testversion", // German
              name: "registerFreeTrialDt",
              meta: {
                title: "Kostenloses Testkonto Erstellen – LeadGen App",
                description:
                  "Erstellen und erfassen Sie mühelos mehr Leads mit unseren benutzerfreundlichen Online-Formularen. Melden Sie sich noch heute für eine kostenlose Testversion an",
              },
            },
            {
              path: "registro-teste-gratuito", // Portuguese
              name: "registerFreeTrialPt",
              meta: {
                title: "Criar Conta de Teste Gratuita – LeadGen App",
                description:
                  "Crie e capture mais leads facilmente com nossos formulários online intuitivos. Cadastre-se para um teste gratuito hoje e veja a diferença",
              },
            },

            // Pro Monthly Routes
            {
              path: "enregistrer-pro", // French
              name: "registerProMonthlyFr",
            },
            {
              path: "registrar-pro", // Spanish
              name: "registerProMonthlyEs",
            },
            {
              path: "registrieren-pro", // German
              name: "registerProMonthlyDt",
            },
            {
              path: "registro-pro", // Portuguese
              name: "registerProMonthlyPt",
            },

            // Pro Annual Routes
            {
              path: "enregistrer-pro-annuel", // French
              name: "registerProAnnualFr",
              meta: {
                title: "Plan Pro Annuel – LeadGen App",
                description:
                  "Débloquez des fonctionnalités avancées avec LeadGen App Pro Annuel. Améliorez la génération de leads et la performance de vos campagnes.",
              },
            },
            {
              path: "registrar-pro-anual", // Spanish
              name: "registerProAnnualEs",
              meta: {
                title: "Plan Pro Anual – LeadGen App",
                description:
                  "Desbloquea funciones avanzadas con LeadGen App Pro Anual. Impulsa la generación de leads y el rendimiento de tus campañas.",
              },
            },
            {
              path: "registrieren-pro-jahrlich", // German
              name: "registerProAnnualDt",
              meta: {
                title: "Pro Jahresplan – LeadGen App",
                description:
                  "Schalten Sie erweiterte Funktionen mit LeadGen App Pro Jahresplan frei. Steigern Sie Ihre Lead-Generierung und Kampagnenleistung.",
              },
            },
            {
              path: "registro-pro-anual", // Portuguese
              name: "registerProAnnualPt",
              meta: {
                title: "Plano Pro Anual – LeadGen App",
                description:
                  "Desbloqueie recursos avançados com LeadGen App Pro Anual. Impulsione a geração de leads e o desempenho das campanhas.",
              },
            },

            // Scale Monthly Routes
            {
              path: "enregistrer-scale", // French
              name: "registerScaleMonthlyFr",
              meta: {
                title: "Plan Scale – LeadGen App",
                description:
                  "Développez votre entreprise avec LeadGen App. Gérez efficacement les leads, optimisez les campagnes et stimulez la croissance.",
              },
            },
            {
              path: "registrar-escala-mensual", // Spanish
              name: "registerScaleMonthlyEs",
              meta: {
                title: "Plan Escala – LeadGen App",
                description:
                  "Escala tu negocio con LeadGen App. Gestiona leads eficientemente, optimiza campañas e impulsa el crecimiento.",
              },
            },
            {
              path: "registrieren-scale", // German
              name: "registerMonthlyScaleDt",
              meta: {
                title: "Scale Plan – LeadGen App",
                description:
                  "Skalieren Sie Ihr Unternehmen mit LeadGen App. Verwalten Sie Leads effizient, optimieren Sie Kampagnen und steigern Sie das Wachstum.",
              },
            },
            {
              path: "registro-escala-mensal", // Portuguese
              name: "registerScaleMonthlyPt",
              meta: {
                title: "Plano Escala – LeadGen App",
                description:
                  "Escale seu negócio com LeadGen App. Gerencie leads eficientemente, otimize campanhas e impulsione o crescimento.",
              },
            },

            // Scale Annual Routes
            {
              path: "enregistrer-scale-annuel", // French
              name: "registerScaleAnnualFr",
              meta: {
                title: "Plan Scale Annuel – LeadGen App",
                description:
                  "Débloquez des fonctionnalités avancées avec LeadGen App Scale Annuel. Optimisez la gestion des leads et la performance des campagnes.",
              },
            },
            {
              path: "registrar-escala-anual", // Spanish
              name: "registerScaleAnnualEs",
              meta: {
                title: "Plan Escala Anual – LeadGen App",
                description:
                  "Desbloquea funciones avanzadas con LeadGen App Escala Anual. Mejora la gestión de leads y el rendimiento de campañas.",
              },
            },
            {
              path: "registrieren-scale-jahrlich", // German
              name: "registerScaleAnnualDt",
              meta: {
                title: "Scale Jahresplan – LeadGen App",
                description:
                  "Schalten Sie erweiterte Funktionen mit LeadGen App Scale Jahresplan frei. Verbessern Sie Ihr Lead-Management und Ihre Kampagnenleistung.",
              },
            },
            {
              path: "registro-escala-anual", // Portuguese
              name: "registerScaleAnnualPt",
              meta: {
                title: "Plano Escala Anual – LeadGen App",
                description:
                  "Desbloqueie recursos avançados com LeadGen App Escala Anual. Melhore o gerenciamento de leads e o desempenho das campanhas.",
              },
            },

            // Enterprise Monthly Routes
            {
              path: "enregistrer-entreprise", // French
              name: "registerEnterpriseMonthlyFr",
              meta: {
                title: "Plan Entreprise – LeadGen App",
                description:
                  "Développez votre entreprise avec les formulaires puissants de LeadGen App Enterprise. Capturez plus de leads et stimulez la croissance efficacement.",
              },
            },
            {
              path: "registrar-empresa-mensual", // Spanish
              name: "registerEnterpriseMonthlyEs",
              meta: {
                title: "Plan Empresa – LeadGen App",
                description:
                  "Impulsa tu negocio con los potentes formularios de LeadGen App Enterprise. Captura más leads y genera crecimiento eficientemente.",
              },
            },
            {
              path: "registrieren-entreprise", // German
              name: "registerEnterpriseMonthlyDt",
              meta: {
                title: "Enterprise Plan – LeadGen App",
                description:
                  "Stärken Sie Ihr Unternehmen mit den leistungsstarken Online-Formularen von LeadGen App Enterprise. Gewinnen Sie mehr Leads und steigern Sie das Wachstum.",
              },
            },
            {
              path: "registro-empresa-mensal", // Portuguese
              name: "registerEnterpriseMonthlyPt",
              meta: {
                title: "Plano Empresa – LeadGen App",
                description:
                  "Impulsione seu negócio com os poderosos formulários do LeadGen App Enterprise. Capture mais leads e gere crescimento eficientemente.",
              },
            },

            // Enterprise Annual Routes
            {
              path: "enregistrer-entreprise-annuel", // French
              name: "registerEnterpriseAnnualFr",
              meta: {
                title: "Plan Entreprise Annuel – LeadGen App",
                description:
                  "Libérez la puissance de LeadGen App Enterprise Annuel. Obtenez des fonctionnalités avancées et un support pour maximiser votre génération de leads.",
              },
            },
            {
              path: "registrar-empresa-anual", // Spanish
              name: "registerEnterpriseAnnualEs",
              meta: {
                title: "Plan Empresa Anual – LeadGen App",
                description:
                  "Desbloquea el poder de LeadGen App Enterprise Anual. Obtén funciones avanzadas y soporte para maximizar tu generación de leads.",
              },
            },
            {
              path: "registrieren-entreprise-jahrlich", // German
              name: "registerEnterpriseAnnualDt",
              meta: {
                title: "Enterprise Jahresplan – LeadGen App",
                description:
                  "Nutzen Sie die Leistung von LeadGen App Enterprise Jahresplan. Erhalten Sie erweiterte Funktionen und Support für maximale Lead-Generierung.",
              },
            },
            {
              path: "registro-empresa-anual", // Portuguese
              name: "registerEnterpriseAnnualPt",
              meta: {
                title: "Plano Empresa Anual – LeadGen App",
                description:
                  "Desbloqueie o poder do LeadGen App Enterprise Anual. Obtenha recursos avançados e suporte para maximizar sua geração de leads.",
              },
            },

            // Pro Monthly Trial Routes
            {
              path: "enregistrer-pro-essai", // French
              name: "registerProMonthlyTrialFr",
            },
            {
              path: "registrar-pro-prueba", // Spanish
              name: "registerProMonthlyTrialEs",
            },
            {
              path: "registrieren-pro-testversion", // German
              name: "registerProMonthlyTrialDt",
            },
            {
              path: "registro-pro-teste", // Portuguese
              name: "registerProMonthlyTrialPt",
            },

            // Pro Annual Trial Routes
            {
              path: "enregistrer-pro-annuel-essai", // French
              name: "registerProAnnualTrialFr",
            },
            {
              path: "registrar-pro-anual-prueba", // Spanish
              name: "registerProAnnualTrialEs",
            },
            {
              path: "registrieren-pro-jahrlich-testversion", // German
              name: "registerProAnnualTrialDt",
            },
            {
              path: "registro-pro-anual-teste", // Portuguese
              name: "registerProAnnualTrialPt",
            },

            // Scale Monthly Trial Routes
            {
              path: "enregistrer-scale-essai", // French
              name: "registerScaleMonthlyTrialFr",
            },
            {
              path: "registrar-escala-prueba", // Spanish
              name: "registerScaleMonthlyTrialEs",
            },
            {
              path: "registrieren-scale-testversion", // German
              name: "registerScaleMonthlyTrialDt",
            },
            {
              path: "registro-escala-teste", // Portuguese
              name: "registerScaleMonthlyTrialPt",
            },

            // Scale Annual Trial Routes
            {
              path: "enregistrer-scale-annuel-essai", // French
              name: "registerScaleAnnualTrialFr",
            },
            {
              path: "registrar-escala-anual-prueba", // Spanish
              name: "registerScaleAnnualTrialEs",
            },
            {
              path: "registrieren-scale-jahrlich-testversion", // German
              name: "registerScaleAnnualTrialDt",
            },
            {
              path: "registro-escala-anual-teste", // Portuguese
              name: "registerScaleAnnualTrialPt",
            },

            // Enterprise Monthly Trial Routes
            {
              path: "enregistrer-entreprise-essai", // French
              name: "registerEnterpriseMonthlyTrialFr",
            },
            {
              path: "registrar-empresa-prueba", // Spanish
              name: "registerEnterpriseMonthlyTrialEs",
            },
            {
              path: "registrieren-entreprise-testversion", // German
              name: "registerEnterpriseMonthlyTrialDt",
            },
            {
              path: "registro-empresa-teste", // Portuguese
              name: "registerEnterpriseMonthlyTrialPt",
            },

            // Enterprise Annual Trial Routes
            {
              path: "enregistrer-entreprise-annuel-essai", // French
              name: "registerEnterpriseAnnualTrialFr",
              meta: {
                title: "Plan Entreprise Essai Annuel – LeadGen App",
                description:
                  "Essayez LeadGen App Enterprise gratuitement pendant 14 jours sans carte bancaire. Créez jusqu'à 300 formulaires, capturez des leads illimités.",
              },
            },
            {
              path: "registrar-empresa-anual-prueba", // Spanish
              name: "registerEnterpriseAnnualTrialEs",
              meta: {
                title: "Plan Empresa Prueba Anual – LeadGen App",
                description:
                  "Prueba LeadGen App Enterprise gratis durante 14 días sin tarjeta de crédito. Crea hasta 300 formularios, captura leads ilimitados.",
              },
            },
            {
              path: "registrieren-entreprise-jahrlich-testversion", // German
              name: "registerEnterpriseAnnualTrialDt",
              meta: {
                title: "Enterprise Testversion Jahresplan – LeadGen App",
                description:
                  "Testen Sie LeadGen App Enterprise 14 Tage kostenlos ohne Kreditkarte. Erstellen Sie bis zu 300 Formulare, erfassen Sie unbegrenzt Leads.",
              },
            },
            {
              path: "registro-empresa-anual-teste", // Portuguese
              name: "registerEnterpriseAnnualTrialPt",
              meta: {
                title: "Plano Empresa Teste Anual – LeadGen App",
                description:
                  "Experimente LeadGen App Enterprise gratuitamente por 14 dias sem cartão de crédito. Crie até 300 formulários, capture leads ilimitados.",
              },
            },

            // Starter Monthly Routes
            {
              path: "registre-entree", // French
              name: "registerStarterMonthlyFr",
            },
            {
              path: "registro-arrancadora", // Spanish
              name: "registerStarterMonthlyEs",
            },
            {
              path: "registrieren-starter", // German
              name: "registerStarterMonthlyDe",
            },
            {
              path: "registro-iniciante", // Portuguese
              name: "registerStarterMonthlyPt",
            },
          ],
        },
        // onboarding
        {
          path: "onboard",
          name: "industry-onboard",
          component: IndustryOnBoard,
          meta: {
            showWhenLoggedIn: true,
            title: "Welcome – LeadGen App",
            description:
              "Welcome Here To Your LeadGen App Account – The Fastest Way To Create Lead Capture And Multi-step Forms For Marketers.",
          },
        },
        // dashboard
        {
          path: "/dashboard",
          component: Dash,
          meta: { requiresAuth: true },
          children: [
            {
              path: "",
              name: "dashboard",
              component: Dashboard,
            },
            {
              path: "/users",
              name: "users.index",
              component: UserList,
            },
            {
              path: "/users/:id/edit",
              name: "users.edit",
              component: UserEdit,
            },
            {
              path: "/forms",
              name: "forms.index",
              component: FormsListView,
            },
            {
              path: "/forms/share",
              name: "form.share",
              component: FormShare,
            },
            {
              path: "/forms/:id/detail",
              component: FormDetail,
              children: [
                {
                  path: "connections",
                  component: FormConnectionsTab,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                  children: [
                    {
                      path: "",
                      name: "forms.detail.connectionstab.list",
                      component: FormConnections,
                      props: (route) => ({ formId: parseInt(route.params.id) }),
                    },
                    {
                      path: "create/:connectionType?",
                      name: "forms.detail.connectionstab.create",
                      component: FormCreateConnection,
                      props: (route) => ({ formId: parseInt(route.params.id) }),
                    },
                    {
                      path: "edit/:connectionId/:connectionType",
                      name: "forms.detail.connectionstab.edit",
                      component: FormEditConnection,
                      props: (route) => ({ formId: parseInt(route.params.id) }),
                    },
                  ],
                },
                {
                  path: "",
                  name: "forms.overview",
                  component: FormOverview,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                },
                {
                  path: "/forms/:id/share",
                  name: "forms.share",
                  component: FormEmbed,
                },
                {
                  path: "/forms/:id/settings",
                  name: "forms.settings",
                  component: FormSettings,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                },
                {
                  path: "/forms/:id/leads",
                  name: "forms.leads",
                  component: FormLeads,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                },
                {
                  path: "/forms/:id/tracking-events",
                  name: "forms.trackingEvents",
                  component: FormTrackingEvents,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                },
                {
                  path: "/forms/:id/experiments",
                  name: "forms.experiments.overview",
                  component: FormExperimentsOverview,
                },
                {
                  path: "/forms/:id/experiments/:experimentId",
                  name: "forms.experiments.detail",
                  component: FormExperimentDetail,
                },
              ],
            },
            {
              path: "/googlerecaptchakeys",
              name: "googlerecaptchakeys",
              component: GoogleRecaptchaKeysList,
            },
            {
              path: "/connections-list",
              name: "connections.list",
              component: ConnectionsList,
            },
            {
              path: "/plans",
              name: "plans.index",
              component: Subscription,
            },
            {
              path: "/billing",
              name: "billing.index",
              component: Billing,
            },
            {
              path: "/lead-proofs-list",
              name: "leadproofs.index.list",
              component: LeadProofList,
            },
            {
              path: "/lead-proofs-create",
              name: "leadproofs.create",
              component: LeadProofManage,
            },
            {
              path: "/lead-proofs/:id/edit",
              name: "leadproofs.edit",
              component: LeadProofManage,
            },
            {
              path: "/external-checkout-list",
              name: "externalcheckout.index.list",
              component: ExternalCheckoutList,
            },
            {
              path: "/external-checkout-create",
              name: "externalcheckout.create",
              component: ExternalCheckoutManage,
            },
            {
              path: "/external-checkout/:id/edit",
              name: "externalcheckout.edit",
              component: ExternalCheckoutManage,
            },
            {
              path: "/help",
              name: "help.index",
              component: Help,
            },
            {
              path: "/partial-leads",
              name: "partialleads",
              component: PartialLeads,
            },
            {
              path: "/user-profile",
              name: "user.profile",
              component: UserProfile,
            },
            {
              path: "/settings",
              name: "settings",
              component: Settings,
            },
            {
              path: "/blacklist-ips",
              name: "blacklist.ips",
              component: BlacklistIps,
            },
            {
              path: "/template-builder-list",
              name: "template-builder.index.list",
              component: FormTemplateList,
            },
            {
              path: "/template-builder-create",
              name: "template-builder.create",
              component: FormTemplateBuilder,
            },
            {
              path: "/template-builder/:id/edit",
              name: "template-builder.edit",
              component: FormTemplateBuilder,
            },
            {
              path: "/user-settings",
              name: "user-settings",
              component: UserSettings,
            },
            {
              path: "/package-builder-list",
              name: "package-builder.index.list",
              component: PackageBuilderList,
            },
            {
              path: "/package-builder-create",
              name: "package-builder.create",
              component: PackageBuilder,
            },
            {
              path: "/package-builder/:id/edit",
              name: "package-builder.edit",
              component: PackageBuilder,
            },
            // {
            //   path: '/user-form-report',
            //   name: 'user-form.report',
            //   component: FormReport
            // },
            {
              path: "/utm-params",
              name: "utmparams.index.list",
              component: UtmParams,
            },
            {
              path: "/utm-params-create",
              name: "utmparams.create",
              component: UtmParamsManager,
            },
            {
              path: "/utm-params/:id/edit",
              name: "utmparams.edit",
              component: UtmParamsManager,
            },
          ],
        },
        // chat
        {
          path: "/chat",
          name: "chat",
          component: chatPage,
        },
        // form/variant create/edit
        {
          path: "/forms/create",
          name: "forms.create",
          component: FormCreate,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/create/option",
          name: "forms.create.option",
          component: FormCreateOption,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/choose/template/:industry?",
          name: "forms.choose.template",
          component: FormBrowseTemplate,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/:tab?",
          name: "forms.variants.edit",
          component: FormVariantEdit,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings/answerbased/create",
          name: "forms.variants.answerbased.create",
          component: AnswerBased,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings/answerbased/:endingId/edit",
          name: "forms.variants.answerbased.edit",
          component: AnswerBased,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings/visitorbased/create",
          name: "forms.variants.visitorbased.create",
          component: VisitorBased,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings/visitorbased/:endingId/edit",
          name: "forms.variants.visitorbased.edit",
          component: VisitorBased,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings",
          name: "forms.variants.edit.conditions",
          component: FormVariantEdit,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/create",
          name: "forms.variants.create",
          component: FormVariantCreate,
          meta: { requiresAuth: true },
        },
        // landing page
        // {
        //   path: '/landingpages',
        //   name: 'landingpages.index',
        //   component: LandingPageList,
        // },
        // {
        //   path: '/landingpages/create',
        //   component: LandingPageCreate,
        //   name: 'landingpages.create',
        //   meta: { requiresAuth: true }
        // },
        // {
        //   path: '/landingpages/:id/edit',
        //   component: LandingPageEdit,
        //   name: 'landingpages.edit',
        //   meta: { requiresAuth: true }
        // },
        // {
        //   path: '/lp/:id/:title?',
        //   component: LandingPageView,
        //   name: 'landingpages.show',
        //   meta: { showWhenLoggedIn: true }
        // },
      ],
    },
    // 404
    {
      path: "/:catchAll(.*)",
      component: PageNotFound,
    },
  ],
  linkActiveClass: "active",
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "LeadGen"
  let meta = document.querySelector("meta[name=description]")

  if (meta && to.meta.description) {
    meta.content = to.meta.description
  }

  setTimeout(() => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      console.log("private route")
      // protected routes
      if (!store?.getters?.isLoggedIn) {
        localStorage.setItem(
          "private_url",
          JSON.stringify({ name: to.name, params: to.params })
        )

        if (store.state.token) {
          // auto logout
          store.dispatch(actionTypes.LOGOUT_USER).then((result) => {
            next({
              path: "/login",
            })
          })
        } else {
          next({
            path: "/login",
          })
        }
      } else {
        next()
      }
    } else {
      // public routes
      if (!store?.getters?.isLoggedIn) {
        next()
      } else {
        if (to.matched.some((record) => record.meta.showWhenLoggedIn)) {
          next()
        } else {
          next({
            path: "/dashboard",
          })
        }
      }
    }
  }, 0)
})

export default router
