import { putData, getData } from '../plugins/ApiCalls'

export function changePlan(data) {
  return putData('plans/change', data)
}

export function cancelPlan() {
  return putData('plans/cancel')
}

export function resumePlan() {
  return putData('plans/resume')
}

export function getPlans() {
  return getData('plans')
}

export function planSubscription(planId) {
  return getData('plans/' + planId + '/subscription')
}
