import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
import '@mdi/font/css/materialdesignicons.css';
import { CustomTheme } from './theme'
import { fa } from "vuetify/iconsets/fa";
// make sure to also import the coresponding css
import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  theme: {
    defaultTheme: 'CustomTheme',
    themes: {
      CustomTheme,
    },
  },
})

export default vuetify;
