import * as mutationTypes from '@/store/mutation-types'
import * as UtmParamsApi from '@/api/utm-params'
const state = {
  utmParamsLists: []
}

const getters = {
  getUtmParamsList: function (state) {
    return state.utmParamsLists
  }
}

const actions = {
  getScriptsList ({ state, commit }, data) {
    commit(mutationTypes.FETCH_UTM_PARAMS_LISTS)
    return UtmParamsApi.getScriptsList(data)
  },
  getScript ({ state, commit }, data) {
    return UtmParamsApi.getScript(data.id)
  },
  getScriptSuccess ({ state, commit }, data) {
    commit(mutationTypes.FETCH_UTM_PARAM_SUCCESS, data)
  },

  getScriptListSuccess ({ state, commit }, data) {
    commit(mutationTypes.FETCH_UTM_PARAMS_LISTS_SUCCESS, data)
  },
  createScript ({ state, commit }, data) {
    return UtmParamsApi.createScript(data)
  },
  updateScript ({ state, commit }, data) {
    return UtmParamsApi.updateScript(data.id, data.data)
  },
  createScriptSuccess ({ state, commit }, data) {
    commit(mutationTypes.CREATE_UTM_PARAMS_SUCCESS, data)
  },
  deleteScript ({ state, commit }, data) {
    commit(mutationTypes.DELETE_UTM_PARAM)
    return UtmParamsApi.deleteScript(data)
  },
  deleteScriptSuccess ({ state, commit }, data) {
    commit(mutationTypes.DELETE_UTM_PARAM_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_UTM_PARAMS_LISTS] (state, data) { },
  [mutationTypes.FETCH_UTM_PARAMS_LISTS_SUCCESS] (state, data) {
    state.utmParamsLists = data || []
  },

  [mutationTypes.DELETE_UTM_PARAM] (state, data) { },
  [mutationTypes.DELETE_UTM_PARAM_SUCCESS] (state, data) {
    const index = state.utmParamsLists.findIndex(item => item.id === data.id)
    if (index > -1) {
      state.utmParamsLists.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
