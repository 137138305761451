import * as FormThemeTemplateApi from '@/api/form-theme-template'

const state = {
  templates: []
}

const getters = {}

const actions = {
  publishCustomTemplate (context, data) {
    return FormThemeTemplateApi.publishTemplate(data)
  },
  updateTemplate (context, data) {
    return FormThemeTemplateApi.updateTemplate(data)
  },
  deactivateDefaultTemplate (context, data) {
    return FormThemeTemplateApi.deactivateDefaultTemplate(data)
  },
  activateDefaultTemplate (context, data) {
    return FormThemeTemplateApi.publishTemplate(data)
  },
  deleteTemplate (context, data) {
    return FormThemeTemplateApi.deleteTemplate(data)
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
