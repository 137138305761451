<template>
  <v-col cols="12">
    <h5 class="font-weight-bold text-primary-blue">Enterprise</h5>
    <v-card class="pa-1" color="primary-blue" rounded="md">
      <v-card-text>
        <h5 class="font-weight-normal text-white ml-5">Custom solutions available for enterprises</h5>
        <div class="d-flex justify-space-between">
          <ul class="ml-5">
            <li><v-icon color="success" size="20" class="mr-1">mdi-check-decagram</v-icon>Access to more than 300 forms
            </li>
            <li><v-icon color="success" size="20" class="mr-1">mdi-check-decagram</v-icon>Supporting high lead volume
            </li>
            <li><v-icon color="success" size="20" class="mr-1">mdi-check-decagram</v-icon>Dedicated support manager</li>
          </ul>
          <v-btn color="white" variant="outlined" rounded="md" elevation="5" class="text-none font-weight-bold mr-15 mt-2"
            size="x-large" href="https://leadgenapp.io/book-onboarding-call/" target="_blank">
            Book a Call
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <p class="text-gre-darken-2 mt-5 mb-0 pb-0">Get detailed insight into plans and features on our <a
        href="https://leadgenapp.io/pricing/" target="_blank" class="text-primary-red link">pricing page <v-icon
          size="15">mdi-open-in-new</v-icon></a>
    </p>
  </v-col>
</template>

