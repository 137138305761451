<template>
  <div class="form-detail-tab-view">
    <div v-if="$route.name !== 'forms.experiments.overview' && $route.name !== 'forms.experiments.detail'">
      <v-tabs grow v-model="currentTabId" color="primary-red" height="60" slider-color="primary-red">
        <v-tab v-for="item in tabs" :key="item.id" :value="item.id" color="primary-red"
          class="text-none text-left font-weight-bold">
          <v-icon start>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-tab>
      </v-tabs>
      </div>
      <v-divider v-if="$route.name !== 'forms.experiments.overview' && $route.name !== 'forms.experiments.detail'"></v-divider>
      <div class="mt-3">
        <router-view />
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      currentTabId: '', // Default to an empty string, will be set by the route
      tabs: [
        { id: 'overview-tab', title: 'Overview', icon: 'mdi-view-dashboard', route: 'forms.overview' },
        { id: 'leads-tab', title: 'Leads', icon: 'mdi-list-box', route: 'forms.leads' },
        { id: 'connections-tab', title: 'Integrations', icon: 'mdi-connection', route: 'forms.detail.connectionstab.list' },
        { id: 'settings-tab', title: 'Settings', icon: 'mdi-file-cog', route: 'forms.settings' },
        { id: 'trackingEvents', title: 'Tracking Events', icon: 'mdi-chart-timeline', route: 'forms.trackingEvents' },
      ],
    }
  },
  mounted() {
    this.setTabFromRoute() // Set the tab based on the route when component mounts
  },
  watch: {
    currentTabId(tabId) {
      this.onTabChange(tabId)
    }
  },
  methods: {
    setTabFromRoute() {
      // Determine which tab is active based on the current route name
      const currentRoute = this.$route.name
      const matchedTab = this.tabs.find(tab => tab.route === currentRoute)
      this.currentTabId = matchedTab ? matchedTab.id : 'overview-tab'
    },
    onTabChange(tabId) {
      // Update the route based on the selected tab
      console.log('form-id', this.formId)
      const selectedTab = this.tabs.find(tab => tab.id === tabId)
      if (selectedTab) {
        this.$router.push({ name: selectedTab.route, params: { id: this.formId } })
      } else {
        this.$router.push({ name: 'forms.overview', params: { id: this.formId } })
      }
    },
  },
  computed: {
    ...mapGetters([
      'currentFormId',
      'currentFormKey'
    ]),
    formId: function () {
      return parseInt(this.$route.params.id)
    },
    form: function () {
      let form = this.$store.getters.getForm(this.formId)
      this.formTitle = form.title
      return form
    },
    formKey: function () {
      return this.form.key
    }
  },
}
</script>
