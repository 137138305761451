import { getData, postData } from '../plugins/ApiCalls'

export function getFormTemplates() {
  return getData('form-template-browser/templates')
}

export function getFormTemplateIndustries() {
  return getData('form-template-browser/industries')
}

export function getFormTemplateCategories() {
  return getData('form-template-browser/categories')
}

export function useFormTemplate(data) {
  return postData('form-template-browser/' + data.formId + '/useTemplate', data)
}
