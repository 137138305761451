import * as actionTypes from '@/store/action-types.js'
import {
  paddleMonthlyPlans,
  paddleYearlyPlans,
  paddleMonthlyTrialPlans,
  paddleYearlyTrialPlans,
  freeTrialPlans
} from '@/shared/constants/plan/paddle/plans'
import planPublicIds from '@/shared/constants/plan/paddle/publicIds'
import opType from '@/shared/constants/plan/paddle/action'
import {
  registerRoutePath,
  registerRoutesName
} from '@/shared/constants/plan/paddle/route'
import config from '@/api/config'
import environments from '@/shared/constants/environments'

export default {
  data: function () {
    return {
      pastDue: {
        loaded: false,
        bankUrl: null,
        errorMsg: null
      },
      discount: {
        add: false,
        coupon: null,
        errorMsg: null
      },
      subscriptionStatus: {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        planId: null,
        action: null,
        message: null,
        success: false,
        error: false
      },
      mixin_user: {
        id: null,
        token: null
      }
    }
  },
  methods: {
    async planMixin_loadUpdateUrl() {
      try {
        const response = await this.$store.dispatch(
          'subscription/getPastDueSubscription',
          { id: this.user.plan.subscription_id }
        )
        this.pastDue.loaded = true
        this.pastDue.errorMsg = null
        this.pastDue.bankUrl = response?.data?.data?.updateUrl
      } catch (error) {
        this.pastDue.loaded = false
        this.pastDue.bankUrl = null
        this.pastDue.errorMsg = error?.body?.meta?.error_message
      }
    },
    async planMixin_loadDiscountCoupon() {
      try {
        const response = await this.$store.dispatch(
          'subscription/getDiscountCoupon'
        )
        this.discount.add = true
        this.discount.errorMsg = null
        this.discount.coupon = response?.data?.data?.coupon
      } catch (error) {
        this.discount.add = false
        this.discount.coupon = null
        this.discount.errorMsg = error?.body?.meta?.error_message
      }
    },
    async planMixin_getPlanFeatures(userId) {
      try {
        const response = await this.$store.dispatch(actionTypes.FETCH_USER, {
          userId
        })
        return response?.data?.data
      } catch (error) {
        throw error
      }
    },
    planMixin_getDetails: function (identifier) {
      let planDetails = {}
      planDetails.isMonthlyBilled = false
      planDetails.isTrialPlan = false
      planDetails.isFreeTrial = false
      planDetails.isSinglePlan = false
      planDetails.registerPageRoute = null

      switch (identifier) {
        case registerRoutePath.registerProMonthlyTrial:
        case planPublicIds.paddleProMonthlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialPlanId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProMonthlyTrial,
            paddleMonthlyTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerProMonthlyTrial
          break
        case registerRoutePath.registerProAnnualTrial:
        case planPublicIds.paddleProYearlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialAnnualProId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProYearlyTrial,
            paddleYearlyTrialPlans
          )
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerProAnnualTrial
          break
        case registerRoutePath.registerScaleMonthlyTrial:
        case planPublicIds.paddleScaleMonthlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialScaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleMonthlyTrial,
            paddleMonthlyTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleMonthlyTrial
          break
        case registerRoutePath.registerScaleAnnualTrial:
        case planPublicIds.paddleScaleYearlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialAnnualScaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleYearlyTrial,
            paddleYearlyTrialPlans
          )
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleAnnualTrial
          break
        case registerRoutePath.registerEnterpriseMonthlyTrial:
        case planPublicIds.paddleEnterpriseMonthlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialEnterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseMonthlyTrial,
            paddleMonthlyTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseMonthlyTrial
          break
        case registerRoutePath.registerEnterpriseAnnualTrial:
        case planPublicIds.paddleEnterpriseYearlyTrial:
          this.subscriptionStatus.planId =
            config.paddle.trialAnnualEnterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseYearlyTrial,
            paddleYearlyTrialPlans
          )
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseAnnualTrial
          break
        case registerRoutePath.registerProMonthly:
        case planPublicIds.paddleProMonthly:
          this.subscriptionStatus.planId = config.paddle.planId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute = registerRoutesName.registerProMonthly
          break
        case registerRoutePath.registerStarterMonthly:
        case planPublicIds.paddleStarterMonthly:
          this.subscriptionStatus.planId = config.paddle.starterPlanId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleStarterMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerStarterMonthly
          break
        case registerRoutePath.registerStarterMonthlyFr:
        case registerRoutePath.registerStarterMonthlyDe:
        case registerRoutePath.registerStarterMonthlyEs:
        case registerRoutePath.registerStarterMonthlyPt:
          this.subscriptionStatus.planId = config.paddle.starterPlanId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleStarterMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerStarterMonthly
          break
        case registerRoutePath.registerProMonthlyFr:
        case registerRoutePath.registerProMonthlyDt:
        case registerRoutePath.registerProMonthlyEs:
        case registerRoutePath.registerProMonthlyPt:
          this.subscriptionStatus.planId = config.paddle.planId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute = registerRoutesName.registerProMonthly
          break
        case registerRoutePath.registerProAnnual:
        case planPublicIds.paddleProYearly:
          this.subscriptionStatus.planId = config.paddle.annualProId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute = registerRoutesName.registerProAnnual
          break
        case registerRoutePath.registerProAnnualFr:
        case registerRoutePath.registerProAnnualDt:
        case registerRoutePath.registerProAnnualEs:
        case registerRoutePath.registerProAnnualPt:
          this.subscriptionStatus.planId = config.paddle.annualProId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerProAnnualFr
          break
        case registerRoutePath.registerScaleMonthly:
        case planPublicIds.paddleScaleMonthly:
          this.subscriptionStatus.planId = config.paddle.scaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleMonthly
          break
        case registerRoutePath.registerScaleMonthlyFr:
        case registerRoutePath.registerScaleMonthlyDt:
        case registerRoutePath.registerScaleMonthlyEs:
        case registerRoutePath.registerScaleMonthlyPt:
          this.subscriptionStatus.planId = config.paddle.scaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleMonthly
          break
        case registerRoutePath.registerScaleAnnual:
        case planPublicIds.paddleScaleYearly:
          this.subscriptionStatus.planId = config.paddle.annualScaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleAnnual
          break
        case registerRoutePath.registerScaleAnnualFr:
        case registerRoutePath.registerScaleAnnualDt:
        case registerRoutePath.registerScaleAnnualEs:
        case registerRoutePath.registerScaleAnnualPt:
          this.subscriptionStatus.planId = config.paddle.annualScaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleAnnual
          break
        case registerRoutePath.registerEnterpriseMonthly:
        case planPublicIds.paddleEnterpriseMonthly:
          this.subscriptionStatus.planId = config.paddle.enterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseMonthly
          break
        case registerRoutePath.registerEnterpriseMonthlyFr:
        case registerRoutePath.registerEnterpriseMonthlyDt:
        case registerRoutePath.registerEnterpriseMonthlyEs:
        case registerRoutePath.registerEnterpriseMonthlyPt:
          this.subscriptionStatus.planId = config.paddle.enterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseMonthly
          break
        case registerRoutePath.registerEnterpriseAnnual:
        case planPublicIds.paddleEnterpriseYearly:
          this.subscriptionStatus.planId = config.paddle.annualEnterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseAnnual
          break
        case registerRoutePath.registerEnterpriseAnnualFr:
        case registerRoutePath.registerEnterpriseAnnualDt:
        case registerRoutePath.registerEnterpriseAnnualEs:
        case registerRoutePath.registerEnterpriseAnnualPt:
          this.subscriptionStatus.planId = config.paddle.annualEnterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseAnnual
          break
        case registerRoutePath.registerSingleAnnual:
        case planPublicIds.paddleSinglePlan:
          this.subscriptionStatus.planId = config.paddle.singlePlanId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleSinglePlan,
            paddleYearlyPlans
          )
          planDetails.isSinglePlan = true
          break
        case registerRoutePath.registerFreeTrial:
        case registerRoutePath.registerFreeTrialFr:
        case registerRoutePath.registerFreeTrialDt:
        case registerRoutePath.registerFreeTrialPt:
        case registerRoutePath.registerFreeTrialEs:
          planDetails = this.planMixin_filterPlans(
            planPublicIds.freeTrial,
            freeTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.isFreeTrial = true
          break
        default:
          planDetails = this.planMixin_filterPlans(
            planPublicIds.freeTrial,
            freeTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.isFreeTrial = true
          break
      }
      return planDetails
    },
    planMixin_getDefaultRoute: function () {
      let planDetails = {}
      this.subscriptionStatus.planId = config.paddle.trialPlanId
      planDetails = this.planMixin_filterPlans(
        planPublicIds.paddleProMonthlyTrial,
        paddleMonthlyTrialPlans
      )
      planDetails.isMonthlyBilled = true
      planDetails.isTrialPlan = true
      planDetails.registerPageRoute =
        registerRoutesName.registerProMonthlyTrial
      return planDetails
    },
    planMixin_filterPlans: function (planPublicId, planType) {
      const planDetails = planType.find((object) => {
        return object.publicId === planPublicId
      })
      return planDetails
    },
    planMixin_resetSubscriptionStatus: function () {
      this.subscriptionStatus = {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        planId: null,
        action: null,
        message: null,
        success: false,
        error: false,
      }
    },
    planMixin_subscribeUser: function (planId, userData) {
      this.planMixin_resetSubscriptionStatus()
      this.screen && this.screen.step++

      const metadata = {
        user_id: userData?.id
      }
      this.mixin_user.id = userData?.id
      this.mixin_user.token = userData?.token
      this.subscriptionStatus.action = opType.subscribe

      let checkoutConfig = {
        product: planId,
        theme: 'none',
        email: userData.email,
        passthrough: JSON.stringify(metadata),
        method: 'inline',
        frameTarget: 'payment-div',
        frameInitialHeight: 416,
        frameStyle: 'width:100%; min-width:312px; background-color:transparent; border:none;',
        marketingConsent: userData.subscribe_newsletter,
        successCallback: () => {
          this.flag.loading = true
          this.planMixin_pollingSubscriptionStatus(() => {
            this.subscriptionStatus.success = true
            let otherThis = this
            if (userData.token) {
              setTimeout(function () {
                otherThis.flag.loading = false
                otherThis.$router.push({
                  name: 'tokenLogin',
                  query: { token: userData.token }
                })
              }, 3000)
            } else {
              setTimeout(function () {
                otherThis.flag.loading = false
                otherThis.$router.push({ name: 'login' })
              }, 3000)
            }
          })
        },
        closeCallback: () => {
          this.planMixin_resetSubscriptionStatus()
        }
      }

      if (config.paddle.coupon) {
        checkoutConfig.coupon = config.paddle.coupon
      }

      if ([environments.LOCAL, environments.DEV].includes(config.environment)) {
        window.Paddle.Environment.set('sandbox')
      }

      // Ensure that Paddle is loaded and the DOM is ready
      if (window.Paddle) {
        this.$nextTick(() => {
          window.Paddle.Checkout.open(checkoutConfig)
        })
      } else {
        console.error('Paddle is not loaded')
      }
    },
    planMixin_pollingSubscriptionStatus(callback = null) {
      this.subscriptionStatus.intervalCounter = 0
      this.subscriptionStatus.success = false
      this.subscriptionStatus.error = false

      const pollingInterval = 4000
      const intervalLimit = 10 // Adjust as needed

      // Start polling at regular intervals
      this.subscriptionStatus.interval = setInterval(async () => {
        this.subscriptionStatus.intervalCounter++

        try {
          // Sync user data to check the subscription status
          const userSynced = await this.planMixin_syncLocalUser()

          // If user data is successfully synced and subscription is active
          if (userSynced) {
            clearInterval(this.subscriptionStatus.interval)
            this.subscriptionStatus.success = true
            this.planMixin_handleSubscription(
              false,
              this.subscriptionStatus.action
            )
            if (callback) callback(true)
          }
        } catch (err) {
          console.error('Error:', err)
        }

        // Check if polling limit is reached
        if (this.subscriptionStatus.intervalCounter >= intervalLimit) {
          clearInterval(this.subscriptionStatus.interval)
          this.subscriptionStatus.error = true

          try {
            // Attempt a final sync
            await this.planMixin_syncLocalUser()
          } finally {
            this.planMixin_handleSubscription(
              true,
              this.subscriptionStatus.action
            )
            if (callback) callback(false)
          }
        }
      }, pollingInterval)
    },
    async planMixin_syncLocalUser() {
      try {
        // Store token for authentication
        if (this.mixin_user.token) {
          localStorage.setItem('token', JSON.stringify(this.mixin_user.token))
        }

        const response = await this.$store.dispatch(actionTypes.FETCH_USER, {
          userId: this.mixin_user.id || this.user.id,
        })

        const user = response?.data?.data

        // Remove token after fetching user data
        if (this.mixin_user.token) {
          localStorage.removeItem('token')
        }

        // Check if user has an active plan
        if (user?.plan?.subscription) {
          await this.$store.dispatch(actionTypes.UPDATE_USER_LOCAL, user)
          localStorage.setItem('user', JSON.stringify(user))
          return true // User has an active subscription
        }

        throw new Error('No active subscription found')
      } catch (error) {
        return false
      }
    },
    planMixin_handleSubscription(isError, status) {
      if (!isError) {
        this.subscriptionStatus.success = true
      } else {
        this.subscriptionStatus.error = true
      }
    }
  }
}
