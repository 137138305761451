<template>
  <v-container class="token-login-view fill-height" fluid>
    <v-row align="center" justify="center" class="my-16">
      <v-col cols="12" sm="10" md="8" lg="6" xl="4">
        <v-card class="text-center pa-6 my-5" flat>
          <v-card-title class="text-center d-flex flex-column align-center">
            <v-img width="80" aspect-ratio="1" cover :src="require('@/assets/images/leadgen-leaf-logo.png')"></v-img>
          </v-card-title>
          <v-card-text>
            <h4 class="text-h4 font-weight-bold mb-4">LeadGen</h4>
            <v-row justify="center" class="my-1">
              <v-col cols="12" v-if="loading">
                <div class="text-h6 mb-5 font-weight-regular">
                  Please hold on while we securely log you into your account.
                </div>
                <!-- Loader -->
                <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
                  color="secondary-red"></v-progress-circular>
              </v-col>
              <v-col cols="12" v-else-if="errorMessage">
                <!-- Error / Warning alert -->
                <v-alert border type="error" variant="tonal" rounded="md" density="compact" class="my-4 text-left"
                  icon="mdi-alert-circle">
                  {{ errorMessage }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import jwtDecode from 'jwt-decode'
import * as actionTypes from '@/store/action-types.js'

export default {
  data: function () {
    return {
      token: '',
      loading: true,
      errorMessage: ''
    }
  },
  mounted: function () {
    this.token = this.$route.query.token
    this.logout()
    this.login()
  },
  methods: {
    logout: function () {
      this.$store.dispatch(actionTypes.LOGOUT_USER)
    },
    login: function () {
      if (!this.token || !this.token.trim().length) {
        this.loading = false
        this.errorMessage = 'Unable to login due to missing token!'
        return
      }

      let decodedToken = null

      try {
        decodedToken = jwtDecode(this.token)
      } catch (e) {
        this.loading = false
        this.errorMessage = 'Token received is not a valid JWT Token.'
        return
      }

      localStorage.setItem('token', JSON.stringify(this.token))

      this.$store.dispatch(actionTypes.FETCH_USER, {
        userId: decodedToken.sub
      }).then((response) => {
        let user = response.data.data

        this.$store.dispatch(actionTypes.LOGIN_SUCCESS, {
          'token': this.token,
          'user': user,
          'decodedToken': decodedToken
        })

        localStorage.setItem('decodedToken', JSON.stringify(jwtDecode(this.token)))
        localStorage.setItem('user', JSON.stringify(user))

        this.fetchPlans(user)

        if (user.show_onboarding) {
          localStorage.removeItem('private_url')
          this.$router.push({ name: 'industry-onboard' })
          return
        }

        this.$router.push('dashboard')
      }, (response) => {
        localStorage.removeItem('token')
        this.errorMessage = 'Unable to login, something went wrong.'
        this.$store.dispatch(actionTypes.LOGIN_FAILED)
      })
    },
    fetchUserSubscription: function (user) {
      this.$store.dispatch(actionTypes.FETCH_PLAN_SUBSCRIPTION, {
        planId: user.plan.id
      }).then((response) => {
        user.plan.subscription = response.data.data
        localStorage.setItem('user', JSON.stringify(user))
        this.$store.dispatch(actionTypes.FETCH_PLAN_SUBSCRIPTION_SUCCESS, response.data.data)
      })
    },
    fetchPlans: function (user) {
      this.$store.dispatch(actionTypes.FETCH_PLANS).then((response) => {
        localStorage.setItem('plans', JSON.stringify(response.data.data) || [])
        this.$store.dispatch(actionTypes.FETCH_PLANS_SUCCESS, response.data.data)
        this.fetchUserSubscription(user)
      })
    }
  }
}
</script>

<style lang="scss">
.token-login-view {
  flex: 1;

  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
