export default {
  methods: {
    adjustDashboardLayout: function () {
      let scrollHeight = document
        .querySelector('.dashboard-notification')
        .scrollHeight

      document
        .querySelector('.dashboard-main')
        .style.marginTop = `${scrollHeight}px`

      document
        .querySelector('.dashboard-content__header')
        .style.top = `${scrollHeight}px`
    },
    adjustPlanLayout: function () {
      document
        .querySelector('.dashboard-main')
        .style.marginTop = '0px'

      document
        .querySelector('.dashboard-content__header')
        .style.top = '0px'
    }
  }
}
