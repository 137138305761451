<template>
  <!-- Browse menu -->
  <div class="template-browse my-1">
    <!-- CARD CONTAINER -->
    <v-row>
      <div class="card-container">
        <!-- Template Card -->
        <v-card class="card-template" v-for="(template) of paginatedTemplates" :key="template.id" rounded="sm" hover
          max-width="345" height="400">
          <div class="template-tag">{{ activeFilterText ? activeFilterText : getPrimaryCategory(template) }}</div>
          <v-img :lazy-src="require('@/assets/images/views/templates/img-error.jpg')" height="225"
            :src="getImagePath(template.template_image)" cover>
            <!-- template placeholder -->
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular color="red" indeterminate :width="3"></v-progress-circular>
              </div>
            </template>
            <!-- template error -->
            <template v-slot:error>
              <v-img :src="require('@/assets/images/views/templates/img-error.jpg')" cover></v-img>
            </template>
          </v-img>
          <v-divider :thickness="5" color="primary-red" opacity="1"></v-divider>
          <v-card-text class="mt-2 ml-1">
            <div class="font-weight-bold">{{ template.title }}</div>
          </v-card-text>
          <v-card-actions class="mb-0 px-3">
            <v-btn class="text-none font-weight-bold view-btn" rounded="md" @click="handlePreviewClick(template)">
              View Template
            </v-btn>
            <v-btn class="text-none font-weight-bold use-btn" rounded="md" @click="handleUseClick(template)"
              :loading="mixin_use.loading">
              Use Template
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-row>
    <!-- Load More Templates -->
    <v-row class="my-8">
      <v-col>
        <div class="text-center" v-if="shouldRenderLoadMoreButton">
          <v-btn append-icon="mdi-chevron-down" size="large" variant="outlined" rounded="md"
            class="text-none font-weight-bold more-btn" color="red" @click="loadMoreTemplates"
            :loading="mixin_paginate.loader">Load
            More Templates</v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- TEMPLATE PREVIEW MODAL -->
    <TemplatePreviewModal ref="previewTemplateModal" :preview="store"></TemplatePreviewModal>
    <v-snackbar rounded="md" height="50" :color="snackbar.error ? 'secondary-red' : 'primary-green'" :timeout="3000"
      v-model="snackbar.show">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
    </v-snackbar>
  </div>
</template>

<script>
import config from '@/api/config'
import templateMixin from '@/mixins/templates'
import TemplatePreviewModal from '@/components/FormTemplateBrowser/TemplatePreviewModal'

export default {
  mixins: [templateMixin],
  props: {
    filteredTemplates: Array,
    activeFilterText: String
  },
  components: {
    TemplatePreviewModal
  },
  data() {
    return {
      store: {
        title: null,
        category: null,
        url: null,
        formId: null
      },
      snackbar: {
        show: false,
        text: null,
        error: false
      }
    }
  },
  computed: {
    paginatedTemplates() {
      const startIndex = 0
      const endIndex = this.mixin_paginate.loadFrom + this.mixin_paginate.templatesToShow
      return [...this.filteredTemplates]
        .reverse()
        .slice(startIndex, endIndex)
    },
    shouldRenderLoadMoreButton() {
      return this.filteredTemplates.length > this.mixin_paginate.loadFrom + this.mixin_paginate.templatesToShow
    }
  },
  methods: {
    getPrimaryCategory(templates) {
      if (templates.template_categories && templates.primary_category) {
        for (let category of templates.template_categories) {
          if (category.id === templates.primary_category.form_category_id) {
            return category.title
          }
        }
      }
      return ''
    },
    getImagePath: function (value) {
      return config.basePath + '/media/' + value.ref_id + '/' + value.name
    },
    loadMoreTemplates: function () {
      this.mixin_paginate.loader = true
      // Calculate the new end index for loading more templates
      let newEndIndex = this.mixin_paginate.loadFrom + this.mixin_paginate.templatesToShow

      // Adjust if the new end index exceeds the total number of templates
      newEndIndex = Math.min(newEndIndex, this.filteredTemplates.length)

      // Update loadFrom for the next load
      this.mixin_paginate.loadFrom = newEndIndex

      // Re-check if there are more templates to show
      if (newEndIndex >= this.filteredTemplates.length) {
        this.mixin_paginate.hasMore = false
      }
      this.mixin_paginate.loader = false
    },
    checkHasMoreTemplates: function () {
      const remainingTemplates = this.filteredTemplates.length - this.mixin_paginate.loadFrom
      this.mixin_paginate.hasMore = remainingTemplates > 0
    },
    handleUseClick: function (template) {
      this.handleUseOrView(template, () => {
        this.$refs.previewTemplateModal.useTemplateForm()
      })
    },
    handlePreviewClick: function (template) {
      this.handleUseOrView(template, () => {
        this.$refs.previewTemplateModal.mixin_use.dialog = true
      })
    },
    handleUseOrView: function (template, actionCallback) {
      if (template && template.template_form && template.template_form.key) {
        this.store.title = template.title
        this.store.category = this.activeFilterText ? this.activeFilterText : this.getPrimaryCategory(template)
        this.store.url = config.formsUrl + '/' + template.template_form.key
        this.store.formId = template.form_id
        actionCallback()
      } else {
        this.snackbar.show = true
        this.snackbar.error = true
        this.snackbar.text = 'OOPS! Something Went Wrong.'
      }
    }
  }
}
</script>
