<template>
  <v-container class="w-75">
    <v-row class="user-settings-view">
      <v-col cols="12">
        <v-snackbar rounded="md" height="50" :color="snackbarColor" v-model="snackbar" :timeout="5000">
          <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbarMessage }} </p>
        </v-snackbar>

        <v-form @submit.prevent="onFormSubmit">
          <v-row align="center" justify="space-between" class="mb-4 text-none">
            <v-col>
              <h4 class="page-title">User Settings</h4>
            </v-col>
            <v-col class="text-right ">
              <v-btn :loading="saving" class="text-none font-weight-bold" prepend-icon="mdi-content-save-cog"
                type="submit" color="primary-red" rounded="md">
                Save Settings
              </v-btn>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <div>
            <v-card rounded="md" elevation="0" class="border-thin border-t-0 rounded-t-sm">
              <v-card-title class="bg-blue-lighten-5 text-primary-blue">
                <h6 class="d-flex justify-left mb-0">
                  <v-icon icon="mdi-email-check" class="pt-1"></v-icon>
                  <p class="font-weight-bold text-primary-blue mb-0">&nbsp;&nbsp;Email Verification Settings
                  </p>
                  <span class="lg-badge pt-2 ml-4" :class="emailVerification ? 'bg-primary-green' : 'bg-secondary-red'">
                    {{ emailVerification ? 'Enabled' : 'Disabled' }}
                  </span>
                </h6>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-switch v-model="emailVerification" color="primary-red" inset inline persistent-hint
                  density="comfortable" label="Email Verification " hide-details></v-switch>
                <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mb-1 mt-5 text-black">
                  <p class="text-black mb-0"> <strong>Note:</strong> A verification email will be sent on signing up if
                    the toggle is enabled.
                  </p>
                </v-alert>
              </v-card-text>
            </v-card>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    const emailVerification = ref(false)
    const saving = ref(false)
    const snackbarMessage = ref('')
    const snackbar = ref(false)
    const snackbarColor = ref('success')
    const id = ref(null)

    const userSetting = computed(() => store.getters['user-settings/fetchUserSettings'])

    const loadUserSetting = async () => {
      try {
        const response = await store.dispatch('user-settings/fetchUserSettings')
        const usersetting = response.data.data[0]
        await store.dispatch('user-settings/fetchUserSettingsSuccess', { usersetting })
        id.value = usersetting.id
        emailVerification.value = Boolean(usersetting.email_verification_enabled)
      } catch (error) {
        store.dispatch('user-settings/fetchUserSettingsError')
      }
    }

    const updateField = (field, value) => {
      store.dispatch('user-settings/updateUserSettings', { value, field })
    }

    const onFormSubmit = async () => {
      saving.value = true
      snackbarMessage.value = ''
      try {
        await store.dispatch('user-settings/updateUserSettings', {
          id: id.value,
          email_verification_enabled: emailVerification.value ? 1 : 0
        })
        saving.value = false
        snackbarMessage.value = 'Your settings have been updated successfully.'
        snackbarColor.value = 'success'
        snackbar.value = true
      } catch (err) {
        let message = err.response && err.response.data && err.response.data.meta
          ? err.response.data.meta.error_message
          : 'Unable to save settings. Please try again later.'
        snackbarMessage.value = message
        saving.value = false
        snackbarColor.value = 'error'
        snackbar.value = true
      }
    }

    watch(emailVerification, (newValue) => {
      updateField('email_verification_enabled', newValue ? '1' : '0')
    })

    onMounted(loadUserSetting)

    return {
      emailVerification,
      saving,
      snackbarMessage,
      snackbar,
      snackbarColor,
      userSetting,
      onFormSubmit
    }
  }
}
</script>
