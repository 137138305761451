<template>
  <v-container class="fill-height home-view">
    <v-row align="center" justify="center" class="my-16">
      <v-col cols="12" sm="10" md="8" lg="6" xl="4">
        <v-card class="text-center pa-6 my-5" flat>
          <v-card-title class="text-center d-flex flex-column align-center">
            <v-img :width="80" aspect-ratio="1" cover :src="require('@/assets/images/leadgen-leaf-logo.png')"></v-img>
            <br>
          </v-card-title>
          <v-spacer class="my-10 pb-10"></v-spacer>
          <v-card-text>
            <!-- Headline -->
            <v-row class="justify-center">
              <v-col cols="12" sm="auto" class="pa-2">
                <span class="text-h4 font-weight-regular">
                  ...capture your lead insights.
                </span>
              </v-col>
            </v-row>
            <!-- Buttons -->
            <v-row class="justify-center" v-if="!isLoggedIn">
              <v-col cols="12" sm="auto" class="pa-2">
                <v-btn color="primary-green" rounded="md" variant="elevated" size="large" class="text-none font-weight-bold"
                  prepend-icon="mdi-account-plus" @click="$router.push({ name: 'register' })" block>
                  Create LeadGen Account
                </v-btn>
              </v-col>

              <v-col cols="12" sm="auto" class="pa-2">
                <v-btn color="primary-red" rounded="md" variant="elevated" size="large" class="text-none font-weight-bold"
                  prepend-icon="mdi-login" @click="$router.push({ name: 'login' })" block>
                  Sign In
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="justify-center" v-else>
              <v-col cols="12" sm="auto" class="pa-2">
                <v-btn color="secondary-red" rounded="md" variant="elevated" size="large" class="text-none font-weight-bold"
                  append-icon="mdi-arrow-right" @click="$router.push({ name: 'dashboard' })" block>
                  Dashboard
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
}
</script>
