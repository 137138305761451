<template>
  <div class="template-topbar">
    <v-container>
      <v-row>
        <v-text-field variant="outlined" clearable color="grey-darken-4" density="comfortable" rounded="md" width="100"
          label="Search Form Templates" placeholder="Type here to search for Form templates" v-model="search.keyword"
          @focus="search.focus = true" @blur="search.focus = false" @input="handleSearchInput"
          @keydown.enter="handleSearch">
          <template v-slot:prepend-inner>
            <v-icon :color="search.focus ? 'grey-darken-4' : 'grey'" class="opacity-100">mdi-magnify</v-icon>
          </template>
          <template v-slot:append>
            <v-btn class="text-none" color="primary-red" rounded="md" height="47"
              :variant="search.hover ? 'elevated' : 'flat'" @mouseenter="search.hover = true"
              @mouseleave="search.hover = false" @click="$router.push({ name: 'forms.create' })">
              <v-icon>mdi-plus</v-icon>
              <span class="d-none d-sm-inline font-weight-bold">&nbsp;Create Form</span>
            </v-btn>
          </template>
        </v-text-field>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'
import templateMixin from '@/mixins/templates'

export default {
  mixins: [templateMixin],
  data: () => ({
    search: {
      keyword: null,
      focus: false,
      hover: false
    }
  }),
  methods: {
    handleSearchInput: _debounce(function () {
      this.handleSearch()
    }, 500),

    handleSearch() {
      this.$emit('filterChange', this.mixin_templateKeywords.KEYWORD, this.search.keyword)
    }
  }
}
</script>
