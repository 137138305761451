<template>
  <v-form ref="recaptchaFormRef" class="recaptcha-form" @submit.prevent="saveRecaptcha">
    <v-alert border :type="alert.type" variant="tonal" rounded="md" density="compact" class="mb-6"
      icon="mdi-information" v-if="alert.show">
      {{alert.text}}
    </v-alert>
    <!-- title input -->
    <v-text-field class="mb-0" label="Title" placeholder="Enter reCAPTCHA title" variant="outlined"
      density="comfortable" rounded="md" :rules="$valid(
        [
          { rule: 'required' },
          { rule: 'between', min: '5', max: '50' }
        ]
      )" v-model="recaptchaForm.title">
      <template v-slot:prepend-inner>
        <v-icon>mdi-text-short</v-icon>
      </template>
    </v-text-field>
    <!-- key input -->
    <v-text-field class="mb-0" variant="outlined" density="comfortable" rounded="md" v-model="recaptchaForm.site_key"
      label="Site Key" placeholder="Enter reCAPTCHA Site Key" :rules="$valid(
        [
          { rule: 'required' }
        ]
      )" required><template v-slot:prepend-inner>
        <v-icon>mdi-key</v-icon>
      </template>
    </v-text-field>
    <!-- secret key input -->
    <v-text-field class="mb-0" variant="outlined" density="comfortable" :rules="$valid(
      [
        { rule: 'required' }
      ]
    )" rounded="md" v-model="recaptchaForm.secret_key" label="Secret Key" placeholder="Enter reCAPTCHA Secret Key"
      required>
      <template v-slot:prepend-inner>
        <v-icon>mdi-shield-key</v-icon>
      </template>
    </v-text-field>
    <v-card-actions class="d-flex justify-end mb-3 mr-n2">
      <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
        @click="cancelForm()">
      </v-btn>
      <v-btn rounded="md" variant="elevated" class="font-weight-bold text-none" color="primary-red" :loading="saving"
        @click="saveRecaptcha">
        <span>Save</span>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup>
import { watch, ref } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['recaptchaComplete', 'closeDialog'])
const store = useStore()
const saving = ref(false)
const recaptchaFormRef = ref(null)

const alert = ref({
  show: false,
  type: '',
  text: ''
})

const cancelForm = () => {
  emit('closeDialog')
}

const props = defineProps({
  editAction: Boolean,
  recaptchaData: Object
})

const recaptchaForm = ref({ ...props.recaptchaData })

watch(() => props.recaptchaData, (newData) => {
  recaptchaForm.value = { ...newData }
}, { deep: true })

const showAlert = (type, text) => {
  alert.value = { show: true, type, text }
  setTimeout(() => {
    alert.value.show = false
  }, 5000)  // Hide alert after 5 seconds
}

const saveRecaptcha = async () => {
  saving.value = true
  alert.value.show = false

  // Validate the form
  const { valid } = await recaptchaFormRef.value.validate()
  if (valid) {
    try {
      let response
      if (props.editAction) {
        response = await store.dispatch('googlerecaptcha/updateRecaptchaKey', recaptchaForm.value)
      } else {
        response = await store.dispatch('googlerecaptcha/createRecaptchaKey', recaptchaForm.value)
      }

      showAlert('success', 'reCAPTCHA saved successfully')
      emit('recaptchaComplete', true)

      if (!props.editAction) {
        recaptchaForm.value = {
          title: '',
          site_key: '',
          secret_key: ''
        }
        setTimeout(() => {
          emit('closeDialog')
        }, 5000)
      }
    } catch (err) {
      showAlert('error', err.response?.data?.meta?.error_message || 'An error occurred while saving reCAPTCHA')
    } finally {
      saving.value = false
    }
  } else {
    saving.value = false
  }
}
</script>
