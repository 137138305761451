export const endingConditions = {
  ISEQUALTO: 'Is Equal To',
  ISNOTEQUALTO: 'Is Not Equal To',
  ISFILLED: 'Is Filled',
  ISEMPTY: 'Is Empty',
  ISCHECKED: 'Is Checked',
  ISNOTCHECKED: 'Is Not Checked',
  COUNTRYEQUALS: 'Country Equals',
  COUNTRYDOSENTEQUALS: 'Country Doesnt Equals',
  CONTAINS: 'Contains',
  DOSENTCONTAINS: 'Does Not Contain',
  STARTSWITH: 'Starts With',
  DOSENTSTARTSWITH: 'Doesn’t Start With',
  BEFORE: 'Before',
  AFTER: 'After',
  ENDSWITH: 'Ends With',
  DOSENTENDSWITH: 'Doesn’t End With',
  GREATERTHAN: 'Greater Than',
  LESSTHAN: 'Less Than'
}
