<template>
  <div id="form-create-view">
    <div class="row" v-if="this.formId">
      <form-builder :form-id="formId"></form-builder>
    </div>
  </div>
</template>

<script>
import FormBuilder from '@/components/FormBuilder'
import * as actionTypes from '@/store/action-types'
export default {
  components: {
    'form-builder': FormBuilder
  },

  mounted: function () {
    this.$store.dispatch(actionTypes.FORMBUILDER_RESET)
  },

  computed: {
    formId: function () {
      return parseInt(this.$route.params.id)
    }
  }
}
</script>
