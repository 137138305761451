<template>
  <div class="dashboard-notification__item">
    <strong>{{ userMixin_trialDaysLeft }} out of {{ plan.trial_days }} days</strong> left on the trial.
    <router-link :to="{ name: 'plans.index' }" class="trial-notification__item__action">Upgrade</router-link> for the
    full experience!
  </div>
</template>

<script type="text/javascript">

import userMixin from '@/mixins/user'

export default {
  mixins: [userMixin]
}
</script>
