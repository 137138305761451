<template>
  <div id='main-wrapper-form-variant-theme'>
    <div class='main-container' @click.capture='showChromePicker(1)'>
      <v-tabs class="position-fixed z-1 bg-white w-100 pl-8" ref=""
        style="z-index: 10; box-shadow: 0 3px 6px 0 rgba(66, 69, 143, .12);" color="secondary-red" v-model="pageTabs">
        <v-tab class="text-none font-weight-bold" value="themes">Themes</v-tab>
        <v-tab class="text-none font-weight-bold" value="theme-settings">Theme Settings</v-tab>
        <v-tab class="text-none font-weight-bold" value="typography">Typography</v-tab>
        <v-tab class="text-none font-weight-bold" value="ui-elements">UI Elements</v-tab>
        <v-tab class="text-none font-weight-bold" value="step-navigation">Step Navigation</v-tab>
      </v-tabs>
      <!-- <v-divider></v-divider> -->
      <v-tabs-window v-model="pageTabs" type="text" background-color="white" text-color-active="grey" text-color="black"
        v-if="chromePickers.length === chromePickersCount">
        <v-tabs-window-item value="themes">
          <div class='theme-setting-both-sides'>
            <!-- LEFT SIDE START -->
            <div class='left-side'>
              <form-theme-store :formId="parseInt($route.params.id)" :formVariantId="parseInt($route.params.variantId)"
                v-on:addStyle="addStyle" />
            </div>
            <!-- LEFT SIDE END -->

            <!-- RIGHT SIDE START -->
            <div class='right-side'>
              <div class='preview'>Preview</div>
              <div class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">

                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">Question
                  </div>
                  <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_description.font.color,
                    fontSize: typography.question_description.font.size + 'px',
                    fontFamily: typography.question_description.font.family,
                    fontWeight: typography.question_description.font.weight,
                    lineHeight: typography.question_description.font.line_height + 'px',
                    textAlign: typography.question_description.text_align
                  }"> This is the question description.
                  </div>
                  <input type="text" class='activeone' value='question answer' :style="{
                    textAlign: typography.input_box.text_align
                  }">
                  <div class='required' :style="{ color: general.colors.warning_color }"> This field is required </div>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    Which Platform(s) will the app will be build on ?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <ul class="radio-list">
                    <li v-for="(choice, cindex) in choices" :key="cindex">
                      <input name='question1' type="radio">
                      <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                    </li>
                  </ul>

                  <div class='gdpr'>
                    <div :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">
                      GDPR Marketing Permissions
                    </div>
                    <div class='grdp-details' :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> We will use the information you provide
                      on this form to be in touch with you and to provide updates and marketing. Please let us know all
                      the ways you like to hear from us.
                    </div>
                    <div class="gdpr-terms">
                      <v-checkbox hide-details label="Email" class="mb-n4"></v-checkbox>
                      <v-checkbox hide-details label="Direct Post" class="mb-n4"></v-checkbox>
                      <v-checkbox hide-details label="Customized online advertising"></v-checkbox>
                    </div>
                    <p :style='{ color: theme.general.colors.text_color }'><small>We use <strong>LeadGen</strong> as our
                        marketing automation platform. By submitting this form, you acknowledge that the
                        information you
                        provide will be transferred to <strong>LeadGen</strong>.</small></p>
                  </div>

                  <div class='both-buttons'>
                    <div class="back-button-wrapper">
                      <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                        v-if="!uiElements.step_navigation.back_button.hide">
                        <!-- <v-btn :style="backButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.back_button.shadow }'>
                          <span class="v-btn__content" :style="contentStyle">
                            <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>&nbsp;
                            {{ uiElements.step_navigation.back_button.text }}
                          </span>
                        </v-btn> -->
                        <v-btn :style="backButtonStyle" :class="[
                          'back-btn',
                          uiElements.step_navigation.back_button.shadow ? 'elevation-1' : 'elevation-0'
                        ]">
                          <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>&nbsp;
                          {{ uiElements.step_navigation.back_button.text }}
                        </v-btn>
                      </div>
                    </div>

                    <div class="continue-button-wrapper" :class='{
                      btnFull: uiElements.step_navigation.back_button.hide,
                      btnHalf: !uiElements.step_navigation.back_button.hide
                    }'>
                      <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                        <v-btn :style="continueButtonStyle" :class="[
                          'continue-btn',
                          uiElements.step_navigation.next_button.shadow ? 'elevation-1' : 'elevation-0'
                        ]">
                          {{ uiElements.step_navigation.next_button.text }}
                          &nbsp;<i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                        </v-btn>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <!-- RIGHT SIDE END -->
          </div>
        </v-tabs-window-item>

        <!-- GENERAL -->
        <v-tabs-window-item value="theme-settings">
          <div class='theme-setting-both-sides'>
            <!-- LEFT SIDE START -->
            <div class='left-side'>
              <v-row>
                <v-col cols="12">
                  <h3>Theme Settings</h3>
                  <p> Theme settings gives you quick access to commonly used theme default settings. A deeper level of
                    customization is available under the <span class='text-secondary-red'>Typography </span>, <span
                      class='text-secondary-red'> UI
                      Elements </span> and <span class='text-secondary-red'> Step Navigation </span>sections.</p>
                </v-col>
                <v-col cols="12" class="mb-0 pb-0">
                  <h6 class="font-weight-bold">Color Palette</h6>
                </v-col>
                <v-col cols="4" class="mt-0">
                  <color-picker v-model="general.colors.active_color" model-title="Active Color"
                    @colorChanged="updateGeneralSetting($event, 'generalActiveColor')"></color-picker>
                </v-col>
                <v-col cols="4">
                  <color-picker v-model="general.colors.text_color" model-title="Text Color"
                    @colorChanged="updateGeneralSetting($event, 'generalTextColor')"></color-picker>
                </v-col>
                <v-col cols="4">
                  <color-picker v-model="general.colors.warning_color" model-title="Warning Color"
                    @colorChanged="updateGeneralSetting($event, 'generalWarningColor')"></color-picker>
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col cols="12" class="mb-0 pb-0">
                  <h6 class="font-weight-bold">Global Fonts</h6>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-switch hide-details color="secondary-red" label="Inherit from Website (Direct Embed Only)"
                    v-model="general.inherit_toggle" @update:modelValue="updateGeneralSetting($event, 'inheritFont')"
                    class="input-font">
                  </v-switch>
                </v-col>
                <v-col class="pt-0" cols="8" v-if="!this.theme.general.inherit_toggle">
                  <v-autocomplete active hide-details variant="outlined" density="comfortable"
                    label="Primary Font Family" :items="fontFamily" v-model="(general.font.family)"
                    @update:modelValue="updateGeneralSetting($event, 'generalFamily')" class="input-font">
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-6">
                <v-col cols="12" class="mb-0 pb-0">
                  <h6 class="font-weight-bold">Form</h6>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-switch hide-details color="secondary-red" label="Static Height"
                    :model-value="!general.dynamic_height"
                    @update:modelValue="updateGeneralSetting($event, 'generalDynamicHeight')" class="input-font">
                  </v-switch>
                </v-col>
                <v-col cols="4" class="pt-0">
                  <v-switch hide-details color="secondary-red" label="Fade-In Effect" v-model="general.dynamic_fadein"
                    @update:modelValue="updateGeneralSetting($event, 'generalDynamicFadein')" class="input-font">
                  </v-switch>
                </v-col>
              </v-row>
            </div>
            <!-- LEFT SIDE END -->

            <!-- RIGHT SIDE START -->
            <div class='right-side'>
              <div class='preview'>Preview</div>
              <div class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">

                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">Question
                  </div>
                  <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_description.font.color,
                    fontSize: typography.question_description.font.size + 'px',
                    fontFamily: typography.question_description.font.family,
                    fontWeight: typography.question_description.font.weight,
                    lineHeight: typography.question_description.font.line_height + 'px',
                    textAlign: typography.question_description.text_align
                  }"> This is the question description.
                  </div>
                  <input type="text" class='activeone' value='question answer' :style="{
                    textAlign: typography.input_box.text_align
                  }">
                  <div class='required' :style="{ color: general.colors.warning_color }"> This field is required </div>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    Which Platform(s) will the app will be build on ?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <ul class="radio-list">
                    <li v-for="(choice, cindex) in choices" :key="cindex">
                      <input name='question1' type="radio">
                      <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                    </li>
                  </ul>

                  <div class='gdpr'>
                    <div :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">
                      GDPR Marketing Permissions
                    </div>
                    <div class='grdp-details' :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> We will use the information you provide
                      on this form to be in touch with you and to provide updates and marketing. Please let us know all
                      the ways you like to hear from us.
                    </div>
                    <div class="gdpr-terms">
                      <v-checkbox hide-details label="Email" class="mb-n4"></v-checkbox>
                      <v-checkbox hide-details label="Direct Post" class="mb-n4"></v-checkbox>
                      <v-checkbox hide-details label="Customized online advertising"></v-checkbox>
                    </div>
                    <p :style='{ color: theme.general.colors.text_color }'><small>We use <strong>LeadGen</strong> as our
                        marketing automation platform. By submitting this form, you acknowledge that the information you
                        provide will be transferred to <strong>LeadGen</strong>.</small></p>
                  </div>

                  <div class='both-buttons'>
                    <div class="back-button-wrapper">
                      <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                        v-if="!uiElements.step_navigation.back_button.hide">
                        <v-btn buttonType='button' :style="backButtonStyle" :class="[
                          'back-btn',
                          uiElements.step_navigation.back_button.shadow ? 'elevation-1' : 'elevation-0'
                        ]">
                          <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                          {{ uiElements.step_navigation.back_button.text }}
                        </v-btn>
                      </div>
                    </div>

                    <div class="continue-button-wrapper" :class='{
                      btnFull: uiElements.step_navigation.back_button.hide,
                      btnHalf: !uiElements.step_navigation.back_button.hide
                    }'>
                      <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                        <v-btn buttonType='button' :style="continueButtonStyle" :class="[
                          'continue-btn',
                          uiElements.step_navigation.next_button.shadow ? 'elevation-1' : 'elevation-0'
                        ]">
                          {{ uiElements.step_navigation.next_button.text }}
                          <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                        </v-btn>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <!-- RIGHT SIDE END -->
          </div>
        </v-tabs-window-item>

        <!-- Typography -->
        <v-tabs-window-item value="typography" id='t'>
          <div class='theme-setting-both-sides'>
            <div class='left-side'>
              <h3 class="mb-3">Typography</h3>
              <v-tabs ref="" class="shadow-tab" color="secondary-red" v-model="typographyTabs">
                <v-tab class="text-none text-capitalize" value="questionTitle">Question title</v-tab>
                <v-tab class="text-none text-capitalize" value="questionDescription">Question description</v-tab>
                <v-tab class="text-none text-capitalize" value="inputBox">Input box</v-tab>
                <v-tab class="text-none text-capitalize" value="textElement">Text Element</v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <v-tabs-window v-model="typographyTabs" type="text" style="width:90%" background-color="white"
                text-color-active="black" text-color="black" indicatorColor="primary" class="pt-10">
                <v-tabs-window-item value="questionTitle">
                  <v-row>
                    <v-col cols="4">
                      <v-autocomplete active variant="outlined" density="comfortable" label="TypeFace"
                        :items="fontFamily" :model-value="(typography.question_title.font.family)"
                        @update:modelValue="updateTypographySetting($event, 'titleFamily')" class="input-font-family">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Font Size" :items="fontSize"
                        :value="(typography.question_title.font.size)"
                        @update:modelValue="updateTypographySetting($event, 'titleFontSize')">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Weight" :items="fontWeight"
                        :value="(typography.question_title.font.weight)"
                        @update:modelValue="updateTypographySetting($event, 'titleFontWeight')">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Line Height" :items="lineHeight"
                        :value="(typography.question_title.font.line_height)"
                        @update:modelValue="updateTypographySetting($event, 'titleLineHeight')"></v-select>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="typography.question_title.font.color"
                        @colorChanged="updateTypographySetting($event, 'titleColor')"></color-picker>
                    </v-col>
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Text Alignment"
                        :items="buttonAlignment" :value="(typography.question_title.text_align)"
                        @update:modelValue="updateTypographySetting($event, 'titleAlignment')">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-tabs-window-item>
                <v-tabs-window-item value="questionDescription">
                  <v-row>
                    <v-col cols="4">
                      <v-autocomplete active variant="outlined" density="comfortable" label="TypeFace"
                        :items="fontFamily" :hasSearch=true :model-value="(typography.question_description.font.family)"
                        @update:modelValue="updateTypographySetting($event, 'descriptionFamily')"
                        class="input-font-family">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Font Size" :items="fontSize"
                        :value="(typography.question_description.font.size)"
                        @update:modelValue="updateTypographySetting($event, 'descriptionFontSize')">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Weight" :items="fontWeight"
                        :value="(typography.question_description.font.weight)"
                        @update:modelValue="updateTypographySetting($event, 'descriptionWeight')">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Line Height" :items="lineHeight"
                        :value="(typography.question_description.font.line_height)"
                        @update:modelValue="updateTypographySetting($event, 'descriptionLineHeight')"></v-select>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="typography.question_description.font.color"
                        @colorChanged="updateTypographySetting($event, 'descriptionColor')"></color-picker>
                    </v-col>
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Text Alignment"
                        :items="buttonAlignment" :value="(typography.question_description.text_align)"
                        @update:modelValue="updateTypographySetting($event, 'descriptionAlignment')">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-tabs-window-item>
                <v-tabs-window-item value="inputBox">
                  <v-row>
                    <v-col cols="4">
                      <v-autocomplete active variant="outlined" density="comfortable" label="Family Style"
                        :items="fontFamily" :hasSearch=true :model-value="(typography.input_box.font.family)"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxFamily')"
                        class="input-font-family">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="typography.input_box.font.background_color" model-title="Background Color"
                        @colorChanged="updateTypographySetting($event, 'inputBoxBackground')"></color-picker>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="typography.input_box.font.color" model-title="Text Color"
                        @colorChanged="updateTypographySetting($event, 'inputBoxColor')"></color-picker>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Font Size" :items="fontSize"
                        v-model="(typography.input_box.font.font_size)"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxFont')" class="input-font-family">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field active variant="outlined" density="comfortable" label="Height"
                        v-model="typography.input_box.font.height"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxHeight')" type="number" :min="15"
                        :max="48">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field active variant="outlined" density="comfortable" label="Spacing"
                        v-model="(typography.input_box.font.spacing)"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxSpacing')" type="number" :min="10"
                        :max="48">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Text Alignment"
                        :items="buttonAlignment" v-model="typography.input_box.text_align"
                        @update:modelValue="updateTypographySetting($event, 'inputAlignment')">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="typography.input_box.placeholder.color"
                        model-title="Placeholder Text Color"
                        @colorChanged="updateTypographySetting($event, 'inputBoxPlaceholderColor')"></color-picker>
                    </v-col>
                  </v-row>
                </v-tabs-window-item>
                <v-tabs-window-item value="textElement">
                  <v-row>
                    <v-col cols="4">
                      <v-autocomplete active variant="outlined" density="comfortable" label="Family Style"
                        :items="fontFamily" :hasSearch=true v-model="typography.text_box.family"
                        @update:modelValue="updateTypographySetting($event, 'textBoxFamily')"
                        class="input-font-family"></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="typography.text_box.color"
                        @colorChanged="updateTypographySetting($event, 'textBoxColor')"></color-picker>
                    </v-col>
                  </v-row>
                </v-tabs-window-item>
              </v-tabs-window>
            </div>

            <div class='right-side'>
              <div class='preview'>Preview</div>
              <div class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">
                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div :style="{
                    color: typography.text_box.color,
                    fontFamily: typography.text_box.family,
                    paddingBottom: '20px',
                    fontSize: '30px'
                  }">
                    Form Heading Text
                  </div>
                  <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">Question
                  </div>
                  <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_description.font.color,
                    fontSize: typography.question_description.font.size + 'px',
                    fontFamily: typography.question_description.font.family,
                    fontWeight: typography.question_description.font.weight,
                    lineHeight: typography.question_description.font.line_height + 'px',
                    textAlign: typography.question_description.text_align
                  }"> This is the question description.
                  </div>
                  <input type="text" class='activeone' value='question answer' :style="{
                    textAlign: typography.input_box.text_align
                  }">
                  <div class='required' :style="{ color: general.colors.warning_color }"> This field is required </div>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    Which Platform(s) will the app will be build on ?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <ul class="radio-list">
                    <li v-for="(choice, cindex) in choices" :key="cindex">
                      <input name='question1' type="radio">
                      <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                    </li>
                  </ul>

                  <div class='gdpr'>
                    <div :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }"> GDPR Marketing Permissions</div>
                    <div class='grdp-details' :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> We will use the information you provide
                      on this form to be in touch with you and to provide updates and marketing. Please let us know all
                      the ways you like to hear from us.
                    </div>
                    <div class="gdpr-terms">
                      <v-checkbox hide-details label="Email" class="mb-n4"></v-checkbox>
                      <v-checkbox hide-details label="Direct Post" class="mb-n4"></v-checkbox>
                      <v-checkbox hide-details label="Customized online advertising"></v-checkbox>
                    </div>
                    <p :style='{ color: theme.general.colors.text_color }'><small>We use <strong>LeadGen</strong> as our
                        marketing automation platform. By submitting this form, you acknowledge that the information you
                        provide will be transferred to <strong>LeadGen</strong>.</small></p>

                  </div>
                  <div class='both-buttons'>
                    <div class="back-button-wrapper">
                      <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                        v-if="!uiElements.step_navigation.back_button.hide">
                        <v-btn buttonType='button' :style="backButtonStyle" :class="[
                          'back-btn',
                          uiElements.step_navigation.back_button.shadow ? 'elevation-1' : 'elevation-0'
                        ]">
                          <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                          {{ uiElements.step_navigation.back_button.text }}
                        </v-btn>
                      </div>
                    </div>

                    <div class="continue-button-wrapper" :class='{
                      btnFull: uiElements.step_navigation.back_button.hide,
                      btnHalf: !uiElements.step_navigation.back_button.hide
                    }'>
                      <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                        <v-btn buttonType='button' :style="continueButtonStyle" :class="[
                          'continue-btn',
                          uiElements.step_navigation.next_button.shadow ? 'elevation-1' : 'elevation-0'
                        ]">
                          {{ uiElements.step_navigation.next_button.text }}
                          <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </v-tabs-window-item>

        <!-- UI Elements -->
        <v-tabs-window-item value="ui-elements">
          <div class='theme-setting-both-sides'>
            <div class='left-side '>
              <h3 class="mb-3">UI Elements</h3>
              <v-tabs class="shadow-tab" next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline" ref="" color="secondary-red" v-model="uiElementTabs">
                <v-tab class="text-none" value="form" @click="buttonPreview(0)">Form</v-tab>
                <v-tab class="text-none" value="questions" @click="buttonPreview(0)">Questions</v-tab>
                <v-tab class="text-none" value="select" @click="buttonPreview(0)">Select/Radio</v-tab>
                <v-tab class="text-none" value="inputBox" @click="buttonPreview(0)">Input Box</v-tab>
                <v-tab class="text-none" value="choiceImage" @click="buttonPreview(0)">Choice Image/ Icon</v-tab>
                <v-tab class="text-none" value="scale" @click="buttonPreview(5)">Scale</v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <v-tabs-window v-model="uiElementTabs" type="text" class="px-2" background-color="white"
                text-color-active="black" text-color="black" indicatorColor="primary">
                <v-tabs-window-item value="form" class="pt-5">
                  <h6 class="font-weight-bold">Form</h6>
                  <v-row>
                    <v-col cols="4">
                      <color-picker v-model="uiElements.background.color" model-title="Canvas Background"
                        @colorChanged="updateUiElementsSetting($event, 'uiBackground')"></color-picker>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="uiElements.background.formColor" model-title="Form Background"
                        @colorChanged="updateUiElementsSetting($event, 'uiBackgroundForm')"></color-picker>
                    </v-col>
                    <v-col cols="4">
                      <v-switch color="secondary-red" label="Form Shadow" v-model="(uiElements.background.formShadow)"
                        @update:modelValue="updateUiElementsSetting($event, 'formShadow')">
                      </v-switch>
                    </v-col>
                  </v-row>
                  <h6 class="font-weight-bold">Form Progess Bar</h6>
                  <v-switch hide-details color="secondary-red" label="Show/Hide Form Progress Bar"
                    v-model="(uiElements.step_progress.showProgress)"
                    @update:modelValue="updateUiElementsSetting($event, 'showProgress')">
                  </v-switch>
                  <v-row class="mt-0 pt-0" v-if="uiElements.step_progress.showProgress">
                    <v-col cols="4">
                      <color-picker v-model="uiElements.step_progress.config.stroke_color"
                        model-title="Remaining Progress"
                        @colorChanged="updateUiElementsSetting($event, 'uiStrokeColor')"></color-picker>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="uiElements.step_progress.config.fill_color" model-title="Lapsed Progress"
                        @colorChanged="updateUiElementsSetting($event, 'uiFillColor')"></color-picker>
                    </v-col>
                    <v-col cols="4">
                      <v-select active variant="outlined" density="comfortable" label="Progress Bar Position"
                        :items="progressbarPosition" v-model="(uiElements.step_progress.progressPosition)"
                        @update:modelValue="updateUiElementsSetting($event, 'progressPosition')">
                      </v-select>
                    </v-col>
                    <v-col cols="4" class="mt-n10 mb-4">
                      <v-switch color="secondary-red" label="Animation" hide-details
                        v-model="(uiElements.step_progress.showAnimation)"
                        @update:modelValue="updateUiElementsSetting($event, 'showAnimation')">
                      </v-switch>
                    </v-col>
                  </v-row>
                  <h6 class="font-weight-bold">Form Borders</h6>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="4">
                      <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                        :items="borderStyle" v-model="uiElements.background.form_border_style"
                        @update:modelValue="updateUiElementsSetting($event, 'formBorderStyle')"
                        class="input-font-family">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select active hide-details variant="outlined" density="comfortable" label="Border Width"
                        :items="borderWidth" v-model="(uiElements.background.form_border_width)"
                        @update:modelValue="updateUiElementsSetting($event, 'formBorderWidth')">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field active hide-details variant="outlined" density="comfortable" label="Border Radius"
                        v-model="uiElements.background.form_border_radius"
                        @update:modelValue="updateUiElementsSetting($event, 'formBorderRadius')" type="number" :min="0"
                        :max="100">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <color-picker v-model="uiElements.background.form_border_color" model-title="Border Color"
                        @colorChanged="updateUiElementsSetting($event, 'formBorderColor')"></color-picker>
                    </v-col>
                  </v-row>
                  <h6 class="font-weight-bold mt-6">Form Internal Spacing</h6>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="4">
                      <v-text-field active hide-details variant="outlined" density="comfortable" label="Top"
                        v-model="(uiElements.background.form_padding_top)"
                        @update:modelValue="updateUiElementsSetting($event, 'formPaddingTop')" type="number" :min="0"
                        :max="100">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field active hide-details variant="outlined" density="comfortable" label="Right"
                        v-model="(uiElements.background.form_padding_right)"
                        @update:modelValue="updateUiElementsSetting($event, 'formPaddingRight')" type="number" :min="0"
                        :max="100">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field active hide-details variant="outlined" density="comfortable" label="Bottom"
                        v-model="(uiElements.background.form_padding_bottom)"
                        @update:modelValue="updateUiElementsSetting($event, 'formPaddingBottom')" type="number" :min="0"
                        :max="100">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field active variant="outlined" density="comfortable" label="Left"
                        v-model="(uiElements.background.form_padding_left)"
                        @update:modelValue="updateUiElementsSetting($event, 'formPaddingLeft')" type="number" :min="0"
                        :max="100">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-tabs-window-item>

                <v-tabs-window-item value="questions" class="pt-5">
                  <v-row>
                    <v-col cols="12" class="pb-0 mb-0">
                      <v-switch color="secondary-red" label="Required questions are indicated with an asterisk (*)"
                        hide-details v-model="(uiElements.question.asterisk_for_required)"
                        @update:modelValue="updateUiElementsSetting($event, 'uiQuesAsterisk')">
                      </v-switch>
                    </v-col>
                    <v-col cols="12" class="pt-0 mt-0">
                      <v-switch color="secondary-red" label="Hide Question Labels"
                        v-model="(uiElements.question.hide_question_labels)"
                        @update:modelValue="updateUiElementsSetting($event, 'uiQuesLabel')">
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-tabs-window-item>

                <v-tabs-window-item value="select" class="pt-5">
                  <v-tabs ref="" color="secondary-red" v-model="uiElementSelectTabs">
                    <v-tab class="text-none" value="defaultAppearance">Default Appearance</v-tab>
                    <v-tab class="text-none" value="hoverAppearance">Hover Appearance</v-tab>
                    <v-tab class="text-none" value="activeAppearance">Active Appearance</v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-window v-model="uiElementSelectTabs" type="text" background-color="white"
                    text-color-active="black" text-color="black" indicatorColor="primary">
                    <v-tabs-window-item value="defaultAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Select/Radio Borders</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle" v-model="(uiElements.radio_checkbox.default_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxDefaultBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth" :value="(uiElements.radio_checkbox.default_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxDefaultBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Border Radius" v-model="(uiElements.radio_checkbox.radius)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxRadius')" type="number"
                            :min="5" :max="200">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="mb-4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.radio_checkbox.default_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxDefaultBorderColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pt-0">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Select/Radio Font</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Font Size"
                            v-model="(uiElements.radio_checkbox.font.size)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxDefaultFontSize')"
                            type="number" :min="5" :max="200">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Text Color" v-model="uiElements.radio_checkbox.default_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxDefaultTextColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.radio_checkbox.default_style.backgroundcolor"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxDefaultBackgroundColor')"></color-picker>
                        </v-col>
                        <v-col cols="4" class="mb-4">
                          <color-picker model-title="Dropdown Color"
                            v-model="uiElements.choice.dropdown_skin.default_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxDefaultDropdownColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pt-0">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Other Options</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active variant="outlined" density="comfortable" label="Spaces Between"
                            v-model="(uiElements.radio_checkbox.margin)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxMargin')" type="number"
                            :min="5" :max="200">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active variant="outlined" density="comfortable" label="Select Button Height"
                            v-model="(uiElements.radio_checkbox.font.line_height)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxLineHeight')" type="number"
                            :min="5" :max="200">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="hoverAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle" :value="(uiElements.radio_checkbox.hover_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxHoverBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth" :value="(uiElements.radio_checkbox.hover_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxHoverBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.radio_checkbox.hover_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxHoverBorderColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4">
                        <v-col cols="4">
                          <color-picker model-title="Text Color" v-model="uiElements.radio_checkbox.hover_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxHoverTextColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color" v-model="uiElements.radio_checkbox.hover_color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxHoverBackgroundColor')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="activeAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle" :value="(uiElements.radio_checkbox.active_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxActiveBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth" :value="(uiElements.radio_checkbox.active_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'uiCheckboxActiveBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.radio_checkbox.hover_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxActiveBorderColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4">
                        <v-col cols="4">
                          <color-picker model-title="Text Color" v-model="uiElements.radio_checkbox.active_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxActiveTextColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color" v-model="uiElements.radio_checkbox.checked_color"
                            @colorChanged="updateUiElementsSetting($event, 'uiCheckboxActiveBackgroundColor')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                  </v-tabs-window>
                </v-tabs-window-item>

                <v-tabs-window-item value="inputBox" class="pt-5">
                  <v-row class="mb-2">
                    <v-col cols="4">
                      <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                        :items="borderStyle" :value="(typography.input_box.border.style)"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxBorderStyle')"
                        class="input-font-family">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                        :items="borderWidth" :value="(typography.input_box.border.width)"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxBorderWidth')">
                      </v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select active hide-details variant="outlined" density="comfortable" label="Border Skin"
                        :items="['all', 'bottom']" :value="(typography.input_box.border.skin)"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxBorderSkin')"
                        class="input-font-family">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="4">
                      <color-picker v-model="typography.input_box.border.color" model-title="Border Color"
                        @colorChanged="updateTypographySetting($event, 'inputBoxBorderColor')"></color-picker>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field active variant="outlined" density="comfortable" label="Corner Radius"
                        v-model="(typography.input_box.radius)"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxRadius')" type="number" :min="0"
                        :max="20">
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field active variant="outlined" density="comfortable" label="Text Intent"
                        v-model="(typography.input_box.font.text_intent)"
                        @update:modelValue="updateTypographySetting($event, 'inputBoxTextIntent')" type="number"
                        :min="0" :max="200">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-tabs-window-item>

                <v-tabs-window-item value="choiceImage" class="pt-5">
                  <v-tabs ref="" color="secondary-red" v-model="uiElementChoiceImageTabs">
                    <v-tab class="text-none" value="defaultAppearance">Default Appearance</v-tab>
                    <v-tab class="text-none" value="hoverAppearance">Hover Appearance</v-tab>
                    <v-tab class="text-none" value="activeAppearance">Active Appearance</v-tab>
                  </v-tabs>
                  <v-tabs-window v-model="uiElementChoiceImageTabs" type="text" background-color="white"
                    text-color-active="black" text-color="black" indicatorColor="primary" class="pt-5">
                    <v-tabs-window-item value="defaultAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Border Style</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle" :value="(uiElements.choice.image_icon_skin.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth" :value="(uiElements.choice.image_icon_skin.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Border Radius" v-model="(uiElements.choice.image_icon_skin.border.radius)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconBorderRadius')" type="number">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.choice.image_icon_skin.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconDefaultBorderColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Positioning</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Top"
                            v-model="(uiElements.choice.image_icon_skin.padding.top)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconPaddingTop')" type="number">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Right"
                            v-model="(uiElements.choice.image_icon_skin.padding.right)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconPaddingRight')" type="number"
                            :min="0" :max="100">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Bottom"
                            v-model="(uiElements.choice.image_icon_skin.padding.bottom)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconPaddingBottom')" type="number"
                            :min="0" :max="100">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Left"
                            v-model="(uiElements.choice.image_icon_skin.padding.left)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconPaddingLeft')" type="number"
                            :min="0" :max="100">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Title Style</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete active hide-details variant="outlined" density="comfortable" label="Typeface"
                            :items="fontFamily" :hasSearch=true
                            :model-value="(uiElements.choice.image_icon_skin.title_font.family)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultTitleFontFamily')"
                            class="input-font-family">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Font Size"
                            :items="fontSize" :value="(uiElements.choice.image_icon_skin.title_font.size)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultTitleFontSize')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Weight"
                            :items="fontWeight" :value="(uiElements.choice.image_icon_skin.title_font.weight)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultTitleFontWeight')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Line Height"
                            :items="lineHeight" :hasSearch=true
                            :value="(uiElements.choice.image_icon_skin.title_font.line_height)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultTitleFontLineHeight')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4" class="mt-3 pt-0">
                          <color-picker v-model="uiElements.choice.image_icon_skin.title_font.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconDefaultTitleFontColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Description Style</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete active hide-details variant="outlined" density="comfortable" label="Typeface"
                            :items="fontFamily" :hasSearch=true
                            :model-value="(uiElements.choice.image_icon_skin.description_font.family)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontFamily')"
                            class="input-font-family">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Font Size"
                            :items="fontSize" :value="(uiElements.choice.image_icon_skin.description_font.size)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontSize')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Weight"
                            :items="fontWeight" :value="(uiElements.choice.image_icon_skin.description_font.weight)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontWeight')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Line Height"
                            :items="lineHeight" :hasSearch=true
                            :value="(uiElements.choice.image_icon_skin.description_font.line_height)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontLineHeight')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4" class="mt-3 pt-0">
                          <color-picker v-model="uiElements.choice.image_icon_skin.description_font.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Icon/Image Style</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Icon Size"
                            v-model="(uiElements.choice.image_icon_skin.icon_size)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultIconSize')"
                            type="number">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Image Width"
                            v-model="(uiElements.choice.image_icon_skin.image_width)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultImageWidth')"
                            type="number">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Icon Color" v-model="uiElements.choice.image_icon_skin.icon_color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconDefaultIconColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Tickbox Color"
                            v-model="uiElements.choice.image_icon_skin.tickbox.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconDefaultTickboxColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Other Options</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Box Width"
                            v-model="(uiElements.choice.image_icon_skin.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultWidth')" type="number">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Box Height"
                            v-model="(uiElements.choice.image_icon_skin.height)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultHeight')"
                            type="number">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="mt-0">
                            <color-picker model-title="Background Color"
                              v-model="uiElements.choice.image_icon_skin.background_color"
                              @colorChanged="updateUiElementsSetting($event, 'imageIconDefaultBackgroundColor')"></color-picker>
                          </v-col>
                        <v-row class="mb-4 pt-0 ml-1">
                          <v-col cols="4">
                            <v-switch color="secondary-red" label="Box Shadow" hide-details
                              v-model="(uiElements.choice.image_icon_skin.shadow)"
                              @update:modelValue="updateUiElementsSetting($event, 'imageIconDefaultShadow')">
                            </v-switch>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="hoverAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle" :value="(uiElements.choice.image_icon_skin.hover_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconHoverBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth" :value="(uiElements.choice.image_icon_skin.hover_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconHoverBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.choice.image_icon_skin.hover_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconHoverBorderColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pa-0">
                        <v-col cols="4">
                          <color-picker model-title="Title Color"
                            v-model="uiElements.choice.image_icon_skin.hover_style.title_font.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconHoverTitleColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Description Color"
                            v-model="uiElements.choice.image_icon_skin.hover_style.description_font.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconHoverDesColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Icon Color"
                            v-model="uiElements.choice.image_icon_skin.hover_style.icon_color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconHoverIconColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pa-0">
                        <v-col cols="4">
                          <color-picker model-title="Box Background Color"
                            v-model="uiElements.choice.image_icon_skin.hover_style.background_color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconHoverBgColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <v-switch color="secondary-red" label="Box Shadow"
                            v-model="(uiElements.choice.image_icon_skin.hover_style.shadow)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconHoverShadow')">
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="activeAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle" :value="(uiElements.choice.image_icon_skin.active_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconActiveBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth" :value="(uiElements.choice.image_icon_skin.active_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconActiveBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.choice.image_icon_skin.active_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconActiveBorderColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pa-0">
                        <v-col cols="4">
                          <color-picker model-title="Title Color"
                            v-model="uiElements.choice.image_icon_skin.active_style.title_font.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconActiveTitleColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Description Color"
                            v-model="uiElements.choice.image_icon_skin.active_style.description_font.color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconActiveDesColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Icon Color"
                            v-model="uiElements.choice.image_icon_skin.active_style.icon_color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconActiveIconColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pa-0">
                        <v-col cols="4">
                          <color-picker model-title="Box Background Color"
                            v-model="uiElements.choice.image_icon_skin.active_style.background_color"
                            @colorChanged="updateUiElementsSetting($event, 'imageIconActiveBgColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <v-switch color="secondary-red" label="Box Shadow"
                            v-model="(uiElements.choice.image_icon_skin.active_style.shadow)"
                            @update:modelValue="updateUiElementsSetting($event, 'imageIconActiveShadow')">
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                  </v-tabs-window>
                </v-tabs-window-item>

                <v-tabs-window-item value="scale" class="pt-5">
                  <v-tabs ref="" color="secondary-red" v-model="uiElementScaleTabs">
                    <v-tab class="text-none" value="defaultAppearance">Default Appearance</v-tab>
                    <v-tab class="text-none" value="hoverAppearance">Hover Appearance</v-tab>
                    <v-tab class="text-none" value="activeAppearance">Active Appearance</v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-window v-model="uiElementScaleTabs" type="text" background-color="white"
                    text-color-active="black" text-color="black" indicatorColor="primary">
                    <v-tabs-window-item value="defaultAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.config.fill_color" model-title="Selected Scale"
                            @colorChanged="updateUiElementsSetting($event, 'scaleUiFillColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.config.stroke_color" model-title="Remaining Scale"
                            @colorChanged="updateUiElementsSetting($event, 'scaleUiStrokeColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.border_color" model-title="Border Color"
                            @colorChanged="updateUiElementsSetting($event, 'scaleBorderColor')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.selector_color" model-title="Selector Color"
                            @colorChanged="updateUiElementsSetting($event, 'scaleSelectorColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.smileys_color" model-title="Smileys Icon Color"
                            @colorChanged="updateUiElementsSetting($event, 'scaleSmileysColor')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.smileys_text_color" model-title="Smileys Text Color"
                            @colorChanged="updateUiElementsSetting($event, 'scaleSmileysTextColor')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="hoverAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.hover_style.smileys_hover_color"
                            model-title="Smileys Hover Color"
                            @colorChanged="updateUiElementsSetting($event, 'scaleSmileysHoverColor')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="activeAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.active_style.smileys_active_color"
                            @colorChanged="updateUiElementsSetting($event, 'scaleSmileysActiveColor')"
                            model-title="Smileys Active Color"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker v-model="uiElements.scale.active_style.smileys_active_text_color"
                            @colorChanged="updateUiElementsSetting($event, 'scaleSmileysActiveTextColor')"
                            model-title="Smileys Text Active Color"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                  </v-tabs-window>
                </v-tabs-window-item>
              </v-tabs-window>
            </div>

            <div class='right-side'>
              <div class='preview'>Preview</div>
              <div v-if="this.btnPreviewFlag == 5" class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">
                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    What is your (approximate) monthly marketing budget?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <div class="scale-slider">
                    <div class="counter">
                      <div class="counter-value">${{ parseInt(slider) }}</div>
                    </div>
                    <v-slider style="margin-bottom: 30px;" :track-color="theme.ui_elements.scale.config.stroke_color"
                      :color="theme.ui_elements.scale.config.fill_color"
                      :thumb-color="theme.ui_elements.scale.selector_color" class="slider" thumb-size="28" :min="1000"
                      :max="10000" v-model="slider"></v-slider>
                  </div>
                  <div class="continue-button-wrapper" :class='{
                    btnFull: uiElements.step_navigation.back_button.hide,
                    btnHalf: !uiElements.step_navigation.back_button.hide
                  }'>
                    <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                      <v-btn buttonType='button' :style="continueButtonStyle" :class="[
                        'continue-btn',
                        uiElements.step_navigation.next_button.shadow ? 'elevation-1' : 'elevation-0'
                      ]">
                        {{ uiElements.step_navigation.next_button.text }}
                        <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="this.btnPreviewFlag == 0" class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">
                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">Question
                  </div>
                  <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_description.font.color,
                    fontSize: typography.question_description.font.size + 'px',
                    fontFamily: typography.question_description.font.family,
                    fontWeight: typography.question_description.font.weight,
                    lineHeight: typography.question_description.font.line_height + 'px',
                    textAlign: typography.question_description.text_align
                  }"> This is the question description.
                  </div>
                  <input type="text" class='activeone' value='question answer' :style="{
                    textAlign: typography.input_box.text_align
                  }">
                  <div class='required' :style="{ color: general.colors.warning_color }"> This field is required </div>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    Which Platform(s) will the app will be build on ?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <ul class="radio-list">
                    <li v-for="(choice, cindex) in choices" :key="cindex">
                      <input name='question1' type="radio">
                      <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                    </li>
                  </ul>

                  <div class='gdpr'>
                    <div :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }"> GDPR Marketing Permissions
                    </div>
                    <div class='grdp-details' :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> We will use the information you provide
                      on this form to be in touch with you and to provide updates and marketing. Please let us know all
                      the ways you like to hear from us.
                    </div>
                    <div class="gdpr-terms">
                      <v-checkbox hide-details label="Email" class="mb-n4"></v-checkbox>
                      <v-checkbox hide-details label="Direct Post" class="mb-n4"></v-checkbox>
                      <v-checkbox hide-details label="Customized online advertising"></v-checkbox>
                    </div>
                    <p :style='{ color: theme.general.colors.text_color }'><small>We use <strong>LeadGen</strong> as our
                        marketing automation platform. By submitting this form, you acknowledge that the information you
                        provide will be transferred to <strong>LeadGen</strong>.</small></p>

                  </div>
                  <div class='both-buttons'>
                    <div class="back-button-wrapper">
                      <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                        v-if="!uiElements.step_navigation.back_button.hide">
                        <v-btn buttonType='button' :style="backButtonStyle" :class="[
                          'back-btn',
                          uiElements.step_navigation.back_button.shadow ? 'elevation-1' : 'elevation-0'
                        ]">
                          <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                          {{ uiElements.step_navigation.back_button.text }}
                        </v-btn>
                      </div>
                    </div>

                    <div class="continue-button-wrapper" :class='{
                      btnFull: uiElements.step_navigation.back_button.hide,
                      btnHalf: !uiElements.step_navigation.back_button.hide
                    }'>
                      <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                        <v-btn buttonType='button' :style="continueButtonStyle" :class="[
                          'continue-btn',
                          uiElements.step_navigation.next_button.shadow ? 'elevation-1' : 'elevation-0'
                        ]">
                          {{ uiElements.step_navigation.next_button.text }}
                          <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </v-tabs-window-item>

        <!-- Step Navigation -->
        <v-tabs-window-item value="step-navigation" id="step_navigation">
          <div class='theme-setting-both-sides'>
            <div class='left-side '>
              <h3 class="mb-3">Step Navigation</h3>
              <v-tabs class="shadow-tab" ref="" color="secondary-red" v-model="stepNavigationTabs">
                <v-tab class="text-none" value="backButton" @click="buttonPreview(0)">Back Button</v-tab>
                <v-tab class="text-none" value="continueButton" @click="buttonPreview(0)">Continue Button</v-tab>
                <v-tab class="text-none" value="submitButton" @click="buttonPreview(3)">Submit Button</v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <v-tabs-window v-model="stepNavigationTabs" type="text" style="width:90%" background-color="white"
                text-color-active="black" text-color="black" indicatorColor="primary" class="pt-5">
                <v-tabs-window-item value="backButton">
                  <v-tabs ref="" color="secondary-red" v-model="stepNavigationBackTabs">
                    <v-tab class="text-none" value="defaultAppearance">Default Appearance</v-tab>
                    <v-tab class="text-none" value="hoverAppearance">Hover Appearance</v-tab>
                    <v-tab class="text-none" value="activeAppearance">Active Appearance</v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-window v-model="stepNavigationBackTabs" type="text" background-color="white"
                    text-color-active="black" text-color="black" indicatorColor="primary" class="pt-5">
                    <v-tabs-window-item value="defaultAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="12" class="my-0 py-0">
                          <h6 class="font-weight-bold">Button Style</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Button Text"
                            v-model="uiElements.step_navigation.back_button.text"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackText')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <SelectIcons active hide-details variant="outlined" density="comfortable" label="Icon Name"
                            :items="allIcons" item-title="label" v-if="renderIcons"
                            v-model="uiElements.step_navigation.back_button.icon" placeholder="Select the Icon">
                          </SelectIcons>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Button Width In %" type="number" :min="30" :max="100"
                            v-model="uiElements.step_navigation.back_button.width"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackWidth')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Button Height" type="number" :min="30" :max="100"
                            v-model="uiElements.step_navigation.back_button.font.height"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackHeight')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable"
                            label="Button Alignment" :items="buttonAlignment"
                            :value="uiElements.step_navigation.back_button.alignment"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackAlignment')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-switch hide-details density="comfortable" label="Hide Back Button" color="secondary-red"
                            v-model="uiElements.step_navigation.back_button.hide"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackHide')">
                          </v-switch>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0 mt-6">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Button Text</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete active hide-details variant="outlined" density="comfortable" label="Typeface"
                            :items="fontFamily" :model-value="uiElements.step_navigation.back_button.font.family"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackFamily')"
                            class="input-font-family">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Font Size"
                            :items="fontSize" :value="uiElements.step_navigation.back_button.font.size"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackSize')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Font Weight"
                            :items="fontWeight" :value="uiElements.step_navigation.back_button.font.weight"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackWeight')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Font Color"
                            v-model="uiElements.step_navigation.back_button.font.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavBackColor')">
                          </color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0 my-6">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Border &amp; Other Options</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Corner Radius" type="number" :min="0" :max="20"
                            v-model="uiElements.step_navigation.back_button.borderRadius"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackRadius')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="uiElements.step_navigation.back_button.default_style.border.style"
                            @update:modelValue="updateUiElementsSetting($event, 'backBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="uiElements.step_navigation.back_button.default_style.border.width"
                            @update:modelValue="updateUiElementsSetting($event, 'backBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.back_button.default_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'backBorderColor')">
                          </color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.back_button.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavBackBackgroundColor')">
                          </color-picker>
                        </v-col>
                        <v-col cols="4">
                          <v-switch active hide-details variant="outlined" density="comfortable" label="Shadow"
                            color="secondary-red" v-model="uiElements.step_navigation.back_button.shadow"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavBackShadow')">
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="hoverAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="(uiElements.step_navigation.back_button.hover_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'backBorderStyleHover')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="(uiElements.step_navigation.back_button.hover_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'backBorderWidthHover')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.back_button.hover_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'backBorderColorHover')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="4">
                          <color-picker v-model="uiElements.step_navigation.back_button.hover_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavBackColorHover')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.back_button.hover_style.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavBackBgColorHover')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="activeAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="(uiElements.step_navigation.back_button.active_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'backBorderStyleActive')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="(uiElements.step_navigation.back_button.active_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'backBorderWidthActive')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.back_button.active_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'backBorderColorActive')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="4">
                          <color-picker v-model="uiElements.step_navigation.back_button.active_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavBackColorActive')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.back_button.active_style.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavBackBgColorActive')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                  </v-tabs-window>
                </v-tabs-window-item>
                <v-tabs-window-item value="continueButton">
                  <v-tabs ref="" color="secondary-red" v-model="stepNavigationContinueTabs">
                    <v-tab class="text-none" value="defaultAppearance">Default Appearance</v-tab>
                    <v-tab class="text-none" value="hoverAppearance">Hover Appearance</v-tab>
                    <v-tab class="text-none" value="activeAppearance">Active Appearance</v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-window v-model="stepNavigationContinueTabs" type="text" background-color="white"
                    text-color-active="black" text-color="black" indicatorColor="primary" class="pt-5">
                    <v-tabs-window-item value="defaultAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="12" class="my-0 py-0">
                          <h6 class="font-weight-bold">Button Style</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Button Text"
                            v-model="uiElements.step_navigation.next_button.text"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextText')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <SelectIcons active hide-details variant="outlined" density="comfortable" label="Icon Name"
                            :items="allIcons" item-title="label" v-if="renderIcons"
                            v-model="uiElements.step_navigation.next_button.icon" placeholder="Select the Icon">
                          </SelectIcons>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Button Width In %" type="number" :min="30" :max="100"
                            v-model="uiElements.step_navigation.next_button.width"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextWidth')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Button Height" type="number" :min="30" :max="100"
                            v-model="uiElements.step_navigation.next_button.font.height"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextHeight')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable"
                            label="Button Alignment" :items="buttonAlignment"
                            :value="uiElements.step_navigation.next_button.alignment"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextAlignment')">
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0 mt-6">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Button Text</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete active hide-details variant="outlined" density="comfortable" label="Typeface"
                            :items="fontFamily" :model-value="uiElements.step_navigation.next_button.font.family"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextFamily')"
                            class="input-font-family">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Font Size"
                            :items="fontSize" :value="uiElements.step_navigation.next_button.font.size"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextSize')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Font Weight"
                            :items="fontWeight" :value="uiElements.step_navigation.next_button.font.weight"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextWeight')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Font Color"
                            v-model="uiElements.step_navigation.next_button.font.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavNextColor')">
                          </color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0 mt-6">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Border &amp; Other Options</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Corner Radius" type="number" :min="0" :max="20"
                            v-model="uiElements.step_navigation.next_button.borderRadius"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextRadius')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="uiElements.step_navigation.next_button.default_style.border.style"
                            @update:modelValue="updateUiElementsSetting($event, 'nextBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="uiElements.step_navigation.next_button.default_style.border.width"
                            @update:modelValue="updateUiElementsSetting($event, 'nextBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.next_button.default_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'nextBorderColor')">
                          </color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.next_button.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavNextBackgroundColor')">
                          </color-picker>
                        </v-col>
                        <v-col cols="4">
                          <v-switch active variant="outlined" density="comfortable" label="Shadow" color="secondary-red"
                            v-model="uiElements.step_navigation.next_button.shadow"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextShadow')">
                          </v-switch>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0 mt-0">
                        <v-col cols="12" class="mt-n4 pt-0 ml-2">
                          <v-switch color="secondary-red" label="Continue Button Visible (Auto Navigation)"
                            :model-value="!uiElements.step_navigation.next_button.hidden"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavNextVisible')">
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="hoverAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="(uiElements.step_navigation.next_button.hover_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'nextBorderStyleHover')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="(uiElements.step_navigation.next_button.hover_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'nextBorderWidthHover')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.next_button.hover_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'nextBorderColorHover')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="4">
                          <color-picker v-model="uiElements.step_navigation.next_button.hover_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavNextColorHover')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.next_button.hover_style.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavNextBgColorHover')">
                          </color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="activeAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="(uiElements.step_navigation.next_button.active_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'nextBorderStyleActive')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="(uiElements.step_navigation.next_button.active_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'nextBorderWidthActive')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.next_button.active_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'nextBorderColorActive')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="4">
                          <color-picker v-model="uiElements.step_navigation.next_button.active_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavNextColorActive')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.next_button.active_style.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavNextBgColorActive')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                  </v-tabs-window>
                </v-tabs-window-item>
                <v-tabs-window-item value="submitButton">
                  <v-tabs ref="" color="secondary-red" v-model="stepNavigationSubmitTabs">
                    <v-tab class="text-none" value="defaultAppearance">Default Appearance</v-tab>
                    <v-tab class="text-none" value="hoverAppearance">Hover Appearance</v-tab>
                    <v-tab class="text-none" value="activeAppearance">Active Appearance</v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-window v-model="stepNavigationSubmitTabs" type="text" background-color="white"
                    text-color-active="black" text-color="black" indicatorColor="primary" class="pt-5">
                    <v-tabs-window-item value="defaultAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="12" class="my-0 py-0">
                          <h6 class="font-weight-bold">Button Style</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable" label="Button Text"
                            v-model="uiElements.step_navigation.submit_button.text"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitText')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <SelectIcons active hide-details variant="outlined" density="comfortable" label="Icon Name"
                            :items="allIcons" item-title="label" v-if="renderIcons"
                            v-model="uiElements.step_navigation.submit_button.icon" placeholder="Select the Icon">
                          </SelectIcons>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Button Width In %" type="number" :min="30" :max="100"
                            v-model="uiElements.step_navigation.submit_button.width"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitWidth')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Button Height" type="number" :min="30" :max="100"
                            v-model="uiElements.step_navigation.submit_button.font.height"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitHeight')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable"
                            label="Button Alignment" :items="buttonAlignment"
                            :value="uiElements.step_navigation.submit_button.alignment"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitAlignment')">
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0 mt-6">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Button Text</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete active hide-details variant="outlined" density="comfortable" label="Typeface"
                            :items="fontFamily" :model-value="uiElements.step_navigation.submit_button.font.family"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitFamily')"
                            class="input-font-family">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Font Size"
                            :items="fontSize" :value="uiElements.step_navigation.submit_button.font.size"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitSize')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Font Weight"
                            :items="fontWeight" :value="uiElements.step_navigation.submit_button.font.weight"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitWeight')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Font Color"
                            v-model="uiElements.step_navigation.submit_button.font.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavSubmitColor')">
                          </color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0 mt-6">
                        <v-col cols="12" class="mb-0 pb-0">
                          <h6 class="font-weight-bold">Border &amp; Other Options</h6>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field active hide-details variant="outlined" density="comfortable"
                            label="Corner Radius" type="number" :min="0" :max="20"
                            v-model="uiElements.step_navigation.submit_button.borderRadius"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitRadius')">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="uiElements.step_navigation.submit_button.default_style.border.style"
                            @update:modelValue="updateUiElementsSetting($event, 'submitBorderStyle')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="uiElements.step_navigation.submit_button.default_style.border.width"
                            @update:modelValue="updateUiElementsSetting($event, 'submitBorderWidth')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.submit_button.default_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'submitBorderColor')">
                          </color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.submit_button.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavSubmitBackgroundColor')">
                          </color-picker>
                        </v-col>
                        <v-col cols="4">
                          <v-switch active variant="outlined" density="comfortable" label="Shadow" color="secondary-red"
                            v-model="uiElements.step_navigation.submit_button.shadow"
                            @update:modelValue="updateUiElementsSetting($event, 'uiStepNavSubmitShadow')">
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="hoverAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="(uiElements.step_navigation.submit_button.hover_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'submitBorderStyleHover')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="(uiElements.step_navigation.submit_button.hover_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'submitBorderWidthHover')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.submit_button.hover_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'submitBorderColorHover')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="4">
                          <color-picker v-model="uiElements.step_navigation.submit_button.hover_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavSubmitColorHover')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.submit_button.hover_style.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavSubmitBgColorHover')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                    <v-tabs-window-item value="activeAppearance" class="pt-5">
                      <v-row>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Style"
                            :items="borderStyle"
                            :value="(uiElements.step_navigation.submit_button.active_style.border.style)"
                            @update:modelValue="updateUiElementsSetting($event, 'submitBorderStyleActive')"
                            class="input-font-family">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-select active hide-details variant="outlined" density="comfortable" label="Border Stroke"
                            :items="borderWidth"
                            :value="(uiElements.step_navigation.submit_button.active_style.border.width)"
                            @update:modelValue="updateUiElementsSetting($event, 'submitBorderWidthActive')">
                          </v-select>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Border Color"
                            v-model="uiElements.step_navigation.submit_button.active_style.border.color"
                            @colorChanged="updateUiElementsSetting($event, 'submitBorderColorActive')"></color-picker>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 pt-0">
                        <v-col cols="4">
                          <color-picker v-model="uiElements.step_navigation.submit_button.active_style.color"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavSubmitColorActive')"></color-picker>
                        </v-col>
                        <v-col cols="4">
                          <color-picker model-title="Background Color"
                            v-model="uiElements.step_navigation.submit_button.active_style.backgroundColor"
                            @colorChanged="updateUiElementsSetting($event, 'uiStepNavSubmitBgColorActive')"></color-picker>
                        </v-col>
                      </v-row>
                    </v-tabs-window-item>
                  </v-tabs-window>
                </v-tabs-window-item>
              </v-tabs-window>
            </div>

            <div class='right-side'>
              <div class='preview'>Preview</div>

              <div v-if="this.btnPreviewFlag == 0 || this.btnPreviewFlag == 3" class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">

                <div v-if="this.btnPreviewFlag == 0" class='preview-btns'>

                  <div class='form-demo' :style="formDemoStyle"
                    :class='{ formShadow: uiElements.background.formShadow }'>
                    <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">Question
                    </div>
                    <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> This is the question description.
                    </div>
                    <input type="text" class='activeone' value='question answer'
                      :style="{ textAlign: typography.input_box.text_align }">
                    <div class='required' :style="{ color: general.colors.warning_color }"> This field is required
                    </div>
                    <div class='question2' :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">
                      Which Platform(s) will the app will be build on ?<span
                        v-if='uiElements.question.asterisk_for_required'>
                        *</span>
                    </div>
                    <ul class="radio-list">
                      <li v-for="(choice, cindex) in choices" :key="cindex">
                        <input name='question1' type="radio">
                        <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                      </li>
                    </ul>


                    <div class='both-buttons'>
                      <div class="back-button-wrapper">
                        <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                          v-if="!uiElements.step_navigation.back_button.hide">
                          <v-btn buttonType='button' :style="backButtonStyle" :class="[
                            'back-btn',
                            uiElements.step_navigation.back_button.shadow ? 'elevation-1' : 'elevation-0'
                          ]">
                            <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                            {{ uiElements.step_navigation.back_button.text }}
                          </v-btn>
                        </div>
                      </div>

                      <div class="continue-button-wrapper">
                        <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                          <v-btn buttonType='button' :style="continueButtonStyle" :class="[
                            'continue-btn',
                            uiElements.step_navigation.next_button.shadow ? 'elevation-1' : 'elevation-0'
                          ]">
                            {{ uiElements.step_navigation.next_button.text }}
                            <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="this.btnPreviewFlag == 3" class='preview-btns'>
                  <div class='form-demo' :style="formDemoStyle"
                    :class='{ formShadow: uiElements.background.formShadow }'>
                    <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">Question
                    </div>
                    <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> This is the question description.
                    </div>
                    <input type="text" class='activeone' value='question answer'
                      :style="{ textAlign: typography.input_box.text_align }">
                    <div class='required' :style="{ color: general.colors.warning_color }"> This field is required
                    </div>
                    <div class='question2' :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">
                      Which Platform(s) will the app will be build on ?<span
                        v-if='uiElements.question.asterisk_for_required'>
                        *</span>
                    </div>
                    <ul class="radio-list">
                      <li v-for="(choice, cindex) in choices" :key="cindex">
                        <input name='question1' type="radio">
                        <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                      </li>
                    </ul>

                    <div class='both-buttons'>
                      <div class="back-button-wrapper">
                        <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                          v-if="!uiElements.step_navigation.back_button.hide">
                          <v-btn buttonType='button' :style="backButtonStyle" :class="[
                            'back-btn',
                            uiElements.step_navigation.back_button.shadow ? 'elevation-1' : 'elevation-0'
                          ]">
                            <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                            {{ uiElements.step_navigation.back_button.text }}
                          </v-btn>
                        </div>
                      </div>

                      <div class="submit-button-wrapper" :class='{
                        btnFull: uiElements.step_navigation.back_button.hide,
                        btnHalf: !uiElements.step_navigation.back_button.hide
                      }'>
                        <div :style="'width:' + uiElements.step_navigation.submit_button.width + '%'">
                          <v-btn buttonType='button' :style="submitButtonStyle" :class="[
                            'submit-btn',
                            uiElements.step_navigation.submit_button.shadow ? 'elevation-1' : 'elevation-0'
                          ]">
                            {{ uiElements.step_navigation.submit_button.text }}
                            <i class="material-icons">{{ uiElements.step_navigation.submit_button.icon }}</i>
                          </v-btn>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </v-tabs-window-item>
      </v-tabs-window>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as actionTypes from '@/store/action-types'
//import { Chrome } from 'vue-color'
import icons from '@/store/icons'
import googleFonts from '@/store/google-fonts'
import userMixin from '@/mixins/user'
import FormThemeStore from '@/components/FormVariantTheme/components/FormThemeStore'
import ColorPicker from '../ColorPicker.vue'
import SelectIcons from '../SelectIcons.vue'

export default {
  props: ['color'],
  mixins: [userMixin],
  data() {
    return {
      slider: 5000,
      formId: '',
      variantId: '',
      fontFamily: ['Lato', 'Open Sans', 'Roboto', 'Raleway', 'PT Serif', 'Ubuntu', 'Montserrat', 'Oswald', 'PT Sans', 'Poppins', 'Muli', 'Oxygen', 'Lobster', 'Teko', 'Jura', 'Karma', 'Nunito Sans', 'Prompt', 'Work Sans', 'Neuton', 'Cabin', 'Arvo', 'Quicksand', 'BioRhyme', 'Trirong', 'Lemonada', 'Bai Jamjuree', 'Mali', 'Krub', 'KoHo', 'Fahkwang', 'Kodchasan', 'Cormorant Infant', 'Infant', 'Maitree', 'Laila', 'Martel Sans', 'Saira', 'Cormorant', 'Changa', 'Zilla Slab', 'Martel'],
      typographyOptions: ['Question title', 'Question description'],
      raised: true,
      fontWeight: ['300', '400', '700'],
      fontSize: ['5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '16', '18', '20', '24', '26', '28', '30', '32', '34', '36', '38', '40', '42'],
      lineHeight: ['9', '10', '11', '12', '13', '14', '16', '18', '20', '24', '36', '48'],
      uiOptions: ['Background', 'Questions', 'Step Navigation'],
      borderStyle: ['None', 'Solid', 'Dotted', 'Dashed', 'Inset', 'Outset'],
      borderWidth: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      progressbarPosition: ['Top', 'Bottom'],
      menuSetTypo: true,
      menuSetUi: true,
      flag: 0,
      choices: ['Android', 'Windows'],
      customStyle: '',
      gdpr: [],
      btnPreviewFlag: 0,
      next: 40,
      back: 30,
      buttonAlignment: ['left', 'right', 'center'],
      renderIcons: false,
      chromePickers: [],
      chromePickersCount: 82,
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      publishingTheme: false,
      displayPicker: false,
      fontsLoaded: [],
      pageTabs: "themes",
      uiElementTabs: "form",
      typographyTabs: "questionTitle",
      stepNavigationTabs: "backButton",
      stepNavigationBackTabs: "defaultAppearance",
      stepNavigationContinueTabs: "defaultAppearance",
      stepNavigationSubmitTabs: "defaultAppearance",
      uiElementChoiceImageTabs: "defaultAppearance",
      uiElementScaleTabs: "defaultAppearance",
      uiElementSelectTabs: "defaultAppearance",
    }
  },
  components: {
    //'chrome-picker': Chrome,
    'form-theme-store': FormThemeStore,
    ColorPicker,
    SelectIcons
  },
  mounted: function () {
    for (let i = 0; i < this.chromePickersCount; i++) {
      this.chromePickers.push({
        id: i,
        visible: false
      })
    }
    this.addStyle()
    this.fontFamily = this.fontFamily.sort()
    setTimeout(() => {
      this.renderIcons = true
    }, 1000)
  },
  methods: {
    buttonPreview: function (x) {
      this.btnPreviewFlag = x
    },
    choiceStyle: function () {
      // scale
      this.customStyle += `#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .scale-slider .slider .v-slider-track {
        height: 10px !important;
      }`
      this.customStyle += `#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .scale-slider .slider .v-slider-track__background {
        height: 10px !important;
      }`
      // check boxes
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .gdpr-terms .ui-checkbox--color-primary.is-checked .ui-checkbox__checkmark::before {' + 'background-color:' + this.theme.ui_elements.radio_checkbox.checked_color + '!important;' + 'border-color:' + this.theme.ui_elements.radio_checkbox.checked_color + '!important}'
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .gdpr-terms .ui-checkbox.is-checked .ui-checkbox__checkmark::after { border-color:' + this.theme.ui_elements.radio_checkbox.active_style.color + '!important}'
      // Font Family
      this.customStyle += '.radio-list li label.checkbox-label{' + 'font-family:' + this.theme.general.font.family + ';color:' + this.theme.general.colors.text_color + '}'
      // Input box
      this.customStyle += `
        #main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .activeone {
          border: 0;
          ${this.theme.typography.input_box.border.skin === 'all' ? 'border' : 'border-bottom'}: ${this.theme.typography.input_box.border.width}px ${this.theme.typography.input_box.border.style} ${this.theme.typography.input_box.border.color};
          box-shadow: none;
          color: ${this.theme.typography.input_box.font.color};
          text-indent: ${this.theme.typography.input_box.font.text_intent}px;
          font-family: ${this.theme.typography.input_box.font.family};
          font-size: ${this.theme.typography.input_box.font.font_size}px;
          background-color: ${this.theme.typography.input_box.font.background_color};
          border-radius: ${this.theme.typography.input_box.radius}px;
          height: ${this.theme.typography.input_box.font.height}px;
        }
        #main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .activeone:hover,
        #main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .activeone:focus {
          border: 0;
          ${this.theme.typography.input_box.border.skin === 'all' ? 'border' : 'border-bottom'}: ${this.theme.typography.input_box.border.width}px ${this.theme.typography.input_box.border.style} ${this.theme.general.colors.active_color};
        }
        `
      // GDPR Options
      this.customStyle += '.right-side .form-demo .ui-checkbox__label-text { font-family:' + this.theme.general.font.family + ';color:' + this.theme.general.colors.text_color + '}'
      // Question option text color
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li label.checkbox-label { color:' + this.theme.general.colors.text_color + '}'
      // Margin
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li { margin-bottom:' + this.theme.ui_elements.radio_checkbox.margin + 'px; }'
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .question2{ margin-top:' + this.theme.typography.input_box.font.spacing + 'px; }'
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .gdpr{ margin-top:' + this.theme.typography.input_box.font.spacing + 'px; }'
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li:last-child { margin-bottom: 0px !important; }'
      // Border radius checkbox/radio
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li label.checkbox-label { border-radius:' + this.theme.ui_elements.radio_checkbox.radius + 'px; }'
      // default style checkbox/radio
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li label.checkbox-label { border:' +
        this.theme.ui_elements.radio_checkbox.default_style.border.width + 'px' + ' ' + this.theme.ui_elements.radio_checkbox.default_style.border.style + this.theme.ui_elements.radio_checkbox.default_style.border.color + ';color:' + this.theme.ui_elements.radio_checkbox.default_style.color + ';background-color:' + this.theme.ui_elements.radio_checkbox.default_style.backgroundcolor + ';font-family:' +
        this.theme.general.font.family + ';font-size:' + this.theme.ui_elements.radio_checkbox.font.size + 'px;' + 'line-height:' + this.theme.ui_elements.radio_checkbox.font.line_height + 'px;' + '}'
      // hover border checkbox/radio
      if (this.theme.ui_elements.radio_checkbox.hover_style.border.width === '0' && this.theme.ui_elements.radio_checkbox.default_style.border.width) {
        this.customStyle += 'input[type="checkbox"]:hover + label,  input[type="radio"]:hover + label { border:' + this.theme.ui_elements.radio_checkbox.default_style.border.width + 'px' + ' ' + this.theme.ui_elements.radio_checkbox.hover_style.border.style + ' ' + 'transparent' + '!important; color:' + this.theme.ui_elements.radio_checkbox.hover_style.color + '!important;background-color:' + this.theme.ui_elements.radio_checkbox.hover_color + '!important;font-family:' + this.theme.general.font.family + '}'
      } else {
        this.customStyle += 'input[type="checkbox"]:hover + label,  input[type="radio"]:hover + label { border:' + this.theme.ui_elements.radio_checkbox.hover_style.border.width + 'px' + ' ' + this.theme.ui_elements.radio_checkbox.hover_style.border.style + ' ' + this.theme.ui_elements.radio_checkbox.hover_style.border.color + '!important; color:' + this.theme.ui_elements.radio_checkbox.hover_style.color + '!important;background-color:' + this.theme.ui_elements.radio_checkbox.hover_color + '!important;font-family:' +
          this.theme.general.font.family + '}'
      }
      // CHECKED check boxes
      this.customStyle += 'input[type="radio"]:checked + label, input[type="checkbox"]:checked + label { border:' + this.theme.ui_elements.radio_checkbox.active_style.border.width + 'px' + ' ' + this.theme.ui_elements.radio_checkbox.active_style.border.style + ' ' + this.theme.ui_elements.radio_checkbox.active_style.border.color + '!important; color:' + this.theme.ui_elements.radio_checkbox.active_style.color + '!important;background-color:' + this.theme.ui_elements.radio_checkbox.checked_color + '!important;font-family:' +
        this.theme.general.font.family + '}'
      let temp = 'center;'
      if (this.theme.ui_elements.step_navigation.back_button.alignment === 'left') {
        temp = 'flex-start;'
      } else if (this.theme.ui_elements.step_navigation.back_button.alignment === 'right') {
        temp = 'flex-end;'
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper { justify-content:' + temp + ';width:' + this.theme.ui_elements.step_navigation.back_button.width + '%' + '}'
      temp = 'center;'
      if (this.theme.ui_elements.step_navigation.next_button.alignment === 'left') {
        temp = 'flex-start;'
      } else if (this.theme.ui_elements.step_navigation.next_button.alignment === 'right') {
        temp = 'flex-end;'
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper { justify-content:' + temp + ';width:' + this.theme.ui_elements.step_navigation.next_button.width + '%' + '}'
      temp = 'center;'
      if (this.theme.ui_elements.step_navigation.submit_button.alignment === 'left') {
        temp = 'flex-start;'
      } else if (this.theme.ui_elements.step_navigation.submit_button.alignment === 'right') {
        temp = 'flex-end;'
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper { justify-content:' + temp + ';width:' + this.theme.ui_elements.step_navigation.submit_button.width + '%' + '}'
      if (this.theme.ui_elements.step_navigation.back_button.hide) {
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper { width:0% }'
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper { width:100% }'
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper { width:100% }'
      } else {
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper { width: 48% }'
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper { width: 48% }'
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper { width: 48% }'
      }
      // back hover style
      let backgroundcolor = this.uiElements.step_navigation.back_button.backgroundColor
      if (this.uiElements.step_navigation.back_button.hover_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.back_button.hover_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper>div>.v-btn:hover{ color:' + this.uiElements.step_navigation.back_button.hover_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.back_button.hover_style.border.width + 'px ' + this.uiElements.step_navigation.back_button.hover_style.border.style + ' ' + this.uiElements.step_navigation.back_button.hover_style.border.color + '!important}'
      // back active style
      backgroundcolor = this.uiElements.step_navigation.back_button.backgroundColor
      if (this.uiElements.step_navigation.back_button.active_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.back_button.active_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper>div>.v-btn:active{ color:' + this.uiElements.step_navigation.back_button.active_style.color + '!important; background-color:' + backgroundcolor + '!important; border:' + this.uiElements.step_navigation.back_button.active_style.border.width + 'px ' + this.uiElements.step_navigation.back_button.active_style.border.style + ' ' + this.uiElements.step_navigation.back_button.active_style.border.color + '!important}'

      // next  hover style
      backgroundcolor = this.uiElements.step_navigation.next_button.backgroundColor
      if (this.uiElements.step_navigation.next_button.hover_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.next_button.hover_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper>div>.v-btn:hover{ color:' + this.uiElements.step_navigation.next_button.hover_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.next_button.hover_style.border.width + 'px ' + this.uiElements.step_navigation.next_button.hover_style.border.style + ' ' + this.uiElements.step_navigation.next_button.hover_style.border.color + '!important}'
      // next active style
      backgroundcolor = this.uiElements.step_navigation.next_button.backgroundColor
      if (this.uiElements.step_navigation.next_button.active_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.next_button.active_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper>div>.v-btn:active{ color:' + this.uiElements.step_navigation.next_button.active_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.next_button.active_style.border.width + 'px ' + this.uiElements.step_navigation.next_button.active_style.border.style + ' ' + this.uiElements.step_navigation.next_button.active_style.border.color + '!important}'
      // submit  hover style
      backgroundcolor = this.uiElements.step_navigation.submit_button.backgroundColor
      if (this.uiElements.step_navigation.submit_button.hover_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.submit_button.hover_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper>div>.v-btn:hover{ color:' + this.uiElements.step_navigation.submit_button.hover_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.submit_button.hover_style.border.width + 'px ' + this.uiElements.step_navigation.submit_button.hover_style.border.style + ' ' + this.uiElements.step_navigation.submit_button.hover_style.border.color + '!important}'
      // submit  active style
      backgroundcolor = this.uiElements.step_navigation.submit_button.backgroundColor
      if (this.uiElements.step_navigation.submit_button.active_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.submit_button.active_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper>div>.v-btn:active{ color:' + this.uiElements.step_navigation.submit_button.active_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.submit_button.active_style.border.width + 'px ' + this.uiElements.step_navigation.submit_button.active_style.border.style + ' ' + this.uiElements.step_navigation.submit_button.active_style.border.color + '!important}'
    },
    addStyle: function () {
      this.choiceStyle()
      this.googleFontFamily()
      var x = document.createElement('STYLE')
      x.id = 'formbuilder-designer-styles'
      var t = document.createTextNode(this.customStyle)
      x.appendChild(t)
      if (document.querySelector('#formbuilder-designer-styles')) {
        document.getElementById('formbuilder-designer-styles').remove()
      }
      document.head.appendChild(x)
    },
    googleFontFamily: function () {
      for (const googleFontKey in googleFonts) {
        const googleFontValue = googleFonts[googleFontKey]
        let insert = false

        if (
          googleFontValue.label === this.theme.general.font.family ||
          googleFontValue.label === this.theme.typography.question_description.font.family ||
          googleFontValue.label === this.theme.typography.question_title.font.family ||
          googleFontValue.label === this.theme.ui_elements.step_navigation.back_button.font.family ||
          googleFontValue.label === this.theme.ui_elements.step_navigation.next_button.font.family ||
          googleFontValue.label === this.theme.ui_elements.step_navigation.submit_button.font.family ||
          googleFontValue.label === this.theme.typography.input_box.font.family ||
          googleFontValue.label === this.theme.typography.text_box.family
        ) {
          insert = true
        }

        for (let theme of this.themes) {
          if (
            googleFontValue.label === theme.config.general.font.family ||
            googleFontValue.label === theme.config.ui_elements.step_navigation.back_button.font.family ||
            googleFontValue.label === theme.config.ui_elements.step_navigation.next_button.font.family ||
            googleFontValue.label === theme.config.ui_elements.step_navigation.submit_button.font.family
          ) {
            insert = true
          }
        }

        if (insert && this.fontsLoaded.indexOf(googleFontValue.link) === -1) {
          this.fontsLoaded.push(googleFontValue.link)

          var link = document.createElement('link')
          link.href = googleFontValue.link
          link.rel = 'stylesheet'
          document.head.appendChild(link)
        }
      }
    },
    hideAllChromePickers: function () {
      for (let i = 0; i < this.chromePickersCount; i++) {
        this.chromePickers[i].visible = false
      }
    },
    hexColorCodeInput: function (e) {
      if (e.slice(0, 1) === '#') {
        this.colors = {
          hex: e
        }
      } else if (e.slice(0, 4) === 'rgba') {
        var rgba = e.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        var hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)
        this.colors = {
          hex: hex,
          a: rgba[3]
        }
      }
    },
    getHexColorValue: function (e) {
      let value = e.hex
      if (e.a < 1) {
        value = `rgba(${e.rgba.r}, ${e.rgba.g}, ${e.rgba.b}, ${e.rgba.a})`
      }
      if (value === undefined) {
        this.hexColorCodeInput(e)
        return e
      }
      return value
    },
    showChromePicker: function (id) {
      if (id >= 2) {
        this.flag = 1
        this.hideAllChromePickers()
        for (let i = 0; i < this.chromePickersCount; i++) {
          if (this.chromePickers[i].id !== id) {
            continue
          }
          this.chromePickers[i].visible = true
        }
        return
      } else if (id === 1 && this.flag === 0) {
        this.hideAllChromePickers()
        this.flag = 0
        return
      }
      this.flag = 0
    },
    updateGeneralSetting: function (e, choice) {
      let general = Object.assign({}, this.general)
      let inheritFont = 'inherit'
      let defaultFont = 'Raleway'
      switch (choice) {
        case 'generalFamily':
          this.updateTypographySetting(e, 'descriptionFamily')
          this.updateTypographySetting(e, 'titleFamily')
          this.updateTypographySetting(e, 'inputBoxFamily')
          this.updateUiElementsSetting(e, 'uiStepNavNextFamily')
          this.updateUiElementsSetting(e, 'uiStepNavBackFamily')
          this.updateUiElementsSetting(e, 'uiStepNavSubmitFamily')
          this.updateTypographySetting(e, 'textBoxFamily')
          this.updateUiElementsSetting(e, 'imageIconDefaultTitleFontFamily')
          this.updateUiElementsSetting(e, 'imageIconDefaultDescriptionFontFamily')

          // if (this.typography.question_description.font.family === general.font.family) {
          //   this.updateTypographySetting(e, 'descriptionFamily')
          // }
          // if (this.typography.question_title.font.family === general.font.family) {
          //   console.log('event', e)
          //   this.updateTypographySetting(e, 'titleFamily')
          // }
          // if (this.typography.input_box.font.family === general.font.family) {
          //   this.updateTypographySetting(e, 'inputBoxFamily')
          // }
          // if (this.uiElements.step_navigation.next_button.font.family === general.font.family) {
          //   this.updateUiElementsSetting(e, 'uiStepNavNextFamily')
          // }
          // if (this.uiElements.step_navigation.back_button.font.family === general.font.family) {
          //   this.updateUiElementsSetting(e, 'uiStepNavBackFamily')
          // }
          // if (this.uiElements.step_navigation.submit_button.font.family === general.font.family) {
          //   this.updateUiElementsSetting(e, 'uiStepNavSubmitFamily')
          // }
          // if (this.typography.text_box.family === general.font.family) {
          //   this.updateTypographySetting(e, 'textBoxFamily')
          // }
          // if (this.uiElements.choice.image_icon_skin.title_font.family === general.font.family) {
          //   this.updateUiElementsSetting(e, 'imageIconDefaultTitleFontFamily')
          // }
          // if (this.uiElements.choice.image_icon_skin.description_font.family === general.font.family) {
          //   this.updateUiElementsSetting(e, 'imageIconDefaultDescriptionFontFamily')
          // }
          general.font.family = e
          break
        case 'generalActiveColor':
          this.updateUiElementsSetting(e, 'uiStepNavNextBackgroundColor')
          this.updateUiElementsSetting(e, 'scaleUiFillColor')
          this.updateUiElementsSetting(e, 'scaleBorderColor')
          this.updateUiElementsSetting(e, 'uiStepNavSubmitBackgroundColor')
          this.updateUiElementsSetting(e, 'uiCheckboxCheckedColor')
          this.updateTypographySetting(e, 'inputBoxColor')
          this.updateTypographySetting(e, 'inputBoxPlaceholderColor')
          this.updateUiElementsSetting(e, 'uiCheckboxHoverBorderColor')
          this.updateUiElementsSetting(e, 'uiCheckboxHoverBackgroundColor')
          this.updateUiElementsSetting(e, 'uiCheckboxActiveBorderColor')
          this.updateUiElementsSetting(e, 'uiCheckboxActiveBackgroundColor')

          general.colors.active_color = e
          this.flag = 1
          break
        case 'generalTextColor':
          this.updateTypographySetting(e, 'titleColor')
          this.updateTypographySetting(e, 'descriptionColor')
          // if (this.typography.question_title.font.color === general.colors.text_color) {
          //   this.updateTypographySetting(e, 'titleColor')
          // }
          // if (this.typography.question_description.font.color === general.colors.text_color) {
          //   this.updateTypographySetting(e, 'descriptionColor')
          // }
          general.colors.text_color = e

          this.flag = 1
          break
        case 'generalWarningColor':
          general.colors.warning_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'generalDynamicHeight':
          general.dynamic_height = !e
          this.flag = 1
          break
        case 'generalDynamicFadein':
          general.dynamic_fadein = e
          this.flag = 1
          break
        case 'inheritFont':
          if (general.inherit_toggle) {
            general.font.family = inheritFont
            this.updateTypographySetting(inheritFont, 'titleFamily')
            this.updateTypographySetting(inheritFont, 'descriptionFamily')
            this.updateTypographySetting(inheritFont, 'inputBoxFamily')
            this.updateUiElementsSetting(inheritFont, 'uiStepNavNextFamily')
            this.updateUiElementsSetting(inheritFont, 'uiStepNavBackFamily')
            this.updateUiElementsSetting(inheritFont, 'uiStepNavSubmitFamily')
            this.updateTypographySetting(inheritFont, 'textBoxFamily')
            this.updateUiElementsSetting(inheritFont, 'imageIconDefaultTitleFontFamily')
            this.updateUiElementsSetting(inheritFont, 'imageIconDefaultDescriptionFontFamily')
          } else {
            general.font.family = defaultFont
            this.updateTypographySetting(defaultFont, 'titleFamily')
            this.updateTypographySetting(defaultFont, 'descriptionFamily')
            this.updateTypographySetting(defaultFont, 'inputBoxFamily')
            this.updateUiElementsSetting(defaultFont, 'uiStepNavNextFamily')
            this.updateUiElementsSetting(defaultFont, 'uiStepNavBackFamily')
            this.updateUiElementsSetting(defaultFont, 'uiStepNavSubmitFamily')
            this.updateTypographySetting(defaultFont, 'textBoxFamily')
            this.updateUiElementsSetting(defaultFont, 'imageIconDefaultTitleFontFamily')
            this.updateUiElementsSetting(defaultFont, 'imageIconDefaultDescriptionFontFamily')
          }
          general.inherit_toggle = e
      }
      this.$store.dispatch(actionTypes.UPDATE_FORM_THEME_GENERAL, general)
      this.addStyle()
    },
    updateTypographySetting: function (e, choice) {
      let typography = Object.assign({}, this.typography)
      switch (choice) {
        case 'titleAlignment':
          typography.question_title.text_align = e
          break
        case 'descriptionAlignment':
          typography.question_description.text_align = e
          break
        case 'inputAlignment':
          typography.input_box.text_align = e
          break
        case 'titleFamily':
          typography.question_title.font.family = e
          break
        case 'titleLineHeight':
          typography.question_title.font.line_height = e
          break
        case 'titleFontSize':
          typography.question_title.font.size = e
          break
        case 'titleFontWeight':
          typography.question_title.font.weight = e
          break
        case 'titleColor':
          if (this.uiElements.scale.selector_color === typography.question_title.font.color) {
            this.updateUiElementsSetting(e, 'scaleSelectorColor')
          }
          typography.question_title.font.color = e
          // this.flag = 1
          break
        case 'descriptionFamily':
          typography.question_description.font.family = e
          break
        case 'descriptionLineHeight':
          typography.question_description.font.line_height = e
          break
        case 'descriptionFontSize':
          typography.question_description.font.size = e
          break
        case 'descriptionWeight':
          typography.question_description.font.weight = e
          break
        case 'descriptionColor':
          typography.question_description.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxBorderSkin':
          typography.input_box.border.skin = e
          break
        case 'inputBoxBorderStyle':
          typography.input_box.border.style = e
          break
        case 'inputBoxBorderWidth':
          typography.input_box.border.width = e
          break
        case 'inputBoxBorderColor':
          typography.input_box.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxHeight':
          typography.input_box.font.height = e
          break
        case 'inputBoxSpacing':
          typography.input_box.font.spacing = e
          break
        case 'inputBoxTextIntent':
          typography.input_box.font.text_intent = e
          break
        case 'inputBoxFamily':
          typography.input_box.font.family = e
          break
        case 'inputBoxBackground':
          typography.input_box.font.background_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxColor':
          typography.input_box.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxPlaceholderColor':
          typography.input_box.placeholder.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxFont':
          typography.input_box.font.font_size = e
          break
        case 'inputBoxRadius':
          typography.input_box.radius = e
          break
        case 'textBoxColor':
          typography.text_box.color = this.getHexColorValue(e)
          break
        case 'textBoxFamily':
          typography.text_box.family = e
          break
      }
      this.$store.dispatch(actionTypes.UPDATE_FORM_THEME_TYPOGRAPHY, typography)
      this.addStyle()
    },
    updateUiElementsSetting: function (e, choice) {
      let uiElements = JSON.parse(JSON.stringify(this.uiElements))
      switch (choice) {
        case 'uiQuesAsterisk':
          uiElements.question.asterisk_for_required = e
          break
        case 'uiQuesLabel':
          uiElements.question.hide_question_labels = e
          break
        case 'uiBackground':
          uiElements.background.color = this.getHexColorValue(e)

          this.flag = 1
          break
        case 'uiBackgroundForm':
          uiElements.background.formColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'formShadow':
          uiElements.background.formShadow = e
          break
        case 'uiStrokeColor':
          uiElements.step_progress.config.stroke_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiFillColor':
          uiElements.step_progress.config.fill_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'showProgress':
          uiElements.step_progress.showProgress = e
          break
        case 'showAnimation':
          uiElements.step_progress.showAnimation = e
          break
        case 'progressPosition':
          uiElements.step_progress.progressPosition = e
          break
        case 'formBorderWidth':
          uiElements.background.form_border_width = e
          break
        case 'formBorderStyle':
          uiElements.background.form_border_style = e
          break
        case 'formBorderColor':
          uiElements.background.form_border_color = this.getHexColorValue(e)
          break
        case 'formBorderRadius':
          uiElements.background.form_border_radius = e
          break
        // form padding
        case 'formPaddingRight':
          uiElements.background.form_padding_right = e
          break
        case 'formPaddingLeft':
          uiElements.background.form_padding_left = e
          break
        case 'formPaddingBottom':
          uiElements.background.form_padding_bottom = e
          break
        case 'formPaddingTop':
          uiElements.background.form_padding_top = e
          break
        // back button
        case 'uiStepNavBackText':
          uiElements.step_navigation.back_button.text = e
          break
        case 'uiStepNavBackFamily':
          uiElements.step_navigation.back_button.font.family = e
          break
        case 'uiStepNavBackWeight':
          uiElements.step_navigation.back_button.font.weight = e
          break
        case 'uiStepNavBackSize':
          uiElements.step_navigation.back_button.font.size = e
          break
        case 'uiStepNavBackLineHeight':
          uiElements.step_navigation.back_button.font.line_height = e
          break
        case 'uiStepNavBackRounded':
          uiElements.step_navigation.back_button.rounded = e
          break
        case 'uiStepNavBackShadow':
          uiElements.step_navigation.back_button.shadow = e
          break
        case 'uiStepNavBackColor':
          uiElements.step_navigation.back_button.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackBackgroundColor':
          uiElements.step_navigation.back_button.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackIconUrl':
          uiElements.step_navigation.back_button.icon = e
          break
        case 'uiStepNavBackWidth':
          uiElements.step_navigation.back_button.width = e
          break
        case 'uiStepNavBackAlignment':
          uiElements.step_navigation.back_button.alignment = e
          break
        case 'uiImage':
          uiElements.background.image = e
          break
        case 'uiStepNavBackRadius':
          uiElements.step_navigation.back_button.borderRadius = e
          break
        case 'uiStepNavBackHide':
          uiElements.step_navigation.back_button.hide = e
          break
        // back default
        case 'backBorderStyle':
          uiElements.step_navigation.back_button.default_style.border.style = e
          break
        case 'backBorderWidth':
          uiElements.step_navigation.back_button.default_style.border.width = e
          break
        case 'backBorderColor':
          uiElements.step_navigation.back_button.default_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackHeight':
          uiElements.step_navigation.back_button.font.height = e
          break
        case 'uiStepNavNextVisible':
          uiElements.step_navigation.next_button.hidden = !e
          break
        // back hover
        case 'backBorderStyleHover':
          uiElements.step_navigation.back_button.hover_style.border.style = e
          break
        case 'backBorderWidthHover':
          uiElements.step_navigation.back_button.hover_style.border.width = e
          break
        case 'backBorderColorHover':
          uiElements.step_navigation.back_button.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackColorHover':
          uiElements.step_navigation.back_button.hover_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackBgColorHover':
          uiElements.step_navigation.back_button.hover_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'backBorderStyleActive':
          uiElements.step_navigation.back_button.active_style.border.style = e
          break
        case 'backBorderWidthActive':
          uiElements.step_navigation.back_button.active_style.border.width = e
          break
        case 'backBorderColorActive':
          uiElements.step_navigation.back_button.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackColorActive':
          uiElements.step_navigation.back_button.active_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackBgColorActive':
          uiElements.step_navigation.back_button.active_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextText':
          uiElements.step_navigation.next_button.text = e
          break
        case 'uiStepNavNextFamily':
          uiElements.step_navigation.next_button.font.family = e
          break
        case 'uiStepNavNextWeight':
          uiElements.step_navigation.next_button.font.weight = e
          break
        case 'uiStepNavNextSize':
          uiElements.step_navigation.next_button.font.size = e
          break
        case 'uiStepNavNextLineHeight':
          uiElements.step_navigation.next_button.font.line_height = e
          break
        case 'uiStepNavNextRounded':
          uiElements.step_navigation.next_button.rounded = e
          break
        case 'uiStepNavNextShadow':
          uiElements.step_navigation.next_button.shadow = e
          break
        case 'uiStepNavNextColor':
          uiElements.step_navigation.next_button.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextBackgroundColor':
          uiElements.step_navigation.next_button.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextIconUrl':
          uiElements.step_navigation.next_button.icon = e
          break
        case 'uiStepNavNextIconPosition':
          uiElements.step_navigation.next_button.iconPosition = e
          break
        case 'uiStepNavNextRadius':
          uiElements.step_navigation.next_button.borderRadius = e
          break
        case 'uiStepNavNextWidth':
          uiElements.step_navigation.next_button.width = e
          break
        case 'uiStepNavNextAlignment':
          uiElements.step_navigation.next_button.alignment = e
          break
        // next default
        case 'nextBorderStyle':
          uiElements.step_navigation.next_button.default_style.border.style = e
          break
        case 'nextBorderWidth':
          uiElements.step_navigation.next_button.default_style.border.width = e
          break
        case 'nextBorderColor':
          uiElements.step_navigation.next_button.default_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextHeight':
          uiElements.step_navigation.next_button.font.height = e
          break
        // next hover
        case 'nextBorderStyleHover':
          uiElements.step_navigation.next_button.hover_style.border.style = e
          break
        case 'nextBorderWidthHover':
          uiElements.step_navigation.next_button.hover_style.border.width = e
          break
        case 'nextBorderColorHover':
          uiElements.step_navigation.next_button.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextColorHover':
          uiElements.step_navigation.next_button.hover_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextBgColorHover':
          uiElements.step_navigation.next_button.hover_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'nextBorderStyleActive':
          uiElements.step_navigation.next_button.active_style.border.style = e
          break
        case 'nextBorderWidthActive':
          uiElements.step_navigation.next_button.active_style.border.width = e
          break
        case 'nextBorderColorActive':
          uiElements.step_navigation.next_button.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextColorActive':
          uiElements.step_navigation.next_button.active_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextBgColorActive':
          uiElements.step_navigation.next_button.active_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitText':
          uiElements.step_navigation.submit_button.text = e
          break
        case 'uiStepNavSubmitFamily':
          uiElements.step_navigation.submit_button.font.family = e
          break
        case 'uiStepNavSubmitWeight':
          uiElements.step_navigation.submit_button.font.weight = e
          break
        case 'uiStepNavSubmitSize':
          uiElements.step_navigation.submit_button.font.size = e
          break
        case 'uiStepNavSubmitLineHeight':
          uiElements.step_navigation.submit_button.font.line_height = e
          break
        case 'uiStepNavSubmitRounded':
          uiElements.step_navigation.submit_button.rounded = e
          break
        case 'uiStepNavSubmitShadow':
          uiElements.step_navigation.submit_button.shadow = e
          break
        case 'uiStepNavSubmitColor':
          uiElements.step_navigation.submit_button.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitBackgroundColor':
          uiElements.step_navigation.submit_button.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitIconUrl':
          uiElements.step_navigation.submit_button.icon = e
          break
        case 'uiStepNavSubmitIconPosition':
          uiElements.step_navigation.submit_button.iconPosition = e
          break
        case 'uiStepNavSubmitRadius':
          uiElements.step_navigation.submit_button.borderRadius = e
          break
        case 'uiStepNavSubmitWidth':
          uiElements.step_navigation.submit_button.width = e
          break
        case 'uiStepNavSubmitAlignment':
          uiElements.step_navigation.submit_button.alignment = e
          break
        // submit default
        case 'submitBorderStyle':
          uiElements.step_navigation.submit_button.default_style.border.style = e
          break
        case 'submitBorderWidth':
          uiElements.step_navigation.submit_button.default_style.border.width = e
          break
        case 'submitBorderColor':
          uiElements.step_navigation.submit_button.default_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitHeight':
          uiElements.step_navigation.submit_button.font.height = e
          break
        // submit hover
        case 'submitBorderStyleHover':
          uiElements.step_navigation.submit_button.hover_style.border.style = e
          break
        case 'submitBorderWidthHover':
          uiElements.step_navigation.submit_button.hover_style.border.width = e
          break
        case 'submitBorderColorHover':
          uiElements.step_navigation.submit_button.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitColorHover':
          uiElements.step_navigation.submit_button.hover_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitBgColorHover':
          uiElements.step_navigation.submit_button.hover_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'submitBorderStyleActive':
          uiElements.step_navigation.submit_button.active_style.border.style = e
          break
        case 'submitBorderWidthActive':
          uiElements.step_navigation.submit_button.active_style.border.width = e
          break
        case 'submitBorderColorActive':
          uiElements.step_navigation.submit_button.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitColorActive':
          uiElements.step_navigation.submit_button.active_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitBgColorActive':
          uiElements.step_navigation.submit_button.active_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxMargin':
          uiElements.radio_checkbox.margin = e
          break
        case 'uiCheckboxLineHeight':
          uiElements.radio_checkbox.font.line_height = e
          break
        case 'uiCheckboxRadius':
          uiElements.radio_checkbox.radius = e
          break
        case 'uiCheckboxDefaultBorderStyle':
          uiElements.radio_checkbox.default_style.border.style = e
          break
        case 'uiCheckboxDefaultFontSize':
          uiElements.radio_checkbox.font.size = e
          break
        case 'uiCheckboxDefaultBorderColor':
          uiElements.radio_checkbox.default_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxDefaultBorderWidth':
          uiElements.radio_checkbox.default_style.border.width = e
          break
        case 'uiCheckboxDefaultTextColor':
          uiElements.radio_checkbox.default_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxDefaultDropdownColor':
          uiElements.choice.dropdown_skin.default_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxDefaultBackgroundColor':
          uiElements.radio_checkbox.default_style.backgroundcolor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxHoverBorderStyle':
          uiElements.radio_checkbox.hover_style.border.style = e
          break
        case 'uiCheckboxHoverBorderColor':
          uiElements.radio_checkbox.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxHoverBorderWidth':
          uiElements.radio_checkbox.hover_style.border.width = e
          break
        case 'uiCheckboxHoverTextColor':
          uiElements.radio_checkbox.hover_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxHoverBackgroundColor':
          uiElements.radio_checkbox.hover_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxActiveBorderStyle':
          uiElements.radio_checkbox.active_style.border.style = e
          break
        case 'uiCheckboxActiveBorderColor':
          uiElements.radio_checkbox.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxActiveBorderWidth':
          uiElements.radio_checkbox.active_style.border.width = e
          break
        case 'uiCheckboxActiveTextColor':
          uiElements.radio_checkbox.active_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxActiveBackgroundColor':
          uiElements.radio_checkbox.checked_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultBorderStyle':
          uiElements.choice.image_icon_skin.border.style = e
          break
        case 'imageIconDefaultBorderColor':
          uiElements.choice.image_icon_skin.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultBorderWidth':
          uiElements.choice.image_icon_skin.border.width = e
          break
        case 'imageIconPaddingTop':
          uiElements.choice.image_icon_skin.padding.top = e
          break
        case 'imageIconPaddingRight':
          uiElements.choice.image_icon_skin.padding.right = e
          break
        case 'imageIconPaddingLeft':
          uiElements.choice.image_icon_skin.padding.left = e
          break
        case 'imageIconPaddingBottom':
          uiElements.choice.image_icon_skin.padding.bottom = e
          break
        case 'imageIconDefaultTitleFontSize':
          uiElements.choice.image_icon_skin.title_font.size = e
          break
        case 'imageIconDefaultTitleFontWeight':
          uiElements.choice.image_icon_skin.title_font.weight = e
          break
        case 'imageIconDefaultTitleFontFamily':
          uiElements.choice.image_icon_skin.title_font.family = e
          break
        case 'imageIconDefaultTitleFontLineHeight':
          uiElements.choice.image_icon_skin.title_font.line_height = e
          break
        case 'imageIconDefaultTitleFontHeight':
          uiElements.choice.image_icon_skin.title_font.height = e
          break
        case 'imageIconDefaultTitleFontColor':
          uiElements.choice.image_icon_skin.title_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultDescriptionFontSize':
          uiElements.choice.image_icon_skin.description_font.size = e
          break
        case 'imageIconDefaultDescriptionFontWeight':
          uiElements.choice.image_icon_skin.description_font.weight = e
          break
        case 'imageIconDefaultDescriptionFontFamily':
          uiElements.choice.image_icon_skin.description_font.family = e
          break
        case 'imageIconDefaultDescriptionFontLineHeight':
          uiElements.choice.image_icon_skin.description_font.line_height = e
          break
        case 'imageIconDefaultDescriptionFontHeight':
          uiElements.choice.image_icon_skin.description_font.height = e
          break
        case 'imageIconDefaultDescriptionFontColor':
          uiElements.choice.image_icon_skin.description_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultWidth':
          uiElements.choice.image_icon_skin.width = e
          break
        case 'imageIconDefaultHeight':
          uiElements.choice.image_icon_skin.height = e
          break
        case 'imageIconDefaultShadow':
          uiElements.choice.image_icon_skin.shadow = e
          break
        case 'imageIconDefaultBackgroundColor':
          uiElements.choice.image_icon_skin.background_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultIconSize':
          uiElements.choice.image_icon_skin.icon_size = e
          break
        case 'imageIconDefaultIconColor':
          uiElements.choice.image_icon_skin.icon_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultImageWidth':
          uiElements.choice.image_icon_skin.image_width = e
          break
        case 'imageIconBorderRadius':
          uiElements.choice.image_icon_skin.border.radius = e
          break
        case 'imageIconDefaultTickboxColor':
          uiElements.choice.image_icon_skin.tickbox.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverBorderStyle':
          uiElements.choice.image_icon_skin.hover_style.border.style = e
          break
        case 'imageIconHoverBorderColor':
          uiElements.choice.image_icon_skin.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverBorderWidth':
          uiElements.choice.image_icon_skin.hover_style.border.width = e
          break
        case 'imageIconHoverBgColor':
          uiElements.choice.image_icon_skin.hover_style.background_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverTitleColor':
          uiElements.choice.image_icon_skin.hover_style.title_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverDesColor':
          uiElements.choice.image_icon_skin.hover_style.description_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverIconColor':
          uiElements.choice.image_icon_skin.hover_style.icon_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverShadow':
          uiElements.choice.image_icon_skin.hover_style.shadow = e
          break
        // Image Icon Active style
        case 'imageIconActiveBorderStyle':
          uiElements.choice.image_icon_skin.active_style.border.style = e
          break
        case 'imageIconActiveBorderColor':
          uiElements.choice.image_icon_skin.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveBorderWidth':
          uiElements.choice.image_icon_skin.active_style.border.width = e
          break
        case 'imageIconActiveBgColor':
          uiElements.choice.image_icon_skin.active_style.background_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveTitleColor':
          uiElements.choice.image_icon_skin.active_style.title_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveDesColor':
          uiElements.choice.image_icon_skin.active_style.description_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveIconColor':
          uiElements.choice.image_icon_skin.active_style.icon_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveShadow':
          uiElements.choice.image_icon_skin.active_style.shadow = e
          break
        case 'scaleUiFillColor':
          uiElements.scale.config.fill_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleUiStrokeColor':
          uiElements.scale.config.stroke_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleBorderColor':
          uiElements.scale.border_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSelectorColor':
          uiElements.scale.selector_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysColor':
          uiElements.scale.smileys_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysTextColor':
          uiElements.scale.smileys_text_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysHoverColor':
          uiElements.scale.hover_style.smileys_hover_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysActiveColor':
          uiElements.scale.active_style.smileys_active_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysActiveTextColor':
          uiElements.scale.active_style.smileys_active_text_color = this.getHexColorValue(e)
          this.flag = 1
          break
      }
      this.$store.dispatch(actionTypes.UPDATE_FORM_THEME_UI_ELEMENTS, uiElements)
      this.addStyle()
    },
    typographySelectedTab: function (value) {
      if (value === 'Question title') {
        document.getElementById('question_title').style.display = 'block'
        document.getElementById('question_description').style.display = 'none'
      } else {
        document.getElementById('question_title').style.display = 'none'
        document.getElementById('question_description').style.display = 'block'
      }
    }
  },
  computed: {
    ...mapGetters([
      'defaultFormVariantTheme'
    ]),
    themes: function () {
      return this.$store.state.formThemeTemplates[0] || []
    },
    theme: function () {
      let themes = []
      if (this.$route.params.id && this.$route.params.variantId) {
        themes = this.$store.state.formVariantThemes
          .filter(t => t.form_variant_id === parseInt(this.$route.params.variantId))
      } else {
        themes = this.$store.state.formVariantThemes.filter(t => t.id === -1)
        if (themes.length === 0) {
          return this.defaultFormVariantTheme
        }
      }
      //return deepmerge(this.defaultFormVariantTheme, themes[0]) || {}
      return { ...this.defaultFormVariantTheme, ...themes[0] }
    },
    typography: function () {
      return this.theme.typography
    },
    general: function () {
      return this.theme.general
    },
    uiElements: function () {
      if (!this.theme.ui_elements.step_navigation.submit_button.icon) {
        this.theme.ui_elements.step_navigation.submit_button.icon = ' '
      }
      if (!this.theme.ui_elements.step_navigation.back_button.icon) {
        this.theme.ui_elements.step_navigation.back_button.icon = ' '
      }
      if (!this.theme.ui_elements.step_navigation.next_button.icon) {
        this.theme.ui_elements.step_navigation.next_button.icon = ' '
      }
      return this.theme.ui_elements
    },
    allIcons: function () {
      let materialIcons = icons.map((icon) => {
        return {
          label: icon,
          value: icon
        }
      })
      materialIcons.unshift({ label: 'Select', value: '' })
      return materialIcons
    },
    backButtonStyle: function () {
      return {
        backgroundColor: this.uiElements.step_navigation.back_button.backgroundColor,
        color: this.uiElements.step_navigation.back_button.font.color,
        height: this.uiElements.step_navigation.back_button.font.height + 'px',
        width: '100%',
        padding: '0px',
        borderRadius: this.uiElements.step_navigation.back_button.borderRadius + 'px !important',
        border: this.uiElements.step_navigation.back_button.default_style.border.width + 'px' + ' ' + this.uiElements.step_navigation.back_button.default_style.border.style + ' ' + this.uiElements.step_navigation.back_button.default_style.border.color,
        boxSizing: 'border-box',
        '--custom-font-family': this.uiElements.step_navigation.back_button.font.family,
        '--custom-font-size': this.uiElements.step_navigation.back_button.font.size + 'px',
        '--custom-font-weight': this.uiElements.step_navigation.back_button.font.weight,
      }
    },
    continueButtonStyle: function () {
      return {
        color: this.uiElements.step_navigation.next_button.font.color,
        backgroundColor: this.uiElements.step_navigation.next_button.backgroundColor,
        height: this.uiElements.step_navigation.next_button.font.height + 'px',
        width: '100%',
        padding: '0px',
        borderRadius: this.uiElements.step_navigation.next_button.borderRadius + 'px !important',
        border: this.uiElements.step_navigation.next_button.default_style.border.width + 'px' + ' ' + this.uiElements.step_navigation.next_button.default_style.border.style + ' ' + this.uiElements.step_navigation.next_button.default_style.border.color,
        boxSizing: 'border-box',
        '--custom-font-family': this.uiElements.step_navigation.next_button.font.family,
        '--custom-font-size': this.uiElements.step_navigation.next_button.font.size + 'px',
        '--custom-font-weight': this.uiElements.step_navigation.next_button.font.weight,
      }
    },
    submitButtonStyle: function () {
      return {
        color: this.uiElements.step_navigation.submit_button.font.color,
        backgroundColor: this.uiElements.step_navigation.submit_button.backgroundColor,
        height: this.uiElements.step_navigation.submit_button.font.height + 'px',
        width: '100%',
        padding: '0px',
        borderRadius: this.uiElements.step_navigation.submit_button.borderRadius + 'px !important',
        border: this.uiElements.step_navigation.submit_button.default_style.border.width + 'px' + ' ' + this.uiElements.step_navigation.submit_button.default_style.border.style + ' ' + this.uiElements.step_navigation.submit_button.default_style.border.color,
        boxSizing: 'border-box',
        '--custom-font-family': this.uiElements.step_navigation.submit_button.font.family,
        '--custom-font-size': this.uiElements.step_navigation.submit_button.font.size + 'px',
        '--custom-font-weight': this.uiElements.step_navigation.submit_button.font.weight,
      }
    },
    formDemoStyle: function () {
      return {
        backgroundColor: this.uiElements.background.formColor,
        border: this.uiElements.background.form_border_width + 'px' + ' ' +
          this.uiElements.background.form_border_style + ' ' +
          this.uiElements.background.form_border_color,
        borderRadius: this.uiElements.background.form_border_radius + 'px',
        padding: this.uiElements.background.form_padding_top + 'px' + ' ' + this.uiElements.background.form_padding_right + 'px' + ' ' + this.uiElements.background.form_padding_bottom + 'px' + ' ' + this.uiElements.background.form_padding_left + 'px'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.back-btn :deep(.v-btn__content) {
  font-family: var(--custom-font-family);
  font-size: var(--custom-font-size);
  font-weight: var(--custom-font-weight);
  text-transform: none;
}

.continue-btn :deep(.v-btn__content) {
  font-family: var(--custom-font-family);
  font-size: var(--custom-font-size);
  font-weight: var(--custom-font-weight);
  text-transform: none;
}

.submit-btn :deep(.v-btn__content) {
  font-family: var(--custom-font-family);
  font-size: var(--custom-font-size);
  font-weight: var(--custom-font-weight);
  text-transform: none;
}

.counter {
  margin-bottom: 27px;
  display: flex;
  justify-content: center;
  font-size: 21px;
}

.counter-value {
  border: 1px solid grey;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
