import questionTypesMap from '@/store/question-types-map'
import * as util from '@/library/util'
import _ from 'lodash'
import { addressSkins, addressSkinIds, addressAutocompleteModes, dateSkins, dateSkinIds, rangeSkinIds, rangeSkins } from '@/shared/constants/formQuestionSkins'

export default [
  {
    'type': questionTypesMap.FIRST_NAME,
    'question': 'What is your first name?',
    'label': 'First Name',
    'fa_icon': 'fa fa-file-text',
    'material_icon': 'note',
    'description': '',
    'field_name': 'first_name',
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Contains', 'Does Not Contain', 'Starts With', 'Doesn’t Start With', 'Ends With', 'Doesn’t End With', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: '',
      require: true
    }
  },

  {
    'type': questionTypesMap.LAST_NAME,
    'question': 'What is your last name?',
    'label': 'Last Name',
    'fa_icon': 'fa fa-file-text',
    'material_icon': 'note',
    'description': '',
    'field_name': 'last_name',
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Contains', 'Does Not Contain', 'Starts With', 'Doesn’t Start With', 'Ends With', 'Doesn’t End With', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: '',
      require: true
    }
  },

  {
    'type': questionTypesMap.SHORT_TEXT,
    'question': '',
    'label': 'Short Text',
    'fa_icon': 'fa fa-file-text',
    'material_icon': 'note',
    'description': '',
    'field_name': 'short_text',
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Contains', 'Does Not Contain', 'Starts With', 'Doesn’t Start With', 'Ends With', 'Doesn’t End With', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: '',
      require: true
    }
  },

  {
    'type': questionTypesMap.PARAGRAPH_TEXT,
    'question': '',
    'label': 'Paragraph Text',
    'fa_icon': 'fa fa-paragraph',
    'material_icon': 'assignment',
    'description': '',
    'field_name': 'long_text',
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Contains', 'Does Not Contain', 'Starts With', 'Doesn’t Start With', 'Ends With', 'Doesn’t End With', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: '',
      require: true
    }
  },

  {
    'type': questionTypesMap.EMAIL_ADDRESS,
    'question': 'What is your email address?',
    'label': 'Email Address',
    'fa_icon': 'fa fa-envelope',
    'material_icon': 'email',
    'description': '',
    'field_name': 'email',
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Contains', 'Does Not Contain', 'Starts With', 'Doesn’t Start With', 'Ends With', 'Doesn’t End With', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: '',
      require: true,
      replyTo: false,
      restrictEmail: false,
      restrictEmailFields: [
        {id: 1, email: '', order: 1, allow: 1}
      ]
    }
  },

  {
    'type': questionTypesMap.PHONE_NUMBER,
    'question': 'What is your phone number?',
    'label': 'Phone Number',
    'fa_icon': 'fa fa-phone',
    'material_icon': 'phone',
    'description': '',
    'field_name': 'phone',
    'hide_title': true,
    'state': ['Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: '',
      require: true,
      defaultCountryCode: null,
      enableDefaultCode: false
    }
  },
  {
    'type': questionTypesMap.DATE,
    'question': 'What is your date of birth?',
    'label': 'Date',
    'fa_icon': 'fa fa-date',
    'material_icon': 'date',
    'description': '',
    'field_name': '',
    'skin': _.find(dateSkins, {id: dateSkinIds.THREE_INPUT_BOXES}),
    'hide_title': true,
    'state': ['Is Equal To Date', 'Is Not Equal To Date', 'Before', 'After', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderDayText: '',
      placeholderMonthText: '',
      placeholderYearText: '',
      require: true,
      minDate: null,
      maxDate: null,
      enableMinMax: false,
      autoIncrement: false,
      skins: dateSkins
    }
  },

  {
    'type': questionTypesMap.SINGLE_CHOICE,
    'question': '',
    'label': 'Single Select (Choose One Option)',
    'fa_icon': 'fa fa-list',
    'material_icon': 'list',
    'description': '',
    'field_name': 'choice_question',
    'skin': util.singleSelectSkins()[0],
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      require: true,
      choices: [
        {id: 1, label: 'Choice 1', order: 1, description: '', icon: '', image: '', selected: false},
        {id: 2, label: 'Choice 2', order: 2, description: '', icon: '', image: '', selected: false},
        {id: 3, label: 'Choice 3', order: 3, description: '', icon: '', image: '', selected: false}
      ],
      choicesValues: [0, 0, 0],
      enableChoicesValues: false,
      enablePreSelectChoices: false,
      randomChoiceOrder: false,
      skins: util.singleSelectSkins(),
      otherOption: ''
    }
  },

  {
    'type': questionTypesMap.MULTIPLE_CHOICE,
    'question': '',
    'label': 'Multi Select (Choose One Or More Options)',
    'fa_icon': 'fa fa-list',
    'material_icon': 'list',
    'description': '',
    'field_name': 'choice_question2',
    'skin': util.multiSelectSkins()[0],
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      require: true,
      choices: [
        {id: 1, label: 'Choice 1', order: 1, description: '', icon: '', image: '', selected: false},
        {id: 2, label: 'Choice 2', order: 2, description: '', icon: '', image: '', selected: false},
        {id: 3, label: 'Choice 3', order: 3, description: '', icon: '', image: '', selected: false}
      ],
      choicesValues: [0, 0, 0],
      enableMinMaxChoices: false,
      minChoices: 1,
      maxChoices: 1,
      enableChoicesValues: false,
      enablePreSelectChoices: false,
      randomChoiceOrder: false,
      skins: util.multiSelectSkins(),
      otherOption: ''
    }
  },

  {
    type: questionTypesMap.ADDRESS,
    question: 'What is your home address?',
    label: 'Address',
    fa_icon: 'fa fa-list',
    material_icon: 'list',
    description: '',
    field_name: 'address',
    hide_title: true,
    skin: _.find(addressSkins, {id: addressSkinIds.DEFAULT}),
    skins: addressSkins,
    autocompleteApiKeySource: '',
    autocompleteApiKey: '',
    autocompleteMode: addressAutocompleteModes[0],
    autocompleteFieldsEdit: false,
    state: ['Country Equals', 'Country Doesnt Equals', 'State Equals', 'State Dosent Equals'],
    required: true,
    fields: {
      address_line_2: {
        id: 'address_line_2',
        enabled: true,
        required: true,
        label: 'Address Line 2',
        label_value: '',
        placeholder: 'Address Line 2',
        placeholder_value: 'Address Line 2',
        field_name: '',
        field_name_suffix: 'line_2',
        webhook: false,
        order: 1
      },
      address_line_1: {
        id: 'address_line_1',
        enabled: true,
        required: true,
        label: 'Address Line 1',
        label_value: '',
        placeholder: 'Address Line 1',
        placeholder_value: 'Address Line 1',
        field_name: '',
        field_name_suffix: 'line_1',
        webhook: false,
        order: 2
      },
      city: {
        id: 'city',
        enabled: true,
        required: true,
        label: 'Town/City',
        label_value: '',
        placeholder: 'Town/City',
        placeholder_value: 'Town/City',
        field_name: '',
        field_name_suffix: 'city',
        webhook: false,
        auto_complete: false,
        order: 3
      },
      state: {
        id: 'state',
        enabled: true,
        required: true,
        label: 'State',
        label_value: '',
        placeholder: 'State',
        placeholder_value: 'State',
        field_name: '',
        field_name_suffix: 'state',
        webhook: false,
        auto_complete: true,
        order: 4
      },
      country: {
        id: 'country',
        enabled: true,
        required: true,
        label: 'Country',
        label_value: '',
        placeholder: 'Country',
        placeholder_value: 'Country',
        field_name: '',
        field_name_suffix: 'country',
        webhook: false,
        auto_complete: true,
        order: 5
      },
      post_code: {
        id: 'post_code',
        enabled: false,
        required: true,
        label: 'Postal Code / Zip Code',
        label_value: '',
        placeholder: 'Zip Code field',
        placeholder_value: 'Post Code',
        field_name: '',
        field_name_suffix: 'post_code',
        webhook: false,
        order: 6
      },
      landmark: {
        id: 'landmark',
        enabled: false,
        required: true,
        label: 'Landmark',
        label_value: '',
        placeholder: 'Landmark',
        placeholder_value: 'Landmark',
        field_name: '',
        field_name_suffix: 'landmark',
        webhook: false,
        order: 7
      }
    }
  },

  {
    type: questionTypesMap.GDPR,
    label: 'GDPR',
    title: 'Marketing permissions',
    description: 'We will use the information you provide on this form to be in touch with you and to provide updates and marketing. Please let us know all the ways you like to hear from us.',
    legalText: 'You can change your mind at any time by clicking the unsubscribe link in the footer of any email you receive from us, or by contacting us at hello@leadgenapp.io <br/> We use <strong> Leadgen </strong> as our marketing automation platform. By submitting this form, you acknowledge that the information you provide will be transferred to <strong>Leadgen<strong/>.',
    field_name: 'gdpr',
    hide_title: true,
    'state': ['Is Checked', 'Is Not Checked'],
    options: {
      choices: [{
        id: 1,
        label: 'Email',
        required: true
      }]
    },
    required: true,
    enabled: true
  },
  {
    'type': questionTypesMap.NUMBER,
    'question': '',
    'label': 'Number',
    'fa_icon': 'fa fa-phone',
    'material_icon': 'phone',
    'description': '',
    'field_name': 'number',
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Greater Than', 'Less Than', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: '',
      enableMinMaxLimit: false,
      require: true,
      minNumber: null,
      maxNumber: null
    }
  },
  {
    'type': questionTypesMap.URL,
    'question': 'What is your website URL?',
    'label': 'Website',
    'fa_icon': 'fa fa-link',
    'material_icon': 'link',
    'description': '',
    'field_name': 'website',
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Contains', 'Does Not Contain', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: 'https://',
      require: true
    }
  },
  {
    'type': questionTypesMap.RANGE,
    'question': '',
    'label': 'Scale',
    'fa_icon': 'fa fa-scale',
    'material_icon': 'linear_scale',
    'description': '',
    'field_name': '',
    'skin': _.find(rangeSkins, {id: rangeSkinIds.SLIDER_SCALE}),
    'hide_title': true,
    'state': ['Is Equal To', 'Is Not Equal To', 'Greater Than', 'Less Than', 'Is Empty', 'Is Filled'],
    'options': {
      placeholder: true,
      placeholderText: '',
      require: true,
      enableCustomText: false,
      enableUnitValues: false,
      enableStepCount: false,
      showHideOrientationScale: true,
      rangeFields: {
        unitAlignment: 'left',
        unit: '',
        stepCount: '',
        veryUnsatisfied: 'Very Unsatisfied',
        unsatisfied: 'Unsatisfied',
        neutral: 'Neutral',
        satisfied: 'Satisfied',
        verySatisfied: 'Very Satisfied',
        lowerEndScaleText: 'Very Bad',
        higherEndScaleText: 'Excellent',
        maxScaleLimit: 5,
        minScaleValue: 0,
        maxScaleValue: 100,
        valueMin: 0,
        valueMax: 100,
        likertRadios: [
          {id: 0, label: '1', lowerEndScaleText: 'Very Bad'},
          {id: 1, label: '2'},
          {id: 2, label: '3'},
          {id: 3, label: '4'},
          {id: 4, label: '5', higherEndScaleText: 'Excellent'}
        ]
      },
      skins: rangeSkins
    }
  }
]
