import * as mutationTypes from '@/store/mutation-types'
import * as UserSettingsApi from '@/api/user-settings'

const state = {
  userSettings: {}
}

const getters = {
  userSettings: function (state) {
    return state.userSettings
  }
}

const actions = {
  fetchUserSettings (context, data) {
    return UserSettingsApi.fetchEmailVerification(data)
  },
  fetchUserSettingsSuccess ({ state, commit }, data) {
    commit(mutationTypes.FETCH_USER_SETTING_SUCCESS, data)
  },
  updateUserSettings ({ state, commit }, data) {
    commit(mutationTypes.SAVE_USER_SETTING, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_USER_SETTING_SUCCESS] (state, data) {
    state.whiteLabel = data
  },
  [mutationTypes.SAVE_USER_SETTING]: (context, data) => {
    return UserSettingsApi.updateEmailVerification(data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
