export default {
  subscribeWithinTrial: 'subscribeWithinTrial',
  upgradeWithinTrial: 'upgradeWithinTrial',
  downgradeWithinTrial: 'downgradeWithinTrial',
  subscribe: 'subscribe',
  upgrade: 'upgrade',
  update: 'update',
  downgrade: 'downgrade',
  cancel: 'cancel',
  pause: 'pause',
  resume: 'resume',
  billing: 'billing'
}
