/* app */
export const INIT_APP = 'INITIALIZE_APPLICATION'

/* Entity */
export const INSERT_ENTITY = 'INSERT_ENTITY'
export const UPDATE_ENTITY = 'UPDATE_ENTITY'

/* form builder */
export const FORMBUILDER_CREATE_FORM = 'FORMBUILDER_CREATE_FORM'
export const FORMBUILDER_CREATE_FORM_VARIANT_SUCCESS = 'FORMBUILDER_CREATE_FORM_VARIANT_SUCCESS'
export const FORMBUILDER_CREATE_FORM_VARIANT_ERROR = 'FORMBUILDER_CREATE_FORM_VARIANT_ERROR'
export const FORMBUILDER_UPDATE_FORM = 'FORMBUILDER_UPDATE_FORM'
export const FORMBUILDER_ARCHIVE_FORM = 'FORMBUILDER_ARCHIVE_FORM'
export const FORMBUILDER_UPDATE_ID = 'FORMBUILDER_UPDATE_ID'
export const FORMBUILDER_RESET = 'FORMBUILDER_RESET'
export const FORMBUILDER_UPDATE_CHOICE_FORMULA = 'FORMBUILDER_UPDATE_CHOICE_FORMULA'
export const FORMBUILDER_UPDATE_GDPR = 'FORMBUILDER_UPDATE_GDPR'
export const FORMBUILDER_REMOVE_GDPR = 'FORMBUILDER_REMOVE_GDPR'
export const FORMBUILDER_UPDATE_CALCULATOR = 'FORMBUILDER_UPDATE_CALCULATOR'

// steps
export const FORMBUILDER_ADD_STEP = 'FORMBUILDER_ADD_STEP'
export const FORMBUILDER_ADD_QUESTION = 'FORMBUILDER_ADD_QUESTION'
export const FORMBUILDER_ADD_ELEMENT = 'FORMBUILDER_ADD_ELEMENT'
export const FORMBUILDER_UPDATE_FORMTITLE = 'FORMBUILDER_UPDATE_FORMTITLE'
export const FORMBUILDER_UPDATE_STEPQ = 'FORMBUILDER_UPDATE_STEPQUESTIONS'
export const FORMBUILDER_DELETE_STEP = 'FORMBUILDER_DELETE_STEP'
export const FORMBUILDER_UPDATE_STEPS = 'FORMBUILDER_UPDATE_STEPS'
export const FORMBUILDER_UPDATE_STEP_JUMP = 'FORMBUILDER_UPDATE_STEP_JUMP'
export const FORMBUILDER_UPDATE_STEP_AN = 'FORMBUILDER_UPDATE_STEP_AUTO_NAVIGATION'

// questions
export const FORMBUILDER_ADD_QT = 'FORMBUILDER_ADD_QT'
export const FORMBUILDER_DUPLICATE_QUESTION = 'FORMBUILDER_DUPLICATE_QUESTION'
export const FORMBUILDER_UPDATE_QTYPE = 'FORMBUILDER_UPDATE_QUESTION_TYPE'
export const FORMBUILDER_UPDATE_QREQUIRE = 'FORMBUILDER_UPDATE_QUESTION_REQUIRED'
export const FORMBUILDER_UPDATE_QTITLE = 'FORMBUILDER_UPDATE_QUESTION_TITLE'
export const FORMBUILDER_UPDATE_QTITLE_HIDE = 'FORMBUILDER_UPDATE_QUESTION_TITLE_HIDE'
export const FORMBUILDER_UPDATE_QPLACEHOLDER = 'FORMBUILDER_UPDATE_QUESTION_PLACEHOLDER'
export const FORMBUILDER_UPDATE_QDATE_PLACEHOLDERDAY = 'FORMBUILDER_UPDATE_QUESTION_DATE_PLACEHOLDERDAY'
export const FORMBUILDER_UPDATE_QDATE_PLACEHOLDERMONTH = 'FORMBUILDER_UPDATE_QUESTION_DATE_PLACEHOLDERMONTH'
export const FORMBUILDER_UPDATE_QDATE_PLACEHOLDERYEAR = 'FORMBUILDER_UPDATE_QUESTION_DATE_PLACEHOLDERYEAR'
export const FORMBUILDER_UPDATE_QMINDATE = 'FORMBUILDER_UPDATE_QUESTION_MINDATE'
export const FORMBUILDER_UPDATE_QMAXDATE = 'FORMBUILDER_UPDATE_QUESTION_MAXDATE'
export const FORMBUILDER_UPDATE_QREQUIRED = 'FORMBUILDER_UPDATE_QUESTION_REQUIRED'
export const FORMBUILDER_UPDATE_QMINMAX = 'FORMBUILDER_UPDATE_QUESTION_MINMAX'
export const FORMBUILDER_UPDATE_QNUMBER = 'FORMBUILDER_UPDATE_QUESTION_NUMBER'
export const FORMBUILDER_UPDATE_QMINMAXCHOICES = 'FORMBUILDER_UPDATE_QMINMAXCHOICES'
export const FORMBUILDER_UPDATE_QENABLE_CHOICES_VALUES = 'FORMBUILDER_UPDATE_QENABLE_CHOICES_VALUES'
export const FORMBUILDER_UPDATE_QENABLE_PRE_SELECT_CHOICES = 'FORMBUILDER_UPDATE_QENABLE_PRE_SELECT_CHOICES'
export const FORMBUILDER_UPDATE_QMINCHOICES = 'FORMBUILDER_UPDATE_QMINCHOICES'
export const FORMBUILDER_UPDATE_QMAXCHOICES = 'FORMBUILDER_UPDATE_QMAXCHOICES'
export const FORMBUILDER_UPDATE_QSKIN = 'FORMBUILDER_UPDATE_QUESTION_SKIN'
export const FORMBUILDER_UPDATE_QMINMAX_AUTOINCEREMENT = 'FORMBUILDER_UPDATE_QMINMAX_AUTOINCEREMENT'

export const FORMBUILDER_UPDATE_QCHOICES = 'FORMBUILDER_UPDATE_QUESTION_CHOICES'
export const FORMBUILDER_UPDATE_QCHOICES_VALUES = 'FORMBUILDER_UPDATE_QUESTION_CHOICES_VALUES'
export const FORMBUILDER_UPDATE_QCHOICE = 'FORMBUILDER_UPDATE_QUESTION_CHOICE'
export const FORMBUILDER_UPDATE_QCHOICE_VALUE = 'FORMBUILDER_UPDATE_QUESTION_CHOICE_VALUE'
export const FORMBUILDER_DELETE_QCHOICE = 'FORMBUILDER_DELETE_QUESTION_CHOICE'
export const FORMBUILDER_DELETE_QCHOICE_VALUE = 'FORMBUILDER_DELETE_QUESTION_CHOICE_VALUE'
export const FORMBUILDER_ADD_QCHOICE = 'FORMBUILDER_ADD_QUESTION_CHOICE'
export const FORMBUILDER_ADD_QCHOICE_VALUE = 'FORMBUILDER_ADD_QUESTION_CHOICE_VALUE'
export const FORMBUILDER_SORT_QCHOICE = 'FORMBUILDER_SORT_QUESTION_CHOICE'
export const FORMBUILDER_UPDATE_GDPR_CHOICES = 'FORMBUILDER_UPDATE_GDPR_CHOICES'
export const FORMBUILDER_UPDATE_QENABLE_RANDOM_CHOICE_ORDER = 'FORMBUILDER_UPDATE_QENABLE_RANDOM_CHOICE_ORDER'

export const FORMBUILDER_UPDATE_QENABLE_OTHER_CHOICE = 'FORMBUILDER_UPDATE_QENABLE_OTHER_CHOICE'

export const FORMBUILDER_DELETE_Q = 'FORMBUILDER_DELETE_QUESTION'
export const FORMBUILDER_VALIDATE = 'FORMBUILDER_VALIDATE'
export const FORMBUILDER_UPDATE_QDESCRIPTION = 'FORMBUILDER_UPDATE_QUESTION_DESCRIPTION'
export const FORMBUILDER_UPDATE_QFIELDNAME = 'FORMBUILDER_UPDATE_QUESTION_FIELDNAME'

export const FORMBUILDER_ADD_QJUMP = 'FORMBUILDER_ADD_QUESTION_JUMP'
export const FORMBUILDER_UPDATE_QJUMP = 'FORMBUILDER_UPDATE_QUESTION_JUMP'
export const FORMBUILDER_DELETE_QJUMP = 'FORMBUILDER_DELETE_QUESTION_JUMP'
export const FORMBUILDER_ADD_QJUMP_CONDITION = 'FORMBUILDER_ADD_QUESTION_JUMP_CONDITION'
export const FORMBUILDER_UPDATE_QJUMP_CONDITION = 'FORMBUILDER_UPDATE_QUESTION_JUMP_CONDITION'
export const FORMBUILDER_DELETE_QJUMP_CONDITION = 'FORMBUILDER_DELETE_QUESTION_JUMP_CONDITION'
export const FORMBUILDER_DELETE_QJUMPS = 'FORMBUILDER_DELETE_QUESTION_JUMPS'

export const FORMBUILDER_UPDATE_QADDRESS_FIELDS = 'FORMBUILDER_UPDATE_QUESTION_ADDRESS_FIELDS'
export const FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY = 'FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY'
export const FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY_SOURCE = 'FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY_SOURCE'
export const FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_FIELDS_EDIT = 'FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_FIELDS_EDIT'
export const FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE = 'FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE'

export const FORMBUILDER_UPDATE_QEMAIL_REPLYTO = 'FORMBUILDER_UPDATE_QEMAIL_REPLYTO'
export const FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL = 'FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL'
export const FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELDS = 'FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELDS'
export const FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELD = 'FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELD'
export const FORMBUILDER_ADD_QEMAIL_RESTRICTEMAILFIELD = 'FORMBUILDER_ADD_QEMAIL_RESTRICTEMAILFIELD'
export const FORMBUILDER_DELETE_QEMAIL_RESTRICTEMAILFIELD = 'FORMBUILDER_DELETE_QEMAIL_RESTRICTEMAILFIELD'

export const FORMBUILDER_UPDATE_QPHONE_DEFAULT_COUNTRY_CODE = 'FORMBUILDER_UPDATE_QPHONE_DEFAULT_COUNTRY_CODE'
export const FORMBUILDER_UPDATE_QPHONE_COUNTRY_CODE_VALUE = 'FORMBUILDER_UPDATE_QPHONE_COUNTRY_CODE_VALUE'

export const FORMBUILDER_UPDATE_QNUMBER_QMINMAXLIMIT = 'FORMBUILDER_UPDATE_QNUMBER_QMINMAXLIMIT'
export const FORMBUILDER_UPDATE_QMINNUMBER = 'FORMBUILDER_UPDATE_QMINNUMBER'
export const FORMBUILDER_UPDATE_QMAXNUMBER = 'FORMBUILDER_UPDATE_QMAXNUMBER'

// Range
export const FORMBUILDER_UPDATE_QRANGE_UNITVALUES = 'FORMBUILDER_UPDATE_QRANGE_UNITVALUES'
export const FORMBUILDER_UPDATE_QRANGE_STEPCOUNT = 'FORMBUILDER_UPDATE_QRANGE_STEPCOUNT'
export const FORMBUILDER_UPDATE_QRANGE_CUSTOMTEXT = 'FORMBUILDER_UPDATE_QRANGE_CUSTOMTEXT'
export const FORMBUILDER_UPDATE_QRANGE_SHORIENTATIONSCALE = 'FORMBUILDER_UPDATE_QRANGE_SHORIENTATIONSCALE'
export const FORMBUILDER_UPDATE_QRANGE_RANGEFIELDS = 'FORMBUILDER_UPDATE_QRANGE_RANGEFIELDS'
export const FORMBUILDER_UPDATE_QRANGE_RANGEFIELD = 'FORMBUILDER_UPDATE_QRANGE_RANGEFIELD'

// theme variant
export const FORMBUILDER_SET_DEFAULT_THEME = 'FORMBUILDER_SET_DEFAULT_THEME'
export const FORMBUILDER_SET_THEME_SETTING_FIRST_TIME = 'FORMBUILDER_SET_THEME_SETTING_FIRST_TIME'
export const UPDATE_FORM_THEME_GENERAL = 'UPDATE_FORM_THEME_GENERAL'
export const UPDATE_FORM_THEME_TYPOGRAPHY = 'UPDATE_FORM_THEME_TYPOGRAPHY'
export const UPDATE_FORM_THEME_UI_ELEMENTS = 'UPDATE_FORM_THEME_UI_ELEMENTS'

export const FORMBUILDER_GET_THEME_SETTING_SUCCESS = 'FORMBUILDER_GET_THEME_SETTING_SUCCESS'

// Form Theme Template
export const FORMBUILDER_GET_FORM_THEME_TEMPLATES = 'FORMBUILDER_GET_FORM_THEME_TEMPLATES'
export const FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS = 'FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS'
export const FORMBUILDER_SET_TEMPLATE_THEME = 'FORMBUILDER_SET_TEMPLATE_THEME'

// elements
export const FORMBUILDER_UPDATE_ENUMBER = 'FORMBUILDER_UPDATE_ELEMENT_NUMBER'
export const FORMBUILDER_DELETE_E = 'FORMBUILDER_DELETE_ELEMENT'

export const FORMBUILDER_UPDATE_TB_ELEMENT_CONTENT = 'FORMBUILDER_UPDATE_TEXTBOX_ELEMENT_CONTENT'

/* form */
export const FORM_UPDATE_IFRAME_HEIGHT = 'FORM_UPDATE_IFRAME_HEIGHT'
export const FORM_UPDATE_IFRAME_WIDTH = 'FORM_UPDATE_IFRAME_WIDTH'
export const UPDATE_IFRAME_DIMENSIONS = 'UPDATE_IFRAME_DIMENSIONS'
export const FORM_FULL_WIDTH_FORM = 'FORM_FULL_WIDTH_FORM'
export const FORM_UPDATE_IFRAME_FORM_SHADOW = 'FORM_UPDATE_IFRAME_FORM_SHADOW'

export const FORM_FETCH_LEADS_SUCCESS = 'FORM_FETCH_LEAD_SUCCESS'
export const FORM_FETCH_LEADS_ERROR = 'FORM_FETCH_LEAD_ERROR'

export const FETCH_FORMS_COUNT = 'FETCH_FORMS_COUNT'
export const FETCH_FORMS_BY_USER = 'FETCH_FORMS_BY_USER'
export const FETCH_FORM_BY_USER = 'FETCH_FORM_BY_USER'
export const FETCH_FORM_BY_KEY = 'FETCH_FORM_BY_KEY'

export const FORM_FETCH_OVERVIEW_SUCCESS = 'FORM_FETCH_OVERVIEW_SUCCESS'
export const FORM_FETCH_OVERVIEW_ERROR = 'FORM_FETCH_OVERVIEW_ERROR'

export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS'
export const FETCH_FORM_ERROR = 'FETCH_FORM_ERROR'

export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS'
export const FETCH_FORMS_ERROR = 'FETCH_FORMS_ERROR'

export const FORM_DUPLICATE_SUCCESS = 'FORM_DUPLICATE_SUCCESS'
export const FORM_DUPLICATE_ERROR = 'FORM_DUPLICATE_ERROR'

export const FORM_ARCHIVE_SUCCESS = 'FORM_ARCHIVE_SUCCESS'
export const FORM_ARCHIVE_ERROR = 'FORM_ARCHIVE_ERROR'

export const FM_RESET_FORM_STATUS = 'FM_RESET_FORM_STATUS'
export const FM_RESET_FORM_STATUS_SUCCESS = 'FM_RESET_FORM_STATUS_SUCCESS'
export const FM_RESET_FORM_STATUS_ERROR = 'FM_RESET_FORM_STATUS_ERROR'

export const FORM_DELETE_LEAD = 'FORM_DELETE_LEAD'
export const FORM_DELETE_LEAD_SUCCESS = 'FORM_DELETE_LEAD_SUCCESS'

/* form variants */
export const FETCH_FORM_VARIANTS_SUCCESS = 'FETCH_FORM_VARIANTS_SUCCESS'
export const FETCH_FORM_VARIANTS_ERROR = 'FETCH_FORM_VARIANTS_ERROR'

export const FORM_FETCH_VARIANT_STATE_SUCCESS = 'FORM_FETCH_VARIANT_STATE_SUCCESS'
export const FORM_FETCH_VARIANT_STATE_ERROR = 'FORM_FETCH_VARIANT_STATE_ERROR'

export const DUPLICATE_FORM_VARIANT_SUCCESS = 'DUPLICATE_FORM_VARIANT_SUCCESS'
export const DUPLICATE_FORM_VARIANT_ERROR = 'DUPLICATE_FORM_VARIANT_ERROR'

export const DELETE_FORM_VARIANT_SUCCESS = 'DELETE_FORM_VARIANT_SUCCESS'
export const DELETE_FORM_VARIANT_ERROR = 'DELETE_FORM_VARIANT_ERROR'

export const DELETE_FORM_LEAD_SUCCESS = 'DELETE_FORM_LEAD_SUCCESS'
export const DELETE_FORM_LEAD_ERROR = 'DELETE_FORM_LEAD_ERROR'

/* form setting */
export const FORM_FETCH_SETTING = 'FETCH_FORM_SETTING'
export const FORM_FETCH_SETTING_SUCCESS = 'FETCH_FORM_SETTING_SUCCESS'
export const FORM_FETCH_SETTING_ERROR = 'FETCH_FORM_SETTING_ERROR'
export const FORM_SETTING_UPDATE_FIELD = 'FORM_SETTING_UPDATE_FIELD'

// Partial leads
export const SAVE_PARTIAL_LEADS_SETTINGS = 'SAVE_PARTIAL_LEADS_SETTINGS'
export const SAVE_PARTIAL_LEADS_SETTINGS_SUCCESS = 'SAVE_PARTIAL_LEADS_SETTINGS_SUCCESS'
export const FETCH_GLOBAL_PARTIAL_SETTING = 'FETCH_GLOBAL_PARTIAL_SETTING'
export const FETCH_GLOBAL_PARTIAL_LEADS_SETTINGS_SUCCESS = 'FETCH_GLOBAL_PARTIAL_LEADS_SETTINGS_SUCCESS'
export const FETCH_PARTIAL_LEADS_COUNT = 'FETCH_PARTIAL_LEADS_COUNT'

/* form experiments */
export const FORM_EXPERIMENT_CREATE_SUCCESS = 'FORM_EXPERIMENT_CREATE_SUCCESS'
export const FORM_EXPERIMENT_CREATE_ERROR = 'FORM_EXPERIMENT_CREATE_ERROR'

export const FORM_EXPERIMENT_UPDATE_SUCCESS = 'FORM_EXPERIMENT_UPDATE_SUCCESS'
export const FORM_EXPERIMENT_UPDATE_ERROR = 'FORM_EXPERIMENT_CREATE_ERROR'

export const FETCH_FORM_EXPERIMENTS_SUCCESS = 'FETCH_FORM_EXPERIMENTS_SUCCESS'
export const FETCH_FORM_EXPERIMENTS_ERROR = 'FETCH_FORM_EXPERIMENTS_ERROR'

export const FETCH_FORM_EXPERIMENT_SUCCESS = 'FETCH_FORM_EXPERIMENT_SUCCESS'
export const FETCH_FORM_EXPERIMENT_ERROR = 'FETCH_FORM_EXPERIMENT_ERROR'

export const FORM_EXPERIMENT_START_SUCCESS = 'FORM_EXPERIMENT_START_SUCCESS'
export const FORM_EXPERIMENT_START_ERROR = 'FORM_EXPERIMENT_START_ERROR'

export const FORM_EXPERIMENT_END_SUCCESS = 'FORM_EXPERIMENT_END_SUCCESS'
export const FORM_EXPERIMENT_END_ERROR = 'FORM_EXPERIMENT_END_ERROR'

export const FORM_EXPERIMENT_RESULT_SUCCESS = 'FORM_EXPERIMENT_RESULT_SUCCESS'
export const FORM_EXPERIMENT_RESULT_ERROR = 'FORM_EXPERIMENT_RESULT_ERROR'

/* form experiment variants */
export const FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS = 'FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS'
export const FETCH_FORM_EXPERIMENT_VARIANTS_ERROR = 'FETCH_FORM_EXPERIMENT_VARIANTS_ERROR'

/* form leads */
export const FETCH_LEADS_COUNT = 'FETCH_LEADS_COUNT'

/* form webhooks */
export const FETCH_FORM_WEBHOOKS_SUCCESS = 'FETCH_FORM_WEBHOOKS_SUCCESS'
export const CREATE_FORM_WEBHOOK_SUCCESS = 'CREATE_FORM_WEBHOOK_SUCCESS'

/* form hidden fields */
export const RESET_FORM_HIDDEN_FIELDS = 'RESET_FORM_HIDDEN_FIELDS'
export const FETCH_FORM_HIDDEN_FIELDS_SUCCESS = 'FETCH_FORM_HIDDEN_FIELDS_SUCCESS'
export const UPDATE_FORM_HIDDEN_FIELDS_SUCCESS = 'UPDATE_FORM_HIDDEN_FIELDS_SUCCESS'
export const REMOVE_FORM_HIDDEN_FIELD = 'REMOVE_FORM_HIDDEN_FIELD'
export const UPDATE_FORM_HIDDEN_FIELD_ATTRIBUTE = 'UPDATE_FORM_HIDDEN_FIELD_ATTRIBUTE'

/* Form Footer Text */
export const UPDATE_FORM_FOOTER_TEXT = 'UPDATE_FORM_FOOTER_TEXT'

// conversion
export const FETCH_AVG_CONV_RATE_COUNT = 'FETCH_AVERAGE_CONVERSION_RATE_COUNT'

// register
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED = 'REGISTER_FAILED'

// login
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT_USER = 'LOGOUT_USER'

// LandingPage Module
export const FETCH_LANDINGPAGE_TEMPLATES = 'FETCH_LANDINGPAGE_TEMPLATES'
export const FETCH_LANDINGPAGE_TEMPLATES_SUCCESS = 'FETCH_LANDINGPAGE_TEMPLATES_SUCCESS'
export const FETCH_LANDINGPAGE_TEMPLATES_ERROR = 'FETCH_LANDINGPAGE_TEMPLATES_ERROR'

export const CREATE_LANDINGPAGE_TPL1 = 'CREATE_LANDINGPAGE_TPL1'
export const CREATE_LANDINGPAGE_TPL1_SUCCESS = 'CREATE_LANDINGPAGE_TPL1_SUCCESS'
export const CREATE_LANDINGPAGE_TPL1_ERROR = 'CREATE_LANDINGPAGE_TPL1_ERROR'

export const UPDATE_LANDINGPAGE_TPL1 = 'UPDATE_LANDINGPAGE_TPL1'
export const UPDATE_LANDINGPAGE_TPL1_SUCCESS = 'UPDATE_LANDINGPAGE_TPL1_SUCCESS'
export const UPDATE_LANDINGPAGE_TPL1_ERROR = 'UPDATE_LANDINGPAGE_TPL1_ERROR'

export const FETCH_LANDINGPAGES = 'FETCH_LANDINGPAGES'
export const FETCH_LANDINGPAGES_SUCCESS = 'FETCH_LANDINGPAGES_SUCCESS'
export const FETCH_LANDINGPAGES_ERROR = 'FETCH_LANDINGPAGES_ERROR'

export const FETCH_LANDINGPAGE = 'FETCH_LANDINGPAGE'
export const FETCH_LANDINGPAGE_SUCCESS = 'FETCH_LANDINGPAGE_SUCCESS'
export const FETCH_LANDINGPAGE_ERROR = 'FETCH_LANDINGPAGE_ERROR'

export const FETCH_LANDINGPAGE_VIEW = 'FETCH_LANDINGPAGE_VIEW'
export const FETCH_LANDINGPAGE_VIEW_SUCCESS = 'FETCH_LANDINGPAGE_VIEW_SUCCESS'
export const FETCH_LANDINGPAGE_VIEW_ERROR = 'FETCH_LANDINGPAGE_VIEW_ERROR'

export const CREATE_LANDINGPAGE_VISIT = 'CREATE_LANDINGPAGE_VISIT'
export const CREATE_LANDINGPAGE_VISIT_SUCCESS = 'CREATE_LANDINGPAGE_VISIT_SUCCESS'
export const CREATE_LANDINGPAGE_VISIT_ERROR = 'CREATE_LANDINGPAGE_VISIT_ERROR'

export const CREATE_LANDINGPAGE_OPTIN = 'CREATE_LANDINGPAGE_OPTIN'
export const CREATE_LANDINGPAGE_OPTIN_SUCCESS = 'CREATE_LANDINGPAGE_OPTIN_SUCCESS'
export const CREATE_LANDINGPAGE_OPTIN_ERROR = 'CREATE_LANDINGPAGE_OPTIN_ERROR'

export const DELETE_LANDINGPAGES = 'DELETE_LANDINGPAGES'
export const DELETE_LANDINGPAGES_SUCCESS = 'DELETE_LANDINGPAGES_SUCCESS'
export const DELETE_LANDINGPAGES_ERROR = 'DELETE_LANDINGPAGES_ERROR'

export const UPDATE_LANDINGPAGE_SLUG = 'UPDATE_LANDINGPAGE_SLUG'
export const UPDATE_LANDINGPAGE_SLUG_SUCCESS = 'UPDATE_LANDINGPAGE_SLUG_SUCCESS'
export const UPDATE_LANDINGPAGE_SLUG_ERROR = 'UPDATE_LANDINGPAGE_SLUG_ERROR'

// Media Module
export const MM_SET_VISIBILITY = 'MM_SET_VISIBILITY'
export const MM_CHANGE_TAB = 'MM_CHANGE_TAB'

export const MM_FETCH_IMAGES = 'MM_FETCH_IMAGES'
export const MM_FETCH_IMAGES_SUCCESS = 'MM_FETCH_IMAGES_SUCCESS'
export const MM_FETCH_IMAGES_ERROR = 'MM_FETCH_IMAGES_ERROR'
export const MM_LOADING_IMAGES = 'MM_LOADING_IMAGES'
export const MM_RESET_IMAGES = 'MM_RESET_IMAGES'

export const MM_SELECT_MEDIA = 'MM_SELECT_MEDIA'
export const MM_UPLOAD_MEDIA = 'MM_UPLOAD_MEDIA'
export const MM_UPLOAD_MEDIA_SUCCESS = 'MM_UPLOAD_MEDIA_SUCCESS'
export const MM_UPLOAD_MEDIA_ERROR = 'MM_UPLOAD_MEDIA_ERROR'

// Google Recaptcha Module
export const FETCH_GOOGLE_RECAPTCHA_KEYS = 'FETCH_GOOGLE_RECAPTCHA_KEYS'
export const FETCH_GOOGLE_RECAPTCHA_KEYS_SUCCESS = 'FETCH_GOOGLE_RECAPTCHA_KEYS_SUCCESS'

export const CREATE_GOOGLE_RECAPTCHA_KEY = 'CREATE_GOOGLE_RECAPTCHA_KEY'
export const CREATE_GOOGLE_RECAPTCHA_KEY_SUCCESS = 'CREATE_GOOGLE_RECAPTCHA_KEY_SUCCESS'

export const UPDATE_GOOGLE_RECAPTCHA_KEY = 'UPDATE_GOOGLE_RECAPTCHA_KEY'
export const UPDATE_GOOGLE_RECAPTCHA_KEY_SUCCESS = 'UPDATE_GOOGLE_RECAPTCHA_KEY_SUCCESS'

export const DELETE_GOOGLE_RECAPTCHA_KEY = 'DELETE_GOOGLE_RECAPTCHA_KEY'
export const DELETE_GOOGLE_RECAPTCHA_KEY_SUCCESS = 'DELETE_GOOGLE_RECAPTCHA_KEY_SUCCESS'

// Blacklist IP Module
export const BIP_FETCH_BLACKLIST_IPS = 'BIP_FETCH_BLACKLIST_IPS'
export const BIP_FETCH_BLACKLIST_IPS_SUCCESS = 'BIP_FETCH_BLACKLIST_IPS_SUCCESS'

export const BIP_CREATE_BLACKLIST_IP = 'BIP_CREATE_BLACKLIST_IP'
export const BIP_CREATE_BLACKLIST_IP_SUCCESS = 'BIP_CREATE_BLACKLIST_IP_SUCCESS'

export const BIP_UPDATE_BLACKLIST_IP = 'BIP_UPDATE_BLACKLIST_IP'
export const BIP_UPDATE_BLACKLIST_IP_SUCCESS = 'BIP_UPDATE_BLACKLIST_IP_SUCCESS'

export const BIP_DELETE_BLACKLIST_IP = 'BIP_DELETE_BLACKLIST_IP'
export const BIP_DELETE_BLACKLIST_IP_SUCCESS = 'BIP_DELETE_BLACKLIST_IP_SUCCESS'

// External Checkout
export const ECM_CREATE_EXTERNAL_CHECKOUT = 'ECM_CREATE_EXTERNAL_CHECKOUT'
export const ECM_CREATE_EXTERNAL_CHECKOUT_SUCCESS = 'ECM_CREATE_EXTERNAL_CHECKOUT_SUCCESS'
export const ECM_UPDATE_EXTERNAL_CHECKOUT = 'ECM_UPDATE_EXTERNAL_CHECKOUT'
export const ECM_UPDATE_EXTERNAL_CHECKOUT_SUCCESS = 'ECM_UPDATE_EXTERNAL_CHECKOUT_SUCCESS'
export const ECM_FETCH_EXTERNAL_CHECKOUT = 'ECM_FETCH_EXTERNAL_CHECKOUT'
export const ECM_FETCH_EXTERNAL_CHECKOUT_SUCCESS = 'ECM_FETCH_EXTERNAL_CHECKOUT_SUCCESS'
export const ECM_FETCH_EXTERNAL_CHECKOUTS = 'ECM_FETCH_EXTERNAL_CHECKOUTS'
export const ECM_FETCH_EXTERNAL_CHECKOUTS_SUCCESS = 'ECM_FETCH_EXTERNAL_CHECKOUTS_SUCCESS'
export const ECM_DELETE_EXTERNAL_CHECKOUT = 'ECM_DELETE_EXTERNAL_CHECKOUT'
export const ECM_DELETE_EXTERNAL_CHECKOUT_SUCCESS = 'ECM_DELETE_EXTERNAL_CHECKOUT_SUCCESS'

// Form Template Builder
export const CREATE_FORM_TEMPLATE_BUILD = 'CREATE_FORM_TEMPLATE_BUILD'
export const FETCH_FORM_TEMPLATE = 'FETCH_FORM_TEMPLATE'
export const FETCH_FORM_TEMPLATE_SUCCESS = 'FETCH_FORM_TEMPLATE_SUCCESS'
export const UPDATE_FORM_TEMPLATE = 'UPDATE_FORM_TEMPLATE'

/* Icon Library Module */
export const ILM_SET_VISIBILITY = 'ILM_SET_VISIBILITY'
export const ILM_SELECT_LIBRARY = 'ILM_SELECT_LIBRARY'
export const ILM_SELECT_ICON = 'ILM_SELECT_ICON'
export const ILM_SEARCH_LIBRARY = 'ILM_SEARCH_LIBRARY'
export const ILM_FETCH_ICONS_SUCCESS = 'ILM_FETCH_ICONS_SUCCESS'
export const ILM_RESET_ICONS = 'ILM_RESET_ICONS'
export const ILM_TOGGLE_FETCHING = 'ILM_TOGGLE_FETCHING'
export const ILM_TOGGLE_CATEGORY = 'ILM_TOGGLE_CATEGORY'

// plan
export const CHANGE_USER_PLAN = 'CHANGE_USER_PLAN'
export const CHANGE_USER_PLAN_SUCCESS = 'CHANGE_USER_PLAN_SUCCESS'
export const CANCEL_USER_PLAN_SUCCESS = 'CANCEL_USER_PLAN_SUCCESS'
export const RESUME_USER_PLAN_SUCCESS = 'RESUME_USER_PLAN_SUCCESS'
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS'
export const FETCH_PLAN_SUBSCRIPTION_SUCCESS = 'FETCH_PLAN_SUBSCRIPTION_SUCCESS'

// dashboards
export const FETCH_DASHBOARD_WIDGET_SUCCESS = 'FETCH_DASHBOARD_WIDGET_SUCCESS'

// users
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const UPDATE_USER_LOCAL = 'UPDATE_USER_LOCAL'
export const REQUEST_PERMANENT_DELETE_ACCOUNT = 'REQUEST_PERMANENT_DELETE_ACCOUNT'
export const CANCEL_PERMANENT_DELETE_ACCOUNT = 'CANCEL_PERMANENT_DELETE_ACCOUNT'

// Module Settings
export const FETCH_SETTING_WHITE_LABEL_SUCCESS = 'FETCH_SETTING_WHITE_LABEL_SUCCESS'
export const UPDATE_LOCAL_SETTING_WHITE_LABEL = 'UPDATE_LOCAL_SETTING_WHITE_LABEL'
export const UPDATE_LOCAL_SETTING_CREDENTIALS = 'UPDATE_LOCAL_SETTING_CREDENTIALS'
export const UPDATE_LOCAL_SETTING_CREDENTIAL = 'UPDATE_LOCAL_SETTING_CREDENTIAL'
export const FETCH_SETTING_LEAD_NOTIFICATION_SUCCESS = 'FETCH_SETTING_LEAD_NOTIFICATION_SUCCESS'
export const UPDATE_LOCAL_SETTING_LEAD_NOTIFICATION = 'UPDATE_LOCAL_SETTING_LEAD_NOTIFICATION'

// Module Notification
export const MN_TOGGLE_VISIBILITY = 'MN_TOGGLE_VISIBILITY'

// mark read lead notifications
export const MARK_READ_LEAD_ERROR = 'MARK_READ_LEAD_ERROR'

// Form Template Browser
export const FETCH_FORM_TEMPLATES = 'FETCH_FORM_TEMPLATES'
export const FETCH_FORM_TEMPLATES_SUCCESS = 'FETCH_FORM_TEMPLATES_SUCCESS'

export const SET_FILTERED_FORM_TEMPLATES_SUCCESS = 'SET_FILTERED_FORM_TEMPLATES_SUCCESS'
export const SET_FILTERED_FORM_TEMPLATES_LENGTH = 'SET_FILTERED_FORM_TEMPLATES_LENGTH'

export const FETCH_FORM_TEMPLATE_CATEGORIES = 'FETCH_FORM_TEMPLATE_CATEGORIES'
export const FETCH_FORM_TEMPLATE_CATEGORIES_SUCCESS = 'FETCH_FORM_TEMPLATE_CATEGORIES_SUCCESS'

export const FETCH_FORM_TEMPLATE_INDUSTRIES = 'FETCH_FORM_TEMPLATE_INDUSTRIES'
export const FETCH_FORM_TEMPLATE_INDUSTRIES_SUCCESS = 'FETCH_FORM_TEMPLATE_INDUSTRIES_SUCCESS'

export const USE_FORM_TEMPLATE = 'USE_FORM_TEMPLATE'
export const USE_FORM_TEMPLATE_ERROR = 'USE_FORM_TEMPLATE_ERROR'

// FORM LISTS
export const FETCH_FORM_LISTS = 'FETCH_FORM_LISTS'
export const FETCH_FORM_LISTS_SUCCESS = 'FETCH_FORM_LISTS_SUCCESS'
export const BULK_DELETE_FORMS = 'BULK_DELETE_FORMS'
export const BULK_DELETE_FORMS_SUCCESS = 'BULK_DELETE_FORMS_SUCCESS'
export const BULK_DUPLICATE_FORMS = 'BULK_DUPLICATE_FORMS'
export const BULK_DUPLICATE_FORMS_SUCCESS = 'BULK_DUPLICATE_FORMS_SUCCESS'

// USER LISTS
export const FETCH_USER_LISTS = 'FETCH_USER_LISTS'
export const FETCH_USER_LISTS_SUCCESS = 'FETCH_USER_LISTS_SUCCESS'

// LEADPROOF LISTS
export const FETCH_LEAD_PROOF_LISTS = 'FETCH_LEAD_PROOF_LISTS'
export const FETCH_LEAD_PROOF_LISTS_SUCCESS = 'FETCH_LEAD_PROOF_LISTS_SUCCESS'
export const DELETE_LEAD_PROOF = 'DELETE_LEAD_PROOF'
export const DELETE_LEAD_PROOF_SUCCESS = 'DELETE_LEAD_PROOF_SUCCESS'
export const FETCH_LEAD_PROOF_LIST_COUNT = 'FETCH_LEAD_PROOF_LIST_COUNT'

// TEMPLATE LISTS
export const FETCH_FORM_TEMPLATE_LISTS = 'FETCH_FORM_TEMPLATE_LISTS'
export const FETCH_FORM_TEMPLATE_LISTS_SUCCESS = 'FETCH_FORM_TEMPLATE_LISTS_SUCCESS'
export const DELETE_FORM_TEMPLATE = 'DELETE_FORM_TEMPLATE'
export const DELETE_FORM_TEMPLATE_SUCCESS = 'DELETE_FORM_TEMPLATE_SUCCESS'

// Form Connections
export const FETCH_FORM_CONNECTION = 'FETCH_FORM_CONNECTION'
export const FETCH_FORM_CONNECTION_SUCCESS = ' FETCH_FORM_CONNECTION_SUCCESS'
export const FETCH_FORM_CONNECTIONS = ' FETCH_FORM_CONNECTIONS'
export const FETCH_FORM_CONNECTIONS_SUCCESS = ' FETCH_FORM_CONNECTIONS_SUCCESS'

// Contact State Module
export const FETCH_CONTACT_STATE = 'FETCH_CONTACT_STATE'
export const FETCH_CONTACT_STATES_SUCCESS = 'FETCH_CONTACT_STATES_SUCCESS'
export const EDIT_FORM_CONNECTION = 'EDIT_FORM_CONNECTION'

export const CREATE_CONTACT_STATE = 'CREATE_CONTACT_STATE'
export const CREATE_CONTACT_STATE_SUCCESS = 'CREATE_CONTACT_STATE_SUCCESS'
export const CREATE_CONTACT_STATE_FAILED = 'CREATE_CONTACT_STATE_FAILED'

export const UPDATE_CONTACT_STATE = 'UPDATE_CONTACT_STATE'
export const UPDATE_CONTACT_STATE_SUCCESS = 'UPDATE_CONTACT_STATE_SUCCESS'
export const UPDATE_CONTACT_STATE_FAILED = 'UPDATE_CONTACT_STATE_FAILED'

export const GET_CONTACT_STATE = 'GET_CONTACT_STATE'
export const GET_CONTACT_STATE_SUCCESS = 'GET_CONTACT_STATE_SUCCESS'

export const DELETE_CONTACT_STATE = 'DELETE_CONTACT_STATE'
export const DELETE_CONTACT_STATE_SUCCESS = 'DELETE_CONTACT_STATE_SUCCESS'

/* Integration */
export const FORM_FETCH_INTEGRATION = 'FORM_FETCH_INTEGRATION'
export const FORM_FETCH_INTEGRATION_SUCCESS = 'FORM_FETCH_INTEGRATION_SUCCESS'

/* User-settings */
export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS'
export const FETCH_USER_SETTING_SUCCESS = 'FETCH_USER_SETTING_SUCCESS'
export const USER_SETTING_UPDATE_FIELD = 'USER_SETTING_UPDATE_FIELD'
export const SAVE_USER_SETTING = 'SAVE_USER_SETTING'

// PACKAGE BUILDER
export const FETCH_AVAILABLE_FEATURES = 'FETCH_AVAILABLE_FEATURES'
export const FETCH_AVAILABLE_FEATURES_SUCCESS = 'FETCH_AVAILABLE_FEATURES_SUCCESS'
export const FETCH_FEATURE_PROPERTIES = 'FETCH_FEATURE_PROPERTIES'
export const FETCH_FEATURE_PROPERTIES_SUCCESS = 'FETCH_FEATURE_PROPERTIES_SUCCESS'
export const FETCH_PACKAGE_LISTS = 'FETCH_PACKAGE_LISTS'
export const FETCH_PACKAGE_LISTS_SUCCESS = 'FETCH_PACKAGE_LISTS_SUCCESS'
export const FETCH_PACKAGE_DATA = 'FETCH_PACKAGE_DATA'
export const SAVE_PACKAGE_DATA = 'SAVE_PACKAGE_DATA'
export const UPDATE_PACKAGE_DATA = 'UPDATE_PACKAGE_DATA'
export const FETCH_FORM_REPORT_DATA = 'FETCH_FORM_REPORT_DATA'

// UTM Params
export const CREATE_UTM_PARAMS = 'CREATE_UTM_PARAMS'
export const CREATE_UTM_PARAMS_SUCCESS = 'CREATE_UTM_PARAMS_SUCCESS'
export const UPDATE_UTM_PARAMS = 'UPDATE_UTM_PARAMS'
export const FETCH_UTM_PARAM = 'FETCH_UTM_PARAM'
export const FETCH_UTM_PARAM_SUCCESS = 'FETCH_UTM_PARAM_SUCCESS'
export const FETCH_UTM_PARAMS_LISTS = 'FETCH_UTM_PARAMS_LISTS'
export const FETCH_UTM_PARAMS_LISTS_SUCCESS = 'FETCH_UTM_PARAMS_LISTS_SUCCESS'
export const DELETE_UTM_PARAM = 'DELETE_UTM_PARAM'
export const DELETE_UTM_PARAM_SUCCESS = 'DELETE_UTM_PARAM_SUCCESS'

// FORM ENDINGS
export const FETCH_FORM_ENDINGS = 'FETCH_FORM_ENDINGS'
export const UPDATE_FORM_ENDINGS_DATA = 'UPDATE_FORM_ENDINGS_DATA'
export const FETCH_FORM_ENDINGS_DATA = 'FETCH_FORM_ENDINGS_DATA'
export const FETCH_FORM_ENDINGS_SUCCESS = 'FETCH_FORM_ENDINGS_SUCCESS'
export const FETCH_FORM_QUESTIONS = 'FETCH_FORM_QUESTIONS'
export const FETCH_FORM_QUESTIONS_SUCCESS = 'FETCH_FORM_QUESTIONS_SUCCESS'
export const DELETE_FORM_ENDING = 'DELETE_FORM_ENDING'
export const BULK_DELETE_FORM_ENDINGS = 'BULK_DELETE_FORM_ENDINGS'
export const BULK_DELETE_FORM_ENDINGS_SUCCESS = 'BULK_DELETE_FORM_ENDINGS_SUCCESS'
export const DUPLICATE_FORM_ENDING = 'DUPLICATE_FORM_ENDING'
export const BULK_DUPLICATE_FORM_ENDINGS = 'BULK_DUPLICATE_FORM_ENDINGS'
export const BULK_DUPLICATE_FORM_ENDINGS_SUCCESS = 'BULK_DUPLICATE_FORM_ENDINGS_SUCCESS'
export const SET_VISITOR_PRIORITY = 'SET_VISITOR_PRIORITY'
