<template>
  <v-container class="user-profile-view">
    <div v-if="loader.profile">
      <!-- USER PROFILE LOADER-->
      <div class="user-profile-view__loading" v-if="!flag.errorProfile">
        <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
          color="secondary-red"></v-progress-circular>
      </div>
      <!-- USER PROFILE LOAD ERROR-->
      <div class="user-profile-view__error" v-else>
        <h3>Whoops, looks like something went wrong. Please click the button to try again.</h3>
        <v-btn variant="elevated" color="primary-red" class="text-none font-weight-bold" rounded="md" size="large"
          prepend-icon="mdi-reload" @click="reloadPage()">
          Reload
        </v-btn>
      </div>
    </div>

    <!-- my profile page card-->
    <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm" v-else>
      <v-card-title class="bg-blue-lighten-5 text-primary-blue">
        <h6 class="d-flex justify-left mb-0">
          <v-icon icon="mdi-account-edit" class="pt-1"></v-icon>
          <p class="font-weight-bold text-primary-blue mb-0">&nbsp;My Profile</p>
        </h6>
      </v-card-title>
      <v-divider></v-divider>

      <!-- General Details Section -->
      <v-card-subtitle class="pa-5 mx-2 my-1 text-high-emphasis">
        <v-row>
          <v-col cols="auto" class="d-flex justify-center">
            <v-avatar color="secondary-blue" size="60">
              <h5 class="mb-0 text-wrap">{{ getInitials(user.name) }}</h5>
            </v-avatar>
          </v-col>
          <v-col class="d-flex flex-column justify-center">
            <span class="font-weight-bold">{{ user.name }}</span>
            <p class="text-grey-darken-3 text-wrap">Joined LeadGen on {{ localeDate(user.created_at) }}</p>
          </v-col>
        </v-row>
      </v-card-subtitle>

      <v-divider class="mx-9"></v-divider>

      <v-card-text class="px-5 mx-4">

        <!-- Login Details Section -->
        <v-row class="mb-2">
          <v-col cols="12" class="d-flex flex-column text-start">
            <h6 class="font-weight-black text-uppercase">LOGIN DETAILS</h6>
            <p class="text-wrap">Your email is: {{ profileDetails.email }}</p>
            <v-row>
              <v-col cols="auto">
                <v-btn rounded="md" color="grey-darken-3" class="font-weight-bold text-capitalize" variant="tonal"
                  :loading="loader.email" @click="openCredentialModal(true)">
                  change email
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn rounded="md" color="grey-darken-3" class="font-weight-bold text-capitalize" variant="tonal"
                  :loading="loader.password" @click="openCredentialModal(false)">
                  change password
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <!-- Security Section -->
        <v-row class="my-2" v-if="profileDetails.hasCredential">
          <v-col cols="12" class="d-flex flex-column text-start">
            <h6 class="font-weight-bold text-uppercase">SECURITY</h6>
            <p class="text-wrap">
              Two-factor authentication (2FA) makes your account more secure by
              adding an extra verification step when you log in.
            </p>
            <v-row>
              <v-col cols="auto">
                <v-btn rounded="md" color="grey-darken-3" class="font-weight-bold text-capitalize" variant="tonal"
                  @click="openSettingModal(true)" :loading="loader.twoFA">
                  {{ profileDetails.isTwoFactorEnabled ? 'Disable' : 'Enable' }} 2FA
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider v-if="profileDetails.hasCredential"></v-divider>

        <!-- Delete Section -->
        <v-row class="my-2">
          <v-col cols="12" class="d-flex flex-column text-start">
            <h6 class="font-weight-bold text-uppercase">Danger zone</h6>
            <p class="text-wrap">
              {{ profileDetails.isArchived
                ? 'This account will be deleted after 24 hours. If you wish to stop the deletion process, please click \
              the button below.'
                : 'This action will permanently delete the account, along with all associated data, from the LeadGen \
              system.Once deleted, there will be no way to restore the user account.' }}
            </p>
            <v-row>
              <v-col cols="auto">
                <v-btn :color="profileDetails.isArchived ? 'primary-green' : 'primary-red'" variant="elevated"
                  rounded="md" class="font-weight-bold text-capitalize" id="delete-cancel"
                  @click="profileDetails.isArchived ? userDeleteOrCancel(false) : openSettingModal(false)"
                  :loading="loader.delete">
                  {{ profileDetails.isArchived ? 'cancel deletion' : 'delete account' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- dialog change email -->
    <v-dialog rounded="sm" max-width="550" persistent v-model="dialog.openEmailDialog">
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Change your email</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row class="mt-0">
            <v-col cols="12">
              <div v-if="profileDetails.isGoogleUser && !profileDetails.hasCredential">
                <v-alert border type="warning" variant="tonal" rounded="md" density="compact" class="mb-6">
                  <p class="text-black mb-0">Since your profile is linked to a Google account, you must set a password
                    first for
                    this to
                    function correctly.</p>
                </v-alert>
              </div>
              <v-form ref="emailUpdateForm" v-else>
                <!-- new email input -->
                <v-text-field variant="outlined" label="Your new email" color="secondary-red"
                  prepend-inner-icon="mdi-email-edit-outline" density="comfortable" rounded="md" clearable
                  :rules="$valid([{ rule: 'required', msg: 'Please set new email' }, { rule: 'email' }])"
                  v-model="change.newEmail"></v-text-field>
                <!-- current password input -->
                <v-text-field variant="outlined" label="Your password (for verification)" color="secondary-red"
                  prepend-inner-icon="mdi-lock-check-outline" density="comfortable" rounded="md" clearable
                  :append-inner-icon="flag.currentP ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="flag.currentP ? 'text' : 'password'" @click:append-inner="flag.currentP = !flag.currentP"
                  :rules="$valid([{ rule: 'required', msg: 'Please enter your current password' }])"
                  v-model="change.currentPassword"></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="dialog.openEmailDialog = false">
          </v-btn>
          <v-btn rounded="md" variant="elevated" class="text-none font-weight-bold" color="primary-red" type="submit"
            @click="profileDetails.isGoogleUser && !profileDetails.hasCredential ? sendPasswordResetLink(false) : updateCredential(true)"
            :loading="loader.email">
            <span>{{ profileDetails.isGoogleUser && !profileDetails.hasCredential ? 'Send Password Link' : 'Confirm'
              }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog change password -->
    <v-dialog rounded="sm" max-width="550" persistent v-model="dialog.openPasswordDialog">
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Change your password</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row class="mt-0">
            <v-col cols="12" class="pt-0">
              <div v-if="profileDetails.isGoogleUser && !profileDetails.hasCredential">
                <v-alert border type="warning" variant="tonal" rounded="md" density="compact" class="mb-6">
                  <p class="text-black mb-0">Considering your profile's connection to a Google account, password reset
                    takes
                    place via email.</p>
                </v-alert>
              </div>
              <v-form ref="passwordUpdateForm" v-else @submit.prevent="updateCredential">
                <v-alert border type="info" variant="tonal" rounded="md" density="compact" class="mt-0 mb-6">
                  <p class="text-black mb-0">The change will lead to a logout. Sign in again with the updated password
                    to
                    continue.</p>
                </v-alert>
                <!-- current password input -->
                <v-text-field variant="outlined" label="Your password (for verification)" color="secondary-red"
                  prepend-inner-icon="mdi-lock-outline" density="comfortable" rounded="md" clearable
                  :append-inner-icon="flag.currentP ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="flag.currentP ? 'text' : 'password'" @click:append-inner="flag.currentP = !flag.currentP"
                  :rules="$valid([{ rule: 'required', msg: 'Please enter your current password' }])"
                  v-model="change.currentPassword"></v-text-field>
                <!-- new password input -->
                <v-text-field variant="outlined" label="Your new password" color="secondary-red"
                  prepend-inner-icon="mdi-lock-plus-outline" density="comfortable" rounded="md" clearable
                  :append-inner-icon="flag.newP ? 'mdi-eye' : 'mdi-eye-off'" :type="flag.newP ? 'text' : 'password'"
                  @click:append-inner="flag.newP = !flag.newP" :rules="$valid([
                    { rule: 'required', msg: 'Please enter your new password' },
                    { rule: 'password' },
                    { rule: 'between', min: '8', max: '150' }
                  ])" v-model="change.newPassword"></v-text-field>
                <!-- confirm password input -->
                <v-text-field variant="outlined" label="Confirm your new password" color="secondary-red"
                  prepend-inner-icon="mdi-lock-check-outline" density="comfortable" rounded="md" clearable :rules="$valid([
                    { rule: 'required', msg: 'Please confirm your password' },
                    { rule: 'password' },
                    { rule: 'confirm', field: change.newPassword },
                    { rule: 'between', min: '8', max: '150' }
                  ])" v-model="change.confirmPassword"></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="dialog.openPasswordDialog = false">
          </v-btn>
          <v-btn rounded="md" variant="elevated" class="text-none font-weight-bold" color="primary-red" type="submit"
            @click="profileDetails.isGoogleUser && !profileDetails.hasCredential ? sendPasswordResetLink(true) : updateCredential(false)"
            :loading="loader.password">
            <span>{{ profileDetails.isGoogleUser && !profileDetails.hasCredential ? 'Send Password Link' : 'Confirm'
              }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog change 2FA/Delete Account -->
    <v-dialog rounded="sm" max-width="550" v-model="dialog.openSettingDialog" persistent>
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row class="mt-2">
            <v-col cols="12">
              <v-alert border :type="flag.isDeleteInitiated ? 'error' : 'warning'" variant="tonal" rounded="md"
                density="compact" icon="mdi-information">
                <p class="text-black mb-0">
                      {{ flag.isDeleteInitiated
                      ? 'This action permanently deletes the account and all associated data from the LeadGen system. Once deleted, there will be no way to restore the user account.'
                      : 'By enabling Two-factor authentication (2FA), your account gains an extra layer of security through an additional verification step during login.'
                     }}
                    </p>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="dialog.openSettingDialog = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="primary-red" type="submit"
            @click="flag.isDeleteInitiated ? changeAccountSetting(false) : changeAccountSetting(true)"
            :loading="flag.isDeleteInitiated ? loader.delete : loader.twoFA">
            <span>{{ flag.isDeleteInitiated ? 'Confirm' : profileDetails.isTwoFactorEnabled ? 'Disable' : 'Enable'
              }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- action snackbar -->
    <v-snackbar rounded="md" height="50" :color="snackbar.error ? 'secondary-red' : 'primary-green'" :timeout="3000"
      v-model="snackbar.show">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
    </v-snackbar>
  </v-container>
</template>

<script>
import helperMixin from '@/mixins/helper'
import userMixin from '@/mixins/user'
import * as actionTypes from '@/store/action-types'

export default {
  mixins: [userMixin, helperMixin],
  data() {
    return {
      change: {
        newEmail: null,
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
        message: ''
      },
      flag: {
        currentP: false,
        newP: false,
        errorProfile: false,
        isDeleteInitiated: false,
        isEmailChangeInitiated: false,
      },
      dialog: {
        openEmailDialog: false,
        openPasswordDialog: false,
        openSettingDialog: false,
      },
      loader: {
        profile: true,
        twoFA: false,
        delete: false,
        email: false,
        password: false
      },
      snackbar: {
        show: false,
        text: null,
        error: false
      },
      profileDetails: {
        email: '',
        isTwoFactorEnabled: false,
        isGoogleUser: false,
        hasCredential: false,
        isArchived: false
      }
    }
  },
  mounted() {
    this.loadProfileSettings()
  },
  methods: {
    loadProfileSettings: function () {
      this.$store.dispatch('user/fetchProfileSettings', {
        userId: this.user.id
      }).then((response) => {
        setTimeout(() => {
          this.loader.profile = false
          this.profileDetails = {
            email: response.data.data.email,
            isTwoFactorEnabled: response.data.data.two_factor_enabled,
            isGoogleUser: response.data.data.is_google_user,
            hasCredential: response.data.data.has_credential,
            isArchived: response.data.data.is_inactive
          }
        }, 10)
      }).catch(() => {
        this.flag.errorProfile = true
      })
    },
    reloadPage: function () {
      window.location.reload()
    },
    getInitials: function (name) {
      const parts = name.trim().split(/\s+/)
      return parts.map(part => part.charAt(0).toUpperCase()).slice(0, 2).join('')
    },
    resetChange() {
      this.change = {
        newEmail: null,
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
        message: ''
      }
    },
    openSettingModal(flag) {
      this.flag.isDeleteInitiated = !flag
      this.dialog.openSettingDialog = true
    },
    changeAccountSetting(flag) {
      this.flag.isDeleteInitiated = !flag
      flag ? this.user2FAChange() : this.userDeleteOrCancel(true)
    },
    user2FAChange: function () {
      this.loader.twoFA = true
      this.$store.dispatch('user/reset2FASettings', {
        userId: this.user.id,
        payload: {
          twoFactor: this.profileDetails.isTwoFactorEnabled ? 0 : 1
        }
      }).then((response) => {
        setTimeout(() => {
          this.loader.twoFA = false
          this.dialog.openSettingDialog = false
          this.profileDetails.isTwoFactorEnabled = response.data.data.enabled
        }, 500)
      }).catch((err) => {
        this.loader.twoFA = false
        this.dialog.openSettingDialog = false
        let message = err.response && err.response.data && err.response.data.meta
          ? err.response.data.meta.error_message : 'Unable to change 2FA settings. Please try again later.'
        this.showSnackbar(true, message)
      })
    },
    userDeleteOrCancel: function (flag) {
      this.loader.delete = true
      let action = flag ? 'requestUserDelete' : 'cancelUserDelete'
      this.$store.dispatch(`user/${action}`, {
        userId: this.user.id
      }).then((response) => {
        setTimeout(() => {
          this.loader.delete = false
          this.dialog.openSettingDialog = false
          this.profileDetails.isArchived = flag
        }, 500)
      }).catch((err) => {
        this.loader.delete = false
        this.dialog.openSettingDialog = false
        let defaultMsg = flag ? 'delete account.' : 'cancel deletion.'
        let message = err.response && err.response.data && err.response.data.meta
          ? err.response.data.meta.error_message : 'Unable to ' + defaultMsg + ' Please try again later.'
        this.showSnackbar(true, message)

      })
    },
    sendPasswordResetLink: function (isPassword) {
      this.loader.email = !isPassword
      this.loader.password = isPassword
      this.$store.dispatch('user/resetUserPassword', {
        email: this.user.email
      }).then((response) => {
        setTimeout(() => {
          this.loader.email = false
          this.loader.password = false
          this.dialog = {
            openEmailDialog: false,
            openPasswordDialog: false,
            openSettingDialog: false,
          },
            this.showSnackbar(false, 'Please check your email and click the button to create a new password.')
        }, 500)
      }).catch((err) => {
        this.loader.email = false
        this.loader.password = false
        let message = err?.response?.data?.meta?.error_message ?? 'Unable to send reset link! Please try again later.'
        this.showSnackbar(true, message)
      })
    },
    openCredentialModal(flag) {
      this.resetChange()
      flag ? this.dialog.openEmailDialog = true : this.dialog.openPasswordDialog = true
    },
    updateCredential: async function (flag) {
      let formRef = flag ? this.$refs.emailUpdateForm : this.$refs.passwordUpdateForm
      const { valid } = await formRef.validate()
      if (!valid) {
        return // Prevent submission if the form is not valid
      }
      let dispatch = flag ? 'changeUserEmail' : 'changeUserPassword'
      let payload = flag ? {
        email: this.change.newEmail,
        password: this.change.currentPassword
      } : {
        oldPassword: this.change.currentPassword,
        newPassword: this.change.confirmPassword
      }
      let dispatchData = { userId: this.user.id, data: payload }
      flag ? this.loader.email = true : this.loader.password = true
      this.$store.dispatch(`user/${dispatch}`, dispatchData)
        .then((response) => {
          setTimeout(() => {
            this.loader.email = false
            this.loader.password = false
            let message = flag ? 'Email updated! Use the new email to log in.' : 'Password updated! Use the new password to log in.'
            this.profileDetails.email = this.change.newEmail
            this.showSnackbar(false, message)
            // Close the modal
            flag ? this.dialog.openEmailDialog = false : this.dialog.openPasswordDialog = false
            if (!flag) {
              this.$store.dispatch(actionTypes.LOGOUT_USER).then((result) => {
                this.$router.push({ name: 'login' })
              })
            }
          }, 500)
        }).catch((err) => {
          this.loader.email = false
          this.loader.password = false
          let message = err?.response?.data?.meta?.error_message ?? 'Unable to update credentials! Please try again later.'
          this.showSnackbar(true, message)
          // Close the modal
          flag ? this.dialog.openEmailDialog = false : this.dialog.openPasswordDialog = false
        })
    },
    showSnackbar: function (flag, message) {
      this.snackbar.show = true
      this.snackbar.error = flag
      this.snackbar.text = message
    }
  }
}
</script>
