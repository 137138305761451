export default {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  WEEK_TO_DATE: 'week_to_date',
  LAST_WEEK: 'last_week',
  THIS_WEEK: 'this_week',
  MONTH_TO_DATE: 'month_to_date',
  LAST_MONTH: 'last_month',
  LAST_3_MONTHS: 'last_3_months',
  LAST_6_MONTHS: 'last_6_months',
  YEAR_TO_DATE: 'year_to_date',
  LAST_YEAR: 'last_year',
  ALL_TIME: 'all_time',
  CUSTOM: 'custom',
  PREVIOUS_PERIOD: 'previous_period',
  PREVIOUS_YEAR: 'previous_year',
  NONE: 'NONE',
  AS_PER_PLAN: 'AS_PER_PLAN',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY'
}
