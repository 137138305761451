<template>
  <div id="form-edit-view">
    <div class="loader text-center"  v-if="loading">
      <v-progress-circular v-show="true" :size="40" :width="3" indeterminate
        color="secondary-red"></v-progress-circular>
    </div>
    <div v-else>
      <form-builder action="update" :tab="$route.params.tab"></form-builder>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import FormBuilder from '@/components/FormBuilder'

export default {
  components: {
    'form-builder': FormBuilder
  },

  data: function () {
    return {
      loading: true,
      newChanges: false
    }
  },

  mounted: function () {
    this.fetchBuilderState()
  },

  methods: {
    fetchBuilderState: function () {
      let self = this
      self.$store.dispatch(actionTypes.FORM_FETCH_VARIANT_STATE, {
        formId: self.$route.params.id,
        variantId: self.$route.params.variantId
      }).then((response) => {
        self.$store.dispatch(actionTypes.FORMBUILDER_RESET, {
          state: response.data.data
        }).then(() => {
          self.loading = false
        })
      }, (response) => {
        self.$router.push({ name: 'forms.overview', params: { id: this.$route.params.id } })
      })
    },
    previewForm: function () {
      let routeData = this.$router.resolve({ name: 'forms.variants.preview', params: { id: this.$route.params.id || 0, variantId: this.$route.params.variantId || 0 } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
