export default {
  STEP: 'step',
  KEYWORD: 'keyword',
  SINGLE: 'single',
  MULTI: 'multi',
  INDUSTRY: 'industry',
  CATEGORY: 'category',
  TEMPLATE_CATEGORIES: 'template_categories',
  TEMPLATE_INDUSTRIES: 'template_industries'
}
