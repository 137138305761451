<template>
  <div class="answer-based-outcome-cpt">
    <div class="container">
      <condition-builder-header headerMessage="form submission"></condition-builder-header>
      <condition-builder ref="conditionBuilder" :endingTrigger="formQuestions" :endingType="answerBased.type"
        :isEditView="isEditView" :placeholderTrigger="answerBased.placeholderTrigger"
        :placeholderState="answerBased.placeholderState">
      </condition-builder>
    </div>
  </div>
</template>

<script>
import { formEndingTypes } from '@/shared/constants/formEndingTypes'
import { editorMixin } from '@/mixins/form-endings'
import userMixin from '@/mixins/user'
import ConditionBuilder from '@/components/form-builder/Elements/ConditionBuilder'
import ConditionBuilderHeader from './ConditionBuilderHeader.vue'

export default {
  mixins: [editorMixin, userMixin],
  components: {
    'condition-builder': ConditionBuilder,
    ConditionBuilderHeader
  },
  data: function () {
    return {
      formConditionBuilderScope: 'formConditionBuilder',
      answerBased: {
        type: formEndingTypes.ANSWERBASED,
        placeholderTrigger: 'Select a question to apply a condition',
        placeholderState: 'Choose the state of the selected question'
      }
    }
  },
  mounted: function () {
    this.endingsMixin_getFormQuestions()
  },
  computed: {
    isEditView: function () {
      return this.$route.name === 'forms.variants.answerbased.edit'
    }
  }
}
</script>
<style></style>
