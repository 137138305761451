export const multiSelectSkins = () => [
  {
    id: 'button',
    label: 'Button',
    alignment: 'vertical'
  },
  {
    id: 'checkbox',
    label: 'Checkbox',
    alignment: 'vertical'
  },
  {
    id: 'dropdown',
    label: 'Dropdown'
  },
  {
    id: 'image',
    label: 'Image',
    alignment: 'horizontal',
    layout: 'boxed_content',
    column: '3'
  },
  {
    id: 'icon',
    label: 'Icon',
    alignment: 'horizontal',
    layout: 'boxed_content',
    column: '3'
  }
]

export const singleSelectSkins = () => [
  {
    id: 'button',
    label: 'Button',
    alignment: 'vertical'
  },
  {
    id: 'radio',
    label: 'Radio',
    alignment: 'vertical'
  },
  {
    id: 'dropdown',
    label: 'Dropdown'
  },
  {
    id: 'image',
    label: 'Image',
    alignment: 'horizontal',
    layout: 'boxed_content',
    column: '3'
  },
  {
    id: 'icon',
    label: 'Icon',
    alignment: 'horizontal',
    layout: 'boxed_content',
    column: '3'
  },
  {
    id: 'radio_outline',
    label: 'Line Radio Button',
    alignment: 'vertical'
  }
]

export const alignments = () => [
  {
    label: 'Vertical',
    value: 'vertical'
  },
  {
    label: 'Vertical Center',
    value: 'vertical_center'
  },
  {
    label: 'Horizontal',
    value: 'horizontal'
  },
  {
    label: 'Horizontal Center',
    value: 'horizontal_center'
  },
  {
    label: 'Horizontal Space Between',
    value: 'horizontal_space_between'
  },
  {
    label: 'Horizontal Space Around',
    value: 'horizontal_space_around'
  }
]
