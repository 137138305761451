export default {
  methods: {
    parseIcon(iconStr) {
      const iconObj = {
        class: '',
        text: ''
      }

      if (!iconStr) {
        return iconObj
      }

      if (iconStr.search(/material-icons/g) === 0) {
        const iconArr = iconStr.split(' ')

        iconObj.text = iconArr.pop()
        iconObj.class = iconArr.pop()
      }

      if (iconStr.search(/(fas |far |fab )/g) === 0) {
        iconObj.class = iconStr
      }

      return iconObj
    }
  }
}
