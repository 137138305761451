import { getData } from '../plugins/ApiCalls'

export function pauseSubscription(id) {
  return getData('subscriptions/' + id + '/pause')
}

export function resumeSubscription(id) {
  return getData('subscriptions/' + id + '/resume')
}

export function cancelSubscription(id) {
  return getData('subscriptions/' + id + '/cancel')
}

export function updateSubscription(id, params) {
  if (params) {
    return getData('subscriptions/' + id + '/update', {
      params
    })
  }
  return getData('subscriptions/' + id + '/update')
}

export function getPastDueSubscription(id) {
  return getData('subscriptions/' + id + '/past-due')
}

export function listPayment(id) {
  return getData('subscriptions/' + id + '/list-payment')
}

export function listDiscountCoupon() {
  return getData('subscriptions/list-coupon')
}

export function listTransaction(id) {
  return getData('subscriptions/' + id + '/list-transaction')
}
