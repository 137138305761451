import { postData, getData, putData, deleteData } from "../plugins/ApiCalls"

export function create(data) {
  return postData('forms', data, { emulateJSON: true })
}

export function getByUser(page = 1) {
  return getData('forms')
}

export function getFormByUser(formId) {
  return getData('forms/' + formId)
}

export function getForm(id) {
  return getData('forms/' + id)
}

export function updateForm(id, data) {
  return putData('forms/' + id, data, { emulateJSON: true })
}

export function getForms(data) {
  let params = new URLSearchParams()

  if (data && data.page) {
    params.append('page', data.page)
  }

  if (data && data.search) {
    params.append('q', encodeURIComponent(JSON.stringify(data.search)))
  }

  return getData('forms?' + params.toString())
}

export function getFormByKey(key, variantId, visitorId) {
  if (variantId) {
    return postData(
      'forms/' + key + '/variants/' + variantId + '/preview',
      { 'leadgen_visitor_id': visitorId },
      { emulateJSON: true }
    )
  } else {
    return postData(
      'forms/key/' + key,
      { 'leadgen_visitor_id': visitorId },
      { emulateJSON: true }
    )
  }
}

export function saveLead(data) {
  return postData('leads', data, { emulateJSON: true })
}

export function deleteLead(data) {
  return deleteData('leads/' + data.leadId)
}

export function getLeads(formId, variantId, experimentId, page) {
  if (variantId && experimentId) {
    return getData('forms/' + formId + '/leads/' + variantId + '/' + experimentId + '?page=' + page)
  } else if (variantId) {
    return getData('forms/' + formId + '/leads/' + variantId + '?page=' + page)
  } else {
    return getData('forms/' + formId + '/leads' + '?page=' + page)
  }
}

export function formsCount() {
  return getData('forms/count')
}

export function getSetting(id) {
  return getData('forms/' + id + '/setting')
}

export function saveSetting(id, data) {
  return putData('forms/' + id + '/setting', data, { emulateJSON: true })
}

export function getOverview(id) {
  return getData('forms/' + id + '/overview')
}

export function duplicate(id) {
  return getData('forms/' + id + '/duplicate')
}

export function archive(id) {
  return deleteData('forms/' + id + '/archive')
}

export function resetFormStatus(id) {
  return deleteData('forms/' + id + '/resetstatus')
}

export function share(data) {
  return postData('forms/' + data.form_id + '/share', data)
}

export function getFormLists(data) {
  return getData('forms/list?listParams=' + encodeURIComponent(JSON.stringify(data)))
}

export function bulkDeleteForms(data) {
  return deleteData(`forms/bulkdelete?ids=${data.checked}&userId=${data.userId}`)
}

export function bulkDuplicateForms(data) {
  return getData(`forms/bulkduplicate?ids=${data.checked}&userId=${data.userId}`)
}

export function getFormTrackingEvents(formId) {
  return getData('forms/' + formId + '/tracking-events')
}

export function getPartialLeadsCount(id) {
  return getData('partial/' + id + '/count')
}

export function updateFooterText(formId, data) {
  return putData('forms/' + formId + '/footerText', data, { emulateJSON: true })
}

export function getCompleteLeadsCount(id) {
  return getData('leads/' + id + '/count')
}
