<template>
  <v-container style="width:90%">
    <h4 class="page-title">Templates</h4>
    <v-row>
      <v-col class="text-end mb-n10">
        <v-btn color="primary-red font-weight-bold text-none" @click="$router.push({ name: 'template-builder.create' })"
          prepend-icon="mdi-plus">
          Create Template
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <general-list :fields="fields" :loadedError="load.loadedError" :loaded="load.loaded" :listData="listData"
        :listPagination="listPagination" @onListParamsChange="loadTemplates($event)" ref="generalList">
        <template v-slot:Title="props">
          <router-link class="text-secondary-red"
            :to="{ name: 'template-builder.edit', params: { id: props.rowData.id } }">
            {{ props.rowData.title }}
          </router-link>
        </template>
        <template v-slot:Description="props">
          {{ props.rowData.description }}
        </template>
        <template v-slot:TemplateID="props">
          {{ props.rowData.template_id }}
        </template>
        <template v-slot:Action="props">
          <v-icon @click="openDeleteFormTemplateModal(props.rowData.id)" title="Delete"
            color="primary-red">mdi-delete</v-icon>
        </template>
      </general-list>
    </div>

    <!-- delete  Form Template modal -->
    <v-dialog v-model="this.delete.isOpen" max-width="600px">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert v-if="this.delete.deleted" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">Unable to delete this form template.</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to delete this form template. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="this.delete.isOpen = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
            :loading="this.delete.deleting" @click="deleteFormTemplate">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GeneralList from '@/components/lists/GeneralList'
import { mapGetters } from 'vuex'

export default {
  components: {
    'general-list': GeneralList
  },
  data: function () {
    return {
      delete: {
        isOpen: false,
        deleted: false,
        deleting: false,
        deleteSuccess: false,
      },
      load: {
        loaded: false,
        loadedError: false,
      },
      selectedFormTemplate: -1,
      listData: [],
      listPagination: {},
      fields: [
        {
          name: 'Title',
          sortField: 'title',
          searchField: 'title'
        },
        {
          name: 'Description'
        },
        {
          name: 'TemplateID'
        },
        {
          name: 'Action'
        }
      ]
    }
  },
  mounted: function () {
    this.loadTemplates()
  },
  methods: {
    loadTemplates: function (params) {
      if (this.isAdmin) {
        if (!params) {
          return
        }
        params.resource = 'form_template'
        this.load.loaded = false
        this.load.loadedError = false
        this.$store.dispatch('formtemplatelists/getFormTemplateList', params)
          .then((response) => {
            this.$store.dispatch('formtemplatelists/getFormTemplateListSuccess', response.data.data)
            this.listData = response.data.data
            this.listPagination = response.data.pagination
            this.load.loaded = true
            this.load.loadedError = false
          }, (response) => {
            this.load.loaded = true
            this.load.loadedError = true
          })
      }
    },
    openDeleteFormTemplateModal: function (id) {
      this.selectedFormTemplate = id
      this.delete.deleted = false
      this.delete.isOpen = true
    },
    deleteFormTemplate: function () {
      this.delete.deleteSuccess = false
      this.delete.deleted = false
      this.delete.deleting = true
      this.$store.dispatch('formtemplatelists/deleteFormTemplate', {
        id: this.selectedFormTemplate
      }).then((response) => {
        this.delete.deleteSuccess = true
        this.delete.deleted = true
        this.delete.deleting = false
        this.delete.isOpen = false
        this.$refs.generalList.reload()
        this.$store.dispatch('formtemplatelists/deleteFormTemplateSuccess', {
          id: this.selectedFormTemplate
        })
      }, (response) => {
        this.deleteError = true
        this.delete.deleted = true
        this.delete.deleting = false
      })
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  }
}
</script>
