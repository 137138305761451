import { deleteData, getData, postData } from '../plugins/ApiCalls'

export function leadsCount() {
  return getData('leads/count')
}

export function averageConversionRate() {
  return getData('leads/average-conversion-rate')
}

export function bulkDelete(formId, variantId) {
  return deleteData('forms/' + formId + '/variants/' + variantId + '/bulk-delete')
}

export function markRead(data) {
  return postData('form-lead-view/mark-read', data, { emulateJSON: true })
}
