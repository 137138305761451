<template>
  <v-container style="width: 85%;">
    <div class="dashboards">
      <general-widget></general-widget>
    </div>
    <account-expired v-if="dialog.accountExpired" :userName="userName" :showModal="dialog.accountExpired"></account-expired>
    <past-due v-if="dialog.pastDue" :showModal="dialog.pastDue"></past-due>
    <trial-ended v-if="dialog.trialEnded" :showModal="dialog.trialEnded"></trial-ended>
  </v-container>
</template>

<script>
import userMixin from '@/mixins/user'
import pastDueMixin from '@/mixins/plan'
import GeneralWidget from '@/components/dashboard/widgets/general'
import TrialEnded from '@/components/plan/TrialEnded'
import AccountExpired from '@/components/plan/AccountExpired'
import PastDue from '@/components/plan/PastDue'

export default {
  name: 'dashboard-view',
  mixins: [userMixin, pastDueMixin],
  components: {
    'general-widget': GeneralWidget,
    'trial-ended': TrialEnded,
    'account-expired': AccountExpired,
    'past-due': PastDue
  },
  data: function () {
    return {
      userName: null,
      dialog: {
        accountExpired: false,
        trialEnded: false,
        pastDue: false
      }
    }
  },
  mounted: function () {
    if (!this.isAdmin && !this.isSuperCustomer) {
      if ((this.userMixin_hasActiveFreeTrialPlan || this.userMixin_hasActiveFreePlan)) {
        this.checkUserStatus()
      } else if (this.userMixin_isPastDueUser) {
        this.dialog.pastDue = true
      }
    }
  },
  methods: {
    checkUserStatus: function () {
      if ((this.userMixin_hasActiveFreePlan) || (this.userMixin_hasActiveFreeTrialPlan && this.userMixin_planExpired)) {
        let firstName = this.user.name.split(' ')
        this.userName = firstName[0]
        this.dialog.accountExpired = true  // Open the Account Expired modal
      } else if (this.userMixin_hasActiveFreeTrialPlan && (!this.userMixin_inTrial)) {
        this.dialog.trialEnded = true  // Open the Trial Ended modal
      }
    }
  }
}
</script>
