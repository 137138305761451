export const resourceTypes = {
  LEAD_PROOF: 'lead_proof',
  EXTERNAL_CHECKOUT: 'external_checkout',
  FORM_TEMPLATE: 'form_template',
  PACKAGE_BUILDER: 'package_builder',
  USER_FORM_REPORT: 'user_form_report',
  UTM_SCRIPT: 'utm_script',
  FORM_ENDINGS: 'form_endings',
  FORMS_LIST: 'form'
}
