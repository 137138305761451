<template>
  <div class="d-flex">
    <v-btn hide-details :style="`background-color:${colorCode};`" flat class="color-part" size="small">
      <v-menu activator="parent" :close-on-content-click="false">
        <v-color-picker 
          hide-details 
          v-model="colorCode" 
          mode="hex" 
          :modes="['hex', 'rgba']"
          @update:model-value="colorChanged"
        ></v-color-picker>
      </v-menu>
    </v-btn>
    <v-text-field
      class="no-outline" 
      hide-details 
      active 
      density="comfortable" 
      :label="modelTitle" 
      v-model="colorCode"
      @input="colorChanged" 
      @blur="colorChanged"
    ></v-text-field>
  </div>
</template>

<script>
import AppDialog from './AppDialog.vue'

export default {
  components: {
    AppDialog,
  },
  props: {
    modelValue: {
      type: String,
      default: '#ffffff'
    },
    modelTitle: {
      type: String,
      default: 'Color'
    },
  },
  data() {
    return {
      colorCode: '#ffffff',
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue !== this.colorCode) {
          this.colorCode = newValue;
        }
      },
      immediate: true
    }
  },
  methods: {
    colorChanged() {
      this.$emit('update:modelValue', this.colorCode);
      this.$emit('colorChanged', this.colorCode);
    }
  }
}
</script>

<style scoped>
.color-part {
  height: 48px;
  border-radius: 2px;
  border: 1px solid #E0E0E0;
}
.no-outline :deep(.v-field__outline) {
  display: none;
}
</style>