import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function createTemplateBuild(data) {
  return postData('form-templates', data)
}

export function getFormTemplates() {
  return getData('form-templates')
}

export function updateFormTemplate(id, data) {
  return putData('form-templates/' + id, data)
}

export function getFormTemplateList(data) {
  return getData('form-templates/list?listParams=' + encodeURIComponent(JSON.stringify(data)))
}

export function getFormTemplate(id) {
  return getData('form-templates/' + id)
}

export function deleteFormTemplate(id) {
  return deleteData('form-templates/' + id)
}

export function getFormTemplateCategories() {
  return getData('form-template-categories')
}

export function getFormTemplateIndustries() {
  return getData('form-template-industries')
}
