export const onboardingGoal = [
  {
    label: 'Generate more leads',
    image: require('../../../assets/images/onboarding/more-leads.svg'),
    value: 'Generate_More_Leads'
  },
  {
    label: 'Improve Lead Quality',
    image: require('../../../assets/images/onboarding/improve-leads.svg'),
    value: 'Improve_Lead_Quality'
  },
  {
    label: 'Manage Clients',
    image: require('../../../assets/images/onboarding/manage-clients.svg'),
    value: 'Manage_Clients'
  },
  {
    label: 'Feedback collection/surveys',
    image: require('../../../assets/images/onboarding/feedback-surveys.svg'),
    value: 'Feedback_Collection_Or_Surveys'
  },
  {
    label: 'Data Collection',
    image: require('../../../assets/images/onboarding/data-collection.svg'),
    value: 'Data_Collection'
  },
  {
    label: 'Just Exploring',
    image: require('../../../assets/images/onboarding/just-explore.svg'),
    value: 'Just_Exploring'
  }
]

export const onboardingIndustries = [
  {
    label: 'Accounting',
    value: 'Accounting'
  },
  {
    label: 'Advertising',
    value: 'Advertising'
  },
  {
    label: 'Aerospace Industry',
    value: 'Aerospace_Industry'
  },
  {
    label: 'Aesthetic Care',
    value: 'Aesthetic_Care'
  },
  {
    label: 'AI and Data Science Researcher',
    value: 'AI_and_Data_Science_Researcher'
  },
  {
    label: 'Automotive',
    value: 'Automotive'
  },
  {
    label: 'Banking',
    value: 'Banking'
  },
  {
    label: 'Blogging',
    value: 'Blogging'
  },
  {
    label: 'Book writing company',
    value: 'Book_writing_company'
  },
  {
    label: 'Business Consulting',
    value: 'Business_Consulting'
  },
  {
    label: 'Business Development',
    value: 'Business_Development'
  },
  {
    label: 'Car Rental',
    value: 'Car_Rental'
  },
  {
    label: 'Cleaning Service',
    value: 'Cleaning_Service'
  },
  {
    label: 'Construction',
    value: 'Construction'
  },
  {
    label: 'Content Marketing',
    value: 'Content_Marketing'
  },
  {
    label: 'Cybersecurity',
    value: 'Cybersecurity'
  },
  {
    label: 'Debt/Loans',
    value: 'Debt_Loans'
  },
  {
    label: 'Digital Agency',
    value: 'Digital_Agency'
  },
  {
    label: 'Digital Marketing',
    value: 'Digital_Marketing'
  },
  {
    label: 'Ecommerce',
    value: 'Ecommerce'
  },
  {
    label: 'Education',
    value: 'Education'
  },
  {
    label: 'Energy Services',
    value: 'Energy_Services'
  },
  {
    label: 'Fashion',
    value: 'Fashion'
  },
  {
    label: 'Fencing',
    value: 'Fencing'
  },
  {
    label: 'Finance',
    value: 'Finance'
  },
  {
    label: 'Fitness',
    value: 'Fitness'
  },
  {
    label: 'Food and Beverages',
    value: 'Food_and_Beverages'
  },
  {
    label: 'Furniture',
    value: 'Furniture'
  },
  {
    label: 'Green Energy',
    value: 'Green_Energy'
  },
  {
    label: 'Health and Wellness',
    value: 'Health_and_Wellness'
  },
  {
    label: 'Healthcare',
    value: 'Healthcare'
  },
  {
    label: 'Home Care',
    value: 'Home_Care'
  },
  {
    label: 'Hospitality',
    value: 'Hospitality'
  },
  {
    label: 'Insurance',
    value: 'Insurance'
  },
  {
    label: 'Investment',
    value: 'Investment'
  },
  {
    label: 'IT',
    value: 'IT'
  },
  {
    label: 'Law Firm',
    value: 'Law_Firm'
  },
  {
    label: 'Lead Generation',
    value: 'Lead_Generation'
  },
  {
    label: 'Logistics',
    value: 'Logistics'
  },
  {
    label: 'Marketing and Advertising',
    value: 'Marketing_and_Advertising'
  },
  {
    label: 'Media and Communications',
    value: 'Media_and_Communications'
  },
  {
    label: 'Mortgage',
    value: 'Mortgage'
  },
  {
    label: 'Music',
    value: 'Music'
  },
  {
    label: 'Pensions',
    value: 'Pensions'
  },
  {
    label: 'Photography',
    value: 'Photography'
  },
  {
    label: 'Pool and Landscape Service',
    value: 'Pool_and_Landscape_Service'
  },
  {
    label: 'Printing service',
    value: 'Printing_service'
  },
  {
    label: 'Real estate',
    value: 'Real_estate'
  },
  {
    label: 'Recruitment',
    value: 'Recruitment'
  },
  {
    label: 'Roofing',
    value: 'Roofing'
  },
  {
    label: 'SaaS',
    value: 'SaaS'
  },
  {
    label: 'Security Solutions',
    value: 'Security_Solutions'
  },
  {
    label: 'SEO',
    value: 'SEO'
  },
  {
    label: 'Software Consultant',
    value: 'Software_Consultant'
  },
  {
    label: 'Software Development',
    value: 'Software_Development'
  },
  {
    label: 'Sports',
    value: 'Sports'
  },
  {
    label: 'Telecommunication',
    value: 'Telecommunication'
  },
  {
    label: 'Tourism & Travel',
    value: 'Tourism_Travel'
  },
  {
    label: 'Virtual Assistance',
    value: 'Virtual_Assistance'
  },
  {
    label: 'Web and Mobile Apps Development',
    value: 'Web_and_Mobile_Apps_Development'
  },
  {
    label: 'Web Design / Development Agency',
    value: 'Web_Design_Development_Agency'
  }
]

export const onboardingRole = [
  {
    label: 'CEO',
    value: 'CEO'
  },
  {
    label: 'Director / Department Head',
    value: 'Director'
  },
  {
    label: 'Department Head',
    value: 'Department_Head'
  },
  {
    label: 'Manager',
    value: 'Manager'
  },
  {
    label: 'Small Business Owner',
    value: 'Small_Business_Owner'
  },
  {
    label: 'Doctor / Physician',
    value: 'Doctor_Physician'
  },
  {
    label: 'CFO',
    value: 'CFO'
  },
  {
    label: 'Developer / Programmer',
    value: 'Developer_Programmer'
  },
  {
    label: 'Lawyer',
    value: 'Lawyer'
  },
  {
    label: 'Sales Representative',
    value: 'Sales_Representative'
  },
  {
    label: 'Marketing / Communications',
    value: 'Marketing_Communications'
  },
  {
    label: 'Customer Service',
    value: 'Customer_Service'
  },
  {
    label: 'Human Resources',
    value: 'Human_Resources'
  },
  {
    label: 'Educator',
    value: 'Educator'
  },
  {
    label: 'Student',
    value: 'Student'
  },
  {
    label: 'Admin Support',
    value: 'Admin_Support'
  },
  {
    label: 'Umemployed',
    value: 'Umemployed'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]
