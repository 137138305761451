<template>
  <div class="form-creator-option-view">
    <!-- HEADER -->
    <div class="form-creator-option-view__header">
      <!-- LOGO -->
      <div class="form-creator-option-view__header__logo">
        <router-link :to="{ name: 'dashboard' }">
          <img src="./../assets/images/icons/new-logo.svg" alt="LeadGen" />
        </router-link>
      </div>
      <!-- ACTION -->
      <div class="form-creator-option-view__header__actions">
        <user-account-action :userName="userName"></user-account-action>
      </div>
    </div>
    <!-- BODY -->
    <div class="form-creator-option-view__body">
      <!-- FORM CREATE OPTIONS -->
      <div class="container">
        <div class="create-option-header">
          <h2>Start a new form</h2>
          <p>Create an amazing form to easily collect leads and data</p>
        </div>
        <div class="create-option-body">
          <div class="option-card" @click="$router.push({ name: 'forms.create' })">
            <div class="option-card__image">
              <img src="./../assets/images/icons/blank-form.svg" alt="Blank" />
            </div>
            <div class="option-card__title">Blank</div>
          </div>

          <div class="option-card" @click="$router.push({ name: 'forms.choose.template' })">
            <div class="option-card__image">
              <img
                src="./../assets/images/icons/use-template.svg"
                alt="Use Template"
              />
            </div>
            <div class="option-card__title">Use Template</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixins/user'
import UserAccountAction from '@/components/UserAccountAction'

export default {
  mixins: [userMixin],
  components: {
    'user-account-action': UserAccountAction
  },
  data: function () {
    return {
      userName: null
    }
  },
  mounted () {
    this.userName = this.user.name
  }
}
</script>
