import { getData, postData, putData } from '../plugins/ApiCalls'

export function createExperiment(formId, data) {
  return postData('forms/' + formId + '/experiments', data, { emulateJSON: true })
}

export function updateExperiment(formId, experimentId, data) {
  return putData('forms/' + formId + '/experiments/' + experimentId, data, { emulateJSON: true })
}

export function getFormExperiments(formId) {
  return getData('forms/' + formId + '/experiments')
}

export function getFormExperiment(formId, experimentId) {
  return getData('forms/' + formId + '/experiments/' + experimentId)
}

export function startExperiment(formId, experimentId) {
  return putData('forms/' + formId + '/experiments/' + experimentId + '/start')
}

export function endExperiment(formId, experimentId) {
  return putData('forms/' + formId + '/experiments/' + experimentId + '/end')
}

export function result(formId, experimentId) {
  return getData('forms/' + formId + '/experiments/' + experimentId + '/result')
}
