import * as mutationTypes from '@/store/mutation-types'
import * as UserApi from '@/api/user'

const actions = {
  requestUserDelete ({ state, commit }, data) {
    commit(mutationTypes.REQUEST_PERMANENT_DELETE_ACCOUNT, data)
  },
  cancelUserDelete ({ state, commit }, data) {
    commit(mutationTypes.CANCEL_PERMANENT_DELETE_ACCOUNT, data)
  },

  changeUserEmail ({ state, commit }, data) {
    return UserApi.updateUserEmail(data.userId, data.data)
  },

  changeUserPassword ({ state, commit }, data) {
    return UserApi.updateUserPassword(data.userId, data.data)
  },
  resetUserPassword ({ state, commit }, data) {
    return UserApi.sendPasswordResetLink(data)
  },

  reset2FASettings ({ state, commit }, data) {
    return UserApi.updateUser2FA(data.userId, data.payload)
  },

  fetchProfileSettings ({state, commit}, data) {
    return UserApi.fetchProfileSettings(data.userId)
  }
}

const mutations = {
  [mutationTypes.REQUEST_PERMANENT_DELETE_ACCOUNT] (state, data) {
    return UserApi.requestUserDeletion(data.userId)
  },
  [mutationTypes.CANCEL_PERMANENT_DELETE_ACCOUNT] (state, data) {
    return UserApi.cancelUserDeletion(data.userId)
  }
}

export default {
  namespaced: true,
  actions,
  mutations
}
