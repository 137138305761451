import { mapGetters } from 'vuex'
import _ from 'lodash'
import * as actionTypes from '@/store/action-types'
import roleTypes from '@/store/roles'
import signupSources from '@/store/signup-sources'
import paddleSubscriptionStatusTypes from '@/store/paddle-subscription-status-types'
import planPublicIds from "@/shared/constants/plan/paddle/publicIds"
import operators from '@/shared/constants/comparisonOperators'
import timePeriods from '@/store/time-periods'
import moment from 'moment'

export default {
  data: function () {
    return {
      value: null
    }
  },
  methods: {
    hasActiveSubscriptionByUser: function (user) {
      let subscription = this.getSubscriptionByUser(user)

      if (!subscription) {
        return false
      }

      return subscription.active
    },
    getPlanByUser: function (user) {
      if (!user.plan) {
        return null
      }

      return user.plan
    },
    getSubscriptionByUser: function (user) {
      let plan = this.getPlanByUser(user)

      if (!plan) {
        return null
      }

      return plan.subscription
    },

    userMixin_hasFeatureAccess: function (featureSlug) {
      if (this.isAdmin || this.isSuperCustomer) {
        return true
      }

      if (!this.plan) {
        return false
      }

      return this.plan.plan_features.some(element => element.slug === featureSlug)
    },

    userMixin_hasFeaturePropertyAccess: function (featureSlug, operator, inputArray) {
      if (this.isAdmin || this.isSuperCustomer) {
        return true
      }
      return this.$store.dispatch(actionTypes.FETCH_USER, {
        userId: this.user.id
      })
        .then((response) => {
          let data = response.data.data
          this.value = this.checkFeaturePropertyAccess(data.plan, featureSlug, operator, inputArray)
          return this.value
        })
    },
    checkFeaturePropertyAccess: function (plan, featureSlug, operator, inputArray) {
      let planFeatures = plan.plan_features.find(elm => elm.slug === featureSlug)
      if (planFeatures === undefined) {
        return false
      }

      let planFeatureProperties = planFeatures.feature_properties.filter(element => element.feature_id === planFeatures.id)
      if ((planFeatureProperties === undefined) || (planFeatureProperties.length === 0)) {
        return false
      }
      let result = []
      inputArray.forEach(input => {
        planFeatureProperties.forEach(pp => {
          if (input['limitation_title'] === pp.title) {
            let limitationValue = input['limitation_value']
            if (
              pp.reset_period === timePeriods.MONTHLY &&
              input['limitation_valueMonthly'] !== undefined &&
              input['limitation_valueMonthly'] !== null
            ) {
              limitationValue = input['limitation_valueMonthly']
            } else if (
              pp.reset_period === timePeriods.YEARLY &&
              input['limitation_valueYearly'] !== undefined &&
              input['limitation_valueYearly'] !== null
            ) {
              limitationValue = input['limitation_valueYearly']
            } else if (
              pp.reset_period === timePeriods.AS_PER_PLAN &&
              input['limitation_valueAsPerPlan'] !== undefined &&
              input['limitation_valueAsPerPlan'] !== null
            ) {
              limitationValue = input['limitation_valueAsPerPlan']
            }

            result.push(
              this.userMixin_dynComparison(
                limitationValue,
                pp.value,
                input['compare']
              )
            )
          }
        })
      })

      return operator === 'OR' ? result.some(res => res === true) : !result.some(res => res === false)
    },

    userMixin_dynComparison: function (inputValue, comparisonValue, operator) {
      const comparisonOperators = {
        [operators.EQ]: inputValue === comparisonValue,
        [operators.LT]: inputValue < comparisonValue,
        [operators.LTE]: inputValue <= comparisonValue,
        [operators.GT]: inputValue > comparisonValue,
        [operators.GTE]: inputValue >= comparisonValue
      }
      return comparisonOperators[operator] || false
    }
  },
  computed: {
    ...mapGetters(['user']),

    plan: function () {
      return this.user?.plan
    },

    subscription: function () {
      if (!this.user) {
        return null
      }

      return this.user.plan.subscription
    },

    hasSubscription: function () {
      if (this.subscription) {
        return true
      }

      return false
    },

    hasCancelledSubscription: function () {
      if (!this.subscription) {
        return false
      }

      return this.subscription.ends_at && this.subscription.status === paddleSubscriptionStatusTypes.DELETED
    },

    isAdmin: function () {
      if (!this.user) {
        return false
      }

      return !_.isNil(_.find(this.user.roles, { name: roleTypes.ADMIN }))
    },

    isSuperCustomer: function () {
      if (!this.user) {
        return false
      }

      return !_.isNil(_.find(this.user.roles, { name: roleTypes.SUPER_CUSTOMER }))
    },

    isCustomer: function () {
      if (!this.user) {
        return false
      }

      return !_.isNil(_.find(this.user.roles, { name: roleTypes.CUSTOMER }))
    },

    isOneToolUser: function () {
      if (!this.user) {
        return false
      }

      return this.user.source === signupSources.ONETOOL
    },

    isVerified: function () {
      return this.user && !this.user.verified
    },

    syncUser: function () {
      if (!this.user) {
        return
      }

      this.$store
        .dispatch(actionTypes.FETCH_USER, {
          userId: this.user.id
        })
        .then(response => {
          let user = response.data.data

          this.$store.dispatch(actionTypes.UPDATE_USER_LOCAL, user).then(() => {
            localStorage.setItem('user', JSON.stringify(user))
          })
        })
    },

    userMixin_hasFreeTrialDefaultPlan: function () {
      if (!this.user) {
        return false
      }
      return this.user.defaultPlan.public_id === planPublicIds.freeTrial
    },

    userMixin_hasActiveFreeTrialPlan: function () {
      if (!this.plan) {
        return false
      }
      return this.plan.public_id === planPublicIds.freeTrial
    },

    userMixin_trialDaysLeft: function () {
      if (!this.plan) {
        return null
      }
      return this.plan.trial_days_left
    },

    userMixin_inTrial: function () {
      if (!this.plan) {
        return false
      }
      return this.plan.in_trial
    },

    userMixin_planExpired: function () {
      if (!this.plan) {
        return false
      }
      return this.plan.plan_expired
    },

    userMixin_hasActiveFreePlan: function () {
      if (!this.plan) {
        return false
      }
      return this.plan.public_id === planPublicIds.freePlan
    },

    userMixin_isPastDueUser: function () {
      if (!this.subscription) {
        return false
      }
      return this.subscription.status === paddleSubscriptionStatusTypes.PAST_DUE
    },

    userMixin_hasPausedSubscription: function () {
      if (!this.subscription) {
        return false
      }
      return (
        this.subscription.paused_at &&
        this.subscription.paused_from &&
        this.subscription.status === paddleSubscriptionStatusTypes.PAUSED
      )
    },

    userMixin_hasPausedSubscriptionInGrace: function () {
      if (!this.subscription) {
        return false
      }

      let presentDate = moment.utc(new Date()).format('YYYY-MM-DD')
      let pausedDate = moment.utc(this.subscription.paused_from).format('YYYY-MM-DD')

      return this.userMixin_hasPausedSubscription && pausedDate > presentDate
    },

    userMixin_hasOneDaySinceRegister: function () {
      if (!this.user) {
        return false
      }
      return moment(this.user.created_at).isAfter(moment().subtract(24, 'hours'))
    },
    userMixin_showTwentyFourHourDiscount: function () {
      return (
        !this.isAdmin &&
        !this.isSuperCustomer &&
        this.userMixin_hasOneDaySinceRegister &&
        this.userMixin_hasActiveFreeTrialPlan
      )
    }
  }
}
