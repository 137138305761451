import * as mutationTypes from '@/store/mutation-types'
import * as FormWebhookApi from '@/api/form-webhook'
import webhookMethods from '@/store/modules/formwebhook/webhook-methods'
import webhookFormats from '@/store/modules/formwebhook/webhook-formats'

const state = {
  webhooks: [],
  webhookFormats: webhookFormats,
  webhookMethods: webhookMethods
}

const getters = {
  webhooks: function (state) {
    return state.webhooks || []
  },
  webhookMethods: function (state) {
    return state.webhookMethods
  },
  webhookFormats: function (state) {
    return state.webhookFormats
  }
}

const actions = {
  fetchFormWebhooks ({state, commit}, data) {
    return FormWebhookApi.fetchFormWebhooks(data.formId)
  },
  fetchFormWebhooksSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_WEBHOOKS_SUCCESS, data)
  },
  fetchFormWebhook ({state, commit}, data) {
    return FormWebhookApi.fetchFormWebhook(data.formId, data.webhookId)
  },
  createFormWebhook ({state, commit}, data) {
    return FormWebhookApi.createFormWebhook(data.formId, data.webhook)
  },
  createFormWebhookSuccess ({state, commit}, data) {
    commit(mutationTypes.CREATE_FORM_WEBHOOK_SUCCESS, data)
  },
  updateFormWebhook ({state, commit}, data) {
    return FormWebhookApi.updateFormWebhook(data.formId, data.webhookId, data.webhook)
  },
  deleteFormWebhook: (context, data) => {
    return FormWebhookApi.deleteFormWebhook(data.formId, data.id)
  }
}

const mutations = {
  [mutationTypes.FETCH_FORM_WEBHOOKS_SUCCESS] (state, data) {
    state.webhooks = data
  },
  [mutationTypes.CREATE_FORM_WEBHOOK_SUCCESS] (state, data) {
    let webhooks = state.webhooks.slice()
    webhooks.unshift(data)
    state.webhooks = webhooks
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
