<template>
  <div class="help-view">
    <div class="container">
      <h4 class="page-title">Help</h4>
      <p class="text-grey-darken-4">Find answers to your questions here. Check out our help articles, video content or
        get in touch directly.</p>
      <v-row class="mt-10">
        <v-col cols="12" md="3">
          <div class="help-center-content-card">
            <div class="content-card__body">
              <v-btn href="https://leadgenapp.io/help/" target="_blank" text="Help Center" color="primary-red"
                append-icon="mdi-open-in-new" class="text-none font-weight-bold"></v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <div class="help-content-card">
            <div class="help__features">
              <ul>
                <li class="text-grey-darken-2">
                  <v-icon class="mr-2" color="secondary-red" :size="20" icon="mdi-check-decagram"></v-icon>
                  Comprehensive library on LeadGen App
                </li>
                <li class="text-grey-darken-2">
                  <v-icon class="mr-2" color="secondary-red" :size="20" icon="mdi-check-decagram"></v-icon>
                  Articles & video based on user questions
                </li>
                <li class="text-grey-darken-2">
                  <v-icon class="mr-2" color="secondary-red" :size="20" icon="mdi-check-decagram"></v-icon>
                  Developer's guide
                </li>
              </ul>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-wrap mt-10">
            <v-col cols="12" md="4">
              <div class="content-card rounded">
                <header class="content-card__header youtube-header bg-secondary-red-lighten-5 justify-center ga-4">
                  <v-icon size="large" color="primary-red" icon="mdi-youtube"></v-icon>
                  Youtube Channel
                </header>
                <div class="text-center content-card__body">
                  <v-btn href="https://www.youtube.com/channel/UCgzgy4jaJxN-1UbIPD9utSw?sub_confirmation=1"
                    target="_blank" text color="primary-red" class="text-none font-weight-bold" variant="outlined">
                    Watch
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="content-card rounded">
                <header class="content-card__header services-header bg-blue-lighten-5 justify-center ga-4">
                  <v-icon size="large" color="#3b5998" icon="mdi-cogs"></v-icon>
                  Services
                </header>
                <div class="text-center content-card__body">
                  <v-btn href="https://leadgenapp.io/services/" target="_blank" text color="primary-red"
                    class="text-none font-weight-bold" variant="outlined">
                    Explore
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="content-card rounded">
                <header class="content-card__header courses-header bg-green-lighten-5 justify-center ga-4">
                  <v-icon size="large" color="primary-green" icon="mdi-folder-open"></v-icon>
                  Courses
                </header>
                <div class="text-center content-card__body">
                  <v-btn href="https://leadgenapp.io/tools/courses" target="_blank" text color="primary-red"
                    class="text-none font-weight-bold" variant="outlined">
                    Learn More
                  </v-btn>
                </div>
              </div>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
