<template>
  <!-- Trial Ended Modal -->
  <v-dialog v-model="showTrialEndedModal" persistent rounded="md" max-width="750" class="past-due-dialog">
    <v-card>
      <v-card-title class="bg-black white--text d-flex align-center px-0 py-4">
        <v-row no-gutters class="d-flex justify-space-between align-center">
          <!-- Left-aligned Logo -->
          <v-col cols="3" class="pl-9">
            <v-img :src="require('./../../assets/images/views/logo/logo_2022.png')" :height="25"></v-img>
          </v-col>

          <!-- Right-aligned Image -->
          <v-col cols="2" class="pr-9">
            <v-img :src="require('./../../assets/images/icons/plan-header-two.svg')" :height="30"></v-img>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="px-5">
        <v-empty-state :image="require('./../../assets/images/plans/trial.jpg')" size="250" text-width="700">
          <template v-slot:media>
            <v-img class="mb-2"></v-img>
          </template>
          <template v-slot:title>
            <div class="text-h5 font-weight-bold text-high-emphasis">Trial Ended</div>
          </template>
          <template v-slot:text>
            <p class="text-grey-darken-2">
              Your LeadGen App trial has ended. To access your account and grow
              your business with new lead generation campaigns, subscribe to any
              LeadGen plan and you're ready to go.
            </p>
            <v-spacer class="my-4"></v-spacer>
            <v-btn rounded="md" color="primary-red" class="font-weight-bold text-capitalize" elevation="5" height="42"
              width="200" @click="sendToPlans()">
              Subscribe Now
            </v-btn>
          </template>
        </v-empty-state>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showModal: Boolean
  },
  data() {
    return {
      showTrialEndedModal: false
    }
  },
  mounted() {
    this.showTrialEndedModal = this.showModal
  },
  methods: {
    sendToPlans: function () {
      this.$router.push({ name: 'plans.index' })
    }
  }
}
</script>
