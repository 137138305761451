<template>
  <v-container class="form-integration-type-view pt-1">
    <v-row class="align-center justify-space-between mt-n6">
      <v-col cols="12" md="6" class="text-left my-6">
        <router-link :to="{ name: 'forms.detail.connectionstab.list', params: { id: $route.params.id } }"
          class="text-primary-red mb-5 link">
          <v-icon left>mdi-arrow-left</v-icon>
          Back to Connections
        </router-link>
      </v-col>
    </v-row>

    <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
      <v-card-title class="bg-blue-lighten-5 text-primary-blue">
        <h6 class="d-flex justify-center mb-0">
          <v-icon icon="mdi-alpha-t-circle-outline" class="mt-1"></v-icon>
          <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
            Configure Trustedform
          </p>
        </h6>
      </v-card-title>
      <v-card-text class="px-10">
        <v-form @submit.prevent="() => false" ref="trustedForm">
          <v-row class="px-2 pt-7">
            <v-col cols="12" md="6">
              <p class="text-primary-blue mb-0">Step 1</p>
              <h5 class="step-count-title"><b>Grab your Script</b></h5>
              <p>The first step is to log in to your ActiveProspect account, grab your TrustedForm Script, and paste it
                into
                the text box below. Be sure to exclude any script tags, and only copy the function part of the code.</p>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="6" class="pl-5">
              <p class="text-primary-blue mb-0">Step 2</p>
              <h5 class="step-count-title"><b>Add Hidden Field</b></h5>
              <p>After adding the script, hit save and navigate to the <span class="text-primary-red cursor-pointer"
                  @click="goToVariant()">Edit
                  Form.</span> Scroll to the bottom to add a hidden field called <b>"xxTrustedFormCertUrl"</b>, leave
                the
                field default value empty, and disable capturing from URL parameters. Save the form, test the lead, and
                you're
                good to go!</p>
            </v-col>

            <v-col cols="12" md="12" class="py-2 text-left">
              <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mt-3">
                <p class="text-black mb-0 border-0">
                  Checkout our <a href="https://leadgenapp.io/help/trustedform-integration/" target="_blank"
                    class="text-info font-weight-bold">TrustedForm Integration guide <v-icon :size="14"
                      class="font-weight-bold">mdi-open-in-new</v-icon></a> for
                  guidance about this setup.
                </p>
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="px-2 py-2">
            <v-col cols="12" md="12" class="pb-0 pt-2">
              <v-textarea name="script" variant="outlined" label="Enter TrustedForm script"
                placeholder="Enter TrustedForm script code without tags" prepend-inner-icon="mdi-language-javascript"
                :rules="$valid([{ rule: 'required' }])" v-model="trackingEventForm.script" rows="7" no-resize
                active></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="py-0 d-flex justify-space-between">
              <v-switch hide-details label="Activate" inset color="primary-red"
                v-model="trackingEventForm.active"></v-switch>

              <v-btn type="submit" prepend-icon="mdi-floppy" class="text-none font-weight-bold"
                :loading="trackingEventForm.saving" color="primary-red" @click="saveEvent">Save Trustedform</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>

  <v-snackbar rounded="md" height="50" :color="snackbar.error ? 'secondary-red' : 'primary-green'" :timeout="3000"
    v-model="snackbar.show">
    <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
  </v-snackbar>
</template>

<script>
import _ from 'lodash'
import userMixin from '@/mixins/user'
import formBuilderTabs from '@/shared/constants/formBuilderTabs.js'
import variantTypes from '@/store/variant-types'
import { featureSlugs } from '@/shared/constants/packagebuilder/features'

export default {
  mixins: [userMixin],
  data: function () {
    return {
      snackbar: {
        show: false,
        text: null,
        error: false
      },
      trackingEventForm: {
        id: null,
        script: '',
        active: false,
        saving: false,
      }
    }
  },
  mounted: function () {
    this.fetchTrackingEvents()
  },
  methods: {
    goToVariant: function () {
      this.$router.push({ name: 'forms.variants.edit', params: { id: this.$route.params.id, variantId: this.champion.id, tab: formBuilderTabs.BUILD } })
    },
    fetchTrackingEvents() {
      this.$store.dispatch('form/getFormTrackingEvents', {
        formId: this.formId
      }).then((response) => {
        this.$store.dispatch('form/setFormTrackingEvents', {
          formId: this.formId,
          events: response.data.data || []
        })
      })
    },
    saveEvent: async function () {
      this.trackingEventForm.saving = true
      const { valid } = await this.$refs.trustedForm.validate()
      if (!valid) {
        this.trackingEventForm.saving = false
        return
      }
      if (this.trackingEventForm.script.search(`script>`) >= 0 || this.trackingEventForm.script.search(`<script`) >= 0) {
        this.showSnackbar(true, 'Scripts tags not allowed, enter javascript code without tags')
        this.trackingEventForm.saving = false
        return
      } else if (!this.userMixin_hasFeatureAccess(this.featureSlugs.TRUSTEDFORM)) {
        this.showSnackbar(true, 'Plese upgrade to Scale plan in order to use this integration.')
        this.trackingEventForm.saving = false
        return
      }
      this.$store.dispatch('formTrackingEvent/saveFormTrackingEvent', this.trackingEventForm)
        .then(() => {
          this.trackingEventForm.saving = false
          this.fetchTrackingEvents()
          this.showSnackbar(false, 'Connection saved successfully')
        }, () => {
          this.trackingEventForm.saving = true
          this.showSnackbar(true, 'Something went wrong! please try again')
        })
    },
    showSnackbar: function (flag, message) {
      this.snackbar.show = true
      this.snackbar.error = flag
      this.snackbar.text = message
    }
  },
  computed: {
    featureSlugs: function () {
      return featureSlugs
    },
    formId() {
      return this.$route.params.id
    },
    variantId: function () {
      return parseInt(this.$route.params.variantId) || null
    },
    trackingEvents() {
      return _.find(this.$store.getters['form/trackingEvents'] || [], { formId: this.formId }) || []
    },
    events() {
      return _.find(this.trackingEvents.events, (obj) => obj.title === this.featureSlugs.TRUSTEDFORM.toUpperCase())
    },
    variants: function () {
      return this.$store.getters.getFormVariants(parseInt(this.formId))
    },
    champion: function () {
      for (let v of this.variants) {
        if (v.form_variant_type.type === variantTypes.CHAMPION) {
          return v
        }
      }
      return {}
    }
  },
  watch: {
    events(newVal) {
      this.trackingEventForm.script = newVal.script || ''
      this.trackingEventForm.id = newVal.id || null
      this.trackingEventForm.active = newVal.active === 1 ? true : false || false
      this.trackingEventForm.title = newVal.title || this.featureSlugs.TRUSTEDFORM.toUpperCase()
      this.trackingEventForm.configured = newVal.configured
    }
  }
}
</script>
<style></style>
