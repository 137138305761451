<template>
  <div class="template-sidebar mt-5">
    <!-- SideBar menu -->
    <v-container class="pa-1 pa-sm-2">
      <!-- expansion panel layout -->
      <v-expansion-panels v-model="expansion.layout" variant="accordion" class="mb-5" rounded="2" elevation="1">
        <v-expansion-panel class="border">
          <v-expansion-panel-title color="#edf4f9" class="font-weight-bold">FORM LAYOUT</v-expansion-panel-title>
          <v-expansion-panel-text class="layout">
            <div class="d-flex flex-wrap flex-row flex-sm-row justify-center my-2">
              <v-btn :active="isActive(mixin_templateKeywords.STEP, mixin_templateKeywords.SINGLE)" variant="outlined"
                class="text-none font-weight-bold single-btn mt-sm-2 mb-2 mb-sm-0 mr-sm-2" rounded="md"
                @click="handleFilter(mixin_templateKeywords.STEP, mixin_templateKeywords.SINGLE)">
                Single-Step
              </v-btn>

              <v-btn :active="isActive(mixin_templateKeywords.STEP, mixin_templateKeywords.MULTI)" variant="outlined"
                class="text-none font-weight-bold ml-2 ml-sm-0 mt-sm-2 multi-btn" rounded="md"
                @click="handleFilter(mixin_templateKeywords.STEP, mixin_templateKeywords.MULTI)">
                Multi-Step
              </v-btn>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- expansion panel type -->
      <v-expansion-panels v-model="expansion.type" variant="accordion" class="mb-5" rounded="md" elevation="1">
        <v-expansion-panel class="border">
          <v-expansion-panel-title color="#edf4f9" class="font-weight-bold">FORM TYPE</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-tabs v-model="expansion.tab" bg-color="white" align-tabs="center" color="primary-red"
              class="d-flex flex-wrap flex-column" fixed-tabs>
              <v-tab value="category" class="font-weight-bold">CATEGORIES</v-tab>
              <v-tab value="industry" class="font-weight-bold">INDUSTRIES</v-tab>
            </v-tabs>

            <v-tabs-window v-model="expansion.tab">
              <!-- categories -->
              <v-tabs-window-item value="category">
                <v-list>
                  <v-hover v-for="(category, index) in filteredCategories" :key="index" v-slot="{ isHovering, props }">
                    <v-list-item rounded="rounded" v-bind="props" :value="category.id"
                      :active="isActive(mixin_templateKeywords.CATEGORY, category.id)"
                      @click="handleFilter(mixin_templateKeywords.CATEGORY, category.id)" :class="{
                        'text-primary-red': isActive(mixin_templateKeywords.CATEGORY, category.id),
                        'text-secondary-red': isHovering && !isActive(mixin_templateKeywords.CATEGORY, category.id)
                      }">
                      <v-list-item-title class="font-weight-bold">
                        {{ category.title }}
                      </v-list-item-title>

                      <template v-slot:append>
                        <v-chip class="font-weight-bold" label size="x-small" rounded="sm"
                          :color="isActive(mixin_templateKeywords.CATEGORY, category.id) ? 'primary-red' : (isHovering ? 'red' : '#edf4f9')"
                          variant="flat">
                          {{ getBadgeCount(mixin_templateKeywords.CATEGORY, category) }}
                        </v-chip>
                      </template>
                    </v-list-item>
                  </v-hover>
                </v-list>
              </v-tabs-window-item>

              <!-- industries -->
              <v-tabs-window-item value="industry">
                <v-list>
                  <v-hover v-for="(industry, index) in filteredIndustries" :key="index" v-slot="{ isHovering, props }">
                    <v-list-item v-bind="props" :value="industry.id"
                      :active="isActive(mixin_templateKeywords.INDUSTRY, industry.id)"
                      @click="handleFilter(mixin_templateKeywords.INDUSTRY, industry.id)" :class="{
                        'text-primary-red': isActive(mixin_templateKeywords.INDUSTRY, industry.id),
                        'text-secondary-red': isHovering && !isActive(mixin_templateKeywords.INDUSTRY, industry.id)
                      }">
                      <v-list-item-title class="font-weight-bold">
                        {{ industry.title }}
                      </v-list-item-title>

                      <template v-slot:append>
                        <v-chip class="font-weight-bold" label size="x-small" rounded="sm"
                          :color="isActive(mixin_templateKeywords.INDUSTRY, industry.id) ? 'primary-red' : (isHovering ? 'red' : '#edf4f9')"
                          variant="flat">
                          {{ getBadgeCount(mixin_templateKeywords.INDUSTRY, industry) }}
                        </v-chip>
                      </template>
                    </v-list-item>
                  </v-hover>
                </v-list>
              </v-tabs-window-item>
            </v-tabs-window>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import templateMixin from '@/mixins/templates'

export default {
  mixins: [templateMixin],
  props: {
    isActive: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      expansion: {
        tab: 'category',
        layout: [0],
        type: [0]
      }
    }
  },
  computed: {
    filteredCategories() {
      return this.formTemplateCategories
    },
    filteredIndustries() {
      return this.formTemplateIndustries
    }
  },
  mounted() {
    // Check if any industry is active and set the tab to 'industry'
    if (this.filteredIndustries.some(industry => this.isActive(this.mixin_templateKeywords.INDUSTRY, industry.id))) {
      this.expansion.tab = 'industry'
    }
  },
  methods: {
    getBadgeCount(type, data) {
      const formType = (type === this.mixin_templateKeywords.CATEGORY)
        ? this.mixin_templateKeywords.TEMPLATE_CATEGORIES
        : this.mixin_templateKeywords.TEMPLATE_INDUSTRIES

      return this.formTemplates.reduce((count, template) => {
        if (Array.isArray(template[formType]) && template[formType].some(type => type.id === data.id)) {
          count++
        }
        return count
      }, 0)
    },
    handleFilter: function (type, id) {
      this.resetFilters()
      this.resetPaginate()
      this.$emit('filterChange', type, id)
    }
  }
}
</script>
