/* app */
export const INIT_APP = 'INITIALIZE_APPLICATION'

/* Entity */
export const INSERT_ENTITY = 'INSERT_ENTITY'
export const UPDATE_ENTITY = 'UPDATE_ENTITY'

/* form builder */
export const FORMBUILDER_CREATE_FORM = 'FORMBUILDER_CREATE_FORM'
export const FORMBUILDER_CREATE_FORM_VARIANT = 'FORMBUILDER_CREATE_FORM_VARIANT'
export const FORMBUILDER_CREATE_FORM_VARIANT_SUCCESS = 'FORMBUILDER_CREATE_FORM_VARIANT_SUCCESS'
export const FORMBUILDER_CREATE_FORM_VARIANT_ERROR = 'FORMBUILDER_CREATE_FORM_VARIANT_ERROR'
export const FORMBUILDER_UPDATE_FORM = 'FORMBUILDER_UPDATE_FORM'
export const FORMBUILDER_ARCHIVE_FORM = 'FORMBUILDER_ARCHIVE_FORM'
export const FORMBUILDER_UPDATE_ID = 'FORMBUILDER_UPDATE_ID'
export const FORMBUILDER_RESET = 'FORMBUILDER_RESET'
export const FORMBUILDER_UPDATE_CHOICE_FORMULA = 'FORMBUILDER_UPDATE_CHOICE_FORMULA'
export const FORMBUILDER_UPDATE_GDPR = 'FORMBUILDER_UPDATE_GDPR'
export const FORMBUILDER_REMOVE_GDPR = 'FORMBUILDER_REMOVE_GDPR'
export const FORMBUILDER_UPDATE_CALCULATOR = 'FORMBUILDER_UPDATE_CALCULATOR'

// steps
export const FORMBUILDER_ADD_STEP = 'FORMBUILDER_ADD_STEP'
export const FORMBUILDER_ADD_QUESTION = 'FORMBUILDER_ADD_QUESTION'
export const FORMBUILDER_DUPLICATE_QUESTION = 'FORMBUILDER_DUPLICATE_QUESTION'
export const FORMBUILDER_ADD_ELEMENT = 'FORMBUILDER_ADD_ELEMENT'
export const FORMBUILDER_UPDATE_FORMTITLE = 'FORMBUILDER_UPDATE_FORMTITLE'
export const FORMBUILDER_UPDATE_STEPQ = 'FORMBUILDER_UPDATE_STEPQUESTIONS'
export const FORMBUILDER_DELETE_STEP = 'FORMBUILDER_DELETE_STEP'
export const FORMBUILDER_UPDATE_STEPS = 'FORMBUILDER_UPDATE_STEPS'
export const FORMBUILDER_UPDATE_STEP_JUMP = 'FORMBUILDER_UPDATE_STEP_JUMP'
export const FORMBUILDER_UPDATE_STEP_AN = 'FORMBUILDER_UPDATE_STEP_AUTO_NAVIGATION'

// questions
export const FORMBUILDER_ADD_QT = 'FORMBUILDER_ADD_QT'
export const FORMBUILDER_UPDATE_QTYPE = 'FORMBUILDER_UPDATE_QUESTION_TYPE'
export const FORMBUILDER_UPDATE_QTITLE = 'FORMBUILDER_UPDATE_QUESTION_TITLE'
export const FORMBUILDER_UPDATE_QTITLE_HIDE = 'FORMBUILDER_UPDATE_QUESTION_TITLE_HIDE'
export const FORMBUILDER_UPDATE_QPLACEHOLDER = 'FORMBUILDER_UPDATE_QUESTION_PLACEHOLDER'
export const FORMBUILDER_UPDATE_QDATE_PLACEHOLDERDAY = 'FORMBUILDER_UPDATE_QUESTION_DATE_PLACEHOLDERDAY'
export const FORMBUILDER_UPDATE_QDATE_PLACEHOLDERMONTH = 'FORMBUILDER_UPDATE_QUESTION_DATE_PLACEHOLDERMONTH'
export const FORMBUILDER_UPDATE_QDATE_PLACEHOLDERYEAR = 'FORMBUILDER_UPDATE_QUESTION_DATE_PLACEHOLDERYEAR'
export const FORMBUILDER_UPDATE_QREQUIRED = 'FORMBUILDER_UPDATE_QUESTION_REQUIRED'
export const FORMBUILDER_UPDATE_QMINMAX = 'FORMBUILDER_UPDATE_QUESTION_MINMAX'
export const FORMBUILDER_UPDATE_QMINDATE = 'FORMBUILDER_UPDATE_QUESTION_MINDATE'
export const FORMBUILDER_UPDATE_QMAXDATE = 'FORMBUILDER_UPDATE_QUESTION_MAXDATE'
export const FORMBUILDER_UPDATE_QNUMBER = 'FORMBUILDER_UPDATE_QUESTION_NUMBER'
export const FORMBUILDER_UPDATE_QSKIN = 'FORMBUILDER_UPDATE_QUESTION_SKIN'
export const FORMBUILDER_UPDATE_QMINMAX_AUTOINCEREMENT = 'FORMBUILDER_UPDATE_QMINMAX_AUTOINCEREMENT'

export const FORMBUILDER_UPDATE_QMINMAXCHOICES = 'FORMBUILDER_UPDATE_QMINMAXCHOICES'
export const FORMBUILDER_UPDATE_QMINCHOICES = 'FORMBUILDER_UPDATE_QMINCHOICES'
export const FORMBUILDER_UPDATE_QMAXCHOICES = 'FORMBUILDER_UPDATE_QMAXCHOICES'
export const FORMBUILDER_UPDATE_QENABLE_CHOICES_VALUES = 'FORMBUILDER_UPDATE_QENABLE_CHOICES_VALUES'
export const FORMBUILDER_UPDATE_QENABLE_PRE_SELECT_CHOICES = 'FORMBUILDER_UPDATE_QENABLE_PRE_SELECT_CHOICES'
export const FORMBUILDER_UPDATE_GDPR_CHOICES = 'FORMBUILDER_UPDATE_GDPR_CHOICES'
export const FORMBUILDER_UPDATE_QENABLE_RANDOM_CHOICE_ORDER = 'FORMBUILDER_UPDATE_QENABLE_RANDOM_CHOICE_ORDER'

export const FORMBUILDER_UPDATE_QCHOICES = 'FORMBUILDER_UPDATE_QUESTION_CHOICES'
export const FORMBUILDER_UPDATE_QCHOICES_VALUES = 'FORMBUILDER_UPDATE_QUESTION_CHOICES_VALUES'
export const FORMBUILDER_UPDATE_QCHOICE = 'FORMBUILDER_UPDATE_QUESTION_CHOICE'
export const FORMBUILDER_UPDATE_QCHOICE_VALUE = 'FORMBUILDER_UPDATE_QUESTION_CHOICE_VALUE'
export const FORMBUILDER_DELETE_QCHOICE = 'FORMBUILDER_DELETE_QUESTION_CHOICE'
export const FORMBUILDER_DELETE_QCHOICE_VALUE = 'FORMBUILDER_DELETE_QUESTION_CHOICE_VALUE'
export const FORMBUILDER_ADD_QCHOICE = 'FORMBUILDER_ADD_QUESTION_CHOICE'
export const FORMBUILDER_ADD_QCHOICE_VALUE = 'FORMBUILDER_ADD_QUESTION_CHOICE_VALUE'
export const FORMBUILDER_SORT_QCHOICE = 'FORMBUILDER_SORT_QUESTION_CHOICE'

export const FORMBUILDER_UPDATE_QENABLE_OTHER_CHOICE = 'FORMBUILDER_UPDATE_QENABLE_OTHER_CHOICE'

export const FORMBUILDER_DELETE_Q = 'FORMBUILDER_DELETE_QUESTION'
export const FORMBUILDER_VALIDATE = 'FORMBUILDER_VALIDATE'
export const FORMBUILDER_UPDATE_QVALIDITY = 'FORMBUILDER_UPDATE_QUESTION_VALIDITY'
export const FORMBUILDER_UPDATE_QDESCRIPTION = 'FORMBUILDER_UPDATE_QUESTION_DESCRIPTION'
export const FORMBUILDER_UPDATE_QFIELDNAME = 'FORMBUILDER_UPDATE_QUESTION_FIELDNAME'

export const FORMBUILDER_ADD_QJUMP = 'FORMBUILDER_ADD_QUESTION_JUMP'
export const FORMBUILDER_UPDATE_QJUMP = 'FORMBUILDER_UPDATE_QUESTION_JUMP'
export const FORMBUILDER_DELETE_QJUMP = 'FORMBUILDER_DELETE_QUESTION_JUMP'
export const FORMBUILDER_ADD_QJUMP_CONDITION = 'FORMBUILDER_ADD_QUESTION_JUMP_CONDITION'
export const FORMBUILDER_UPDATE_QJUMP_CONDITION = 'FORMBUILDER_UPDATE_QUESTION_JUMP_CONDITION'
export const FORMBUILDER_DELETE_QJUMP_CONDITION = 'FORMBUILDER_DELETE_QUESTION_JUMP_CONDITION'
export const FORMBUILDER_DELETE_QJUMPS = 'FORMBUILDER_DELETE_QUESTION_JUMPS'

export const FORMBUILDER_UPDATE_QADDRESS_FIELDS = 'FORMBUILDER_UPDATE_QUESTION_ADDRESS_FIELDS'
export const FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY = 'FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY'
export const FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY_SOURCE = 'FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY_SOURCE'
export const FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_FIELDS_EDIT = 'FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_FIELDS_EDIT'
export const FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE = 'FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE'

export const FORMBUILDER_UPDATE_QEMAIL_REPLYTO = 'FORMBUILDER_UPDATE_QEMAIL_REPLYTO'
export const FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL = 'FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL'
export const FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELDS = 'FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELDS'
export const FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELD = 'FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELD'
export const FORMBUILDER_ADD_QEMAIL_RESTRICTEMAILFIELD = 'FORMBUILDER_ADD_QEMAIL_RESTRICTEMAILFIELD'
export const FORMBUILDER_DELETE_QEMAIL_RESTRICTEMAILFIELD = 'FORMBUILDER_DELETE_QEMAIL_RESTRICTEMAILFIELD'

export const FORMBUILDER_UPDATE_QPHONE_DEFAULT_COUNTRY_CODE = 'FORMBUILDER_UPDATE_QPHONE_DEFAULT_COUNTRY_CODE'
export const FORMBUILDER_UPDATE_QPHONE_COUNTRY_CODE_VALUE = 'FORMBUILDER_UPDATE_QPHONE_COUNTRY_CODE_VALUE'

export const FORMBUILDER_UPDATE_QNUMBER_QMINMAXLIMIT = 'FORMBUILDER_UPDATE_QNUMBER_QMINMAXLIMIT'
export const FORMBUILDER_UPDATE_QMINNUMBER = 'FORMBUILDER_UPDATE_QMINNUMBER'
export const FORMBUILDER_UPDATE_QMAXNUMBER = 'FORMBUILDER_UPDATE_QMAXNUMBER'
// Range
export const FORMBUILDER_UPDATE_QRANGE_UNITVALUES = 'FORMBUILDER_UPDATE_QRANGE_UNITVALUES'
export const FORMBUILDER_UPDATE_QRANGE_STEPCOUNT = 'FORMBUILDER_UPDATE_QRANGE_STEPCOUNT'
export const FORMBUILDER_UPDATE_QRANGE_CUSTOMTEXT = 'FORMBUILDER_UPDATE_QRANGE_CUSTOMTEXT'
export const FORMBUILDER_UPDATE_QRANGE_SHORIENTATIONSCALE = 'FORMBUILDER_UPDATE_QRANGE_SHORIENTATIONSCALE'
export const FORMBUILDER_UPDATE_QRANGE_RANGEFIELDS = 'FORMBUILDER_UPDATE_QRANGE_RANGEFIELDS'
export const FORMBUILDER_UPDATE_QRANGE_RANGEFIELD = 'FORMBUILDER_UPDATE_QRANGE_RANGEFIELD'

// theme
export const FORMBUILDER_SET_THEME_SETTING_FIRST_TIME = 'FORMBUILDER_SET_THEME_SETTING_FIRST_TIME'
export const FORMBUILDER_SET_DEFAULT_THEME = 'FORMBUILDER_SET_DEFAULT_THEME'
export const UPDATE_FORM_THEME_GENERAL = 'UPDATE_FORM_THEME_GENERAL'
export const UPDATE_FORM_THEME_TYPOGRAPHY = 'UPDATE_FORM_THEME_TYPOGRAPHY'
export const UPDATE_FORM_THEME_UI_ELEMENTS = 'UPDATE_FORM_THEME_UI_ELEMENTS'

export const FORMBUILDER_SAVE_THEME_SETTING = 'FORMBUILDER_SAVE_THEME_SETTING'
export const FORMBUILDER_GET_THEME_SETTING = 'FORMBUILDER_GET_THEME_SETTING'
export const FORMBUILDER_UPDATE_THEME_SETTING = 'FORMBUILDER_UPDATE_THEME_SETTING'

export const FORMBUILDER_GET_THEME_SETTING_SUCCESS = 'FORMBUILDER_GET_THEME_SETTING_SUCCESS'

// Theme templates
export const FORMBUILDER_SAVE_FORM_THEME_TEMPLATE = 'FORMBUILDER_SAVE_FORM_THEME_TEMPLATE'
export const FORMBUILDER_GET_FORM_THEME_TEMPLATES = 'FORMBUILDER_GET_FORM_THEME_TEMPLATES'
export const FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS = 'FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS'
export const FORMBUILDER_SET_TEMPLATE_THEME = 'FORMBUILDER_SET_TEMPLATE_THEME'

// elements
export const FORMBUILDER_UPDATE_ENUMBER = 'FORMBUILDER_UPDATE_ELEMENT_NUMBER'
export const FORMBUILDER_DELETE_E = 'FORMBUILDER_DELETE_ELEMENT'

export const FORMBUILDER_UPDATE_TB_ELEMENT_CONTENT = 'FORMBUILDER_UPDATE_TEXTBOX_ELEMENT_CONTENT'

/* form */
export const FORM_UPDATE_IFRAME_HEIGHT = 'FORM_UPDATE_IFRAME_HEIGHT'
export const FORM_UPDATE_IFRAME_WIDTH = 'FORM_UPDATE_IFRAME_WIDTH'
export const FORM_UPDATE_IFRAME_DIMENSIONS = 'FORM_UPDATE_IFRAME_DIMENSIONS'
export const FORM_UPDATE_IFRAME_FORM_SHADOW = 'FORM_UPDATE_IFRAME_FORM_SHADOW'
export const FORM_FULL_WIDTH_FORM = 'FORM_FULL_WIDTH_FORM'
export const FORM_SAVE_LEAD = 'FORM_SAVE_LEAD'
export const FORM_DELETE_LEAD = 'FORM_DELETE_LEAD'
export const FORM_DELETE_LEAD_SUCCESS = 'FORM_DELETE_LEAD_SUCCESS'

export const FORM_FETCH_LEADS = 'FORM_FETCH_LEAD' //  leads/responses
export const FORM_FETCH_LEADS_SUCCESS = 'FORM_FETCH_LEAD_SUCCESS'
export const FORM_FETCH_LEADS_ERROR = 'FORM_FETCH_LEAD_ERROR'
export const FETCH_FORMS_COUNT = 'FETCH_FORMS_COUNT'
export const FETCH_PARTIAL_LEADS_COUNT = 'FETCH_PARTIAL_LEADS_COUNT'
export const FETCH_COMPLETE_LEADS_COUNT = 'FETCH_COMPLETE_LEADS_COUNT'

export const FETCH_FORMS_BY_USER = 'FETCH_FORMS_BY_USER'
export const FETCH_FORM_BY_USER = 'FETCH_FORM_BY_USER'
export const FETCH_FORM_BY_KEY = 'FETCH_FORM_BY_KEY'
export const FORM_FETCH_OVERVIEW = 'FORM_FETCH_OVERVIEW'
export const FORM_FETCH_OVERVIEW_SUCCESS = 'FORM_FETCH_OVERVIEW_SUCCESS'
export const FORM_FETCH_OVERVIEW_ERROR = 'FORM_FETCH_OVERVIEW_ERROR'
export const FETCH_FORM = 'FETCH_FORM'
export const UPDATE_FORM = 'UPDATE_FORM'
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS'
export const FETCH_FORM_ERROR = 'FETCH_FORM_ERROR'
export const FETCH_FORMS = 'FETCH_FORMS'
export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS'
export const FETCH_FORMS_ERROR = 'FETCH_FORMS_ERROR'

export const FORM_DUPLICATE = 'FORM_DUPLICATE'
export const FORM_DUPLICATE_SUCCESS = 'FORM_DUPLICATE_SUCCESS'
export const FORM_DUPLICATE_ERROR = 'FORM_DUPLICATE_ERROR'

export const FORM_ARCHIVE = 'FORM_ARCHIVE'
export const FORM_ARCHIVE_SUCCESS = 'FORM_ARCHIVE_SUCCESS'
export const FORM_ARCHIVE_ERROR = 'FORM_ARCHIVE_ERROR'

export const FORM_SHARE = 'FORM_SHARE'

/* form visits */
export const FORM_CREATE_VISIT_BY_KEY = 'FORM_CREATE_VISIT_BY_KEY'

/* form variants */
export const FETCH_FORM_VARIANTS = 'FETCH_FORM_VARIANTS'
export const FETCH_FORM_VARIANTS_SUCCESS = 'FETCH_FORM_VARIANTS_SUCCESS'
export const FETCH_FORM_VARIANTS_ERROR = 'FETCH_FORM_VARIANTS_ERROR'

export const FORM_FETCH_VARIANT_STATE = 'FORM_FETCH_VARIANT_STATE'
export const FORM_FETCH_VARIANT_STATE_SUCCESS = 'FORM_FETCH_VARIANT_STATE_SUCCESS'
export const FORM_FETCH_VARIANT_STATE_ERROR = 'FORM_FETCH_VARIANT_STATE_ERROR'

export const DUPLICATE_FORM_VARIANT = 'DUPLICATE_FORM_VARIANT'
export const DUPLICATE_FORM_VARIANT_SUCCESS = 'DUPLICATE_FORM_VARIANT_SUCCESS'
export const DUPLICATE_FORM_VARIANT_ERROR = 'DUPLICATE_FORM_VARIANT_ERROR'

export const DELETE_FORM_VARIANT = 'DELETE_FORM_VARIANT'
export const DELETE_FORM_VARIANT_SUCCESS = 'DELETE_FORM_VARIANT_SUCCESS'
export const DELETE_FORM_VARIANT_ERROR = 'DELETE_FORM_VARIANT_ERROR'

export const DELETE_FORM_LEAD = 'DELETE_FORM_LEAD'
export const DELETE_FORM_LEAD_SUCCESS = 'DELETE_FORM_LEAD_SUCCESS'
export const DELETE_FORM_LEAD_ERROR = 'DELETE_FORM_LEAD_ERROR'

export const PROMOTE_FORM_VARIANT = 'PROMOTE_FORM_VARIANT'
export const UPDATE_FORM_VARIANT_SETTING = 'UPDATE_FORM_VARIANT_SETTING'

/* form setting */
export const FORM_FETCH_SETTING = 'FETCH_FORM_SETTING'
export const FORM_FETCH_SETTING_SUCCESS = 'FETCH_FORM_SETTING_SUCCESS'
export const FORM_FETCH_SETTING_ERROR = 'FETCH_FORM_SETTING_ERROR'
export const FORM_SETTING_UPDATE_FIELD = 'FORM_SETTING_UPDATE_FIELD'
export const FORM_SAVE_SETTING = 'FORM_SAVE_SETTING'

// Partial leads
export const SAVE_PARTIAL_LEADS_SETTINGS = 'SAVE_PARTIAL_LEADS_SETTINGS'
export const SAVE_PARTIAL_LEADS_SETTINGS_SUCCESS = 'SAVE_PARTIAL_LEADS_SETTINGS_SUCCESS'
export const FETCH_GLOBAL_PARTIAL_SETTING = 'FETCH_GLOBAL_PARTIAL_SETTING'
export const FETCH_GLOBAL_PARTIAL_LEADS_SETTINGS_SUCCESS = 'FETCH_GLOBAL_PARTIAL_LEADS_SETTINGS_SUCCESS'

/* form experiment */
export const FORM_EXPERIMENT_CREATE = 'FORM_EXPERIMENT_CREATE'
export const FORM_EXPERIMENT_CREATE_SUCCESS = 'FORM_EXPERIMENT_CREATE_SUCCESS'
export const FORM_EXPERIMENT_CREATE_ERROR = 'FORM_EXPERIMENT_CREATE_ERROR'

export const FORM_EXPERIMENT_UPDATE = 'FORM_EXPERIMENT_UPDATE'
export const FORM_EXPERIMENT_UPDATE_SUCCESS = 'FORM_EXPERIMENT_UPDATE_SUCCESS'
export const FORM_EXPERIMENT_UPDATE_ERROR = 'FORM_EXPERIMENT_CREATE_ERROR'

export const FETCH_FORM_EXPERIMENTS = 'FETCH_FORM_EXPERIMENTS'
export const FETCH_FORM_EXPERIMENTS_SUCCESS = 'FETCH_FORM_EXPERIMENTS_SUCCESS'
export const FETCH_FORM_EXPERIMENTS_ERROR = 'FETCH_FORM_EXPERIMENTS_ERROR'

export const FETCH_FORM_EXPERIMENT = 'FETCH_FORM_EXPERIMENT'
export const FETCH_FORM_EXPERIMENT_SUCCESS = 'FETCH_FORM_EXPERIMENT_SUCCESS'
export const FETCH_FORM_EXPERIMENT_ERROR = 'FETCH_FORM_EXPERIMENT_ERROR'

export const FORM_EXPERIMENT_START = 'FORM_EXPERIMENT_START'
export const FORM_EXPERIMENT_START_SUCCESS = 'FORM_EXPERIMENT_START_SUCCESS'
export const FORM_EXPERIMENT_START_ERROR = 'FORM_EXPERIMENT_START_ERROR'

export const FORM_EXPERIMENT_END = 'FORM_EXPERIMENT_END'
export const FORM_EXPERIMENT_END_SUCCESS = 'FORM_EXPERIMENT_END_SUCCESS'
export const FORM_EXPERIMENT_END_ERROR = 'FORM_EXPERIMENT_END_ERROR'

export const FORM_EXPERIMENT_RESULT = 'FORM_EXPERIMENT_RESULT'
export const FORM_EXPERIMENT_RESULT_SUCCESS = 'FORM_EXPERIMENT_RESULT_SUCCESS'
export const FORM_EXPERIMENT_RESULT_ERROR = 'FORM_EXPERIMENT_RESULT_ERROR'

/* form experiment variants */
export const FETCH_FORM_EXPERIMENT_VARIANTS = 'FETCH_FORM_EXPERIMENT_VARIANTS'
export const FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS = 'FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS'
export const FETCH_FORM_EXPERIMENT_VARIANTS_ERROR = 'FETCH_FORM_EXPERIMENT_VARIANTS_ERROR'

/* form leads */
export const FETCH_LEADS_COUNT = 'FETCH_LEADS_COUNT'

/* form hidden fields */
export const RESET_FORM_HIDDEN_FIELDS = 'RESET_FORM_HIDDEN_FIELDS'
export const FETCH_FORM_HIDDEN_FIELDS = 'FETCH_FORM_HIDDEN_FIELDS'
export const FETCH_FORM_HIDDEN_FIELDS_SUCCESS = 'FETCH_FORM_HIDDEN_FIELDS_SUCCESS'
export const UPDATE_FORM_HIDDEN_FIELDS_SUCCESS = 'UPDATE_FORM_HIDDEN_FIELDS_SUCCESS'
export const REMOVE_FORM_HIDDEN_FIELD = 'REMOVE_FORM_HIDDEN_FIELD'
export const UPDATE_FORM_HIDDEN_FIELD_ATTRIBUTE = 'UPDATE_FORM_HIDDEN_FIELD_ATTRIBUTE'
export const SAVE_FORM_HIDDEN_FIELDS = 'SAVE_FORM_HIDDEN_FIELDS'

/* Form Footer Text */
export const UPDATE_FORM_FOOTER_TEXT = 'UPDATE_FORM_FOOTER_TEXT'

/* dashboard */
export const FETCH_AVG_CONV_RATE_COUNT = 'FETCH_AVERAGE_CONVERSION_RATE_COUNT'

/* user */
export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'

export const UPDATE_USER_BASICDETAILS = 'UPDATE_USER_BASICDETAILS'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE'
export const UPDATE_USER_ACTIVATION = 'UPDATE_USER_ACTIVATION'
export const CANCEL_USER_SUBSCRIPTION = 'CANCEL_USER_SUBSCRIPTION'
export const UPDATE_USER_LOCAL = 'UPDATE_USER_LOCAL'

// register
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED = 'REGISTER_FAILED'

// login
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT_USER = 'LOGOUT_USER'

// verification
export const VERIFY_USER_ACCOUNT = 'VERIFY_USER_ACCOUNT'
export const SEND_ACCOUNT_VERIFICATION = 'SEND_ACCOUNT_VERIFICATION'
export const SEND_PASSWORD_RESET_LINK = 'SEND_PASSWORD_RESET_LINK'
export const RESET_PASSWORD = 'RESET_PASSWORD'

// plan
export const CHANGE_USER_PLAN = 'CHANGE_USER_PLAN'
export const CHANGE_USER_PLAN_SUCCESS = 'CHANGE_USER_PLAN_SUCCESS'

export const CANCEL_USER_PLAN = 'CANCEL_USER_PLAN'

export const RESUME_USER_PLAN = 'RESUME_USER_PLAN'

export const FETCH_PLANS = 'FETCH_PLANS'
export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS'

export const FETCH_PLAN_SUBSCRIPTION = 'FETCH_PLAN_SUBSCRIPTION'
export const FETCH_PLAN_SUBSCRIPTION_SUCCESS = 'FETCH_PLAN_SUBSCRIPTION_SUCCESS'

// Stripe
export const FETCH_STRIPE_SETUP_INTENT = 'FETCH_STRIPE_SETUP_INTENT'
export const FETCH_STRIPE_COUPON_CODE = 'FETCH_STRIPE_COUPON_CODE'

// lead proofs
export const CREATE_LEAD_PROOF = 'CREATE_LEAD_PROOF'
export const UPDATE_LEAD_PROOF = 'UPDATE_LEAD_PROOF'
export const FETCH_LEAD_PROOF = 'FETCH_LEAD_PROOF'
export const FETCH_LEAD_PROOFS = 'FETCH_LEAD_PROOFS'
export const DELETE_LEAD_PROOF = 'DELETE_LEAD_PROOF'
// Onboarding
export const SAVE_ONBOARDING = 'SAVE_ONBOARDING'
export const SAVE_ONBOARDING_FLAG = 'SAVE_ONBOARDING_FLAG'

// mark read lead notifications
export const MARK_READ_LEAD = 'MARK_READ_LEAD'
export const MARK_READ_LEAD_ERROR = 'MARK_READ_LEAD_ERROR'

// FORM LISTS

export const FETCH_FORM_LISTS = 'FETCH_FORM_LISTS'
export const FETCH_FORM_LISTS_SUCCESS = 'FETCH_FORM_LISTS_SUCCESS'
export const BULK_DELETE_FORMS = 'BULK_DELETE_FORMS'
export const BULK_DELETE_FORMS_SUCCESS = 'BULK_DELETE_FORMS_SUCCESS'
export const BULK_DUPLICATE_FORMS = 'BULK_DUPLICATE_FORMS'
export const BULK_DUPLICATE_FORMS_SUCCESS = 'BULK_DUPLICATE_FORMS_SUCCESS'

// USER LISTS

export const FETCH_USER_LISTS = 'FETCH_USER_LISTS'
export const FETCH_USER_LISTS_SUCCESS = 'FETCH_USER_LISTS_SUCCESS'

// LEADPROOF LISTS

export const FETCH_LEAD_PROOF_LISTS = 'FETCH_LEAD_PROOF_LISTS'
export const FETCH_LEAD_PROOF_LISTS_SUCCESS = 'FETCH_LEAD_PROOF_LISTS_SUCCESS'
export const FETCH_LEAD_PROOF_LIST_COUNT = 'FETCH_LEAD_PROOF_LIST_COUNT'

// EXTERNAL-CHECKOUTS LISTS

export const ECM_FETCH_EXTERNAL_CHECKOUTS = 'ECM_FETCH_EXTERNAL_CHECKOUTS'
export const ECM_FETCH_EXTERNAL_CHECKOUTS_SUCCESS = 'ECM_FETCH_EXTERNAL_CHECKOUTS_LISTS_SUCCESS'
export const ECM_DELETE_EXTERNAL_CHECKOUT = 'ECM_DELETE_EXTERNAL_CHECKOUT'
export const ECM_DELETE_EXTERNAL_CHECKOUT_SUCCESS = 'ECM_DELETE_EXTERNAL_CHECKOUT_SUCCESS'

// TEMPLATE LISTS

export const FETCH_FORM_TEMPLATE_LISTS = 'FETCH_FORM_TEMPLATE_LISTS'
export const FETCH_FORM_TEMPLATE_LISTS_SUCCESS = 'FETCH_FORM_TEMPLATE_LISTS_SUCCESS'
export const DELETE_FORM_TEMPLATE = 'DELETE_FORM_TEMPLATE'
export const DELETE_FORM_TEMPLATE_SUCCESS = 'DELETE_FORM_TEMPLATE_SUCCESS'

/* USER setting */
export const FETCH_USER_SETTING_ERROR = 'FETCH_USER_SETTING_ERROR'
export const USER_SETTING_UPDATE_FIELD = 'USER_SETTING_UPDATE_FIELD'

// PACKAGE BUILDER
export const FETCH_AVAILABLE_FEATURES = 'FETCH_AVAILABLE_FEATURES'
export const FETCH_AVAILABLE_FEATURES_SUCCESS = 'FETCH_AVAILABLE_FEATURES_SUCCESS'
export const FETCH_FEATURE_PROPERTIES = 'FETCH_FEATURE_PROPERTIES'
export const FETCH_FEATURE_PROPERTIES_SUCCESS = 'FETCH_FEATURE_PROPERTIES_SUCCESS'
export const FETCH_PACKAGE_LISTS = 'FETCH_PACKAGE_LISTS'
export const FETCH_PACKAGE_LISTS_SUCCESS = 'FETCH_PACKAGE_LISTS_SUCCESS'
export const FETCH_PACKAGE_DATA = 'FETCH_PACKAGE_DATA'
export const SAVE_PACKAGE_DATA = 'SAVE_PACKAGE_DATA'
export const UPDATE_PACKAGE_DATA = 'UPDATE_PACKAGE_DATA'
export const FETCH_FORM_REPORT_DATA = 'FETCH_FORM_REPORT_DATA'

// UTM Params
export const CREATE_UTM_PARAMS = 'CREATE_UTM_PARAMS'
export const CREATE_UTM_PARAMS_SUCCESS = 'CREATE_UTM_PARAMS_SUCCESS'
export const UPDATE_UTM_PARAMS = 'UPDATE_UTM_PARAMS'
export const FETCH_UTM_PARAM = 'FETCH_UTM_PARAM'
export const FETCH_UTM_PARAM_SUCCESS = 'FETCH_UTM_PARAM_SUCCESS'
export const FETCH_UTM_PARAMS_LISTS = 'FETCH_UTM_PARAMS_LISTS'
export const FETCH_UTM_PARAMS_LISTS_SUCCESS = 'FETCH_UTM_PARAMS_LISTS_SUCCESS'
export const DELETE_UTM_PARAM = 'DELETE_UTM_PARAM'

// FORM ENDINGS
export const FETCH_FORM_ENDINGS = 'FETCH_FORM_ENDINGS'
export const FETCH_FORM_ENDINGS_DATA = 'FETCH_FORM_ENDINGS_DATA'
export const UPDATE_FORM_ENDINGS_DATA = 'UPDATE_FORM_ENDINGS_DATA'
export const FETCH_FORM_ENDINGS_SUCCESS = 'FETCH_FORM_ENDINGS_SUCCESS'
export const FETCH_FORM_QUESTIONS = 'FETCH_FORM_QUESTIONS'
export const FETCH_FORM_QUESTIONS_SUCCESS = 'FETCH_FORM_QUESTIONS_SUCCESS'
export const DELETE_FORM_ENDING = 'DELETE_FORM_ENDING'
export const DUPLICATE_FORM_ENDING = 'DUPLICATE_FORM_ENDING'
export const BULK_DELETE_FORM_ENDINGS = 'BULK_DELETE_FORM_ENDINGS'
export const BULK_DELETE_FORM_ENDINGS_SUCCESS = 'BULK_DELETE_FORM_ENDINGS_SUCCESS'
export const BULK_DUPLICATE_FORM_ENDINGS = 'BULK_DUPLICATE_FORM_ENDINGS'
export const BULK_DUPLICATE_FORM_ENDINGS_SUCCESS = 'BULK_DUPLICATE_FORM_ENDINGS_SUCCESS'
export const SET_VISITOR_PRIORITY = 'SET_VISITOR_PRIORITY'
