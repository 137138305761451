import * as mutationTypes from '@/store/mutation-types'
import * as PartialLeadsApi from '@/api/partialleads'

const state = {
  globalPartialSettings: []
}

const getters = {
  getGlobalPartialSetting: function (state) {
    return state.globalPartialSettings
  }
}

const actions = {
  saveSettings ({state, commit}, data) {
    commit(mutationTypes.SAVE_PARTIAL_LEADS_SETTINGS)
    return PartialLeadsApi.savePartialLeadSettings(data)
  },
  saveSettingsSuccess ({state, commit}, data) {
    commit(mutationTypes.SAVE_PARTIAL_LEADS_SETTINGS_SUCCESS, data)
  },
  fetchGlobalPartialSettings ({state, commit}, data) {
    commit(mutationTypes.FETCH_GLOBAL_PARTIAL_SETTING)
    return PartialLeadsApi.getGlobalPartialSetting(data.user_id)
  },
  fetchGlobalPartialSettingsSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_GLOBAL_PARTIAL_LEADS_SETTINGS_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.SAVE_PARTIAL_LEADS_SETTINGS] (state, data) {},
  [mutationTypes.SAVE_PARTIAL_LEADS_SETTINGS_SUCCESS] (state, data) {},
  [mutationTypes.FETCH_GLOBAL_PARTIAL_SETTING] (state, data) {},
  [mutationTypes.FETCH_GLOBAL_PARTIAL_LEADS_SETTINGS_SUCCESS] (state, data) {
    state.globalPartialSettings = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
