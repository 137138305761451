import * as mutationTypes from '@/store/mutation-types'
import * as FormApi from '@/api/form'

const state = {
  formLists: [],
  bulkDeleteForms: false,
  bulkDuplicateForms: false
}

const getters = {
  getFormLists: function (state) {
    return state.formLists
  }
}

const actions = {
  fetchFormLists ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_LISTS)
    return FormApi.getFormLists(data)
  },
  fetchFormListsSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_LISTS_SUCCESS, data)
  },
  bulkDeleteForms ({state, commit}, data) {
    commit(mutationTypes.BULK_DELETE_FORMS)
    return FormApi.bulkDeleteForms(data)
  },
  bulkDeleteFormsSuccess ({state, commit}, data) {
    commit(mutationTypes.BULK_DELETE_FORMS_SUCCESS, data)
  },
  bulkDuplicateForms ({state, commit}, data) {
    commit(mutationTypes.BULK_DUPLICATE_FORMS)
    return FormApi.bulkDuplicateForms(data)
  },
  bulkDuplicateFormsSuccess ({state, commit}, data) {
    commit(mutationTypes.BULK_DUPLICATE_FORMS_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_FORM_LISTS] (state, data) {},
  [mutationTypes.FETCH_FORM_LISTS_SUCCESS] (state, data) {
    state.formLists = data || []
  },
  [mutationTypes.BULK_DELETE_FORMS] (state, data) {
    state.bulkDeleteForms = true
  },
  [mutationTypes.BULK_DELETE_FORMS_SUCCESS] (state, data) {
    state.bulkDeleteForms = false
  },
  [mutationTypes.BULK_DUPLICATE_FORMS] (state, data) {
    state.bulkDuplicateForms = true
  },
  [mutationTypes.BULK_DUPLICATE_FORMS_SUCCESS] (state, data) {
    state.bulkDuplicateForms = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
