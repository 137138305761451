import { postData, getData, putData, deleteData } from "../plugins/ApiCalls"

export function fetchUserList(data) {
  return getData('users/list?listParams=' + encodeURIComponent(JSON.stringify(data)))
}

export function verifyUserAccount(userId, token) {
  return getData('verify-email/' + userId + '/' + token)
}

export function sendAccountVerification(email) {
  return postData('send-email-verification', { email: email }, { emulateJSON: true })
}

export function sendPasswordResetLink(data) {
  return postData('send-password-reset-link', data, { emulateJSON: true })
}

export function resetPassword(data) {
  return postData('reset-password', data, { emulateJSON: true })
}

export function fetchUsers(data) {
  let params = new URLSearchParams()

  if (data && data.page) {
    params.append('page', data.page)
  }

  if (data && data.search) {
    params.append('q', encodeURIComponent(JSON.stringify(data.search)))
  }

  return getData('users?' + params.toString())
}

export function fetchUser(userId) {
  return getData('users/' + userId)
}

export function updateBasicDetails(userId, data) {
  return putData('users/' + userId + '/basicdetails', data, { emulateJSON: true })
}

export function updateRole(userId, data) {
  return putData('users/' + userId + '/changerole', data, { emulateJSON: true })
}

export function updatePassword(userId, data) {
  return putData('users/' + userId + '/changepassword', data, { emulateJSON: true })
}

export function updateActivation(userId, data) {
  return putData('users/' + userId + '/activation', data, { emulateJSON: true })
}

export function cancelSubscription(userId) {
  return putData('users/' + userId + '/cancel-subscription')
}

export function requestUserDeletion(userId) {
  return putData('users/' + userId + '/request-deletion')
}

export function cancelUserDeletion(userId) {
  return deleteData('users/' + userId)
}

export function updateUserEmail(userId, data) {
  return putData('users/' + userId + '/update-email', data, { emulateJSON: true })
}

export function updateUserPassword(userId, data) {
  return putData('users/' + userId + '/update-password', data, { emulateJSON: true })
}

export function updateUser2FA(userId, data) {
  return putData('users/' + userId + '/update-two-factor', data)
}

export function fetchProfileSettings(userId) {
  return getData('profile-settings/' + userId)
}
