import * as mutationTypes from '@/store/mutation-types'
import * as formTemplateBrowserApi from '@/api/form-template-browser'

const state = {
  formTemplates: [],
  formTemplateCategories: [],
  formTemplateIndustries: []
}

const getters = {
  getFormTemplates: state => state.formTemplates,
  getFormTemplateCategories: state => state.formTemplateCategories,
  getFormTemplateIndustries: state => state.formTemplateIndustries
}

const actions = {
  getFormTemplates ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATES)
    return formTemplateBrowserApi.getFormTemplates()
  },
  getFormTemplatesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATES_SUCCESS, data)
  },

  getFormTemplateCategories ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_CATEGORIES)
    return formTemplateBrowserApi.getFormTemplateCategories()
  },
  getFormTemplateCategoriesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_CATEGORIES_SUCCESS, data)
  },

  getFormTemplateIndustries ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_INDUSTRIES)
    return formTemplateBrowserApi.getFormTemplateIndustries()
  },
  getFormTemplateIndustriesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_INDUSTRIES_SUCCESS, data)
  },

  useFormTemplate ({state, commit}, data) {
    commit(mutationTypes.USE_FORM_TEMPLATE)
    return formTemplateBrowserApi.useFormTemplate(data)
  },
  useFormTemplateError ({state, commit}, data) {
    commit(mutationTypes.USE_FORM_TEMPLATE_ERROR, data)
  }

}

const mutations = {
  [mutationTypes.FETCH_FORM_TEMPLATES] (state) {
  },
  [mutationTypes.FETCH_FORM_TEMPLATES_SUCCESS] (state, data) {
    state.formTemplates = data || []
  },

  [mutationTypes.FETCH_FORM_TEMPLATE_CATEGORIES] (state) {
  },
  [mutationTypes.FETCH_FORM_TEMPLATE_CATEGORIES_SUCCESS] (state, data) {
    state.formTemplateCategories = data || []
  },

  [mutationTypes.FETCH_FORM_TEMPLATE_INDUSTRIES] (state) {
  },
  [mutationTypes.FETCH_FORM_TEMPLATE_INDUSTRIES_SUCCESS] (state, data) {
    state.formTemplateIndustries = data || []
  },

  [mutationTypes.USE_FORM_TEMPLATE] (state, data) {
  },
  [mutationTypes.USE_FORM_TEMPLATE_ERROR] (state, data) {
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
