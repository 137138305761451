import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function createProof(data) {
  return postData('lead-proofs', data)
}

export function updateProof(id, data) {
  return putData('lead-proofs/' + id, data)
}

export function getProof(id) {
  return getData('lead-proofs/' + id)
}

export function getProofs() {
  return getData('lead-proofs')
}

export function deleteLeadProof(id) {
  return deleteData('lead-proofs/' + id)
}

export function getLeadProofList(data) {
  return getData('lead-proofs/list?listParams=' + encodeURIComponent(JSON.stringify(data)))
}

export function getLeadProofCounts() {
  return getData('lead-proofs/count')
}
