<template>
  <v-form ref="blackListFormRef" class="recaptcha-form" @submit.prevent="saveBlackList">
    <v-alert border :type="alert.type" variant="tonal" rounded="md" density="compact" class="mb-6"
      icon="mdi-information" v-if="alert.show">
      {{ alert.text }}
    </v-alert>

    <!-- IP Input -->
    <v-text-field class="mb-0" label="IP" placeholder="e.g. 255.456.789.012" variant="outlined" density="comfortable"
      prepend-inner-icon="mdi-ip" rounded="md" :rules="$valid([{ rule: 'required' }, { rule: 'ip' }])"
      v-model="form.ip">
    </v-text-field>

    <!-- Operator Input -->
    <v-select class="mb-0" label="Operator" variant="outlined" density="comfortable" rounded="md"
      prepend-inner-icon="mdi-vector-link" :rules="$valid([{ rule: 'required' }])" :items="operatorOptions"
      v-model="form.operator" />

    <!-- Reason Input -->
    <v-textarea class="mb-0" label="Reason" placeholder="Why do you want to block this IP?" variant="outlined"
      prepend-inner-icon="mdi-text-long" density="comfortable"
      :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '3', max: '150' }])" rounded="md" rows="2" auto-grow
      v-model="form.reason" />

    <!-- Form Actions -->
    <v-card-actions class="d-flex justify-end mb-3 mr-n2">
      <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
        @click="cancelForm()">
      </v-btn>
      <v-btn rounded="md" variant="elevated" class="font-weight-bold text-none" color="primary-red" :loading="saving"
        @click="saveBlackList">
        <span>Save</span>
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { reactive, watch, computed, ref, nextTick } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    editAction: Boolean,
    blacklistIpForm: Object
  },
  setup(props, { emit }) {
    const store = useStore()
    const blackListFormRef = ref(null)
    const saving = ref(false)

    const ipMatchOperators = [
      { text: 'Equal', value: 'EQUAL' },
      { text: 'Contains', value: 'CONTAINS' }
    ]

    const form = reactive({
      id: '',
      ip: '',
      reason: '',
      operator: 'EQUAL'
    })

    const alert = ref({
      show: false,
      type: '',
      text: ''
    })

    const operatorOptions = computed(() => ipMatchOperators.map(op => ({ title: op.text, value: op.value })))

    const validateForm = async () => {
      await nextTick() // Ensure the DOM has updated
      if (blackListFormRef.value) {
        const { valid } = await blackListFormRef.value.validate()
        return valid
      }
      return false
    }

    const saveBlackList = async () => {
      const isValid = await validateForm()
      if (isValid) {
        saving.value = true
        try {
          const response = props.editAction
            ? await store.dispatch('blacklistips/updateBlacklistIp', { id: form.id, ...form })
            : await store.dispatch('blacklistips/createBlacklistIp', form)

          await store.dispatch('blacklistips/fetchBlacklistIps')
          alert.value = { show: true, type: 'success', text: 'Blacklist IP saved successfully.' }
          emit('blacklistIpComplete', true)
        } catch (err) {
          alert.value = { show: true, type: 'error', text: err.response?.data?.meta?.error_message || 'An error occurred while saving.' }
          emit('blacklistIpComplete', false)
        } finally {
          saving.value = false
        }
      }
    }

    watch(() => props.blacklistIpForm, (newValue) => {
      if (newValue) Object.assign(form, newValue)
    }, { immediate: true })

    const cancelForm = () => {
      emit('closeDialog')
    }

    return {
      form,
      saveBlackList,
      operatorOptions,
      cancelForm,
      blackListFormRef,
      saving,
      alert
    }
  }
}
</script>
