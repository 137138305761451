<template>
  <v-row style="margin: 0 auto; width: 82%;">
    <v-col cols="12" class="mt-4 mb-4 ml-n2">
      <a @click="goBack">
        <v-icon color="primary-red" class="mr-2" icon="mdi-arrow-left"></v-icon> <span class="text-primary-red">Form
          Endings</span>
      </a>
    </v-col>
    <v-col cols="1" class="mt-3">
      <v-icon color="green" size="48">mdi-book-check</v-icon>
    </v-col>
    <v-col class="ml-n4 mt-1" cols="11">
      <h4 class="mb-n2 font-weight-bold text-capitalize">Change Thank you Page</h4>
      <p class="text-grey-darken-1">
        Change thank you page action according to the {{ headerMessage }}.
      </p>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    headerMessage: {
      type: String
    }
  },
  methods: {
    goBack: function () {
      if (!this.$route.params.id || !this.$route.params.variantId) {
        return
      }

      this.$router.push({
        name: 'forms.variants.edit',
        params: {
          id: this.$route.params.id,
          variantId: this.$route.params.variantId,
          tab: 'formendings'

        }
      })
    }
  },
}
</script>
<style scoped>
* {
  font-family: 'karla', sans-serif;
}
</style>
