import * as FormTrackingEventApi from '@/api/form-tracking-event'

export default {
  namespaced: true,
  state: {
  },
  getters: {
    trackingEvents (state) {
      return state.trackingEvents
    }
  },
  actions: {
    saveFormTrackingEvent (context, data) {
      return FormTrackingEventApi.updateTrackingEvent(data)
    }
  },
  mutations: {
  }
}
