<template>
  <div id="form-create-view">
     <form-builder></form-builder>
  </div>
</template>

<script>
import FormBuilder from '@/components/FormBuilder'
import * as actionTypes from '@/store/action-types'

export default {

  data () {
    return {

    }
  },

  components: {
    'form-builder': FormBuilder
  },

  mounted: function () {
    this.$store.dispatch(actionTypes.FORMBUILDER_RESET)
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>



</style>
