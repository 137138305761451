import * as mutationTypes from '@/store/mutation-types'
import * as TemplatesApi from '@/api/landingpage-templates'
import * as LandingPagesApi from '@/api/landingpages'
import * as LandingPageVisitApi from '@/api/landingpage-visits'
import * as LandingPageOptinApi from '@/api/landingpage-optins'

const state = {
  templates: [],
  pages: []
}

const getters = {
  templates: (state) => {
    return state.templates
  },
  pages: (state) => {
    return state.pages
  }
}

const actions = {
  fetchTemplates ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGE_TEMPLATES)
    return TemplatesApi.fetchTemplates(data)
  },
  fetchTemplatesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGE_TEMPLATES_SUCCESS, data)
  },
  fetchTemplatesError ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGE_TEMPLATES_ERROR, data)
  },

  createLandingPageTPL1 ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_TPL1)
    return LandingPagesApi.createLandingPageTPL1(data)
  },
  createLandingPageTPL1Success ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_TPL1_SUCCESS)
  },
  createLandingPageTPL1Error ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_TPL1_ERROR)
  },

  updateLandingPageTPL1 ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LANDINGPAGE_TPL1)
    return LandingPagesApi.updateLandingPageTPL1(data)
  },
  updateLandingPageTPL1Success ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LANDINGPAGE_TPL1_SUCCESS)
  },
  updateLandingPageTPL1Error ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LANDINGPAGE_TPL1_ERROR)
  },

  fetchLandingPages ({state, commit}, data) {
    return LandingPagesApi.fetchPages(data.page)
  },
  fetchLandingPagesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGES_SUCCESS, data)
  },
  fetchLandingPagesError ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGES_SUCCESS, data)
  },

  fetchLandingPage ({state, commit}, data) {
    return LandingPagesApi.fetchPage(data.pageId)
  },
  fetchLandingPageSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGE_SUCCESS, data)
  },
  fetchLandingPageError ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGE_SUCCESS, data)
  },

  fetchLandingPageView ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGE_VIEW)
    return LandingPagesApi.fetchPageView(data.pageId)
  },
  fetchLandingPageViewSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGE_VIEW_SUCCESS)
  },
  fetchLandingPageViewError ({state, commit}, data) {
    commit(mutationTypes.FETCH_LANDINGPAGE_VIEW_SUCCESS)
  },

  createLandingPageVisit ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_VISIT)
    return LandingPageVisitApi.createVisit(data)
  },
  createLandingPageVisitSuccess ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_VISIT_SUCCESS)
  },
  createLandingPageVisitError ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_VISIT_SUCCESS)
  },

  createLandingPageOptin ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_OPTIN)
    return LandingPageOptinApi.createOptin(data)
  },
  createLandingPageOptinSuccess ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_OPTIN_SUCCESS)
  },
  createLandingPageOptinError ({state, commit}, data) {
    commit(mutationTypes.CREATE_LANDINGPAGE_OPTIN_SUCCESS)
  },

  deleteLandingPages ({state, commit}, data) {
    commit(mutationTypes.DELETE_LANDINGPAGES)
    return LandingPagesApi.deletePages(data)
  },
  deleteLandingPagesSuccess ({state, commit}, data) {
    commit(mutationTypes.DELETE_LANDINGPAGES_SUCCESS, data)
  },
  deleteLandingPagesError ({state, commit}, data) {
    commit(mutationTypes.DELETE_LANDINGPAGES_SUCCESS, data)
  },

  updateLandingPageSlug ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LANDINGPAGE_SLUG)
    return LandingPagesApi.updateSlug(data)
  },
  updateLandingPageSlugSuccess ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LANDINGPAGE_SLUG_SUCCESS, data)
  },
  updateLandingPageSlugError ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LANDINGPAGE_SLUG_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_LANDINGPAGE_TEMPLATES] (state) {
  },
  [mutationTypes.FETCH_LANDINGPAGE_TEMPLATES_SUCCESS] (state, data) {
    state.templates = data
  },
  [mutationTypes.FETCH_LANDINGPAGE_TEMPLATES_ERROR] (state) {
    state.templates = []
  },

  [mutationTypes.CREATE_LANDINGPAGE_TPL1] (state) {
  },
  [mutationTypes.CREATE_LANDINGPAGE_TPL1_SUCCESS] (state) {
  },
  [mutationTypes.CREATE_LANDINGPAGE_TPL1_ERROR] (state) {
  },

  [mutationTypes.UPDATE_LANDINGPAGE_TPL1] (state) {
  },
  [mutationTypes.UPDATE_LANDINGPAGE_TPL1_SUCCESS] (state) {
  },
  [mutationTypes.UPDATE_LANDINGPAGE_TPL1_ERROR] (state) {
  },

  [mutationTypes.FETCH_LANDINGPAGES] (state) {
  },
  [mutationTypes.FETCH_LANDINGPAGES_SUCCESS] (state, data) {
    if (state.pages.length === 0 || data.page === 1) {
      state.pages = data.pages || []
    } else {
      state.pages = state.pages.concat(data.pages)
    }
  },
  [mutationTypes.FETCH_LANDINGPAGES_ERROR] (state) {
    state.pages = []
  },

  [mutationTypes.FETCH_LANDINGPAGE] (state) {
  },
  [mutationTypes.FETCH_LANDINGPAGE_SUCCESS] (state, data) {
    for (let page of state.pages) {
      if (page.id === data.id) {
        page = data
        return
      }
    }
    state.pages.push(data)
  },
  [mutationTypes.FETCH_LANDINGPAGE_ERROR] (state) {
  },

  [mutationTypes.FETCH_LANDINGPAGE_VIEW] (state) {
  },
  [mutationTypes.FETCH_LANDINGPAGE_VIEW_SUCCESS] (state, data) {
  },
  [mutationTypes.FETCH_LANDINGPAGE_VIEW_ERROR] (state) {
  },

  [mutationTypes.CREATE_LANDINGPAGE_VISIT] (state) {
  },
  [mutationTypes.CREATE_LANDINGPAGE_VISIT_SUCCESS] (state, data) {
  },
  [mutationTypes.CREATE_LANDINGPAGE_VISIT_ERROR] (state) {
  },

  [mutationTypes.CREATE_LANDINGPAGE_OPTIN] (state) {
  },
  [mutationTypes.CREATE_LANDINGPAGE_OPTIN_SUCCESS] (state, data) {
  },
  [mutationTypes.CREATE_LANDINGPAGE_OPTIN_ERROR] (state) {
  },

  [mutationTypes.DELETE_LANDINGPAGES] (state) {
  },
  [mutationTypes.DELETE_LANDINGPAGES_SUCCESS] (state, data) {
  },
  [mutationTypes.DELETE_LANDINGPAGES_ERROR] (state) {
  },

  [mutationTypes.UPDATE_LANDINGPAGE_SLUG] (state) {
  },
  [mutationTypes.UPDATE_LANDINGPAGE_SLUG_SUCCESS] (state, data) {
  },
  [mutationTypes.UPDATE_LANDINGPAGE_SLUG_ERROR] (state) {
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
