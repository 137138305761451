import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function createScript(data) {
  return postData('utm-params', data)
}

export function updateScript(id, data) {
  return putData('utm-params/' + id, data)
}

export function getScript(id) {
  return getData('utm-params/' + id)
}

export function getScripts() {
  return getData('utm-params')
}

export function deleteScript(id) {
  return deleteData('utm-params/' + id)
}

export function getScriptsList(data) {
  return getData('utm-params/list?listParams=' + encodeURIComponent(JSON.stringify(data)))
}
