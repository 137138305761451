import * as mutationTypes from '@/store/mutation-types'
import * as DashboardApi from '@/api/dashboard'
import _ from 'lodash'

const state = {
  widgets: []
}

const getters = {
  getWidget: (state) => (type) => {
    return _.find(state.widgets, {type: type})
  }
}

const actions = {
  fetchDashboardWidget (context, data) {
    return DashboardApi.fetchDashboardWidget(data)
  },
  fetchDashboardWidgetSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_DASHBOARD_WIDGET_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_DASHBOARD_WIDGET_SUCCESS] (state, data) {
    let widgets = state.widgets.filter((d) => d.type !== data.type)
    widgets.push(data)
    state.widgets = widgets
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
