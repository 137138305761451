import templateKeywords from "@/shared/constants/templates/index"

export default {
  data() {
    return {
      mixin_load: {
        loader: true,
        page: false
      },
      mixin_paginate: {
        loader: false,
        hasMore: true,
        loadFrom: 0,
        templatesToShow: 15
      },
      mixin_selectedFilter: {
        category: null,
        industry: null,
        step: null,
        keyword: ""
      },
      mixin_use: {
        dialog: false,
        loading: false,
        error: false,
        message: null
      }
    }
  },
  mounted() {
    this.loadFormTemplateCategories()
    this.loadFormTemplateIndustries()
    this.loadFormTemplates()
  },
  computed: {
    formTemplateCategories: function () {
      return (
        this.$store.getters["formtemplatebrowser/getFormTemplateCategories"] ||
        []
      )
    },
    formTemplateIndustries: function () {
      return (
        this.$store.getters["formtemplatebrowser/getFormTemplateIndustries"] ||
        []
      )
    },
    formTemplates: function () {
      return this.$store.getters["formtemplatebrowser/getFormTemplates"] || []
    },
    mixin_templateKeywords: function () {
      return templateKeywords
    },
    mixin_filteredTemplates: function () {
      return this.formTemplates.filter((template) => {
        // If any condition is not met, we return false, which excludes this template from the filtered results

        if (
          this.mixin_selectedFilter.category &&
          !template.template_categories.some(
            (category) => category.id === this.mixin_selectedFilter.category
          )
        ) {
          return false // Exclude this template
        }

        if (
          this.mixin_selectedFilter.industry &&
          !template.template_industries.some(
            (industry) => industry.id === this.mixin_selectedFilter.industry
          )
        ) {
          return false // Exclude this template
        }

        if (this.mixin_selectedFilter.step) {
          const isMulti =
            this.mixin_selectedFilter.step ===
            this.mixin_templateKeywords.MULTI
          if (
            (isMulti && template.template_steps_count <= 1) ||
            (!isMulti && template.template_steps_count !== 1)
          ) {
            return false // Exclude this template
          }
        }

        if (
          this.mixin_selectedFilter.keyword &&
          !template.title
            .toLowerCase()
            .includes(this.mixin_selectedFilter.keyword.toLowerCase())
        ) {
          return false // Exclude this template
        }

        return true // Include this template in the filtered results
      })
    }
  },
  methods: {
    resetLoader: function () {
      this.mixin_load = {
        loader: true,
        page: false
      }
    },
    resetPaginate: function () {
      this.mixin_paginate = {
        loader: false,
        hasMore: true,
        loadFrom: 0,
        templatesToShow: 15
      }
    },
    resetFilters: function () {
      this.mixin_selectedFilter = {
        category: null,
        industry: null,
        step: null,
        keyword: ""
      }
    },
    resetUse: function () {
      this.mixin_use = {
        dialog: false,
        loading: false
      }
    },
    loadFormTemplateCategories: function () {
      this.$store
        .dispatch("formtemplatebrowser/getFormTemplateCategories")
        .then(
          (response) => {
            const sortedCategories = response.data.data.sort((a, b) =>
              a.title.localeCompare(b.title)
            )
            this.$store.dispatch(
              "formtemplatebrowser/getFormTemplateCategoriesSuccess",
              sortedCategories
            )
          },
          (error) => {
            this.mixin_load.loader = false
            this.mixin_load.page = false
            console.error(error)
          }
        )
    },
    loadFormTemplateIndustries: function () {
      this.$store
        .dispatch("formtemplatebrowser/getFormTemplateIndustries")
        .then(
          (response) => {
            const sortedIndustries = response.data.data.sort((a, b) =>
              a.title.localeCompare(b.title)
            )
            this.$store.dispatch(
              "formtemplatebrowser/getFormTemplateIndustriesSuccess",
              sortedIndustries
            )
          },
          (error) => {
            this.mixin_load.loader = false
            this.mixin_load.page = false
            console.error(error)
          }
        )
    },
    loadFormTemplates: function () {
      this.$store.dispatch("formtemplatebrowser/getFormTemplates").then(
        (response) => {
          this.$store.dispatch(
            "formtemplatebrowser/getFormTemplatesSuccess",
            response.data.data
          )
          setTimeout(() => {
            this.mixin_load.loader = false
            this.mixin_load.page = true
          }, 50)
        },
        (error) => {
          this.mixin_load.loader = false
          this.mixin_load.page = false
          console.error(error)
        }
      )
    }
  }
}
