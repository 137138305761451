<template>
  <v-dialog v-model="showDialog" v-bind="$attrs">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <slot name="header">
          <label>{{ title }}</label>
          <div class="text-right"><v-btn icon="mdi-close" variant="text" @click="toggleDialog()"></v-btn></div>
        </slot>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    submitBtnLabel: {
      type: String,
      default: 'Submit'
    },
    cancelBtnLabel: {
      type: String,
      default: 'Cancel'
    },
    height: {
      type: String,
      default: '500px'
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
      this.showDialog = newValue
    }
  },
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog
      if (!this.showDialog) {
        this.$emit('close')
      }
    }
  },
}
</script>

<style scoped></style>
