export const planFeatures = {
  featuresStarter: [
    "Contact State Integration",
    "Google Analytics Integration",
    "UTM Parameters",
    "Webhook Integration",
    "Zapier Integration",
    "Email Notifications",
    "Form Geo Fencing",
    "Add Tracking Events",
    "Premium Themes",
    "Form Metrics",
  ],
  featuresPro: [
    "Contact State Integration",
    "Google Analytics Integration",
    "UTM Parameters",
    "Webhook Integration",
    "Zapier Integration",
    "Email Notifications",
    "Form Geo Fencing",
    "Add Tracking Events",
    "Premium Themes",
    "Form Metrics",
    "Lead Proof Popups",
  ],
  featuresScale: [
    "Contact State Integration",
    "Google Analytics Integration",
    "UTM Parameters",
    "Trustedform Integration",
    "Webhook Integration",
    "Zapier Integration",
    ["Multiple Form Endings", "Answer-Based"],
    "Email Notifications",
    "Form Geo Fencing",
    "Add Tracking Events",
    "Premium Themes",
    "Form Metrics",
    "Lead Proof Popups",
  ],
  featuresEnterprise: [
    "Contact State Integration",
    "Google Analytics Integration",
    "UTM Parameters",
    "Trustedform Integration",
    "Webhook Integration",
    "Zapier Integration",
    ["Multiple Form Endings", "Answer-Based", "Visitor-Based"],
    "Email Notifications",
    "Form Geo Fencing",
    "Add Tracking Events",
    "Premium Themes",
    "Form Metrics",
    "Lead Proof Popups",
    "Account Manager",
  ],
  featuresFreeTrial: [
    "Contact State Integration",
    "Google Analytics Integration",
    "UTM Parameters",
    "Trustedform Integration",
    "Webhook Integration",
    "Zapier Integration",
    "Email Notifications",
    "Form Geo Fencing",
    "Add Tracking Events",
    "Premium Themes",
    "Form Metrics",
    "Lead Proof Popups",
  ],
  featuresSingle: [
    "Contact State Integration",
    "Google Analytics Integration",
    "UTM Parameters",
    "Webhook Integration",
    "Zapier Integration",
    "Email Notifications",
    "Form Geo Fencing",
    "Add Tracking Events",
    "Premium Themes",
    "Form Metrics",
  ],
};

export const paddleMonthlyPlans = [
  {
    title: "Starter",
    text: "Includes:",
    originalPrice: 19,
    price: 19,
    forms: 1,
    leads: 50,
    views: 12500,
    partial_leads: 0,
    publicId: "paddle_starter",
    features: planFeatures.featuresStarter,
    isMonthly: true,
    titleClass: "starter-bg",
  },
  {
    title: "Pro",
    text: "Includes:",
    originalPrice: 49,
    price: 49,
    forms: 20,
    leads: 500,
    views: 12500,
    partial_leads: 20,
    publicId: "paddle_pro",
    features: planFeatures.featuresPro,
    isMonthly: true,
    titleClass: "pro-bg",
  },
  {
    title: "Scale",
    text: "Includes:",
    originalPrice: 99,
    price: 99,
    forms: 100,
    publicId: "paddle_scale",
    leads: 2000,
    views: 12500,
    partial_leads: 1000,
    features: planFeatures.featuresScale,
    isMonthly: true,
    isPopular: true,
    titleClass: "scale-bg",
  },
  {
    title: "Enterprise",
    text: "Includes:",
    originalPrice: 250,
    price: 250,
    forms: 300,
    leads: 10000,
    views: 12500,
    partial_leads: 10000,
    publicId: "paddle_enterprise",
    features: planFeatures.featuresEnterprise,
    isMonthly: true,
    titleClass: "enterprise-bg",
  },
];

export const paddleYearlyPlans = [
  {
    title: "Single",
    text: "Includes:",
    originalPrice: 50,
    price: 50,
    forms: 1,
    leads: 30,
    views: 1000,
    partial_leads: 0,
    publicId: "paddle_single_annual",
    features: planFeatures.featuresSingle,
    titleClass: "single-bg",
  },
  {
    title: "Pro",
    text: "Includes:",
    originalPrice: 49,
    price: 39,
    forms: 20,
    leads: 500,
    views: 12500,
    partial_leads: 20,
    publicId: "paddle_pro_annual",
    features: planFeatures.featuresPro,
    titleClass: "pro-bg",
  },
  {
    title: "Scale",
    text: "Includes:",
    originalPrice: 99,
    price: 79,
    forms: 100,
    publicId: "paddle_scale_annual",
    leads: 2000,
    views: 12500,
    partial_leads: 1000,
    features: planFeatures.featuresScale,
    isPopular: true,
    titleClass: "scale-bg",
  },
  {
    title: "Enterprise",
    text: "Includes:",
    originalPrice: 250,
    price: 200,
    forms: 300,
    leads: 10000,
    views: 12500,
    partial_leads: 10000,
    publicId: "paddle_enterprise_annual",
    features: planFeatures.featuresEnterprise,
    titleClass: "enterprise-bg",
  },
];

export const paddleMonthlyTrialPlans = [
  {
    title: "Pro Trial",
    text: "Includes:",
    originalPrice: 49,
    price: 49,
    forms: 20,
    leads: 500,
    views: 12500,
    partial_leads: 20,
    publicId: "paddle_pro_trial",
    features: planFeatures.featuresPro,
    isMonthly: true,
    isTrialPlan: true,
    titleClass: "pro-bg",
  },
  {
    title: "Scale Trial",
    text: "Includes:",
    originalPrice: 99,
    price: 99,
    forms: 100,
    publicId: "paddle_scale_trial",
    leads: 2000,
    views: 12500,
    partial_leads: 1000,
    features: planFeatures.featuresScale,
    isMonthly: true,
    isPopular: true,
    isTrialPlan: true,
    titleClass: "scale-bg",
  },
  {
    title: "Enterprise Trial",
    text: "Includes:",
    originalPrice: 250,
    price: 250,
    forms: 300,
    leads: 10000,
    views: 12500,
    partial_leads: 10000,
    publicId: "paddle_enterprise_trial",
    features: planFeatures.featuresEnterprise,
    isMonthly: true,
    isTrialPlan: true,
    titleClass: "enterprise-bg",
  },
];

export const paddleYearlyTrialPlans = [
  {
    title: "Pro Trial",
    text: "Includes:",
    originalPrice: 49,
    price: 39,
    forms: 20,
    leads: 500,
    views: 12500,
    partial_leads: 20,
    publicId: "paddle_pro_annual_trial",
    features: planFeatures.featuresPro,
    titleClass: "pro-bg",
  },
  {
    title: "Scale Trial",
    text: "Includes:",
    originalPrice: 99,
    price: 79,
    forms: 100,
    publicId: "paddle_scale_annual_trial",
    leads: 2000,
    views: 12500,
    partial_leads: 1000,
    features: planFeatures.featuresScale,
    isPopular: true,
    titleClass: "scale-bg",
  },
  {
    title: "Enterprise Trial",
    text: "Includes:",
    originalPrice: 250,
    price: 200,
    forms: 300,
    leads: 10000,
    views: 12500,
    partial_leads: 10000,
    publicId: "paddle_enterprise_annual_trial",
    features: planFeatures.featuresEnterprise,
  },
];

export const freeTrialPlans = [
  {
    title: "Free Trial",
    text: "Includes:",
    originalPrice: 0,
    price: 0,
    forms: 20,
    leads: 500,
    views: 12500,
    partial_leads: 20,
    publicId: "free_trial",
    features: planFeatures.featuresFreeTrial,
  },
];
