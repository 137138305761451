export default {
  SHORT_TEXT: 'SHORT_TEXT',
  PARAGRAPH_TEXT: 'PARAGRAPH_TEXT',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  PHONE_NUMBER: 'PHONE_NUMBER',
  SINGLE_CHOICE: 'SINGLE_CHOICE',
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  GDPR: 'GDPR',
  DATE: 'DATE',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  ADDRESS: 'ADDRESS',
  NUMBER: 'NUMBER',
  URL: 'URL',
  RANGE: 'RANGE'
}
