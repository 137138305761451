<template>
  <v-container class="fill-height forgot-password-view">
    <v-row align="center" justify="center" class="my-16">
      <v-col cols="12" sm="10" md="8" lg="6" xl="4">
        <v-card class="text-center pa-6 my-2" flat>
          <v-card-title class="text-center d-flex flex-column align-center">
            <v-img :width="80" aspect-ratio="1" cover :src="require('@/assets/images/leadgen-leaf-logo.png')"></v-img>
            <br>
          </v-card-title>
          <v-card-text>
            <h4 class="text-h4 font-weight-bold mb-4">Forgot Password</h4>
            <div class="text-subtitle-1	 font-weight-regular">
              Enter your email and we'll send you instructions to reset your password.
            </div>
            <!-- Error / Warning alert -->
            <v-row justify="center" class="my-1">
              <v-col cols="12">
                <v-alert border :type="alert.type" variant="tonal" rounded="md" density="compact" class="my-4 text-left"
                  icon="mdi-alert-circle" v-if="alert.show">
                  {{ alert.message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-form ref="forgotPasswordForm">
              <v-row class="text-left">
                <v-col cols="12">
                  <v-text-field variant="outlined" label="Enter your email address"
                    prepend-inner-icon="mdi-email-outline" density="comfortable" rounded="md" v-model="reset.email"
                    :rules="$valid([
                      { rule: 'required', msg: 'Please enter your email address' },
                      { rule: 'email' }
                    ])"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="text-left">
                <v-col cols="6">
                  <v-btn variant="text" color="secondary-red" @click="$router.push({ name: 'login' })">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Back to login
                  </v-btn>
                </v-col>
                <!-- reset btn -->
                <v-col cols="6" class="text-right">
                  <v-btn color="primary-red" rounded="md" height="42" width="90%"
                    class="text-none font-weight-bold mb-0 reset-btn" append-icon="mdi-send" :loading="reset.loader"
                    @click="sendPasswordResetLink">Send Reset Link</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import * as actionTypes from '@/store/action-types.js'

export default {
  data: () => ({
    alert: {
      show: false,
      type: '',
      message: ''
    },
    reset: {
      email: null,
      loader: false
    }
  }),
  methods: {
    ...mapActions({
      sendResetLink: actionTypes.SEND_PASSWORD_RESET_LINK
    }),

    async sendPasswordResetLink() {
      const { valid } = await this.$refs.forgotPasswordForm.validate()
      if (!valid) {
        this.reset.loader = false
        return
      }
      this.reset.loader = true
      try {
        await this.sendResetLink({ email: this.reset.email })
        this.showAlert('success', 'Password reset link is sent to your email inbox.')
      } catch (err) {
        this.showAlert('error', err.response?.data?.meta?.error_message || 'Something went wrong! Please try again later.')
      } finally {
        this.reset.loader = false
      }
    },
    showAlert(type, message) {
      this.alert = { show: true, type, message }
    }
  }
}
</script>
