<template>
  <v-dialog ref="previewTemplateModal" class="form-template-preview" rounded="sm" opacity="0.6"
    v-model="mixin_use.dialog" @click.outside="closeDialog">
    <v-card class="mx-auto" width="800">
      <template v-slot:append>
        <v-btn variant="elevated" rounded="md" class="text-none font-weight-bold use-template-btn"
          :loading="mixin_use.loading" @click="useTemplateForm()">
          Use Template
        </v-btn>
      </template>

      <template v-slot:title>
        <h3 class="font-weight-bold text-no-wrap mb-0">{{ truncatedTitle(preview.title) }}</h3>
      </template>

      <template v-slot:subtitle>
        <v-chip class="font-weight-bold" color="primary-red" density="compact" variant="flat" rounded="md" label>
          <v-icon icon="mdi-label" start></v-icon>
          <span class="font-weight-bold text-wrap mt-1">{{ preview.category }}</span>
        </v-chip>
      </template>

      <v-divider :thickness="7" color="#edf4f9" opacity="1" class="mx-5"></v-divider>

      <!-- Centered Close Button -->
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn color="#edf4f9" rounded="sm" size="small" elevation="0" class="text-none font-weight-bold"
            @click="closeDialog">
            Close Form Preview
          </v-btn>
        </v-col>
      </v-row>

      <v-card-text class="my-1">
        <v-row v-if="mixin_use.error">
          <v-col cols="12">
            <v-alert class="w-100" type="warning" density="compact" variant="tonal" rounded="sm" border>
              {{ mixin_use.message }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="my-1 text-center" v-if="view.loader">
          <v-col cols="12">
            <v-progress-circular color="red" indeterminate :width="3" :size="50"></v-progress-circular>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="iframe-outer-container">
            <div ref="iframeContainer" class="iframe-inner-container">
              <iframe :src="preview.url" width="650"></iframe>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import formBuilderTabs from '@/shared/constants/formBuilderTabs.js'
import variantTypes from '@/store/variant-types'
import * as actionTypes from '@/store/action-types'
import templateMixin from '@/mixins/templates'

export default {
  mixins: [templateMixin],
  props: ['preview'],
  data() {
    return {
      view: {
        loader: true
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.view.loader = false
    }, 1000)
  },
  methods: {
    closeDialog() {
      this.resetUse()
    },
    truncatedTitle(text) {
      const maxLength = 60
      return text.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text
    },
    useTemplateForm: function () {
      this.mixin_use.loading = true
      let formId = this.preview.formId
      if (!formId) {
        this.mixin_use.loading = false
        this.mixin_use.error = true
        this.mixin_use.message = 'Unable to use this template!'
        return
      }
      this.$store
        .dispatch('formtemplatebrowser/useFormTemplate', {
          formId: this.preview.formId,
          formTitle: this.preview.title
        })
        .then(
          (response) => {
            this.mixin_use.error = false
            this.fetchFormVariants(response.data.data.id)
          },
          (response) => {
            this.$store.dispatch('formtemplatebrowser/useFormTemplateError', null)
            this.mixin_use.loading = false
            this.mixin_use.error = true
            this.mixin_use.message = 'Something went wrong! Unable to use this template!'
          }
        )
    },
    fetchFormVariants: function (createdFormId) {
      this.$store
        .dispatch(actionTypes.FETCH_FORM_VARIANTS, {
          formId: createdFormId
        })
        .then(
          (response) => {
            this.$store.dispatch(
              actionTypes.FETCH_FORM_VARIANTS_SUCCESS,
              response.data.data
            )
            let variantData = this.getChampion(response.data.data)
            if (variantData) {
              this.closeDialog()
              this.$router.push({
                name: 'forms.variants.edit',
                params: {
                  id: createdFormId,
                  variantId: variantData.id,
                  tab: formBuilderTabs.BUILD
                }
              })
            }
          },
          (response) => {
            this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, {
              formId: createdFormId
            })
          }
        )
    },
    getChampion: function (data) {
      for (let v of data) {
        if (v.form_variant_type.type === variantTypes.CHAMPION) {
          return v
        }
      }
      return {}
    }
  }
}
</script>
