import * as SubscriptionApi from '@/api/subscription'

const state = {}

const getters = {}

const actions = {
  pauseSubscription ({commit}, data) {
    return SubscriptionApi.pauseSubscription(data.id)
  },

  resumeSubscription ({commit}, data) {
    return SubscriptionApi.resumeSubscription(data.id)
  },

  cancelSubscription ({commit}, data) {
    return SubscriptionApi.cancelSubscription(data.id)
  },

  updateSubscription ({commit}, data) {
    return SubscriptionApi.updateSubscription(data.id, data.params)
  },

  getPastDueSubscription ({commit}, data) {
    return SubscriptionApi.getPastDueSubscription(data.id)
  },

  listPayment ({commit}, data) {
    return SubscriptionApi.listPayment(data.id)
  },

  getDiscountCoupon ({commit}, data) {
    return SubscriptionApi.listDiscountCoupon()
  },

  listTransaction ({commit}, data) {
    return SubscriptionApi.listTransaction(data.id)
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
