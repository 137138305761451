<template>
  <v-col cols="12">
    <h5 class="font-weight-bold text-primary-blue">See All Features</h5>
    <v-table density="comfortable" rounded="md" class="border-thin rounded">
      <thead class="bg-blue-lighten-5">
        <tr>
          <th class="text-left font-weight-bold text-primary-blue w-33">Plan</th>
          <th class="text-center font-weight-bold text-primary-blue" v-for="plan in paddleMonthlyPlans"
            :key="plan.title">
            {{ plan.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-grey-darken-4 w-33">Number of forms</td>
          <td class="text-center text-grey-darken-3" v-for="plan in paddleMonthlyPlans" :key="plan.title">
            {{ formatNumber(plan.forms) }}
          </td>
        </tr>
        <tr>
          <td class="text-grey-darken-4 w-33">Lead submissions/month</td>
          <td class="text-center text-grey-darken-3" v-for="plan in paddleMonthlyPlans" :key="plan.title">
            {{ formatNumber(plan.leads) }}
          </td>
        </tr>
        <tr>
          <td class="text-grey-darken-4 w-33">Partial leads/month</td>
          <td class="text-center text-grey-darken-3" v-for="plan in paddleMonthlyPlans" :key="plan.title">
            {{ formatNumber(plan.partial_leads) }}
          </td>
        </tr>
        <tr>
          <td class="text-grey-darken-4 w-33">Views/month</td>
          <td class="text-center text-grey-darken-3" v-for="plan in paddleMonthlyPlans" :key="plan.title">
            {{ formatNumber(plan.views) }}
          </td>
        </tr>
        <tr>
          <td class="text-grey-darken-4 w-33">Questions per form</td>
          <td class="text-center text-grey-darken-3" v-for="plan in paddleMonthlyPlans" :key="plan.title">
            Unlimited
          </td>
        </tr>
        <tr v-for="(feature, index) in allFeatures" :key="index">
          <td class="text-grey-darken-4 w-33">{{ getFeatureLabel(feature) }}</td>
          <td class="text-center text-grey-darken-3" v-for="plan in paddleMonthlyPlans" :key="plan.title">
            <template v-if="isMultipleFormEndings(feature)">
              <template v-if="getMultipleFormEndingsValue(plan, feature) === '✗'">
                <span class="text-grey">✗</span>
              </template>
              <template v-else>
                <div v-html="getMultipleFormEndingsValue(plan, feature)"></div>
              </template>
            </template>
            <template v-else>
              <span v-if="isPlanFeature(plan, feature)" class="text-primary-green">✔</span>
              <span v-else class="text-grey">✗</span>
            </template>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-col>
</template>

<script>
import { paddleMonthlyPlans, planFeatures } from '@/shared/constants/plan/paddle/plans'

export default {
  data() {
    return {
      paddleMonthlyPlans,
      planFeatures
    }
  },
  computed: {
    allFeatures() {
      const featuresSet = new Set()
      Object.values(this.planFeatures).forEach(featureList => {
        featureList.forEach(feature => {
          if (Array.isArray(feature)) {
            featuresSet.add('Multiple Form Endings')
          } else {
            featuresSet.add(feature)
          }
        })
      })
      return Array.from(featuresSet)
    }
  },
  methods: {
    isPlanFeature(plan, feature) {
      const planFeatureList = this.planFeatures[`features${plan.title}`]
      if (feature === 'Multiple Form Endings') {
        return planFeatureList.some(f => Array.isArray(f) && f[0] === 'Multiple Form Endings')
      } else {
        return planFeatureList.includes(feature)
      }
    },
    isMultipleFormEndings(feature) {
      return feature === 'Multiple Form Endings'
    },
    getFeatureLabel(feature) {
      return feature === 'Multiple Form Endings' ? 'Multiple form endings' : feature
    },
    getMultipleFormEndingsValue(plan, feature) {
      const planFeatureList = this.planFeatures[`features${plan.title}`]
      const multipleFormEndings = planFeatureList.find(f => Array.isArray(f) && f[0] === 'Multiple Form Endings')

      if (!multipleFormEndings) return '✗'

      let badgeHtml = ''

      if (multipleFormEndings.includes('Answer-Based')) {
        badgeHtml += '<span class="lg-badge pt-1 ml-1 mt-2 bg-secondary-blue">Answer Based</span><br>'
      }

      if (multipleFormEndings.includes('Visitor-Based')) {
        badgeHtml += '<span class="lg-badge pt-1 ml-1 mt-1 mb-2 bg-tertiary-blue">Visitor Based</span>'
      }

      return badgeHtml || '✗'
    },
    formatNumber(num) {
      return num.toLocaleString()
    }
  }
}
</script>
