import { getData, postData } from '../plugins/ApiCalls'

export function fetchProviderRedirectUrl(provider) {
  return getData(provider + '/auth/redirect')
}

export function registerProviderAccount(provider, data) {
  return postData(provider + '/auth/register', data)
}

export function loginProviderAccount(provider, data) {
  return postData(provider + '/auth/login', data)
}
