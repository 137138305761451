import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function saveFormTemplateTheme(data) {
  return postData('form-theme-templates', data)
}

export function updateTemplate(data) {
  return putData(`form-theme-templates/${data.id}`, data)
}

export function getFormTemplateThemes() {
  return getData('form-theme-templates')
}

export function publishTemplate(data) {
  return putData(`form-theme-templates/publish/${data.id}`, data)
}

export function deactivateDefaultTemplate(id) {
  return putData(`form-theme-templates/deactivate/${id}`)
}

export function deleteTemplate(id) {
  return deleteData(`form-theme-templates/${id}`)
}
