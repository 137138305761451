import { getData, postData, putData } from '../plugins/ApiCalls'

export function getAvailableFeatures() {
  return getData('package-builder/features')
}

export function getFeatureProperties() {
  return getData('package-builder/properties')
}

export function getPackageLists(data) {
  return getData('package-builder/list?listParams=' + encodeURIComponent(JSON.stringify(data)))
}

export function getPackageData(id) {
  return getData('package-builder/' + id)
}

export function savePackageData(data) {
  return postData('package-builder', data)
}

export function updatePackageData(id, data) {
  return putData('package-builder/' + id, data)
}
