<template>
  <div class="form-connections-list-view">
    <div class="d-flex justify-space-between mt-5">
      <h5><b>Integrations</b></h5>
      <router-link :to="{ name: 'forms.detail.connectionstab.create', params: { id: $route.params.id } }">
        <v-btn class="text-none font-weight-bold" prepend-icon="mdi-plus" color="primary-green">Add Integration</v-btn>
      </router-link>
    </div>
    <div class="form-connections-list-view__body">
      <div v-if="integrationLoaded">
        <div class="empty-integration-text" v-if="!integration">
          <v-empty-state class="text-capitalize" :image="require('@/assets/images/no-integration.svg')">
            <template v-slot:title>
              <h5 class="text-capitalize text-grey-darken-5 mt-2 mb-0">You haven't added any integrations for this form
                yet.
              </h5>
            </template>
            <template v-slot:text>
              <p class="text-capitalize text-grey-darken-3 mt-0">Click on 'Add Integration' to start with your first
                integration.</p>
            </template>
            <template v-slot:headline>
              <h2 class="text-capitalize text-primary-blue mt-2">No Integrations Yet</h2>
            </template>
          </v-empty-state>
        </div>
      </div>
      <div class="text-center">
        <v-progress-circular v-if="!integrationLoaded" model-value="50" :size="60" :width="4" indeterminate
          color="secondary-red"></v-progress-circular>
      </div>
      <v-list v-if="integration" class="rounded mt-4" lines="two">
        <v-list-item :class="{ 'border-b-thin': index !== integration.length - 1 }"
          v-for="(connection, index) in integration" :key="index" :subtitle="connection.data.method"
          :title="connection.data.title">
          <template v-slot:title>
            <h6>{{ connection.data.title }}</h6>
          </template>
          <template v-slot:subtitle>
            <span v-if="connection.type === formConnectionTypes.WEBHOOK">Method : {{ connection.data.method }}</span> |
            <span v-if="connection.type !== formConnectionTypes.TRUSTEDFORM">Status : {{ connection.data.enable ?
              'ENABLED' : 'DISABLED' }}</span>
            <span v-else>Status : {{ connection.data.active ? 'ENABLED' : 'DISABLED' }}</span>
          </template>
          <template v-slot:prepend>
            <v-avatar color="grey-lighten-3">
              <v-icon v-if="connection.type === formConnectionTypes.WEBHOOK" color="primary-red"
                size="large">mdi-webhook</v-icon>
              <v-icon v-if="connection.type === formConnectionTypes.CONTACTSTATE" color="primary-red"
                size="large">mdi-alpha-c-circle-outline</v-icon>
              <v-icon v-if="connection.type === formConnectionTypes.TRUSTEDFORM" color="primary-red"
                size="large">mdi-alpha-t-circle-outline</v-icon>
            </v-avatar>
          </template>

          <template v-slot:append>
            <router-link
              v-if="connection.type === formConnectionTypes.CONTACTSTATE || connection.type === formConnectionTypes.WEBHOOK"
              :to="{ name: 'forms.detail.connectionstab.edit', params: { id: connection.form_id, connectionId: connection.data.id, webhookId: connection.data.id, connectionType: connection.type } }">
              <v-btn color="primary-red" icon="mdi-pencil" variant="text"></v-btn>
            </router-link>
            <router-link v-if="connection.type === formConnectionTypes.TRUSTEDFORM"
              :to="{ name: 'forms.detail.connectionstab.create', params: { id: $route.params.id, connectionType: formConnectionTypes.TRUSTEDFORM } }">
              <v-btn color="primary-red" icon="mdi-pencil" variant="text"></v-btn>
            </router-link>
            <v-btn @click="openDeleteConnectionModal(connection)"
              v-if="connection.type !== formConnectionTypes.TRUSTEDFORM" color="primary-red" icon="mdi-delete"
              variant="text"></v-btn>
          </template>
        </v-list-item>
      </v-list>

      <!-- Delete Webhook Modal -->
      <v-dialog v-model="deleteFlag.webHookModal" max-width="600">
        <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
          <template v-slot:title>
            <strong>Are you sure?</strong>
          </template>
          <v-card-text>
            <v-alert v-if="deleteFlag.deleted && deleteFlag.deletedError" border type="warning" variant="tonal"
              density="compact">
              <p class="text-black ma-0">Unable to delete form webhook integration.</p>
            </v-alert>
            <v-spacer class="my-4"></v-spacer>
            <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
              <p class="text-black ma-0">You are about to delete webhook integration. Are you sure you
                want to proceed?</p>
            </v-alert>
          </v-card-text>
          <v-card-actions class="mb-3 mr-4">
            <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
              @click="deleteFlag.webHookModal = false">
            </v-btn>
            <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
              :loading="deleting" @click="deleteWebHook">
              <span>Delete</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete ContactState Modal -->
      <v-dialog v-model="deleteFlag.contactStateModal" max-width="600">
        <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
          <template v-slot:title>
            <strong>Are you sure?</strong>
          </template>
          <v-card-text>
            <v-alert v-if="deleteFlag.deleted && deleteFlag.deletedError" border type="warning" variant="tonal"
              density="compact">
              <p class="text-black ma-0">Unable to delete form contact state integration.</p>
            </v-alert>
            <v-spacer class="my-4"></v-spacer>
            <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
              <p class="text-black ma-0">You are about to delete contact state integration. Are you sure you
                want to proceed?</p>
            </v-alert>
          </v-card-text>
          <v-card-actions class="mb-3 mr-4">
            <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
              @click="deleteFlag.contactStateModal = false">
            </v-btn>
            <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
              :loading="deleting" @click="deleteContactState">
              <span>Delete</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import { formConnectionTypes } from '@/shared/constants/formConnectionTypes'
export default {
  data: function () {
    return {
      deleteFlag: {
        webHookModal: false,
        contactStateModal: false,
        deleted: false,
        deleting: false,
        deletedError: false,
      },
      submitting: false,
      submitErrorMessage: '',
      submitSuccess: false,
      selectedConnectionId: -1,
      selectedForm: -1,
      integration: [],
      integrationLoaded: false,
      form_variant_id: ''
    }
  },
  mounted: function () {
    this.fetchWebhooks()
    this.fetchFormVariants()
    this.fetchConnection()
  },
  methods: {
    fetchConnection: function () {
      for (let v of this.formVariants) {
        this.form_variant_id = v.id
      }
      this.$store.dispatch('formconnection/fetchFormConnection', {
        formId: this.$route.params.id,
        variantId: this.form_variant_id
      })
        .then((response) => {
          this.integration = response.data.data
          this.integrationLoaded = true
          this.$store.dispatch('formconnection/fetchFormConnectionSuccess', response.data.data)
        })
    },
    goToView: function (view) {
      if (view === 'create') {
        this.$router.push({ name: 'forms.connections.type', params: { id: this.$route.params.id } })
      }
    },
    fetchWebhooks: function () {
      this.$store.dispatch('formwebhook/fetchFormWebhooks', { formId: this.$route.params.id })
        .then((response) => {
          this.$store.dispatch('formwebhook/fetchFormWebhooksSuccess', response.data.data || [])
        })
    },
    openModal: function (ref) {
      this.$refs[ref].open()
    },
    closeModal: function (ref) {
      this.$refs[ref].close()
    },
    showWebhookModal: function () {
      if (!this.updateMode) {
        this.webhookForm = {
          title: '',
          enable: true,
          method: 'POST',
          format: 'application/x-www-form-urlencoded',
          url: '',
          form_variant_id: ''
        }
      }
      this.openModal('webhookModal')
    },
    fetchFormVariants: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS, {
        formId: this.$route.params.id
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response.data.data)
      }, (response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, {
          formId: this.$route.params.id
        })
      })
    },
    openDeleteConnectionModal: function (connection) {
      this.selectedConnectionId = connection.data.id
      this.selectedForm = connection.form_id
      this.deleteFlag.deleted = false
      if (connection.type === 'webhook') {
        this.deleteFlag.webHookModal = true
      } else if (connection.type === 'contactstate') {
        this.deleteFlag.contactStateModal = true
      }
    },
    deleteWebHook: function () {
      this.deleteFlag.deleted = false
      this.deleteFlag.deleting = true
      this.$store.dispatch('formwebhook/deleteFormWebhook', {
        id: this.selectedConnectionId,
        formId: this.selectedForm
      }).then((response) => {
        this.deleteFlag.deleted = true
        this.deleteFlag.deleting = false
        this.deleteFlag.webHookModal = false
        this.fetchConnection()
        this.fetchWebhooks()
        this.fetchFormVariants()
      }, (response) => {
        this.deleteFlag.deletedError = true
        this.deleteFlag.deleted = true
        this.deleteFlag.deleting = false
      })
    },
    deleteContactState: function () {
      this.deleteFlag.deleted = false
      this.deleteFlag.deleting = true
      this.$store.dispatch('formcontactstate/deleteContactState', {
        id: this.selectedConnectionId
      }).then((response) => {
        this.deleteFlag.deleted = true
        this.deleteFlag.deleting = false
        this.deleteFlag.contactStateModal = false
        this.fetchConnection()
        this.$store.dispatch('formcontactstate/deleteContactStateSuccess', {
          id: this.selectedConnectionId
        })
      }, (response) => {
        this.deleteFlag.deletedError = true
        this.deleteFlag.deleted = true
        this.deleteFlag.deleting = false
      })
    }
  },
  computed: {
    formConnections: function () {
      return this.$store.getters['formconnection/getFormConnections'] || []
    },
    formVariants: function () {
      return this.$store.getters.getFormVariants(parseInt(this.$route.params.id))
    },
    formConnectionTypes: function () {
      return formConnectionTypes
    }
  }
}
</script>
