<template>
  <v-container style="width:80%;" class="utm-list-view">
    <h4 class="page-title">UTM Parameters</h4>
    <v-row>
      <v-col class="text-end mb-n10">
        <v-btn color="primary-red font-weight-bold text-none" @click="$router.push({ name: 'utmparams.create' })"
          prepend-icon="mdi-plus"> Create Script</v-btn>
      </v-col>
    </v-row>
    <div>
      <general-list :fields="fields" :loadedError="flag.error" :loaded="!flag.loader" :listData="listData"
        :listPagination="listPagination" @onListParamsChange="loadUtmScripts($event)" ref="generalList">
        <template v-slot:Title="props">
          <router-link :to="{ name: 'utmparams.edit', params: { id: props.rowData.id } }" class="secondary-red">
            {{ props.rowData.title }}
          </router-link>
        </template>
        <template v-slot:Form="props">
          {{ props.rowData.form.title }}
        </template>
        <template v-slot:Variant="props">
          {{ props.rowData.form_variant.title }}
        </template>
        <template v-slot:Choice="props">
          {{ props.rowData.choice }}
        </template>
        <template v-slot:Action="props">
          <v-icon color="primary-red" @click="openDeleteUtmScriptModal(props.rowData.id)">
            mdi-delete
          </v-icon>
        </template>
      </general-list>
    </div>

    <!-- Delete UTM Script modal -->
    <v-dialog v-model="flag.isModalOpen" max-width="600px">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert v-if="flag.error" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">Unable to delete UTM parameter. Please try again.</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to delete this parameter. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-3">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="closeDeleteModal">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
            :loading="flag.loader" @click="deleteUtmScript">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GeneralList from '@/components/lists/GeneralList'
import { mapGetters } from 'vuex'
import { resourceTypes } from '@/shared/constants/generalList/list'

export default {
  components: {
    'general-list': GeneralList
  },
  data: function () {
    return {
      flag: {
        loader: false,
        error: false,
        isModalOpen: false
      },
      selectedUtmScript: -1,
      listData: [],
      listPagination: {},
      fields: [
        {
          name: 'Title',
          sortField: 'title',
          searchField: 'title'
        },
        {
          name: 'Form'
        },
        {
          name: 'Variant'
        },
        {
          name: 'Choice'
        },
        {
          name: 'Action'
        }
      ]
    }
  },
  mounted() {
    this.loadUtmScripts()
  },
  methods: {
    loadUtmScripts(params) {
      if (!params) return;
      params.resource = resourceTypes.UTM_SCRIPT;

      this.flag.loader = true;
      this.flag.error = false;

      this.$store.dispatch('utmparamslists/getScriptsList', params)
        .then((response) => {
          this.listData = response.data.data;
          this.listPagination = response.data.pagination;
          this.flag.loader = false;
        })
        .catch(() => {
          this.flag.loader = false;
          this.flag.error = true;
        });
    },
    openDeleteUtmScriptModal(id) {
      this.selectedUtmScript = id;
      this.flag.error = false;
      this.flag.isModalOpen = true;
    },
    closeDeleteModal() {
      this.flag.isModalOpen = false;
      this.selectedUtmScript = -1;
      this.flag.error = false;
    },
    deleteUtmScript() {
      this.flag.loader = true;
      this.flag.error = false;

      this.$store.dispatch('utmparamslists/deleteScript', this.selectedUtmScript)
        .then(() => {
          this.flag.loader = false;
          this.flag.isModalOpen = false;
          this.$refs.generalList.reload();
        })
        .catch(() => {
          this.flag.loader = false;
          this.flag.error = true;
        });
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  }
}
</script>
