<template>
  <v-container class="form-browse-template-view">
    <!-- HEADER -->
    <v-row>
      <div class="form-browse-template-view__header">
        <!-- LOGO -->
        <div class="form-browse-template-view__header__logo">
          <router-link :to="{ name: 'dashboard' }">
            <img src="./../assets/images/icons/new-logo.svg" alt="LeadGen" />
          </router-link>
        </div>
        <!-- ACTION -->
        <div class="form-browse-template-view__header__actions">
          <user-account-action :userName="userName"></user-account-action>
        </div>
      </div>
    </v-row>
    <!-- PAGE BODY LOADER & ERROR-->
    <v-row v-if="!mixin_load.page">
      <v-col cols="12">
        <!-- TEMPLATE BROWSER LOADER-->
        <div class="form-browse-template-view__loading" v-if="mixin_load.loader">
          <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
            color="secondary-red"></v-progress-circular>
        </div>
        <!-- TEMPLATE BROWSER LOAD ERROR-->
        <div class="form-browse-template-view__error" v-else>
          <h3>Whoops, looks like something went wrong. Please click the button to try again.</h3>
          <v-btn variant="elevated" color="primary-red" class="text-none font-weight-bold" rounded="md" size="large"
            prepend-icon="mdi-reload" @click="reloadPage()">
            Reload
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- PAGE BODY-->
    <v-row v-else>
      <!-- Sidebar for larger screens, full width on mobile -->
      <v-col cols="12" md="3" class="mt-16">
        <TemplateSideBar @filterChange="filterChange" :isActive="isActive" />
      </v-col>
      <!-- Main content, full width on mobile -->
      <v-col cols="12" md="9">
        <!-- TEMPLATE TOP BAR -->
        <v-row class="mt-16 page-heading">
          <!-- PAGE HEADING -->
          <v-col cols="12" class="d-flex flex-wrap flex-column text-center mt-5">
            <h4 class="font-weight-bold">Form Templates</h4>
            <p>High-Converting Form Designs for Your Website, Ready to Use and Editable to Your Needs.</p>
          </v-col>
          <!-- TOP BAR -->
          <v-col cols="12">
            <TemplateTopBar @filterChange="filterChange" />
          </v-col>
        </v-row>
        <!-- TEMPLATE BROWSE -->
        <v-row>
          <v-col cols="12">
            <TemplateBrowse :filteredTemplates="mixin_filteredTemplates" :activeFilterText="activeFilterText"
              @filters-updated="updateActiveFilterText" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userMixin from '@/mixins/user'
import templateMixin from '@/mixins/templates'
import UserAccountAction from '@/components/UserAccountAction'
import TemplateSideBar from '@/components/FormTemplateBrowser/TemplateSideBar'
import TemplateTopBar from '@/components/FormTemplateBrowser/TemplateTopBar'
import TemplateBrowse from '@/components/FormTemplateBrowser/TemplateBrowse'

export default {
  mixins: [userMixin, templateMixin],
  components: {
    UserAccountAction,
    TemplateTopBar,
    TemplateSideBar,
    TemplateBrowse
  },
  data() {
    return {
      userName: null,
      activeFilterText: null
    }
  },
  mounted() {
    this.userName = this.user.name
    if (this.$route.params.industry) {
      setTimeout(() => {
        const industryID = this.getIndustryIDFromSlug(this.$route.params.industry)
        if (industryID) {
          this.filterChange(this.mixin_templateKeywords.INDUSTRY, industryID)
        } else {
          this.filterChange()
        }
      }, 200)
    }
  },
  methods: {
    reloadPage: function () {
      window.location.reload()
    },
    getIndustryIDFromSlug: function (slug) {
      const industry = this.formTemplateIndustries.find(
        (ind) => ind.slug.toLowerCase() === slug.toLowerCase(),
      )

      if (industry) {
        return industry.id
      } else {
        return null
      }
    },
    filterChange: function (type, data) {
      this.resetFilters()
      this.resetPaginate()

      if (type === this.mixin_templateKeywords.CATEGORY ||
        type === this.mixin_templateKeywords.INDUSTRY ||
        type === this.mixin_templateKeywords.STEP) {
        this.mixin_selectedFilter[type] = data
      } else if (type === this.mixin_templateKeywords.KEYWORD && data) {
        this.mixin_selectedFilter[this.mixin_templateKeywords.KEYWORD] = data
      } else {
        this.mixin_selectedFilter[this.mixin_templateKeywords.KEYWORD] = data
      }
      this.activeFilterText = this.calculateActiveFilterText(type, data)
    },
    calculateActiveFilterText(type, data) {
      if (type === this.mixin_templateKeywords.CATEGORY) {
        return this.formTemplateCategories.find(
          category => category.id === data
        ).title
      } else if (type === this.mixin_templateKeywords.INDUSTRY) {
        return this.formTemplateIndustries.find(
          industry => industry.id === data
        ).title
      } else {
        return null
      }
    },
    updateActiveFilterText(newActiveFilterText) {
      this.activeFilterText = newActiveFilterText
    },
    isActive: function (type, data) {
      const filterValue =
        type === this.mixin_templateKeywords.CATEGORY
          ? this.mixin_selectedFilter.category
          : type === this.mixin_templateKeywords.INDUSTRY
            ? this.mixin_selectedFilter.industry
            : this.mixin_selectedFilter.step

      return filterValue === data
    }
  }
}
</script>
