<template>
  <div class="media-browser">
    <div class="media-browser__content">
      <div class="media-browser__items">
        <div :class="{ 'media-browser__item': true, selected: isSelected(item.id) }" v-for="item in items"
          :key="item.id" @click="select(item)">
          <img :src="thumbnail('small', item).url" :title="item.name" :alt="item.name" />
          <i class="material-icons media-browser__item__delete" title="Delete" @click="deleteMedia(item)">delete</i>
        </div>
      </div>
      <div class="media-browser__loadmore" v-if="!isLastPage">
        <v-progress-circular indeterminate color="error" :size="30" :width="3" v-if="loading"></v-progress-circular>
        <v-btn color="primary-red" class="text-none text-capitalize font-weight-bold" variant="outlined" rounded="md"
          @click="loadMedia" v-else>Load More</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  mounted: function () {
    if (this.pagination.current_page && this.pagination.current_page > 1) {
      return
    }

    this.$store.dispatch('media/loadingMedia', {
      mediaType: this.type,
      loading: true
    }).then(() => {
      this.fetchMedia(1)
    })
  },
  methods: {
    fetchMedia: function (page) {
      this.$store.dispatch('media/fetchMedia', {
        mediaType: this.type,
        page: page
      }).then((response) => {
        this.$store.dispatch('media/loadingMedia', {
          mediaType: this.type,
          loading: false
        })

        this.$store.dispatch('media/fetchMediaSuccess', {
          mediaType: this.type,
          images: { images: response.data.data, pagination: response.data.pagination }
        })
      }).catch(() => {
        this.$store.dispatch('media/loadingMedia', {
          mediaType: this.type,
          loading: false
        })

        this.$store.dispatch('media/fetchMediaError', {
          mediaType: this.type
        })
      })
    },
    select: function (item) {
      this.$store.dispatch('media/selectMedia', {
        mediaType: this.type,
        selected: [item] || []
      })
    },
    isSelected: function (id) {
      return _.find(this.selected, { id: id }) !== undefined
    },
    thumbnail: function (size, item) {
      let thumb = _.find(item.thumbnails, { size: size })

      if (thumb) {
        return thumb
      }

      return item
    },
    deleteMedia: function (item) {
      let type = item.type
      this.$store.dispatch('media/deleteMedia', {
        id: item.id
      }).then(() => {
        this.$emit('delete', type)
      })
    },
    loadMedia: function () {
      this.$store.dispatch('media/loadingMedia', {
        mediaType: this.type,
        loading: true
      }).then(() => {
        this.fetchMedia(this.pagination.current_page + 1)
      })
    }
  },
  computed: {
    mediaTypes: function () {
      return this.$store.getters['media/types']
    },
    media: function () {
      if (this.type === this.mediaTypes.IMAGE) {
        return this.$store.getters['media/images']
      }

      return {}
    },
    items: function () {
      if (this.type === this.mediaTypes.IMAGE) {
        return this.media.data
      }

      return []
    },
    selected: function () {
      return this.$store.getters['media/selected']
    },
    loading: function () {
      if (this.type === this.mediaTypes.IMAGE) {
        return this.$store.getters['media/loading'].images
      }

      return false
    },
    pagination: function () {
      return this.media.pagination
    },
    isLastPage: function () {
      if (!this.media.pagination.current_page) {
        return false
      }

      return this.pagination.current_page === this.pagination.last_page
    }
  }
}
</script>
