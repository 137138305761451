import * as mutationTypes from '@/store/mutation-types'
import * as packageBuilderApi from '@/api/package-builder'

const state = {
  availableFeatures: [],
  featureProperties: [],
  packageLists: [],
  packageData: []
}

const getters = {
  getAvailableFeatures: function (state) {
    return state.availableFeatures
  },
  getFeatureProperties: function (state) {
    return state.featureProperties
  },
  getPackageLists: function (state) {
    return state.packageLists
  },
  getPackageData: function (state) {
    return state.packageData
  }
}

const actions = {
  getAvailableFeatures ({state, commit}, data) {
    commit(mutationTypes.FETCH_AVAILABLE_FEATURES)
    return packageBuilderApi.getAvailableFeatures()
  },
  getAvailableFeaturesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_AVAILABLE_FEATURES_SUCCESS, data)
  },

  getFeatureProperties ({state, commit}, data) {
    commit(mutationTypes.FETCH_FEATURE_PROPERTIES)
    return packageBuilderApi.getFeatureProperties()
  },
  getFeaturePropertiesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FEATURE_PROPERTIES_SUCCESS, data)
  },

  getPackageLists ({state, commit}, data) {
    commit(mutationTypes.FETCH_PACKAGE_LISTS)
    return packageBuilderApi.getPackageLists(data)
  },
  getPackageListsSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_PACKAGE_LISTS_SUCCESS, data)
  },

  getPackageData ({state, commit}, data) {
    commit(mutationTypes.FETCH_PACKAGE_LISTS)
    return packageBuilderApi.getPackageData(data.id)
  },
  getPackageDataSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_PACKAGE_LISTS_SUCCESS, data)
  },

  savePackageData ({state, commit}, data) {
    commit(mutationTypes.SAVE_PACKAGE_DATA)
    return packageBuilderApi.savePackageData(data)
  },

  updatePackageData ({state, commit}, data) {
    commit(mutationTypes.UPDATE_PACKAGE_DATA)
    return packageBuilderApi.updatePackageData(data.id, data.data)
  }

}

const mutations = {
  [mutationTypes.FETCH_AVAILABLE_FEATURES] (state) {
  },
  [mutationTypes.FETCH_AVAILABLE_FEATURES_SUCCESS] (state, data) {
    state.availableFeatures = data || []
  },

  [mutationTypes.FETCH_FEATURE_PROPERTIES] (state) {
  },
  [mutationTypes.FETCH_FEATURE_PROPERTIES_SUCCESS] (state, data) {
    state.featureProperties = data || []
  },

  [mutationTypes.FETCH_PACKAGE_LISTS] (state, data) {},
  [mutationTypes.FETCH_PACKAGE_LISTS_SUCCESS] (state, data) {
    state.packageLists = data || []
  },

  [mutationTypes.FETCH_PACKAGE_DATA] (state, data) {
    state.packageData = data || []
  },

  [mutationTypes.SAVE_PACKAGE_DATA] (state, data) {
    state.packageData = data || []
  },

  [mutationTypes.UPDATE_PACKAGE_DATA] (state, data) {
    state.packageData = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
