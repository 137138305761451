<template>
  <v-container class="w-75">
    <v-form ref="mediaUploaderForm">
      <v-row justify="center" v-if="uploading.loader">
        <v-col cols="12">
          <div class="text-center">
            <v-progress-circular :model-value="uploading.percent" :rotate="360" :size="60" :width="5"
              color="secondary-red">
              <template v-slot:default> {{ uploading.percent }} % </template>
            </v-progress-circular>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="media-uploader mt-2" id="media-uploader-drag" v-else>
        <v-col cols="12">
          <div class="text-center pa-5 border-thin border-dashed border-black-border rounded-sm cursor-grabbing py-16"
            id="media-uploader-drop" @dragenter.prevent="handleDragEnter" @dragover.prevent="handleDragOver"
            @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop">
            <v-icon size="48" color="grey">mdi-cloud-upload</v-icon>
            <p>Drag and drop your Image here</p>
            <p>or</p>
            <v-file-input variant="outlined" density="comfortable" placeholder="Select an image up to 4MB"
              class="cursor-pointer" prepend-icon="" prepend-inner-icon="mdi-attachment" chips counter show-size :rules="$valid([
                { rule: 'required' },
                { rule: 'fileType', types: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'] },
                { rule: 'fileSize', max: '4' }
              ])" accept="image/*" name="upload-image" v-model="imageFiles" @update:modelValue="onMediaChange">
              <template v-slot:append-inner>
                <v-btn color="secondary-red" class="text-none text-capitalize font-weight-bold" rounded="md">
                  Browse Image
                </v-btn>
              </template>
            </v-file-input>
          </div>
          <span class="d-block mt-2 text-grey-darken-3 text-body-2 text-center">Maximum upload size: 4 MB.</span>
        </v-col>
        <v-col cols="12" class="my-2" v-if="uploading.error">
          <v-alert border type="error" variant="tonal" rounded="md" density="compact" icon="mdi-information">
            <p class="text-black text-left mb-0">{{ uploading.message }}</p>
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: function () {
    return {
      uploading: {
        loader: false,
        success: false,
        error: false,
        message: null,
        percent: 0
      },
      media: {},
      imageFiles: undefined
    }
  },
  methods: {
    onMediaChange: function (files) {
      if (files) {
        this.upload(files)
      }
    },
    handleDragEnter(event) {
      event.preventDefault()
      // Reset error state on new drag
      this.uploading.error = false
      this.uploading.message = null
    },
    handleDragOver(event) {
      event.preventDefault()
      event.dataTransfer.dropEffect = 'copy'
    },
    handleDragLeave(event) {
      event.preventDefault()
    },
    handleDrop(event) {
      event.preventDefault()

      // Reset states
      this.uploading.error = false
      this.uploading.message = null
      this.uploading.percent = 0

      const files = event.dataTransfer.files

      if (!files || files.length === 0) {
        this.uploading.error = true
        this.uploading.message = 'No files detected or file is empty.'
        return
      }

      const file = files[0]

      if (!this.isImage(file.type)) {
        this.uploading.error = true
        this.uploading.message = 'Only Images are allowed.'
        return
      }

      const fileSize = Math.round(file.size / 1024)
      if (fileSize > 4096) {
        this.uploading.error = true
        this.uploading.message = 'Sorry, maximum image size allowed is up to 4 MB'
        return
      }

      this.uploading.loader = true
      this.imageUpload(file)
    },
    isImage: function (type) {
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp']
      return validTypes.includes(type)
    },
    async upload(file) {
      this.uploading.error = false
      this.uploading.message = null
      this.uploading.percent = 0

      if (!file) {
        this.uploading.error = true
        this.uploading.message = 'Please select a valid image.'
        return
      }

      try {
        await this.$nextTick()
        const { valid } = await this.$refs.mediaUploaderForm.validate()

        if (!valid) {
          return
        }

        this.uploading.loader = true
        await this.imageUpload(file)

      } catch (error) {
        this.uploading.error = true
        this.uploading.message = error.message || 'An error occurred while processing the file.'
        this.uploading.loader = false
      }
    },
    imageUpload(file) {
      let formData = new FormData()
      formData.append('media_file', file, file.name)
      formData.append('public', 1)

      let progress = (event) => {
        this.uploading.percent = Math.round((event.loaded / event.total) * 100)
      }

      return this.$store.dispatch('media/uploadMedia', { formData: formData, progress: progress })
        .then((response) => {
          this.uploading.loader = false
          this.uploading.success = true
          this.media = response?.data?.data
          this.$emit('uploaded', this.media)
          return this.$store.dispatch('media/uploadMediaSuccess')
        })
        .catch((error) => {
          this.uploading.loader = false
          this.uploading.error = true
          if (error?.response?.status === 413) {
            this.uploading.message = 'Sorry file size is greater than 4MB'
          } else {
            this.uploading.message = error?.data?.meta?.error_message || 'Upload failed'
          }
          return this.$store.dispatch('media/uploadMediaError')
        })
    }
  }
}
</script>
