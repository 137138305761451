export default {
  methods: {
    formatMixin_changeCase: function (inputString) {
      if (!inputString) {
        return ''
      }
      const words = inputString.toLowerCase().split('_')
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toLowerCase() + words[i].slice(1)
      }
      return words.join(' ')
    }
  }
}
