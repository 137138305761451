import { postData, getData, putData, deleteData } from "../plugins/ApiCalls"

export function fetchBlacklistIps() {
  return getData('blacklist-ips')
}

export function createBlacklistIp(data) {
  return postData('blacklist-ips', data)
}

export function updateBlacklistIp(data) {
  return putData('blacklist-ips/' + data.id, data)
}

export function deleteBlacklistIp(id) {
  return deleteData('blacklist-ips/' + id)
}
