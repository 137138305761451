import * as onboardApi from '@/api/onboard'

const actions = {
  saveOnboardData (context, data) {
    return onboardApi.saveOnboardingData(data)
  },

  saveOnboardFlag (context, data) {
    onboardApi.saveOnboardingFlag(data)
  }
}

export default {
  namespaced: true,
  actions
}
