<template>
  <v-container class="form-settings-view" v-if="flag.pageLoader">
    <!-- FORM SETTINGS LOADER-->
    <div class="form-settings-view__loading" v-if="!flag.pageError">
      <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
        color="secondary-red"></v-progress-circular>
    </div>
    <!-- FORM SETTINGS LOAD ERROR-->
    <div class="form-settings-view__error" v-else>
      <h3>Whoops, looks like something went wrong. Please click the button to try again.</h3>
      <v-btn variant="elevated" color="primary-red" class="text-none font-weight-bold" rounded="md" size="large"
        prepend-icon="mdi-reload" @click="reloadPage()">
        Reload
      </v-btn>
    </div>
  </v-container>
  <v-container class="form-settings-view" v-else>
    <v-form ref="formSettings">
      <v-row class="align-center justify-space-between my-1">
        <v-col cols="12" md="6" class="text-left">
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn rounded="md" color="primary-red" class="text-none font-weight-bold" prepend-icon="mdi-update"
            @click="onFormSettingUpdate()" :loading="flag.loading">
            Update Settings
          </v-btn>
        </v-col>
      </v-row>

      <!-- partial lead -->
      <v-expansion-panels elevation="0" class="py-5 px-0" v-model="panel.partial" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-account-supervisor-circle-outline" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Partial Leads</p>
            </h6>
            <v-row no-gutters>
              <v-col class="d-flex justify-start" cols="4">
                <span class="lg-badge ml-4" :class="partial.enable ? 'bg-primary-green' : 'bg-secondary-red'">
                  {{ partial.enable ? 'Enabled' : 'Disabled' }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0 d-flex px-5">
            <v-row class="px-2 py-5 d-flex">
              <div style="width: 30%">
                <v-switch v-model="partial.enable" label="Partial Lead Tracking" color="primary-red" inset
                  density="compact" hide-details>
                </v-switch>
                <p class="partial-info" v-if="partial.enable">
                  Only available in multi-step forms<br />(Forms with at least 2 form
                  steps)
                </p>
                <a class="text-primary-red link" target="_blank" v-if="partial.enable"
                  href="https://leadgenapp.io/help/partial-lead-submissions/">How Partial Lead Tracking Works<v-icon
                    color="primary-red" :size="15" icon="mdi-open-in-new"></v-icon>
                </a>
              </div>
              <div style="width: 70%" v-if="partial.enable">
                <label class="d-flex justify-center font-weight-bold text-black mb-3 opt">Choose Opt-in
                  Method</label>
                <v-radio-group class="partial-consent-wrapper" inline v-model="partial.consentType">
                  <v-row>
                    <v-col cols="6" class="d-flex align-stretch">
                      <div :class="partial.consentType === 1 ? 'is-active' : ''"
                        class="content-card rounded-lg cursor-pointer d-flex flex-column">
                        <div class="content-card__body flex-grow-1 d-flex flex-column">
                          <div class="label-text-overview  d-flex flex-column justify-space-between">
                            <div class="d-flex align-center">
                              <v-radio :value="1" color="red" class="font-weight-bold"></v-radio>
                              <span><strong>Informed Consent</strong></span>
                            </div>

                            <p class="grey-darken-2 pt-0">
                              Once visitor clicks on the next button to reach form step 2, all provided responses will
                              be
                              stored. Notice about storing data will be shown at the end of the form step 1 and
                              consent
                              is
                              obtained by navigation button click to form step 2.
                            </p>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6" class="d-flex align-stretch">
                      <div :class="partial.consentType === 0 ? 'active' : ''"
                        class="content-card rounded-lg cursor-pointer d-flex flex-column">
                        <div class="content-card__body  d-flex flex-column">
                          <div class="label-text-overview flex-grow-1 d-flex flex-column justify-space-between">
                            <div class="d-flex align-center">
                              <v-radio :value="0" color="red" class="font-weight-bold"></v-radio>
                              <span><strong>Expressed Consent</strong></span>
                            </div>
                            <p class="grey-darken-2 pt-0">
                              Consent is obtained from active opt-in when form visitor ticks a checkbox, shown along
                              with
                              information text about data storing. The checkbox is shown at the bottom of form step 1
                              and
                              needs to be checked by the form visitor to progress through the form (Opt-in is
                              mandatory).
                            </p>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </div>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>


      <v-spacer class="my-1"></v-spacer>

      <!-- email notifications -->
      <v-expansion-panels elevation="0" class="py-5 px-0" v-model="panel.email" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-email" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Email Notifications</p>
            </h6>
            <v-row no-gutters>
              <v-col class="d-flex justify-start" cols="4">
                <span class="lg-badge ml-4" :class="emailNotify.enable ? 'bg-primary-green' : 'bg-secondary-red'">
                  {{ emailNotify.enable ? 'Enabled' : 'Disabled' }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0 px-5">
            <v-row class="py-5 px-0">
              <v-col cols="12" md="6">
                <v-switch label="Email notifications" color="primary-red" inset density="compact" hide-details
                  v-model="emailNotify.enable">
                </v-switch>
              </v-col>
              <v-col cols="12" md="6" v-if="emailNotify.enable">
                <v-switch label="Reply to header in email notifications" color="primary-red" inset density="compact"
                  hide-details v-model="emailNotify.replyTo">
                </v-switch>
              </v-col>
            </v-row>
            <v-row class="pa-0" v-if="emailNotify.enable">
              <v-col cols="12" md="6">
                <v-text-field variant="outlined" density="default" clearable prepend-inner-icon="mdi-text-short"
                  label="Subject" :rules="$valid([
                    { rule: 'required' },
                    { rule: 'between', min: 3, max: 50 },
                    { rule: 'alphaSpaces' }
                  ])" v-model="emailNotify.subject">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field variant="outlined" density="default" clearable prepend-inner-icon="mdi-inbox-arrow-up"
                  label="From Address Name" :rules="$valid([
                    { rule: 'required' },
                    { rule: 'between', min: 3, max: 50 },
                  ])" v-model="emailNotify.fromAddress">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <v-textarea variant="outlined" density="default" clearable rows="1" auto-grow
                  prepend-inner-icon="mdi-email-arrow-right" label="To" :rules="$valid([
                    { rule: 'required' },
                    { rule: 'email' },
                    { rule: 'commaSeparatedEmails', msg: 'Please enter valid comma-separated To email addresses or at least one valid email' }
                  ])" v-model="emailNotify.to">
                </v-textarea>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <v-textarea variant="outlined" density="default" clearable rows="1" auto-grow
                  prepend-inner-icon="mdi-email-plus" label="Cc" :rules="$valid([
                    { rule: 'email' },
                    { rule: 'commaSeparatedEmails', msg: 'Please enter valid comma-separated Cc email addresses or at least one valid email' }
                  ])" v-model="emailNotify.cc">
                </v-textarea>
              </v-col>
              <v-col cols="12" md="6" class="pt-0">
                <v-textarea variant="outlined" density="default" clearable rows="1" auto-grow
                  prepend-inner-icon="mdi-email-plus" label="Bcc" :rules="$valid([
                    { rule: 'email' },
                    { rule: 'commaSeparatedEmails', msg: 'Please enter valid comma-separated Bcc email addresses or at least one valid email' }
                  ])" v-model="emailNotify.bcc">
                </v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-spacer class="my-1"></v-spacer>

      <!-- responses -->
      <v-expansion-panels elevation="0" class="py-5 px-0" v-model="panel.responses" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-format-list-checks" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Responses</p>
            </h6>
            <v-row no-gutters>
              <v-col class="d-flex justify-start" cols="4">
                <span class="lg-badge ml-4" :class="responses.enable ? 'bg-primary-green' : 'bg-secondary-red'">
                  {{ responses.enable ? 'Enabled' : 'Disabled' }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0 px-5">
            <v-row class="py-5 px-0">
              <v-col cols="12" md="6">
                <v-switch label="Form open to submission" color="primary-red" inset density="compact" hide-details
                  v-model="responses.enable">
                </v-switch>
              </v-col>
              <v-col cols="12" v-if="responses.enable">
                <v-select class="mb-0" label="Response Limit" placeholder="Select response limit"
                  prepend-inner-icon="mdi-format-list-checks" variant="outlined" density="default" chips color="black"
                  :items="responseLimits" item-title="label" return-object v-model="responses.limit" :rules="$valid([
                    { rule: 'required' }
                  ])"></v-select>

                <v-alert type="info" variant="tonal" rounded="md" density="compact">
                  <p class="text-black mb-0">
                    <strong>Note: </strong> Disabling this setting will prevent visitors from submitting the form.
                    You can specify how many times a user from the same IP address can submit the form before
                    submissions
                    are blocked. For optimal performance, consider setting it to <strong>Unlimited</strong>.
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-spacer class="my-1"></v-spacer>

      <!-- recaptcha -->
      <v-expansion-panels elevation="0" class="px-0" v-model="panel.recaptcha" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-sync" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;reCAPTCHA</p>
            </h6>
            <v-row no-gutters>
              <v-col class="d-flex justify-start" cols="4">
                <span class="lg-badge ml-4" :class="recaptcha.enable ? 'bg-primary-green' : 'bg-secondary-red'">
                  {{ recaptcha.enable ? 'Enabled' : 'Disabled' }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0 px-5">
            <v-row class="py-5 px-0">
              <v-col cols="12" md="9">
                <v-switch label="Google reCAPTCHA" color="primary-red" inset density="compact" hide-details
                  v-model="recaptcha.enable">
                </v-switch>
              </v-col>
              <v-col cols="12" v-if="recaptcha.enable && googleRecaptchaKeys.length > 0">
                <v-autocomplete label="Select reCAPTCHA key" placeholder="Select reCAPTCHA" id="plan-features"
                  color="black" prepend-inner-icon="mdi-sync" variant="outlined" density="default" clearable chips
                  class="mb-0" :rules="$valid([
                    { rule: 'required' }
                  ])" :items="googleRecaptchaKeys" item-title="title" item-value="id"
                  v-model="recaptcha.selectedKey"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-btn rounded="md" variant="flat" class="text-none font-weight-bold my-3" color="primary-red"
                  type="button" prepend-icon="mdi-plus" @click="flag.isRecaptchaModel = true" :loading="flag.loading"
                  v-if="recaptcha.enable && !googleRecaptchaKeys.length > 0">
                  <span>Add Invisible reCAPTCHA key</span>
                </v-btn>
                <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mt-1">
                  <p class="text-black mb-0">
                    <strong>Note: </strong> It's optional to add Google Invisible reCAPTCHA key but if you're
                    embedding
                    form on your site through direct embed then you must add it for your custom domain.
                    <router-link :to="{ name: 'googlerecaptchakeys' }" target="_blank" class="text-info">
                      Manage your keys here
                      <v-icon :size="15">mdi-open-in-new</v-icon>
                    </router-link>
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-spacer class="my-1"></v-spacer>

      <!-- summary -->
      <v-expansion-panels elevation="0" class="py-5 px-0" v-model="panel.summary" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-format-list-checks" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Step Summary</p>
            </h6>
            <v-row no-gutters>
              <v-col class="d-flex justify-start" cols="4">
                <span class="lg-badge ml-4" :class="summary.enable ? 'bg-primary-green' : 'bg-secondary-red'">
                  {{ summary.enable ? 'Enabled' : 'Disabled' }}
                </span>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0 px-5">
            <v-row class="py-5 px-0">
              <v-col cols="12">
                <v-switch label="Step Summary" color="primary-red" inset density="compact" hide-details
                  v-model="summary.enable">
                </v-switch>
                <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mt-3">
                  <p class="text-black mb-0 border-0">
                    You can add a summary step for users to review their answers before submitting the
                    form.
                    <a href="https://leadgenapp.io/help/how-can-i-add-a-summary-step-for-users-to-review-their-answers-before-submitting-the-form/"
                      target="_blank" class="text-info border-0">
                      Need help?
                      <v-icon :size="15">mdi-open-in-new</v-icon>
                    </a>
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-spacer class="my-1"></v-spacer>

      <!-- geolocation -->
      <v-expansion-panels elevation="0" class="py-5 px-0" v-model="panel.geolocation" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-map-marker" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0 ml-1">Geolocation</p>
            </h6>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0">
            <v-row class="pt-5 px-0">
              <v-col cols="12">
                <v-autocomplete class="mb-0" label="Select" placeholder="Select one or more countries"
                  id="geolocation-countries" prepend-inner-icon="mdi-map-marker" variant="outlined" density="default"
                  clearable chips multiple closable-chips color="black" @update:modelValue="onGeolocationChange"
                  :items="autocompleteCountryAddressField" v-model="geolocation.countries"
                  @click:clear="clearGeolocation"></v-autocomplete>
                <v-list v-if="geolocation.countries" :lines="false" density="compact" class="pa-0 ma-0">
                  <v-list-item v-for="g in geolocation.location" :key="g.country" color="primary"
                    class="bg-grey-lighten-4 py-0 my-2 overflow-hidden" height="50" rounded="lg">
                    <v-list-item-title class="mb-8">{{ g.country }}</v-list-item-title>
                    <template v-slot:append>
                      <v-radio-group v-model="g.allow" inline class="my-1" @change="onGeolocationItemChange">
                        <v-radio color="primary-red" :value="1" :name="'geolocation_' + g.country" label="Allow"
                          class="mr-2"></v-radio>
                        <v-radio color="primary-red" :value="0" :name="'geolocation_' + g.country"
                          label="Deny"></v-radio>
                      </v-radio-group>
                    </template>
                  </v-list-item>
                </v-list>
                <v-list v-if="geolocation.countries" :lines="false" density="compact" class="pa-0 ma-0">
                  <v-list-item v-if="geolocation.location.length > 0" color="primary"
                    class="bg-grey-lighten-4 py-0 overflow-hidden" height="50" rounded="lg">
                    <v-list-item-title class="mb-8">Others</v-list-item-title>
                    <template v-slot:append>
                      <v-radio-group v-model="geolocation.others.allow" inline class="my-1"
                        @change="onGeolocationItemChange">
                        <v-radio color="primary-red" :value="1" name="geolocation_others" label="Allow"
                          class="mr-2"></v-radio>
                        <v-radio color="primary-red" :value="0" name="geolocation_others" label="Deny"></v-radio>
                      </v-radio-group>
                    </template>
                  </v-list-item>
                </v-list>
                <v-spacer v-if="geolocation.countries" class="mt-4"></v-spacer>
                <v-alert type="info" variant="tonal" rounded="md" density="compact">
                  <p class="text-black mb-0">
                    <strong>Note: </strong> Restrict access to the form based on the visitor's country of origin,
                    ensuring that only users from specified countries can view or submit the form.
                  </p>
                </v-alert>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-spacer class="my-1"></v-spacer>

      <!-- tracking -->
      <v-expansion-panels elevation="0" class="py-5 px-0" v-model="panel.tracking" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-database-settings" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0 ml-1">Tracking</p>
            </h6>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0 px-5">
            <v-row class="pt-5 px-0">
              <v-col cols="12">
                <v-text-field class="mb-0" label="Google Analytics 4 property"
                  placeholder="Enter GA4 measurement ID e.g. G-XXXX" id="plan-features"
                  prepend-inner-icon="mdi-database-settings" variant="outlined" density="default" clearable chips
                  multiple closable-chips color="black" item-title="title" v-model="tracking.id"></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <v-row class="align-center justify-space-between my-1">
      <v-col cols="12" md="6" class="text-left">
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-btn rounded="md" color="primary-red" class="text-none font-weight-bold" prepend-icon="mdi-update"
          @click="onFormSettingUpdate()" :loading="flag.loading">
          Update Settings
        </v-btn>
      </v-col>
    </v-row>

    <v-spacer class="my-1"></v-spacer>

    <!-- archive -->
    <v-expansion-panels elevation="0" class="py-5 px-0" v-model="panel.archive" ripple>
      <v-expansion-panel>
        <v-expansion-panel-title color="blue-lighten-5">
          <h6 class="d-flex justify-left mb-0">
            <v-icon icon="mdi-archive" class="pt-1"></v-icon>
            <p class="font-weight-bold text-primary-blue mb-0 ml-1">Danger Zone</p>
          </h6>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="border-thin border-t-0 px-5">
          <v-row class="py-5 px-0">
            <v-col cols="12">
              <v-alert type="error" variant="tonal" border icon="mdi-alert-circle" density="compact">
                <p class="text-black mb-0"> <strong>Archive this form. </strong>It will delete all the
                  related resources that belongs to this form.</p>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary-red" variant="flat" rounded="md" class="font-weight-bold text-capitalize"
                @click="flag.isArchiveModal = true">
                Delete Form
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- dialog archive form -->
    <v-dialog rounded="sm" max-width="600" v-model="flag.isArchiveModal" persistent>
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row class="mt-2">
            <v-col cols="12">
              <v-alert type="error" variant="tonal" border icon="mdi-alert-circle" density="compact">
                <p class="text-black mb-0"> Proceed with caution. It will delete all the
                  related resources that belongs to this form.</p>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="flag.isArchiveModal = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="primary-red" type="button"
            @click="onArchiveForm" :loading="flag.loading">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- recaptcha modal -->
    <v-dialog v-model="flag.isRecaptchaModel" max-width=" 500" rounded="sm">
      <v-card prepend-icon="mdi-cached" class="form-title">
        <template v-slot:title>
          <strong>Add Google reCAPTCHA</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert v-if="saveError" type="error" dismissible rounded="sm" density="compact" class="mb-4">
            {{ saveErrorMessage }}
          </v-alert>
          <google-recaptcha-key @recaptcha-complete="onRecaptchaComplete" @save-error="onSaveError"
            @closeDialog="flag.isRecaptchaModel = false" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar rounded="md" height="50" :color="snackbar.error ? 'secondary-red' : 'primary-green'" :timeout="3000"
      v-model="snackbar.show">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import * as actionTypes from '@/store/action-types'
import countries from '@/store/countries'
import { leadConsentTypes } from '../shared/constants/leadConsentTypes'
import userMixin from '@/mixins/user'
import packageBuilderMixin from '@/mixins/package-builder'
import GoogleRecaptchaKey from '@/components/GoogleRecaptchaKey'
import { extend } from '../plugins/validation/manager'

extend('commaSeparatedEmails', {
  getMessage: function (params) {
    return params.msg || `${params.fieldName || "This field"} must be a comma-separated list of valid email addresses without spaces or at least one valid email`
  },
  validate: function (value, params) {
    // Allow empty fields
    if (!value) {
      return true
    }

    // Regular expression for validating emails without spaces
    let emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // Check for any spaces around commas or emails
    if (/\s/.test(value)) {
      return false // Return false if there are any spaces
    }

    // Split the value by commas to get individual emails
    let emails = value.split(',')

    // Validate each email
    for (let i = 0; i < emails.length; i++) {
      if (!emailPattern.test(emails[i])) {
        return false // Return false if any email is invalid
      }
    }

    return true // All emails are valid and no spaces found
  }
})

export default {
  mixins: [userMixin, packageBuilderMixin],
  props: {
    formId: {
      type: Number,
      required: true
    }
  },
  components: {
    GoogleRecaptchaKey
  },
  data() {
    return {
      panel: {
        partial: 0,
        email: 0,
        responses: 0,
        recaptcha: 0,
        summary: 0,
        geolocation: 0,
        tracking: 0,
        archive: 1
      },
      partial: {
        enable: false,
        consentType: 1
      },
      emailNotify: {
        subject: null,
        to: null,
        cc: null,
        bcc: null,
        fromAddress: null,
        replyTo: true,
        enable: true,
      },
      responses: {
        enable: true,
        limit: null
      },
      recaptcha: {
        enable: true,
        selectedKey: null
      },
      summary: {
        enable: false
      },
      geolocation: {
        location: [],
        countries: [],
        others: { country: '', allow: 0 },
      },
      tracking: {
        id: null
      },
      snackbar: {
        text: null,
        show: false,
        error: false,
      },
      flag: {
        loading: false,
        pageLoader: true,
        pageError: false,
        isArchiveModal: false,
        isRecaptchaModel: false
      }
    }
  },
  computed: {
    ...mapGetters(['formSetting']),
    googleRecaptchaKeys() {
      return this.$store.getters['googlerecaptcha/getGoogleRecaptchaKeys'] || []
    },
    autocompleteCountryAddressField() {
      return countries.map(country => country.name)
    },
    responseLimits() {
      return [
        { label: '1', value: '1' },
        { label: '10', value: '10' },
        { label: '50', value: '50' },
        { label: '100', value: '100' },
        { label: 'Unlimited', value: '-1' }
      ]
    }
  },
  methods: {
    reloadPage: function () {
      window.location.reload()
    },
    async fetchFormSetting() {
      try {
        const response = await this.$store.dispatch(actionTypes.FORM_FETCH_SETTING, { formId: this.formId })
        const setting = response.data.data
        this.$store.dispatch(actionTypes.FORM_FETCH_SETTING_SUCCESS, { formId: this.formId, setting })
        this.updateSettingsFromResponse(setting)
        await Promise.all([this.fetchRecaptchaKeys()])
        this.packageBuilderMixin_partialLeadsCount()
        this.flag.pageLoader = false
        this.flag.pageError = false
      } catch (err) {
        this.flag.pageError = true
        this.$store.dispatch(actionTypes.FORM_FETCH_SETTING_ERROR)
      }
    },
    clearGeolocation() {
      console.log('i am called')
      // Reset the entire geolocation object
      this.geolocation = {
        location: [],
        countries: [],
        others: { country: '', allow: 1 }
      };
      // Optionally, update the store if needed
      this.updateField('geolocation', this.geolocation);
    },
    updateSettingsFromResponse(setting) {
      this.partial = {
        enable: Boolean(setting.enabled),
        consentType: setting.consent_type === leadConsentTypes.INFORMED ? 1 : 0
      }
      this.emailNotify = {
        subject: setting.subject,
        to: setting.to,
        cc: setting.cc,
        bcc: setting.bcc,
        fromAddress: setting.from_name,
        replyTo: Boolean(setting.reply_to),
        enable: Boolean(setting.email_notifications),
      }
      this.responses = {
        enable: Boolean(setting.accept_responses),
        limit: this.selectedResponseLimit(setting.response_limit)
      }
      this.recaptcha = {
        enable: Boolean(setting.enable_google_recaptcha),
        selectedKey: setting.google_recaptcha_key_id
      }
      this.summary = {
        enable: Boolean(setting.steps_summary)
      }
      this.tracking = {
        id: setting.tracking_ga4_property
      }
      this.updateGeolocation(setting.geolocation)
    },
    updateGeolocation(geolocationData) {
      if (geolocationData) {
        this.geolocation.location = geolocationData.filter(item => item.country)
        this.geolocation.countries = this.geolocation.location.map(item => item.country)
        const otherItem = geolocationData.find(item => !item.country)
        if (otherItem) {
          this.geolocation.others = { country: '', allow: otherItem.allow }
        }
      }
    },
    async fetchRecaptchaKeys() {
      try {
        const response = await this.$store.dispatch('googlerecaptcha/fetchRecaptchaKeys')
        this.$store.dispatch('googlerecaptcha/fetchRecaptchaKeysSuccess', response.data.data)
      } catch (err) {
        let text = err.response.data && err.response.data.meta && err.response.data.meta.error_message
          ? err.response.data.meta.error_message : 'An error occurred while fetching reCAPTCHA keys. Please try again.'
        this.showSnackbar(text, true)
      }
    },
    onRecaptchaComplete: async function () {
      this.flag.isRecaptchaModel = false
      await Promise.all([this.fetchRecaptchaKeys()])
    },
    updateField(field, value) {
      this.$store.dispatch('FORM_SETTING_UPDATE_FIELD', {
        formId: this.formId,
        value,
        field
      })
    },
    async onFormSettingUpdate() {
      const { valid } = await this.$refs.formSettings.validate()
      if (!valid) {
        this.showSnackbar('Please fill in all required fields', true)
        return // Prevent submission if the form is not valid
      }

      this.flag.loading = true

      try {
        await this.$store.dispatch(actionTypes.FORM_SAVE_SETTING, { formId: this.formId })
        this.flag.loading = false
        this.showSnackbar('Form settings have been updated successfully.', false)
      } catch (err) {
        let text = err.response.data && err.response.data.meta && err.response.data.meta.error_message
          ? err.response.data.meta.error_message : 'An error occurred in updating form setting. Please try again.'
        this.showSnackbar(text, true)
      } finally {
        this.flag.loading = false
      }
    },
    async onArchiveForm() {
      this.flag.loading = true
      try {
        await this.$store.dispatch(actionTypes.FORM_ARCHIVE, { id: this.formId })
        this.$store.dispatch(actionTypes.FORM_ARCHIVE_SUCCESS)
        this.$router.push({ name: 'forms.index' })
        this.flag.loading = false
      } catch (err) {
        let text = err.response.data && err.response.data.meta && err.response.data.meta.error_message
          ? err.response.data.meta.error_message : 'An error occurred while archiving this form. Please try again.'
        this.showSnackbar(text, true)
        this.flag.loading = false
        this.$store.dispatch(actionTypes.FORM_ARCHIVE_ERROR)
      }
    },
    onGeolocationChange(value) {
      const geolocation = value.map(country => ({
        country,
        allow: this.geolocation.location.find(item => item.country === country)?.allow || 1
      }))
      this.geolocation.location = geolocation
    },
    onGeolocationItemChange: function (value) {
      this.geolocation.location = [...this.geolocation.location]
    },
    selectedResponseLimit(value) {
      return this.responses.enable
        ? this.responseLimits.find(item => item.value === value.toString())
        : this.responseLimits[4]
    },
    showSnackbar(message, error) {
      this.snackbar.show = true
      this.snackbar.error = error
      this.snackbar.text = message
    },
  },
  watch: {
    'partial.enable'(newValue) {
      this.updateField('partial_leads', newValue ? '1' : '0')
      if (newValue) localStorage.setItem('activated', 'true')
    },
    'partial.consentType'(newValue) {
      this.updateField('consent_type', newValue ? leadConsentTypes.INFORMED : leadConsentTypes.EXPRESSED)
    },
    'emailNotify.enable'(newValue) {
      this.updateField('email_notifications', newValue ? '1' : '0')
    },
    'emailNotify.subject'(newValue) {
      this.updateField('subject', newValue)
    },
    'emailNotify.to'(newValue) {
      this.updateField('to', newValue)
    },
    'emailNotify.cc'(newValue) {
      this.updateField('cc', newValue)
    },
    'emailNotify.bcc'(newValue) {
      this.updateField('bcc', newValue)
    },
    'emailNotify.fromAddress'(newValue) {
      this.updateField('from_name', newValue)
    },
    'emailNotify.replyTo'(newValue) {
      this.updateField('reply_to', newValue ? '1' : '0')
    },
    'responses.enable'(newValue) {
      this.updateField('accept_responses', newValue ? '1' : '0')
    },
    'responses.limit'(newValue) {
      this.updateField('response_limit', newValue.value)
    },
    'summary.enable'(newValue) {
      this.updateField('steps_summary', newValue ? '1' : '0')
    },
    'recaptcha.enable'(newValue) {
      this.updateField('enable_google_recaptcha', newValue ? '1' : '0')
    },
    'recaptcha.selectedKey'(newValue) {
      this.updateField('google_recaptcha_key_id', newValue)
    },
    geolocation: {
      deep: true,
      handler(newValue) {
        const data = newValue.location.concat({
          country: this.geolocation.others.country,
          allow: this.geolocation.others.allow
        })
        this.updateField('geolocation', data)
      }
    },
    'tracking.id'(newValue) {
      this.updateField('tracking_ga4_property', newValue || '')
    }
  },
  mounted() {
    this.fetchFormSetting()
    setTimeout(() => {
      this.packageBuilderMixin_canCreateLeadAndPartialLead()
    }, 2000)
  }
}
</script>
