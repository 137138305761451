import * as socialAuthApi from '@/api/social-auth'

const state = {
  redirectProviderUrl: {}
}

const getters = {
  getProviderRedirectUrl: function (state) {
    return state.redirectProviderUrl
  }
}

const actions = {
  getProviderRedirectUrl ({state, commit}, data) {
    return socialAuthApi.fetchProviderRedirectUrl(data)
  },
  socialSignUp ({state, commit}, data) {
    return socialAuthApi.registerProviderAccount(data.provider, data.data)
  },
  socialLogIn ({state, commit}, data) {
    return socialAuthApi.loginProviderAccount(data.provider, data.data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions
}
