<template>
  <div>
    <router-view></router-view>
    <!-- MEDIA MANAGER -->
    <!-- <media-manager></media-manager> -->
    <!-- ICON LIBRARY MANAGER -->
    <!-- <icon-library-manager></icon-library-manager> -->
  </div>
</template>

<script>
import * as actionTypes from './store/action-types'
import { mapGetters } from 'vuex'
import MediaManager from '@/components/media/MediaManager'
import IconLibraryManager from '@/components/icon-library/IconLibraryManager'
import userMixin from '@/mixins/user'

export default {
  name: 'app',

  mixins: [userMixin],

  components: {
    MediaManager,
    IconLibraryManager
  },

  mounted() {
    //let user = localStorage.getItem('user')
    let user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      let subscription = user?.plan?.subscription
      if (subscription) {
        let d1 = new Date(subscription?.current_period_end * 1000)
        let d2 = new Date()
        if (d2 >= d1) {
          user.plan = this.freePlan
          this.$store.dispatch(actionTypes.CHANGE_USER_PLAN_SUCCESS, { plan: this.freePlan })
        }
      }
    }

    this.$store.dispatch(actionTypes.INIT_APP, {
      isAuthenticated: !localStorage.getItem('token'),
      token: JSON.parse(localStorage.getItem('token')),
      decodedToken: JSON.stringify(localStorage.getItem('decodedToken')),
      user: user,
      plans: localStorage.getItem('plans')
    })

    if (!this.user) {
      return
    }

    this.$store.dispatch('notification/toggleVisibility', {
      key: 'verifyEmail',
      value: !this.user?.verified && !localStorage.getItem('notification.dashboard.verifyEmailHide')
    })
  },
  methods: {
    selectedMedia: function (selected) {
    }
  },
  computed: {
    ...mapGetters(['freePlan'])
  }
}
</script>
