<template>
  <v-container class="form-theme-store-cpt">
    <v-row v-if="appliedDefaultTheme">
      <v-col cols="12" class="mb-4">
        <v-alert border type="info" variant="tonal" rounded="md" density="compact" icon="mdi-information"
          class="text-black text-left">
          <p class="text-black mb-0">You just activated your new theme! Please save your changes.</p>
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="align-baseline">
      <v-col cols="6" class="mt-n5">
        <h3 class="mb-0">Themes</h3>
      </v-col>
      <v-col cols="6" class="d-flex justify-end" v-if="selectedTheme">
        <v-btn size="small" class="text-none font-weight-bold mr-2" color="default" variant="flat" rounded="md"
          @click="discardTheme">Discard</v-btn>
        <v-btn size="small" class="text-none font-weight-bold mr-2" color="secondary-red" variant="flat" rounded="md"
          @click="() => openDialog('updateCustomTheme')"
          v-if="this.currentTab === 'myThemes' || (this.currentTab === 'themeStore' && isAdmin)">
          Update Theme
        </v-btn>
        <v-btn size="small" class="text-none font-weight-bold mr-2" color="secondary-red" variant="flat" rounded="md"
          @click="applyDefaultTheme">Apply Theme</v-btn>
        <v-btn class="mt-n3" icon="mdi-dots-vertical" color="secondary-red" variant="text" v-if="moreActions.length > 0"
          id="menu-activator">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
        <v-menu activator="#menu-activator">
          <v-list>
            <v-list-item v-for="(item, index) in moreActions" :key="index" :value="index"
              @click="() => openDialog(item.action)">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <p>Choose from designs below or use your custom themes</p>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12">
        <v-tabs ref="" color="secondary-red" v-model="currentTab" class="mb-4" @update:modelValue="onTabChange($event)"
          align-tabs="end">
          <v-tab class="text-none" value="themeStore">Theme Store</v-tab>
          <v-tab class="text-none" value="myThemes" v-if="customThemes.length > 0">My Themes</v-tab>
          <v-tab class="text-none" value="inactiveThemes" v-if="isAdmin && deactivatedDefaultThemes.length > 0">Inactive
            Themes</v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-tabs-window v-model="currentTab" ref="uiTabs">
          <!-- THEME STORE TAB -->
          <v-tabs-window-item value="themeStore">
            <v-container class="overflow-y-auto" style="max-height: 330px;">
              <v-row>
                <v-col v-for="(defaultTheme, index) in defaultThemes" :key="index">
                  <form-theme-store-preview :selectedTheme="selectedTheme" :theme="defaultTheme"
                    @selectTheme="selectTheme" />
                </v-col>
              </v-row>
            </v-container>
          </v-tabs-window-item>
          <!-- MY|CUSTOM THEMES TAB -->
          <v-tabs-window-item value="myThemes">
            <v-container class="overflow-y-auto" style="max-height: 330px;">
              <v-row>
                <v-col v-for="(customTheme, index) in customThemes" :key="index">
                  <form-theme-store-preview :selectedTheme="selectedTheme" :theme="customTheme"
                    @selectTheme="selectTheme" />
                </v-col>
              </v-row>
            </v-container>
          </v-tabs-window-item>
          <!-- INACTIVE THEMES TAB -->
          <v-tabs-window-item value="inactiveThemes">
            <v-container class="overflow-y-auto" style="max-height: 330px;">
              <v-row class="form-theme-store-cpt__themes">
                <v-col v-for="(deactivatedDefaultTheme, index) in deactivatedDefaultThemes" :key="index">
                  <form-theme-store-preview :selectedTheme="selectedTheme" :theme="deactivatedDefaultTheme"
                    @selectTheme="selectTheme" />
                </v-col>
              </v-row>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-col>
    </v-row>

    <!-- Theme actions start -->
    <v-row class="">
      <v-col cols="12">
        <h3> Actions</h3>
      </v-col>
      <v-col cols="12" class="d-flex justify-start mt-n4">
        <v-btn variant="flat" rounded="md" class="text-none font-weight-bold mr-4" color="secondary-red"
          @click="openDialog('resetDefaultTheme')">
          Reset to default theme
        </v-btn>
        <v-btn variant="flat" rounded="md" class="text-none font-weight-bold" color="light-blue"
          @click="openDialog('saveCustomTheme')">
          Save as Custom Theme
        </v-btn>
      </v-col>
    </v-row>

    <!-- dialog reset theme-->
    <v-dialog rounded="sm" max-width="600" v-model="dialog.resetDefaultTheme" persistent>
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row>
            <v-col cols="12">
              <v-alert border type="error" variant="tonal" rounded="md" density="compact" icon="mdi-information">
                <p class="text-black mb-0">
                  Proceed with caution. You are about to reset to default theme.
                </p>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="dialog.resetDefaultTheme = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="secondary-red" @click="reset"
            :loading="loader.dialogConfirm">
            <span>Reset</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog save custom theme-->
    <v-dialog rounded="sm" max-width="600" v-model="dialog.saveCustomTheme" persistent>
      <v-form @submit.prevent="saveCustomTheme()" ref="saveCustomTheme">
        <v-card prepend-icon="mdi-update">
          <template v-slot:title>
            <strong>Are you sure?</strong>
          </template>
          <v-divider></v-divider>
          <v-card-text class="mb-0">
            <v-row>
              <v-col cols="12" class="mb-0 pb-0">
                <v-text-field
                  :rules="$valid([{ rule: 'required', fieldName: 'Theme Title Field' }, { rule: 'between', min: '5', max: '50' }])"
                  active variant="outlined" density="comfortable" label="Theme Name" placeholder="Set theme name"
                  v-model="title.customTheme"></v-text-field>
                <MediaManager v-if="isAdmin" @mm:selected="openPicker($event)">
                  <template v-slot:default="{ openModal }">
                    <v-btn v-if="image" block variant="outlined" elevation="0" class="font-weight-bold"
                      prepend-icon="mdi-image-plus" color="secondary-red" @click="openModal()">
                      <template v-slot:prepend>
                        <img :src="image" width="26px" height="26px" />Add Image
                      </template>
                    </v-btn>
                    <v-btn v-else block variant="outlined" elevation="0" class="font-weight-bold" text="Add image"
                      prepend-icon="mdi-image-plus" color="secondary-red" @click="openModal()">
                    </v-btn>
                  </template>
                </MediaManager>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mb-2 mr-4">
            <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
              @click="dialog.saveCustomTheme = false">
            </v-btn>
            <v-btn rounded="md" type="submit" variant="flat" class="text-none font-weight-bold" color="secondary-red"
              :loading="loader.dialogConfirm">
              <span>Save</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- dialog update custom theme-->
    <v-dialog rounded="sm" max-width="600" v-model="dialog.updateCustomTheme" persistent>
      <v-form @submit.prevent="updateTheme()" ref="updateTheme">
        <v-card prepend-icon="mdi-update">
          <template v-slot:title>
            <strong>Are you sure?</strong>
          </template>
          <v-divider></v-divider>
          <v-card-text class="mb-0">
            <v-row>
              <v-col cols="12">
                <v-alert border type="warning" variant="tonal" rounded="md" density="compact" icon="mdi-information">
                  <p class="text-black mb-0">
                    Proceed with caution. You are about to update your custom theme with your current form design
                    settings? Your custom theme will permanently take over the new settings.
                  </p>
                </v-alert>
              </v-col>
              <v-col cols="12" class="mb-0 pb-0">
                <v-text-field
                  :rules="$valid([{ rule: 'required', fieldName: 'Theme Title Field' }, { rule: 'between', min: '5', max: '50' }])"
                  active variant="outlined" density="comfortable" label="Theme Name" placeholder="Set theme name"
                  v-model="title.updateTheme"></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-0 pt-0" v-if="isAdmin">
                <MediaManager v-if="isAdmin" @mm:selected="openPicker($event)">
                  <template v-slot:default="{ openModal }">
                    <v-btn v-if="themeImage || image" block variant="outlined" elevation="0" class="font-weight-bold"
                      prepend-icon="mdi-image-plus" color="secondary-red" @click="openModal()">
                      <template v-slot:prepend>
                        <img :src="!themeImage ? image : themeImage" width="26px" height="26px" />Add Image
                      </template>
                    </v-btn>
                    <v-btn v-else block variant="outlined" elevation="0" class="font-weight-bold" text="Add image"
                      prepend-icon="mdi-image-plus" color="secondary-red" @click="openModal()">
                    </v-btn>
                  </template>
                </MediaManager>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mb-2 mr-4">
            <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
              @click="cancelUpdate">
            </v-btn>
            <v-btn rounded="md" type="submit" variant="flat" class="text-none font-weight-bold" color="secondary-red"
              :loading="loader.dialogConfirm">
              <span>Update</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- dialog publish custom theme-->
    <v-dialog rounded="sm" max-width="600" v-model="dialog.publishCustomTheme" persistent>
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row>
            <v-col cols="12">
              <v-alert border type="warning" variant="tonal" rounded="md" density="compact" icon="mdi-information">
                <p class="text-black mb-0">
                  Published Custom theme will appear as default theme in customer accounts.
                </p>
              </v-alert>
            </v-col>
            <v-col cols="12" class="mb-0 pb-0">
              <v-text-field active variant="outlined" density="comfortable" label="Theme Name"
                placeholder="Set theme name" v-model="title.publishTheme"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="dialog.publishCustomTheme = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="secondary-red"
            @click="publishCustomTheme" :loading="loader.dialogConfirm">
            <span>Publish</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog activate theme-->
    <v-dialog rounded="sm" max-width="600" v-model="dialog.activateTheme" persistent>
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row>
            <v-col cols="12">
              <v-alert border type="warning" variant="tonal" rounded="md" density="compact" icon="mdi-information">
                <p class="text-black mb-0">
                  You are about to activate <strong>{{ selectedTheme?.title }}</strong> theme. Are you sure?
                </p>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="dialog.activateTheme = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="secondary-red"
            @click="activateDefaultTheme" :loading="loader.dialogConfirm">
            <span>Activate</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog deactivate theme-->
    <v-dialog rounded="sm" max-width="600" v-model="dialog.deactivateTheme" persistent>
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row>
            <v-col cols="12">
              <v-alert border type="warning" variant="tonal" rounded="md" density="compact" icon="mdi-information">
                <p class="text-black mb-0">
                  You are about to deactivate <strong>{{ selectedTheme?.title }}</strong> theme. Are you sure?
                </p>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="dialog.deactivateTheme = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="secondary-red"
            @click="deactivateDefaultTheme" :loading="loader.dialogConfirm">
            <span>Deactivate</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog delete theme-->
    <v-dialog rounded="sm" max-width="600" v-model="dialog.deleteTheme" persistent>
      <v-card prepend-icon="mdi-update">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text class="mb-0">
          <v-row>
            <v-col cols="12">
              <v-alert border type="error" variant="tonal" rounded="md" density="compact" icon="mdi-information">
                <p class="text-black mb-0">
                  You are about to delete <strong>{{ selectedTheme?.title }}</strong> theme. Are you sure?
                </p>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="dialog.deleteTheme = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="secondary-red"
            @click="deleteTheme" :loading="loader.dialogConfirm">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as actionTypes from "@/store/action-types"
import userMixin from "@/mixins/user"
import { mapGetters } from "vuex"
import EventBus from "@/library/event-bus"
import FormThemeStorePreview from "@/components/FormVariantTheme/components/FormThemeStorePreview"
import config from "@/api/config"
import MediaManager from "../../media/MediaManager.vue"

export default {
  props: {
    formId: {
      type: Number,
    },
    formVariantId: {
      type: Number,
    },
  },
  mixins: [userMixin],
  components: {
    "form-theme-store-preview": FormThemeStorePreview,
    MediaManager
  },
  data() {
    return {
      currentTab: "themeStore",
      themeTabs: "themeStore",
      selectedTheme: null,
      storeImage: "",
      image: "",
      appliedDefaultTheme: false,
      title: {
        updateTheme: null,
        publishTheme: null,
        customTheme: null
      },
      dialog: {
        resetDefaultTheme: false,
        saveCustomTheme: false,
        updateCustomTheme: false,
        publishCustomTheme: false,
        activateTheme: false,
        deactivateTheme: false,
        deleteTheme: false,
      },
      loader: {
        dialogConfirm: false
      }
    }
  },
  methods: {
    cancelUpdate: function () {
      this.dialog.updateCustomTheme = false
      this.discardTheme()
    },
    openPicker: function (selected) {
      this.$store.dispatch("media/setVisibility", {
        visible: true,
      })
      this.image = selected[0].url
      this.storeImage = selected[0].id
    },
    openDialog(dialog) {
      switch (dialog) {
        case "resetDefaultTheme":
          this.dialog.resetDefaultTheme = true
          break
        case "saveCustomTheme":
          this.dialog.saveCustomTheme = true
          break
        case "updateCustomTheme":
          this.title.updateTheme = this.selectedTheme?.title
          this.dialog.updateCustomTheme = true
          break
        case "make_default":
          this.title.publishTheme = this.selectedTheme?.title
          this.dialog.publishCustomTheme = true
          break
        case "activate":
          this.dialog.activateTheme = true
          break
        case "deactivate":
          this.dialog.deactivateTheme = true
          break
        case "delete":
          this.dialog.deleteTheme = true
          break
      }
    },
    saveCustomTheme: async function () {
      const { valid } = await this.$refs.saveCustomTheme.validate()
      if (!valid) {
        return
      }

      if (!(this.title.customTheme === "")) {
        this.loader.dialogConfirm = true
        this.$store
          .dispatch(actionTypes.FORMBUILDER_SAVE_FORM_THEME_TEMPLATE, {
            title: this.title.customTheme,
            imageId: this.storeImage,
            image: this.image,
            config: {
              general: this.theme.general,
              typography: this.theme.typography,
              ui_elements: this.theme.ui_elements,
              custom_css: this.custom_css,
            },
          })
          .then((response) => {
            this.currentTab = "myThemes"
            this.selectedTheme = null
            this.$store
              .dispatch(actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES)
              .then((response) => {
                this.$store.dispatch(
                  actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS,
                  response.data.data
                )
                this.loader.dialogConfirm = false
                this.dialog.saveCustomTheme = false
              })
          })
      }
    },
    publishCustomTheme: function () {
      if (!this.title.publishTheme) {
        return
      }

      this.loader.dialogConfirm = true
      this.$store
        .dispatch("formthemetemplate/publishCustomTemplate", {
          id: this.selectedTheme.id,
          config: this.selectedTheme.config,
          title: this.title.publishTheme,
        })
        .then(
          () => {
            this.selectedTheme = null
            this.getThemesTemplate()
            this.title.publishTheme = ""
            this.loader.dialogConfirm = false
            this.dialog.publishCustomTheme = false
          },
          () => {
            this.loader.dialogConfirm = false
          }
        )
    },
    updateTheme: async function () {
      const { valid } = await this.$refs.updateTheme.validate()
      if (!valid) {
        return
      }

      if (!this.title.updateTheme) {
        return
      }

      this.loader.dialogConfirm = true
      this.image = ''
      this.$store
        .dispatch("formthemetemplate/updateTemplate", {
          id: this.selectedTheme.id,
          config: {
            general: this.theme.general,
            typography: this.theme.typography,
            ui_elements: this.theme.ui_elements,
          },
          theme_image: this.selectedTheme.theme_image,
          title: this.title.updateTheme,
          imageId: this.storeImage,
        })
        .then(
          () => {
            this.selectedTheme = null
            this.title.updateTheme = ""
            this.storeImage = null
            this.loader.dialogConfirm = false
            this.dialog.updateCustomTheme = false
            this.getThemesTemplate()
          },
          () => {
            this.loader.dialogConfirm = false
          }
        )
    },
    selectTheme(theme) {
      this.selectedTheme = theme

      window.scroll({
        top: 0,
        behavior: "smooth",
      })
    },
    applyDefaultTheme: function () {
      this.$store
        .dispatch(actionTypes.FORMBUILDER_SET_TEMPLATE_THEME, {
          theme: this.selectedTheme,
        })
        .then(() => {
          this.$emit("addStyle")
          this.selectedTheme = null
          this.appliedDefaultTheme = true
          setTimeout(() => {
            this.appliedDefaultTheme = false
          }, 10000)
        })
    },
    discardTheme() {
      this.selectedTheme = null
    },
    deactivateDefaultTheme: function () {
      this.loader.dialogConfirm = true
      this.$store
        .dispatch(
          "formthemetemplate/deactivateDefaultTemplate",
          this.selectedTheme.id
        )
        .then(() => {
          this.selectedTheme = null
          this.getThemesTemplate()
          this.currentTab = "inactiveThemes"
          this.loader.dialogConfirm = false
          this.dialog.deactivateTheme = false
        })
    },
    activateDefaultTheme: function () {
      this.loader.dialogConfirm = true
      this.$store
        .dispatch(
          "formthemetemplate/activateDefaultTemplate",
          this.selectedTheme
        )
        .then(() => {
          this.selectedTheme = null
          this.getThemesTemplate()
          this.currentTab = "themeStore"
          this.loader.dialogConfirm = false
          this.dialog.activateTheme = false
        })
    },
    deleteTheme: function () {
      this.loader.dialogConfirm = true
      this.$store
        .dispatch("formthemetemplate/deleteTemplate", this.selectedTheme.id)
        .then(() => {
          this.selectedTheme = null
          this.getThemesTemplate()
          if (this.currentTab === "myThemes" && this.customThemes.length > 0) {
            this.currentTab = "myThemes"
          } else {
            this.currentTab = "themeStore"
          }
          this.loader.dialogConfirm = false
          this.dialog.deleteTheme = false
        })
    },
    getThemesTemplate: function () {
      this.$store
        .dispatch(actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES)
        .then((response) => {
          this.$store.dispatch(
            actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS,
            response.data.data
          )
        })
    },
    reset: function () {
      this.loader.dialogConfirm = true
      if (this.$route.params.id && this.$route.params.variantId) {
        this.$store
          .dispatch(actionTypes.FORMBUILDER_SET_DEFAULT_THEME, {
            id: this.theme.id,
            form_variant_id: parseInt(this.$route.params.variantId),
          })
          .then(() => {
            this.$emit("addStyle")
          })
      } else {
        this.$store
          .dispatch(actionTypes.FORMBUILDER_SET_DEFAULT_THEME, { id: -1 })
          .then(() => {
            this.$emit("addStyle")
          })
      }
      this.dialog.resetDefaultTheme = false
      this.loader.dialogConfirm = false
    },
    onTabChange(tab) {
      this.selectedTheme = null
      this.currentTab = tab
    }
  },
  computed: {
    ...mapGetters(["defaultFormVariantTheme"]),
    themeImage: function () {
      if (!this.selectedTheme?.theme_image) {
        return
      }
      if (this.image) {
        return this.image
      } else {
        return (
          config.basePath +
          "/media/" +
          this.selectedTheme?.theme_image?.ref_id +
          "/" +
          this.selectedTheme?.theme_image?.name
        )
      }
    },
    theme: function () {
      let themes = []
      if (this.formId && this.formVariantId) {
        themes = this.$store.state.formVariantThemes.filter(
          (t) => t.form_variant_id === parseInt(this.formVariantId)
        )
      } else {
        themes = this.$store.state.formVariantThemes.filter((t) => t.id === -1)
        if (themes.length === 0) {
          return this.defaultFormVariantTheme
        }
      }

      //return deepmerge(this.defaultFormVariantTheme, themes[0])
      return { ...this.defaultFormVariantTheme, ...themes[0] }
    },
    moreActions() {
      const actions = []

      if (this.currentTab === "themeStore" && this.isAdmin) {
        actions.push({
          label: "Deactivate",
          action: "deactivate",
        })
      }

      if (this.currentTab === "myThemes") {
        if (this.isAdmin) {
          actions.push({
            label: "Make Default",
            action: "make_default",
          })
        }

        actions.push({
          label: "Delete",
          action: "delete",
        })
      }

      if (this.currentTab === "inactiveThemes" && this.isAdmin) {
        actions.push({
          label: "Activate",
          action: "activate",
        })

        actions.push({
          label: "Delete",
          action: "delete",
        })
      }

      return actions
    },
    themes: function () {
      return this.$store.state.formThemeTemplates[0] || []
    },
    customThemes: function () {
      let themes = this.themes.filter((t) => t.type === "CUSTOM")

      return themes
    },
    defaultThemes: function () {
      let themes = this.themes.filter(
        (t) => t.type === "DEFAULT" && t.published
      )

      return themes
    },
    deactivatedDefaultThemes: function () {
      let themes = this.themes.filter(
        (t) => t.type === "DEFAULT" && !t.published
      )

      return themes
    },
  },
}
</script>
