import * as mutationTypes from '@/store/mutation-types'
import * as ExternalCheckoutApi from '@/api/external-checkout'

const state = {
  externalCheckoutList: []
}

const getters = {
  getExternalCheckoutList: function (state) {
    return state.externalCheckouts
  }
}

const actions = {
  getExternalCheckoutList ({ state, commit }, data) {
    commit(mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS)
    return ExternalCheckoutApi.getExternalCheckoutList(data)
  },
  getExternalCheckoutListSuccess ({ state, commit }, data) {
    commit(mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS_SUCCESS, data)
  },
  deleteExternalCheckout ({ state, commit }, data) {
    commit(mutationTypes.ECM_DELETE_EXTERNAL_CHECKOUT)
    return ExternalCheckoutApi.deleteExternalCheckout(data.id)
  },
  deleteExternalCheckoutSuccess ({ state, commit }, data) {
    commit(mutationTypes.ECM_DELETE_EXTERNAL_CHECKOUT_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS] (state, data) {

  },
  [mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS_SUCCESS] (state, data) {
    state.externalCheckoutsList = data || []
  },
  [mutationTypes.ECM_DELETE_EXTERNAL_CHECKOUT] (state, data) { },
  [mutationTypes.ECM_DELETE_EXTERNAL_CHECKOUT_SUCCESS] (state, data) {
    const index = state.externalCheckoutsList.findIndex(item => item.id === data.id)
    if (index > -1) {
      state.externalCheckoutsList.splice(index, 1)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
