<template>
  <v-container class="fill-height auth-container">
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>
