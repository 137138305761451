<template>
  <div class="container user-edit-view">
      <div class="user-edit-view__breadcrum">
        <router-link class="text-primary-red" :to="{name : 'users.index'}"> <v-icon icon="mdi-arrow-left"></v-icon>
          <span class="text">Users</span> </router-link>
      </div>
      <div class="user-edit-view__content">
        <user-edit :user-id="parseInt(this.$route.params.id)" />
      </div>
  </div>
</template>

<script>
import UserEdit from '@/components/UserEdit'

export default {
  components: {
    'user-edit': UserEdit
  }
}
</script>
