<template>
  <!-- Past Due Modal -->
  <v-dialog v-model="showPastDueModal" persistent rounded="md" max-width="850" class="past-due-dialog">
    <v-card>
      <v-card-title class="bg-black white--text d-flex align-center px-0 py-4">
        <v-row no-gutters class="d-flex justify-space-between align-center">
          <!-- Left-aligned Logo -->
          <v-col cols="3" class="pl-9">
            <v-img :src="require('./../../assets/images/views/logo/logo_2022.png')" :height="25"></v-img>
          </v-col>

          <!-- Right-aligned Image -->
          <v-col cols="2" class="pr-9">
            <v-img :src="require('./../../assets/images/icons/plan-header-two.svg')" :height="30"></v-img>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="px-5">
        <v-empty-state :image="require('./../../assets/images/plans/bill.jpg')" size="250" text-width="800">
          <template v-slot:media>
            <v-img class="mb-4"></v-img>
          </template>
          <template v-slot:title>
            <div class="text-h5 font-weight-bold text-high-emphasis">Action Required: Confirm Bank Details</div>
          </template>
          <template v-slot:text>
            <p class="text-grey-darken-2">
              The billing on your account failed for this month's payment and your account including your forms are
              currently offline. This can happen when there is a problem with the bank, insufficient funds, card expiry,
              or other reasons.
            </p>
            <v-spacer class="my-4"></v-spacer>
            <v-btn rounded="md" color="primary-red" class="font-weight-bold text-capitalize" elevation="5" height="42"
              width="250" @click="sendToPlans()">
              Update Your Bank Details
            </v-btn>
            <p class="mt-2 mb-0 text-grey-darken-2">
              To regain access to the account, click on the button above and update the bank/card information. This will
              re-activate the account and ensure your forms and lead access will be available again.
            </p>
          </template>
        </v-empty-state>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showModal: Boolean
  },
  data() {
    return {
      showPastDueModal: false
    }
  },
  mounted() {
    this.showPastDueModal = this.showModal
  },
  methods: {
    sendToPlans: function () {
      this.$router.push({ name: 'plans.index' })
    }
  }
}
</script>
