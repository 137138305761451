import * as mutationTypes from '@/store/mutation-types'
import * as formReportApi from '@/api/report'

const state = {
  formReportData: []
}

const getters = {
  getFormReportData: function (state) {
    return state.formReportData
  }
}

const actions = {
  getFormReportData ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_REPORT_DATA)
    return formReportApi.getFormReportData(data)
  }
}

const mutations = {
  [mutationTypes.FETCH_FORM_REPORT_DATA] (state, data) {
    state.formReportData = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
