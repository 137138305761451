
<template>
  <div class="chat-page-view">
    <div class="nav-bar">
     <a href="https://leadgenapp.io/">
       <img src="./../assets/images/leadgen-logo-white-new.svg" alt="LeadGen" />
     </a>
    </div>
    <div class="container">
      <div class="wrapper">
        <h1>Start Chat</h1>
        <h3>Wait for the chat window to open on the right screen side.</h3>
        <h6>Available live chat hours with agent:</h6>
        <p style="margin-top:-11px;">6am-9pm (UK), 3am-5pm (ET)</p>
        <p>Leave your chat message at any time with your email outside chat hours and we will get back as soon as possible.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      //
    }
  }
}
</script>

