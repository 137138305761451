<template>
  <v-container>
    <v-row class="general-chart-view mt-10">
      <v-col cols="12">
        <!-- Graph Card -->
        <v-card rounded="md" min-height="400" variant="flat">
          <v-card-text v-if="!initialized" class="text-center py-5">
            <v-progress-circular indeterminate color="error" :size="30" :width="3">
            </v-progress-circular>
          </v-card-text>
          <v-card-text v-else>
            <div class="float-right py-4">
              <v-select variant="outlined" density="compact" v-model="chartView" @update:modelValue="chartInit"
                :items="chartViewOptions" item-title="label" return-object></v-select>
            </div>
            <div class="graph-indicator">
              <canvas id="general-chart"></canvas>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from 'chart.js'
import moment from 'moment'
import timePeriods from '@/store/time-periods'
import _ from 'lodash'

export default {
  props: {
    initialized: {
      type: Boolean,
      default: false,
      required: true
    },
    generalWidget: {
      default: null,
      required: true
    }
  },
  data: function () {
    return {
      chartViewOptions: [
        {
          label: 'By Leads',
          value: 'lead'
        },
        {
          label: 'By Partial Leads',
          value: 'partialLead'
        },
        {
          label: 'By Views',
          value: 'view'
        },
        {
          label: 'By Conversion Rate',
          value: 'conversionRate'
        }
      ],
      chartView: {
        label: 'Views',
        value: 'view'
      },
      chartInstance: null
    }
  },
  methods: {
    getPeriodRange: function () {
      let periodStart
      let periodEnd

      if (timePeriods.ALL_TIME) {
        if (this.chartView.value === 'lead') {
          periodStart = _.first(this.leadsResponse['value'])?.leads_created_at
          periodEnd = _.last(this.leadsResponse['value'])?.leads_created_at
        } else if (this.chartView.value === 'partialLead') {
          periodStart = _.first(this.partialLeadsResponse['value'])?.leads_created_at
          periodEnd = _.last(this.partialLeadsResponse['value'])?.leads_created_at
        } else if (this.chartView.value === 'view') {
          periodStart = _.first(this.viewsResponse?.value)?.visits_created_at
          periodEnd = _.last(this.viewsResponse?.value)?.visits_created_at
        } else if (this.chartView.value === 'conversionRate') {
          periodStart = _.first(this.crResponse['value'])?.conversion_rate_created_at
          periodEnd = _.last(this.crResponse['value'])?.conversion_rate_created_at
        }
      } else {
        periodStart = this.generalWidget.filter_params.period.start_date
        periodEnd = this.generalWidget.filter_params.period.end_date
      }

      periodStart = moment(periodStart).format('MMM DD, YYYY')
      periodEnd = moment(periodEnd).format('MMM DD, YYYY')

      return [periodStart, periodEnd]
    },
    getComparePeriodRange: function () {
      let comparePeriodStart = moment(this.generalWidget.filter_params.compare_period.start_date)
        .format('MMM DD, YYYY')
      let comparePeriodEnd = moment(this.generalWidget.filter_params.compare_period.end_date)
        .format('MMM DD, YYYY')

      return [comparePeriodStart, comparePeriodEnd]
    },
    chartInit: function () {
      if (this.chartInstance) {
        this.chartInstance.destroy()
      }

      if (this.chartView.value === 'lead') {
        this.chartInstance = this.renderLeadsChart()
      } else if (this.chartView.value === 'partialLead') {
        this.chartInstance = this.renderPartialLeadsChart()
      } else if (this.chartView.value === 'view') {
        this.chartInstance = this.renderViewsChart()
      } else if (this.chartView.value === 'conversionRate') {
        this.chartInstance = this.renderConversionRateChart()
      }
    },
    renderViewsChart: function () {
      const ctx = document.getElementById('general-chart')
      let data = this.viewsResponse?.value.map((v) => {
        return v.visits
      })
      let labels = this.viewsResponse?.value.map((v) => moment(v.visits_created_at).unix() * 1000)

      if (!this.viewsResponse?.compare) {
        return this.createChart(ctx, labels, data, 'Views')
      }

      let compareData = this.viewsResponse?.compare.value.map((v) => {
        return v.visits
      })
      let compareDataLabels = this.viewsResponse?.compare.value.map((v) => v.visits_created_at)

      return this.createChart(ctx, labels, data, 'Views', compareData, compareDataLabels)
    },
    renderLeadsChart: function () {
      const ctx = document.getElementById('general-chart')

      let data = this.leadsResponse.value.map((l) => {
        return l.leads
      })
      let labels = this.leadsResponse.value.map((l) => moment(l.leads_created_at).unix() * 1000)

      if (!this.leadsResponse.compare) {
        return this.createChart(ctx, labels, data, 'Leads')
      }

      let compareData = this.leadsResponse.compare.value.map((l) => {
        return l.leads
      })
      let compareDataLabels = this.leadsResponse.compare.value.map((l) => l.leads_created_at)

      return this.createChart(ctx, labels, data, 'Leads', compareData, compareDataLabels)
    },
    renderPartialLeadsChart: function () {
      const ctx = document.getElementById('general-chart')

      let data = this.partialLeadsResponse.value.map((l) => {
        return l.leads
      })
      let labels = this.partialLeadsResponse.value.map((l) => moment(l.leads_created_at).unix() * 1000)

      if (!this.partialLeadsResponse.compare) {
        return this.createChart(ctx, labels, data, 'PartialLeads')
      }

      let compareData = this.partialLeadsResponse.compare.value.map((l) => {
        return l.leads
      })
      let compareDataLabels = this.partialLeadsResponse.compare.value.map((l) => l.leads_created_at)

      return this.createChart(ctx, labels, data, 'PartialLeads', compareData, compareDataLabels)
    },
    renderConversionRateChart: function () {
      const ctx = document.getElementById('general-chart')

      let data = this.crResponse.value.map((cr) => {
        return cr.conversion_rate
      })
      let labels = this.crResponse.value.map((cr) => moment(cr?.conversion_rate_created_at).unix() * 1000)

      if (!this.crResponse.compare) {
        return this.createChart(ctx, labels, data, 'ConversionRate')
      }

      let compareData = this.crResponse.compare.value.map((cr) => {
        return cr.conversion_rate
      })
      let compareDataLabels = this.crResponse.compare.value.map((cr) => cr?.conversion_rate_created_at)

      return this.createChart(ctx, labels, data, 'ConversionRate', compareData, compareDataLabels)
    },
    createChart: function (ctx, labels, data, chartName, compareData, compareDataLabels) {
      let periodRange = this.getPeriodRange()
      if (!chartName) {
        chartName = 'Views'
      }
      let datasets = [
        {
          label: chartName + ': ' + periodRange[0] + ' to ' + periodRange[1],
          backgroundColor: '#fff7f7',
          borderColor: 'rgba(238, 110, 115, 1)',
          data: data,
          type: 'line',
          pointRadius: 0,
          fill: true,
          lineTension: 0.50,
          borderWidth: 2
        }
      ]

      if (compareData) {
        let comparePeriodRange = this.getComparePeriodRange()

        datasets.push({
          label: 'Compare: ' + comparePeriodRange[0] + ' to ' + comparePeriodRange[1],
          backgroundColor: 'rgba(238, 110, 115, 0.1)',
          borderColor: 'rgba(238, 110, 115, 1)',
          borderDash: [2, 2],
          data: compareData,
          type: 'line',
          pointRadius: 0,
          fill: false,
          lineTension: 0,
          borderWidth: 2
        })
      }

      return (new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          scales: {
            xAxes: [{
              type: 'time',
              timeFormat: 'YYYY-MM-DD',
              distribution: 'series'
            }],
            yAxes: [{
              scaleLabel: {
                display: true
              },
              ticks: {
                beginAtZero: true
              }
            }]
          },
          tooltips: {
            intersect: false,
            mode: 'index',
            callbacks: {
              label: function (tooltipItem) {
                if (compareDataLabels && tooltipItem.datasetIndex === 1) {
                  let xLabel = moment(compareDataLabels[tooltipItem.index]).format('MMM DD, YYYY')
                  return ' ' + xLabel + '  ' + chartName + ':' + tooltipItem.yLabel
                }
                return ' ' + tooltipItem.xLabel + '  ' + chartName + ':' + tooltipItem.yLabel
              },
              title: function () {
                return chartName
              }
            }
          }
        }
      }))
    }
  },
  computed: {
    viewsResponse: function () {
      return this.generalWidget?.response?.views
    },
    leadsResponse: function () {
      return this.generalWidget.response.leads
    },
    partialLeadsResponse: function () {
      return this.generalWidget.response.partial_leads
    },
    crResponse: function () {
      return this.generalWidget.response.conversion_rate
    },
    ctResponse: function () {
      return this.generalWidget.response.completion_time
    }
  },
  watch: {
    initialized: function (initialized) {
      if (initialized) {
        setTimeout(() => this.chartInit(), 100)
      }
    }
  }
}
</script>

<style></style>
