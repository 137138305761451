import * as mutationTypes from '@/store/mutation-types'
import * as LeadProofApi from '@/api/lead-proofs'
const state = {
  leadProofLists: [],
  leadProofCounts: []
}

const getters = {
  getLeadProofList: function (state) {
    return state.leadProofLists
  }
}

const actions = {
  getLeadProofList ({ state, commit }, data) {
    commit(mutationTypes.FETCH_LEAD_PROOF_LISTS)
    return LeadProofApi.getLeadProofList(data)
  },
  getLeadProofListSuccess ({ state, commit }, data) {
    commit(mutationTypes.FETCH_LEAD_PROOF_LISTS_SUCCESS, data)
  },
  deleteLeadProof ({ state, commit }, data) {
    commit(mutationTypes.DELETE_LEAD_PROOF)
    return LeadProofApi.deleteLeadProof(data.id)
  },
  deleteLeadProofSuccess ({ state, commit }, data) {
    commit(mutationTypes.DELETE_LEAD_PROOF_SUCCESS, data)
  },

  fetchLeadProofCount ({ state, commit }, data) {
    commit(mutationTypes.FETCH_LEAD_PROOF_LIST_COUNT)
    return LeadProofApi.getLeadProofCounts(data)
  }
}

const mutations = {
  [mutationTypes.FETCH_LEAD_PROOF_LISTS] (state, data) { },
  [mutationTypes.FETCH_LEAD_PROOF_LISTS_SUCCESS] (state, data) {
    state.leadProofLists = data || []
  },

  [mutationTypes.DELETE_LEAD_PROOF] (state, data) { },
  [mutationTypes.DELETE_LEAD_PROOF_SUCCESS] (state, data) {
    const index = state.leadProofLists.findIndex(item => item.id === data.id)
    if (index > -1) {
      state.leadProofLists.splice(index, 1)
    }
  },

  [mutationTypes.FETCH_LEAD_PROOF_LIST_COUNT] (state, data) {
    state.leadProofCounts = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
