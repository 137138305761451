<template>
  <v-container class="package-builder-view">
    <v-row class="align-center justify-space-between my-1">
      <v-col cols="12" md="6" class="text-left">
        <router-link :to="{ name: 'package-builder.index.list' }" class="text-primary-red mb-5 link">
          <v-icon left>mdi-arrow-left</v-icon>
          Packages
        </router-link>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <v-btn rounded="md" color="primary-red" class="text-none font-weight-bold" @click="onFormSubmit"
          :loading="packageData.loader" :prepend-icon="isEditView ? 'mdi-update' : 'mdi-plus'">
          {{ isEditView ? 'Update' : 'Create' }} Package
        </v-btn>
      </v-col>
    </v-row>
    <!-- package builder form-->
    <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
      <v-card-title class="bg-blue-lighten-5 text-primary-blue">
        <h6 class="d-flex justify-center mb-0">
          <v-icon icon="mdi-briefcase-plus" class="pt-1"></v-icon>
          <p class="font-weight-bold text-primary-blue mb-0">&nbsp;{{ isEditView ? "Edit Package" : "Add Package" }}</p>
        </h6>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="packageBuilderForm">
          <v-row class="px-10 py-10">
            <!-- package title -->
            <v-col cols="12" md="6">
              <v-text-field variant="outlined" density="comfortable" clearable prepend-inner-icon="mdi-text-short"
                label="Set Title (for admin)" v-model="packageData.title" :rules="$valid([
                  { rule: 'required' },
                  { rule: 'between', min: 5, max: 150 },
                  { rule: 'alphaSpaces' }
                ])">
              </v-text-field>
            </v-col>
            <!-- package description -->
            <v-col cols="12" md="6">
              <v-textarea variant="outlined" density="comfortable" clearable prepend-inner-icon="mdi-text-long"
                label="Add Description (for admin)" rows="1" auto-grow v-model="packageData.description" :rules="$valid([
                  { rule: 'between', min: 3, max: 300 },
                  { rule: 'alphaSpaces' }
                ])">
              </v-textarea>
            </v-col>
            <!-- package plan -->
            <v-col cols="12" md="6">
              <v-autocomplete label="Select Plan" placeholder="Type to search plans" color="secondary-red" id="plan"
                prepend-inner-icon="mdi-currency-usd" variant="outlined" density="comfortable" clearable
                :disabled="this.isEditView" :items="typeof plans === 'string' ? JSON.parse(plans) : plans"
                item-title="title" item-value="id" v-model="packageData.selectedPlanId" :rules="$valid([
                  { rule: 'required' }
                ])">
              </v-autocomplete>
            </v-col>
            <!-- package features -->
            <v-col cols="12" md="6">
              <v-autocomplete label="Add Features to Plan" placeholder="Type to search features" id="plan-features"
                prepend-inner-icon="mdi-feature-search-outline" variant="outlined" density="comfortable" clearable chips
                multiple closable-chips color="secondary-red" @update:modelValue="getChosenPackage"
                :items="availableFeatures" item-title="title" return-object v-model="packageData.selectedFeaturesId"
                :rules="$valid([
                  { rule: 'required' }
                ])"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="dynamicDiv.load">
            <v-col cols="12" align="center">
              <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
                color="secondary-red"></v-progress-circular> </v-col>
          </v-row>
          <v-row v-else-if="dynamicDiv.show">
            <v-col cols="12" align="center">
              <h5><b>Note:</b> Set property values for selected features</h5>
              <hr class="property-break">
            </v-col>
          </v-row>
          <div class="dynamic-div" v-for="(ep) in packageData.chosenPackage" :key="ep.property_id">
            <!-- Descriptive Fields -->
            <!-- Feature, Feature Property, and Property Unit -->
            <v-row class="justify-center">
              <v-col cols="12" md="4" class="text-center">
                <v-chip class="ma-2" color="primary-red" label rounded="xl">
                  <span class="text-black text-wrap"><b>Feature: </b> {{ formatProperty(ep.feature_title) }}</span>
                </v-chip>
              </v-col>
              <v-col cols="12" md="4" class="text-center">
                <v-chip class="ma-2" color="primary-red" label rounded="xl">
                  <span class="text-black text-wrap"><b>Feature Property: </b> {{ formatProperty(ep.property_title)
                    }}</span>
                </v-chip>
              </v-col>
              <v-col cols="12" md="4" class="text-center">
                <v-chip class="ma-2" color="primary-red" label rounded="xl">
                  <span class="text-black text-wrap"><b>Property Unit: </b> {{ formatProperty(ep.property_unit)
                    }}</span>
                </v-chip>
              </v-col>
            </v-row>
            <!-- Input Fields -->
            <!-- Property Value & Time Period Value -->
            <v-row class="px-16 mx-16">
              <!-- Property Unit Number -->
              <v-col cols="12" md="6" v-if="ep.property_type === featurePropertyTypes.NUMBER">
                <v-text-field variant="outlined" density="comfortable" clearable prepend-inner-icon="mdi-numeric"
                  label="Set Property Value" v-model="packageData.selectedPropertyValues[ep.property_id]"
                  :ref="`property-value-${(ep.property_id)}`" :name="`property-value-${(ep.property_id)}`" :rules="$valid([
                    { rule: 'required' },
                    { rule: 'number' }
                  ])">
                </v-text-field>
              </v-col>
              <!-- Property Unit Boolean -->
              <v-col cols="12" md="6" v-if="ep.property_type === featurePropertyTypes.BOOLEAN">
                <v-switch color="primary-red" inset density="compact"
                  v-model="packageData.selectedPropertyValues[ep.property_id]"
                  :ref="`property-value-${(ep.property_id)}`" :name="`property-value-${(ep.property_id)}`"
                  :label="`${packageData.selectedPropertyValues[ep.property_id] ? 'Allow Access' : 'Deny Access'}`">
                </v-switch>
              </v-col>
              <!-- Property Reset Period -->
              <v-col cols="12" md="6">
                <v-autocomplete label="Select Reset Period" placeholder="Type to search for reset period"
                  density="comfortable" variant="outlined" :items="timePeriodOptions" item-title="label"
                  item-value="value" :disabled="ep.property_type === featurePropertyTypes.BOOLEAN"
                  :ref="`reset-period-${(ep.property_id)}`" :name="`reset-period-${(ep.property_id)}`"
                  v-model="packageData.selectedPropertyResetPeriod[ep.property_id]" :rules="$valid([
                    { rule: 'required' }
                  ])">
                </v-autocomplete>
              </v-col>
            </v-row>
            <hr class="property-break">
          </div>
          <v-row>
            <v-col align="center">
              <v-btn rounded="md" color="primary-red" class="text-none font-weight-bold" @click="onFormSubmit"
                :loading="packageData.loader" :prepend-icon="isEditView ? 'mdi-update' : 'mdi-plus'">
                {{ isEditView ? 'Update' : 'Create' }} Package
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar rounded="md" height="50" :color="snackbar.error ? 'secondary-red' : 'primary-green'" :timeout="3000"
      v-model="snackbar.show">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { featurePropertyTypes } from '@/shared/constants/packagebuilder/featurePropertyTypes'
import { formConnectionTypes } from '@/shared/constants/formConnectionTypes'
import timePeriods from '@/store/time-periods'

export default {
  data() {
    return {
      snackbar: {
        show: false,
        text: null,
        error: false
      },
      dynamicDiv: {
        load: false,
        show: false
      },
      packageData: {
        loader: false,
        title: null,
        description: null,
        selectedPlanId: null,
        selectedFeaturesId: [],
        selectedPropertyValues: [],
        selectedPropertyResetPeriod: [],
        chosenPackage: []
      }
    }
  },
  mounted() {
    this.fetchData()
    if (this.isEditView) {
      setTimeout(() => {
        this.loadPackageData()
      }, 100)
    }
  },
  methods: {
    ...mapActions('packagebuilder', [
      'getAvailableFeatures',
      'getFeatureProperties',
      'getAvailableFeaturesSuccess',
      'getFeaturePropertiesSuccess',
      'getPackageData'
    ]),
    async fetchData() {
      try {
        const [availableFeatures, featureProperties] = await Promise.all([
          this.getAvailableFeatures(),
          this.getFeatureProperties()
        ])

        this.getAvailableFeaturesSuccess(availableFeatures.data.data.map(feature => ({
          ...feature,
          title: feature.title.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        })))
        this.getFeaturePropertiesSuccess(featureProperties.data.data)
      } catch (err) {
        this.showSnackbar(true, 'An error occurred while fetching the package.')
      }
    },
    getChosenPackage(value) {
      this.dynamicDiv.show = false
      this.dynamicDiv.load = true
      this.packageData.selectedFeaturesId = value

      // Reset chosenPackage
      this.packageData.chosenPackage = this.featureProperties.reduce((singleObj, property) => {
        const feature = this.packageData.selectedFeaturesId.find(f => f.id === property.feature_id)
        if (feature !== undefined) {
          singleObj.push({
            feature_id: feature.id,
            feature_title: feature.title,
            feature_slug: feature.slug,
            property_id: property.id,
            property_title: property.title,
            property_type: property.type,
            property_unit: property.unit,
            property_isResetPeriod: property.isResetPeriod
          })

          // Set default value for selectedPropertyResetPeriod if not already set
          if (!this.packageData.selectedPropertyResetPeriod[property.id]) {
            this.packageData.selectedPropertyResetPeriod[property.id] = this.timePeriodOptions[0].label
          }

          // Initialize the selectedPropertyValues with the correct default value for boolean properties
          if (property.type === this.featurePropertyTypes.BOOLEAN && this.packageData.selectedPropertyValues[property.id] === undefined) {
            this.packageData.selectedPropertyValues[property.id] = false
          }

        }
        return singleObj
      }, [])

      this.dynamicDiv.show = true
      this.dynamicDiv.load = false
    },
    loadPackageData: function () {
      this.getPackageData({ id: this.$route.params.id }).then((response) => {
        let responseData = response.data.data
        this.packageData.title = responseData.title
        this.packageData.description = responseData.description
        this.packageData.selectedPlanId = { id: responseData.plan.id, title: responseData.plan.title }

        let featureProperties = responseData.plan.plan_features.flatMap(feature => feature.feature_properties)

        for (let property of featureProperties) {
          if (property.type === this.featurePropertyTypes.BOOLEAN) {
            this.packageData.selectedPropertyValues[property.feature_property_id] = property.value === "1" || property.value === 1
          } else {
            this.packageData.selectedPropertyValues[property.feature_property_id] = property.value
          }
          let selected = this.timePeriodOptions.find(item => item.label === property.reset_period)
          this.packageData.selectedPropertyResetPeriod[property.feature_property_id] =
            selected ? selected.label : this.timePeriodOptions[0].label
        }

        let planFeatureIds = responseData.plan.plan_features.map(feature => feature.id)
        this.packageData.selectedFeaturesId = this.availableFeatures.filter(feature => planFeatureIds.includes(feature.id))
        this.getChosenPackage(this.packageData.selectedFeaturesId)
      }).catch({})
    },
    async onFormSubmit() {
      this.packageData.loader = true
      const { valid } = await this.$refs.packageBuilderForm.validate()
      if (!valid) {
        this.packageData.loader = false
        return // Prevent submission if the form is not valid
      }

      let packageBuilderData = this.packageData.chosenPackage
        .filter(ep => ep)
        .map(ep => {
          let propertyValue = null
          if (ep.property_type === this.featurePropertyTypes.BOOLEAN) {
            // Handle boolean type property values
            propertyValue = this.packageData.selectedPropertyValues[ep.property_id]
          } else {
            // Handle other type property values
            propertyValue = this.$refs[`property-value-${ep.property_id}`][0].value
          }
          return {
            featureId: ep.feature_id,
            propertyId: ep.property_id,
            propertyValue: propertyValue,
            propertyResetPeriod: this.$refs[`reset-period-${ep.property_id}`][0].value
          }
        })

      let formData = {
        packageTitle: this.packageData.title,
        packageDescription: this.packageData.description,
        packagePlan: this.packageData.selectedPlanId,
        packageData: packageBuilderData
      }

      let packageAction = this.isEditView ? 'updatePackageData' : 'savePackageData'
      let dispatchData = this.isEditView ? { id: parseInt(this.$route.params.id), data: formData } : formData

      this.$store.dispatch(`packagebuilder/${packageAction}`, dispatchData)
        .then((response) => {
          this.packageData.loader = false
          if (!this.isEditView) {
            this.$router.push({ name: 'package-builder.edit', params: { id: response.data.data.id } })
          }
          this.showSnackbar(false, `${this.isEditView ? 'Package updated' : 'Package added'} successfully.`)
        }).catch((err) => {
          this.packageData.loader = false
          let text = err.response.data && err.response.data.meta && err.response.data.meta.error_message
            ? err.response.data.meta.error_message : 'An error occurred while saving the package.'
          this.showSnackbar(true, text)
        })
    },
    formatProperty: function (str) {
      let formatted = str.toLowerCase().replace(/_/g, ' ')
      return formatted.charAt(0).toUpperCase() + formatted.slice(1)
    },
    showSnackbar: function (flag, message) {
      this.snackbar.show = true
      this.snackbar.error = flag
      this.snackbar.text = message
    }
  },
  computed: {
    ...mapGetters('packagebuilder', {
      availableFeatures: 'getAvailableFeatures',
      featureProperties: 'getFeatureProperties'
    }),
    ...mapState({
      plans: state => state.plans
    }),
    isEditView: function () {
      return this.$route.name === 'package-builder.edit'
    },
    timePeriodOptions: function () {
      return [
        { label: 'None', value: timePeriods.NONE },
        { label: 'As Per Plan', value: timePeriods.AS_PER_PLAN },
        { label: 'Monthly', value: timePeriods.MONTHLY },
        { label: 'Yearly', value: timePeriods.YEARLY }
      ]
    },
    featurePropertyTypes: function () {
      return featurePropertyTypes
    },
    formConnectionTypes: function () {
      return formConnectionTypes
    }
  }
}
</script>
