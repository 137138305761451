import * as mutationTypes from '@/store/mutation-types'
import * as formEndingApi from '@/api/form-endings'

const state = {
  endingsLists: [],
  formQuestions: [],
  visitorPriority: []
}

const getters = {
  getVisitorPriority: function (state) {
    return state.visitorPriority
  },
  getFormEndingLists: function (state) {
    return state.endingsLists
  },
  getFormQuestions: function (state) {
    return state.formQuestions
  }
}

const actions = {

  getFormEndings ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_ENDINGS)
    return formEndingApi.getFormEndingLists(data)
  },
  getFormEndingData ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_ENDINGS_DATA)
    return formEndingApi.getFormEndingData(data)
  },
  updateAnswerBasedData ({state, commit}, data) {
    commit(mutationTypes.UPDATE_FORM_ENDINGS_DATA)
    return formEndingApi.updateFormEnding(data)
  },
  getFormEndingsSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_ENDINGS_SUCCESS, data)
  },
  saveDefaultThankyouPage (context, data) {
    return formEndingApi.updateDefaultThankyouPage(data)
  },
  getFormQuestions ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_QUESTIONS)
    return formEndingApi.getFormQuestions(data)
  },
  getFormQuestionsSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_QUESTIONS_SUCCESS, data)
  },
  saveAnswerBasedData (context, data) {
    return formEndingApi.createFormEnding(data, data.userId)
  },
  deleteFormEnding ({state, commit}, data) {
    commit(mutationTypes.DELETE_FORM_ENDING)
    return formEndingApi.deleteFormEnding(data)
  },
  bulkDeleteFormEndings ({state, commit}, data) {
    commit(mutationTypes.BULK_DELETE_FORM_ENDINGS)
    return formEndingApi.bulkDeleteFormEndings(data)
  },
  bulkDeleteFormEndingsSuccess ({state, commit}, data) {
    commit(mutationTypes.BULK_DELETE_FORM_ENDINGS_SUCCESS, data)
  },
  bulkDuplicateFormEndings ({state, commit}, ids) {
    commit(mutationTypes.BULK_DUPLICATE_FORM_ENDINGS)
    return formEndingApi.bulkDuplicateFormEndings(ids)
  },
  bulkDuplicateFormEndingsSuccess ({state, commit}, data) {
    commit(mutationTypes.BULK_DUPLICATE_FORM_ENDINGS_SUCCESS, data)
  },
  duplicateFormEnding ({state, commit}, data) {
    commit(mutationTypes.DUPLICATE_FORM_ENDING)
    return formEndingApi.duplicateFormEnding(data)
  },
  enableFormEnding (context, data) {
    return formEndingApi.enableFormEnding(data)
  },
  setVisitorPriority ({ commit }, payload) {
    commit(mutationTypes.SET_VISITOR_PRIORITY, payload)
    return formEndingApi.saveOrder(payload)
  }
}

const mutations = {
  [mutationTypes.SET_VISITOR_PRIORITY] (state, payload) {
    state.visitorPriority = payload
  },
  [mutationTypes.UPDATE_FORM_ENDINGS_DATA] (state, data) {},
  [mutationTypes.DELETE_FORM_ENDING] (state, data) {},
  [mutationTypes.BULK_DELETE_FORM_ENDINGS] (state, data) {},
  [mutationTypes.BULK_DELETE_FORM_ENDINGS_SUCCESS] (state, data) {},
  [mutationTypes.BULK_DUPLICATE_FORM_ENDINGS] (state, data) {},
  [mutationTypes.BULK_DUPLICATE_FORM_ENDINGS_SUCCESS] (state, data) {},
  [mutationTypes.DUPLICATE_FORM_ENDING] (state, data) {},
  [mutationTypes.FETCH_FORM_ENDINGS] (state, data) {},
  [mutationTypes.FETCH_FORM_ENDINGS_DATA] (state, data) {},
  [mutationTypes.FETCH_FORM_ENDINGS_SUCCESS] (state, data) {
    state.endingsLists = data || []
  },
  [mutationTypes.FETCH_FORM_QUESTIONS] (state, data) {},
  [mutationTypes.FETCH_FORM_QUESTIONS_SUCCESS] (state, data) {
    state.formQuestions = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
