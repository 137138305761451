export const featureSlugs = {
  LEAD_PROOFS: 'lead_proofs',
  PARTIAL_LEADS: 'partial_leads',
  LEADS: 'leads',
  TRUSTEDFORM: 'trustedform',
  MULTIPILE_ENDINGS: 'multipile_endings'
}

export const usageCount = {
  PARTIAL_LEADS: 'partial',
  LEADS: 'lead',
  FORMS: 'form'
}
