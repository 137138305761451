import * as mutationTypes from '@/store/mutation-types'
import * as ExternalCheckoutApi from '@/api/external-checkout'

const state = {
  externalCheckouts: [],
  externalCheckoutsList: []
}

const getters = {
  getExternalCheckouts: function (state) {
    return state.externalCheckouts
  }
}

const actions = {
  getExternalCheckouts({ state, commit }, data) {
    commit(mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS)
    return ExternalCheckoutApi.getExternalCheckouts()
  },
  getExternalCheckoutsSuccess({ state, commit }, data) {
    commit(mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS_SUCCESS, data)
  },
  getExternalCheckout({ state, commit }, data) {
    commit(mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUT)
    return ExternalCheckoutApi.getExternalCheckout(data.id)
  },
  getExternalCheckoutSuccess({ state, commit }, data) {
    commit(mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUT_SUCCESS, data)
  },

  createExternalCheckout({ state, commit }, data) {
    commit(mutationTypes.ECM_CREATE_EXTERNAL_CHECKOUT)
    return ExternalCheckoutApi.createExternalCheckout(data)
  },
  createExternalCheckoutSuccess({ state, commit }, data) {
    commit(mutationTypes.ECM_CREATE_EXTERNAL_CHECKOUT_SUCCESS, data)
  },

  updateExternalCheckout({ state, commit }, data) {
    commit(mutationTypes.ECM_UPDATE_EXTERNAL_CHECKOUT)
    return ExternalCheckoutApi.updateExternalCheckout(data.id, data.data)
  },
  updateExternalCheckoutSuccess({ state, commit }, data) {
    commit(mutationTypes.ECM_UPDATE_EXTERNAL_CHECKOUT_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS_SUCCESS](state, data) {
    state.externalCheckouts = data || []
  },
  [mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUT](state) { },
  [mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUT_SUCCESS](state, data) {
    let index = 0
    for (let gkey of state.externalCheckouts) {
      if (gkey.id === data.id) {
        state.externalCheckouts[index] = data
      }
      index++
    }
  },

  [mutationTypes.ECM_CREATE_EXTERNAL_CHECKOUT_SUCCESS](state, data) {
    state.externalCheckouts.push(data)
    // Vue.set(state.externalCheckouts, state.externalCheckouts.length, data)
  },
  [mutationTypes.ECM_UPDATE_EXTERNAL_CHECKOUT](state) { },
  [mutationTypes.ECM_UPDATE_EXTERNAL_CHECKOUT_SUCCESS](state, data) {
    let index = 0
    for (let gkey of state.externalCheckouts) {
      if (gkey.id === data.id) {
        state.externalCheckouts[index] = data
        //Vue.set(state.externalCheckouts, index, data)
      }
      index++
    }
  },
  [mutationTypes.ECM_DELETE_EXTERNAL_CHECKOUT_SUCCESS](state, data) {
    const index = state.externalCheckoutsList.findIndex(checkout => checkout.id === data.id)
    if (index !== -1) {
      state.externalCheckouts.splice(index, 1)
    }
  },
  // [mutationTypes.ECM_DELETE_EXTERNAL_CHECKOUT_SUCCESS](state, data) {
  //   let index = 0
  //   for (let gkey of state.externalCheckoutsList) {
  //     if (gkey.id === data.id) {
  //       state.externalCheckouts.splice(index, 1)
  //       Vue.set(state.externalCheckouts, state.externalCheckouts, data)
  //     }
  //     index++
  //   }
  // },
  [mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS](state, data) { },
  [mutationTypes.ECM_FETCH_EXTERNAL_CHECKOUTS_SUCCESS](state, data) {
    state.externalCheckoutsList = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
