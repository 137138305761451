<template>
  <div class="lf-dropdown lf-dropdown__right">
    <span><i class="material-icons">account_circle</i></span>
    <div class="lf-dropdown__content">
      <div class="lf-dropdown__content__header">
        <small> Welcome! <b>{{ userName }}</b>
        </small>
      </div>
      <ul>
        <li @click="profile()">
          <a>
            <i class="material-icons">person</i>
            <span>My Profile</span>
          </a>
        </li>
        <li @click="logout">
          <a>
            <i class="material-icons">logout</i>
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'
import * as actionTypes from '@/store/action-types'

export default {
  props: ['userName'],
  mounted () {
    $(document).off('click')
    $('.lf-dropdown > span').off('click')
    $('.lf-dropdown__content > ul > li').off('click')

    $(document).click(function (e) {
      if ($(e.target).closest('.lf-dropdown').length === 0) {
        $('.lf-dropdown').removeClass('open')
      }
    })

    $(document).on('click', '.lf-dropdown > span', function () {
      $(this).closest('.lf-dropdown').toggleClass('open')
    })

    $(document).on('click', '.lf-dropdown__content > ul > li', function () {
      $('.lf-dropdown').removeClass('open')
    })
  },

  methods: {
    logout: function () {
      this.$store.dispatch(actionTypes.LOGOUT_USER).then((result) => {
        this.$router.push({name: 'login'})
      })
    },
    profile: function () {
      this.$router.push({ name: 'user.profile' })
    }
  }
}
</script>
