import { createStore } from 'vuex'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import questionTypes from '../store/question-types'
import questionTypesMap from '@/store/question-types-map'
import planTypes from '@/store/plan-types'
import formStepElementTypes from '@/store/form-step-element-types'
import mimeTypes from '@/store/mime-types'
import httpVerbs from '@/store/http-verbs'
import themeDefaults from '@/store/theme-defaults'
import roles from '@/store/roles'
import LandingPageModule from '@/store/modules/landingpage'
import MediaModule from '@/store/modules/media'
import GoogleRecaptchaModule from '@/store/modules/googlerecaptcha'
import DashboardModule from '@/store/modules/dashboard'
import FormWebhookModule from '@/store/modules/formwebhook'
import SettingModule from '@/store/modules/setting'
import IconLibraryModule from '@/store/modules/iconlibrary'
import SubscriptionModule from '@/store/modules/subscription'
import NotificationModule from '@/store/modules/notification'
import ExternalCheckoutModule from '@/store/modules/externalcheckout'
import FormThemeTemplateModule from '@/store/modules/formthemetemplate'
import BlacklistIpsModule from '@/store/modules/blacklistips'
import FormTemplateBrowser from '@/store/modules/formtemplatebrowser'
import FormTemplateBuildModule from '@/store/modules/formtemplatebuild'
import FormListsModule from '@/store/modules/lists/formIndex'
import UserListsModule from '@/store/modules/lists/userIndex'
import LeadProofListsModule from '@/store/modules/lists/LeadProofIndex'
import FormConnectionModule from '@/store/modules/formconnection'
import FormContactStateModule from '@/store/modules/formcontactstate'
import SocialAuth from '@/store/modules/socialauth'
import Form from '@/store/modules/form/form'
import FormTrackingEvent from '@/store/modules/form/form-tracking-event'
import FormResetStatusModule from '@/store/modules/form'
import UserSettingsModule from '@/store/modules/user-settings'
import UserModule from '@/store/modules/user'
import PackageBuilder from '@/store/modules/packagebuilder'
import PartialLeadsModule from '@/store/modules/partialleads'
import FormReport from '@/store/modules/report'
import ExternalCheckoutListsModule from '@/store/modules/lists/externalCheckoutIndex'
import FormTemplateListsModule from '@/store/modules/lists/formTemplateListIndex'
import Onboarding from '@/store/modules/onboarding'
import UtmParamListModule from '@/store/modules/lists/utmParamIndex'
import formEndingsListModule from './modules/form/form-endings'

const state = {
  user: null,
  users: [],
  token: null,
  decodedToken: null,
  leadsCount: -1,
  formsCount: -1,
  averageConversionCount: -1,
  formBuildState: {
    id: null,
    formTitle: 'My Form',
    validate: false,
    lastStepId: 1,
    lastQuestionId: 1,
    lastElementId: 1,
    choiceFormula: '',
    calculator: {
      fieldName: ''
    },
    steps: [
      {
        'id': 1,
        'autoNavigation': false,
        'questions': [
          {
            'id': 1,
            'stepId': 1,
            'number': 1,
            'type': 'SHORT_TEXT',
            'required': true,
            'showHideOrientationScale': true,
            'placeholder': 'Please enter your name',
            'title': '',
            'description': '',
            'choices': [
              { id: 1, label: 'Choice 1', order: 1, description: '', icon: '', image: '' },
              { id: 2, label: 'Choice 2', order: 2, description: '', icon: '', image: '' },
              { id: 3, label: 'Choice 3', order: 3, description: '', icon: '', image: '' }
            ],
            restrictEmailFields: [
              { id: 1, order: 1, allow: 1 }
            ],
            rangeFields: {
              unitAlignment: 'left',
              unit: '',
              veryUnsatisfied: 'Very Unsatisfied',
              unsatisfied: 'Unsatisfied',
              neutral: 'Neutral',
              satisfied: 'Satisfied',
              verySatisfied: 'Very Satisfied',
              lowerEndScaleText: 'Very Bad',
              higherEndScaleText: 'Excellent',
              maxScaleLimit: 5,
              minScaleValue: 0,
              maxScaleValue: 100,
              valueMin: 0,
              valueMax: 100,
              likertRadios: [
                { id: 0, label: '1', lowerEndScaleText: 'Very Bad' },
                { id: 1, label: '2' },
                { id: 2, label: '3' },
                { id: 3, label: '4' },
                { id: 4, label: '5', higherEndScaleText: 'Excellent' }
              ]
            },
            'choicesValues': [0, 0, 0],
            'valid': false,
            'enabled': true,
            'hide_title': false
          }
        ]
      }
    ]
  },
  formPreview: {
    height: 400,
    width: 500,
    formShadow: '',
    reload: false,
    fullWidthForm: true,
  },
  forms: [],
  formLeads: {},
  formSetting: {},
  formVariants: [],
  formExperiments: [],
  formExperimentResults: [],
  formExperimentVariants: [],
  formVariantSettings: [],
  plans: [],
  formHiddenFields: [],
  formQuestionTypes: questionTypes,
  questionTypesMap: questionTypesMap,
  formArchived: false,
  // resetFormStatus: false,
  planTypes: planTypes,
  mimeTypes: mimeTypes,
  httpVerbs: httpVerbs,
  rolesMap: roles,
  formStepElementTypes: formStepElementTypes,
  formVariantThemes: [],
  formThemeTemplates: {},
  defaultFormVariantTheme: themeDefaults
}

export const store = createStore({
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
   modules: {
    'landingpage': LandingPageModule,
    'media': MediaModule,
    'googlerecaptcha': GoogleRecaptchaModule,
    'formconnection': FormConnectionModule,
    'dashboard': DashboardModule,
    'formwebhook': FormWebhookModule,
    'setting': SettingModule,
    'iconlibrary': IconLibraryModule,
    'subscription': SubscriptionModule,
    'notification': NotificationModule,
    'externalcheckout': ExternalCheckoutModule,
    'formthemetemplate': FormThemeTemplateModule,
    'blacklistips': BlacklistIpsModule,
    'formtemplatebrowser': FormTemplateBrowser,
    'formtemplatebuild': FormTemplateBuildModule,
    'formlists': FormListsModule,
    'userlists': UserListsModule,
    'leadprooflists': LeadProofListsModule,
    'formcontactstate': FormContactStateModule,
    'socialauth': SocialAuth,
    'form': Form,
    'formTrackingEvent': FormTrackingEvent,
    'formResetStatus': FormResetStatusModule,
    'user-settings': UserSettingsModule,
    'user': UserModule,
    'packagebuilder': PackageBuilder,
    'partialLeads': PartialLeadsModule,
    'formreport': FormReport,
    'externalcheckoutlists': ExternalCheckoutListsModule,
    'formtemplatelists': FormTemplateListsModule,
    'onboarding': Onboarding,
    'utmparamslists': UtmParamListModule,
    'formendings': formEndingsListModule
  }
})
