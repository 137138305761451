// ADDRESS QUESTION TYPE
export const addressSkinIds = {
  DEFAULT: 'default',
  GOOGLE_AUTOCOMPLETE: 'googleAutocomplete'
}

export const addressAutocompleteModeIds = {
  MANUAL: 'manual',
  SEARCH: 'search'
}

export const addressAutocompleteModes = [
  {
    id: addressAutocompleteModeIds.MANUAL,
    label: 'Manual'
  },
  {
    id: addressAutocompleteModeIds.SEARCH,
    label: 'Search'
  }
]

export const addressSkins = [
  {
    id: addressSkinIds.DEFAULT,
    label: 'Default'
  },
  {
    id: addressSkinIds.GOOGLE_AUTOCOMPLETE,
    label: 'Google Autocomplete'
  }
]

// Date Quetion type skins
export const dateSkinIds = {
  THREE_INPUT_BOXES: 'threeInputBoxes',
  ONE_INPUT_BOX: 'oneInputBox',
  DROPDOWN: 'dropdown',
  DATE_PICKER: 'datePicker'
}
export const dateSkins = [
  {
    id: dateSkinIds.THREE_INPUT_BOXES,
    label: '3 Input Boxes'
  },
  {
    id: dateSkinIds.ONE_INPUT_BOX,
    label: '1 Input Box'
  },
  {
    id: dateSkinIds.DROPDOWN,
    label: 'Dropdown'
  },
  {
    id: dateSkinIds.DATE_PICKER,
    label: 'Date Picker'
  }
]

// Range Quetion type skins
export const rangeSkinIds = {
  SLIDER_SCALE: 'sliderscale',
  RANGE_SCALE: 'rangescale',
  LIKERT_SCALE: 'likertscale',
  LIKERT_SMILEYS_SCALE: 'likertsmileysscale'
}
export const rangeSkins = [
  {
    id: rangeSkinIds.SLIDER_SCALE,
    label: 'Slider Scale'
  },
  {
    id: rangeSkinIds.RANGE_SCALE,
    label: 'Range Scale'
  },
  {
    id: rangeSkinIds.LIKERT_SCALE,
    label: 'Likert Scale'
  },
  {
    id: rangeSkinIds.LIKERT_SMILEYS_SCALE,
    label: 'Likert Scale - Smileys'
  }
]
export const otherChoice = {
  OTHER: 'other'
}
