<template>
  <v-row align="center" justify="center" class="my-16 forgot-password-view">
    <v-col cols="12" sm="10" md="8" lg="6" xl="4">
      <v-card class="text-center pa-6 my-2" flat>
        <v-card-title class="text-center d-flex flex-column align-center">
          <v-img :width="80" aspect-ratio="1" cover :src="require('@/assets/images/leadgen-leaf-logo.png')"></v-img>
          <br>
        </v-card-title>
        <v-card-text>
          <h4 class="text-h4 font-weight-bold mb-4">Reset Password</h4>
          <div class="text-subtitle-1	 font-weight-regular">
            Your new password must be different from previously used passwords.
          </div>
          <!-- Error / Warning alert -->
          <v-row justify="center" class="my-1">
            <v-col cols="12">
              <v-alert border :type="alert.type" variant="tonal" rounded="md" density="compact" class="text-left mb-8"
                icon="mdi-alert-circle" v-if="alert.show">
                <span class="alert mb-0"> {{ alert.message }}</span>
              </v-alert>
            </v-col>
          </v-row>
          <v-form ref="resetPasswordForm">
            <v-row class="text-left">
              <v-col cols="12">
                <!-- Password input -->
                <v-text-field label="Enter your password" variant="outlined" density="comfortable" rounded="md"
                  prepend-inner-icon="mdi-lock-plus-outline" v-model="reset.password"
                  :append-inner-icon="reset.toggleP ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="reset.toggleP = !reset.toggleP" :type="reset.toggleP ? 'text' : 'password'"
                  :rules="$valid([
                    { rule: 'required', msg: 'Please enter your new password' },
                    { rule: 'password' },
                    { rule: 'between', min: '8', max: '150' }
                  ])"></v-text-field>

                <!-- confirm password input -->
                <v-text-field variant="outlined" label="Your new password" prepend-inner-icon="mdi-lock-check-outline"
                  density="comfortable" rounded="md" clearable :rules="$valid([
                    { rule: 'required', msg: 'Please confirm your password' },
                    { rule: 'password' },
                    { rule: 'confirm', field: reset.password },
                    { rule: 'between', min: '8', max: '150' }
                  ])" v-model="reset.confirm"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="text-left">
              <v-col cols="6">
                <v-btn variant="text" color="secondary-red" @click="$router.push({ name: 'login' })">
                  <v-icon left>mdi-chevron-left</v-icon>
                  Back to login
                </v-btn>
              </v-col>
              <!-- reset btn -->
              <v-col cols="6" class="text-right">
                <v-btn color="primary-red" rounded="md" height="42" width="90%"
                  class="text-none font-weight-bold mb-0 reset-btn" append-icon="mdi-lock-reset" :loading="reset.loader"
                  @click="resetPassword">Reset Password</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import * as actionTypes from '@/store/action-types'

export default {
  data: () => ({
    reset: {
      password: '',
      confirm: '',
      toggleP: false,
      loader: false
    },
    alert: {
      show: false,
      type: '',
      message: ''
    }
  }),
  methods: {
    ...mapActions({
      resetPasswordAction: actionTypes.RESET_PASSWORD
    }),

    async resetPassword() {
      const { valid } = await this.$refs.resetPasswordForm.validate()
      if (!valid) {
        this.reset.loader = false
        return
      }

      this.reset.loader = true
      try {
        await this.resetPasswordAction({
          password: this.reset.password,
          token: this.$route.params.token,
          userId: this.$route.params.userId
        })
        this.showAlert('success', 'Your password has been reset. Please use your new password for future logins.')
      } catch (err) {
        this.showAlert('error', err.response?.data?.meta?.error_message || 'Something went wrong! Please try again later.')
      } finally {
        this.reset.loader = false
      }
    },
    showAlert(type, message) {
      this.alert = { show: true, type, message }
    }
  }
}
</script>
